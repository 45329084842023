import skylight from 'skylight-js-sdk'

export const formatRealm = realm => {
  const trimmedRealm = realm.trim()
  return skylight.utils.isFrontline() ? trimmedRealm : trimmedRealm.toLowerCase()
}

export const generateTempPassword = (length = 12) => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let retVal = ''

  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n))
  }
  return retVal
}
