import React from 'react'
import ZipIcon from '../../Common/Icons/zip'
import HtmlIcon from '../../Common/Icons/tag'
import './ResourcePreview.css'

const ICON_MAP = {
  'application/zip': ZipIcon,
  'text/html': HtmlIcon
}

const ResourcePreview = (props) => {
  const Icon = ICON_MAP[props.type]

  return (
    <div className="resource-preview">{Icon ? <Icon /> : null}</div>
  )
}

export { ResourcePreview }
