import React from 'react'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'

import './ForgotPasswordLink.css'

const ForgotPasswordLink = props => {
  return (
    <div className="forgot-password-link">
      <NavLink to="/recover-password">
        <FormattedMessage id="FORGOT_PASSWORD_QUESTION" defaultMessage="Forgot password?" />
      </NavLink>
    </div>
  )
}

export default ForgotPasswordLink
