import React from 'react'
import { FormattedMessage } from 'react-intl'
import MenuItem from 'material-ui/MenuItem'
import { DeleteIcon, DetailsMenu, isPhone } from 'skylight-common'
import ArchiveIcon from 'material-ui/svg-icons/content/archive'
// import MediaIcon from 'material-ui/svg-icons/image/photo'
import SecurityTrimmer from '../../../../containers/SecurityTrimmer'
import { ASSIGNMENT } from '../../../../svc/scopes'

import skylight from 'skylight-js-sdk'
import './AssignmentActionMenu.css'

const AssignmentActionMenu = (props) => {
  const item = props.assignmentItem.item
  const { mode } = props.assignmentItem

  // Nothing to remove for assignment.
  if (!isPhone() || mode === 'new') {
    return null
  }

  const onDelete = () => {
    props.onAssignmentDeleteItems([item.id])
    props.onShowSnackbar('DELETING_ASSIGNMENT')
  }

  const onDeleteAssignment = () => {
    props.onConfirm(
      onDelete,
      {
        title: 'ASSIGNMENT_DELETE_DIALOG_TITLE',
        titleValues: { value: item.name },
        message: 'ASSIGNMENT_DELETE_DIALOG_DESCRIPTION',
        action: 'ACTION_DELETE_ASSIGNMENT'
      }
    )
  }

  const onUnassign = () => {
    props.onUnassignWorkflow([item.id])
    props.onShowSnackbar('ARCHIVING_ASSIGNMENT')
  }

  // const onCapturedMedia = () => {
  //   props.onMediaByAssignment(item.id)
  // }

  const showArchive = !item.isTask && !item.archived

  const menuItems = [
    // https://upskill.atlassian.net/browse/SKY-905
    // {icon: MediaIcon, action: onCapturedMedia, show: !item.isTask,
    //   text: {id: 'CAPTURED_MEDIA', default: 'Captured media'}},
    {
      icon: DeleteIcon,
      action: onDeleteAssignment,
      scopes: [ASSIGNMENT.DELETE],
      show: true,
      text: { id: 'DELETE_ASSIGNMENT', default: 'Delete Assignment' }
    },
    {
      icon: ArchiveIcon,
      action: onUnassign,
      scopes: [ASSIGNMENT.ASSIGN],
      show: showArchive,
      text: { id: 'ACTION_UNASSIGN_WORKFLOW', default: 'Unassign' }
    }
  ]

  // Removed as a part of - https://favro.com/card/60fe74a05e7da9c931484a9a/Ups-4340
  return item.isTask
    ? <SecurityTrimmer scopes={[ASSIGNMENT.DELETE]}>
      <DetailsMenu className="assignment-action-menu">
        <MenuItem
          primaryText={<FormattedMessage id="DELETE_ASSIGNMENT" defaultMessage="Delete assignment" />}
          leftIcon={<DeleteIcon />}
          onTouchTap={onDeleteAssignment} />
      </DetailsMenu>
    </SecurityTrimmer>
    : <DetailsMenu className="assignment-action-menu">
      {menuItems
        .filter(m => m.show && skylight.auth.hasScopes(props.scopes, m.scopes))
        .map((m, i) => {
          const Icon = m.icon
          return <MenuItem
            key={i}
            primaryText={<FormattedMessage id={m.text.id} defaultMessage={m.text.default} />}
            leftIcon={<Icon className="assignment-action-left-icon" />}
            onTouchTap={m.action} />
        })}
    </DetailsMenu>
}

export { AssignmentActionMenu }
