import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import appActions from '../actions/app'
import { actions } from 'skylight-common'

import { Details as DetailsComp } from '../App/Home/Details'

const details = (state, props) => state

const detailsActions = {
  onCloseDetails: appActions.onCloseDetails,
  onToggleDrawer: actions.nav.onToggleDrawer
}

const Details = withRouter(connect(details, detailsActions)(DetailsComp))

export { Details }
