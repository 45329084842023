import React from 'react'
import { FormattedTime } from 'skylight-common'
import { UserAvatar, UserTitle } from '../Common'

export const ChatMessage = props => {
  const { me, message, partner } = props
  const isFromMe = message.from === 'me'
  const user = isFromMe ? me : partner

  return (
    <div className={`chat-message ${isFromMe ? ' mine' : ''}`}>
      <div className="avatar">
        <UserAvatar className="small" user={user} />
      </div>
      <div className="title-body">
        <div className="title">
          <UserTitle user={user} />
          <div className="date">
            <FormattedTime value={message.date} />
          </div>
        </div>
        <div className="body">
          {message.message}
        </div>
      </div>
    </div>
  )
}
