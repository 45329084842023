export const USER_DEFAULT_QUERY = {
  sortBy: 'firstName,lastName',
  sortAsc: true,
  filter: {},
  filters: []
}

export const CALL_DEFAULT_QUERY = {
  sortBy: 'status',
  sortAsc: false,
  includeMe: false,
  isCallUser: true,
  filter: {
    showUnavailable: true,
    showAll: false
  },
  filters: []
}

export const GROUP_DEFAULT_QUERY = {
  sortBy: 'name',
  sortAsc: true,
  filter: {},
  filters: []
}

export const filterByUsername = (x, q) => {
  if (!q.filter.title) return true

  return q.filter.title
    .toLowerCase()
    .split(',')
    .map(x => x.trim())
    .filter(x => !!x)
    .some(f => x.username.toLowerCase().includes(f) ||
    (x.firstName && x.firstName.toLowerCase().includes(f)) ||
    (x.lastName && x.lastName.toLowerCase().includes(f)))
}

export const filterByUserIds = (x, q) => {
  if (!q.filter.userIds || q.filter.userIds.length === 0) return true
  return q.filter.userIds.includes(x.id)
}

export const filterGroups = (groups, query) => {
  if (!query || !query.filter) return groups
  return groups.filter(g => {
    if (query.filter.title) {
      if (!query.filter.title
        .toLowerCase()
        .split(',')
        .map(x => x.trim())
        .filter(x => !!x)
        .some(f => g.name.toLowerCase().includes(f))) return false
    }

    if (query.filter.groupIds && query.filter.groupIds.length > 0) {
      if (!query.filter.groupIds.includes(g.id)) return false
    }

    return true
  })
}

export const filterUsers = (users, query) => {
  if (!query || !query.filter) return true
  return users
    .filter(x => filterByUsername(x, query))
    .filter(x => filterByUserIds(x, query))
}
