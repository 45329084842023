import React from 'react'
import { ActionButton, ListActionButton, ListFooterStats, Table, Column, MaterialIcon, Loading, DeleteIcon } from 'skylight-common'

import { FormattedMessage } from 'react-intl'
import IconButton from 'material-ui/IconButton'
import GroupIcon from 'material-ui/svg-icons/action/supervisor-account'
// import EditIcon from 'material-ui/svg-icons/editor/mode-edit'
import { GROUP } from '../../../../svc/scopes'
import { filterGroups } from '../../../../lib/contact'
import NewGroupIcon from 'material-ui/svg-icons/social/group-add'
import skylight from 'skylight-js-sdk'
import './GroupList.css'

const EmptyLabel =
  <FormattedMessage id="EMPTY_GROUP_LIST" defaultMessage="You have not created any groups." />

const GroupList = (props) => {
  const { groups } = props
  const items = filterGroups(groups.items, groups.query)
  const detailsOpen = groups.selectedIds.length === 1

  const onDelete = () => {
    props.onConfirm(
      () => props.onGroupDeleteItems(groups.selectedIds, groups.query),
      {
        title: 'GROUPS_REMOVE_DIALOG_TITLE',
        message: 'GROUPS_REMOVE_DIALOG_DESCRIPTION',
        action: 'ACTION_DELETE_GROUP'
      }
    )
  }

  const headingActions = [{
    show: 'selected',
    label: { id: 'ACTION_DELETE_GROUP', default: 'Delete group(s)' },
    action: onDelete,
    primary: true,
    scopes: [GROUP.DELETE],
    icon: <DeleteIcon />,
    type: 'icon'
  }
  ]
    .filter((a) => skylight.auth.hasScopes(a.scopes))

  const onGroupSelect = (ids) => {
    props.onGroupSelectItems(ids, items)
    if (ids.length === 1) {
      props.onGroupMemberLoadItems({ id: ids[0] })
    }
  }

  const selectAndEdit = (e, item) => {
    e.stopPropagation()
    props.onGroupSelectItems([item.id])
    props.onGroupChangeMode('edit', item)
  }

  return <div className={`dashboard-list group-list ${detailsOpen ? 'details-open' : ''}`}>
    <Loading
      isLoading={groups.isLoading}
      message="LOADING_GROUP_LIST"
      error={groups.error ? EmptyLabel : null}>
      <Table
        showHover
        showCheckboxes
        tableHeaderActions={headingActions.map(a =>
          <ActionButton
            key={a.label.id}
            show={a.show}
            label={<FormattedMessage id={a.label.id} defaultMessage={a.label.default} />}
            icon={a.icon || null}
            primary={a.primary}
            type={a.type}
            disabled={a.disabled}
            onTouchTap={a.action} />
        )}
        tableFooter={<ListFooterStats list={{ ...groups, items, total: items.length }} onUnselect={onGroupSelect} />}
        checkboxIcon={() => <GroupIcon />}
        query={groups.query}
        items={items}
        total={groups.total}
        selectedRows={groups.selectedIds}
        name="contactsGroupList"
        emptyLabel={EmptyLabel}
        onQuery={props.onGroupQuery}
        onRowsSelected={onGroupSelect}>

        <Column
          title={<FormattedMessage id="NAME" defaultMessage="Name" />}
          name="name" />
        <Column
          title={<FormattedMessage id="NUMBER_MEMBERS" defaultMessage="# Members" />}
          name="totalMembers"
          render={item => item.totalMembers || 0} />
        <Column
          key="actions"
          title=""
          className="actions"
          isAction
          render={item => <div className="group-actions list-actions">
            <IconButton
              tooltip={<FormattedMessage id="ACTION_MODIFY" defaultMessage="Modify" />}
              onTouchTap={(e) => selectAndEdit(e, item)}>
              <MaterialIcon icon="mode_edit" />
            </IconButton>
                          </div>} />
      </Table>
    </Loading>
    {skylight.auth.hasScopes([GROUP.CREATE]) && <ListActionButton
      icon={<NewGroupIcon />}
      tooltip={<FormattedMessage id="ACTION_CREATE_GROUP" defaultMessage="New group" />}
      onAction={() => props.onGroupNew({ name: '', description: '' })} />}
  </div>
}

export { GroupList }
