import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Login as LoginComp } from '../App/Login'
import loginActions from '../actions/login'
import appActions from '../actions/app'

const mapStateToProps = (state, props) => ({
  login: state.login,
  auth: state.auth,
  app: state.app,
  dialog: state.dialog
})

const dispatchActions = {
  ...loginActions,
  ...appActions
}

const Login = withRouter(connect(mapStateToProps, dispatchActions)(LoginComp))

export { Login }
