import * as ActionTypes from './types'
import { CONFIG } from 'skylight-common'
import { deleteCookie } from '../lib/cookie'
import skylight from 'skylight-js-sdk'

export const signout = () => async(dispatch) => {
  if (skylight.utils.isFrontline()) {
    redirectFrontlineToSignout(dispatch)
  } else {
    signoutSkylight(dispatch)
  }
}

export const onSignout = () => async(dispatch) => {
  if (skylight.utils.isFrontline()) return onFrontlineLogout(dispatch)
  signoutSkylight(dispatch)
}

export const signoutSkylight = async(dispatch) => {
  dispatch({ type: ActionTypes.USER_SIGNOUT_START })
  try {
    await skylight.auth.signout()
  } catch (_) {}
  dispatch({ type: ActionTypes.USER_SIGNOUT })
  deleteCookie(CONFIG.helpCookie)
}

export const redirectFrontlineToSignout = async(dispatch) => {
  dispatch({ type: ActionTypes.USER_SIGNOUT_START })
  const webUrl = window.origin
  const authUrl = await skylight.getSignoutUrl({
    redirectUri: skylight.utils.resolveUrl(webUrl, '/logout')
  })
  window.location = authUrl
}

export const onFrontlineLogout = async(dispatch) => {
  dispatch({ type: ActionTypes.USER_SIGNOUT })
  deleteCookie(CONFIG.helpCookie)
  await skylight.onSignout()
}

export const onRefreshUser = () => async(dispatch, getState) => {
  try {
    const user = await skylight.user.getById(getState().auth.user.id)
    // See - https://favro.com/card/60fe74a05e7da9c931484a9a/Ups-8021
    // TODO: Remove once /users switches to langauge -> locale.
    dispatch({
      type: ActionTypes.AUTH_USER_REFRESHED,
      user: { ...user, locale: user.locale || user.language }
    })
  } catch (e) {
    dispatch({ type: ActionTypes.AUTH_REFRESH_USER_ERROR })
  }
}

export const clientAppLogin = () => dispatch => {
  dispatch({ type: ActionTypes.CLIENT_APP_LOGIN })
  dispatch(signout())
}

export default {
  signout,
  onSignout,
  onClientAppLogin: clientAppLogin,
  onRefreshUser
}
