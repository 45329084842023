import callSdk from 'skylight-call-sdk'
import skylight from 'skylight-js-sdk'
import { getFileAsArray, getFileFromChunks, takeSnapshot, uploadFile } from './svc'
import { getUserMedia } from '../../../lib/calls'

export const initCall = () => {
  if (!window.RTCPeerConnection) {
    skylight.logger.error('WebRTC is not available')
    return
  }

  const webWebrtc = {
    RTCPeerConnection: window.RTCPeerConnection,
    RTCSessionDescription: window.RTCSessionDescription,
    RTCIceCandidate: window.RTCIceCandidate,
    MediaStreamTrack: window.MediaStreamTrack,
    getUserMedia
  }

  if (!webWebrtc.MediaStreamTrack.getSources) {
    webWebrtc.MediaStreamTrack.getSources = async() => {
      const devices = await navigator.mediaDevices.enumerateDevices()
      devices.forEach(x => {
        return {
          kind: x.kind === 'videoinput' ? 'video' : x.kind,
          facing: 'front',
          deviceId: x.deviceId,
          id: x.deviceId
        }
      })
    }
  }

  const svc = {
    getFileAsArray,
    getFileFromChunks,
    takeSnapshot,
    uploadFile
  }

  window.callSdk = callSdk // DEBUG ONLY. TODO: Remove.
  callSdk.init(webWebrtc, svc)
}
