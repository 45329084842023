import React from 'react'
import { initDrawer } from './drawer'
import { MediaEditorToolbar } from './MediaEditorToolbar'
import { CallZoom } from '../../CallZoom'
// import CrossIcon from 'material-ui/svg-icons/navigation/close'
import './MediaEditor.css'

class MediaEditor extends React.Component {
  onResize = () => {
    this.drawer.redraw(this.drawer.ctx)
  }

  componentDidMount() {
    this.drawer = initDrawer(this.container, {
      color: this.props.call ? this.props.call.ui.sketch.color : undefined,
      item: this.props.item,
      isEditable: this.props.isEditable,
      zoom: this.props.call.ui.sketch.zoom,
      sendAnnotations: this.props.sendAnnotations,
      onZoom: this.onZoomChange
    })
    window.addEventListener('resize', this.onResize)
  }

  // eslint-disable-next-line
  componentWillReceiveProps (nextProps) {
    if (!this.drawer) return
    const sketch = nextProps.call.ui.sketch
    if (this.props.width !== nextProps.width) {
      // Resized from DrawerResizer.
      this.drawer.redraw(this.drawer.ctx)
    }
    if (this.drawer.item !== nextProps.item) {
      this.drawer.item = nextProps.item
    }
    if (this.drawer.ctx && nextProps.call) {
      this.drawer.ctx.drawing.color = nextProps.call.ui.sketch.color
    }

    this.drawer.ctx.gestureMode = sketch.gestureMode
    this.drawer.zoom = sketch.zoom
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
    const item = this.props.item
    if (this.drawer) {
      const uri = await this.drawer.getPreview()
      this.props.sketchCloseItem()
      this.props.updateAttachment(item.id, { file: { ...item.file, uri } })
    }
  }

  onUndo = () => {
    this.props.undoAnnotation()
  }

  onRedo = () => {
    this.props.redoAnnotation()
  }

  onToggleGestureMode = (mode) => {
    this.props.onToggleGestureMode(mode)
  }

  onToggleColor = () => {
    this.props.setSketchShowColorPicker(!this.props.call.ui.sketch.showColorPicker)
  }

  onSetColor = (color) => {
    this.props.setSketchColor(color)
  }

  onZoomChange = (zoom) => {
    if (zoom === 1) {
      this.props.setSketchGestureMode('draw')
    }
    this.props.setSketchZoom(zoom)
  }

  render() {
    const props = this.props
    const { zoom, gestureMode } = props.call.ui.sketch

    return (
      <div className="media-editor">
        <div
          className="drawing-layer"
          ref={container => { this.container = container }} />
        {/* Un-comment to test ensure pixel-perfect zoom/move. */}
        {/* <CrossIcon className='cross' /> */}
        {this.props.isEditable &&
          <MediaEditorToolbar
            sketch={props.call.ui.sketch}
            item={props.item}
            gestureMode={gestureMode}
            zoom={zoom}
            onToggleGestureMode={props.setSketchGestureMode}
            onUndo={this.onUndo}
            onRedo={this.onRedo}
            onToggleColor={this.onToggleColor}
            onSetColor={this.onSetColor} />}
        <CallZoom
          min={1}
          max={4}
          delta={1}
          zoom={zoom}
          onChange={this.onZoomChange} />
      </div>
    )
  }
}

export { MediaEditor }
