import React from 'react'
import { Form, RequiredLabel, Tooltip, TrimmingTextField } from 'skylight-common'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import RaisedButton from 'material-ui/RaisedButton'
import { FormattedMessage } from 'react-intl'
import { GroupMembership } from '../../../../containers/common'
import { USER } from '../../../../svc/scopes'
import skylight from 'skylight-js-sdk'
import { UserImage } from '../../../Common'
import { SUPPORTED_LOCALES } from '../../../../svc/locale'

import './UserDetails.css'

const DEFAULT_LOCALE = 'DEFAULT_LOCALE'

const USER_PROPS = [
  { prop: 'id', disabled: () => true, label: { id: 'UUID', default: 'UUID' } },
  {
    prop: 'username',
    disabled: mode => mode !== 'new',
    label: { id: 'USERNAME', default: 'Username', isRequired: true }
  },
  { prop: 'email', label: { id: 'EMAIL', default: 'Email', isRequired: true } },
  { prop: 'firstName', label: { id: 'FIRSTNAME', default: 'First name' } },
  { prop: 'lastName', label: { id: 'LASTNAME', default: 'Last name' } },
  { prop: 'jobTitle', label: { id: 'JOBTITLE', default: 'Job title' } },
  { prop: 'officePhone', label: { id: 'OFFICE_PHONE', default: 'Office phone' } },
  { prop: 'mobilePhone', label: { id: 'MOBILE_PHONE', default: 'Mobile phone' } },
  { prop: 'location', label: { id: 'ACCOUNT_LOCATION', default: 'Location' } }
]

const isUuid = x => {
  return x.prop === 'id'
}
const NEW_USER_PROPS = USER_PROPS.filter(x => !isUuid(x))
const EDIT_USER_PROPS = USER_PROPS

const EDITABLE_MAP = {
  new: () => true, // Checked on a list level
  edit: (s) => skylight.auth.hasScopes([USER.EDIT])
}

const UserDetails = (props) => {
  const { user } = props
  const { item, mode, errors = {} } = user || { item: props.item }

  const action = EDITABLE_MAP[mode]
  const isEditable = !!action && action(props.scopes)

  const renderGroupMembership = () => {
    return mode === 'new'
      ? null
      : <div className="details-item">
        <span className="title">
          <FormattedMessage id="GROUP_MEMBERSHIP" defaultMessage="Group membership" />
        </span>
        <span className="value">
          <GroupMembership user={user.item} force />
        </span>
        </div>
  }

  const renderProp = (u) => {
    // settings name is required to prevent login/password autocompletion!
    const isDisabled = u.disabled ? u.disabled(mode) : !isEditable

    // Don't visually indicate required fields when they are already disabled.
    let isRequired = !!u.label.isRequired && !isDisabled
    // Simplest way to handle this.
    if (u.prop === 'email' && item.role !== 'admin') {
      isRequired = false
    }

    return (!u.visible || u.visible(mode))
      ? <TrimmingTextField
      className={!isEditable ? 'read-only-field' : ''}
      key={u.prop}
      disabled={isDisabled}
      value={item[u.prop] || ''}
      type={u.type}
      name={u.prop + '_'}
      errorText={errors[u.prop]
        ? <FormattedMessage
          id={`${errors[u.prop]}_${u.label.id}`}
          defaultMessage="Field is required" />
        : null}
      underlineDisabledStyle={{ display: 'none' }}
      floatingLabelText={<RequiredLabel isRequired={isRequired}>
        <FormattedMessage id={u.label.id} defaultMessage={u.label.default} />
      </RequiredLabel>}
      onChange={(e, v) => props.onUserChange(u.prop, v)} />
      : null
  }

  // Hack for the material UI floating label not showing properly if value is set to null/empty string.
  // In this case floating label does not go up and intersects with the default label.
  const onLocaleChange = (newLocale) => {
    if (newLocale === DEFAULT_LOCALE) {
      newLocale = ''
    }
    props.onUserChange('locale', newLocale)
  }

  const renderLanguage = () => {
    const localeValue = item.locale || item.language || DEFAULT_LOCALE
    return <SelectField
      className="dropdown-with-hover"
      disabled={!isEditable}
      floatingLabelText={<FormattedMessage id="LANGUAGE" defaultMessage="Language" />}
      value={localeValue}
      onChange={(e, k, v) => onLocaleChange(v)}>
      <MenuItem value={DEFAULT_LOCALE} primaryText={<FormattedMessage id="DOMAIN_DEFAULT" defaultMessage="Domain default" />} />
      {SUPPORTED_LOCALES.map(x => <MenuItem key={x.value} value={x.value} primaryText={x.title} />)}
    </SelectField>
  }

  const resetPwd = () => {
    props.onResetUserPassword(item)
  }

  const renderResetPwd = () => {
    if (mode !== 'edit') {
      return null
    }

    return <div className="reset-pwd">
      <Tooltip
        tooltipPosition={{
          horizontalPosition: 'right',
          verticalPosition: 'top'
        }}
        tooltip={<FormattedMessage id="RESET_PASSWORD_DESCRIPTION" />}
        className="reset-password">
        <RaisedButton
          className="reset-password-button"
          label={<FormattedMessage id="RESET PASSWORD" defaultMessage="Reset password" />}
          primary
          onTouchTap={resetPwd}
          type="button" />
      </Tooltip>
    </div>
  }

  const PROPS = mode === 'new' ? NEW_USER_PROPS : EDIT_USER_PROPS

  const handleSubmit = () => {
    user.hasChanged && props.onUserSave(user.item)
  }

  // Consider moving out to actions.
  const onUploadAvatar = (item) => {
    if (item.id) {
      props.onUserChange('avatar', item.id)
    } else {
      props.onAvatarUploadError()
    }
  }

  return (
    <div className="user-details">
      <Form onSubmit={handleSubmit}>
        {/* Autocomplete hack for chrome.  */}
        <input type="text" className="autocomplete" />
        <input type="password" className="autocomplete" />

        <UserImage
          src={item.avatar}
          isEditable={!props.callUser}
          onUploadImage={onUploadAvatar} />

        <SelectField
          className={'type-select' + (!isEditable ? ' read-only-field' : '') + ' dropdown-with-hover'}
          floatingLabelText={<RequiredLabel isRequired={isEditable}>
            <FormattedMessage id="ACCOUNT_TYPE" defaultMessage="Account type" />
          </RequiredLabel>}
          disabled={!isEditable}
          errorText={errors.role
            ? <FormattedMessage id="REQUIRED_ACCOUNT_TYPE_ERROR" defaultMessage="Account type is required" />
            : null}
          underlineDisabledStyle={{ display: 'none' }}
          onChange={(e, i, v) => props.onUserChange('role', v)}
          value={item.role}>
          <MenuItem value="user" primaryText={<FormattedMessage id="USER" defaultMessage="User" />} />
          <MenuItem value="manager" primaryText={<FormattedMessage id="MANAGER" defaultMessage="Manager" />} />
          <MenuItem value="admin" primaryText={<FormattedMessage id="ADMIN" defaultMessage="Admin" />} />
        </SelectField>
        {PROPS.map(renderProp)}
        {renderLanguage()}

        {!props.callUser && renderGroupMembership()}
        {renderResetPwd()}
      </Form>
    </div>
  )
}

export { UserDetails }
