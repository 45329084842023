import React from 'react'
import { FormattedMessage } from 'react-intl'
import { GridView, MediaGridItem, UserTitle, groupBySection, isMobile } from 'skylight-common'
import media from '../../../../lib/media'

import './MediaGrid.css'

const DEFAULT_IMAGE = '/assets/img/default.png'

const MediaGrid = props => {
  const { items, selectedIds, query, isLoading, done, total } = props.list

  const onCheck = (v, item) => {
    const ids = Array.isArray(v) ? v : v ? [...selectedIds, item.id] : selectedIds.filter(id => id !== item.id)

    props.onMediaSelectItems(ids, items)
  }

  const onSelect = (item, e) => {
    return media.onSelect(item, e, props.list, props.onMediaSelectItems)
  }

  const onOpenPreview = (item, e) => {
    return media.onPreview(item, e, props.onPreviewMediaItem)
  }

  return (
    <div className="media-grid">
      <GridView
        {...groupBySection(props.columns, query.sortBy)}
        selectedIds={selectedIds}
        query={query}
        isLoading={isLoading}
        onSelectItems={props.onMediaSelectItems}
        items={items}
        total={total}
        done={done}
        heading={props.heading}
        renderItem={MediaGridItem}
        renderTooltipProp={(x, p) =>
          p === 'owner'
            ? (
            <UserTitle user={x[p]} renderError={() => <FormattedMessage id="NO_OWNER" defaultMessage="No owner" />} />
              )
            : null
        }
        cols={selectedIds.length === 1 ? 4 : 6}
        useFixedHeight={true}
        hideCheckbox={true}
        gridFooter={props.listFooter}
        defaultPreview={DEFAULT_IMAGE}
        emptyLabel={<FormattedMessage id="EMPTY_MEDIA_LIST" defaultMessage="No media items." />}
        onQuery={props.onMediaQuery}
        onSelect={onSelect}
        isDoubleClick={!isMobile()} // enable double click only on web
        onOpenPreview={onOpenPreview}
        onCheck={onCheck}/>
    </div>
  )
}

export { MediaGrid }
