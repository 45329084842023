import React from 'react'
import VersionList from './VersionList/VersionList'

const Versions = props => {
  return <div className="versions">
    <VersionList />
  </div>
}

export { Versions }
