import * as ActionTypes from '../../../actions/types'

const initialState = {}

const devices = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SESSION_EVENT_DEVICE_ADD:
      return {
        ...state,
        [action.device.deviceId]: action.device
      }
    default:
      return state
  }
}

export default devices
