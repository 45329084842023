import React from 'react'
import IconButton from 'material-ui/IconButton'
import UndoIcon from 'material-ui/svg-icons/content/undo'
import RedoIcon from 'material-ui/svg-icons/content/redo'
import PencilIcon from 'material-ui/svg-icons/image/edit'
import PanIcon from 'material-ui/svg-icons/action/pan-tool'
import callSdk from 'skylight-call-sdk'

class MediaEditorToolbar extends React.Component {
  render() {
    const {
      sketch, item, gestureMode, zoom,
      onUndo, onRedo, onToggleColor, onSetColor, onToggleGestureMode
    } = this.props

    const actions = [
      {
        title: 'UNDO',
        name: 'undo',
        icon: <UndoIcon />,
        action: onUndo,
        disabled: item.localAnnotations.length === 0
      },
      {
        title: 'REDO',
        name: 'redo',
        icon: <RedoIcon />,
        action: onRedo,
        disabled: item.redoAnnotations.length === 0
      },
      {
        title: 'COLOR',
        name: 'color',
        icon: <PencilIcon />,
        className: gestureMode === callSdk.constants.GESTURE_MODES.DRAW ? 'selected' : '',
        action: gestureMode === callSdk.constants.GESTURE_MODES.PAN
          ? () => onToggleGestureMode(callSdk.constants.GESTURE_MODES.DRAW)
          : onToggleColor
      },
      {
        title: 'PAN',
        name: 'pan',
        icon: <PanIcon />,
        className: gestureMode === callSdk.constants.GESTURE_MODES.PAN ? 'selected' : '',
        action: () => onToggleGestureMode(callSdk.constants.GESTURE_MODES.PAN),
        disabled: zoom === 1
      }
    ]

    const SKETCH_PALETTE = callSdk.constants.SKETCH_PALETTE
    const colors = Object.keys(SKETCH_PALETTE).map(key => ({ name: key, color: SKETCH_PALETTE[key] }))
    return (
      <div className="media-editor-toolbar">
        {sketch.showColorPicker && <div className="color-picker">
          {colors.map(x =>
            <div
              key={x.name}
              className={`color ${x.color === sketch.color ? 'selected' : ''}`}
              onClick={() => onSetColor(x.color)}
              style={{ backgroundColor: x.color }} />
          )}
        </div>}
        <div className="toolbar">
          {actions.map(x => <div
            key={x.name}
            className={`toolbar-button ${x.disabled ? 'disabled' : ''} ${x.className || ''}`}>
              <IconButton disabled={x.disabled} onTouchTap={x.action}>{x.icon}</IconButton>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export { MediaEditorToolbar }
