import React from 'react'
import { DialogTitle, Form } from 'skylight-common'
import { Dialog } from 'material-ui'
import { FormattedMessage } from 'react-intl'
import { AccountPassword } from '../../../../Home/Profile/Settings/AccountPassword'
import CaptchaBadge from '../../../../Common/Captcha/CaptchaBadge'
import { getCaptchaToken } from '../../../../Common/Captcha/captcha'
import get from 'lodash/get'
import LargeRaisedButton from '../../../../Common/LargeRaisedButton/LargeRaisedButton'
import {
  GENERIC_PASSWORD_REQUIREMENTS,
  GENERIC_PASSWORD_REQUIREMENTS_NAMES,
  MAX_PASSWORD_LENGTH, REQUIRED_PASSWORD_FIELDS
} from '../../../../../svc/login'

import './ResetPasswordDialog.css'

export class ResetPasswordDialog extends React.Component {
  state = {
    maxLimitError: null
  }

  onClose = () => {
    this.props.onHideDialog(this.props.dialogType)
    this.props.onClearResetPasswordForm()
    this.props.onProfileGenericErrorsUpdate([])
  }

  onFieldChange = (e, v) => {
    const { name } = e.target
    this.props.onProfileFieldChanged(name, v, 'passwordForm')
  }

  onResetPassword = async e => {
    e.preventDefault()
    e.stopPropagation()

    const { genericErrors, passwordForm } = this.props.settings || {}

    if (genericErrors.length) return

    if (get(passwordForm, REQUIRED_PASSWORD_FIELDS.NEW_PASSWORD, '').length > MAX_PASSWORD_LENGTH) {
      // clear all fields
      Object.values(REQUIRED_PASSWORD_FIELDS).forEach(x => this.props.onProfileFieldChanged(x, '', 'passwordForm'))
      this.setState({ maxLimitError: { id: 'PWD_DOES_NOT_MEET_SECURITY_REQ', defaultMessage: 'Password does not meet security requirements.' } })
      return
    }

    const captchaToken = await getCaptchaToken()
    this.props.onResetPassword({ ...passwordForm, captchaToken })
  }

  onBlur = e => {
    const { passwordForm } = this.props.settings || {}

    const { name } = e.target
    if (name === REQUIRED_PASSWORD_FIELDS.NEW_PASSWORD) {
      const genericErrors = GENERIC_PASSWORD_REQUIREMENTS
        .filter(x => x.validate({ password: get(passwordForm, REQUIRED_PASSWORD_FIELDS.NEW_PASSWORD, ''), username: get(this.props, 'auth.user.username', '') }))
        .map(x => x.name)
      this.props.onProfileGenericErrorsUpdate(genericErrors)
    }
  }

  onFocus = e => {
    const { name } = e.target
    const { genericErrors } = this.props.settings

    if (name === REQUIRED_PASSWORD_FIELDS.NEW_PASSWORD) {
      this.setState({ maxLimitError: null })
      if (genericErrors.includes(GENERIC_PASSWORD_REQUIREMENTS_NAMES.SAME_AS_PREVIOUS)) {
        this.props.onProfileGenericErrorsUpdate([])
      }
    }

    this.props.onProfileErrorsDelete(name)
  }

  render() {
    const { dialogType, dialog, settings } = this.props

    const { open = false } = dialog[dialogType] || {}

    const { errors = {}, genericErrors, passwordForm } = settings || {}

    return (
      <Dialog
        title={<DialogTitle id="CHANGE_PASSWORD" defaultMessage="Change password" />}
        open={open}
        overlayClassName="reset-password-overlay"
        className="dialog-modal reset-password-dialog"
        contentClassName="reset-password-content"
        bodyClassName="reset-password-body"
        autoScrollBodyContent
        contentStyle={{ maxWidth: '650px' }}
        onRequestClose={this.onClose}>
        <Form className="reset-password-body-container" onSubmit={this.onResetPassword}>
          <AccountPassword
            maxLimitError={this.state.maxLimitError}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            errors={errors}
            form={passwordForm}
            onChange={this.onFieldChange}
            genericErrors={genericErrors} />
          <div className="reset-password-actions">
            <LargeRaisedButton
              primary
              className="update-button"
              key="update-button"
              label={<FormattedMessage id="CHANGE_PASSWORD" defaultMessage="Change password" />}
              type="submit" />
          </div>
          <div className="captcha-badge-container">
            <CaptchaBadge />
          </div>
        </Form>
      </Dialog>
    )
  }
}
