import React from 'react'
import { connect } from 'react-redux'
import {
  ActionButton,
  ListActionButton,
  ListFooterStats,
  GridHeader,
  Column,
  MaterialIcon,
  Loading,
  CursorTooltip
} from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import skylight from 'skylight-js-sdk'
import classNames from 'classnames'
import IconButton from 'material-ui/IconButton'
import GroupIcon from 'material-ui/svg-icons/action/supervisor-account'
import NewGroupIcon from 'material-ui/svg-icons/social/group-add'
import { GROUP } from '../../../../../svc/scopes'
import { filterGroups } from '../../../../../lib/contact'
import { TableNextGen } from '../../../../Common/NextGen/TableNextGen'
import contactActions from '../../../../../actions/contact'
import dialogActions from '../../../../../actions/dialog'
import RemoveIcon from '../../../../../App/Common/Icons/removeTrash'
import GroupTextFilter from '../GroupFilter/GroupTextFilter'

import './GroupList.css'

const EmptyLabel =
  <FormattedMessage id="EMPTY_GROUP_LIST" defaultMessage="You have not created any groups." />

const GroupList = (props) => {
  const { groups } = props
  const items = filterGroups(groups.items, groups.query)
  const detailsOpen = groups.selectedIds.length === 1
  const selectedAny = groups.selectedIds.length > 0

  const onDelete = () => {
    props.onConfirm(
      () => props.onGroupDeleteItems(groups.selectedIds, groups.query),
      {
        title: 'GROUPS_REMOVE_DIALOG_TITLE',
        message: 'GROUPS_REMOVE_DIALOG_DESCRIPTION',
        action: 'ACTION_DELETE_GROUP'
      }
    )
  }

  const onGroupSelect = (ids) => {
    props.onGroupSelectItems(ids, items)
    if (ids.length === 1) {
      props.onGroupMemberLoadItems({ id: ids[0] })
    }
  }

  const selectAndEdit = (e, item) => {
    e.stopPropagation()
    onGroupSelect([item.id])
    props.onGroupChangeMode('edit', item)
  }

  const getHeaderActions = () => [<GroupTextFilter key="filter" />]

  const renderItemActions = item => (
    <div className="group-actions list-actions">
      <CursorTooltip tooltip={<FormattedMessage id="ACTION_MODIFY" defaultMessage="Modify" />}>
        <IconButton
          // tooltip={<FormattedMessage id="ACTION_MODIFY" defaultMessage="Modify" />}
          onTouchTap={(e) => selectAndEdit(e, item)}>
          <MaterialIcon icon="mode_edit" />
        </IconButton>
      </CursorTooltip>
    </div>
  )

  const renderListActionButton = () => {
    if (!skylight.auth.hasScopes([GROUP.CREATE])) {
      return null
    }

    return <ListActionButton
      className={classNames('list-action-button', { 'item-selected': selectedAny })}
      icon={<NewGroupIcon />}
      tooltip={<FormattedMessage id="ACTION_CREATE_GROUP" defaultMessage="New group" />}
      onAction={() => props.onGroupNew({ name: '', description: '' })} />
  }

  const getTitle = () => {
    const oneAccount = groups.total === 1
    return <div className="group-list-title">
      <span className="group-list-items-number">{groups.total}</span>
      <FormattedMessage id={`GROUP${oneAccount ? '' : 'S'}`} defaultMessage={`group account${oneAccount ? '' : 's'}`} />
    </div>
  }

  const footerActions = [
    {
      show: 'selected',
      label: { id: 'ACTION_DELETE_GROUP', default: 'Delete group(s)' },
      action: onDelete,
      primary: true,
      scopes: [GROUP.DELETE],
      icon: <RemoveIcon className="delete-icon" />,
      type: 'icon'
    }
  ]

  const getFooterActions = () => {
    const filtered = footerActions
      .filter(a => skylight.auth.hasScopes(a.scopes))
      .map(a => <CursorTooltip key={a.label.id} tooltip={<FormattedMessage id={a.label.id} defaultMessage={a.label.default} />}>
        <ActionButton
          key={a.label.id}
          className={a.className || ''}
          show={a.show}
          label={<FormattedMessage id={a.label.id} defaultMessage={a.label.default} />}
          onTouchTap={a.action || null}
          disabled={a.disabled}
          type={a.type}
          icon={a.icon || null}
          primary={a.primary}
          menuItems={a.children ? a.children : null}
          popoverOptions={a.popoverOptions} />
        </CursorTooltip>
      )

    return <div className="footer-actions">{filtered}</div>
  }

  const getTableFooter = () => {
    if (!groups.selectedIds.length) {
      return null
    }

    return <ListFooterStats
      list={{ ...groups, items, total: items.length }}
      onUnselect={onGroupSelect}
      actions={getFooterActions()} />
  }

  return <div className={`dashboard-list group-list-next-gen ${detailsOpen ? 'details-open' : ''}`}>
    <GridHeader title={getTitle()} actions={getHeaderActions()} />
    <Loading
      isLoading={groups.isLoading}
      message="LOADING_GROUP_LIST"
      error={groups.error ? EmptyLabel : null}>
      <TableNextGen
        showHover
        showCheckboxes
        tableFooter={getTableFooter()}
        checkboxIcon={() => <GroupIcon />}
        query={groups.query}
        items={items}
        total={groups.total}
        selectedRows={groups.selectedIds}
        name="groupList"
        emptyLabel={EmptyLabel}
        onQuery={props.onGroupQuery}
        onRowsSelected={onGroupSelect}>
        <Column
          title={<FormattedMessage id="NAME" defaultMessage="Name" />}
          name="name" />
        <Column
          title={<FormattedMessage id="NUMBER_MEMBERS" defaultMessage="# Members" />}
          name="totalMembers"
          render={item => item.totalMembers || 0} />
        <Column
          key="actions"
          title=""
          className="actions"
          isAction
          render={renderItemActions} />
      </TableNextGen>
    </Loading>
    {renderListActionButton()}
  </div>
}

export { GroupList }

const groups = (state, props) => ({
  groups: state.contact.groups,
  auth: state.auth
})

const groupsActions = {
  ...contactActions,
  onConfirm: dialogActions.onConfirm
}

export default connect(groups, groupsActions)(GroupList)
