import React from 'react'
import { UserActionMenu } from './UserActionMenu'
import { FooterActionButtons } from '../../../Common'
import { isPhone } from 'skylight-common'
import './UserActionButtons.css'

const UserActionButtons = (props) => {
  const { mode, isLoading, hasChanged } = props.user

  if (mode === 'view') {
    return null
  }

  const mobile = isPhone()
  const className = [
    'user-action-buttons',
    hasChanged ? 'updated' : '',
    mobile ? 'mobile' : ''
  ].join(' ')

  const onCancel = (e) => {
    e.stopPropagation()
    props.onUserCancel()

    if (mode === 'new') {
      props.onCloseDetails()
    }
  }

  const onSave = (e) => {
    e.stopPropagation()
    props.onUserSave(props.user.item)
  }

  return (
    <div className={className}>
      {mobile && <UserActionMenu {...props} />}
      {hasChanged && <FooterActionButtons
        isCancelDisabled={isLoading}
        isSubmitDisabled={isLoading || !hasChanged}
        onCancel={onCancel}
        onSave={onSave} />}
    </div>
  )
}

export { UserActionButtons }
