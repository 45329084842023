import React from 'react'
import { TextFilter } from 'skylight-common'
import { ApplicationFilter } from '.'

const VALUE = 'title'

const ApplicationTextFilter = props => {
  const value = props.query.filter && props.query.filter.title ? props.query.filter.title : ''
  const params = { preventPathSave: props.preventPathSave }

  const onChange = (v) => {
    const value = v.trim()
    const filter = { ...props.query.filter, [VALUE]: value }
    props.onWorkflowFilterUpdated(filter, params)
  }

  return <TextFilter
    value={value}
    className="application-text-filter"
    title="SEARCH"
    iconFilter={<ApplicationFilter
      {...props}
      onWorkflowFilterUpdated={f => props.onWorkflowFilterUpdated(f, params)}
      onWorkflowFiltersUpdated={f => props.onWorkflowFiltersUpdated(f, params)}
      onFilterRemoved={t => props.onWorkflowFilterRemoved(t, params)} />}
    onChange={onChange} />
}

export { ApplicationTextFilter }
