import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const cardFlow = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M15,7 L15,2 L20,7 L15,7 Z M10,17 L8,17 L8,10 L5,10 L9,6 L13,10 L10,10 L10,17 L10,17 Z M15,20 L11,16 L14,16 L14,9 L16,9 L16,16 L19,16 L15,20 Z M16.414,1 L5,1 C3.897,1 3,1.898 3,3 L3,21 C3,22.103 3.897,23 5,23 L19,23 C20.103,23 21,22.103 21,21 L21,5.586 L16.414,1 Z" id="path-1"></path>
    </SvgIcon>
  )
}

export default cardFlow
