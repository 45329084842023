import skylight from 'skylight-js-sdk'

const getDeviceType = (x) => x && x.device ? x.device.type : 'n/a'

const transformCall = call => {
  const caller = call.participants.find(x => x.creator)
  const receiver = call.participants.find(x => !x.creator) // Bad Alexey, very bad.
  let duration = Math.ceil((new Date(call.endTime) - new Date(call.startTime)) / 1000)
  if (isNaN(duration) || duration < 0) {
    duration = 0
  }

  return {
    id: call.roomId,
    status: call.roomStatus,
    callerId: caller.userId,
    callerDevice: getDeviceType(caller.session),
    callerData: caller,
    receiverId: receiver.userId,
    receiverDevice: getDeviceType(receiver.session),
    receiverData: receiver,
    date: call.createdAt, // SHOULD BE USING startTime but it's null
    duration // : call.callLengthSeconds
  }
}

// const queryFilter = (call, query) => {
//   const {filter} = query
//   if (!filter || !Object.keys(filter).length) {
//     return true
//   }

//   if (filter.timestamp) {
//     const fromTimestamp = new Date(filter.timestamp.from)
//     const toTimestamp = new Date(filter.timestamp.to)
//     const callTimestamp = new Date(call.date)
//     if (filter.timestamp.from && callTimestamp < fromTimestamp) {
//       return false
//     }

//     if (filter.timestamp.to && callTimestamp > toTimestamp) {
//       return false
//     }
//   }

//   if (Array.isArray(filter.callerId) && filter.callerId.length) {
//     if (!filter.callerId.includes(call.callerId)) {
//       return false
//     }
//   }

//   if (Array.isArray(filter.callerDevice) && filter.callerDevice.length) {
//     if (!filter.callerDevice.includes(call.callerDevice)) {
//       return false
//     }
//   }

//   if (filter.id && !call.id.includes(filter.id)) {
//     return false
//   }

//   if (filter.status && filter.status.length) {
//     return filter.status.includes(call.status)
//   }

//   return true
// }

export const getCalls = async(query, ctx) => {
  console.log('query', query)

  const roomQuery = {
    sort: `${query.sortAsc ? '' : '-'}${query.sortBy}`,
    limit: query.limit
  }

  const filter = query.filter
  if (filter) {
    if (filter.id) {
      if (query.skip) return []
      let foundById = null
      try {
        foundById = await skylight.analytics.getRoomById(filter.id)
      } catch (e) {
        if (e.status !== 404) throw e
      }

      return foundById ? [transformCall(foundById)] : []
    }

    const timeRange = filter.timestamp
    if (timeRange) {
      if (timeRange.from) roomQuery.startTime = timeRange.from// .toISODate()
      if (timeRange.to) roomQuery.endTime = timeRange.to// .toISODate()
    }

    if (filter.callerId && filter.callerId.length > 0) {
      roomQuery.participantUserIds = filter.callerId.join(',')
    }

    if (filter.callerDevice && filter.callerDevice.length > 0) {
      roomQuery.deviceTypes = filter.callerDevice.join(',')
    }
  }

  if (query.skip && query.nextCursor) {
    roomQuery.nextCursor = query.nextCursor
  }
  const callData = await skylight.analytics.getRooms(roomQuery)

  const calls = callData.data
  const res = calls.map(transformCall)

  return { data: res, page: callData.page }
}

export const getCall = async(id) => {
  const call = await skylight.analytics.getRoomById(id)
  return transformCall(call)
}

export const exportCalls = (calls, filter) => {
}
