import React from 'react'
import CircularProgress from 'material-ui/CircularProgress'
import './MediaPreviewLoader.css'

const MediaPreviewLoader = ({ isFullScreen }) => {
  return (
    <div className="media-preview-loader">
      <CircularProgress size={55} thickness={6} />
    </div>
  )
}

export { MediaPreviewLoader }
