import React from 'react'
import { FormattedMessage } from 'react-intl'

const NotFound = (props) => {
  return <div className="not-found">
      <span><FormattedMessage id="NOT_FOUND" defaultMessage="Page not found" /></span>
    </div>
}

export { NotFound }
