import * as ActionTypes from './types'
import { createFilterActions, createListActions, createItemActions } from 'skylight-common'
import skylight from 'skylight-js-sdk'
import { push } from 'react-router-redux'
import {
  DEFAULT_EVENTS_QUERY,
  DEFAULT_CALLS_QUERY,
  DEFAULT_CALL_EVENT_QUERY,
  DEFAULT_SSRC_VIDEO_SEND_QUERY,
  DEFAULT_BWE_FOR_VIDEO_QUERY
} from '../lib/activity'
import * as activities from '../svc/activities'

const getEvent = query => (event, index) => {
  // ensure every event has a unique id
  event.id = event.id || `${query.skip}.${index}`

  // consolidate 'name' and 'eventType'
  event.eventType = event.eventType || event.name

  // unwind metadata to avoid nesting
  if (event.metadata) {
    for (const prop in event.metadata) {
      if (event.metadata.hasOwnProperty(prop)) {
        event[prop] = event.metadata[prop]
      }
    }
  }

  return event
}

const EVENT_LIST_ACTIONS = createListActions({
  prefix: ActionTypes.PREFIX_ACTIVITY.toUpperCase(),
  handler: ActionTypes.PREFIX_ACTIVITY,
  onGetList: s => s.activity.event.list,
  onLoad: async(query) => {
    const result = await activities.getActivities(query)

    let events = result.events || []
    events = events.map(getEvent(query))
    return events
  }
})

const EVENT_FILTER_ACTIONS = createFilterActions({
  baseUrl: '/activities',
  redirect: false,
  selector: state => state.activity.event.list.query,
  listPrefix: ActionTypes.PREFIX_LIST_ACTIVITY,
  actionPrefix: ActionTypes.PREFIX_ACTIVITY,
  DEFAULT_QUERY: DEFAULT_EVENTS_QUERY,
  loadItems: EVENT_LIST_ACTIONS.onActivityLoadItems,
  push
})

const CALL_LIST_ACTIONS = createListActions({
  prefix: ActionTypes.PREFIX_CALL.toUpperCase(),
  handler: ActionTypes.PREFIX_CALL,
  onGetList: s => s.activity.call.list,
  onLoad: activities.getCalls,
  DEFAULT_QUERY: DEFAULT_CALLS_QUERY
})

const CALL_FILTER_ACTIONS = createFilterActions({
  baseUrl: '/activities',
  redirect: false,
  selector: state => state.activity.call.list.query,
  listPrefix: ActionTypes.PREFIX_LIST_ACTIVITY_CALL,
  actionPrefix: ActionTypes.PREFIX_CALL,
  DEFAULT_QUERY: DEFAULT_CALLS_QUERY,
  loadItems: CALL_LIST_ACTIONS.onCallLoadItems,
  push
})

const CALL_ITEM_ACTIONS = createItemActions({
  prefix: ActionTypes.PREFIX_CALL.toUpperCase(),
  handler: ActionTypes.PREFIX_CALL,
  onGetList: s => s.activity.call.list,
  onSave: () => {},
  onLoad: activities.getCall,
  onLoadItems: CALL_LIST_ACTIONS.onCallLoadItems
})

const CALL_EVENT_LIST_ACTIONS = createListActions({
  prefix: ActionTypes.PREFIX_CALL_EVENT.toUpperCase(),
  handler: ActionTypes.PREFIX_CALL_EVENT,
  onGetList: s => s.activity.call.event.list,
  onLoad: activities.getCallEvents
})

const CALL_EVENT_FILTER_ACTIONS = createFilterActions({
  baseUrl: '/activities',
  redirect: false,
  selector: state => state.activity.call.event.list.query,
  listPrefix: ActionTypes.PREFIX_LIST_ACTIVITY_CALL_EVENT,
  actionPrefix: ActionTypes.PREFIX_CALL_EVENT,
  DEFAULT_QUERY: DEFAULT_CALL_EVENT_QUERY,
  loadItems: CALL_EVENT_LIST_ACTIONS.onCallEventLoadItems,
  push
})

const CALL_SSRC_VIDEO_SEND_REPORT_ACTIONS = createListActions({
  prefix: ActionTypes.PREFIX_CALL_SSRC_VIDEO_SEND.toUpperCase(),
  handler: ActionTypes.PREFIX_CALL_SSRC_VIDEO_SEND,
  onGetList: s => s.activity.call.ssrcVideoSend.list,
  onLoad: activities.getSsrcVideoSendReport
})

const CALL_SSRC_VIDEO_SEND_REPORT_FILTER_ACTIONS = createFilterActions({
  baseUrl: '/activities',
  redirect: false,
  selector: state => state.activity.call.ssrcVideoSend.list.query,
  listPrefix: ActionTypes.PREFIX_LIST_ACTIVITY_CALL_SSRC_VIDEO_SEND,
  actionPrefix: ActionTypes.PREFIX_CALL_SSRC_VIDEO_SEND,
  DEFAULT_QUERY: DEFAULT_SSRC_VIDEO_SEND_QUERY,
  loadItems: CALL_SSRC_VIDEO_SEND_REPORT_ACTIONS.onCallSsrcVideoSendLoadItems,
  push
})

const CALL_BWE_FOR_VIDEO_REPORT_ACTIONS = createListActions({
  prefix: ActionTypes.PREFIX_CALL_BWE_FOR_VIDEO.toUpperCase(),
  handler: ActionTypes.PREFIX_CALL_BWE_FOR_VIDEO,
  onGetList: s => s.activity.call.bweForVideo.list,
  onLoad: activities.getBweForVideoReport
})

const CALL_BWE_FOR_VIDEO_REPORT_FILTER_ACTIONS = createFilterActions({
  baseUrl: '/activities',
  redirect: false,
  selector: state => state.activity.call.bweForVideo.list.query,
  listPrefix: ActionTypes.PREFIX_LIST_ACTIVITY_CALL_BWE_FOR_VIDEO,
  actionPrefix: ActionTypes.PREFIX_CALL_BWE_FOR_VIDEO,
  DEFAULT_QUERY: DEFAULT_BWE_FOR_VIDEO_QUERY,
  loadItems: CALL_BWE_FOR_VIDEO_REPORT_ACTIONS.onCallBweForVideoLoadItems,
  push
})

const deleteActivities = () => async dispatch => {
  dispatch({ type: ActionTypes.ACTIVITY_LIST_DELETE })
  try {
    await skylight.event.delete()
    dispatch({ type: ActionTypes.ACTIVITY_LIST_DELETE_SUCCESS })
  } catch (e) {
    dispatch({ type: ActionTypes.PREFIX_LIST_ACTIVITY + ActionTypes.LIST_REMOVE_ERROR })
  }
}

export default {
  ...EVENT_LIST_ACTIONS,
  ...EVENT_FILTER_ACTIONS,
  onDeleteActivities: deleteActivities,
  ...CALL_LIST_ACTIONS,
  ...CALL_FILTER_ACTIONS,
  ...CALL_ITEM_ACTIONS,
  ...CALL_EVENT_LIST_ACTIONS,
  ...CALL_EVENT_FILTER_ACTIONS,
  ...CALL_SSRC_VIDEO_SEND_REPORT_ACTIONS,
  ...CALL_SSRC_VIDEO_SEND_REPORT_FILTER_ACTIONS,
  ...CALL_BWE_FOR_VIDEO_REPORT_ACTIONS,
  ...CALL_BWE_FOR_VIDEO_REPORT_FILTER_ACTIONS
}
