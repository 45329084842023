import React from 'react'
import skylight from 'skylight-js-sdk'
import { FormattedMessage } from 'react-intl'
import {
  ActionButton, ListActionButton, ListFooterStats,
  Table, Column,
  MaterialIcon, Loading, UserTitle
  , DeleteIcon
} from 'skylight-common'

import NewIcon from 'material-ui/svg-icons/content/add-box'

import './TokenList.css'

const filterByName = (x, q) => {
  if (Array.isArray(q.filter.name)) { // Consequence of SKY-5489
    skylight.logger.warn('Filter has been broken. Resetting it in storage')
    skylight.storage.removeItem('Filter-/admin/tokens')
    window.location.reload()
  }

  return !q.filter.name || q.filter.name
    .toLowerCase()
    .split(',')
    .filter(x => !!x.trim())
    .some(n => x.name && x.name.toLowerCase().includes(n.trim()))
}

const EmptyLabel = <FormattedMessage id="EMPTY_TOKEN_LIST" defaultMessage="There are no tokens." />

const TokenList = (props) => {
  const { list } = props
  const detailsOpen = list.selectedIds.length === 1

  const headingActions = [
    <ActionButton
      key="token-remove-action"
      show="selected"
      icon={<DeleteIcon/>}
      label={<FormattedMessage id="TOKEN_REMOVE_ACTION" defaultMessage="Remove" />}
      onTouchTap={() => props.onConfirm(
        () => props.onApiTokenDeleteItems(list.selectedIds), {
          title: 'TOKEN_REMOVE_DIALOG_TITLE',
          message: 'TOKEN_REMOVE_DIALOG_DESCRIPTION'
        }
      ) }
      primary={true} />
  ]

  return (
    <div className={`token-list dashboard-list ${detailsOpen ? 'details-open' : ''}`}>
      <Loading
        isLoading={list.isLoading && list.total === 0}
        message="LOADING_TOKEN_LIST"
        error={list.error ? EmptyLabel : null}>
        <Table
          tableHeaderActions={headingActions}
          showCheckboxes={true}
          checkboxIcon={() => <MaterialIcon icon="vpn_key" />}
          showHover={true}
          query={list.query}
          items={list.items.filter(x => filterByName(x, list.query))}
          total={list.total}
          selectedRows={list.selectedIds}
          name="tokenList"
          emptyLabel={EmptyLabel}
          onQuery={(query) => props.onApiTokenQuery(query)}
          title={<FormattedMessage id="TOKEN_LIST" defaultMessage="Manage API Credentials" />}
          tableFooter={list.selectedIds.length > 0 && <ListFooterStats list={list} onUnselect={props.onSelectTokens} />}
          onRowsSelected={props.onApiTokenSelectItems}>
            <Column
              name="name"
              title={<FormattedMessage id="NAME" defaultMessage="Name" />} />

            <Column
              name="id"
              sortable={false}
              title={<FormattedMessage id="INTEGRATION_ID" defaultMessage="Integration ID" />} />

            <Column
              name="creator"
              sortable={false}
              title={<FormattedMessage id="CREATOR" defaultMessage="Creator" />}
              render={item => <UserTitle user={item.creator} />} />

            <Column
              name="description"
              sortable={false}
              title={<FormattedMessage id="DESCRIPTION" defaultMessage="Description" />} />
        </Table>
      </Loading>
      <ListActionButton
        icon={<NewIcon/>}
        tooltip={<FormattedMessage id="TOKEN_ADD_ACTION" defaultMessage="New" />}
        onAction={() => {
          props.onApiTokenNew()
          props.onShowAddTokenDialog(list.query)
        }} />
    </div>
  )
}

export { TokenList }
