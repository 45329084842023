import skylight from 'skylight-js-sdk'
import { VERSION_STAGES } from '../../lib/application/version'
import { ListQueryBuilder } from './listQueryBuilder'

const isItemFiltered = (item, filters) => {
  return (filters || []).find(filter => !filter(item))
}

export const getVersions = async(applicationId, query) => {
  const { createdBy, createdAt, modifiedAt } = query.filter

  const versionQuery = new ListQueryBuilder({
    upgrades: 'include',
    limit: query.limit,
    sort: `${query.sortAsc ? '' : '-'}${query.sortBy}`
  })
    .byCreatedBy(createdBy)
    .byCreatedAt(createdAt)
    .byModifiedAt(modifiedAt)
    .buildQuery()

  if (query.nextCursor) {
    versionQuery.nextCursor = query.nextCursor
  }

  const versions = await skylight.we20.version.list(applicationId, versionQuery)
  return {
    data: versions.data,
    page: versions.page
  }
}
// versions is a list of versions
export const processVersions = (versions, queryVersion) => {
  const byVersion = x => {
    return !queryVersion || x.version === +queryVersion
  }
  const filters = [byVersion]
  const lastPublishedVersion = versions
    .filter(x => x.stage === VERSION_STAGES.PUBLISHED)
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0]

  const lastDraftVersion = versions
    .filter(x => x.stage === VERSION_STAGES.DRAFT)
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0]

  const sorted = [...versions]
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    .filter(byVersion)

  const lastVersionHasUpgrade = !!sorted.at(-1)?.upgrade
  const draftVersionBeforeErroredUpgrade = lastVersionHasUpgrade
    ? sorted
      .reverse()
      .find(x => x.stage === VERSION_STAGES.DRAFT && !x?.upgrade)?.version
    : -1
  return versions.reduce((acc, x, i) => {
    x.id = x.version
    x.originalStage = x.stage
    if ((lastPublishedVersion &&
      x.stage === VERSION_STAGES.PUBLISHED &&
      x.version !== lastPublishedVersion.version) || (
      lastDraftVersion &&
        x.stage === VERSION_STAGES.DRAFT &&
        x.version !== lastDraftVersion.version
    )) {
      x.stage = VERSION_STAGES.INACTIVE
    }
    if (x.upgrade?.status === 'created') {
      x.stage = VERSION_STAGES.UPGRADE_PENDING
    }
    if (draftVersionBeforeErroredUpgrade === x.version) {
      x.stage = VERSION_STAGES.DRAFT
    }
    if (!isItemFiltered(x, filters)) {
      acc.push(x)
    }

    return acc
  }, [])
}

export const getLastPublishedVersion = async applicationId => {
  const query = {
    limit: 50,
    sort: '-version'
  }

  do {
    try {
      const { data, page } = await skylight.we20.version.list(applicationId, query)
      const foundVersion = data.find(x => x.stage === VERSION_STAGES.PUBLISHED)
      if (foundVersion) {
        return foundVersion
      }

      query.nextCursor = page.nextCursor
    } catch (e) {
      return null
    }
  } while (query.nextCursor)
}
