import React from 'react'
import MenuItem from 'material-ui/MenuItem'
import { CalendarClock, ChartAreaSplain } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import { DetailsMenu } from '../DetailsMenu'

import './CallActionMenu.css'

const CallActionMenu = props => {
  const navigateTo = page => {
    const pathname = props.location.pathname
    const callId = props.item.original.id

    props.history.push(`${pathname}/${callId}/${page}`)
  }

  return <DetailsMenu
    className="call-action-menu">
    <MenuItem
      leftIcon={<CalendarClock />}
      primaryText={<FormattedMessage id="CALL_EVENTS" defaultMessage="Call events" />}
      onTouchTap={() => navigateTo('events')} />
    <MenuItem
      leftIcon={<ChartAreaSplain />}
      primaryText={<FormattedMessage id="CALL_REPORT" defaultMessage="Call reports" />}
      onTouchTap={() => navigateTo('reports')} />
  </DetailsMenu>
}

export { CallActionMenu }
