import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import classNames from 'classnames'

import { NotFound } from './NotFound/NotFound'
// See: https://upskill.atlassian.net/browse/SKY-1060
// Without enabling allow-scripts on iframe this caching doesn't work,
// but introduces potential issues.
// import {EyeSightCache} from './Cache/EyeSightCache'
import Logout from '../../containers/Logout'
import { LeftNav, TopBar } from '../../containers/layout'
import { Details } from '../../containers/Details'
import { Dialogs } from '../../containers/dialog'
import { JobStatus } from '../../containers/job'
import { HomeLegacy } from './HomeLegacy'
import { HomeNextGen } from './HomeNextGen'
import { HomeWithDrawer } from 'skylight-common'
import { canSeeNextGen } from '../../svc/scopes'
import { isNextGenUrl } from '../../lib/nextGenApplication'
import { LAYOUT_VERSIONS, setCurrentLayoutToStorage } from '../../svc/layout'
import './Home.css'

class Home extends Component {
  // eslint-disable-next-line
  componentWillReceiveProps() {
    const layoutVersion = canSeeNextGen() && isNextGenUrl() ? LAYOUT_VERSIONS.NG : LAYOUT_VERSIONS.LEGACY
    setCurrentLayoutToStorage(layoutVersion)
  }

  render() {
    const props = this.props
    return (
      <HomeWithDrawer {...props} className={classNames('home', { 'next-gen': isNextGenUrl() })}>
        <div className="main">
          <TopBar />
          <LeftNav />
          <Dialogs />
          <div className="home-body">
            <Route path="/logout" component={Logout} />
            <Route path="/404" component={NotFound} />
            <Route path="/ng" render={routeProps => <HomeNextGen {...props} {...routeProps} />} />
            <Route path={`${props.match.url}`} render={() => <HomeLegacy {...props} />} />
          </div>

          <JobStatus />
        </div>
        <Details />
        {/* <EyeSightCache/> */}
      </HomeWithDrawer>
    )
  }
}

export { Home }
