import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const WindowCloseMedium = (props) => {
  return (
    <SvgIcon {...props}>
      <polygon points="13.4142136 12 16.2426407 9.17157288 14.8284271 7.75735931 12 10.5857864 9.17157288 7.75735931 7.75735931 9.17157288 10.5857864 12 7.75735931 14.8284271 9.17157288 16.2426407 12 13.4142136 14.8284271 16.2426407 16.2426407 14.8284271" />
    </SvgIcon>
  )
}

export default WindowCloseMedium
