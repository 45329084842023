import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const applications = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M21.4063,7.08625 C21.7673,7.24625 22.0003,7.60425 22.0003,7.99925 C22.0003,8.39525 21.7673,8.75325 21.4063,8.91325 L12.4063,12.91325 C12.2773,12.97025 12.1383,12.99925 12.0003,12.99925 C11.8623,12.99925 11.7233,12.97025 11.5933,12.91325 L2.5943,8.91325 C2.2333,8.75325 2.0003,8.39525 2.0003,7.99925 C2.0003,7.60425 2.2333,7.24625 2.5943,7.08625 L11.5933,3.08625 C11.8523,2.97125 12.1473,2.97125 12.4063,3.08625 L21.4063,7.08625 Z M22,10.35775 L22,12.55475 L12.413,16.91275 C12.282,16.97075 12.141,16.99975 12,16.99975 C11.869,16.99975 11.738,16.97675 11.615,16.92375 L2,12.91575 L2,10.75075 L11.982,14.90975 L22,10.35775 Z M22,14.35775 L22,16.55475 L12.413,20.91275 C12.282,20.97075 12.141,20.99975 12,20.99975 C11.869,20.99975 11.738,20.97675 11.615,20.92375 L2,16.91575 L2,14.75075 L11.982,18.90975 L22,14.35775 Z" />
    </SvgIcon>
  )
}

export default applications
