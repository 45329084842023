import React, { Component } from 'react'
// import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem'
import { BweForVideoReport, SsrcVideoSendReport } from '../../../../containers/activity'

import './CallReports.css'

const DEFAULT_REPORT = 'ssrcVideoSend'
// const DEFAULT_REPORT = 'bweForVideo'

class CallReports extends Component {
  state = {
    type: DEFAULT_REPORT
  }

  reportTypes = {
    bweForVideo: BweForVideoReport,
    ssrcVideoSend: SsrcVideoSendReport
  }

  renderReport = (type) => {
    const Report = this.reportTypes[type]

    return Report ? <Report callId={this.props.match.params.callId} /> : null
  }

  onChangeType = (event, key, type) => {
    this.setState({ type })
  }

  renderTypes = () => {
    return Object.keys(this.reportTypes).map(key =>
      <MenuItem key={key} value={key} primaryText={key} />
    )
  }

  render() {
    return <div className="call-reports">
      {/* <div className="call-reports-switcher">
        <div className="call-reports-switcher-label">Select report: </div>
        <SelectField
          floatingLabelText=""
          value={this.state.type}
          onChange={this.onChangeType}
        >
        {this.renderTypes()}
        </SelectField>
      </div> */}
      <div className="call-report">
        {this.renderReport(this.state.type)}
      </div>
    </div>
  }
}

export { CallReports }
