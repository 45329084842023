import React from 'react'
import FileTypeZip from '../../../../../Common/Icons/fileTypeZip'
import LinearProgress from 'material-ui/LinearProgress'
import CancelIcon from 'material-ui/svg-icons/navigation/cancel'
import { CursorTooltip } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import { convertBytesToKb } from '../../../../../../lib/utils'
import get from 'lodash/get'
import classNames from 'classnames'

import './FileUpload.css'

const percentage = (partialValue, totalValue) => (100 * partialValue) / totalValue

const FileUpload = ({ upload, onCancelImportUpload }) => {
  const [uploadedBytes, totalBytes] = [get(upload, 'uploadedBytes', 0), get(upload, 'totalBytes', 0)]
  const [uploadedKb, totalKb] = [convertBytesToKb(uploadedBytes), convertBytesToKb(totalBytes)]
  const uploadedPercentage = percentage(uploadedKb, totalKb).toFixed()
  const { error } = upload
  const uploadPercentage = error
    ? <span className="file-upload__upload-failed"><FormattedMessage id="UPLOAD_FAILED" defaultMessage="Upload failed" /></span>
    : <span><FormattedMessage id="UPLOADING" defaultMessage="Uploading" />... {uploadedPercentage}%</span>

  return (
    <div className="file-upload__wrapper">
      <div className="file-upload__icon-wrapper"><FileTypeZip /></div>
      <div className="file-upload__upload-metadata">
        <div className="file-upload__upload-metadata-filename">{upload.filename}</div>
        <LinearProgress mode="determinate" className={classNames('linear-progress', { error })} max={totalKb} value={uploadedKb} />
        <div className="file-upload__upload-status">
          <div className="file-upload__uploaded-metadata">
            <span>{uploadedKb} KB / {totalKb} KB</span>
          </div>
          <div className="file-upload__upload-percentage">
            {uploadPercentage}
          </div>
        </div>
      </div>
      <CursorTooltip className="file-upload__cancel-upload" tooltip={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}>
        <CancelIcon onClick={() => onCancelImportUpload()} />
      </CursorTooltip>
    </div>
  )
}

export default FileUpload
