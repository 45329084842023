import React from 'react'
import { Form, CursorTooltip } from 'skylight-common'
import MenuItem from 'material-ui/MenuItem'
import RaisedButton from 'material-ui/RaisedButton'
import { FormattedMessage } from 'react-intl'
import { USER } from '../../../../../svc/scopes'
import skylight from 'skylight-js-sdk'
import { UserImage } from '../../../../Common'
import { OutlinedTextField } from '../../../../Common/Form/OutlinedTextField'
import { OutlinedSelectField } from '../../../../Common/Form/OutlinedSelectField'
import { OutlinedField } from '../../../../Common/Form/OutlinedField'
import LockOpenIcon from '../../../../Common/Icons/lockOpen'
import { CollapsibleSection } from '../../../../Common/NextGen/DetailsNextGen/CollapsibleSection/CollapsibleSection'
import GroupMembership from '../GroupMembership/GroupMembership'
import { SUPPORTED_LOCALES } from '../../../../../svc/locale'

import './UserDetails.css'

const PRIMARY_PROPS = [
  {
    prop: 'username',
    disabled: () => true,
    required: () => true,
    label: { id: 'USERNAME', default: 'Username' }
  },
  {
    prop: 'email',
    label: { id: 'EMAIL', default: 'Email' },
    required: item => item.role === 'admin'
  }
]

const ADDITIONAL_PROPS = [
  { prop: 'id', disabled: () => true, label: { id: 'UUID', default: 'UUID' } },
  { prop: 'firstName', label: { id: 'FIRSTNAME', default: 'First name' } },
  { prop: 'lastName', label: { id: 'LASTNAME', default: 'Last name' } },
  { prop: 'jobTitle', label: { id: 'JOBTITLE', default: 'Job title' } },
  { prop: 'officePhone', label: { id: 'OFFICE_PHONE', default: 'Office phone' } },
  { prop: 'mobilePhone', label: { id: 'MOBILE_PHONE', default: 'Mobile phone' } },
  { prop: 'location', label: { id: 'ACCOUNT_LOCATION', default: 'Location' } }
]

const UserDetails = (props) => {
  const { user } = props
  const { item, mode, errors = {} } = user || { item: props.item }

  const canEditUser = skylight.auth.hasScopes([USER.EDIT])

  const renderAccountTypeSelectField = () => (
    <OutlinedSelectField
      required={canEditUser}
      floatingLabelText={<FormattedMessage id="ACCOUNT_TYPE" defaultMessage="Account type" />}
      disabled={!canEditUser}
      error={errors.role
        ? { id: 'REQUIRED_ACCOUNT_TYPE_ERROR', defaultMessage: 'Account type is required' }
        : null}
      onChange={(e, i, v) => props.onUserChange('role', v)}
      value={item.role}>
      <MenuItem value="user" primaryText={<FormattedMessage id="USER" defaultMessage="User" />} />
      <MenuItem value="manager" primaryText={<FormattedMessage id="MANAGER" defaultMessage="Manager" />} />
      <MenuItem value="admin" primaryText={<FormattedMessage id="ADMIN" defaultMessage="Admin" />} />
    </OutlinedSelectField>
  )

  const renderGroupMembership = () => {
    if (props.callUser) {
      return null
    }

    return (
      <OutlinedField floatingLabelText={<FormattedMessage id="GROUP_MEMBERSHIP" defaultMessage="Group membership" />}>
        <GroupMembership user={user.item} />
      </OutlinedField>
    )
  }

  const renderProp = (prop) => {
    const isDisabled = prop.disabled ? prop.disabled(mode) : false

    return <OutlinedTextField
      key={prop.prop}
      readonly={isDisabled}
      value={item[prop.prop] || ''}
      type={prop.type}
      required={prop.required && prop.required(item)}
      name={prop.prop + '_'}
      error={errors[prop.prop]
        ? { id: `${errors[prop.prop]}_${prop.label.id}`, defaultMessage: 'Field is required' }
        : null}
      floatingLabelText={<FormattedMessage id={prop.label.id} defaultMessage={prop.label.default} />}
      onChange={(e, v) => props.onUserChange(prop.prop, v)} />
  }

  const renderLanguageField = () => {
    const localeValue = item.locale || item.language || ''
    return <OutlinedSelectField
      disabled={!canEditUser}
      floatingLabelText={<FormattedMessage id="LANGUAGE" defaultMessage="Language" />}
      value={localeValue}
      onChange={(e, k, v) => props.onUserChange('locale', v)}>
        <MenuItem value="" primaryText={<FormattedMessage id="DOMAIN_DEFAULT" defaultMessage="Domain default" />} />
      {SUPPORTED_LOCALES.map(x => <MenuItem key={x.value} value={x.value} primaryText={x.title} />)}
    </OutlinedSelectField>
  }

  const resetPassword = () => {
    props.onResetUserPassword(item)
  }

  const renderResetPasswordButton = () => {
    if (mode !== 'edit') {
      return null
    }

    return <CursorTooltip tooltip={<FormattedMessage id="RESET_PASSWORD_DESCRIPTION" />}>
      <RaisedButton
        className="reset-password-button"
        label={<FormattedMessage id="RESET PASSWORD" defaultMessage="Reset password" />}
        primary
        icon={<LockOpenIcon />}
        onTouchTap={resetPassword}
        type="button" />
    </CursorTooltip>
  }

  const handleSubmit = () => {
    user.hasChanged && props.onUserSave(user.item)
  }

  const onUploadAvatar = (item) => {
    if (item.id) {
      props.onUserChange('avatar', item.id)
    } else {
      props.onAvatarUploadError()
    }
  }

  return (
    <div className="user-details">
      <div className="form-container">
        <Form onSubmit={handleSubmit}>
          <div className="user-details-image-container">
            <UserImage
              src={item.avatar}
              disableFooter
              canEditUser={!props.callUser}
              onUploadImage={onUploadAvatar} />
          </div>
          <div className="user-details-primary-section">
            {renderAccountTypeSelectField()}
            {PRIMARY_PROPS.map(renderProp)}
            {renderGroupMembership()}
          </div>
          <CollapsibleSection
            className="user-details-additional-section"
            title={<FormattedMessage id="ADDITIONAL_PROPERTIES" defaultMessage="Additional properties" />}>
            {ADDITIONAL_PROPS.map(renderProp)}
            {renderLanguageField()}
          </CollapsibleSection>
        </Form>
      </div>
      <div className="buttons-container">
        {renderResetPasswordButton()}
      </div>
    </div>
  )
}

export { UserDetails }
