import React from 'react'
import { Dialog } from 'material-ui'
import { DialogTitle, TrimmingTextField } from 'skylight-common'
import ShieldAccount from '../../../../Common/Icons/shieldAccount'
import { DEFAULT_ROLES, ROLE_PERMISSIONS } from '../../../../../lib/application/roles'
import DownIcon from 'material-ui/svg-icons/navigation/arrow-drop-down'
import { Collapse } from './Collapse'
import { FormattedMessage } from 'react-intl'
import FlatButton from 'material-ui/FlatButton'
import CircularProgress from 'material-ui/CircularProgress'
import { ApplicationPermission } from './Collapse/ApplicationPermission'
import classNames from 'classnames'

import './AddApplicationRole.css'

const AddApplicationRoleSection = props => (
  <div className={`add-application-role-section ${props.className || ''}`}>
    <div className="section-header">
      <div className="section-icon"><ShieldAccount /></div>
      <div className="section-title">{props.title}</div>
    </div>
    {props.children}
  </div>
)

const MAX_ROLE_NAME_LENGTH = 30

const AddApplicationRole = props => {
  const { dialog, dialogType, item } = props
  const { item: role, original: originalRole, hasChanged, errors, mode, isSaving } = item
  const { open } = dialog[dialogType] || {}

  const isEditMode = mode === 'edit'

  const handleFieldChange = (e, v) => {
    props.onApplicationRoleChange(e.target.name, v)
  }

  const renderDialogTitle = () => {
    if (isEditMode) {
      return <DialogTitle id="EDIT_ROLE" defaultMessage="Edit role" />
    }

    return <DialogTitle id="ADD_ROLE" defaultMessage="Add role" />
  }

  const renderNameFieldError = () => {
    if (errors.name) {
      return <FormattedMessage id={`${errors.name}_ROLE_NAME`} defaultMessage="Field is invalid" />
    }

    if (role.name.length > MAX_ROLE_NAME_LENGTH) {
      return <FormattedMessage
        id="ROLE_NAME_EXCEEDS_LENGTH"
        values={{ maxLength: MAX_ROLE_NAME_LENGTH }}
        defaultMessage="Role name cannot exceed {maxLength} characters" />
    }

    return null
  }

  const renderRoleInfoSection = () => {
    if (!role) {
      return null
    }

    const title = <FormattedMessage id="ADD_ROLE_INFO_SECTION" defaultMessage="Role" />
    const messageFormatter = text => <span>{text} *</span>

    const nameLabel = <FormattedMessage id="EDIT_ROLE_NAME_HINT" defaultMessage="Name">
      {messageFormatter}
    </FormattedMessage>
    const descriptionLabel = <FormattedMessage id="EDIT_ROLE_DESC_HINT" defaultMessage="Description" />

    const error = renderNameFieldError()
    return (
      <AddApplicationRoleSection title={title} className="role">
        <div className="text-fields-container">
          <TrimmingTextField
            className={classNames('text-field', { error })}
            name="name"
            autoComplete="off"
            value={role.name}
            disabled={originalRole.name === DEFAULT_ROLES.OWNER}
            onChange={handleFieldChange}
            floatingLabelText={nameLabel}
            errorText={error}
            hintText={nameLabel} />
          <TrimmingTextField
            className="text-field description"
            name="description"
            autoComplete="off"
            value={role.description}
            onChange={handleFieldChange}
            floatingLabelText={descriptionLabel}
            hintText={descriptionLabel} />
        </div>
      </AddApplicationRoleSection>
    )
  }

  const handlePermissionChange = (permission, isPermissionSelected) => {
    if (isPermissionSelected) {
      props.onRolePermissionAdd(permission)
      return
    }

    props.onRolePermissionRemove(permission)
  }

  const renderPermissionsSection = () => {
    const categories = Object.keys(ROLE_PERMISSIONS)
    const title = <FormattedMessage id="ADD_ROLE_PERMISSIONS_SECTION" defaultMessage="Permissions" />

    return (
      <AddApplicationRoleSection title={title} className="permissions">
        {(categories || []).map(c => {
          const permissions = (ROLE_PERMISSIONS[c] || []).map((p, i) => {
            const isFirstPermissionInCategory = i === 0
            const isCategoryWithMultiplePermissions = ROLE_PERMISSIONS[c].some(x => x.permissions.length > 1)
            return <ApplicationPermission
              key={p.description.id}
              role={role}
              isCategoryWithMultiplePermissions={isCategoryWithMultiplePermissions}
              isFirstPermissionInCategory={isFirstPermissionInCategory}
              onPermissionChange={handlePermissionChange}
              permission={p} />
          })

          return <Collapse
            key={c}
            collapseIcon={<DownIcon />}
            headerText={c}
            open>
            {permissions}
          </Collapse>
        })}
      </AddApplicationRoleSection>
    )
  }

  const renderLoader = () => {
    return isSaving ? <div className="loading-container"><CircularProgress size={55} thickness={6} /></div> : null
  }

  const renderDialogContent = () => {
    return (
      <div className="add-role-content-container">
        {renderLoader()}
        {renderRoleInfoSection()}
        {renderPermissionsSection()}
      </div>
    )
  }

  const handleClose = () => {
    props.onCloseDetails()
    props.onHideDialog(dialogType)
  }

  const handleSubmit = () => {
    props.onApplicationRoleSaveSubmitted()
  }

  const getDialogActions = () => {
    if (!role) {
      return null
    }

    const { permissions, name } = role
    const isSubmitDisabled = (!permissions.length ||
      (mode !== 'new' && !hasChanged) ||
      !name || isSaving || name.length > MAX_ROLE_NAME_LENGTH)

    const submitLabel = isEditMode
      ? <FormattedMessage id="EDIT_ROLE" defaultMessage="Edit role" />
      : <FormattedMessage id="ADD_ROLE" defaultMessage="Cancel" />

    return [
      <FlatButton
        key="cancel-action"
        className="cancel-action"
        label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
        onTouchTap={handleClose}/>,
      <FlatButton
        key="submit-action"
        className="submit-action"
        primary
        disabled={isSubmitDisabled}
        label={submitLabel}
        onTouchTap={handleSubmit}/>
    ]
  }

  return (
    <div className="add-application-role-dialog">
      <Dialog
        title={renderDialogTitle()}
        modal={false}
        actions={getDialogActions()}
        open={!!open}
        className="dialog-modal add-application-role"
        contentClassName="add-role-content"
        autoDetectWindowHeight={false}
        autoScrollBodyContent
        onRequestClose={handleClose}>
        {renderDialogContent()}
      </Dialog>
    </div>
  )
}

export { AddApplicationRole }
