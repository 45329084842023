export const DEFAULT_QUERY = {
  view: 'grid',
  limit: 200,
  skip: 0,
  sortBy: 'modifiedAt',
  sortAsc: false,
  filter: { name: [] },
  filters: ['name']
}

export const PICKER_QUERY = {
  sortBy: 'name',
  sortAsc: true
}
