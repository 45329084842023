import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const networkTest = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M8.6178,22.0000333 L9.6178,20.0000667 L14.3818,20.0000667 L15.3818,22.0000333 L8.6178,22.0000333 Z M13.3818,18.0001 L10.6178,18.0001 L11.9998,15.2361461 L13.3818,18.0001 Z M13.3508,13.4661756 C13.7468,13.1001817 13.9998,12.5811903 13.9998,12.0002 C13.9998,10.8962184 13.1028,10.0002333 11.9998,10.0002333 C10.8938,10.0002333 9.9998,10.8962184 9.9998,12.0002 C9.9998,12.5811903 10.2518,13.1011816 10.6488,13.4661756 L6.1048,22.5530241 C5.9508,22.8630189 5.9668,23.2310128 6.1488,23.5260079 C6.3308,23.820003 6.6528,24 6.9998,24 L16.9998,24 C17.3468,24 17.6678,23.820003 17.8498,23.5260079 C18.0328,23.2310128 18.0488,22.8630189 17.8938,22.5530241 L13.3508,13.4661756 Z M20.485,3.5159414 C22.752,5.78190363 24,8.79485342 24,11.9998 C24,15.2057466 22.752,18.2186964 20.485,20.4856586 L19.071,19.0716821 C20.96,17.1827136 22,14.6717555 22,11.9998 C22,9.32884452 20.96,6.81888635 19.071,4.92991783 C17.182,3.04094932 14.671,1.99996667 12,1.99996667 C9.329,1.99996667 6.818,3.04094932 4.928,4.92991783 C3.04,6.81888635 2,9.32884452 2,12.0008 C2,14.6717555 3.04,17.1837136 4.928,19.0726821 L3.514,20.4866586 C1.248,18.2196963 0,15.2057466 0,12.0008 C0,8.79485342 1.248,5.78190363 3.514,3.5159414 C5.781,1.24897918 8.794,0 12,0 C15.205,0 18.218,1.24897918 20.485,3.5159414 Z M11.9996,5.00031666 C15.8596,5.00031666 18.9996,8.14026433 18.9996,12.0002 C18.9996,12.8921851 18.8306,13.7671705 18.4966,14.6001567 L16.6396,13.8541691 C16.8786,13.259179 16.9996,12.6361894 16.9996,12.0002 C16.9996,9.24324595 14.7566,7.00028333 11.9996,7.00028333 C9.2426,7.00028333 6.9996,9.24324595 6.9996,12.0002 C6.9996,12.6351894 7.1216,13.258179 7.3606,13.8541691 L5.5036,14.6001567 C5.1696,13.7661706 4.9996,12.8911851 4.9996,12.0002 C4.9996,8.14026433 8.1396,5.00031666 11.9996,5.00031666 Z" id="path-1"></path>
    </SvgIcon>
  )
}

export default networkTest
