import React from 'react'
import { ByList } from 'skylight-common'
import skylight from 'skylight-js-sdk'

const getItems = props => () => {
  return skylight.we20.session.eventTypes(props.application.applicationId, props.session.sessionId)
    .then(ets => ets.map(et => ({ text: et, value: et })))
}

const ByEventType = props => {
  return <ByList
    className="by-event-type"
    title="SESSION_EVENT_TYPE"
    placeholder={{ id: 'ALL', defaultMessage: 'All' }}
    value={props.value}
    onChange={props.onChange}
    onRemove={props.onRemove}
    onGetItems={getItems(props)} />
}

export { ByEventType }
