import React from 'react'
import { FormattedMessage } from 'react-intl'
import IconButton from 'material-ui/IconButton'
import PersonIcon from 'material-ui/svg-icons/social/person'
import AudioCallIcon from 'material-ui/svg-icons/communication/call'
import VideoCallIcon from 'material-ui/svg-icons/av/videocam'
import { Table, GridHeader, Column, Loading, UserTitle, ActionButton } from 'skylight-common'
import skylight from 'skylight-js-sdk'
import { GroupMembership } from '../GroupMembership/GroupMembership.js'
import { USER } from '../../../../svc/scopes'

import './UserList.css'

const UserList = (props) => {
  const { users } = props.contact

  const onDelete = () => {
    props.onConfirm(
      () => props.onUserDeleteItems(users.selectedIds),
      {
        title: 'USERS_REMOVE_DIALOG_TITLE',
        message: 'USERS_REMOVE_DIALOG_DESCRIPTION',
        action: 'ACTION_DELETE_USER'
      }
    )
  }
  const headingActions = [{
    show: 'selected',
    label: { id: 'ACTION_DELETE', default: 'Remove' },
    action: onDelete,
    primary: true,
    scopes: [USER.DELETE]
  },
  {
    show: 'selected',
    label: { id: 'ACTION_AUDIO_CALL', default: 'Audio Call' },
    primary: true,
    nested: [{
      label: { id: 'ACTION_AUDIO_CALL', default: 'Audio Call' },
      action: () => console.log('NOT_IMPLEMENTED_YET')
    },
    {
      label: { id: 'ACTION_VIDEO_CALL', default: 'Video Call' },
      action: () => console.log('NOT_IMPLEMENTED_YET')
    }],
    scopes: []
  }]
    .filter((a) => skylight.auth.hasScopes(a.scopes))

  const call = (e, userToCall, enableVideo) => {
    e.preventDefault()
    e.stopPropagation()
    const currentUser = props.auth && props.auth.user
    props.onCall(userToCall, currentUser, enableVideo, props.presence)
  }

  return <div className="dashboard-list user-list">
    <Loading
      isLoading={users.isLoading && users.items.length === 0}
      message="LOADING_USER_LIST"
      error={users.error}>
      <Table
        showCheckboxes={true}
        tableHeading={(props) =>
          <GridHeader
            {...props}
            actions={headingActions.map((a, i) =>
              <ActionButton
                show={a.show}
                key={a.label.id + i}
                label={<FormattedMessage id={a.label.id} defaultMessage={a.label.default} />}
                onTouchTap={a.action || null}
                primary={a.primary}>
                  {(a.nested || []).map((n, j) =>
                  <ActionButton
                    key={n.label.id + j}
                    label={<FormattedMessage id={n.label.id} defaultMessage={n.label.default} />}
                    onTouchTap={n.action} />
                  )}
                </ActionButton>
            )}
            title={<FormattedMessage id="USER_LIST" defaultMessage="User list" />} />
        }
        isLoading={users.isLoading}
        checkboxIcon={() => <PersonIcon />}
        showHover={true}
        query={users.query}
        items={users.items}
        total={users.total}
        done={users.done}
        selectedRows={users.selectedIds}
        name="contactsUserList"
        emptyLabel={<FormattedMessage
          id="EMPTY_USER_LIST"
          defaultMessage="You do not have other users assigned to you. Contact your system administrator if you feel that this is an error." />
        }
        onQuery={props.onUserQuery}
        onRowsSelected={props.onUserSelectItems}>

        <Column
          key="name"
          name="firstName,lastName"
          title={<FormattedMessage id="NAME" defaultMessage="Name" />}
          render={(item, index) => <UserTitle {...props} user={item} />} />
        <Column
          key="jobTitle"
          name="jobTitle"
          title={<FormattedMessage id="JOBTITLE" defaultMessage="Job" />} />

        <Column
          key="groups"
          name="groups"
          display={Table.showWide}
          title={<FormattedMessage id="GROUP_MEMBERSHIP" defaultMessage="Group membership" />}
          onTouchTap={(e) => { e.stopPropagation() }}
          render={i => <GroupMembership user={i} {...props}/>} />

        <Column
          key="actions"
          title=""
          isAction={true}
          render={item => <div className="call-actions list-actions">
            <IconButton
              tooltip={<FormattedMessage id="ACTION_AUDIO_CALL" defaultMessage="Audio Call" />}
              onTouchTap={(e) => call(e, item, false)}>
              <AudioCallIcon />
            </IconButton>
            <IconButton
              tooltip={<FormattedMessage id="ACTION_VIDEO_CALL" defaultMessage="Video Call" />}
              onTouchTap={(e) => call(e, item, true)}>
              <VideoCallIcon />
            </IconButton>
          </div>} />
      </Table>
    </Loading>
  </div>
}

export { UserList }
