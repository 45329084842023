import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Table, Column } from 'skylight-common'
import { MediaIcon } from './MediaIcon'
import './ListView.css'

const ListView = (props) => {
  const { list } = props
  const mediaItems = list.items
  const onNavigateToUser = (item) => {
    props.onUserSelectItems([item.id], [item])
  }

  const ListHeading = props.heading

  return (
    <div className="list-view">
      <Table
        tableHeading={ListHeading}
        showHover={true}
        showCheckboxes={true}
        tableHeaderActions={props.tableHeaderActions}
        checkboxIcon={item => <MediaIcon item={item} />}
        isLoading={list.isLoading}
        query={list.query}
        items={mediaItems}
        total={list.total}
        done={list.done}
        selectedRows={list.selectedIds}
        name="mediaList"
        tableFooter={props.listFooter}
        emptyLabel={<FormattedMessage
          id="EMPTY_MEDIA_LIST"
          defaultMessage="You have no shared or captured media." />}
        onQuery={props.onMediaQuery}
        onRowsSelected={(ids) => props.onMediaSelectItems(ids, list.items)}>
        {props.columns.map(x => <Column
          key={x.name}
          name={x.name}
          title={<FormattedMessage id={x.title} defaultMessage={x.title} />}
          sortable={x.sortable !== false}
          render={x.render ? item => x.render(item, onNavigateToUser, props.scopes) : null} />)}
        {/*
          Table header should contain an icon for changing display of the data (Grid/List),
          this column helps us to both display an icon and display an empty column and not allow
          table content to shift.
          Added within: https://upskill.atlassian.net/browse/SKY-2413
          TODO: Consider moving to common
        */}
        <Column
          key="actions"
          isAction={true}
          className="media-header-actions"
          sortable={false} />
      </Table>
    </div>
  )
}

export { ListView }
