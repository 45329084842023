import React from 'react'
import { FilterToolbar, ByUser } from 'skylight-common'
import './UserFilter.css'

const UserFilter = props => {
  const { onUserSelectItems, authUser } = props

  const filterProps = {
    urlPath: '/team/users',
    onSelectItems: onUserSelectItems,
    role: authUser.role
  }

  const FILTERS = [
    { text: 'USERNAME', value: 'userIds', props: { title: 'SELECT_USERS', filterProps }, comp: ByUser }
  ]

  return (
    <FilterToolbar
      className="user-filter"
      filters={FILTERS}
      query={props.query}
      layout={props.layout}
      onFilterUpdated={props.onUserFilterUpdated}
      onFiltersUpdated={props.onUserFiltersUpdated}
      onFilterRemoved={props.onUserFilterRemoved}/>
  )
}

export { UserFilter }
