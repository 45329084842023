import React from 'react'
import PlayArrowIcon from 'material-ui/svg-icons/av/play-arrow'
import FastForwardIcon from 'material-ui/svg-icons/av/fast-forward'
import FastRewindIcon from 'material-ui/svg-icons/av/fast-rewind'
import FullscreenIcon from 'material-ui/svg-icons/navigation/fullscreen'
import PauseIcon from 'material-ui/svg-icons/av/pause'
import IconButton from 'material-ui/IconButton'

const PlaybackToolbar = props => {
  const { progress, duration, isPlaying, disabled } = props

  return <div className="playback-toolbar">
    {!disabled && <progress
      value={progress}
      max={duration}
      onTouchTap={props.onTimeSet} />
    }
    <div className="playback">
      <div>
        <IconButton
          onTouchTap={props.onScrollVideoBack}
          disabled={disabled}>
          <FastRewindIcon />
        </IconButton>
        {!isPlaying && <IconButton
          onTouchTap={props.playVideo}
          disabled={disabled}>
          <PlayArrowIcon />
        </IconButton>}
        {isPlaying && <IconButton
          onTouchTap={props.pauseVideo}
          disabled={disabled}>
          <PauseIcon />
        </IconButton>}
        <IconButton
          onTouchTap={props.onScrollVideoForward}
          disabled={disabled}>
          <FastForwardIcon />
        </IconButton>
      </div>
      <IconButton
        className="preview-bar"
        onTouchTap={props.onFullScreenClick}
        disabled={disabled}>
        <FullscreenIcon />
      </IconButton>
    </div>
  </div>
}

export { PlaybackToolbar }
