import React from 'react'
import classNames from 'classnames'
import { Menu } from '../Menu/'

import './SkylightHelpMenu.css'

const SkylightHelpMenu = props => {
  return (
    <Menu
      {...props}
      className={classNames('skylight-help-menu', props.className)}
      width={props.width} />
  )
}

export { SkylightHelpMenu }
