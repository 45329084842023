import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const ArrowDropDown = (props) => {
  return (
    <SvgIcon {...props}>
      <polygon points="7 10 12 15 17 10" />
    </SvgIcon>
  )
}

export default ArrowDropDown
