import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import appActions from '../actions/app'
import errorActions from '../actions/error'
import dialogActions from '../actions/dialog'
import authActions from '../actions/auth'
import { actions, DetailsDrawer as DetailsDrawerComp } from 'skylight-common'

import { Root as RootComp } from '../Root'
import { LeftNav as LeftNavComp } from '../App/Home/Layout/LeftNav'
import { TopBar as TopBarComp } from '../App/Home/Layout/TopBar'

const leftNav = (state, props) => ({
  auth: state.auth,
  layout: state.layout,
  socket: state.presence.socket,
  application: state.application
})

const leftNavActions = {
  ...appActions,
  ...dialogActions,
  ...authActions,
  ...actions.nav
}

const topBar = (state, props) => ({
  auth: state.auth,
  router: state.router,
  layout: state.layout,
  socket: state.presence.socket,
  mqtt: state.mqtt,
  selectedApplication: state.application.selectedApplication
})

const topBarActions = {
  ...appActions,
  ...actions.nav,
  ...dialogActions
}

const rootState = (state, props) => ({
  app: state.app,
  auth: state.auth,
  call: state.call,
  error: state.error,
  locale: state.locale,
  router: state.router
})

const rootActions = {
  ...appActions,
  ...errorActions
}

const drawer = (state, props) => ({
  nav: state.layout.nav
})

const Root = connect(rootState, rootActions)(RootComp)
const LeftNav = withRouter(connect(leftNav, leftNavActions)(LeftNavComp))
const TopBar = withRouter(connect(topBar, topBarActions)(TopBarComp))
const DetailsDrawer = connect(drawer, actions.nav)(DetailsDrawerComp)

export { Root, LeftNav, TopBar, DetailsDrawer }
