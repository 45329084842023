import React from 'react'
import './TextInputTile.css'

const TextInputTile = props => {
  const { item } = props

  return (
    <div className="assignment-textinput-tile">
      <div className="textinput-tile-label">{item.helpText || item.label}</div>
      <div className={`textinput-tile-input ${item.input ? '' : 'textinput-empty'}`}>{item.input}</div>
    </div>
  )
}

export { TextInputTile }
