import React, { Component } from 'react'
import skylight from 'skylight-js-sdk'
import {
  Table, GridHeader, Column,
  MaterialIcon, Loading, UserTitle, ActionButton
} from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import NetworkIcon from '../../../../Common/Icons/networkTest'
import CallIcon from '../../../../Common/Icons/phoneLocked'
import { CALL_STATUSES } from '../../../../../lib/calls' // need more dots!
import { filterUsers as filterUsersBase } from '../../../../../lib/contact'

import './CallUserList.css'

const EmptyLabel = <FormattedMessage
  id="EMPTY_USER_LIST"
  defaultMessage="You do not have other users assigned to you." />

class CallUserList extends Component {
  isAdmin = () => this.props.authUser.role === 'admin'
  isSocketConnected = () => this.props.socket.state === skylight.socket.STATES.CONNECTED

  getActions = () => {
    return [
      <ActionButton
        key="RUN_NETSCAN"
        show="always"
        type="icon"
        icon={<NetworkIcon/>}
        label={<FormattedMessage id={'RUN_NETSCAN'} defaultMessage={'Run Network Test'} />}
        onTouchTap={this.onNetworkScan}
        primary />
    ]
  }

  onUserCall = (ids, users) => {
    const { authUser, onCall, onCallExternalUser, onCallBusyUser } = this.props
    const [id] = ids
    const item = users.find(x => x.id === id)
    if (item && this.isOnline(item)) {
      if (item.isGroupMember === false) {
        onCallExternalUser(item, authUser)
      } else if (this.isBusy(item)) {
        onCallBusyUser(item, authUser)
      } else {
        onCall(item, authUser)
      }
    }
  }

  onNetworkScan = () => {
    this.props.onNetScanDialog()
  }

  isOnline = (item) => this.isSocketConnected() && item.status !== CALL_STATUSES.OFFLINE

  isBusy = (item) => item.status === CALL_STATUSES.BUSY

  filterUsers = (all) => {
    const { list, authUser: user } = this.props
    const { showUnavailable, showAll } = list.query.filter

    let items = showUnavailable ? all : all.filter(this.isOnline)
    if (this.isAdmin()) {
      const groups = user.groups
      items = items.map(u => ({
        ...u,
        isGroupMember: u.groups.some(uGroup => groups.some(g => g.id === uGroup.id))
      }))
      if (!showAll) {
        items = items.filter(u => u.isGroupMember)
      }
    }

    return items
  }

  renderStatus = (user) => {
    const status = this.isSocketConnected() ? user.status : CALL_STATUSES.OFFLINE

    return <FormattedMessage id={status} default={status.toLowerCase()} />
  }

  getRowClassName = (item) => {
    let className = this.isOnline(item) ? 'online-user' : ''
    if (item.isGroupMember === false) {
      className += ' external-user'
    }

    return className
  }

  renderPersonIcon = (item) => {
    if (this.isSocketConnected() && this.isBusy(item)) return <CallIcon className="call" />
    const className = `icon ${this.isOnline(item) ? 'user-online' : ''}`
    return <MaterialIcon className={className} icon="person" />
  }

  onSelect = (e, ids) => {
    e.preventDefault()
    e.stopPropagation()

    this.props.onCallUserSelectItems(ids)
  }

  renderInfoIcon = (item) => {
    return <MaterialIcon
      className="info"
      icon="info"
      onTouchTap={e => this.onSelect(e, [item.id])} />
  }

  renderUserTitle = (item) => {
    return <UserTitle user={item} />
  }

  render() {
    const { props } = this
    const users = props.list
    const items = this.filterUsers(filterUsersBase(users.items, users.query))

    return (
      <div className="dashboard-list call-user-list">
      <Loading
        isLoading={users.isLoading && users.items.length === 0}
        message="LOADING_USER_LIST"
        error={users.error ? EmptyLabel : null}>
          <Table
            tableHeading={(props) => <GridHeader {...props} actions={this.getActions()} />}
            isLoading={users.isLoading}
            checkboxIcon={this.renderPersonIcon}
            showHover={true}
            query={users.query}
            items={items}
            total={items.length}
            done={users.done}
            selectedRows={users.selectedIds}
            name="callsUserList"
            emptyLabel={EmptyLabel}
            onQuery={this.props.onCallUserQuery}
            onRowsSelected={(ids) => this.onUserCall(ids, items)}
            rowClassName={this.getRowClassName}>
            <Column
              className="user-list-icon-column"
              key="userIcon"
              render={this.renderPersonIcon} />
            <Column
              key="name"
              name="firstName,lastName"
              title={<FormattedMessage id="NAME" defaultMessage="Name" />}
              render={this.renderUserTitle} />
            <Column
              key="status"
              name="status"
              className="status-icon"
              title={<FormattedMessage id="STATUS" defaultMessage="Status" />}
              render={this.renderStatus} />
            <Column
              key="username"
              name="username"
              title={<FormattedMessage id="USERNAME" defaultMessage="Username" />} />
            <Column
              key="userAvailableToCall"
              className="actions"
              isAction={true}
              render={this.renderInfoIcon} />
          </Table>
        </Loading>
      </div>
    )
  }
}

export { CallUserList }
