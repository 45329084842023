import React from 'react'
import { connect } from 'react-redux'
import { SessionFilter } from './SessionFilter'
import { TextFilterNextGen } from '../../../../../../Common/NextGen/TextFilterNextGen'

import sessionsActions from '../../../../../../../actions/application/sessions'
import contactActions from '../../../../../../../actions/contact'

const VALUE = 'name'

const SessionTextFilter = props => {
  const value = props.query.filter && props.query.filter.name ? props.query.filter.name : ''
  const params = { preventPathSave: props.preventPathSave }

  const onChange = (v) => {
    const value = v.trim()
    const filter = { ...props.query.filter, [VALUE]: value }
    props.onSessionFilterUpdated(filter, params)
  }

  return <TextFilterNextGen
    value={value}
    className="session-text-filter"
    title="SEARCH"
    iconFilter={<SessionFilter
      {...props}
      onSessionFilterUpdated={f => props.onSessionFilterUpdated(f, params)}
      onSessionFiltersUpdated={f => props.onSessionFiltersUpdated(f, params)}
      onFilterRemoved={t => props.onSessionFilterRemoved(t, params)} />}
    onChange={onChange} />
}

export { SessionTextFilter }

const mapStateToProps = (state) => ({
  query: state.application.session.list.query,
  layout: state.layout,
  authUser: state.auth.user,
  application: state.application.selectedApplication,
  permissions: state.application.permissions
})

const filterActions = {
  ...sessionsActions,
  onUserSelectItems: contactActions.onUserSelectItems
}

const SessionTextFilterContainer = connect(mapStateToProps, filterActions)(SessionTextFilter)

export default SessionTextFilterContainer
