import React from 'react'
import { FormattedMessage } from 'react-intl'
import { isFiltersSnackbarEnabled } from 'skylight-common'
import { FormSection } from '../../../../Common/Form/FormSection'
import { CheckboxField } from '../../../../Common/Form/CheckboxField'
import './Preferences.css'

const Preferences = (props) => {
  const isShowFiltersSnackbarEnabled = () => {
    const form = props.settings.preferencesForm
    if (props.settings.hasChanged && form.hasOwnProperty('showFiltersSnackbar')) {
      return form.showFiltersSnackbar
    }

    return isFiltersSnackbarEnabled()
  }

  return (
    <FormSection
      className="preferences"
      title={<FormattedMessage id="PREFERENCES" defaultMessage="Preferences" />}>
      <div className="form-fields">
        <div className="form-fields-row">
          <CheckboxField
            className="form-field"
            name="showFiltersSnackbar"
            title={<FormattedMessage id="FILTER_SNACKBAR_DISPLAY" defaultMessage="Display warning when custom search filters are applied" />}
            onChange={props.onChange}
            checked={isShowFiltersSnackbarEnabled()} />
        </div>
      </div>
    </FormSection>
  )
}

export { Preferences }
