import React from 'react'
import { ImagePreview } from './ImagePreview'
import { AVPreview } from './AVPreview'
import { ResourcePreview } from './ResourcePreview'
import { MediaPreviewLoader } from './MediaPreviewLoader'
import { MediaElement, getMediaType, getMediaItemUrl, isMobile } from 'skylight-common'
import media from '../../../lib/media'
import './MediaPreview.css'

const renderMedia = (props, state) => {
  const type = props.item ? getMediaType(props.item) : props.type
  if (media.isResourceItem(type)) {
    return <ResourcePreview type={type} />
  }

  if (!state.objectUrl) {
    return <MediaPreviewLoader />
  }

  const onPreview = (id) => {
    if (isMobile()) {
      // Hide details for mobile, preview allows to toggle details info.
      props.onMediaSelectItems([])
    }

    props.onPreviewMediaItem(id)
  }

  const mediaType = type.split('/').shift()
  const newProps = { ...props, ...state, onPreviewMediaItem: onPreview }

  return mediaType === 'video' || mediaType === 'audio'
    ? <AVPreview {...newProps} isAudio={mediaType === 'audio'} />
    : <ImagePreview {...newProps} />
}

export class MediaPreview extends React.Component {
  state = {
    isPreview: !this.props.isFullScreen
  }

  onLoadContent = () => {
    this.setState({ isPreview: false })
  }

  render() {
    const { props } = this
    const { item } = props
    const mediaType = (item ? getMediaType(item) : props.type).split('/').shift() || 'image'
    const url = item ? getMediaItemUrl(item) : props.src

    // Make sure to display thumbnail only for image and video types.
    const isPreview = this.state.isPreview &&
      (mediaType === 'image' || mediaType === 'video')

    return (
      <div className="media-preview" onTouchTap={(e) => e.stopPropagation()}>
        <MediaElement
          src={url}
          fileType={mediaType}
          alt="Media"
          isThumbnail={isPreview}
          render={state => renderMedia({
            ...props,
            isPreview,
            // Valid only for video type.
            onLoadContent: mediaType === 'video' ? this.onLoadContent : null
          }, state)} />
      </div>
    )
  }
}
