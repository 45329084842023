import React from 'react'
import { connect } from 'react-redux'
import { ApplicationFilter } from './ApplicationFilter'
import applicationActions from '../../../../../actions/nextGenApplication'
import contactActions from '../../../../../actions/contact'
import { TextFilterNextGen } from '../../../../Common/NextGen/TextFilterNextGen'

import './ApplicationTextFilter.css'

const VALUE = 'title'

const ApplicationTextFilter = props => {
  const value = props.query.filter && props.query.filter.title ? props.query.filter.title : ''
  const params = { preventPathSave: props.preventPathSave }

  const onChange = (v) => {
    const value = v.trim()
    const filter = { ...props.query.filter, [VALUE]: value }
    props.onApplicationFilterUpdated(filter, params)
  }

  return <TextFilterNextGen
    value={value}
    className="next-gen-application-text-filter active"
    title="SEARCH"
    iconFilter={<ApplicationFilter
      {...props}
      onApplicationFilterUpdated={f => props.onApplicationFilterUpdated(f, params)}
      onApplicationFiltersUpdated={f => props.onApplicationFiltersUpdated(f, params)}
      onFilterRemoved={t => props.onApplicationFilterRemoved(t, params)} />}
    onChange={onChange} />
}

export { ApplicationTextFilter }

const mapStateToProps = (state) => ({
  query: state.application.list.query,
  layout: state.layout,
  authUser: state.auth.user
})

const filterActions = {
  ...applicationActions,
  onUserSelectItems: contactActions.onUserSelectItems
}

const ApplicationTextFilterContainer = connect(mapStateToProps, filterActions)(ApplicationTextFilter)

export default ApplicationTextFilterContainer
