import React from 'react'
import { formatDateTime } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import UserDetailsLink from '../../../../../Contacts/UserDetails/UserDetailsLink'
import { versionUpgradeIsErrored } from '../../../../../../../lib/application/version'

export const DEFAULT_DETAILS_PROPS = [
  { field: 'version', title: 'VERSION', type: 'string' },
  {
    field: 'createdAt',
    title: 'CREATED',
    type: 'render',
    render: x => formatDateTime(x.createdAt)
  },
  {
    field: 'createdBy',
    title: 'CREATED_BY',
    type: 'render',
    render: x =>
      <UserDetailsLink nextGen userId={x.createdBy} />
  },
  {
    field: 'status',
    title: 'STATUS',
    type: 'render',
    render: (x) => versionUpgradeIsErrored(x)
      ? <FormattedMessage
        id="IMPORT_FAILED"
        defaultMessage="Import Failed" />
      : <FormattedMessage
      id={x.stage} />
  },
  {
    field: 'comments',
    title: 'COMMENTS',
    type: 'render',
    render: (x) => versionUpgradeIsErrored(x)
      ? null
      : x.comment
  }
]

export const ERRORED_UPGRADE_DETAILS_PROPS = [
  ...DEFAULT_DETAILS_PROPS,
  {
    field: 'upgrade.error.message',
    title: 'ERROR',
    type: 'string',
    className: 'error-message-field'
  },
  { field: 'upgrade.error.code', title: 'ERROR_CODE', type: 'string' }
]
