import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const SnapshotIcon = (props) => {
  const style = { fill: '#fff' }
  return <SvgIcon className="snapshot-icon" {...props}>
    <path style={style} d="M21 21v-4h2v4a2 2 0 0 1-2 2h-4v-2h4zm0-20a2 2 0 0 1 2 2v4h-2V3h-4V1h4zM3 3v4H1V3a2 2 0 0 1 2-2h4v2H3zm0 14v4h4v2H3a2 2 0 0 1-2-2v-4h2zm9-8a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zm5 7H7a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h1.38a1 1 0 0 0 0.9-0.55l0.44-0.9A1 1 0 0 1 10.62 6h2.76a1 1 0 0 1 0.9 0.55l0.44 0.9A1 1 0 0 0 15.62 8H17a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1z" />
  </SvgIcon>
}

export { SnapshotIcon }
