import UAParser from 'ua-parser-js'
import skylight from 'skylight-js-sdk'
import { storage } from 'skylight-common'
import _ from 'lodash'

const STORAGE_KEYS = {
  DEVICE: 'DEVICE',
  APP_IDENTIFIER: 'APP_IDENTIFIER'
}

const VERSION_URL = `${window.skylightConfig.webUrl}health`
const getAppVersion = async() => {
  const health = await skylight.http.getJSON(VERSION_URL)
  return health.Version
}

function getAppIdentifier() {
  let identifier = storage.loadItem(STORAGE_KEYS.APP_IDENTIFIER)
  if (!identifier) {
    identifier = skylight.utils.uuid()
    storage.saveItem(STORAGE_KEYS.APP_IDENTIFIER, identifier)
  }
  return identifier
}

const getDeviceInfo = async() => {
  const ua = new UAParser().getResult()
  const appVersion = await getAppVersion()
  const identifier = getAppIdentifier()

  return {
    device: {
      name: ua.browser.name,
      version: ua.browser.version,
      identifier,
      type: 'web'
    },
    os: {
      name: ua.os.name,
      version: ua.os.version
    },
    application: {
      name: 'Skylight Web',
      version: appVersion
    }
  }
}

export const getDevice = async() => {
  const device = storage.loadJSON(STORAGE_KEYS.DEVICE)
  const version = _.get(device, 'application.version')
  const appVersion = await getAppVersion()
  if (version === appVersion) {
    return device
  }

  return createDevice()
}

const createDevice = async() => {
  const deviceInfo = await getDeviceInfo()
  const { deviceId } = await skylight.we20.device.create(deviceInfo)
  const device = {
    ...deviceInfo,
    deviceId
  }
  storage.saveJSON(STORAGE_KEYS.DEVICE, device)
  return device
}
