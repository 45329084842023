import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const ZipIcon = (props) => {
  return <SvgIcon {...props}>
      <path d="M16.4141,1 L5.0001,1 C3.8971,1 3.0001,1.898 3.0001,3 L3.0001,8 L5.0001,8 L5.0001,3 L15.0001,3 L15.0001,7 L19.0001,7 L19.0001,8 L21.0001,8 L21.0001,5.586 L16.4141,1 Z M19,21 L5,21 L5,20 L3,20 L3,21 C3,22.103 3.897,23 5,23 L19,23 C20.103,23 21,22.103 21,21 L21,20 L19,20 L19,21 Z M10,16 L10,18 L14,18 L14,16 L13,16 L13,12 L14,12 L14,10 L10,10 L10,12 L11,12 L11,16 L10,16 Z M18,14 L17,14 L17,12 L18,12 C18.551,12 19,12.448 19,13 C19,13.552 18.551,14 18,14 M18,10 C19.654,10 21,11.346 21,13 C21,14.654 19.654,16 18,16 L17,16 L17,18 L15,18 L15,10 L18,10 Z M3.168,16.4453 L6.131,12.0003 L3,12.0003 L3,10.0003 L8,10.0003 C8.369,10.0003 8.708,10.2033 8.882,10.5283 C9.056,10.8533 9.037,11.2473 8.832,11.5543 L5.869,16.0003 L9,16.0003 L9,18.0003 L4,18.0003 C3.631,18.0003 3.292,17.7973 3.118,17.4713 C2.944,17.1463 2.963,16.7523 3.168,16.4453 Z" />
    </SvgIcon>
}

export default ZipIcon
