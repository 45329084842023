import React from 'react'
import { FilterToolbar, ByGroup } from 'skylight-common'

import './GroupFilter.css'

const GroupFilter = props => {
  const { onGroupSelectItems, authUser } = props

  const filterProps = {
    urlPath: '/team/groups',
    onSelectItems: onGroupSelectItems,
    role: authUser.role,
    chipIcon: 'supervisor_account'
  }

  const FILTERS = [
    { text: 'NAME', value: 'groupIds', props: { filterProps }, comp: ByGroup }
  ]

  return (
    <FilterToolbar
      className="group-filter"
      filters={FILTERS}
      query={props.query}
      layout={props.layout}
      onFilterUpdated={props.onGroupFilterUpdated}
      onFiltersUpdated={props.onGroupFiltersUpdated}
      onFilterRemoved={props.onGroupFilterRemoved}/>
  )
}

export { GroupFilter }
