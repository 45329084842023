import React from 'react'
import { FormattedMessage } from 'react-intl'

const SUPPORTED = [
  'attempted',
  'connected',
  'failed'
]

export const CallStatus = ({ status }) => {
  if (SUPPORTED.includes(status)) {
    return <FormattedMessage
      id={`ACTIVITY_CALL_STATUS_${status.toUpperCase()}`}
      defaultMessage={status} />
  }

  return <FormattedMessage
    id="ACTIVITY_CALL_STATUS_CONNECTION_CONCERN"
    defaultMessage={'Connected *'} />
}
