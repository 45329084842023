import React, { Component } from 'react'

// Material UI
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import { SKYLIGHT_THEME } from './styles/theme'

// Translations
import { IntlProvider, addLocaleData } from 'react-intl'
import en from 'react-intl/locale-data/en'
import ru from 'react-intl/locale-data/ru'

// Router
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'
import { history } from './containers'

import { Home } from './containers/home'
import { Login } from './containers/Login'
import Redirect from './containers/Redirect'

// Call
import callSdk from 'skylight-call-sdk'
import { Call, CallNotification } from './containers/call'
import { isLiveEnabled } from './lib/calls'

// Skylight Socket Listeners
import presence from './middleware/presence'

// Global styles
import { SkylightRoot, NativeUserEvent, PrivateRoute, Error } from 'skylight-common'
import './Root.css'
import './styles/muiCommon.css'
import CaptchaScript from './App/Common/Captcha/CaptchaScript'
import RecoverPassword from './App/PasswordReset/RecoverPassword/RecoverPassword'
import PasswordReset from './App/PasswordReset/PasswordReset'
import { Snackbar } from './containers/Snackbar'
import ChangeTempPassword from './App/ChangeTempPassword/ChangeTempPassword'

addLocaleData([...en, ...ru])
presence.init()

const ACTIVE_CALL_STATES = [
  // callSdk.constants.CALL_STATES.AVAILABLE
  // callSdk.constants.CALL_STATES.CONTACTING
  // callSdk.constants.CALL_STATES.CALLING,
  // callSdk.constants.CALL_STATES.ANSWERING,
  callSdk.constants.CALL_STATES.CONNECTING,
  callSdk.constants.CALL_STATES.INCALL,
  // callSdk.constants.CALL_STATES.INCOMING
  callSdk.constants.CALL_STATES.RECONNECTING
]

class Root extends Component {
  componentDidMount() {
    this.props.initApp()
  }

  renderLoading() {
    return <div className="loading">{this.props.app.loadingMessage}</div>
  }

  render() {
    if (this.props.app.isLoading) {
      return this.renderLoading()
    }

    let isInCall = false
    if (isLiveEnabled()) {
      const callState = this.props.call.connection.state
      isInCall = ACTIVE_CALL_STATES.includes(callState)
    }

    const props = this.props
    return (
      <IntlProvider
        locale={props.locale.id}
        messages={props.locale.translations}>
        <MuiThemeProvider muiTheme={getMuiTheme(SKYLIGHT_THEME)}>
          {isInCall
            ? <Call />
            : (
              <ConnectedRouter history={history}>
                <SkylightRoot className="Root">
                  <CaptchaScript />
                  <CallNotification />
                  <Snackbar />
                  <NativeUserEvent onNativeUserEvent={props.onNativeUserEvent} />
                  <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/redirect/:path" component={Redirect} />
                    <Route path="/recover-password" component={RecoverPassword} />
                    <Route path="/change-temp-password" component={ChangeTempPassword} />
                    <Route path="/password-reset" exact component={PasswordReset} />
                    <PrivateRoute auth={props.auth} path="/" component={Home} />
                  </Switch>
                  <Error error={props.error} onHideError={props.onHideError} />
                </SkylightRoot>
              </ConnectedRouter>
              )}
        </MuiThemeProvider>
      </IntlProvider>
    )
  }
}

export { Root }
