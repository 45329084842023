import React from 'react'
import { get } from 'lodash'
import { MediaPreview } from 'skylight-common'
import { EventDetailsBase } from './EventDetailsBase'
import { MEDIA_DETAILS_PROPS } from './eventDetailsProps'

const EventDetailsMedia = props => {
  const { mediaItemInfo } = props.event.item
  const annotation = { ...get(props, 'event.item.media.annotation', {}) }
  const annotations = []
  if (annotation.annotationId) {
    if (!annotation.path) {
      annotation.path = '<svg></svg>' // jumps over a skylight-common error 'annotation path is empty' https://github.com/APXLabs/skylight-common/blob/master/src/export/lib/annotations.js#L3
    }
    annotations.push(annotation)
  }
  return <div className="event-details-media">
    <div className="event-details-media-file">
      <MediaPreview
        annotations={annotations} // displays only an updated annotation https://upskill.atlassian.net/browse/SUP-2930
        onPreviewMediaItem={() => {
          props.onSessionEventSelectItems([], null, true)
          props.onSessionEventPreviewItem(props.event.item.id)
        }}
        isFullScreen
        onMediaSelectItems={props.onSessionEventSelectItems}
        item={mediaItemInfo} />
    </div>
    <EventDetailsBase itemProps={MEDIA_DETAILS_PROPS} {...props} />
  </div>
}

export { EventDetailsMedia }
