import { formatDate, localSort } from 'skylight-common'
import { filterMediaAttachment } from '../../../../lib/assignment'

export const COMMON_COLUMNS = [
  {
    name: 'id',
    title: 'ACTIVITY_CARD_ID',
    groupBy: x => `${x.sequenceId} -> ${x.card.id} (SeqID -> CardID)`
  },
  {
    name: 'sequenceId',
    title: 'ACTIVITY_SEQUENCE_ID',
    groupBy: x => x.sequenceId
  },
  {
    name: 'updatedAt',
    title: 'UPDATED',
    groupBy: x => formatDate(new Date(x.updatedAt))
  }
]

let lastSelectedType = null
export const onSelect = props => item => {
  lastSelectedType = item ? item.cardType : null
  props.onAssignmentAttachmentSelectItems([item.id], [item])
}

export const isCardSelected = (item, selectedIds) => {
  return selectedIds.includes(item.id) && item.cardType === lastSelectedType
}

export const onSelectMediaItem = props => item => {
  props.onAssignmentAttachmentPreviewItem(item.id)
}

export const onCheckMediaItem = props => (checked, item, e) => {
  e && e.stopPropagation()
  const { selectedIds, items } = props.attachment.list
  let newSelectedIds
  if (checked) {
    const selectedItems = selectedIds.map(id => items.find(x => x.id === id))
    if (!item) {
      // Unexpected flow. Leaving some trace just in case.
      console.log('Shouldnt check an empty item.', checked, item)
      console.trace()
      // Reset selected ids for now.
      return props.onAssignmentAttachmentSelectItems([])
    }
    newSelectedIds = [...selectedItems.filter(x => x.cardType === 'media').map(x => x.id), item.id]
  } else {
    newSelectedIds = selectedIds.filter(id => id !== item.id)
  }

  props.onAssignmentAttachmentSelectItems(newSelectedIds)
}

export const onSortUpdate = props => (sortBy, sortAsc, type) => {
  const query = props.attachment.list.query
  const typeQuery = query[type]
  const newQuery = { ...query, [type]: { ...typeQuery, sortBy, sortAsc } }

  props.onAssignmentAttachmentQuery(newQuery)
}

export const listMediaItems = (items, query) => {
  if (!items) return []
  items = filterMediaAttachment(items, query.filter)
  items = localSort(items, query.media)
  return items
}
