import { CustomFiltersComp } from './CustomFilters'
import { connect } from 'react-redux'

const mapStateToProps = ({ admin: { auth } }) => ({
  isLoading: auth.ldapApp.isSaving,
  customFilters: auth.ldapApp.item.customFilters,
  isSyncLoaded: auth.ldapApp.isSyncLoaded
})

const CustomFilters = connect(
  mapStateToProps,
  null
)(CustomFiltersComp)

export { CustomFilters }
