import * as ActionTypes from './types'

export const showError = (translation, params, message) => ({
  type: ActionTypes.SHOW_ERROR,
  error: { translation, params, message }
})

const hideError = () => ({ type: ActionTypes.APP_CLEAR_ERROR })

export default {
  onShowError: showError,
  onHideError: hideError
}
