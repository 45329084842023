import React from 'react'
import { CallStatus, UserAvatar, UserTitle } from '../Common'
import { CallDropZone } from '../CallDropZone'
import { CallVideo } from '../CallVideo'
import { CallFooter } from '../CallFooter'
import { CallHeader } from '../CallHeader'
import { CallMediaPanel } from '../CallMediaPanel'
import { CallZoom } from '../CallZoom'
import { CallReconnecting } from '../CallReconnecting'
import callSdk from 'skylight-call-sdk'
import './CallScreen.css'

const getOppositeStreamType = type => {
  return type === callSdk.constants.STREAM_TYPES.REMOTE
    ? callSdk.constants.STREAM_TYPES.LOCAL
    : callSdk.constants.STREAM_TYPES.REMOTE
}

const { INCALL, RECONNECTING } = callSdk.constants.CALL_STATES
const OPEN_STATES = [INCALL, RECONNECTING]

export const CallScreen = (props) => {
  const { connection } = props.call
  const { state } = connection

  const mainStreamType = props.call.ui.media.mainStreamType
  const secondaryStreamType = getOppositeStreamType(mainStreamType)

  const onSwapVideoClick = () => {
    props.setMainStreamType(getOppositeStreamType(mainStreamType))
  }

  const isOpen = OPEN_STATES.includes(state)// || true
  if (!isOpen) return null

  return <div className="call-screen">
    <div className="call-area">
      <CallHeader {...props} />
      {state === RECONNECTING
        ? <CallReconnecting />
        : <div className="call-video-wrapper">
          <CallVideo type={mainStreamType} className="full" />
          <CallVideo type={secondaryStreamType} className="preview" onClick={onSwapVideoClick} />
        </div>}
      {false && <div className="main">
        <UserAvatar user={connection.partner} className="full" />
        <UserTitle user={connection.partner} className="full" />
        <CallStatus connection={connection} />
      </div>}
      {callSdk.lib.ui.isRemoteZoomEnabled(props.call) && <CallZoom
        zoom={props.call.device.remoteZoom}
        onChange={props.remoteZoom} />}
      <CallFooter {...props} />
    </div>
    <CallMediaPanel {...props}/>
    <CallDropZone
      onSetPdf={props.setPdf}
      onUploadFile={props.uploadFile}
      onSetSnackbar={props.setSnackbar}
      onShowMedia={props.setShowMedia}/>
  </div>
}
