import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { canSeeLegacy } from '../../../svc/scopes'

import {
  Contacts,
  Activities,
  Assignments,
  Calls,
  Media,
  Team,
  Admin,
  Applications
} from '../../../containers'
import { LegacyHomePage } from '../../../containers/homePage'
import { Profile } from '../../../containers/profile'

import './HomeLegacy.css'

const HomeLegacy = props => {
  if (props.location.pathname.includes('/ng')) return null

  if (!canSeeLegacy()) {
    return <Redirect to="/ng" />
  }

  return <div className="home-body-legacy">
    <Route path="/activities/:type?" component={Activities} />
    <Route path="/assignments" component={Assignments} />
    <Route path="/contacts/:type?" component={Contacts} />
    <Route path="/profile/:type?" component={Profile} />
    <Route path="/team/:type?" component={Team} />
    <Route path="/calls/:type?" component={Calls} />
    <Route path="/media" component={Media} />
    <Route path="/admin/:type?" component={Admin} />
    <Route path="/applications" component={Applications} />
    <Route path={`${props.match.url}`} exact render={() => <LegacyHomePage {...props} />} />
  </div>
}

export { HomeLegacy }
