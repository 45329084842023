import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const dashboard = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M21,16 L21,4 L3,4 L3,16 L21,16 M21,2 C22.1045695,2 23,2.8954305 23,4 L23,16 C23,17.1045695 22.1045695,18 21,18 L14,18 L14,20 L16,20 L16,22 L8,22 L8,20 L10,20 L10,18 L3,18 C1.89,18 1,17.1 1,16 L1,4 C1,2.89 1.89,2 3,2 L21,2 M5,6 L14,6 L14,11 L5,11 L5,6 M15,6 L19,6 L19,8 L15,8 L15,6 M19,9 L19,14 L15,14 L15,9 L19,9 M5,12 L9,12 L9,14 L5,14 L5,12 M10,12 L14,12 L14,14 L10,14 L10,12 Z" />
    </SvgIcon>
  )
}

export default dashboard
