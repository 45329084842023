import { connect } from 'react-redux'

import profileActions from '../actions/profile'
import dialogActions from '../actions/dialog'
import localeActions from '../actions/locale'

import { Profile as ProfileComp } from '../App/Home/Profile'

const profile = (state, props) => ({
  auth: state.auth,
  profile: state.profile,
  locale: state.locale
})

const actions = {
  ...profileActions,
  ...dialogActions,
  ...localeActions
}

const Profile = connect(profile, actions)(ProfileComp)

export { Profile }
