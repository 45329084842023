import SecurityIcon from 'material-ui/svg-icons/hardware/security'
import DashboardIcon from '../../../../Common/Icons/dashboard'
import SessionIcon from '../../../../Common/Icons/compassOutline'
import ArrowBackIcon from '../../../../Common/Icons/arrowBack'
import VersionsIcon from '../../../../Common/Icons/versions'
import { PERMISSIONS } from '../../../../../lib/application'
import { isApplicationAdmin } from '../../../../../svc/scopes'
import SignOutIcon from '../../../../Common/Icons/signOut'

export const getItems = (props, matchParams) => {
  const { selectedApplication, permissions } = props.application
  const { archived } = selectedApplication

  const NEXT_GEN_APPS_PATH = '/ng/applications'
  const NEXT_GET_APP_PATH = `${NEXT_GEN_APPS_PATH}/${matchParams.applicationId}`

  const applicationPermissions = permissions[selectedApplication.applicationId] || {}
  const canReadPermissions = (isApplicationAdmin() || applicationPermissions[PERMISSIONS.ROLE.READ_SELF]) && !archived
  const canReadVersions = applicationPermissions[PERMISSIONS.VERSION.READ_PUBLISHED] && !archived
  const canReadSessions = applicationPermissions[PERMISSIONS.SESSION.READ_SELF] && !archived

  return [
    {
      title: 'BACK_TO_APPLICATION',
      default: 'Back to Applications',
      route: NEXT_GEN_APPS_PATH,
      icon: ArrowBackIcon,
      exact: true
    },
    {
      title: 'OVERVIEW',
      default: 'Overview',
      route: `${NEXT_GET_APP_PATH}/general`,
      exact: true,
      icon: DashboardIcon,
      dividerTop: true
    },
    {
      title: 'PERMISSIONS',
      default: 'Permissions',
      route: `${NEXT_GET_APP_PATH}/permissions`,
      icon: SecurityIcon,
      isDisabled: () => !canReadPermissions
    },
    {
      title: 'VERSIONS',
      default: 'Versions',
      route: `${NEXT_GET_APP_PATH}/versions`,
      icon: VersionsIcon,
      isDisabled: () => !canReadVersions
    },
    {
      title: 'SESSIONS',
      default: 'Sessions',
      route: `${NEXT_GET_APP_PATH}/sessions`,
      icon: SessionIcon,
      isDisabled: () => !canReadSessions
    },
    {
      title: 'MENU_SIGNOUT',
      id: 'menu_signout',
      icon: SignOutIcon,
      default: 'Sign Out',
      handler: p => p.signout(),
      stickToBottom: true,
      tooltipPosition: 'top'
    }
  ]
}

export const getMobileItems = (props, matchParams) => [
  ...getItems(props, matchParams)
]
