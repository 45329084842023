import React from 'react'
import CloseIcon from 'material-ui/svg-icons/navigation/close'
import IconButton from 'material-ui/IconButton'
import { FormattedMessage } from 'react-intl'
import { MediaGallery } from '../Common'
import { MediaEditor } from './MediaEditor'
import { DrawerResizer } from 'skylight-common'
import './CallMediaPanel.css'

class CallMediaPanel extends React.Component {
  state = {
    width: undefined
  }

  render() {
    const props = this.props
    const attachment = props.call.attachment
    const item = attachment.selected.length > 0
      ? attachment.attachments.find(x => x.id === attachment.selected[0])
      : null
    const showMedia = props.call.ui.media.showMedia
    if (!item && !showMedia) {
      return null
    }

    const onClose = () => {
      item ? props.selectAttachment([]) : props.setShowMedia(false)
    }

    const onMediaItemSelect = (item, index) => {
      props.selectAttachment([item.id])
    }

    const title = item
      // ? <FormattedMessage id='CALL_MEDIA_ATTACHMENT' values={{value: item.id}}
      //   defaultMessage='Attachment {value}' />
      ? ''
      : <FormattedMessage id="CALL_MEDIA_TITLE" defaultMessage="Media" />

    const onDrawerResize = (width) => {
      this.setState({ width })
    }

    const style = {
      width: this.state.width ? `${this.state.width}px` : undefined
    }

    return (
      <div className="call-media-panel" style={style}>
        <DrawerResizer onDrawerResize={onDrawerResize} maxAreaPercent={0.75} />
        <div className={`header ${item ? 'has-item' : ''}`}>
          <IconButton onTouchTap={onClose}><CloseIcon /></IconButton>
          <div className="header-title">{title}</div>
        </div>
        {item && <MediaEditor
          {...props}
          item={item}
          width={this.state.width}
          isEditable={true} />}
        {!item && <MediaGallery
          call={props.call}
          items={props.call.attachment.attachments}
          // renderItem={item => <MediaEditor item={item} isEditable={false} />}
          onSelect={onMediaItemSelect}
          onUploadFile={props.uploadFile}
          onSetPdf={props.setPdf}
          onSetSnackbar={props.setSnackbar}/>}
      </div>
    )
  }
}

export { CallMediaPanel }
