import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import adminActions from '../../../../../../actions/admin'
import { LDAPToggle } from './LDAPToggle'
import { FormattedMessage } from 'react-intl'
import { Loading } from 'skylight-common'
import { FooterActionButtons } from '../../../../../Common/FooterActionButtons'
import { LdapOptions } from '../../../../Admin/AdminAuth/AuthApps/LdapOptions'
import { NEXT_GEN_FIELD_TYPES } from '../../../../../Common/FormBuilder/NextGenFieldTypes'

import './LDAP.css'

class LDAP extends React.Component {
  componentDidMount() {
    this.props.onLdapAppLoad()
    this.props.onAuthAppLoad()
  }

  onToggleLdap = value => {
    const { ldapApp } = this.props.auth
    const { original } = ldapApp || {}

    if (!value && original) {
      this.props.onLdapAppChange('options', original.options)
    }
    this.props.onLdapAppChange('ldapEnabled', value)
  }

  onLdapFormFieldChange = (prop, value) => {
    const { ldapApp } = this.props.auth
    const { item } = ldapApp || {}
    const currentValue = item[prop]
    const valueChanged = currentValue !== value

    if (!valueChanged) {
      return
    }

    this.props.onLdapAppChange(prop, value)
  }

  render() {
    const { ldapApp, authApp } = this.props.auth
    const { ldapEnabled = false } = ldapApp.item || {}
    const { enabled: samlEnabled = false } = authApp.item || {}

    const onSave = () => {
      ldapApp.hasChanged && this.props.onLdapAppSave(ldapApp.item)
    }

    const onCancel = () => {
      this.props.onLdapAppCancel()
    }

    return (
      <div className={classNames('ldap-settings', this.props.className)}>
        <Loading
          isLoading={ldapApp.isLoading || authApp.isLoading}
          error={
            ldapApp.loadError
              ? (
              <FormattedMessage
                id={ldapApp.loadError}
                defaultMessage="Failed to load LDAP configuration" />
                )
              : null
          }>
          <div className="ldap-settings-content">
            <LDAPToggle ldapApp={ldapApp} authApp={authApp} onToggleLdap={this.onToggleLdap} />
            {ldapEnabled && samlEnabled
              ? <LdapOptions
                fieldTypes={NEXT_GEN_FIELD_TYPES}
                ldapApp={ldapApp}
                onFormFieldChange={this.onLdapFormFieldChange} />
              : null}
            {ldapApp.hasChanged ? <FooterActionButtons onCancel={onCancel} onSave={onSave} /> : null}
          </div>
        </Loading>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.admin.auth
})

export default connect(mapStateToProps, adminActions)(LDAP)
