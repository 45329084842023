import skylight from 'skylight-js-sdk'

import * as ActionTypes from '../../actions/types'

const initialState = {
  state: skylight.socket.STATES.DISCONNECTED
}

const socket = (state = initialState, payload, all) => {
  switch (payload.type) {
    case ActionTypes.SOCKET_STATE_CHANGED:
      return { ...state, state: payload.state }
    default:
      return state
  }
}

export default socket
