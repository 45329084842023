import React from 'react'
import { GridView, CursorTooltip, shiftSelect } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { ApplicationTile } from './ApplicationTile'
import { EmptyListView } from './EmptyListView'

import './ApplicationGrid.css'

const ApplicationGrid = (props) => {
  const { list, permissions } = props

  const onSelect = (v) => {
    props.onApplicationSelectItems(Array.isArray(v) ? v : [v])
  }

  const onShiftSelect = (id) => {
    const { items, selectedIds } = list
    const result = shiftSelect(id, selectedIds, { items, keyProp: 'id' })
    props.onApplicationSelectItems(result)
  }

  const renderItemActions = item => {
    const itemActions = props.getItemActions(item)
    return itemActions.map((a, i) =>
      <div
        key={i}
        className={classNames('item-action-button', a.className)}
        onTouchTap={e => {
          e.stopPropagation()
          a.action()
        }}>
        <CursorTooltip tooltip={<FormattedMessage id={a.tooltip.id} defaultMessage={a.tooltip.default} />}>
          <FormattedMessage className="item-action-button-label" id={a.title.id} defaultMessage={a.title.default} />
        </CursorTooltip>
      </div>)
  }

  return (
    <div className="next-gen-application-grid">
      <GridView
        query={list.query}
        renderItem={<ApplicationTile
          permissions={permissions}
          renderActions={renderItemActions}
          onShiftSelect={onShiftSelect}
          onApplicationDashboardOpen={props.onApplicationDashboardOpen} />}
        heading={props.heading}
        onSelectItems={props.onApplicationSelectItems}
        isLoading={list.isLoading}
        items={list.items}
        done={list.done}
        useFixedHeight
        selectedIds={list.selectedIds}
        cols={4}
        total={list.total}
        gridFooter={props.listFooter}
        emptyLabel={<EmptyListView />}
        onQuery={props.onApplicationQuery}
        onCheck={onSelect} />
    </div>
  )
}

export { ApplicationGrid }
