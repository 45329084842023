import React from 'react'
import { TextFilter } from 'skylight-common'
import { TeamFilter } from '../../../../../containers/users'

const VALUE = 'title'

const UserTextFilter = props => {
  const onChange = (v) => {
    const filter = { ...props.query.filter, [VALUE]: v || '' }
    props.onUserFilterUpdated(filter)
  }

  return <TextFilter
    className="user-text-filter"
    title="PLACEHOLDER_USERNAME"
    value={props.query.filter[VALUE]}
    iconFilter={<TeamFilter />}
    onChange={onChange} />
}

export { UserTextFilter }
