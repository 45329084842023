import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import GeneralInformation from './GeneralInformation/GeneralInformation'
import { Sessions } from './Sessions/Sessions'
import { Versions } from './Versions'
import { Events } from './Events/Events'
import { Permissions } from './Permissions/Permissions'
import { Roles } from './Permissions/Roles'
import applicationActions from '../../../../../actions/nextGenApplication'

import './ApplicationDashboard.css'

class ApplicationDashboard extends Component {
  componentDidMount() {
    const { applicationId } = this.props.match.params
    this.props.onUpdateSelectedApplication(applicationId)
  }

  render() {
    const { match } = this.props
    const { applicationId } = match.params

    if (match.isExact) {
      return <Redirect from="/" to={`${match.url}/general`} />
    }

    return (
      <div className="app-dashboard">
        <Route path={`${match.url}/sessions`} exact render={() => <Sessions applicationId={applicationId} />} />
        <Route
          path={`${match.url}/sessions/:sessionId/events`}
          render={props => (
            <Events applicationId={applicationId} sessionId={props.match.params.sessionId} />
          )} />
        <Route path={`${match.url}/permissions`} exact render={() => <Permissions applicationId={applicationId} />} />
        <Route path={`${match.url}/permissions/roles`} exact component={Roles} />
        <Route path={`${match.url}/general`} exact render={() => <GeneralInformation applicationId={applicationId} />} />
        <Route path={`${match.url}/versions`} exact component={Versions} />
      </div>
    )
  }
}

export { ApplicationDashboard }

const mapStateToProps = (state) => ({
  permissions: state.application.permissions,
  application: state.application.selectedApplication,
  pathname: state.router.location.pathname
})

const dashboardActions = {
  ...applicationActions
}

const ApplicationDashboardContainer = connect(mapStateToProps, dashboardActions)(ApplicationDashboard)

export default ApplicationDashboardContainer
