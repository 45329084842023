import UAParser from 'ua-parser-js'
import { CONFIG } from 'skylight-common'

export const createSession = () => {
  const ua = new UAParser().getResult()

  return {
    device: {
      name: ua.browser.name,
      version: ua.browser.version,
      identifier: 'tab_' + Math.floor(Math.random() * 100000 + 1000).toString(),
      type: 'web'
    },
    os: {
      name: ua.os.name,
      version: ua.os.version
    },
    application: {
      name: 'Skylight Web',
      version: CONFIG.version
    }
  }
}
