import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ValueForProp } from '../PropUtils/PropUtils'
import './ItemProps.css'

export const ItemProps = (state) => {
  const { item, props, className = '' } = state
  const renderItem = (prop) => {
    return (
      <div key={prop.field} className={`item-prop ${prop.className || ''}`}>
        <span className="title">
          <FormattedMessage id={prop.title} defaultMessage={props.title} />
        </span>
        <span className="value">
          <ValueForProp item={item} prop={prop} />
        </span>
      </div>
    )
  }

  return (
    <div className={`item-props ${className}`}>
      {props.map(renderItem)}
    </div>
  )
}
