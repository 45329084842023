export const DEFAULT_QUERY = {
  limit: 50,
  skip: 0,
  sortBy: 'version',
  sortAsc: false,
  filter: { version: '' },
  filters: ['version']
}

export const VERSION_STAGES = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  PENDING: 'pending',
  UPGRADE_PENDING: 'upgrade_pending',
  INACTIVE: 'inactive'
}

export const VERSION_TEMPLATE = {
  applicationId: '',
  version: 1,
  description: '',
  comment: '',
  stage: '',
  configuration: {
    publishPolicy: 'auto',
    persistNavigation: true
  },
  name: '',
  modifiedAt: '',
  modifiedBy: '',
  createdAt: '',
  createdBy: ''
}

export const versionUpgradeIsFine = version => version.upgrade?.status !== 'errored'
export const versionUpgradeIsErrored = version => version.upgrade?.status === 'errored'
