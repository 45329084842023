import React from 'react'
import { ByList } from 'skylight-common'
import './ByStatus.css'

import { ASSIGNMENT_FILTER_TYPES } from '../../../../../lib/assignment'

const OPTIONS = [
  { text: 'STATUS_ASSIGNED', value: 'assigned' },
  { text: 'STATUS_COMPLETED', value: 'completed' },
  { text: 'STATUS_ARCHIVED', value: 'archived' }
]

const PENDING_OPTIONS = [
  { text: 'STATUS_PENDING', value: 'inProgress' },
  { text: 'STATUS_ERROR', value: 'error' }
]

const getOptions = type => {
  let options = OPTIONS

  if (type === ASSIGNMENT_FILTER_TYPES.PENDING) {
    options = PENDING_OPTIONS
  }
  return Promise.resolve(options)
}

const getValue = (originalValue, type) => {
  const options = (type === ASSIGNMENT_FILTER_TYPES.PENDING) ? PENDING_OPTIONS : OPTIONS

  return originalValue.filter(x => options.some(o => o.value === x))
}

const ByStatus = props => {
  const value = getValue(props.value, props.type)

  return <ByList
    className="by-status"
    title="ASSIGNMENT_STATUS"
    value={value}
    enableSorting={false}
    placeholder={{ id: 'NONE', defaultMessage: 'None' }}
    onChange={props.onChange}
    onRemove={props.onRemove}
    onGetItems={() => getOptions(props.type)} />
}

export { ByStatus }
