import React from 'react'
import { UserTitle } from 'skylight-common'
import classNames from 'classnames'
import first from 'lodash/first'
import { matchPath } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import {
  UserDetails, UserActionButtons, UserDetailsMenu,
  UserDetailsNextGen, UserActionButtonsNextGen,
  GroupDetailTabs, GroupActionButtons
} from '../../../containers/users'
import { CallUserDetails, CallUserActionButtons } from '../../../containers/callUser'
import {
  AssignmentDetails, AssignmentActionMenu, AssignmentTitle,
  AttachmentDetails, AttachmentActionButtons
} from '../../../containers/assignment'

import { ActivityDetails, CallDetails, CallActionMenu, CallEventDetails } from '../../../containers/activity'
import { MediaDetails, MediaActionMenu, MediaActionButtons } from '../../../containers/media'
import { DetailsDrawer } from '../../../containers/layout'
import { TokenDetails, TokenActionButtons } from '../../../containers/admin'
import EventDetails from '../../Home/HomeNextGen/Applications/ApplicationDashboard/Events/EventDetails/EventDetails'
import { isFailedImport } from '../../../lib/application/exportImport'
import { ApplicationDetails } from '../HomeNextGen/Applications/ApplicationList/ApplicationDetails/ApplicationDetails'
import { VersionDetails } from '../HomeNextGen/Applications/ApplicationDashboard/Versions/VersionDetails/VersionDetails'

const showListDetails = (list) => {
  return !list.hideDetails && list.selectedIds.length === 1
}

const getListSelectedItem = list => {
  if (list.selectedIds.length !== 1) return null
  const selectedId = first(list.selectedIds)

  return list.items.find(x => x.id === selectedId)
}

export const showDetails = (item, pagePath, props) => {
  if (item.hasOwnProperty('path') && !matchPath(pagePath, { path: item.path })) {
    return false
  }

  return item.showDetails(props)
}

export const renderDetails = (item, props) => {
  return item.render(item, props)
}

const renderList = (item, props, list) => {
  const dataItem = list.items.find(x => x.id === list.selectedIds[0])
  return (
    <DetailsDrawer
      className={classNames(`${item.type}-drawer`, { 'with-preview': list.previewId })}
      header={item.menu(dataItem, props)}
      title={item.title(dataItem, props)}
      footer={item.footer(dataItem, props)}
      onClose={() => props.onCloseDetails(list)}>
      {item.comp(dataItem, props)}
    </DetailsDrawer>
  )
}

const ASSIGNMENT = {
  // TODO: Change implementation
  showDetails: state => {
    const { list, item = {} } = state.assignment
    return item.mode === 'new' || showListDetails(list)
  },
  type: 'assignment',
  render: (item, state) => renderList(item, state, state.assignment.list),
  menu: () => null,
  footer: () => <AssignmentActionMenu />,
  comp: (item, state) => <AssignmentDetails />,
  title: (item, state) => <AssignmentTitle />
}

const USER = {
  showDetails: state => showListDetails(state.contact.users),
  type: 'user',
  render: (item, state) => renderList(item, state, state.contact.users),
  menu: item => <UserDetailsMenu />,
  footer: () => null,
  comp: (item, state) => <UserDetails />,
  title: item => <UserTitle user={item} />
}

const GROUP = {
  showDetails: state => state.contact.group.item,
  type: 'group',
  render: (item, state) => renderList(item, state, state.contact.groups),
  menu: () => null,
  footer: (item, state) => <GroupActionButtons />,
  comp: (item, state) => <GroupDetailTabs />,
  title: item => <FormattedMessage id="GROUP_DETAILS" defaultMessage="Group details" />
}

const USER_IN_GROUP = {
  showDetails: state => state.contact.user.mode === 'edit' && state.contact.user.item && state.contact.user.item.id,
  type: 'user',
  render: (item, state) => renderList(item, state, state.contact.users),
  menu: () => null,
  footer: (item, state) => <UserActionButtons />,
  comp: (item, state) => <UserDetails />,
  title: item => <FormattedMessage id="USER_DETAILS" defaultMessage="Group details" />
}

const USER_NEXT_GEN = {
  showDetails: state => state.contact.user.mode === 'edit' && state.contact.user.item && state.contact.user.item.id,
  type: 'user',
  render: (item, state) => renderList(item, state, state.contact.users),
  menu: () => null,
  footer: (item, state) => <UserActionButtonsNextGen />,
  comp: (item, state) => <UserDetailsNextGen />,
  title: item => <FormattedMessage id="USER_DETAILS" defaultMessage="Group details" />
}

const CALL_USER = {
  showDetails: state => showListDetails(state.callUser.list),
  type: 'user',
  render: (item, state) => renderList(item, state, state.callUser.list),
  menu: () => null,
  footer: (item, state) => <CallUserActionButtons />,
  comp: (item, state) => <CallUserDetails callUser />,
  title: () => <FormattedMessage id="USER_PROFILE" defaultMessage="User profile" />
}

const MEDIA = {
  showDetails: state => showListDetails(state.media.list),
  type: 'media',
  render: (item, state) => renderList(item, state, state.media.list),
  menu: () => null,
  footer: (item, state) => ['edit', 'new'].includes(state.media.item.mode)
    ? <MediaActionButtons />
    : <MediaActionMenu />,
  comp: (item, state) => <MediaDetails />,
  title: (item) => <FormattedMessage id="MEDIA_DETAILS" defaultMessage="Media details" />
}

const ACTIVITY_EVENT = {
  showDetails: state => showListDetails(state.activity.event.list),
  type: 'activity',
  render: (item, state) => renderList(item, state, state.activity.event.list),
  menu: item => null,
  footer: (item, state) => null,
  comp: (item, state) => <ActivityDetails />,
  title: (item) => <FormattedMessage id="ACTIVITY_DETAILS" defaultMessage="Activity details" />
}

const ACTIVITY_CALL = {
  showDetails: state => showListDetails(state.activity.call.list),
  type: 'call',
  render: (item, state) => renderList(item, state, state.activity.call.list),
  menu: item => null,
  footer: (item, state) => <CallActionMenu />,
  comp: (item, state) => <CallDetails />,
  title: (item) => <FormattedMessage id="CALL_DETAILS" defaultMessage="Call details" />
}

const ACTIVITY_CALL_EVENT = {
  showDetails: state => showListDetails(state.activity.call.event.list),
  type: 'callEvent',
  render: (item, state) => renderList(item, state, state.activity.call.event.list),
  menu: item => null,
  footer: () => null,
  comp: (item, state) => <CallEventDetails />,
  title: (item) => <FormattedMessage id="CALL_EVENT_DETAILS" defaultMessage="Event details" />
}

const TOKEN = {
  showDetails: state => showListDetails(state.admin.apiToken.list),
  type: 'token',
  render: (item, state) => renderList(item, state, state.admin.apiToken.list),
  menu: item => null,
  footer: () => <TokenActionButtons />,
  comp: (item, state) => <TokenDetails />,
  title: () => <FormattedMessage id="TOKEN_DETAILS" defaultMessage="API credential details" />
}

const ATTACHMENT_DETAILS_TITLE = {
  media: { id: 'MEDIA', defaultMessage: 'Media details' },
  decision: { id: 'SELECTION_DETAILS', defaultMessage: 'Selection details' },
  textInput: { id: 'TEXT_INPUT', defaultMessage: 'Text input' }
}
const ASSIGNMENT_ATTACHMENTS = {
  showDetails: s => s.assignment.attachment.list.selectedIds.length === 1,
  type: 'assignmentAttachment',
  render: (item, s) => renderList(item, s, s.assignment.attachment.list),
  menu: () => null,
  footer: (item, state) => state.assignment.attachment.item.mode === 'edit'
    ? <AttachmentActionButtons />
    : null,
  comp: () => <AttachmentDetails />,
  title: (_, props) => {
    const item = props.assignment.attachment.item.item
    const title = ATTACHMENT_DETAILS_TITLE[item.cardType]

    return title ? <FormattedMessage {...title} /> : null
  }
}

const SESSION_EVENT = {
  showDetails: state => showListDetails(state.application.event.list),
  type: 'sessionEvent',
  render: (item, state) => renderList(item, state, state.application.event.list),
  menu: () => null,
  footer: (item, state) => null,
  comp: (item, state) => <EventDetails />,
  title: (item) => <FormattedMessage id="SESSION_EVENT_DETAILS" defaultMessage="Event details" />
}

const APPLICATION = {
  showDetails: state => {
    return showListDetails(state.application.list) && isFailedImport(getListSelectedItem(state.application.list))
  },
  type: 'nextGenApplication',
  render: (item, state) => renderList(item, state, state.application.list),
  menu: () => null,
  footer: () => null,
  comp: (item) => <ApplicationDetails item={item} />,
  title: () => <FormattedMessage id="IMPORT_DETAILS" defaultMessage="Import details" />
}

const VERSION = {
  showDetails: state => {
    return showListDetails(state.application.version.list)
  },
  type: 'nextGenApplicationVersion',
  render: (item, state) => renderList(item, state, state.application.version.list),
  menu: () => null,
  footer: () => null,
  comp: (item) => <VersionDetails item={item} />,
  title: () => <FormattedMessage id="VERSION_DETAILS" defaultMessage="Version details" />
}

export const DETAILS = [
  { path: '/assignments', ...ASSIGNMENT },
  { path: '/contacts', ...USER },
  { path: '/contacts/users', ...USER },
  { path: '/contacts/groups', ...GROUP },
  { path: '/team', ...USER_IN_GROUP },
  { path: '/team/users', ...USER_IN_GROUP },
  { path: '/team/groups', ...GROUP },
  { path: '/calls', ...CALL_USER },
  { path: '/media', ...MEDIA },
  { path: '/activities', ...ACTIVITY_EVENT },
  { path: '/activities/events', ...ACTIVITY_EVENT },
  { path: '/activities/calls', ...ACTIVITY_CALL },
  { path: '/activities/calls/:id/events', ...ACTIVITY_CALL_EVENT },
  { path: '/admin', ...TOKEN },
  { path: '/assignments/attachments', ...ASSIGNMENT_ATTACHMENTS },
  { path: '/ng/applications/:applicationId/sessions/:sessionId/events', ...SESSION_EVENT },
  { path: '/ng/users', ...USER_NEXT_GEN },
  { path: '/ng/groups', ...GROUP },
  { path: '/ng/applications', ...APPLICATION },
  { path: '/ng/applications/:applicationId/versions', ...VERSION }
]
