import { connect } from 'mqtt/dist/mqtt'
import skylight from 'skylight-js-sdk'

export let clientId = null

const service = {
  client: null,
  connect: async(mqttSocketUrl, options) => {
    clientId = options.clientId

    service.client = connect(mqttSocketUrl, options)
    const client = service.client

    client.on('close', (e) => {
      skylight.logger.log('Closed mqtt connection', e)
      skylight.events.emit(
        skylight.mqtt.EVENTS.CONNECTION_STATE_CHANGE, skylight.mqtt.STATES.DISCONNECTED)
    })

    client.on('connect', async() => {
      skylight.events.emit(
        skylight.mqtt.EVENTS.CONNECTION_STATE_CHANGE, skylight.mqtt.STATES.CONNECTED)
    })

    client.on('error', (e) => {
      skylight.logger.log('Got mqtt error, disconnecting', e)
      skylight.events.emit(skylight.mqtt.EVENTS.ERROR, e)
    })

    client.on('message', (topic, msg) => {
      try {
        skylight.events.emit(skylight.mqtt.EVENTS.MESSAGE, {
          topic,
          message: JSON.parse(msg)
        })
      } catch (e) {
        skylight.events.emit(skylight.mqtt.EVENTS.ERROR, e)
      }
    })

    client.on('offline', (e) => {
      skylight.events.emit(
        skylight.mqtt.EVENTS.CONNECTION_STATE_CHANGE, skylight.mqtt.STATES.DISCONNECTED)
    })

    client.on('reconnect', () => {
      skylight.logger.log('Reconnected to mqtt')
      skylight.events.emit(
        skylight.mqtt.EVENTS.CONNECTION_STATE_CHANGE, skylight.mqtt.STATES.CONNECTED)
    })
    return client
  },
  subscribe: (client, topics) => {
    return new Promise((resolve, reject) => {
      client.subscribe(topics, (err, result) => {
        if (err) reject(err)
        else resolve(result)
      })
    })
  },
  unsubscribe: (client, topics) => {
    return new Promise((resolve, reject) => {
      client.unsubscribe(topics, (err, result) => {
        if (err) reject(err)
        else resolve(result)
      })
    })
  },
  disconnect: (client) => {
    client.end()
  },
  publish: (client, topic, message = {}, options = {}) => {
    return new Promise((resolve, reject) => {
      client.publish(topic, JSON.stringify(message), options, err => {
        if (err) reject(err)
        else resolve()
      })
    })
  }
}

const scopes = [skylight.auth.scopes.MESSAGE.SUBSCRIBE_EVENTS]
const mqttConfig = {
  service,
  topics: [
    { name: skylight.mqtt.TOPICS.TASKS, scopes },
    { name: skylight.mqtt.TOPICS.USER_APPLICATION, scopes: [skylight.auth.scopes.MESSAGE.SUBSCRIBE_SELF] },
    { name: skylight.mqtt.TOPICS.WORKFLOWS, scopes },
    {
      name: skylight.mqtt.TOPICS.USER,
      scopes: [skylight.auth.scopes.MESSAGE.SUBSCRIBE_SELF]
    }
  ]
}

export const subscribeToClientEvents = async() => {
  const name = skylight.mqtt.TOPICS.CLIENT.replace('{CLIENT_ID}', clientId)
  try {
    await skylight.mqtt.addTopicsAndSubscribe({ name })
  } catch (e) {
    console.error('an error occurred subscribing to client events', e)
    unsubscribeFromClientEvents()
    throw e
  }
}

export const unsubscribeFromClientEvents = () => {
  const name = skylight.mqtt.TOPICS.CLIENT.replace('{CLIENT_ID}', clientId)
  return skylight.mqtt.unsubscribe({ name })
}

export const subscribeToApplicationEvents = async applicationId => {
  const name = skylight.mqtt.TOPICS.APPLICATION.replace('{APPLICATION_ID}', applicationId)
  try {
    await skylight.mqtt.addTopicsAndSubscribe({ name, scopes: [skylight.auth.scopes.MESSAGE.SUBSCRIBE_SELF] })
  } catch (e) {
    console.error('an error occurred subscribing to application events', e)
    unsubscribeFromApplicationEvents(applicationId)
    throw e
  }
}

export const unsubscribeFromApplicationEvents = applicationId => {
  const name = skylight.mqtt.TOPICS.APPLICATION.replace('{APPLICATION_ID}', applicationId)
  return skylight.mqtt.unsubscribe({ name })
}

export default mqttConfig
