import React from 'react'
import { FormattedMessage } from 'react-intl'
import MenuItem from 'material-ui/MenuItem'
import { DeleteIcon, DetailsMenu } from 'skylight-common'
import './GroupActionMenu.css'

const GroupActionMenu = (props) => {
  const { item, mode } = props.group
  if (mode === 'new') {
    return null
  }

  const onDelete = () => {
    props.onConfirm(
      () => props.onGroupDeleteItems([item.id]),
      {
        title: 'GROUPS_REMOVE_DIALOG_TITLE',
        message: 'GROUPS_REMOVE_DIALOG_DESCRIPTION',
        action: 'ACTION_DELETE_GROUP'
      }
    )
  }

  return (
    <DetailsMenu className="group-action-menu">
      <MenuItem
        primaryText={<FormattedMessage id="DELETE_GROUP" defaultMessage="Delete group" />}
        leftIcon={<DeleteIcon />}
        onTouchTap={onDelete} />
    </DetailsMenu>
  )
}

export { GroupActionMenu }
