import React from 'react'
import { connect } from 'react-redux'
import lodash from 'lodash'
import { EventFilter } from './EventFilter'
import { TextFilterNextGen } from '../../../../../../Common/NextGen/TextFilterNextGen'

import eventsActions from '../../../../../../../actions/application/events'
import contactActions from '../../../../../../../actions/contact'

const VALUE = 'eventId'

const EventTextFilter = props => {
  const value = lodash.get(props, 'query.filter.eventId', '')
  const params = { preventPathSave: props.preventPathSave }

  const onChange = (v) => {
    const value = v.trim()
    const filter = { ...props.query.filter, [VALUE]: value }
    props.onSessionEventFilterUpdated(filter, params)
  }

  return <TextFilterNextGen
    value={value}
    className="event-text-filter"
    title="EVENT_ID_SEARCH"
    iconFilter={<EventFilter
      {...props}
      onEventFilterUpdated={f => props.onSessionEventFilterUpdated(f, params)}
      onEventFiltersUpdated={f => props.onSessionEventFiltersUpdated(f, params)}
      onFilterRemoved={t => props.onSessionEventFilterRemoved(t, params)} />}
    onChange={onChange} />
}

export { EventTextFilter }

const mapStateToProps = (state) => ({
  events: state.application.event.list.items,
  query: state.application.event.list.query,
  layout: state.layout,
  authUser: state.auth.user,
  session: state.application.session.item.item,
  application: state.application.selectedApplication
})

const filterActions = {
  ...eventsActions,
  onUserSelectItems: contactActions.onUserSelectItems
}

const EventTextFilterContainer = connect(mapStateToProps, filterActions)(EventTextFilter)

export default EventTextFilterContainer
