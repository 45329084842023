import React from 'react'
import { formatDate } from '../../../../../../../lib/date'
import { FormattedDateTime } from 'skylight-common'
import UserDetailsLink from '../../../../../Contacts/UserDetails/UserDetailsLink'
import { EventType } from './EventType'

const COLUMNS = [
  {
    name: 'eventType',
    title: 'SESSION_EVENT_TYPE',
    sortable: false,
    groupBy: x => x.eventType,
    render: x => <EventType event={x} />
  },
  {
    name: 'createdBy',
    title: 'CREATED_BY',
    groupBy: x => x.createdBy,
    render: item => <UserDetailsLink nextGen userId={item.createdBy} />
  },
  {
    name: 'createdAt',
    title: 'CREATED',
    groupBy: x => formatDate(new Date(x.createdAt)),
    render: x => <FormattedDateTime value={x.createdAt} />
  },
  {
    name: 'deviceId',
    title: 'DEVICE',
    sortable: false,
    groupBy: x => x.deviceId,
    render: (x, props) => {
      const device = x.device
      if (!device) {
        return null
      }

      const { formattedName } = device.device
      return formattedName
    }
  }
]

export { COLUMNS }
