import React from 'react'
import { FormattedMessage } from 'react-intl'
import { SkylightHelpMenuLegacy, SkylightHelpMenuNextGen } from '../../SkylightHelpMenu'
import { TopBarPopoverButton } from './TopBarPopoverButton'
import InfoQuestionSolid from '../../../../Common/Icons/InfoQuestionSolid'

const SkylightHelpButton = props => {
  const MenuComp = props.nextGen ? SkylightHelpMenuNextGen : SkylightHelpMenuLegacy
  return (
    <TopBarPopoverButton
      popoverBody={<MenuComp {...props} className="top-bar-popover-menu" />}
      tooltip={<FormattedMessage id="HELP" defaultMessage="Help" />}>
      <InfoQuestionSolid />
    </TopBarPopoverButton>
  )
}

export { SkylightHelpButton }
