import React from 'react'
import skylight from 'skylight-js-sdk'
import { PdfPageList } from '../PdfPageList'
import { PdfPreview } from '../PdfPreview'
import { loadPdfScript } from './loadPdfScript'
import './PdfEditor.css'

class PdfEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true,
      pdf: null,
      pageNumber: 0,
      numPages: 0
    }

    PdfEditor.loadPdfScriptPromise = loadPdfScript()
  }

  async setStateFromProps(props) {
    PdfEditor.pdfJs = await PdfEditor.loadPdfScriptPromise
    if (!props.pdf) return
    if (props.pdf.id === this.state.id) return

    this.setState({ isLoading: true })

    const pdfUrl = URL.createObjectURL(props.pdf.file)
    try {
      const pdf = await PdfEditor.pdfJs.getDocument(pdfUrl).promise
      this.setState({
        id: props.pdf.id,
        isLoading: false,
        numPages: pdf.numPages,
        pageNumber: 1,
        pdf
      })
    } catch (err) {
      skylight.logger.error('PdfEditor', err)
    }
  }

  // eslint-disable-next-line
  componentWillReceiveProps (nextProps) {
    this.setStateFromProps(nextProps)
  }

  render() {
    const { isLoading, numPages, pdf, pageNumber } = this.state
    const { preview } = this.props
    return (
      <div className="pdf-editor">
        <PdfPageList
          isLoading={isLoading}
          numPages={numPages}
          pageNumber={pageNumber}
          pdf={pdf}
          scale={0.5}
          onChange={(pageNumber) => this.setState({ pageNumber })} />
        <div className="main">
          <PdfPreview
            isLoading={isLoading}
            pageNumber={pageNumber}
            pdf={pdf}
            preview={preview}
            scale={2}
            onChange={this.props.onChange} />
        </div>
      </div>
    )
  }
}

export { PdfEditor }
