import React from 'react'
import { ImagePicker, isMobile, SUPPORTED_IMAGE_TYPES } from 'skylight-common'

import './NewMediaDialog.css'

const TRANSLATIONS = {
  SET_MEDIA: 'UPLOAD_MEDIA',
  TITLE: 'TITLE_UPLOAD_MEDIA',
  DRAG_IMAGE: 'DRAG_MEDIA',
  SELECT_PREVIEW: 'SELECT_MEDIA',
  SELECT_DEVICE_PREVIEW: 'SELECT_DEVICE_MEDIA'
}

const NewMediaDialog = (props) => {
  const { dialogType } = props
  const onUploadUrl = (url) => {} // unused
  const onShowError = error => {
    props.onShowError(error, { className: 'snackbar-common-error' })
  }

  return (
    <ImagePicker
      {...props}
      allowedTypes={[...SUPPORTED_IMAGE_TYPES, 'video', 'pdf']}
      allowMultiUpload={true}
      translations={TRANSLATIONS}
      className="select-media-source"
      dialogType={dialogType}
      loaderType="circular"
      dialogProps={{ autoScrollBodyContent: true }}
      onUnsupportedFileError={onShowError}
      droppable={!isMobile()}
      onlyAllowFileUploads={true}
      onUpload={props.onUploadedMedia}
      onUploadUrl={onUploadUrl} />
  )
}

export { NewMediaDialog }
