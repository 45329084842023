import * as ActionTypes from '../types'
import { push } from 'react-router-redux'
import { createFilterActions, createListActions, createItemActions } from 'skylight-common'
import skylight from 'skylight-js-sdk'
import { getSessions, closeSession, closeSessions } from '../../svc/application/sessions'
import { DEFAULT_QUERY } from '../../lib/application/session'

const LIST_ACTIONS = createListActions({
  prefix: ActionTypes.PREFIX_SESSION.toUpperCase(),
  handler: ActionTypes.PREFIX_SESSION,
  onGetList: s => s.application.session.list,
  onLoad: async(query, { dispatch, getState }) => {
    const state = getState()
    const { applicationId } = state.application.selectedApplication
    return getSessions(applicationId, query)
  }
})

const FILTER_ACTIONS = createFilterActions({
  baseUrl: '/ng/applications/:applicationId/sessions',
  redirect: false,
  selector: state => state.application.session.list.query,
  listPrefix: ActionTypes.PREFIX_SESSION.toUpperCase(),
  actionPrefix: ActionTypes.PREFIX_SESSION,
  DEFAULT_QUERY,
  loadItems: LIST_ACTIONS.onSessionLoadItems,
  push
})

const refreshSessionsList = () => (dispatch, getState) => {
  const state = getState()
  dispatch(LIST_ACTIONS.onSessionLoadItems(state.application.session.list.query))
}

const redirectToNewQuery = FILTER_ACTIONS.onSessionQueryUpdated

const sessionSortUpdate = (sortBy, sortAsc) =>
  redirectToNewQuery(q => ({ ...q, sortBy, sortAsc }))

const onSaveSession = async(session, ctx) => {
  const state = ctx.getState()
  const applicationId = state.application.selectedApplication.applicationId
  const userId = state.auth.user.id
  // Right now sessions are created with dummy data to make it possible to test this feature.
  // TODO: Change to rear session data once UI is ready.
  await skylight.we20.session
    .create(applicationId, { applicationId, participants: [{ id: userId, type: 'user' }], version: 1 }, 'e78fa929627a459dbb570a92')
}

const ITEM_ACTIONS = createItemActions({
  prefix: ActionTypes.PREFIX_SESSION.toUpperCase(),
  handler: ActionTypes.PREFIX_SESSION,
  onGetList: s => s.application.session.list,
  onSave: onSaveSession,
  onLoad: async(item, res, ctx) => {
    return item
  },
  onLoadItems: LIST_ACTIONS.onSessionLoadItems
})

const sessionEventsView = (applicationId, sessionId) => dispatch => {
  dispatch(push(`/ng/applications/${applicationId}/sessions/${sessionId}/events`))
}

const sessionClose = (applicationId, sessionId) => async dispatch => {
  dispatch({ type: ActionTypes.SESSION_CLOSE_START, sessionId })
  try {
    await closeSession(applicationId, sessionId)
    dispatch({ type: ActionTypes.SESSION_CLOSE_SUCCESS, sessionId })
    dispatch(refreshSessionsList())
  } catch (e) {
    console.error(e.message)
    dispatch({ type: ActionTypes.SESSION_CLOSE_ERROR, message: e.message })
  }
}

const sessionsClose = (applicationId, sessionIds) => async dispatch => {
  dispatch({ type: ActionTypes.SESSIONS_CLOSE_START, sessionIds })
  try {
    await closeSessions(applicationId, sessionIds)
    dispatch({ type: ActionTypes.SESSIONS_CLOSE_SUCCESS, sessionIds })
    dispatch(refreshSessionsList())
  } catch (e) {
    console.error(e.message)
    dispatch({ type: ActionTypes.SESSIONS_CLOSE_ERROR, message: e.message })
  }
}

export default {
  ...LIST_ACTIONS,
  ...FILTER_ACTIONS,
  ...ITEM_ACTIONS,
  onSessionSortUpdate: sessionSortUpdate,
  onViewSessionEvents: sessionEventsView,
  onCloseSession: sessionClose,
  onCloseSessions: sessionsClose
}
