import { connect } from 'react-redux'

import {
  ConfirmationDialog as ConfirmationDialogComp,
  ActiveFilters as ActiveFiltersComp
} from 'skylight-common'
import _ from 'lodash'
import { AddAssignment as AddAssignmentComp } from '../App/Home/Layout/Dialogs/AddAssignment'
import { AddDeviceDialog as AddDeviceDialogComp } from '../App/Home/Layout/Dialogs/AddDeviceDialog'
import { AddTokenDialog as AddTokenDialogComp } from '../App/Home/Layout/Dialogs/AddTokenDialog'
import { AssignApplication as AssignApplicationComp } from '../App/Home/Layout/Dialogs/AssignApplication'
import { EnableBrowserPermissions as EnableBrowserPermissionsComp } from '../App/Home/Layout/Dialogs/BrowserPermissions'
import { NetScan as NetScanComp } from '../App/Home/Layout/Dialogs/NetScan'
import { NewMediaDialog as NewMediaDialogComp } from '../App/Home/Layout/Dialogs/NewMediaDialog'
import { ShowTokenCredentialsDialog as ShowTokenCredentialsDialogComp } from '../App/Home/Layout/Dialogs/ShowTokenCredentialsDialog'
import { AddToExistingGroup as AddToExistingGroupComp } from '../App/Home/Layout/Dialogs/AddToExistingGroupDialog/AddToExistingGroupDialog'
import { SetupWifiDialog as SetupWifiDialogComp } from '../App/Home/Layout/Dialogs/SetupWifi'
import { TemporaryPasswordDialog as TemporaryPasswordDialogComp } from '../App/Home/Layout/Dialogs/TemporaryPasswordDialog'
import { ResetPasswordDialog as ResetPasswordDialogComp } from '../App/Home/Layout/Dialogs/ResetPasswordDialog'
import { ChangePasswordDialog as ChangePasswordDialogComp } from '../App/Home/Layout/Dialogs/ChangePasswordDialog'
import { DuplicateAppDialog as DuplicateAppDialogComp } from '../App/Home/Layout/Dialogs/DuplicateAppDialog'
import { VerifyCredentialsDialog as VerifyCredentialsDialogComp } from '../App/Home/Layout/Dialogs/VerifyCredentials'
import { SetupNewApplication as SetupNewApplicationComp } from '../App/Home/Layout/Dialogs/SetupNewApplication'
import { PublishVersion as PublishVersionComp } from '../App/Home/Layout/Dialogs/PublishVersion'
import { SessionEventJsonDataDialog as SessionEventJsonDataDialogComp } from '../App/Home/Layout/Dialogs/SessionEventJsonDataDialog'
import { AddApplicationParticipants as AddApplicationParticipantsComp } from '../App/Home/Layout/Dialogs/AddApplicationParticipants'
import { AddApplicationRole as AddRoleComp } from '../App/Home/Layout/Dialogs/AddApplicationRole'
import { AccountSettingsDialog as AccountSettingsDialogComp } from '../App/Home/Layout/Dialogs/AccountSettingsDialog'
import { NewUserDialog as NewUserDialogComp } from '../App/Home/Layout/Dialogs/NewUserDialog'
import { VersionDialog as VersionDialogComp } from '../App/Home/Layout/Dialogs/VersionDialog'
import { ImportArchiveUpload as ImportArchiveUploadComp } from '../App/Home/Layout/Dialogs/ImportArchiveUpload'
import {
  MediaDownloadConfirmation as MediaDownloadConfirmationComp
} from '../App/Home/Layout/Dialogs/MediaDownloadConfirmation/MediaDownloadConfirmation'
import { QueryFilterDialog as QueryFilterDialogComp } from '../App/Home/Layout/Dialogs/QueryFilterDialog'
import { UserAttributes as UserAttributesComp } from '../App/Home/Layout/Dialogs/UserAttributes'

import adminActions from '../actions/admin'
// import deviceActions from '../actions/device'
import browserActions from '../actions/browser'
import dialogActions from '../actions/dialog'
import mediaActions from '../actions/media'
import netScanActions from '../actions/netscan'
import workflowActions from '../actions/workflow'
import assignmentActions from '../actions/assignment'
import activityActions from '../actions/activity'
import contactActions from '../actions/contact'
import errorActions from '../actions/error'
import callUserActions from '../actions/callUser'
import profileActions from '../actions/profile'
import loginActions from '../actions/login'
import nextGenApplicationActions from '../actions/nextGenApplication'
import versionActions from '../actions/application/versions'
import participantsActions from '../actions/application/participants'
import roleActions from '../actions/application/roles'
import appActions from '../actions/app'
import authActions from '../actions/auth'
import localeActions from '../actions/locale'
import sessionActions from '../actions/application/sessions'
import applicationImportActions from '../actions/applicationImport'
import ldapActions from '../actions/ldap'
import applicationUpgradeActions from '../actions/applicationUpgrade'

// Add Device
const addDevice = (state, props) => ({
  dialog: state.dialog,
  device: state.device
})

const addDeviceActions = {
  ...dialogActions
  // ...deviceActions
}

const AddDeviceDialog = connect(addDevice, addDeviceActions)(AddDeviceDialogComp)

// Confirmation
const confirmation = (state, props) => ({
  dialog: state.dialog
})

const ConfirmationDialog = connect(confirmation, dialogActions)(ConfirmationDialogComp)

// Browser
const browserPerms = (state, props) => ({
  dialog: state.dialog,
  browser: state.browser
})

const browserPermsActions = {
  ...dialogActions,
  ...browserActions
}

const EnableBrowserPermissions = connect(browserPerms, browserPermsActions)(EnableBrowserPermissionsComp)

// New Media
const newMedia = (state, props) => ({
  dialog: state.dialog,
  media: state.media
})

const newMediaActions = {
  ...dialogActions,
  ...mediaActions,
  ...errorActions
}

const NewMediaDialog = connect(newMedia, newMediaActions)(NewMediaDialogComp)

// Add Token
const addToken = (state, props) => ({
  dialog: state.dialog,
  item: state.admin.apiToken.item
})

const addTokenActions = {
  ...dialogActions,
  ...adminActions
}

const AddTokenDialog = connect(addToken, addTokenActions)(AddTokenDialogComp)

// Token Credentials
const tokenCreds = (state, props) => ({
  dialog: state.dialog
})

const tokenCredsActions = {
  ...dialogActions,
  ...adminActions
}

const ShowTokenCredentialsDialog = connect(tokenCreds, tokenCredsActions)(ShowTokenCredentialsDialogComp)

// Version
const version = state => ({
  dialog: state.dialog,
  app: state.app
})

const VersionDialog = connect(version, dialogActions)(VersionDialogComp)

// Assign application
const assignApplication = (state) => ({
  dialog: state.dialog
})

const assignApplicationActions = {
  ...dialogActions,
  ...workflowActions
}

const AssignApplication = connect(assignApplication, assignApplicationActions)(AssignApplicationComp)

const addAssignment = state => ({
  dialog: state.dialog,
  assignment: state.assignment,
  workflow: state.workflow
})

const addAssignmentActions = {
  ...dialogActions,
  ...assignmentActions,
  ...workflowActions
}

const AddAssignment = connect(addAssignment, addAssignmentActions)(AddAssignmentComp)

const activeFilters = state => ({
  dialog: state.dialog,
  router: state.router
})

const activeFiltersActions = {
  ...dialogActions,
  ...nextGenApplicationActions,
  ...versionActions,
  ...sessionActions,
  ...assignmentActions,
  ...workflowActions,
  ...mediaActions,
  ...adminActions,
  ...activityActions,
  ...contactActions,
  ...callUserActions
}

const ActiveFilters = connect(activeFilters, activeFiltersActions)(ActiveFiltersComp)

const netscan = state => ({
  dialog: state.dialog,
  netscan: state.netscan,
  network: state.network,
  mqtt: state.mqtt
})

const netScanDialogActions = { ...dialogActions, ...netScanActions }

const NetScan = connect(netscan, netScanDialogActions)(NetScanComp)

const group = (state, props) => ({
  dialog: state.dialog,
  users: state.contact.users,
  groups: state.contact.groups
})

const groupsActions = {
  ...dialogActions,
  ...contactActions,
  ...errorActions
}

const AddToExistingGroupDialog = connect(group, groupsActions)(AddToExistingGroupComp)

const setupWifi = state => ({
  dialog: state.dialog
})

const SetupWifiDialog = connect(setupWifi, dialogActions)(SetupWifiDialogComp)

const temporaryPassword = state => ({
  dialog: state.dialog
})

const TemporaryPasswordDialog = connect(temporaryPassword, dialogActions)(TemporaryPasswordDialogComp)

const resetPassword = state => ({
  dialog: state.dialog,
  auth: state.auth,
  settings: state.profile.settings
})

const changePassword = state => ({
  dialog: state.dialog,
  profile: state.profile,
  auth: state.auth,
  login: state.login
})

const changePasswordActions = {
  ...dialogActions,
  ...profileActions
}

const ResetPasswordDialog = connect(resetPassword, changePasswordActions)(ResetPasswordDialogComp)
const ChangePasswordDialog = connect(changePassword, changePasswordActions)(ChangePasswordDialogComp)

const duplicateAppDialog = state => ({
  dialog: state.dialog
})

const duplicateAppDialogActions = {
  ...dialogActions,
  ...workflowActions
}

const DuplicateAppDialog = connect(duplicateAppDialog, duplicateAppDialogActions)(DuplicateAppDialogComp)

const verifyCredentials = state => ({
  dialog: state.dialog,
  auth: state.auth,
  errors: state.login.verify.errors,
  isLoading: state.login.verify.isLoading
})

const verifyCredentialsActions = {
  ...dialogActions,
  ...loginActions
}

const VerifyCredentialsDialog = connect(verifyCredentials, verifyCredentialsActions)(VerifyCredentialsDialogComp)

const setupNewApp = state => ({
  dialog: state.dialog,
  newApplication: state.application.newApplication
})

const setupNewAppActions = {
  ...dialogActions,
  ...nextGenApplicationActions,
  ...applicationImportActions,
  ...errorActions
}

const SetupNewApplication = connect(setupNewApp, setupNewAppActions)(SetupNewApplicationComp)

const publishVersion = state => ({
  dialog: state.dialog
})

const publishVersionActions = {
  ...dialogActions,
  ...versionActions
}

const PublishVersion = connect(publishVersion, publishVersionActions)(PublishVersionComp)

const sessionEventJsonDataDialog = state => ({
  dialog: state.dialog,
  event: state.application.event.item
})

const sessionEventJsonDataActions = {
  ...dialogActions
}

const SessionEventJsonDataDialog = connect(sessionEventJsonDataDialog, sessionEventJsonDataActions)(SessionEventJsonDataDialogComp)

const addRole = state => ({
  dialog: state.dialog,
  item: state.application.role.item
})

const addRoleActions = {
  ...dialogActions,
  ...roleActions,
  ...appActions
}

const AddRole = connect(addRole, addRoleActions)(AddRoleComp)

const addApplicationParticipants = (state) => ({
  dialog: state.dialog,
  roles: state.application.role.list.items
})

const addApplicationParticipantsActions = {
  ...dialogActions,
  ...participantsActions
}

const AddApplicationParticipants = connect(addApplicationParticipants, addApplicationParticipantsActions)(AddApplicationParticipantsComp)

const accountSettings = (state) => _.pick(state, ['dialog', 'auth', 'profile', 'locale'])

const accountSettingsActions = {
  ...profileActions,
  ...dialogActions,
  ...localeActions,
  ...authActions
}

const AccountSettingsDialog = connect(accountSettings, accountSettingsActions)(AccountSettingsDialogComp)

const newUser = (state) => _.pick(state, ['dialog', 'auth', 'profile', 'contact', 'locale'])

const newUserActions = {
  ...profileActions,
  ...dialogActions,
  ...localeActions,
  ...authActions,
  ...contactActions
}

const NewUserDialog = connect(newUser, newUserActions)(NewUserDialogComp)

const importArchiveUpload = state => ({
  dialog: state.dialog,
  upload: state.application.applicationImport.upload
})

const importArchiveUploadActions = {
  ...dialogActions,
  ...applicationImportActions
}

const ImportArchiveUpload = connect(importArchiveUpload, importArchiveUploadActions)(ImportArchiveUploadComp)

const upgradeApplication = state => ({
  dialog: state.dialog,
  upload: state.application.applicationUpgrade.upload
})
const upgradeApplicationDialogActions = {
  ...dialogActions,
  ...applicationUpgradeActions
}
const UploadUpgradeApplication = connect(upgradeApplication, upgradeApplicationDialogActions)(ImportArchiveUploadComp)

const mediaDownloadConfirmation = state => ({
  dialog: state.dialog
})

const mediaDownloadConfirmationActions = {
  ...dialogActions,
  onDownloadMediaItem: mediaActions.onDownloadMediaItem
}

const MediaDownloadConfirmation = connect(mediaDownloadConfirmation, mediaDownloadConfirmationActions)(MediaDownloadConfirmationComp)

const queryFilter = state => ({
  dialog: state.dialog,
  ldapApp: state.admin.auth.ldapApp,
  groups: state.contact.groups.items
})

const queryFilterActions = {
  ...dialogActions,
  ...ldapActions
}

const QueryFilterDialog = connect(queryFilter, queryFilterActions)(QueryFilterDialogComp)

const userAttributes = state => ({
  dialog: state.dialog,
  ldap: state.admin.auth.ldapApp
})

const userAttributesActions = {
  ...dialogActions,
  ...ldapActions
}

const UserAttributesDialog = connect(userAttributes, userAttributesActions)(UserAttributesComp)

export {
  AddDeviceDialog,
  ConfirmationDialog,
  EnableBrowserPermissions,
  NetScan,
  NewMediaDialog,
  AddTokenDialog,
  ShowTokenCredentialsDialog,
  VersionDialog,
  AssignApplication,
  AddAssignment,
  ActiveFilters,
  AddToExistingGroupDialog,
  SetupWifiDialog,
  TemporaryPasswordDialog,
  ChangePasswordDialog,
  ResetPasswordDialog,
  DuplicateAppDialog,
  VerifyCredentialsDialog,
  SetupNewApplication,
  PublishVersion,
  SessionEventJsonDataDialog,
  AddRole,
  AddApplicationParticipants,
  AccountSettingsDialog,
  NewUserDialog,
  ImportArchiveUpload,
  MediaDownloadConfirmation,
  QueryFilterDialog,
  UserAttributesDialog,
  UploadUpgradeApplication
}
