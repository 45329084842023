import React, { Component } from 'react'
import { GroupMapping } from '../Mappings/GroupMapping'
import './GroupBody.css'

const NONE_ATTRIBUTE = { value: null, title: 'LDAP_FIELD_METHOD_NONE' }

class GroupBody extends Component {
  getFormattedGroups() {
    const { groups = [] } = this.props

    return groups.map((group) => {
      return {
        value: group.name,
        title: group.name
      }
    })
  }

  getGroupsWithNone() {
    return [NONE_ATTRIBUTE, ...this.getFormattedGroups()]
  }

  getAvailableOptions(includeValue = '', additionalOptions = []) {
    const { groupFilters = [] } = this.props

    return [...additionalOptions, ...this.getFormattedGroups()].filter(({ value }) => {
      return value === includeValue || !groupFilters.some(({ name }) => name === value)
    })
  }

  onChange = (idx) => (field, value) => {
    if (value === null) {
      return
    }

    this.props.onGroupFilterChange(idx, field, value)
  }

  onDelete = (idx) => () => {
    this.props.onDelete(idx, 'groupFilters')
  }

  render() {
    const { groupFilters, enableNewRow = true } = this.props
    const newRowOptions = this.getAvailableOptions(null, [NONE_ATTRIBUTE])

    return (
      <div className="query-filter-dialog-mapping-body group-body">
        {groupFilters.map(({ name, filter }, idx) =>
          <GroupMapping
            key={idx}
            group={name}
            filter={filter}
            options={this.getAvailableOptions(name)}
            onChange={this.onChange(idx)}
            onDelete={this.onDelete(idx)} />
        )}
        {newRowOptions.length > 1 && <GroupMapping
          key={groupFilters.length}
          options={newRowOptions}
          onChange={this.onChange(groupFilters.length)}
          disabled={!enableNewRow} />}
        {groupFilters.length === 0 && <GroupMapping
          key="newRow"
          options={[NONE_ATTRIBUTE]}
          disabled />}
      </div>
    )
  }
}

export { GroupBody }
