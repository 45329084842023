const DEFAULT_OPTIONS = {
  sortOrder: -1
}

const MIN_ELAPSED = 1

const sum = (items, filter, selector) => items.reduce((acc, item) => {
  if (!filter(item)) {
    return acc
  }

  acc += (selector(item) || 0)
  return acc
}, 0)

const findIosVal = (x, prop) => {
  if (!x) return 0
  const found = x.values.find(x => x.hasOwnProperty(prop))
  return found[prop]
}

const WEB_TO_WEB_CONVERT = (ctx, item) => {
  const items = item.stats.pcStats
  const timestamp = items[0].timestamp
  return {
    // debug: items,
    source: ctx.source,
    timestamp: new Date(timestamp),
    bytesSent: sum(items, x => x.type === 'transport', x => x.bytesSent),
    bytesReceived: sum(items, x => x.type === 'transport', x => x.bytesReceived),
    videoBytesSent: sum(items, x => x.type === 'outbound-rtp' && x.mediaType === 'video', x => x.bytesSent),
    audioBytesSent: sum(items, x => x.type === 'outbound-rtp' && x.mediaType === 'audio', x => x.bytesSent),
    availableOutgoingBitrate: sum(items, x => x.type === 'candidate-pair', x => x.availableOutgoingBitrate),
    // SSRC
    packetsLost: sum(items, x => x.type === 'inbound-rtp', x => x.packetsLost),
    firsReceived: sum(items, x => x.type === 'inbound-rtp', x => x.firCount),
    framesSent: sum(items, x => x.type === 'track' && !x.remoteSource, x => x.framesSent),
    frameWidthSent: sum(items, x => x.type === 'track' && !x.remoteSource, x => x.frameWidth),
    frameHeightSent: sum(items, x => x.type === 'track' && !x.remoteSource, x => x.frameHeight),
    limitedResolution: ''
  }
}

const CONVERTERS = [
  {
    match: item => !item.session,
    convert: () => null
  },
  {
    match: item => item.session.device.type === 'web',
    convert: WEB_TO_WEB_CONVERT
  },
  // Order is important. `android` os would swallow the `wearable`.
  {
    match: item => item.session.device.type === 'wearable',
    convert: (ctx, item) => {
      const items = item.stats.pcStats
      const bwe = items.find(x => x.id === 'bweforvideo')
      const ssrc = items.find(x =>
        x.type === 'ssrc' &&
        x.members.mediaType === 'video' &&
        x.id.indexOf('_send') > -1)
      // This timestampUs is milliseconds. Do not / 1000.
      const timestamp = ssrc.timestampUs

      const res = {
        source: ctx.source,
        timestamp: new Date(timestamp),
        //
        bytesSent: sum(items, x => x.type === 'ssrc' && x.id.indexOf('_send') > -1, x => x.members.bytesSent),
        bytesReceived: sum(items, x => x.type === 'ssrc' && x.id.indexOf('_recv') > -1, x => x.members.bytesReceived),
        //
        sendBandwidth: bwe.members.googAvailableSendBandwidth,
        receiveBandwidth: bwe.members.googAvailableReceiveBandwidth,
        transmitBitrate: bwe.members.googTransmitBitrate,
        retransmitBitrate: bwe.members.googRetransmitBitrate,
        availableOutgoingBitrate: bwe.members.googTransmitBitrate,
        //
        packetsLost: ssrc.members.packetsLost,
        firsReceived: ssrc.members.googFirsReceived,
        frameRateSent: ssrc.members.googFrameRateSent,
        frameWidthSent: ssrc.members.googFrameWidthSent,
        frameHeightSent: ssrc.members.googFrameHeightSent,
        limitedResolution: '' +
          (ssrc.members.googBandwidthLimitedResolution ? 'bandwidth, ' : '') +
          (ssrc.members.googCpuLimitedResolution ? 'cpu' : '')
      }

      return res
    }
  },
  {
    match: item => item.session.os.name.toLowerCase() === 'android',
    convert: (ctx, item) => {
      const items = item.stats.pcStats
      // This timestampUs is MICRO seconds.
      const timestamp = items[0].timestampUs / 1000
      return {
        source: ctx.source,
        timestamp: new Date(timestamp),
        bytesSent: sum(items, x => x.type === 'transport', x => x.members.bytesSent),
        bytesReceived: sum(items, x => x.type === 'transport', x => x.members.bytesReceived),
        videoBytesSent: sum(items, x => x.type === 'inbound-rtp' && x.mediaType === 'video', x => 1 * x.members.bytesReceived), // 8* for bits
        videoBytesReceived: sum(items, x => x.type === 'outbound-rtp' && x.mediaType === 'video', x => 1 * x.members.bytesSent), // 8* for bits
        framesDecoded: sum(items, x => x.members.framesDecoded > 0 && x.type === 'inbound-rtp', x => x.members.framesDecoded),
        framesEncoded: sum(items, x => x.members.framesEncoded > 0 && x.type === 'outbound-rtp', x => x.members.framesEncoded),
        availableOutgoingBitrate: sum(items, x => x.type === 'candidate-pair', x => x.members.availableOutgoingBitrate),
        //
        packetsLost: sum(items, x => x.type === 'inbound-rtp', x => x.members.packetsLost),
        firsReceived: sum(items, x => x.type === 'inbound-rtp', x => x.members.firCount),
        framesSent: sum(items, x => x.type === 'inbound-rtp', x => x.members.framesSent),
        frameWidthSent: sum(items, x => x.type === 'track' && !x.members.remoteSource, x => x.members.frameWidth),
        frameHeightSent: sum(items, x => x.type === 'track' && !x.members.remoteSource, x => x.members.frameHeight),
        limitedResolution: ''
      }
    }
  },
  {
    match: item => item.session.os.name === 'ios',
    convert: (ctx, item) => {
      const items = item.stats.pcStats

      const bwe = items.find(x => x.id === 'bweforvideo')
      const ssrc = items.find(x =>
        x.type === 'ssrc' &&
        findIosVal(x, 'mediaType') === 'video' &&
        x.id.indexOf('_send') > -1)
      const timestamp = (ssrc || items[0]).timestamp

      const res = {
        source: ctx.source,
        timestamp: new Date(timestamp),
        //
        bytesSent: sum(items, x => x.type === 'ssrc' && x.id.indexOf('_send') > -1, x => findIosVal(x, 'bytesSent') - 0),
        bytesReceived: sum(items, x => x.type === 'ssrc' && x.id.indexOf('_recv') > -1, x => findIosVal(x, 'bytesReceived') - 0),
        //
        sendBandwidth: findIosVal(bwe, 'googAvailableSendBandwidth'),
        receiveBandwidth: findIosVal(bwe, 'googAvailableReceiveBandwidth'),
        transmitBitrate: findIosVal(bwe, 'googTransmitBitrate'),
        retransmitBitrate: findIosVal(bwe, 'googRetransmitBitrate'),
        availableOutgoingBitrate: findIosVal(bwe, 'googTransmitBitrate'),
        //
        packetsLost: findIosVal(ssrc, 'packetsLost'),
        firsReceived: findIosVal(ssrc, 'googFirsReceived'),
        frameRateSent: findIosVal(ssrc, 'googFrameRateSent'),
        frameWidthSent: findIosVal(ssrc, 'googFrameWidthSent'),
        frameHeightSent: findIosVal(ssrc, 'googFrameHeightSent'),
        limitedResolution: '' +
          (findIosVal(ssrc, 'googBandwidthLimitedResolution') ? 'bandwidth, ' : '') +
          (findIosVal(ssrc, 'googCpuLimitedResolution') ? 'cpu' : '')
      }

      return res
    }
  },
  {
    match: item => true,
    convert: () => null
  }
]

export class Room {
  constructor(room, options = DEFAULT_OPTIONS) {
    this.options = options
    this.room = room
    this.caller = room.participants.find(x => x.creator)
    this.receiver = room.participants.find(x => !x.creator)
    this.callerConvert = this.findConvert(this.caller)
    this.receiverConvert = this.findConvert(this.receiver)
  }

  findConvert(participant) {
    const convert = CONVERTERS.find(c => c.match(participant)).convert
    const source = participant.creator ? 'caller' : 'receiver'
    return (item) => {
      const res = convert({ source }, item)
      if (res) {
        res.id = item.logId
        res.createdAt = new Date(item.createdAt)
      }
      return res
    }
  }

  filterItems(items) {
    return items.filter(x =>
      x.stats &&
      Array.isArray(x.stats.pcStats) &&
      // Magic number 5 is used to determine if it's an expected pcStats.
      // Sometimes getting a pcStats with 1 item pcLoadLetter which can't be processed.
      x.stats.pcStats.length > 5)
  }

  calc(item, prevItem) {
    if (!prevItem) {
      item.sendSpeed = -1
      item.receiveSpeed = -1
      item.transmitBitrate = -1
      item.frameRateSent = -1
      return item
    }

    const elapsed = item.elapsed

    if (!item.hasOwnProperty('sendSpeed')) {
      item.sendSpeed = (item.bytesSent - prevItem.bytesSent) / elapsed
    }

    if (!item.hasOwnProperty('receiveSpeed')) {
      item.receiveSpeed = (item.bytesReceived - prevItem.bytesReceived) / elapsed
    }

    if (!item.hasOwnProperty('transmitBitrate')) {
      item.transmitBitrate = (item.videoBytesSent + item.audioBytesSent -
        prevItem.videoBytesSent - prevItem.audioBytesSent) * 8 / elapsed
    }

    if (!item.hasOwnProperty('frameRateSent')) {
      item.frameRateSent = Math.round((item.framesSent - prevItem.framesSent) / elapsed)
    }

    return item
  }

  normalizeItems(items, convert) {
    items = this.filterItems(items).map(convert).filter(x => !!x)
    items.sort((x, y) => x.timestamp - y.timestamp)

    const normItems = []
    let prevCounter = 1
    for (let i = 0; i < items.length; i++) {
      const prevItem = items[i - prevCounter]
      const item = items[i]

      if (prevItem) {
        item.elapsed = (item.timestamp - prevItem.timestamp) / 1000
        if (item.elapsed < MIN_ELAPSED) {
          prevCounter++
          continue
        } else {
          prevCounter = 1
        }
      }

      const normItem = this.calc(item, prevItem)
      normItems.push(normItem)
    }

    return normItems
  }

  sortAll(items) {
    return items.sort((x, y) => (x.timestamp - y.timestamp) * this.options.sortOrder)
  }

  getSessionId(participant) {
    if (participant && participant.session) {
      return participant.session.sessionId
    }

    return NaN // Equals with NaN would always return false.
  }

  aggregate(items) {
    const callerSessionId = this.getSessionId(this.caller)
    const receiverSessionId = this.getSessionId(this.receiver)

    let callerItems = items.filter(x => x.sessionId === callerSessionId)
    let receiverItems = items.filter(x => x.sessionId === receiverSessionId)

    callerItems = this.normalizeItems(callerItems, this.callerConvert)
    receiverItems = this.normalizeItems(receiverItems, this.receiverConvert)

    const res = this.sortAll([...callerItems, ...receiverItems])
    return res
  }
}
