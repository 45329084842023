import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  GridView, SortPicker, GridHeader,
  groupBySection, localSort
} from 'skylight-common'
import { TextInputTile } from './TextInputTile'
import { AttachmentGridItem } from '../AttachmentGridItem'
import TextInputIcon from 'material-ui/svg-icons/editor/mode-edit'
import { filterTextInputAttachment } from '../../../../../lib/assignment'
import { COMMON_COLUMNS, onSelect, onSortUpdate } from '../attachment-utils'

import './TextInputAttachments.css'

const TextInputGridItem = props =>
  <AttachmentGridItem {...props}>
    <TextInputTile item={props.item} />
  </AttachmentGridItem>

// TODO: Move out duplicate code.
const COLUMNS = [
  {
    name: 'label',
    title: 'CARD_LABEL',
    groupBy: x => x.label
  }
]

const TextInputAttachments = (props) => {
  const list = props.attachment.list
  const query = list.query.textInput
  let items = list.items.filter(x => x.cardType === 'textInput')
  items = filterTextInputAttachment(items, list.query.filter)
  if (!items.length) {
    return null
  }

  const columns = [...COLUMNS, ...COMMON_COLUMNS]

  const heading = p => <GridHeader
    {...p}
    actions={[
    <SortPicker
      key="sort-picker"
      query={query}
      columns={columns}
      onChange={(sBy, sAsc) => onSortUpdate(props)(sBy, sAsc, 'textInput')} />]} />

  return (
    <div className="assignment-textinput-attachments">
      <div className="assignment-attachments-title capture-attachments-title">
        <TextInputIcon />
        <FormattedMessage id="TEXT_INPUT" defaultMessage="Text input" />
      </div>
      <GridView
        {...groupBySection(columns, query.sortBy)}
        items={localSort(items, query)}
        query={query}
        heading={heading}
        total={items.length}
        renderItem={TextInputGridItem}
        selectedIds={list.selectedIds}
        cols={1}
        done={true}
        onQuery={() => {}}
        onSelect={onSelect(props)}
        onCheck={() => {}} />
    </div>
  )
}

export { TextInputAttachments }
