import React from 'react'
import { matchPath, withRouter } from 'react-router'
import { FormattedMessage } from 'react-intl'

const DEFAULT_MATCH_OPTIONS = { exact: true }

const renderer = ({ breadcrumb, match }) => {
  if (typeof breadcrumb === 'string') {
    return <FormattedMessage id={breadcrumb} />
  }

  return breadcrumb({ match })
}

export const getBreadcrumbs = ({ routes, pathname }) => {
  const matches = []

  pathname.replace(/\/$/, '').split('/')
    .reduce((previous, current) => {
      const pathSection = current === '' ? '/' : `${previous}/${current}`

      let breadcrumbMatch

      routes.some(({ breadcrumb, matchOptions, path, props }) => {
        if (!breadcrumb || !path) {
          throw new Error('withBreadcrumbs: `breadcrumb` and `path` must be provided in every route object')
        }

        const match = matchPath(pathSection, { ...(matchOptions || DEFAULT_MATCH_OPTIONS), path })
        if (!match) {
          return false
        }

        breadcrumbMatch = {
          ...props,
          breadcrumb: renderer({ breadcrumb, match }),
          path,
          match
        }

        return true
      })

      if (breadcrumbMatch) {
        matches.push(breadcrumbMatch)
      }

      return pathSection === '/' ? '' : pathSection
    }, '')

  return matches
}

export const withBreadcrumbs = routes => Component => withRouter(props =>
  <Component {...props}
    breadcrumbs={getBreadcrumbs({ routes, pathname: props.location.pathname })} />
)
