import { createStore, applyMiddleware } from 'redux'
// Routing
import reducer from '../reducers'
import thunk from 'redux-thunk'

import { routerMiddleware } from 'react-router-redux'
import { middleware as call } from 'skylight-call-sdk'
import list from '../middleware/list'
import auth from '../middleware/auth'
import error from '../middleware/error'
import { history } from './history'
import mqtt from '../middleware/mqtt'
import network from '../middleware/network'
import application from '../middleware/application'
import { isLiveEnabled } from '../lib/calls'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__// || compose

const middlewares = [
  error,
  auth,
  mqtt,
  network,
  list,
  application,
  ...(isLiveEnabled() ? [call.connection] : []),
  thunk,
  routerMiddleware(history)
]

let middleware = applyMiddleware.apply(null, middlewares)
if (composeEnhancers) middleware = composeEnhancers(middleware)

const store = createStore(reducer, middleware)

export { store }
