import React from 'react'

const SelectedItems = props => {
  const renderItems = () => {
    return (props.items || []).map((item, i) => (
      <div key={i} className="selected-item">
        {props.renderItem(item)}
        {<props.rightIcon onTouchTap={(e) => props.onIconClick(e, item)} />}
      </div>
    ))
  }

  return (
    <div className="selected-items">
      {renderItems()}
    </div>
  )
}

export default SelectedItems
