import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const databaseDisconnected = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M8.81046986,10.6697336 C5.97882106,10.0539723 4,8.64306227 4,7 C4,6.68134465 4.07442878,6.37142116 4.21507267,6.07433638 L1.57036815,3.42963185 L3.14073629,1.85926371 L21.5,20.2185274 L19.9296319,21.7888956 L17.8629236,19.7221873 C16.4023161,20.5083204 14.3165242,21 12,21 C7.58,21 4,19.21 4,17 L4,14 C4,16.21 7.58,18 12,18 C13.3314875,18 14.586748,17.8375638 15.6908792,17.5501427 L14.0131497,15.8724134 C13.3700055,15.9556926 12.6953989,16 12,16 C7.58,16 4,14.21 4,12 L4,9 C4,11.060719 7.11269122,12.756262 11.1166567,12.9759204 L8.81046986,10.6697336 Z M14.9874593,10.7120297 L7.6611672,3.63836833 C8.91085234,3.23443412 10.4004193,3 12,3 C16.42,3 20,4.79 20,7 C20,8.68187285 17.9265919,10.1204967 14.9874593,10.7120297 L14.9874593,10.7120297 Z M16.6034611,12.2723072 C18.6582611,11.5485081 20,10.3529594 20,9 L20,12 C20,12.8280334 19.4974327,13.5971064 18.6364159,14.2351602 L16.6034611,12.2723072 L16.6034611,12.2723072 Z M19.6347151,15.1990353 C19.8720686,14.8205354 20,14.417772 20,14 L20,15.5517241 L19.6347151,15.1990353 L19.6347151,15.1990353 Z" />
    </SvgIcon>
  )
}

export default databaseDisconnected
