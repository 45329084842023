import React from 'react'
import FlatButton from 'material-ui/FlatButton'
import CloseIcon from 'material-ui/svg-icons/navigation/close'
import IconButton from 'material-ui/IconButton'
import { FormattedMessage } from 'react-intl'
import './CallSnackbar.css'

class CallSnackbar extends React.Component {
  render() {
    const { snackbar, onAction, onClose } = this.props
    if (!snackbar) {
      return <div className="call-snackbar"></div>
    }

    const hasError = snackbar.source.includes('ERROR')
    const className = `call-snackbar ${snackbar ? 'open' : ''} ${hasError ? ' error' : ''}`

    return <div className={className}>
      <FormattedMessage
        id={snackbar.source}
        values={snackbar.values}
        defaultMessage={snackbar.source} />
      {snackbar.action &&
        <FlatButton
          className="snackbar-action"
          label={<FormattedMessage
            id={`${snackbar.source}_ACTION`}
            defaultMessage="" />}
          primary
          onTouchTap={() => onAction(snackbar.action)} />}
      <IconButton className="close" onTouchTap={onClose}><CloseIcon /></IconButton>
    </div>
  }
}

export { CallSnackbar }
