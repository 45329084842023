import React from 'react'
import classNames from 'classnames'
import MenuBase from 'material-ui/Menu'
import MenuItem from 'material-ui/MenuItem'
import { CursorTooltip } from 'skylight-common'

import './Menu.css'

const Menu = props => {
  const renderMenuItems = () => {
    return (props.actions || []).map((a, i) => (
      <CursorTooltip
        key={i}
        className="menu-item-tooltip"
        tooltip={a.title}>
        <MenuItem
          className={classNames('menu-item', a.type, { active: a.isActive, 'with-left-icon': !!a.leftIcon })}
          primaryText={a.title}
          leftIcon={a.leftIcon}
          onTouchTap={() => {
            props.onMenuItemClick()
            a.handler()
          }} />
      </CursorTooltip>
    ))
  }

  return (
    <MenuBase
      className={classNames('menu', props.className)}
      width={props.width}
      onMouseLeave={props.onMouseLeave}
      onMouseEnter={props.onMouseEnter}>
      {props.children}
      {renderMenuItems()}
    </MenuBase>
  )
}

export { Menu }
