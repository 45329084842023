import React from 'react'
import { FormattedMessage } from 'react-intl'
import { RequiredLabel, TrimmingTextField, DeleteIcon, ActionButton } from 'skylight-common'

import './LocalServiceForm.css'

const getLocalServiceFormLabel = (f) => {
  if (f.required) {
    return <RequiredLabel>
      <FormattedMessage id={f.label.id} defaultMessage={f.label.default} />
    </RequiredLabel>
  } else {
    return <FormattedMessage id={f.label.id} defaultMessage={f.label.default} />
  }
}

const LocalServiceForm = (props) => {
  return (
    <div className="local-service-form">
      {/* Autocomplete hack for chrome.  */}
      <input type="text" className="autocomplete" />
      <input type="password" className="autocomplete" />

      {props.fields.map((f, fieldIndex) => {
        const uniqueFieldName = 'iceServer' + props.iceServerIndex + f.prop
        return <div className="ice-server-row" key={uniqueFieldName}>
          <div className="remove-button-column">
            {/* Add the remove button only once adjacent to the first field */}
            {fieldIndex === 0 && <ActionButton
              icon={<DeleteIcon/>}
              tooltipPosition={'bottom-right'}
              className="delete-ice-server-button"
              label={<FormattedMessage id={'REMOVE_SERVER'} defaultMessage="Remove server" />}
              onTouchTap={props.onRemove}
              primary={true} />}
          </div>
          <div className="server-fields-column">
            <TrimmingTextField
              name={uniqueFieldName}
              id={uniqueFieldName}
              className={f.className || ''}
              value={props.values[f.prop] || ''}
              floatingLabelText={getLocalServiceFormLabel(f)}
              type={f.type}
              fullWidth={true}
              errorText={props.errors.iceServerIndex === props.iceServerIndex &&
                props.errors[f.prop]
                ? <FormattedMessage values={{ field: f.label.default }}
                  id={props.errors[f.prop]} />
                : null}
                  onChange={(e, v) => props.onChange(f.prop, v)}/>
          </div>
        </div>
      })
      }
    </div>
  )
}

export { LocalServiceForm }
