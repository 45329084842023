import * as ActionTypes from '../../actions/types'
import { createItemReducer } from 'skylight-common'

const baseUser = createItemReducer({
  actionPrefix: ActionTypes.PREFIX_USER,
  defaultMode: 'edit'
})

const user = (state, payload) => {
  state = baseUser(state, payload)
  switch (payload.type) {
    case ActionTypes.USER_SAVE_FIELD_ERROR:
      return { ...state, errors: payload.errors }
    default:
      return state
  }
}

export default user
