import React, { Component } from 'react'
import { allColumns } from '../WorkflowList/listColumns'
import CheckIcon from 'material-ui/svg-icons/toggle/check-box-outline-blank'
import { WorkflowList } from '../WorkflowList/WorkflowList'
import './WorkflowPicker.css'

class WorkflowPicker extends Component {
  constructor(props) {
    super(props)
    this.isLoading = null
    this.state = {
      id: null
    }
  }

  getColumns = () => {
    const { columnNames } = this.props

    return columnNames
      ? allColumns.filter(c => columnNames.includes(c.name))
      : allColumns
  }

  componentDidMount() {
    this.isLoading = true

    if (this.props.onInit) {
      this.props.onInit(this.props.workflow.list, this.props.id)
    }
  }

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    const wfList = nextProps.workflow.list
    if (!wfList.isLoading && this.isLoading === true) {
      this.isLoading = false
      if (this.props.onInit) {
        this.props.onInit(nextProps.workflow.list)
      }
    }

    if (nextProps.id !== this.state.id) {
      this.setState({ id: nextProps.id })
      if (this.props.onInit) {
        this.props.onInit(nextProps.workflow.list, nextProps.id)
      }
    }
  }

  render() {
    return <div className="workflow-picker">
      <WorkflowList
        {...this.props}
        preventPathSave={true}
        workflow={this.props.workflow}
        columns={this.getColumns()}
        icon={<CheckIcon />}
        onSelect={this.props.onWorkflowSelectItems} />
      </div>
  }
}

export { WorkflowPicker }
