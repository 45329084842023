import React from 'react'
import IconButton from 'material-ui/IconButton'
import { FormattedMessage } from 'react-intl'
import { Table, GridHeader, Column, Loading, MaterialIcon } from 'skylight-common'
import './GroupMemberList.css'

const GroupMemberList = (props) => {
  const { groupMembers, group } = props
  const { listMode = true, isEditable } = props
  const getMembers = (items) => {
    return items.filter((m) => !group.membersToRemove.some((r) => r.id === m.id))
  }

  const removeMember = (e, item) => {
    e.stopPropagation()
    props.onSetRemoveGroupMembers(item)
  }

  return (
    <div className="group-member-list dashboard-list">
    <Loading
      isLoading={groupMembers.isLoading && groupMembers.items.length === 0}
      message="LOADING_GROUP_MEMBER_LIST"
      error={groupMembers.error}>
      <Table
        tableHeading={listMode
          ? (props) => <GridHeader {...props}
          title={
            <FormattedMessage id="GROUP_MEMBER_LIST" defaultMessage="Group Member List" />
          } />
          : null
        }
        showHover={true}
        query={groupMembers.query}
        items={getMembers(groupMembers.items)}
        total={groupMembers.total}
        selectedRows={groupMembers.selectedIds}
        onQuery={(query) => props.onGroupMemberLoadItems(query)}
        onRowsSelected={() => {}}>
        <Column
          key="icon"
          name="icon"
          sortable={false}
          className="member-icon"
          render={() => <MaterialIcon icon="person" />} />
        <Column
          key="username"
          name="username"
          title={<FormattedMessage id="USERNAME" defaultMessage="Username" />}
          render={(item) => item.username} />

        {listMode && <Column
          key="email"
          name="email"
          title={<FormattedMessage id="EMAIL" defaultMessage="Email" />}
          render={(item) => item.email} />}

        {(!listMode && isEditable) && <Column
          key="actions"
          title=""
          className="actions"
          isAction={true}
          render={item => <div className="list-actions">
            <IconButton
              className="remove-member"
              tooltip={<FormattedMessage id="ACTION_REMOVE" defaultMessage="Remove" />}
              onTouchTap={(e) => removeMember(e, item)}>
              <MaterialIcon icon="cancel" />
            </IconButton>
          </div>} />}
      </Table>
    </Loading>
  </div>
  )
}

export { GroupMemberList }
