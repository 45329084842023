export const ASSIGN_APPLICATION = 'ASSIGN_APPLICATION'
export const ADD_ASSIGNMENT = 'ADD_ASSIGNMENT'
export const ADD_DEVICE = 'ADD_DEVICE'
export const ADD_MEDIA = 'ADD_MEDIA'
export const ADD_TOKEN = 'ADD_TOKEN'
export const ENABLE_PERMISSIONS = 'ENABLE_PERMISSIONS'
export const NET_SCAN = 'NET_SCAN'
export const SHOW_TOKEN_CREDENTIALS = 'SHOW_TOKEN_CREDENTIALS'
export const VERSION = 'VERSION'
export const ADD_TO_EXISTING_GROUP = 'ADD_TO_EXISTING_GROUP'
export const SETUP_WIFI = 'SETUP_WIFI'
export const TEMPORARY_PASSWORD = 'TEMPORARY_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const DUPLICATE_APPLICATION = 'DUPLICATE_APPLICATION'
export const VERIFY_CREDENTIALS = 'VERIFY_CREDENTIALS'
export const SETUP_NEW_APPLICATION = 'SETUP_NEW_APPLICATION'
export const PUBLISH_VERSION = 'PUBLISH_VERSION'
export const SESSION_EVENT_VIEW_JSON = 'SESSION_EVENT_VIEW_JSON'
export const ADD_APPLICATION_PARTICIPANTS = 'ADD_APPLICATION_PARTICIPANTS'
export const ADD_APPLICATION_ROLE = 'ADD_APPLICATION_ROLE'
export const ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS'
export const NEW_USER = 'NEW_USER'
export const IMPORT_ARCHIVE_UPLOAD = 'IMPORT_ARCHIVE_UPLOAD'
export const MEDIA_DOWNLOAD_CONFIRMATION = 'MEDIA_DOWNLOAD_CONFIRMATION'
export const QUERY_FILTER_CONFIGURATION = 'QUERY_FILTER_CONFIGURATION'
export const USER_ATTRIBUTES = 'USER_ATTRIBUTES'
export const ARCHIVE_APP_CONFIRMATION = 'ARCHIVE_APP_CONFIRMATION'
export const APP_UPGRADE = 'APP_UPGRADE'
export const APP_UPGRADE_UPLOAD = 'APP_UPGRADE_UPLOAD'
