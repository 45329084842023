import React, { Component } from 'react'
import { Loading, DialogTitle } from 'skylight-common'
import QRCode from 'qrcode.react'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import Dialog from 'material-ui/Dialog'

import './AddDeviceDialog.css'

const QR_SIZE_MARGIN = 220

class AddDeviceDialog extends Component {
  state = { timer: -1 }

  isDialogOpened = () => {
    const { dialogType, dialog } = this.props
    return !!(dialog[dialogType] || {}).open
  }

  getDialogParams = (nextProps) => {
    const { dialogType, dialog } = nextProps || this.props
    // TODO: For now provide token expiration from dialog params
    return (dialog[dialogType] || {}).params
  }

  getError = () => {
    const { addDevice = {} } = this.props.device
    return addDevice.error
      ? <FormattedHTMLMessage id={addDevice.error} defaultMessage="Unexpected Error" />
      : null
  }

  clearTimers = () => {
    [this.closeTimer, this.intervalTimer].forEach(r => {
      clearTimeout(r)
    })
  }

  startTimer = (timeout, timeoutLag = 15) => {
    // Close dialog 15 seconds before token expire.
    const closeAfter = timeout - timeoutLag
    this.setState({ timer: closeAfter })

    this.closeTimer = setTimeout(() => this.hideDialog(), closeAfter * 1000)
    this.intervalTimer = setInterval(() => this.setState((prevState) => ({ timer: prevState.timer - 1 })), 1000)
  }

  hideDialog = () => {
    const { onHideDialog, dialogType } = this.props
    this.clearTimers()
    this.setState({ timer: -1 })
    onHideDialog(dialogType)
  }

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    const { deviceToken } = this.props.device.addDevice
    const { deviceToken: nextToken } = nextProps.device.addDevice
    if (nextToken && deviceToken !== nextToken) {
      const { timeout, timeoutLag } = this.getDialogParams(nextProps) || {}
      if (!timeout) {
        return
      }

      this.clearTimers()
      this.startTimer(timeout, timeoutLag)
    }
  }

  renderTimer = () => {
    const { timer } = this.state
    if (timer <= 0) {
      return null
    }

    return <div className="timer-message">
      <FormattedMessage
        id="QR_TIMEOUT"
        defaultMessage="Token will expire in {value} seconds"
        values={{ value: timer }} />
    </div>
  }

  render() {
    const { addDevice = {} } = this.props.device
    // We can't rely on css here, since we must display entire QR code.
    // Also at the moment we do not really care about window resize after QR is shown.
    const size = Math.min(window.innerHeight, window.innerWidth)
    const qrSize = size - QR_SIZE_MARGIN
    const contentStyle = {
      width: `${size + 100}px`,
      maxWidth: 'none'
    }

    return (
      <div className="add-device-wrapper">
        <Dialog
          title={<DialogTitle
            id="DEVICE_ADD_DIALOG_TITLE"
            defaultMessage="Scan code to connect device" />}
          modal={false}
          open={this.isDialogOpened()}
          className="dialog-modal add-device-modal"
          contentClassName="add-device-content"
          contentStyle={contentStyle}
          autoDetectWindowHeight={false}
          onRequestClose={this.hideDialog}>
          {this.renderTimer()}
          <div className="qr-code">
            <Loading isLoading={addDevice.isLoading} error={this.getError()}>
              {addDevice.deviceToken && <QRCode
                value={addDevice.deviceToken}
                size={qrSize} />}
            </Loading>
          </div>
        </Dialog>
      </div>
    )
  }
}

export { AddDeviceDialog }
