import React, { Component } from 'react'
import { Loading } from 'skylight-common'

class Logout extends Component {
  componentDidMount() {
    this.props.onSignout()
  }

  render() {
    return <Loading isLoading={true} message="LOGGING_OUT">
      {null}
    </Loading>
  }
}

export { Logout }
