import React from 'react'
import { FormattedMessage } from 'react-intl'
import './AssignmentStatus.css'

const STATUS_MAP = {
  complete: 'complete',
  inProgress: 'in-progress',
  error: 'error',
  archived: 'archived'
}

const AssignmentStatus = (props) => {
  if (!props.item) {
    return null
  }

  const { archived, isComplete } = props.item

  let status = props.item.status || 'assigned'
  if (archived || isComplete) {
    status = `${archived ? 'archived' : ''} ${isComplete ? 'complete' : ''}`.trim()
  }

  const className = STATUS_MAP[status] || status

  return (
    <div className={`assignment-status ${className}`}>
      <FormattedMessage
        id={`ASSIGNMENT_${status.toUpperCase().replace(' ', '_')}`}
        defaultMessage={status} />
    </div>
  )
}

export { AssignmentStatus }
