import * as ActionTypes from '../../actions/types'

const initialState = {
  name: ''
}

const selectedApplication = (state = initialState, payload) => {
  switch (payload.type) {
    case ActionTypes.SELECTED_APP_UPDATE:
      return { ...state, ...payload.application }
    default:
      return state
  }
}

export default selectedApplication
