import React from 'react'
import { FormattedMessage } from 'react-intl'
import DownIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down'
import { SwitchPortalVersionMenu } from '../../SwitchPortalVersionMenu'
import { TopBarPopoverButton } from './TopBarPopoverButton'

const SwitchPortalVersionButton = props => {
  return (
    <TopBarPopoverButton
      tooltip={<FormattedMessage id="SWITCH_TO" defaultMessage="Switch to" />}
      popoverBody={
        <SwitchPortalVersionMenu {...props} className="top-bar-popover-menu" />
      }>
      <DownIcon />
    </TopBarPopoverButton>
  )
}

export { SwitchPortalVersionButton }
