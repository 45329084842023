import React from 'react'
import { formatDate, downloadFile } from 'skylight-common'
import skylight from 'skylight-js-sdk'
import CallIcon from 'material-ui/svg-icons/communication/call'
import CameraIcon from 'material-ui/svg-icons/image/camera-alt'
// import AlertIcon from 'material-ui/svg-icons/alert/warning'
import UserIcon from 'material-ui/svg-icons/action/account-box'
import PinLockIcon from 'material-ui/svg-icons/action/lock'
// import AssignmentCreatedIcon from '../App/Common/Icons/assignmentCreated'
// import AssignmentDeletedIcon from '../App/Common/Icons/assignmentDeleted'
import AssignmentFlowIcon from '../../App/Common/Icons/assignmentFlow'
import CallFailedIcon from '../../App/Common/Icons/callFailed'
import CardCompletedIcon from '../../App/Common/Icons/cardCompleted'
import CardFlowIcon from '../../App/Common/Icons/cardFlow'
import ScanCompletedIcon from '../../App/Common/Icons/scanCompleted'
import SequenceFlowIcon from '../../App/Common/Icons/sequenceFlow'
import SessionCreatedIcon from '../../App/Common/Icons/sessionCreated'
import SessionConnectedIcon from '../../App/Common/Icons/sessionConnected'
import SessionDisconnectedIcon from '../../App/Common/Icons/sessionDisconnected'
import PinLockFailedIcon from '../../App/Common/Icons/lockAlert'
import { json2csv } from '../../lib/csv'

// Removed as a part of - https://upskill.atlassian.net/browse/SKY-654
export const EVENT_TYPES = {
  // 'assignment-created': {icon: <AssignmentCreatedIcon className='activity-icon'/>},
  // 'assignment-deleted': {icon: <AssignmentDeletedIcon className='activity-icon'/>},
  'assignment-enter': { icon: <AssignmentFlowIcon className="activity-icon"/> },
  'assignment-exit': { icon: <AssignmentFlowIcon className="activity-icon"/> },
  'call-attempt': { icon: <CallIcon className="activity-icon"/> },
  'call-completed': { icon: <CallIcon className="activity-icon"/> },
  'call-connected': { icon: <CallIcon className="activity-icon"/> },
  'call-dropped': { icon: <CallFailedIcon className="activity-icon"/> },
  'call-failed': { icon: <CallFailedIcon className="activity-icon"/> },
  'call-timeout': { icon: <CallFailedIcon className="activity-icon"/> },
  'capture-completed': { icon: <CameraIcon className="activity-icon"/> },
  'card-completed': { icon: <CardCompletedIcon className="activity-icon"/> },
  'card-enter': { icon: <CardFlowIcon className="activity-icon"/> },
  'card-exit': { icon: <CardFlowIcon className="activity-icon"/> },
  // Removed as a part of -
  // 'client-crash': {icon: <AlertIcon className='activity-icon'/>},
  // 'client-memory-high': {icon: <AlertIcon className='activity-icon'/>},
  // 'client-space-low': {icon: <AlertIcon className='activity-icon'/>},
  // 'client-thermal-high': {icon: <AlertIcon className='activity-icon'/>},
  // 'client-voice': {icon: <AlertIcon className='activity-icon'/>},
  'scan-completed': { icon: <ScanCompletedIcon className="activity-icon"/> },
  'sequence-enter': { icon: <SequenceFlowIcon className="activity-icon"/> },
  'sequence-exit': { icon: <SequenceFlowIcon className="activity-icon"/> },
  'session-connected': { icon: <SessionConnectedIcon className="activity-icon"/> },
  'session-created': { icon: <SessionCreatedIcon className="activity-icon"/> },
  'session-deleted': { icon: <SessionCreatedIcon className="activity-icon"/> },
  'session-disconnected': { icon: <SessionDisconnectedIcon className="activity-icon"/> },
  'pin-lock-code-created': { icon: <PinLockIcon className="activity-icon"/> },
  'pin-lock-code-entered': { icon: <PinLockIcon className="activity-icon"/> },
  'pin-lock-code-failed': { icon: <PinLockFailedIcon className="activity-icon"/> },
  'user-login': { icon: <UserIcon className="activity-icon"/> },
  'user-login-sso': { icon: <UserIcon className="activity-icon"/> },
  'user-logout': { icon: <UserIcon className="activity-icon"/> }
}

export const CALL_TYPES = {
  attempt: { icon: <CallIcon className="activity-icon"/> },
  completed: { icon: <CallIcon className="activity-icon"/> },
  connected: { icon: <CallIcon className="activity-icon"/> },
  dropped: { icon: <CallFailedIcon className="activity-icon"/> },
  failed: { icon: <CallFailedIcon className="activity-icon"/> }
}

const ACTIVITY_TYPE_NAMES = Object.keys({ ...EVENT_TYPES })

const getDateRangeTitle = (filter) => {
  const from = filter.timestamp && filter.timestamp.from ? formatDate(filter.timestamp.from) : ''
  const to = filter.timestamp && filter.timestamp.to ? formatDate(filter.timestamp.to) : ''
  let dateRangeTitle = 'All'
  if (from || to) {
    if (!from && to) {
      dateRangeTitle = `until ${to}`
    } else if (from && !to) {
      dateRangeTitle = `from ${from}`
    } else {
      dateRangeTitle = `${from} to ${to}`
    }
  }

  return dateRangeTitle
}

export const exportActivities = (activities, filter) => {
  activities = activities
    .map(x => ({
      created: x.created,
      name: x.name,
      timestamp: x.timestamp,
      createdBy: x.createdBy,
      metadata: JSON.stringify(x.metadata)
    })) || []

  // Build CSV for export
  const activitiesStr = json2csv(activities, ['id'])

  // Download
  const dateRangeTitle = getDateRangeTitle(filter)
  const blob = new Blob([activitiesStr], { type: 'application/octet-stream' })
  downloadFile(window.URL.createObjectURL(blob), `Skylight Activity ${dateRangeTitle}.csv`)
}

export const getActivities = async(query) => {
  const q = {}
  if (query.limit) q.limit = query.limit
  if (query.skip) q.start = query.skip

  if (query.sortBy) {
    const sortSign = query.sortAsc ? '+' : '-'
    q.sort = `${sortSign}${query.sortBy}`
  }

  const { timestamp, to } = query.filter
  if (timestamp && timestamp.from) q.from = timestamp.from
  if (timestamp && timestamp.to) q.to = timestamp.to

  if (to) q.to = to

  const events = []
  if (query.filter.events && query.filter.events.length) {
    query.filter.events.forEach(x => {
      const filtered = ACTIVITY_TYPE_NAMES.filter(e => e.includes(x))
      if (filtered.length) {
        events.push(...filtered)
      } else {
        events.push(x) // Push initial value if no existing types found
      }
    })
  }

  const eventTypes = query.filter.title
    ? query.filter.title
    : (query.filter.eventTypes || []).join(',')

  if (events.length || eventTypes) {
    q.names = `${events.join(',')}${eventTypes}`
  }

  return await skylight.event.list(q)
}
