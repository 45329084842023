import React from 'react'
import { FormattedMessage } from 'react-intl'
import { withRouter } from 'react-router-dom'
import IconButton from 'material-ui/IconButton'
import skylight from 'skylight-js-sdk'
import { MaterialIcon } from 'skylight-common'
import './AttachmentIcon.css'
import { DATA_TYPES } from '../../../../../lib/assignment'
import CircularProgress from 'material-ui/CircularProgress'

const cache = {}

const hasDataSvc = async(id) => {
  if (cache.hasOwnProperty(id)) return cache[id]
  const queue = skylight.utils.createQueue(1000)

  try {
    const cardRes = await queue.add(async() => {
      let cards = (await skylight.assignment.getCardsById(id, { type: DATA_TYPES })).cards
      cards = cards.filter(x => {
        if (x.component) {
          if (x.component.componentType === 'decision' && x.component.choices) {
            // Choices is an object
            // idOne: {label: 'one', selected: false},
            // idTwo: {label: 'two', selected: true}
            return Object.keys(x.component.choices).some(key => x.component.choices[key].selected)
          }

          if (x.component.componentType.startsWith('capture')) {
            return x.component.captures && x.component.captures.length > 0
          }
        }

        if (x.layout) {
          if (x.layout.layoutType === 'textInput') {
            return !!x.layout.input
          }
        }

        return false
      })

      return cards
    })
    cache[id] = cardRes.length > 0
    return cache[id]
  } catch (e) {
    skylight.logger.error('AttachmentIcon', e)
    return false
  }
}

class AttachmentIconComponent extends React.Component {
  state = {
    hasData: false,
    isLoading: true
  }

  loadData = async(item) => {
    const hasData = await hasDataSvc(item.id)
    this.setState({ hasData, isLoading: false })
  }

  componentDidMount() {
    this.loadData(this.props.item)
  }

  componentWillUnmount() {
    this.setState = () => {
    }
  }

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    // Consider checking id.
    if (this.props.item !== nextProps.item) { // Another item was selected without component unmount.
      // this.props.onLoadAttachment(nextProps.item)
      this.loadData(nextProps.item)
    }
  }

  onTouchTap = (e) => {
    e.stopPropagation()
    if (this.props.item) {
      this.props.history.push(`/assignments/attachments/${this.props.item.id}`)
    }
  }

  render() {
    const { isLoading, hasData } = this.state
    if (isLoading) return <div className="attachment-icon-loading"><CircularProgress size={20} thickness={2} /></div>
    if (!hasData) return false

    return (
      <IconButton
        className="assignment-attachment-icon"
        tooltipPosition="bottom-left"
        tooltip={<FormattedMessage id="VIEW_CAPTURED" defaultMessage="View captured results" />}
        onTouchTap={this.onTouchTap}><MaterialIcon icon="attach_file" /></IconButton>
    )
  }
}

const AttachmentIcon = withRouter(AttachmentIconComponent)

export { AttachmentIcon }
