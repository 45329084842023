import * as ActionTypes from '../actions/types'
import * as snackbarTypes from '../App/Home/Layout/Snackbars/snackbarTypes'

export const showSnackbar = (snackbarType, params = null) => ({
  type: ActionTypes.SNACKBAR_SHOW,
  snackbarType,
  params
})

export const hideSnackbar = (snackbarType) => ({
  type: ActionTypes.SNACKBAR_HIDE,
  snackbarType
})

const showLoadingSnackbar = message => showSnackbar(snackbarTypes.SNACKBAR_LOADING, { message })

const showPublishingSnackbar = () => showLoadingSnackbar('PUBLISHING')

const showCreatingDraftSnackbar = () => showLoadingSnackbar('CREATING_DRAFT')

const showArchivingApplicationSnackbar = () => showLoadingSnackbar('APPLICATION_ARCHIVING')

const showImportingApplicationSnackbar = () => showLoadingSnackbar('IMPORTING_APPLICATION')

const showUpgradingApplicationSnackbar = () => showLoadingSnackbar('UPGRADING_APPLICATION')

const showImportCompleteSnackbar = () => showSnackbar(snackbarTypes.SNACKBAR_COMPLETE, { message: 'IMPORT_COMPLETE' })

const hideLoadingSnackbar = () => hideSnackbar(snackbarTypes.SNACKBAR_LOADING)

const showErrorCreatingDraftSnackbar = () => showSnackbar(snackbarTypes.ERROR_CREATING_DRAFT)

const showPublishCompleteSnackbar = () => showSnackbar(snackbarTypes.SNACKBAR_COMPLETE, { message: 'PUBLISH_COMPLETE' })

const showArchivingCompleteSnackbar = () => showSnackbar(snackbarTypes.SNACKBAR_COMPLETE, { message: 'APPLICATION_ARCHIVING_COMPLETE' })

const showErrorArchivingApplication = () => showSnackbar(snackbarTypes.ERROR_ARCHIVING_APPLICATION)

const showExportingApplicationSnackbar = () => showSnackbar(snackbarTypes.SNACKBAR_LOADING, { message: 'CREATING_APPLICATION_ZIP' })

const showDownloadReadySnackbar = () => showSnackbar(snackbarTypes.SNACKBAR_COMPLETE, { message: 'DOWNLOAD_READY' })

const showSuccessSnackbar = (message) => showSnackbar(snackbarTypes.SNACKBAR_SUCCESS, { message })

const showMessageSnackbar = (message, type) => showSnackbar(snackbarTypes.SNACKBAR_MESSAGE, { message, type })

const showIncompatibleVersionError = () => showSnackbar(snackbarTypes.SNACKBAR_INCOMPATIBLE_IMPORT_VERSION)

const showUpgradeError = () => showSnackbar(snackbarTypes.SNACKBAR_UPGRADE_ERROR)

export default {
  onShowSnackbar: showSnackbar,
  onHideSnackbar: hideSnackbar,
  showPublishingSnackbar,
  showCreatingDraftSnackbar,
  showErrorCreatingDraftSnackbar,
  showPublishCompleteSnackbar,
  showLoadingSnackbar,
  showArchivingApplicationSnackbar,
  hideLoadingSnackbar,
  showArchivingCompleteSnackbar,
  showErrorArchivingApplication,
  showExportingApplicationSnackbar,
  showDownloadReadySnackbar,
  showImportingApplicationSnackbar,
  showUpgradingApplicationSnackbar,
  showImportCompleteSnackbar,
  showSuccessSnackbar,
  showMessageSnackbar,
  showIncompatibleVersionError,
  showUpgradeError
}
