import users from './users'
import user from './user'
import groups from './groups'
import userGroups from './userGroups'
import group from './group'
import groupMembers from './groupMembers'
import groupsWithMembers from './groupsWithMembers'

export default {
  users,
  user,
  groups,
  group,
  userGroups,
  groupMembers,
  groupsWithMembers
}
