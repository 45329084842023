import React from 'react'
import classNames from 'classnames'
import { FieldControl, CursorTooltip } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import DeleteIcon from 'material-ui/svg-icons/navigation/close'
import UserRoleIcon from '../../../../../Common/Icons/users/role/UserRoleExisting'
import UserRoleAddIcon from '../../../../../Common/Icons/users/role/UserRoleAdd'

import './Mappings.css'

const DEFAULT_ROLE = 'user'

export function RoleMapping({
  options = [],
  role = DEFAULT_ROLE,
  filter,
  onDelete,
  onChange,
  disabled
}) {
  const [defaultOption = {}] = options
  const nameProps = {
    field: 'name',
    name: 'name',
    type: 'select',
    options,
    disabled
  }
  const filterProps = {
    field: 'filter',
    name: 'filter',
    type: 'text',
    disabled: disabled
  }

  const Icon = disabled ? UserRoleAddIcon : UserRoleIcon

  const handleChange = (field, value) => {
    onChange({ name: role, filter, [field]: value })
  }

  return (
    <div className={classNames('mapping-row', { disabled })}>
      <div className="mapping-row__role-picker">
        <Icon className="row-icon" />
        <FieldControl
          fieldProps={nameProps}
          defaultValue={defaultOption.value}
          value={role}
          onChange={handleChange} />
      </div>
      <div className="mapping-row__filter-picker">
        <FieldControl fieldProps={filterProps} value={filter} onChange={handleChange} />
        {onDelete &&
          <CursorTooltip
            className="mapping-row__delete-icon-tooltip"
            tooltip={<FormattedMessage id="ACTION_REMOVE" defaultMessage="Remove" />}>
            <DeleteIcon className="mapping-row__delete-icon" onTouchTap={onDelete} />
          </CursorTooltip>}
      </div>
    </div>
  )
}
