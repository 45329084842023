import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const deviceClientRun = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M21,2 C22.1045695,2 23,2.8954305 23,4 L23,16 C23,17.1045695 22.1045695,18 21,18 L14,18 L14,21 L15.5,21 C15.7761424,21 16,21.2238576 16,21.5 C16,21.7761424 15.7761424,22 15.5,22 L8.5,22 C8.22385763,22 8,21.7761424 8,21.5 C8,21.2238576 8.22385763,21 8.5,21 L10,21 L10,18 L3,18 C1.89,18 1,17.1 1,16 L1,4 C1,2.89 1.89,2 3,2 L21,2 Z M12,4 C8.6862915,4 6,6.6862915 6,10 C6,11.9234253 6.90505546,13.6354636 8.31270799,14.7333201 C9.29360517,13.8623199 10.5850269,13.3333333 12,13.3333333 C13.4149731,13.3333333 14.7063948,13.8623199 15.68747,14.7334785 C17.0949445,13.6354636 18,11.9234253 18,10 C18,6.6862915 15.3137085,4 12,4 Z M12,7.55555556 C13.3500294,7.55555556 14.4444444,8.64997061 14.4444444,10 C14.4444444,11.3500294 13.3500294,12.4444444 12,12.4444444 C10.6499706,12.4444444 9.55555556,11.3500294 9.55555556,10 C9.55555556,8.64997061 10.6499706,7.55555556 12,7.55555556 Z M15.5555556,6.22222222 C16.1692053,6.22222222 16.6666667,6.71968361 16.6666667,7.33333333 C16.6666667,7.94698306 16.1692053,8.44444444 15.5555556,8.44444444 C14.9419058,8.44444444 14.4444444,7.94698306 14.4444444,7.33333333 C14.4444444,6.71968361 14.9419058,6.22222222 15.5555556,6.22222222 Z" />
    </SvgIcon>
  )
}

export default deviceClientRun
