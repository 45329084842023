import React from 'react'
// Consider moving showWide and showIfFits to separate map
import { FormattedMessage } from 'react-intl'
import { UserTitle, FormattedDateTime, getUserTitle, DetailsLink } from 'skylight-common'
import skylight from 'skylight-js-sdk'
import { GROUP } from '../../../../svc/scopes'
import { AssignmentStatus } from './AssignmentStatus'
import { AttachmentIcon } from '../AssignmentAttachments/AttachmentIcon'

const onNavigateToUser = (item, props) => {
  props.onUserSelectItems([item.id], [item])
}

const renderDetailsLink = (user, props) => <DetailsLink
  item={user}
  path="/team/users"
  renderLink={getUserTitle}
  onNavigateToDetails={() => onNavigateToUser(user, props)} />

const allColumns = [
  {
    name: 'name',
    title: { id: 'APPLICATION_NAME', default: 'Workflow name' },
    render: item => item.name
  },
  {
    name: 'assignedTo',
    title: { id: 'ASSIGNED_TO', default: 'Assigned to' },
    sortable: false,
    render: (item, props) => <UserTitle
      render={skylight.auth.hasScopes([GROUP.EDIT])
        ? u => renderDetailsLink(u, props)
        : null}
      renderError={() => <FormattedMessage id="UNASSIGNED" defaultMessage="Unassigned" />}
      user={item.assignedTo || null} />
  },
  {
    name: 'created',
    title: { id: 'ASSIGNED_ON', default: 'Assigned on' },
    render: item => <FormattedDateTime value={item.created} />
  },
  {
    name: 'createdBy',
    title: { id: 'ASSIGNED_BY', default: 'Assigned by' },
    sortable: false,
    render: (item, props) => <UserTitle
      render={skylight.auth.hasScopes([GROUP.EDIT])
        ? u => renderDetailsLink(u, props)
        : null}
      user={item.createdBy} />
  },
  {
    name: 'updated',
    title: { id: 'LAST_UPDATED_ON', default: 'Last updated on' },
    render: item => <FormattedDateTime value={item.updated} />
  },
  {
    name: 'status',
    title: { id: 'ASSIGNMENT_STATUS', default: 'Status' },
    sortable: false,
    render: item => <AssignmentStatus item={item} />
  },
  {
    name: 'description',
    title: { id: 'DESCRIPTION', default: 'Description' },
    sortable: false
  }
  // {
  //   name: 'data',
  //   title: { id: 'VIEW_CAPTURED', default: 'View captured results'},
  //   render: (item, props) => <AttachmentIcon {...props} item={item} />
  // }
  // Removed as a part of - https://favro.com/organization/60fe74a05e7da9c931484a9a/51c92a8e11bfc44b565332e8?card=Ups-8991
  // {
  //   name: 'attachments',
  //   title: { id: 'ATTACHMENTS', default: 'Attachments' },
  //   className: 'actions',
  //   render: (item, a) => item.status !== 'error' ? <IconButton
  //     className="assignment-attachment-action"
  //     tooltipPosition="bottom-center"
  //       tooltip={<FormattedMessage
  //         id="VIEW_CAPTURED"
  //         defaultMessage="View captured results" />}
  //     children={<MaterialIcon icon="attach_file" />}
  //     onTouchTap={(e) => {
  //       e.stopPropagation()
  //       e.preventDefault()
  //       a.onLoadAttachment(item, true)
  //     }} /> : null
  // },
]

if (window.location.search.indexOf('showAssignmentResults') > -1) {
  allColumns.push({
    name: 'data',
    title: { id: 'VIEW_CAPTURED', default: 'View captured results' },
    render: (item, props) => <AttachmentIcon {...props} item={item} />
  })
}

export { allColumns }
