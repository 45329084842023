import * as ActionTypes from '../types'
import { push } from 'react-router-redux'
import { createFilterActions, createListActions, createItemActions } from 'skylight-common'
import { getParticipantsFromRoles } from '../../svc/application/participants'
import { getRoles, updateRole } from '../../svc/application/roles'
import { DEFAULT_QUERY } from '../../lib/application/participant'
import { DEFAULT_ROLES } from '../../lib/application/roles'
import roleActions from './roles'

const LIST_ACTIONS = createListActions({
  prefix: ActionTypes.PREFIX_APPLICATION_PARTICIPANT.toUpperCase(),
  handler: ActionTypes.PREFIX_APPLICATION_PARTICIPANT,
  onGetList: s => s.application.participant.list,
  onLoad: async(query, { dispatch, getState }) => {
    const state = getState()
    const { applicationId } = state.application.selectedApplication
    const roles = await getRoles(applicationId)
    dispatch({
      type: 'LIST_APPLICATIONROLE_LOADED',
      items: roles
    })

    return getParticipantsFromRoles(roles.data, query)
  }
})

const FILTER_ACTIONS = createFilterActions({
  baseUrl: '/ng/applications/:applicationId/permissions',
  redirect: false,
  selector: state => state.application.participant.list.query,
  listPrefix: ActionTypes.PREFIX_APPLICATION_PARTICIPANT.toUpperCase(),
  actionPrefix: ActionTypes.PREFIX_APPLICATION_PARTICIPANT,
  DEFAULT_QUERY,
  loadItems: LIST_ACTIONS.onApplicationParticipantLoadItems,
  push
})

const redirectToNewQuery = FILTER_ACTIONS.onApplicationParticipantQueryUpdated

const participantSortUpdate = (sortBy, sortAsc) =>
  redirectToNewQuery(q => ({ ...q, sortBy, sortAsc }))

const ITEM_ACTIONS = createItemActions({
  prefix: ActionTypes.PREFIX_APPLICATION_PARTICIPANT.toUpperCase(),
  handler: ActionTypes.PREFIX_APPLICATION_PARTICIPANT,
  onGetList: s => s.application.participant.list,
  onSave: () => {},
  onLoad: async(item, res, ctx) => {
    return item
  },
  onLoadItems: LIST_ACTIONS.onApplicationParticipantLoadItems
})

const participantRoleAdd = (participant, roleId) => async(dispatch, getState) => {
  const state = getState()
  const { applicationId } = state.application.selectedApplication
  const role = state.application.role.list.items.find(role => role.roleId === roleId)
  const hasRole = participant.roles.includes(role.roleId)
  if (hasRole) {
    return
  }

  const newRoleParticipant = {
    id: participant.id,
    type: participant.type
  }

  try {
    dispatch(participantListItemRoleAdd(participant, roleId))
    const newRole = { ...role, participants: [...role.participants, newRoleParticipant] }
    await updateRole(applicationId, roleId, newRole)
    dispatch(roleActions.onApplicationRoleListItemUpdate(newRole))
  } catch (e) {
    dispatch({ type: ActionTypes.ROLE_UPDATE_ERROR, message: e.message })
    dispatch(participantListItemRoleRemove(participant, roleId))
  }
}

const participantRoleRemove = (participant, roleId) => async(dispatch, getState) => {
  if (participant.roles.length === 1) {
    return
  }

  const state = getState()

  const role = state.application.role.list.items.find(role => role.roleId === roleId)
  if (role.name === DEFAULT_ROLES.OWNER) {
    const participants = state.application.participant.list.items
    const hasAnotherOwner = participants.some(p => participant.id !== p.id && p.roles.includes(role.roleId))
    if (!hasAnotherOwner) {
      dispatch({ type: ActionTypes.APPLICATION_SINGLE_OWNER_REMOVE_ERROR })
      return
    }
  }

  const { applicationId } = state.application.selectedApplication
  const roleParticipants = role.participants.filter(p => p.id !== participant.id)
  try {
    dispatch(participantListItemRoleRemove(participant, roleId))
    const newRole = { ...role, participants: roleParticipants }
    await updateRole(applicationId, roleId, newRole)
    dispatch(roleActions.onApplicationRoleListItemUpdate(newRole))
  } catch (e) {
    dispatch({ type: ActionTypes.ROLE_UPDATE_ERROR, message: e.message })
    dispatch(participantListItemRoleAdd(participant, roleId))
  }
}

const participantListItemRoleAdd = (participant, roleId) => dispatch => {
  dispatch(LIST_ACTIONS.onApplicationParticipantListItemUpdate({
    ...participant,
    roles: [...participant.roles, roleId]
  }))
}

const participantListItemRoleRemove = (participant, roleId) => dispatch => {
  dispatch(LIST_ACTIONS.onApplicationParticipantListItemUpdate({
    ...participant,
    roles: [...participant.roles.filter(r => r !== roleId)]
  }))
}

const refreshParticipantsList = () => (dispatch, getState) => {
  const state = getState()
  dispatch(LIST_ACTIONS.onApplicationParticipantLoadItems(state.application.participant.list.query))
}

const addApplicationParticipants = (participants, roleIds) => async(dispatch, getState) => {
  const state = getState()
  const { selectedApplication, role } = state.application
  const { applicationId } = selectedApplication
  const roleItems = role.list.items.filter(role => roleIds.includes(role.roleId))
  try {
    await Promise.all(roleItems.map(async role => {
      const newParticipants = participants.filter(participant => !role.participants.find(p => p.id === participant.id))
      const roleParticipants = [...role.participants, ...newParticipants]
      await updateRole(applicationId, role.roleId, { ...role, participants: roleParticipants })
    }))
    dispatch(refreshParticipantsList())
  } catch (e) {
    dispatch({ type: ActionTypes.ROLE_UPDATE_ERROR, message: e.message })
  }
}

const removeSelectedApplicationParticipants = () => async(dispatch, getState) => {
  const state = getState()
  const { applicationId } = state.application.selectedApplication
  const { items, selectedIds } = state.application.participant.list
  const participantsToRemoveIdsByRoleId = items
    .filter(item => selectedIds.includes(item.id))
    .reduce((acc, participant) => {
      participant.roles.forEach(roleId => {
        if (Array.isArray(acc[roleId])) {
          acc[roleId].push(participant.id)
        } else {
          acc[roleId] = [participant.id]
        }
      })

      return acc
    }, {})

  try {
    const roles = await getRoles(applicationId)

    const ownerRole = roles.data.find(r => r.name === DEFAULT_ROLES.OWNER) || {}
    const ownersToRemove = participantsToRemoveIdsByRoleId[ownerRole.roleId] || []
    if (ownersToRemove.length) {
      const filteredOwners = ownerRole.participants.filter(p => !ownersToRemove.includes(p.id))
      if (!filteredOwners.length) {
        dispatch({ type: ActionTypes.APPLICATION_ALL_OWNERS_REMOVE_ERROR })
        return
      }
    }

    const rolesToUpdate = roles.data.filter(role => !!participantsToRemoveIdsByRoleId[role.roleId])
    await Promise.all(rolesToUpdate.map(async role => {
      const participantIdsToRemove = participantsToRemoveIdsByRoleId[role.roleId]
      const filteredParticipants = role.participants.filter(p => !participantIdsToRemove.includes(p.id))
      await updateRole(applicationId, role.roleId, { ...role, participants: filteredParticipants })
    }))
    dispatch(refreshParticipantsList())
    dispatch(LIST_ACTIONS.onApplicationParticipantSelectItems([]))
  } catch (e) {
    dispatch({ type: ActionTypes.APPLICATION_PARTICIPANT_REMOVE_ERROR, message: e.message })
  }
}

const manageRolesOpen = applicationId => dispatch => {
  dispatch(push(`/ng/applications/${applicationId}/permissions/roles`))
}

export default {
  ...LIST_ACTIONS,
  ...FILTER_ACTIONS,
  ...ITEM_ACTIONS,
  onParticipantSortUpdate: participantSortUpdate,
  onParticipantRoleAdd: participantRoleAdd,
  onParticipantRoleRemove: participantRoleRemove,
  onAddApplicationParticipants: addApplicationParticipants,
  onApplicationParticipantsRemoveSelected: removeSelectedApplicationParticipants,
  onManageRolesOpen: manageRolesOpen
}
