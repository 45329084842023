import React from 'react'
import skylight from 'skylight-js-sdk'
import callSdk from 'skylight-call-sdk'
import NoVideo from 'material-ui/svg-icons/av/videocam-off'
import './CallVideo.css'

class CallVideo extends React.Component {
  type = null
  componentDidUpdate() {
    this.type = this.props.type
  }

  hasTypeChanged = () => this.type && this.type !== this.props.type

  render() {
    const { type, onClick } = this.props
    const stream = callSdk.lib.getCachedStream(type.toLowerCase())
    // Make sure that video stream is `live`.
    // See - https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamTrack/readyState
    // Original issue - https://upskill.atlassian.net/browse/SKY-6259
    const hasVideoTracks = stream && stream.getTracks().some(x => x.kind === 'video' && x.readyState === 'live')
    const className = `call-video ${this.props.className || ''}`

    return (
      <div className={className} onClick={onClick}>
        {type === 'REMOTE' && <audio autoPlay ref={audio => {
          if (audio && stream && !audio.srcObject) {
            audio.srcObject = stream
          }
        }} />}
        <video autoPlay muted ref={video => {
          skylight.events.emit('setVideoRef', { type, ref: video })
          // Make sure that stream set only once to prevent flickering or when type is changed.
          if (video && (this.hasTypeChanged() || (hasVideoTracks && stream && !video.srcObject))) {
            video.srcObject = stream
          }
        }} />
        {!hasVideoTracks && <NoVideo className="no-video" />}
      </div>
    )
  }
}

export { CallVideo }
