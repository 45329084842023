import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import LockIcon from 'material-ui/svg-icons/action/lock'
import ApplicationsIcon from '../../../Common/Icons/applications'
import { ApplicationStatusIcon } from './ApplicationStatusIcon'
import { formatDate } from '../../../../lib/date'
import { Tooltip, UserTitle } from 'skylight-common'
import './ApplicationTile.css'

class ApplicationTile extends Component {
  getClassName = (nextProps) => {
    const { item, selectedIds } = nextProps || this.props

    return [
      'application-tile',
      selectedIds.length === 1 && selectedIds.includes(item.id)
        ? 'selected'
        : '',
      selectedIds.length > 1 && selectedIds.includes(item.id)
        ? 'multi-selected'
        : '',
      item.isLocked ? 'locked' : ''
    ].join(' ')
  }

  onSelect = (e) => {
    e.stopPropagation()
    const { selectedIds } = this.props
    const { id } = this.props.item
    // TODO: Move out, consider making GridView HOC.
    const { metaKey, ctrlKey, shiftKey } = e.nativeEvent
    if (shiftKey) {
      this.props.onShiftSelect(id)
      return
    }

    const sel = metaKey || ctrlKey
      ? (selectedIds.includes(id)
          ? selectedIds.filter(x => x !== id)
          : [...selectedIds, id])
      : id

    this.props.onCheck(sel)
  }

  onOpenApplication = () => {
    const { item, onOpenApplication } = this.props
    onOpenApplication(item)
  }

  shouldComponentUpdate(nextProps) {
    const { item, status } = this.props
    const { item: nextItem, status: nextStatus } = nextProps

    // Rerender only if className/status or the item itself was updated.
    return item !== nextItem || status[item.id] !== nextStatus[item.id] ||
      this.getClassName() !== this.getClassName(nextProps)
  }

  renderLockIcon = () => {
    return this.props.item.isLocked
      ? <Tooltip
        className="lock-icon-wrapper"
        tooltipPosition={{
          horizontalPosition: 'right',
          verticalPosition: 'bottom'
        }}
        tooltip={<FormattedMessage id="LOCKED_APPLICATION" defaultMessage="Locked application" />}>
        <LockIcon className="lock-icon" />
      </Tooltip>
      : null
  }

  render() {
    const { item, status } = this.props

    // onDoubleClick should not work for mobile devices.
    return (
      <div
        className={this.getClassName()}
        onTouchTap={this.onSelect}
        onDoubleClick={this.onOpenApplication}>
        <div className="content">
          {this.renderLockIcon()}
          <ApplicationStatusIcon
            item={item}
            status={status}
            onError={this.props.onAssignmentByApp} />
          <ApplicationsIcon className="icon" />
          <Tooltip tooltip={item.description}>
            <div className="name">{item.name}</div>
          </Tooltip>
          <div className="details">
            <div className="creator">
              <UserTitle user={item.createdBy} />
            </div>
            <div className="time">{formatDate(item.modifiedAt)}</div>
          </div>
        </div>
        <div className="actions">
          {this.props.renderActions(item)}
        </div>
      </div>
    )
  }
}

export { ApplicationTile }
