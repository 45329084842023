import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { CONFIG } from 'skylight-common'
import SignInPicker from './SignInPicker'
import ForgotPasswordLink from './ForgotPasswordLink/ForgotPasswordLink'
import { CHANGE_PASSWORD } from '../../Home/Layout/Dialogs/dialogTypes'
import { OutlinedTextField } from '../../Common/Form/OutlinedTextField'
import OutlinedPasswordField from '../../Common/Form/OutlinedPasswordField/OutlinedPasswordField'
import LoginFormButton from '../LoginFormButton/LoginFormButton'

import './SignIn.css'

const FIELDS = [
  {
    name: 'username',
    label: { id: 'USERNAME', default: 'Username' },
    autoFocus: true,
    autoCorrect: 'off',
    autoCapitalize: 'off',
    action: 'onUsernameChange',
    component: OutlinedTextField
  },
  {
    name: 'password',
    label: { id: 'PASSWORD', default: 'Password' },
    action: 'onPasswordChange',
    type: 'password',
    floatingLabelFixed: true,
    hint: { id: 'FORGOTPWD', defaultMessage: 'Forgot password? Contact your administrator for assistance.' },
    component: OutlinedPasswordField
  }
]

class SignIn extends Component {
  onSignIn = (e) => {
    e && e.preventDefault()
    const { config } = this.props.login.realm

    this.props.onSignIn({
      ...this.props.login.signin,
      location: this.props.history.location,
      url: config ? config.url : null
    })
  }

  renderErrorOrHint = () => {
    const { errors } = this.props.login.signin
    const key = ['realm', 'username', 'password', 'frontline'].find(x => errors[x])
    if (key) {
      return (
        <div className="error-text">
          <FormattedMessage
            id={errors[key]}
            defaultMessage="An error occurred during login. Please try again or contact administrator." />
        </div>
      )
    }

    // Return a default hint if no error to render.
    return <ForgotPasswordLink onTouchTap={() => this.props.redirect('/recover-password')} />
  }

  componentDidMount() {
    const { urlRealm, login } = this.props
    if (!login.signin.realm && urlRealm) {
      this.props.onRealmSignin(urlRealm, true)
      return
    }

    this.redirectOnRealmError()
    if (login.signin.username && this.password && !Object.keys(login.signin.errors).length) {
      this.password.focus()
    }
  }

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    this.redirectOnRealmError(nextProps)
  }

  redirectOnRealmError = (nextProps) => {
    const { redirect, login } = nextProps || this.props
    if (login.realm.realmError && CONFIG.useCarbon) {
      redirect('/login')
    }
  }

  render() {
    const { props } = this
    const { signin, realm } = props.login
    const { dialog } = props
    // disable fields while change temp password dialog is open, while they are disabled nothing can fill them with data
    // last pass fix - https://upskill.atlassian.net/browse/SKY-7489
    const changePasswordIsOpen = dialog[CHANGE_PASSWORD] && dialog[CHANGE_PASSWORD].open
    const authApps = realm.authApps || []

    return (
      <form className="signin" onSubmit={this.onSignIn}>
        {FIELDS.map(x => x.name !== 'realm' || !CONFIG.useCarbon
          ? <x.component
            required
            textFieldRef={ref => { this[x.name] = ref }}
            disabled={changePasswordIsOpen}
            key={x.name}
            autoCorrect={x.autoCorrect}
            autoCapitalize={x.autoCapitalize}
            className={
              `${x.name} ${signin.errors[x.name] || signin.errors.realm ? 'error' : ''}`
            }
            floatingLabelText={<FormattedMessage
              id={x.label.id}
              defaultMessage={x.label.default} />}
            name={x.name}
            id={x.name}
            value={signin[x.name]}
            floatingLabelFixed={x.floatingLabelFixed ? !props.app.nativeUserEvent : undefined}
            type={x.type}
            onFocus={() => props.onSigninErrorsClear()}
            errorStyle={{ display: 'none' }}
            onChange={(e, v) => props[x.action](v)}
            onBlur={e => this.props.handleAutofill(e.target.value, signin[x.name], this.props[x.action])} />
          : null)}
        {this.renderErrorOrHint()}
        <LoginFormButton
          className="signin-btn"
          label={<FormattedMessage id="SIGNIN" defaultMessage="Sign in" />}
          primary
          fullWidth
          type="submit" />
        <SignInPicker authApps={authApps} onStartSignIn={this.props.onStartSignIn} />
      </form>
    )
  }
}

export { SignIn }
