import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { ItemsPicker } from '../../../../../../../Common/ItemsPicker/ItemsPicker'
import ShieldAccount from '../../../../../../../Common/Icons/shieldAccount'

import './RolesPicker.css'

const TRANSLATIONS = {
  MENU_PICKER_LOADING: 'LOADING',
  MENU_PICKER_ADD: 'ROLE_CREATE_BY_NAME',
  MENU_PICKER_SEARCH: 'ROLE_SEARCH_BY_NAME'
}

class RolesPicker extends Component {
  onChange = (role, checked) => {
    if (!checked) {
      this.props.onRoleAdd(role.roleId)
    } else {
      this.props.onRoleRemove(role.roleId)
    }
  }

  handleManageRolesClick = () => {
    this.props.onManageRolesClick && this.props.onManageRolesClick()
  }

  renderMenuPickerFooter = () => (
    <div className="menu-picker-footer">
      <div className="manage-roles-button-container">
        <div
          onClick={this.handleManageRolesClick}
          className="manage-roles-button">
          <FormattedMessage id="ROLES_MANAGE" defaultMessage="MANAGE ROLES" />
        </div>
      </div>
    </div>
  )

  renderPlaceholder = () => (
    <div className="roles-picker__placeholder">
      <FormattedMessage id="ADD_ROLE_S" defaultMessage="Add role(s)" />
    </div>
  )

  render() {
    const { isLoading, selectedRoles } = this.props
    return <ItemsPicker
      className="roles-picker"
      itemIcon={<ShieldAccount />}
      renderItem={item => item.name}
      isLoading={isLoading}
      items={this.props.items}
      selectedItems={selectedRoles}
      placeholder={this.renderPlaceholder()}
      translations={TRANSLATIONS}
      disabled={this.props.disabled}
      onChange={this.onChange}
      footer={this.renderMenuPickerFooter()} />
  }
}

export { RolesPicker }
