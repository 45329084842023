import skylight from 'skylight-js-sdk'
import { APPLICATION_STATUSES } from '../lib/nextGenApplication'
import { ListQueryBuilder } from './application/listQueryBuilder'
import { EXPORT_STATUSES, IMPORT_STATUSES } from '../lib/application/exportImport'
import get from 'lodash/get'

const ARCHIVED_QUERY_VALUES = {
  INCLUDE: 'include',
  ONLY: 'only',
  EXCLUDE: 'exclude'
}

const getArchivedQueryValue = (status = []) => {
  if (status.includes(APPLICATION_STATUSES.ARCHIVED) && status.includes(APPLICATION_STATUSES.ACTIVE)) {
    return ARCHIVED_QUERY_VALUES.INCLUDE
  }

  if (status.includes(APPLICATION_STATUSES.ARCHIVED)) return ARCHIVED_QUERY_VALUES.ONLY

  return ARCHIVED_QUERY_VALUES.EXCLUDE
}

export const loadApplications = async query => {
  const { title, createdBy, createdAt, status } = query.filter

  const applicationQuery = new ListQueryBuilder({
    limit: query.limit,
    sort: `${query.sortAsc ? '' : '-'}${query.sortBy}`,
    archived: getArchivedQueryValue(status),
    imports: 'include',
    importStatus: IMPORT_STATUSES.ERRORED
  })
    .byName(title)
    .byCreatedBy(createdBy)
    .byCreatedAt(createdAt)
    .buildQuery()

  if (query.nextCursor) {
    applicationQuery.nextCursor = query.nextCursor
  }

  const applications = await skylight.we20.application.list(applicationQuery)
  return {
    data: applications.data.reduce((acc, x) => {
      if (x.applicationId) {
        const item = {
          ...x,
          id: x.applicationId,
          modifiedAt: x.modifiedAt || x.createdAt,
          modifiedBy: x.modifiedBy || x.createdBy,
          status: get(x, 'import.status') === IMPORT_STATUSES.ERRORED
            ? IMPORT_STATUSES.ERRORED
            : x.archived
              ? APPLICATION_STATUSES.ARCHIVED
              : APPLICATION_STATUSES.ACTIVE
        }

        acc.push(item)
      }

      return acc
    }, []),
    page: applications.page
  }
}

export const getApplication = async applicationId => {
  let application = null
  try {
    application = await skylight.we20.application.getById(applicationId)
  } catch (e) {
    if (e && e.status === 404) {
      return getApplicationFromList(applicationId)
    }

    throw e
  }

  return application
}

const getApplicationFromList = async applicationId => {
  const query = {
    limit: 200,
    sort: '-createdAt',
    archived: ARCHIVED_QUERY_VALUES.ONLY
  }

  do {
    const { data, page } = await skylight.we20.application.list(query)
    const application = data.find(x => x.applicationId === applicationId)
    if (application) {
      return application
    }

    query.nextCursor = page.nextCursor
  } while (query.nextCursor)

  return null
}

export const getLastApplicationExport = async applicationId => {
  const query = {
    limit: 50,
    sort: '-createdAt'
  }

  do {
    const { data, page } = await skylight.we20.export.list(applicationId, query)
    const lastApplicationExport = data.find(e => e.status === EXPORT_STATUSES.COMPLETED)
    if (lastApplicationExport) {
      const { stage } = await skylight.we20.version.getById(lastApplicationExport.applicationId, lastApplicationExport.version)
      lastApplicationExport.versionStage = stage
      return lastApplicationExport
    }

    query.nextCursor = page.nextCursor
  } while (query.nextCursor)

  return null
}
