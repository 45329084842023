import contactActions from './contact/index'

const initTeam = (contact) => {
  return (dispatch) => {
    dispatch(contactActions.onUserLoadItems(contact.users.query))
    dispatch(contactActions.onGroupLoadItems(contact.groups.query))
  }
}

export default {
  ...contactActions,
  onInitTeam: initTeam
  // onLoadContacts: loadContacts,
  // onSelectUsers: selectUsers, -> onUserSelectItems

  // ...GROUP_MEMBER_LIST_ACTIONS,
  // onSelectGroups: selectGroups, -> onGroupSelectItems
  // onLoadGroups: loadGroups, -> onGroupLoadItems
  // onEditGroup: editGroup, -> onGroupChangeMode
  // onCancelEditGroup: cancelEditGroup, -> onGroupCancel
  // onNewGroup: newGroup, -> onGroupNew + onGroupNew
  // onGroupPropChange: changeGroupProp, -> onGroupChange
  // onGroupSave: saveGroup,
  // onGroupsRemove: removeGroups,

  // onLoadGroupMembers: loadGroupMembers, -> onGroupMembersLoadItems
  // onUsersRemove: removeUsers, -> onUserRemoveItems
  // Unused?!
  // onRemoveGroupMembers: removeGroupMembers,
  // onAddGroupMembers: addGroupMembers,
  // onRemoveGroupMember: removeGroupMember,
  // onAddGroupMember: addGroupMember,
  // onCreateUserGroup: createUserGroup,
  // onNewUser: newUser, -> onUserNew
  // onEditUser: editUser, -> onUserChangeMode
  // onChangeUserProp: changeUserProp, -> onUserChange
  // onCancelEditUser: cancelEditUser, -> onUserCancel
  // onSaveUser: saveUser, -> onUserSave

  // ...CALL_FILTER_ACTIONS,
}
