import * as ActionTypes from './types'
import snackbarActions from './snackbar'
import applicationImportActions from './applicationImport'
import applicationExportActions from './applicationExport'
import { MQTT_EVENT_TYPES } from '../lib/mqtt'
import { EXPORT_STATUSES } from '../lib/application/exportImport'
import error from './error'
import { afterApplicationUpgrade, setUpgradeInProgress } from './applicationUpgrade'

const taskAction = ({ type, topic, payload }) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.JOB_TASK_STATUS_CHANGED, ...payload })
}

const eventsAction = ({ type, topic, payload }) => (dispatch, getState) => {
  if (payload.eventType === 'workflow') {
    dispatch({ type: ActionTypes.WORKFLOW_APPLICATION_EVENT, ...payload })
  }
}

const applicationsAction = ({ type, topic, payload }) => (dispatch, getState) => {
  const eventType = payload.eventType

  if (eventType === MQTT_EVENT_TYPES.EXPORT.EXPORT_COMPLETED) {
    const { exporting } = getState().application.applicationExport
    if (!exporting) return

    const { id: userId } = getState().auth.user
    const exportPayload = payload.export

    // prevent from downloading exports initiated by others
    if (userId !== exportPayload.createdBy) return

    if (exportPayload.status === EXPORT_STATUSES.ERRORED) {
      dispatch(snackbarActions.hideLoadingSnackbar())
      dispatch({ type: ActionTypes.APPLICATION_EXPORT_FINISHED })
      return
    }

    dispatch(applicationExportActions.onApplicationExportCompleted(exportPayload))
  } else if (eventType === MQTT_EVENT_TYPES.EXPORT.EXPORT_ERRORED) {
    dispatch(snackbarActions.hideLoadingSnackbar())
    dispatch(error.onShowError('APPLICATION_EXPORT_ERROR', { className: 'snackbar-common-error' }))
    dispatch({ type: ActionTypes.APPLICATION_EXPORT_FINISHED })
  }
}

const usersActions = ({ type, topic, payload }) => (dispatch, getState) => {
  const eventType = payload.eventType
  switch (eventType) {
    case MQTT_EVENT_TYPES.IMPORT.IMPORT_COMPLETED:
      dispatch(applicationImportActions.onApplicationImportCompleted(payload))
      break
    case MQTT_EVENT_TYPES.IMPORT.IMPORT_ERRORED:
      dispatch(applicationImportActions.onApplicationImportFailed(payload))
      break
    case MQTT_EVENT_TYPES.UPGRADE.CREATED:
      dispatch(setUpgradeInProgress(payload.upgrade, true))
      break
    case MQTT_EVENT_TYPES.UPGRADE.COMPLETED:
      dispatch(afterApplicationUpgrade(payload.upgrade))
      dispatch(setUpgradeInProgress(payload.upgrade, false))
      if (payload.upgrade.applicationId === getState().application.selectedApplication.applicationId) {
        dispatch(snackbarActions.hideLoadingSnackbar())
      }
      break
    case MQTT_EVENT_TYPES.UPGRADE.ERRORED:
      dispatch(setUpgradeInProgress(payload.upgrade, false))
      dispatch(afterApplicationUpgrade(payload.upgrade))
      if (payload.upgrade.applicationId === getState().application.selectedApplication.applicationId) {
        dispatch(snackbarActions.hideLoadingSnackbar())
        dispatch(snackbarActions.showUpgradeError())
      }
      break
    case MQTT_EVENT_TYPES.UPGRADE.DELETED:
      dispatch(setUpgradeInProgress(payload.upgrade, false))
      break
    default:
      break
  }
}

const HANDLERS = {
  tasks: taskAction,
  events: eventsAction,
  applications: applicationsAction,
  users: usersActions
}

const factory = (mqttPayload) => {
  const handler = HANDLERS[mqttPayload.type]
  if (!handler) {
    console.log('MQTT handler ', mqttPayload.type, ' is not implemented')
    return { type: ActionTypes.NOT_IMPLEMENTED }
  }

  return handler(mqttPayload)
}

export default factory
