import React from 'react'

import { AdminTabs } from '../AdminTabs/AdminTabs'

class AdminBase extends React.Component {
  componentWillUnmount() {
    this.props.onLdapAbortSync()
  }

  render() {
    const { match } = this.props
    return (
      <div className="admin">
        <AdminTabs {...this.props} type={match.params.type} />
      </div>
    )
  }
}

export { AdminBase }
