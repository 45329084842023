import React from 'react'
import { isCaptchaEnabled } from './captcha'

const CaptchaBadge = () => {
  if (!isCaptchaEnabled()) return null
  return (
    <div className="captcha-badge">
      This site is protected by reCAPTCHA and the Google&nbsp;
      <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and&nbsp;
      <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.
    </div>
  )
}

export default CaptchaBadge
