import React from 'react'
import { isMobile } from 'skylight-common'
import UploadIcon from 'material-ui/svg-icons/file/cloud-upload'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'
import { FileDropZone } from '../../../../../Common/FileDropZone/FileDropZone'

import './ImportFromFileTab.css'

const TRANSLATIONS = {
  SET_MEDIA: 'UPLOAD_MEDIA',
  TITLE: 'TITLE_UPLOAD_MEDIA',
  DRAG_IMAGE: 'DRAG_AND_DROP_FILE',
  SELECT_PREVIEW: 'BROWSE_FILES',
  SELECT_DEVICE_PREVIEW: 'SELECT_DEVICE_MEDIA',
  DASH_OR: 'OR'
}

export class ImportFromFileTab extends React.Component {
  state = {
    selected: null,
    isFileOver: false
  }

  getTranslationByKey = key => {
    return TRANSLATIONS[key] || '_'
  }

  selectMedia = selected => {
    this.setState({ selected, isFileOver: false })
    this.props.onUpload(selected[0])
  }

  handleUnsupportedFileUploaded = () => {
    this.props.onShowError('ONLY_ZIP_ALLOWED', { className: 'snackbar-common-error' })
  }

  handleIsFileOver = _.throttle((isFileOver) => {
    this.setState({ isFileOver })
  }, 200)

  renderDraggingFile = () => {
    if (!this.state.isFileOver) {
      return null
    }

    return (
      <div className="dragging-file">
        <div className="upload-icon-wrapper">
          <UploadIcon />
        </div>
        <div className="drop-file-to-upload">
          <FormattedMessage id="DROP_FILE_TO_UPLOAD" defaultMessage="Drop file to upload" />
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="import-from-file-container">
        <FileDropZone
          fileOverCallback={this.handleIsFileOver}
          disablePreview
          onUnsupportedFileError={this.handleUnsupportedFileUploaded}
          validateFileOnDrag={false}
          allowedTypes={['application/zip', 'application/x-zip-compressed']}
          excludedTypes={[]}
          allowMultiUpload={false}
          loaderType="circular"
          droppable={!isMobile()}
          onUpload={this.selectMedia}
          selectedFiles={this.state.selected}
          renderPlaceholderIcon={() => null}
          getTranslationByKey={this.getTranslationByKey} />
        {this.props.children}
        {this.renderDraggingFile()}
      </div>
    )
  }
}
