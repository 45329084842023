import React from 'react'
import { FilterToolbar } from 'skylight-common'
import { ByMedia } from './ByMedia'
import { ByTag } from './ByTag'
import { ByMultipleChoice } from './ByMultipleChoice'
import { ByTextInput } from './ByTextInput'
import { ATTACHMENT_DEFAULT_QUERY } from '../../../../../lib/assignment'
import { ByRequired } from './ByRequired'
import './AttachmentFilter.css'

// TODO: Think about value.
const getFilters = (filterProps = {}) => [
  { text: 'MEDIA_TYPE', value: 'media', comp: ByMedia },
  { text: 'MULTIPLE_CHOICE', value: 'decision', props: { filterProps }, comp: ByMultipleChoice },
  { text: 'DATA_FIELD_REQUIRED', value: 'required', comp: ByRequired },
  { text: 'TAG_NAME', value: 'tags', props: { filterProps }, comp: ByTag },
  { text: 'TEXT_INPUT', value: 'textInput', comp: ByTextInput }
]

const AttachmentFilter = props => {
  const filterProps = {
    items: props.items
  }

  const filters = getFilters(filterProps)

  return <FilterToolbar
    className="attachment-filter"
    filters={filters}
    query={props.query}
    defaultQuery={ATTACHMENT_DEFAULT_QUERY}
    layout={props.layout}
    onFilterUpdated={props.onAssignmentAttachmentFilterUpdated}
    onFiltersUpdated={props.onAssignmentAttachmentFiltersUpdated}
    onFilterRemoved={() => {}} />
}

export { AttachmentFilter }
