import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Table, Column, Loading } from 'skylight-common'
import AssignmentIcon from 'material-ui/svg-icons/action/assignment-turned-in'
import './WorkflowList.css'

const WorkflowList = (props) => {
  const { list } = props.workflow
  const icon = props.icon ? props.icon : <AssignmentIcon />

  const onQuery = query => {
    // Prevent workflow picker override target page filter.
    // E.g. workflow query might break the /admin/tokens query.
    return props.onWorkflowQuery(query, { preventPathSave: props.preventPathSave })
  }

  return (
    <div className="workflow-list dashboard-list">
      <Loading
        isLoading={list.isLoading && list.total === 0}
        message="LOADING_WORKFLOW_LIST"
        error={list.error}>
        <Table
          keyProp={'workflowId'}
          showRowNames={!!props.showRowNames}
          showCheckboxes={true}
          checkboxIcon={() => icon}
          showHover={true}
          isLoading={list.isLoading}
          query={list.query}
          done={list.done}
          items={list.items}
          total={list.total}
          selectedRows={list.selectedIds}
          name="workflowList"
          emptyLabel={<FormattedMessage
            id="EMPTY_WORKFLOW_LIST"
            defaultMessage="No applications are created yet" />
          }
          onQuery={onQuery}
          onRowsSelected={props.onSelect}>
          {(props.columns || []).map((c) =>
            <Column
              key={c.name}
              name={c.name}
              sortable={c.sortable !== false}
              title={<FormattedMessage id={c.title.id} defaultMessage={c.title.default} />}
              render={c.render}
              display={c.display} />
          )}
        </Table>
      </Loading>
    </div>
  )
}

export { WorkflowList }
