import React from 'react'
import ReactCrop from 'react-image-crop'
import CircularProgress from 'material-ui/CircularProgress'
import 'react-image-crop/dist/ReactCrop.css'
import './PdfPreview.css'

class PdfPreview extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isDrawing: false
    }
  }

  async drawPageImage(canvas) {
    const imgUrl = canvas.toDataURL('image/png', 1.0)
    this.setState({ isDrawing: false })
    const change = {
      crop: {
        x: 0,
        y: 0,
        // Percent! Not absolute values!
        width: 100, // viewport.width,
        height: 100 // viewport.height
      },
      imgUrl: imgUrl
    }
    this.props.onChange(change)
  }

  async drawPage(page, canvas, scale) {
    const viewport = page.getViewport({ scale })

    const context = canvas.getContext('2d')
    if (!context) return null

    canvas.height = viewport.height
    canvas.width = viewport.width

    const renderContext = {
      canvasContext: context,
      viewport: viewport
    }

    this.renderTask = page.render(renderContext)
    await this.renderTask.promise

    this.drawPageImage(canvas)
  }

  async draw(props) {
    const canvas = this.canvas
    const { pdf, pageNumber, scale = 2 } = props
    if (!canvas || !pdf || !pageNumber) return

    if (this.state.isDrawing) return
    this.setState({ isDrawing: true })

    try {
      const page = await pdf.getPage(pageNumber, scale)
      await this.drawPage(page, canvas, scale)
    } catch (err) {
      console.error('PdfPreview.draw.error', err)
    }
  }

  // eslint-disable-next-line
  componentWillReceiveProps (nextProps) {
    if (nextProps.pageNumber !== this.props.pageNumber ||
      nextProps.pdf !== this.props.pdf) {
      this.props.onChange({
        crop: null,
        imgUrl: null
      })
    }

    if (!nextProps.imgUrl) {
      this.draw(nextProps)
    }
  }

  render() {
    const isLoading = this.props.isLoading || this.state.isDrawing
    const { crop = null, imgUrl = null } = this.props.preview || {}
    const setCanvasRef = ref => {
      const shouldRedraw = ref && this.canvas !== ref
      this.canvas = ref
      if (shouldRedraw) this.draw(this.props)
    }

    const onCropChange = (crop) => {
      this.props.onChange({ crop, imgUrl })
    }

    return (
      <div className="pdf-preview">
        {isLoading && <div className="loading"><CircularProgress /></div>}
        {!isLoading && !imgUrl && <canvas ref={setCanvasRef} />}
        {!isLoading && imgUrl &&
          <ReactCrop src={imgUrl} crop={crop} onChange={onCropChange} />}
      </div>
    )
  }
}

export { PdfPreview }
