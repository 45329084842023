import React from 'react'
import { AttachmentGridItem } from '../AttachmentGridItem'
import { DecisionTile } from './DecisionTile'

export const DecisionGridItem = props => {
  return <AttachmentGridItem {...props}>
    <DecisionTile
      {...props}
      item={{ ...props.item, choices: props.item.choices.filter(x => x.selected) }} />
  </AttachmentGridItem>
}
