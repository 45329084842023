import React from 'react'
import { ByList } from 'skylight-common'
import './ByRequired.css'

const OPTIONS = [
  { text: 'DISPLAY_REQUIRED', value: 'Only show required cards' }
]

const getOptions = () => Promise.resolve(OPTIONS)

const ByRequired = props => {
  return (
    <ByList
      className="by-required-filter"
      title="DATA_FIELD_REQUIRED"
      value={props.value}
      renderFilter={() => null}
      enableSorting={false}
      onChange={props.onChange}
      onRemove={props.onRemove}
      onGetItems={getOptions} />
  )
}

export { ByRequired }
