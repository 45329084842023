import React from 'react'
import { TrimmingTextField } from 'skylight-common'
import VisibleIcon from 'material-ui/svg-icons/image/remove-red-eye'
import HiddenIcon from '../Icons/eyeOff'
import './HiddenTextField.css'

export class HiddenTextField extends React.Component {
  state = { isVisible: false }

  toggleVisibility = e => {
    e.stopPropagation()
    this.setState(s => ({ isVisible: !s.isVisible }))
  }

  // Component uses css to hide text. Instead of using type='password'
  // to prevent Chrome password manager and autocomplete issues.
  render() {
    const { isVisible } = this.state
    const Icon = isVisible ? VisibleIcon : HiddenIcon

    return (
      <div className={`hidden-text-field ${isVisible ? '' : 'hidden'} ${this.props.className || ''}`}>
        <TrimmingTextField {...this.props} type="text" />
        {this.props.value && <Icon className="hidden-text-field-icon" onTouchTap={this.toggleVisibility} />}
      </div>
    )
  }
}
