import React from 'react'
import { FlatButton } from 'material-ui'
import { FormattedMessage } from 'react-intl'
import './index.css'

export const PopupPermissionsTab = props => {
  return (
    <div className="permissions-dialog-content">
      <div className="content-main first-tab">
        <div className="top-description">
          <img
            alt="Popup blocked"
            className="popup-blocked-icon-top"
            src={'/assets/img/popup-blocked.png'}/>
          <FormattedMessage
            id="ALLOW_POPUPS_DESCRIPTION"
            default="It looks like pop-ups are being blocked"/>
        </div>

        <ol className="allowing-popups-list">
          <li>
            <FormattedMessage
              id="ALLOW_POPUPS_CHAPTER_1"
              default="It looks like pop-ups are being blocked"/>
            <img
              alt="Popup blocked"
              className="popup-blocked-icon-middle"
              src={'/assets/img/popup-blocked.png'}/>
          </li>
          <li>
            <FormattedMessage
              id="ALLOW_POPUPS_CHAPTER_2"
              default="Click the link for the pop-up window you'd like to see."/>
          </li>
          <li>
            <FormattedMessage
              id="ALLOW_POPUPS_CHAPTER_3"
              default="To always see pop-ups for the site, select"/>
            <FormattedMessage
              id="ALLOW_POPUPS_CHAPTER_3_1"
              default="Always allow pop-ups from [site] > Done."/>
          </li>
        </ol>

        <div className="dialog-actions-buttons">
          <FlatButton
            label="BACK"
            disabled={true}
            onTouchTap={() => props.onSetPermissionDialogTab(2)}/>
          <FlatButton
            label="NEXT"
            primary={true}
            onTouchTap={() => props.onSetPermissionDialogTab(3)}/>
        </div>
      </div>
    </div>
  )
}
