import React from 'react'
import FlatButton from 'material-ui/FlatButton'
import { FormattedMessage } from 'react-intl'
import './FooterActionButtons.css'

const FooterActionButtons = (props) => {
  return (
    <div className={`footer-action-buttons ${props.className || ''}`}>
      <div className="footer-action-buttons-wrapper">
        <FlatButton
          className="cancel-action"
          label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
          disabled={props.isCancelDisabled}
          onTouchTap={props.onCancel} />
        <FlatButton
          label={<FormattedMessage id="SAVE" defaultMessage="Save" />}
          primary
          disabled={props.isSubmitDisabled}
          onTouchTap={props.onSave} />
      </div>
    </div>
  )
}

export { FooterActionButtons }
