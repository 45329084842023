import React from 'react'
import {
  ByUser,
  ByDateRange
} from 'skylight-common'
import { FilterToolbarNextGen } from '../../../../Common/NextGen/FilterToolbarNextGen'
import { ByStatus } from './ByStatus'

import './ApplicationFilter.css'

const getFilters = (filterProps = {}) => [
  { text: 'APPLICATION_STATUS', value: 'status', comp: ByStatus },
  { text: 'DATE_CREATED', value: 'createdAt', props: { title: 'DATE_CREATED' }, comp: ByDateRange },
  { text: 'OWNER', value: 'createdBy', props: { title: 'OWNER', filterProps }, comp: ByUser }
]

const ApplicationFilter = props => {
  const filterProps = {
    urlPath: '/ng/users',
    onSelectItems: props.onUserSelectItems,
    role: props.authUser.role
  }

  const filters = getFilters(filterProps)

  return <FilterToolbarNextGen
    className="next-gen-application-filter"
    filters={filters}
    query={props.query}
    layout={props.layout}
    onFilterUpdated={props.onApplicationFilterUpdated}
    onFiltersUpdated={props.onApplicationFiltersUpdated}
    onFilterRemoved={props.onApplicationFilterRemoved} />
}

export { ApplicationFilter }
