import skylight from 'skylight-js-sdk'
import get from 'lodash/get'
import { ListQueryBuilder } from './listQueryBuilder'

const isItemFiltered = (item, filters) => {
  return (filters || []).find(filter => !filter(item))
}

const getQueryFilters = query => {
  const filters = {}

  const { eventTypes } = query.filter
  if (eventTypes && eventTypes.length) {
    filters.eventType = eventTypes.join(',')
  }

  return filters
}

export const getEvents = async(applicationId, sessionId, query) => {
  const { eventId, devices, createdBy, createdAt } = query.filter

  const byId = event => !eventId || (event.eventId.toLowerCase().includes(eventId.trim().toLowerCase()))
  const byDevice = event => {
    if (!Array.isArray(devices) || !devices.length) {
      return true
    }

    const { formattedName } = get(event, 'device.device', {})
    return devices.includes(formattedName)
  }

  const filters = [byId, byDevice]

  const eventQuery = new ListQueryBuilder({
    limit: query.limit,
    sort: `${query.sortAsc ? '' : '-'}${query.sortBy}`,
    expandDevices: true,
    ...getQueryFilters(query)
  })
    .byCreatedAt(createdAt)
    .byCreatedBy(createdBy)
    .buildQuery()

  if (query.nextCursor) {
    eventQuery.nextCursor = query.nextCursor
  }

  const events = await skylight.we20.event.list(applicationId, sessionId, eventQuery)
  const eventMediaInfoList = await getEventMediaInfoList(applicationId, sessionId, events.data)
  const deviceInfoList = await getEventDeviceInfoList(events.data)

  const eventsData = events.data.map(event => {
    const isMediaEvent = event.media && event.media.mediaId

    const mediaInfo = eventMediaInfoList.find(info => info && info.mediaId === get(event, 'media.mediaId'))
    const deviceInfo = deviceInfoList.find(info => info && info.deviceId === event.deviceId)

    return {
      ...event,
      mediaItemInfo: isMediaEvent ? mediaInfo : null,
      device: deviceInfo && deviceInfo.device
    }
  })

  return {
    data: eventsData.reduce((acc, x) => {
      if (x.eventId) {
        const item = {
          ...x,
          id: x.eventId
        }

        if (!isItemFiltered(item, filters)) {
          acc.push(item)
        }
      }

      return acc
    }, []),
    page: events.page
  }
}

export const getEventDeviceInfoList = async events => {
  const map = new Map()
  for (const event of events.filter(e => e.deviceId && e.device)) {
    if (!map.has(event.deviceId)) {
      map.set(event.deviceId, {
        deviceId: event.deviceId,
        device: event.device
      })
    }
  }

  const devices = events.filter(event => event.deviceId).map(event => event.deviceId)
  const uniqDevices = [...new Set(devices)]
  return Promise.all(uniqDevices.map(async deviceId => {
    let deviceInfo = null
    try {
      deviceInfo = map.has(deviceId) ? map.get(deviceId) : await skylight.we20.device.getById(deviceId)
      deviceInfo = withFormattedDeviceName(deviceInfo)
    } catch (e) {
      console.error(e)
    }

    return deviceInfo
  }))
}

export const getAllSessionEvents = async(applicationId, sessionId) => {
  const query = {
    limit: 200,
    expandDevices: true
  }

  const sessionEvents = []

  do {
    const { data, page } = await skylight.we20.event.list(applicationId, sessionId, query)
    sessionEvents.push(...data)
    query.nextCursor = page.nextCursor
  } while (query.nextCursor)

  return sessionEvents
}

const withFormattedDeviceName = event => {
  const { name, version } = event.device.device

  let formattedName = ''
  if (version.toLowerCase().startsWith(name.toLowerCase())) {
    formattedName = `${version[0].toUpperCase()}${version.slice(1)}`
  } else {
    formattedName = `${name[0].toUpperCase()}${name.slice(1)} ${version}`
  }

  event.device.device.formattedName = formattedName

  return event
}

const getEventMediaInfoList = async(applicationId, sessionId, events) => {
  const eventMediaIds = events
    .filter(event => event.media && event.media.mediaId)
    .map(event => event.media.mediaId)
  const uniqEventMediaIds = [...(new Set(eventMediaIds))]
  return Promise.all(uniqEventMediaIds.map(async mediaId => {
    return getEventMediaInfo(applicationId, sessionId, mediaId)
  })).then(x => x.filter(eventMediaInfo => eventMediaInfo))
}

const getEventMediaInfo = async(applicationId, sessionId, mediaId) => {
  let mediaInfo = null
  try {
    mediaInfo = await skylight.we20.session.media.getById(applicationId, sessionId, mediaId)
  } catch (e) {
    return null
  }

  return {
    ...mediaInfo,
    id: mediaInfo.mediaId,
    name: mediaInfo.filename,
    type: mediaInfo.contentType
  }
}
