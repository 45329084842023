import callSdk from 'skylight-call-sdk'
import { actions, CONFIG } from 'skylight-common'
import skylight from 'skylight-js-sdk'

import * as ActionTypes from '../actions/types'
import { signout } from '../actions/auth'
import { getIdPToken } from '../actions/login'

import { redirectToApp, startAndroid } from '../actions/app'

import { clearIsSamlAuth } from '../svc/login'

import * as dialogTypes from '../App/Home/Layout/Dialogs/dialogTypes'

const getRealm = (hostname, webUrl) => {
  const baseHost = webUrl
    .replace('http://', '')
    .replace('https://', '')
    .replace('/', '')

  if (hostname.indexOf(baseHost) === -1) {
    return ''
  }

  return hostname
    .replace(baseHost, '')
    .replace('.', '')
}

const ensureIdP = (next) => {
  if (window.location.pathname.indexOf('/login/sso') === 0) {
    const url = new URLSearchParams(window.location.search)
    getIdPToken(url.get('realm'), url.get('code'))(next)
  }
}

// refreshAuthToken could return in 401 which in turn will result
// in redirect. On a *first glance* the simplest solution seems
// to be simply checking if the user is already on /login/*
// and prevent redirect if true.

// *Updated: initSocket could also return 401, however it also retries to
// reconnect. After navigating to /login/{realmName} retry could easily occur,
// which will result in redirect to /login.
// Similar could be reproduced with refreshAuthToken if page is opened from
// /login/{realmName} immediately.
// const handleLogout = params => {
//   const isCanceled = window.location.pathname.startsWith(params.loginUrl)

//   return Promise.resolve(isCanceled)
// }

window.skylight = skylight

const auth = store => next => async action => {
  const state = store.getState()
  const res = next(action)
  let realm = null

  switch (action.type) {
    case ActionTypes.APP_INIT: {
      skylight.events.on('afterHttpRequest', e => {
        // Intercept 401 error and save realm for redirecting user directly on LoginForm page
        // Details in https://upskill.atlassian.net/browse/SKY-6565
        if (e.error && e.error.status === 401) {
          realm = skylight.auth.authData
            ? skylight.auth.getRealmFromToken(skylight.auth.authData.access_token)
            : null
        }
      })
      skylight.events.on('signout', async() => {
        clearIsSamlAuth()
        const state = store.getState()

        // Check if realm is empty and try to get it from a store
        const loginRealm = (state.login.realm && state.login.realm.realm) || realm
        realm = null

        if (window.location.pathname.startsWith('/login')) return
        // Last bastion - shouldn't happen more often than user session (24h+) expiration allows it.
        if (store.getState().call.connection.state === callSdk.constants.CALL_STATES.INCALL) {
          skylight.logger.error('User must be signout, but still on the call.')
          return
        }

        const qSearchParams = new URLSearchParams(window.location.search)
        if (!window.location.pathname.startsWith('/logout')) qSearchParams.append('redirectUrl', window.location.pathname)
        const loginRoute = loginRealm ? `/login/${loginRealm}` : '/login'
        window.top.location.href = `${loginRoute}?${qSearchParams.toString()}` // iframe support.
      })

      if (CONFIG.webUrl) {
        next({ type: ActionTypes.SIGNIN_REALM_CHANGED, value: getRealm(window.location.hostname, CONFIG.webUrl) })
      }

      let hasAuth = false
      const auth = await skylight.auth.tryLoadAuthDataFromStorage()
      if (auth) {
        const user = await skylight.auth.tryLoadUserFromStorage()
        if (user) {
          hasAuth = true
          const scopes = user.scopes
          next({ type: ActionTypes.AUTH_SIGNED_IN, user, auth, scopes, staySigned: true, isLocal: true })
        }
      }

      if (!hasAuth) {
        next({ type: ActionTypes.AUTH_SIGNIN_MISSING })
      }

      ensureIdP(next)

      break
    }
    case ActionTypes.AUTH_SIGNED_IN: {
      next(redirectToApp(window.location.search))
      break
    }
    case ActionTypes.SSO_SET_TOKEN:
      if (state.auth.clientAppLogin) {
        next(startAndroid(action.token))
      }

      break
    case ActionTypes.AUTH_REFRESH_TOKEN_ERROR:
      // TODO: check if network error and retry.
      next(signout())
      break
    case ActionTypes.PROFILE_UPDATED:
      if (skylight.auth.user) { skylight.auth.user = { ...action.user, realm: skylight.auth.user.realm, scopes: skylight.auth.user.scopes } }
      break
    case ActionTypes.USER_TEMP_PASSWORD_RESET_SUCCESS:
      next({
        type: actions.types.DIALOG_SHOW,
        dialogType: dialogTypes.TEMPORARY_PASSWORD,
        params: { password: action.password }
      })
      break
    case ActionTypes.USER_TEMP_PASSWORD_EXPIRED:
      next({
        type: actions.types.DIALOG_SHOW,
        dialogType: dialogTypes.CHANGE_PASSWORD
      })
      break
    default:
  }
  return res
}

export default auth
