import React from 'react'
import { Form } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import { FlatButton } from 'material-ui'
import CircularProgress from 'material-ui/CircularProgress'
import SolutionPicker from './SolutionPicker'
import { SOLUTIONS } from '../../../../../../lib/application/solution'

import './BlankAppTab.css'
import { OutlinedTextField } from '../../../../../Common/Form/OutlinedTextField'
import { upperFirst } from 'lodash'

export class BlankAppTab extends React.Component {
  state = {
    name: '',
    description: '',
    invalidName: false,
    selectedSolutions: Object.values(SOLUTIONS)
  }

  componentDidMount() {
    const urlSearch = new URLSearchParams(location.search)
    if (urlSearch.has('solutions')) {
      const solutions = urlSearch.get('solutions')
      // normalize solution e.g. xmake -> xMake
      const selectedSolutions = solutions.split(',').map((x) => x[0] + upperFirst(x.slice(1)))
      const validSolutions = Object.values(SOLUTIONS).filter(solution => selectedSolutions.includes(solution))
      this.setState({ selectedSolutions: validSolutions })
    }
  }

  onSolutionsChange = selectedSolutions => {
    this.setState({ selectedSolutions })
  }

  onFormSubmit = e => {
    e.preventDefault()
    e.stopPropagation()

    if (!this.state.name) {
      this.setState({ invalidName: true })
      return
    }

    this.setState({ invalidName: false })
    this.props.onNewBlankApplication(this.state.name, this.state.description, this.state.selectedSolutions)
  }

  onFieldChange = (e, v) => {
    this.setState({ [e.target.name]: v })
  }

  renderLoader = () => {
    const { isLoading } = this.props.newApplication
    return isLoading ? <div className="loading-container"><CircularProgress size={55} thickness={6} /></div> : null
  }

  render() {
    const { isLoading } = this.props.newApplication
    return (
      <div className="blank-app-tab-container">
        {this.renderLoader()}
        <Form onSubmit={this.onFormSubmit}>
          <div className="blank-app-properties">
            <FormattedMessage
              id="SETUP_APP_PROPERTIES"
              defaultMessage="App properties" />
          </div>
          <div className="blank-app-info">
            <FormattedMessage
              id="SETUP_APP_ADD_INFO"
              defaultMessage="Add a name and description for your project and then it’ll be ready to go." />
          </div>
          <div className="blank-app-form-fields">
            <OutlinedTextField
              className="text-field name-field"
              name="name"
              value={this.state.name}
              error={this.state.invalidName ? <FormattedMessage id="SETUP_BLANK_APP_NAME_IS_REQUIRED" defaultMessage="Name parameter is required" /> : null}
              required
              floatingLabelText={
                <FormattedMessage id="SETUP_BLANK_APP_NAME_LABEL" defaultMessage="Name" />
              }
              hintText={<FormattedMessage id="SETUP_BLANK_APP_NAME_HINT" defaultMessage="Choose a name for your application" />}
              onChange={this.onFieldChange} />
            <OutlinedTextField
              className="text-field description-field"
              name="description"
              value={this.state.description}
              floatingLabelText={
                <FormattedMessage id="SETUP_BLANK_APP_DESCRIPTION_LABEL" defaultMessage="Description" />
              }
              hintText={<FormattedMessage id="SETUP_BLANK_APP_DESCRIPTION_HINT" defaultMessage="Give your application a description" />}
              onChange={this.onFieldChange} />
            <SolutionPicker selectedSolutions={this.state.selectedSolutions} onSolutionsChange={this.onSolutionsChange} />
            <div className="solutions-hint">
              <FormattedMessage id="SOLUTIONS_HINT" defaultMessage="Applications are categorized by solution type in the Frontline Command Center." />
            </div>
          </div>
          <div className="dialog-actions-buttons">
            <FlatButton
              disabled={isLoading}
              label={<FormattedMessage id="SETUP_BLANK_APP_CREATE" defaultMessage="Create app" />}
              primary
              type="submit" />
          </div>
        </Form>
      </div>
    )
  }
}
