import React from 'react'
import MenuItem from 'material-ui/MenuItem'
import { FormSection } from '../../../../Common/Form/FormSection'
import { OutlinedTextField } from '../../../../Common/Form/OutlinedTextField'
import { OutlinedSelectField } from '../../../../Common/Form/OutlinedSelectField'
import { FormattedMessage } from 'react-intl'
import './AccountLocation.css'

const AccountLocation = (props) => {
  return (
    <FormSection
      className="account-location"
      title={<FormattedMessage id="ACCOUNT_LOCATION" defaultMessage="Location" />}>
      <div className="form-fields">
        <div className="form-fields-row">
          <OutlinedTextField
            className="form-field"
            name="location"
            value={props.form.location || ''}
            floatingLabelText={
              <FormattedMessage id="ACCOUNT_LOCATION" defaultMessage="Location" />
            }
            onChange={props.onChange} />
        </div>
        <div className="form-fields-row">
          <OutlinedTextField
            className="form-field"
            name="street"
            value={props.form.street || ''}
            floatingLabelText={
              <FormattedMessage id="LOCATION_STREET" defaultMessage="Street" />
            }
            onChange={props.onChange} />
        </div>
        <div className="form-fields-row">
          <OutlinedTextField
            className="form-field"
            name="city"
            floatingLabelText={
              <FormattedMessage id="LOCATION_CITY" defaultMessage="City" />
            }
            onChange={props.onChange}
            value={props.form.city || ''} />
          <OutlinedSelectField
            className="form-field"
            name="state"
            disabled={!props.form.city}
            floatingLabelText={
              <FormattedMessage id="LOCATION_STATE" defaultMessage="State" />
            }
            onChange={(e, k, v) => props.onChange(e, v, 'state')}
            value={props.form.state || ''}>
            <MenuItem value="VA" primaryText="VA" />
          </OutlinedSelectField>
          <OutlinedTextField
            className="form-field"
            name="zipcode"
            floatingLabelText={
              <FormattedMessage id="LOCATION_ZIP" defaultMessage="Zip Code" />
            }
            type="number"
            onChange={(e, v) => props.onChange(e, +v)}
            value={props.form.zipcode || ''} />
        </div>
      </div>
    </FormSection>
  )
}

export { AccountLocation }
