import { subscribeToApplicationEvents, unsubscribeFromApplicationEvents } from '../svc/mqtt'
import { showError } from './error'
import * as ActionTypes from './types'
import skylight from 'skylight-js-sdk'
import { EXPORT_STATUSES } from '../lib/application/exportImport'
import snackbarActions from './snackbar'
import { downloadRawMediaItem } from 'skylight-common'

const exportApplication = (applicationId, version) => async(dispatch) => {
  try {
    await subscribeToApplicationEvents(applicationId)
  } catch (e) {
    dispatch(showError('APPLICATION_EXPORT_ERROR', { className: 'snackbar-common-error' }))
    return
  }

  dispatch({ type: ActionTypes.APPLICATION_EXPORT_STARTED })
  try {
    const exportMetadata = await skylight.we20.export.create(applicationId, version)

    // if application is published and has already been exported then
    // export won't start again and archive should be available for download
    if (exportMetadata.status === EXPORT_STATUSES.COMPLETED) {
      dispatch(applicationExportCompleted(exportMetadata))
      return
    }
    dispatch(snackbarActions.showExportingApplicationSnackbar())
  } catch (e) {
    console.error('an error occurred while exporting an app')
    dispatch(snackbarActions.hideLoadingSnackbar())
    dispatch({ type: ActionTypes.APPLICATION_EXPORT_FINISHED })
  }
}

const downloadApplicationExport = (applicationId, exportId, version) => async(dispatch, getState) => {
  const { name, draftVersion, publishedVersion } = getState().application.selectedApplication
  version = version || draftVersion || publishedVersion

  const exportModel = {
    applicationId,
    exportId,
    name: `skylight-export-${name}-${version > 9 ? version : `0${version}`}.zip`
  }

  await downloadRawMediaItem(exportModel)
}

const applicationExportCompleted = exportPayload => async(dispatch, getState) => {
  const { stage: versionStage } = await skylight.we20.version.getById(exportPayload.applicationId, exportPayload.version)
  dispatch({ type: ActionTypes.SELECTED_APP_EXPORT_UPDATE, export: { ...exportPayload, versionStage } })

  await dispatch(downloadApplicationExport(exportPayload.applicationId, exportPayload.exportId, exportPayload.version))
  dispatch(snackbarActions.hideLoadingSnackbar())
  dispatch(snackbarActions.showDownloadReadySnackbar())
  await unsubscribeFromApplicationEvents(exportPayload.applicationId)
  dispatch({ type: ActionTypes.APPLICATION_EXPORT_FINISHED })
}

export default {
  onExportApplication: exportApplication,
  onDownloadApplicationExport: downloadApplicationExport,
  onApplicationExportCompleted: applicationExportCompleted
}
