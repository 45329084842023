import React from 'react'
import { FormattedMessage } from 'react-intl'
import Menu from 'material-ui/Menu'
import SettingsIcon from 'material-ui/svg-icons/image/remove-red-eye'
import { Tooltip } from 'skylight-common'
import FloatingActionButton from 'material-ui/FloatingActionButton'

import './DetailsMenu.css'

class DetailsMenu extends React.Component {
  state = {
    open: false
  }

  mounted = null

  onClose = () => {
    this.setState({ open: false })
  }

  onTouchTap = (e) => {
    e.preventDefault()
    if (this.state.open) {
      return
    }

    this.setState({ open: true })
    document.addEventListener('click', this.handleClickOutside)
  }

  renderTitle = () => {
    return <FormattedMessage id="ACTIVITY_CALL_VIEW" defaultMessage="View" />
  }

  getClassName = () => {
    return [
      'details-menu',
      this.state.open ? 'open' : '',
      this.props.className || ''
    ].join(' ')
  }

  componentDidMount() {
    this.mounted = true
  }

  componentWillUnmount() {
    this.mounted = false
    document.removeEventListener('click', this.handleClickOutside)
  }

  handleClickOutside = (e) => {
    const isOutsideClick = !this.menuContainer.contains(e.target)
    if (isOutsideClick && this.mounted) {
      this.setState({ open: false })
      document.removeEventListener('click', this.handleClickOutside)
    }
  }

  renderMenu = () => {
    return <div className="details-menu-items" >
      <Menu
        className="multiple-items-details-menu"
        onItemTouchTap={this.onClose}>
        {this.props.children}
      </Menu>
    </div>
  }

  render() {
    return <div
      className={this.getClassName()}
      ref={container => { this.menuContainer = container }} >
      {this.state.open && this.renderMenu()}
      <Tooltip
        className="tooltip"
        tooltip={this.renderTitle()}>
        <FloatingActionButton className="icon-menu" onTouchTap={this.onTouchTap}>
            <SettingsIcon />
            {this.renderTitle()}
        </FloatingActionButton>
      </Tooltip>
    </div>
  }
}

export { DetailsMenu }
