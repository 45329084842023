import React from 'react'
import jwtDecode from 'jwt-decode'
import Divider from 'material-ui/Divider'
import PasswordKey from '../Common/Icons/PasswordKey'
import { FormattedMessage } from 'react-intl'
import OutlinedPasswordField from '../Common/Form/OutlinedPasswordField/OutlinedPasswordField'
import LoginPageButton from '../Common/LargeRaisedButton/LargeRaisedButton'
import CaptchaBadge from '../Common/Captcha/CaptchaBadge'
import { getCaptchaToken } from '../Common/Captcha/captcha'
import { connect } from 'react-redux'
import { requestPasswordReset, submitPasswordReset, isTokenInvalidUpdate } from '../../actions/profile'
import classNames from 'classnames'
import appActions from '../../actions/app'
import EmailIcon from 'material-ui/svg-icons/communication/email'
import loginActions from '../../actions/login'
import { first } from 'lodash'
import GenericPasswordRequirements from '../Common/GenericPasswordRequirements/GenericPasswordRequirements'
import { GENERIC_PASSWORD_REQUIREMENTS, MAX_PASSWORD_LENGTH } from '../../svc/login'

import './PasswordReset.css'

class PasswordReset extends React.Component {
  state = {
    username: null,
    password: '',
    passwordConfirmation: '',
    passwordError: null,
    genericErrors: [],
    maxLimitError: null,
    passwordConfirmationError: null,
    isTokenExpired: false
  }

  getTokenFromUrl = () => new URLSearchParams(this.props.location.search).get('token')

  getToken = () => {
    const token = this.getTokenFromUrl()
    try {
      return jwtDecode(token)
    } catch (e) {
      console.error('invalid token provided')
      return null
    }
  }

  componentDidMount() {
    const token = this.getToken()
    if (!token) {
      this.props.redirect('/login')
      return
    }

    // multiplication by 1000 is required because Date.now() returns UNIX time in millis
    const isTokenExpired = Date.now() >= token.exp * 1000
    this.setState({ isTokenExpired, username: token.username })
  }

  onPasswordChange = password => {
    const { username } = this.getToken()
    const genericErrors = GENERIC_PASSWORD_REQUIREMENTS
      .filter(x => x.validate({ password, username }))
      .map(x => x.name)

    let maxLimitError = null
    if (password.length > MAX_PASSWORD_LENGTH) {
      maxLimitError = { id: 'PWD_DOES_NOT_MEET_SECURITY_REQ', defaultMessage: 'Password does not meet security requirements.' }
    }

    this.setState({ password, genericErrors, maxLimitError })
  }

  onPasswordConfirmationChange = passwordConfirmation => {
    this.setState({ passwordConfirmation })
  }

  onPasswordReset = async e => {
    e.preventDefault()

    const { password, passwordConfirmation, genericErrors } = this.state
    if (!password && !passwordConfirmation) {
      const error = { id: 'REQUIRED_PASSWORD', defaultMessage: 'A password is required.' }
      this.setState({ passwordError: error, passwordConfirmationError: error })
      return
    }

    if (!password || !passwordConfirmation || password !== passwordConfirmation) {
      const error = { id: 'PASSWORD_DOES_NOT_MATCH', defaultMessage: 'Password does not match.' }
      this.setState({ passwordError: null, passwordConfirmationError: error })
      return
    }

    if (genericErrors.length) {
      return
    }

    this.setState({ passwordError: null, passwordConfirmationError: null })

    const captchaToken = await getCaptchaToken()
    this.props.onResetPassword(password, this.getTokenFromUrl(), captchaToken)
  }

  onSendNewRecoveryLink = () => {
    const { username, realm } = this.getToken()

    this.props.onResetIsTokenInvalid()
    this.props.onRecoverPassword(username, realm)
  }

  renderContent = () => {
    const { isTokenInvalid } = this.props.pwdReset
    if (this.state.isTokenExpired || isTokenInvalid) {
      return (
        <div>
          <FormattedMessage id="PWD_RESET_LINK_EXPIRED" defaultMessage="The password reset link is no longer valid or has expired.">
            {content => <div className="link-expired">{content}</div>}
          </FormattedMessage>
          <LoginPageButton
            onTouchTap={this.onSendNewRecoveryLink}
            className="send-new-link-button"
            primary
            icon={<EmailIcon />}
            label={<FormattedMessage id="SEND_NEW_RECOVERY_LINK" defaultMessage="Send new recovery link" />} />
          <FormattedMessage id="NEED_ADDITIONAL_HELP" defaultMessage="Need additional help?">
            {content => <span className="need-additional-help">{content}</span>}
          </FormattedMessage>
          <FormattedMessage id="CONTACT_YOUR_ADMINISTRATOR" defaultMessage="Contact your administrator.">
            {content => <span className="contact-your-admin">{content}</span>}
          </FormattedMessage>
        </div>
      )
    }

    const { pwdValidationError } = this.props.pwdReset

    const passwordRequirements = !pwdValidationError && !this.state.maxLimitError ? <GenericPasswordRequirements errors={this.state.genericErrors} /> : null

    return (
      <form onSubmit={this.onPasswordReset}>
        <OutlinedPasswordField
          required
          onFocus={() => this.setState({ passwordError: null, passwordConfirmationError: null })}
          error={pwdValidationError || this.state.passwordError || this.state.maxLimitError || first(this.state.genericErrors)}
          onChange={(_, v) => this.onPasswordChange(v)}
          value={this.state.password}
          className={classNames('password-reset-field password-field', { 'generic-requirements-error': pwdValidationError || this.state.maxLimitError })}
          name="password"
          type="password"
          floatingLabelText={<FormattedMessage id="NEWPWD" defaultMessage="New password" />} />
        {passwordRequirements}
        <OutlinedPasswordField
          required
          onFocus={() => this.setState({ passwordError: null, passwordConfirmationError: null })}
          error={this.state.passwordConfirmationError}
          onChange={(_, v) => this.onPasswordConfirmationChange(v)}
          value={this.state.passwordConfirmation}
          className="password-reset-field password-confirmation-field"
          name="password-confirmation"
          type="password"
          floatingLabelText={<FormattedMessage id="NEWPWDRE" defaultMessage="Confirm password" />} />
        <LoginPageButton
          className="change-password-btn"
          primary
          type="submit"
          icon={<PasswordKey />}
          label={<FormattedMessage id="CHANGEPASSWORD" defaultMessage="Change password" />} />
        <CaptchaBadge />
      </form>
    )
  }

  render() {
    return (
      <div className="password-reset-container">
        <div className="password-reset-content">
          <img className="skylight-logo" src="/assets/img/skylight-logo-blue.png" alt="logo" />
          <div className="password-reset-for">
            <FormattedMessage id="RESET_PASSWORD_FOR" defaultMessage="Reset password for: {username}" values={{ username: this.state.username }} />
          </div>
          <Divider className="divider" />
          {this.renderContent()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  pwdReset: state.login.pwdReset
})

const mapDispatchToProps = dispatch => ({
  onRecoverPassword: (username, realm) => {
    dispatch(loginActions.onRealmChange(realm))
    dispatch(requestPasswordReset(username))
  },
  onResetPassword: (newPassword, token, captchaToken) => {
    dispatch(submitPasswordReset(newPassword, token, captchaToken))
  },
  redirect: path => {
    dispatch(appActions.redirect(path))
  },
  onResetIsTokenInvalid: () => {
    dispatch(isTokenInvalidUpdate(false))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset)
