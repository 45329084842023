import React from 'react'
import { AssignmentList } from '../../../../containers/assignment'
import { ASSIGNMENT_FILTER_TYPES } from '../../../../lib/assignment'
import { TabBar } from 'skylight-common'

import './AssignmentTabs.css'

class AssignmentTabs extends React.Component {
  teamHeading = { id: 'ASSIGNMENT_TEAM', default: 'My team\'s assignments' }

  myColumns = []
  teamColumns = []

  onChange = (v) => {
    if (v === ASSIGNMENT_FILTER_TYPES.TEAM) {
      this.props.redirect('/assignments')
      return
    }

    this.props.redirect(`/assignments/${v}`)
  }

  componentDidMount() {
    const { columns: c } = this.props
    this.myColumns = c.filter(c => !['assignedTo', 'assignedOn'].includes(c.name))
    this.teamColumns = c.filter(c => !['assignedBy', 'assignedOn'].includes(c.name))
  }

  getAssignmentList = () => {
    const { type, heading } = this.props
    const listHeading = type === ASSIGNMENT_FILTER_TYPES.MY ? heading : this.teamHeading
    const listColumns = type === ASSIGNMENT_FILTER_TYPES.MY ? this.myColumns : this.teamColumns

    return <AssignmentList type={type} heading={listHeading} columns={listColumns} />
  }

  render() {
    const { type = ASSIGNMENT_FILTER_TYPES.TEAM } = this.props
    const teamTabValue = type === ASSIGNMENT_FILTER_TYPES.PENDING ? type : ASSIGNMENT_FILTER_TYPES.TEAM

    const TABS = [
      {
        label: { id: 'TAB_TEAM', defaultMessage: 'My team' },
        value: teamTabValue,
        renderChildren: (type) => (type === 'team' || type === 'pending') && this.getAssignmentList()
      },
      {
        label: { id: 'TAB_ASSIGNMENTS', defaultMessage: 'My assignments' },
        value: 'my',
        renderChildren: (type) => type === 'my' && this.getAssignmentList()
      }
    ]

    return <TabBar tabs={TABS} onChange={this.onChange} type={type} />
  }
}

export { AssignmentTabs }
