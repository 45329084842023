import React from 'react'
import { FormattedMessage } from 'react-intl'
import MenuItem from 'material-ui/MenuItem'
import { DeleteIcon, DetailsMenu } from 'skylight-common'
import './UserActionMenu.css'

const UserActionMenu = (props) => {
  const { item, mode } = props.user
  if (mode === 'new') {
    return null
  }

  const onDelete = () => {
    props.onConfirm(
      () => props.onUserDeleteItems([item.id]),
      {
        title: 'USERS_REMOVE_DIALOG_TITLE',
        message: 'USERS_REMOVE_DIALOG_DESCRIPTION',
        action: 'ACTION_DELETE_USER'
      }
    )
  }

  return (
    <DetailsMenu className="user-action-menu">
      <MenuItem
        primaryText={<FormattedMessage id="DELETE_USER" defaultMessage="Delete User" />}
        leftIcon={<DeleteIcon />}
        onTouchTap={onDelete} />
    </DetailsMenu>
  )
}

export { UserActionMenu }
