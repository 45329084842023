import React from 'react'
import DropDownMenu from 'material-ui/DropDownMenu'
import MenuItem from 'material-ui/MenuItem'
import { FormattedMessage } from 'react-intl'
import './CallGroupDetailsHeading.css'

const CallGroupDetailsHeading = ({ group, groups, ...props }) => {
  const renderDropDownMenuItem = (item, key) => {
    return <MenuItem key={key} value={item.id} primaryText={item.name} />
  }

  return (
    <div className="call-group-details-heading">
      <div className="heading-title-wrapper">
        <FormattedMessage id="TEAM_NAME" defaultMessage="Team name"/>
      </div>

      <div className="heading-content">
        <DropDownMenu
          className="heading-content-dd-menu"
          value={group.id}
          onChange={(e, i, id) => {
            props.onLoadGroupMembers({ id })
            props.onGroupSelectItems([id], groups.items)
          }}>
          { groups.items.map((item, key) => renderDropDownMenuItem(item, key)) }
        </DropDownMenu>
      </div>
    </div>
  )
}

export { CallGroupDetailsHeading }
