import React from 'react'
import { TextFilter } from 'skylight-common'
import { CallFilter } from '../../../../../containers/activity'

const VALUE = 'id'

const CallTextFilter = props => {
  const value = props.query.filter && props.query.filter[VALUE] ? props.query.filter[VALUE] : ''
  const onChange = (v) => {
    const value = v.trim()
    const filter = { ...props.query.filter, [VALUE]: value }
    props.onCallFilterUpdated(filter)
  }

  return <TextFilter
    value={value}
    className="activity-call-text-filter"
    title="CALL_FILTER_PLACEHOLDER"
    iconFilter={<CallFilter />}
    onChange={onChange} />
}

export { CallTextFilter }
