import React from 'react'
import { MediaList } from '../../../containers/media'

const Media = (props) => {
  return <div className="media">
    <MediaList />
  </div>
}

export { Media }
