import { connect } from 'react-redux'

import callUserListActions from '../actions/callUser'
import callActions from '../actions/call'
import dialogActions from '../actions/dialog'
import presenceActions from '../actions/presence'
import contactActions from '../actions/contact'

import { CallUserList as CallUserListComp, CallUserActionButtons as CallUserActionButtonsComp } from '../App/Home/Calls'
import { CallUserFilter, CallUserTextFilter } from '../App/Home/Calls/CallTabs/CallUserFilter'

import { CallUserDetails as CallUserDetailsComp } from '../App/Home/Calls/CallTabs/CallUserDetails'

const callDetails = (state, props) => ({
  user: state.callUser.item,
  scopes: state.auth.scopes,
  authUser: state.auth.user,
  presenceUsers: state.presence.users
})

const callUserActions = {
  ...callUserListActions,
  ...callActions,
  ...dialogActions,
  ...presenceActions,
  onUserSelectItems: contactActions.onUserSelectItems
}

const callList = (state, props) => ({
  list: state.callUser.list,
  authUser: state.auth.user,
  scopes: state.auth.scopes,
  socket: state.presence.socket
})

const callFilter = (state) => ({
  query: state.callUser.list.query,
  layout: state.layout,
  list: state.callUser.list,
  authUser: state.auth.user
})

const CallList = connect(callList, callUserActions)(CallUserListComp)
const CallUserDetails = connect(callDetails, callUserActions)(CallUserDetailsComp)
const CallUserActionButtons = connect(callDetails, callUserActions)(CallUserActionButtonsComp)
const CallFilter = connect(callFilter, callUserActions)(CallUserFilter)
const CallTextFilter = connect(callFilter, callUserActions)(CallUserTextFilter)

export {
  CallList,
  CallUserDetails,
  CallUserActionButtons,
  CallFilter,
  CallTextFilter
}
