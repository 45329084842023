import React from 'react'
import {
  Table, Column, FormattedDateTime, GridHeader,
  ExportIcon, MaterialIcon, ActionButton
} from 'skylight-common'
import { UserDetailsLink } from '../../../../containers/common'
import { FormattedMessage } from 'react-intl'
import { EVENT_TYPES, exportActivities } from '../../../../svc/activities'
import './ActivityList.css'

class ActivityList extends React.Component {
  onExport = () => {
    const list = this.props.list
    exportActivities(list.items, list.query.filter)
  }

  renderActivityIcon = (item) => {
    const eventTypeObj = EVENT_TYPES[item.eventType]
    return (eventTypeObj && eventTypeObj.icon) || <MaterialIcon icon="watch_later" />
  }

  getHeadingActions = () => {
    return [
      <ActionButton
        key="export-to-csv"
        icon={<ExportIcon />}
        show="always"
        className="table-heading-button skylight-icon"
        label={<FormattedMessage id="EXPORT_CSV" defaultMessage="Export to CSV" />}
        onTouchTap={this.onExport} />
    ]
  }

  render() {
    const list = this.props.list

    return <div className="activity-list">
      <Table
        tableHeading={(props) => <GridHeader {...props} actions={this.getHeadingActions()} />}
        showCheckboxes={false}
        showHover={true}
        query={list.query}
        items={list.items}
        total={list.total}
        isLoading={list.isLoading}
        done={list.done || !!list.error}
        selectedRows={list.selectedIds}
        name="activityList"
        emptyLabel={<FormattedMessage
          id="EMPTY_ACTIVITY_LIST"
          defaultMessage="There are no activity logs." />
        }
        onQuery={this.props.onActivityQuery}
        onRowsSelected={ids => this.props.onActivitySelectItems(ids, list.items)}>
        <Column
          className="activity-icon-column skylight-icon"
          render={this.renderActivityIcon} />
        <Column
          name="eventType"
          title={<FormattedMessage id="ACTIVITY_EVENT_NAME" defaultMessage="Event"/>}
          sortable={true}
          render={item => item.name} />
        <Column
          name="timestamp"
          sortable={true}
          title={<FormattedMessage id="CREATED_TIME" defaultMessage="Created"/>}
          render={item => <FormattedDateTime value={item.timestamp}/>} />
        <Column
          name="username"
          sortable={false}
          title={<FormattedMessage id="ACTIVITY_USERNAME" defaultMessage="Username"/>}
          render={item => <UserDetailsLink
            userId={item.createdBy}
            linkText={user => user.username} />} />
        <Column
          name="assignmentId"
          sortable={false}
          title={<FormattedMessage id="ACTIVITY_ASSIGNMENT_NAME" defaultMessage="Assignment"/>} />
        <Column
          name="userType"
          sortable={false}
          isVisible={false}
          title={<FormattedMessage id="ACTIVITY_USER_TYPE" defaultMessage="User type"/>} />
        <Column
          name="deviceId"
          sortable={false}
          isVisible={false}
          title={<FormattedMessage id="ACTIVITY_DEVICE_ID" defaultMessage="Device ID"/>} />
      </Table>
    </div>
  }
}

export { ActivityList }
