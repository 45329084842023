import React from 'react'
import { UnreadMessage } from './UnreadMessage'
import './ChatUnread.css'

export const ChatUnread = props => {
  let unreadMessages = props.call.chat.messages.filter(x => !x.isRead)
  unreadMessages = unreadMessages.slice(-3)
  const partner = props.call.connection.partner
  const onOpen = (message) => {
    props.readMessage(message.id)
    props.showMessage(true)
  }
  const onRead = (e, message) => {
    e.stopPropagation()
    props.readMessage(message.id)
  }

  return (
    <div className="chat-unread">
      {unreadMessages.map(x => <UnreadMessage
        key={x.id}
        message={x}
        partner={partner}
        onClick={() => onOpen(x)}
        onClose={(e) => onRead(e, x)} />
      )}
    </div>
  )
}
