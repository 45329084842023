import React from 'react'
import { SelectField, MenuItem } from 'material-ui'
import { FormattedMessage } from 'react-intl'
import './CaptureQuality.css'

const QUALITY_OPTIONS = ['max', 'high', 'medium', 'low']

// TODO: Reuse for both Image/Video capture quality. Once image is implemented on API.
const CaptureQuality = props => {
  const onChange = (e, k, v) => {
    const clientSettings = { ...props.item.clientSettings }
    clientSettings[props.name] = v
    props.onChange('clientSettings', clientSettings)
  }

  return (
    <div className="capture-quality">
      <div className="title">
        <FormattedMessage id="VIDEO_CAPTURE_QUALITY_TITLE" defaultMessage="Video capture quality" />
      </div>
      <div className="description"><FormattedMessage id="VIDEO_CAPTURE_QUALITY_DESCRIPTION" /></div>
      <SelectField
        className="capture-quality-dropdown dropdown-with-hover"
        selectedMenuItemStyle={{ color: '#00aaed' }}
        value={props.item && props.item.clientSettings ? props.item.clientSettings[props.name] : 'medium'}
        onChange={onChange}>
        {QUALITY_OPTIONS.map(x =>
          <MenuItem key={x} value={x} primaryText={<FormattedMessage id={x.toUpperCase()} />} />)}
      </SelectField>
    </div>
  )
}

export { CaptureQuality }
