import React from 'react'
import Checkbox from 'material-ui/Checkbox'
import classNames from 'classnames'

import './ApplicationPermissionSelector.css'

class ApplicationPermissionSelector extends React.Component {
  state = {
    isPermissionSelected: false
  }

  componentDidMount() {
    const { role, permission } = this.props
    const isPermissionSelected = (role.permissions || []).includes(permission)

    this.setState({ isPermissionSelected })
  }

  handleCheckboxChange = (e, isPermissionSelected) => {
    const { permission } = this.props

    this.props.onPermissionChange(permission, isPermissionSelected)
    this.setState({ isPermissionSelected })
  }

  render() {
    const { isPermissionSelected } = this.state

    const disabled = this.props.role.permissions.includes(this.props.parentPermission)
    return (
      <div className="application-permission-selector">
        <div className={classNames('application-permission-checkbox', { disabled })}>
          <Checkbox disabled={disabled} labelPosition="left" checked={isPermissionSelected} onCheck={this.handleCheckboxChange} />
        </div>
      </div>
    )
  }
}

export { ApplicationPermissionSelector }
