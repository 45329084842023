import React from 'react'

import { MenuPicker } from 'skylight-common'
import { GroupsLinks } from '../GroupLink'
import MenuPickerIcon from 'material-ui/svg-icons/navigation/arrow-drop-down'

import './GroupMembership.css'

const TRANSLATIONS = {
  MENU_PICKER_LOADING: 'LOADING',
  MENU_PICKER_ADD: 'GROUP_CREATE_BY_NAME',
  MENU_PICKER_SEARCH: 'GROUP_SEARCH_BY_NAME'
}

class GroupMembership extends React.PureComponent {
  state = {
    isEnabled: false
  }

  onChange = (group, checked) => {
    const user = this.props.user

    if (!checked) {
      this.props.onAddGroupMember(user, group)
    } else {
      this.props.onRemoveGroupMember(user, group)
    }
  }

  onCreateGroup = (groupName) => {
    this.props.onCreateUserGroup(this.props.user, { name: groupName })
  }

  onEnable = () => {
    this.setState({ isEnabled: true })
  }

  render() {
    const props = this.props
    const user = props.user
    const userGroups = user.groups || []
    const selection = () => <GroupsLinks
      groups={userGroups}
      onGroupSelectItems={props.onGroupSelectItems}
      onGroupMemberLoadItems={props.onGroupMemberLoadItems} />
    if (this.props.mode === 'view' || (props.isList && !this.state.isEnabled)) {
      return <div className="group-membership" onTouchTap={() => this.onEnable()}>
        {selection()}
        <div className="icon"><MenuPickerIcon /></div>
      </div>
    }

    const groups = props.groups.items

    return (
      <div className="group-membership">
        <MenuPicker
          openImmediately={props.isList}
          isLoading={props.groups.isLoading}
          items={groups}
          selected={userGroups}
          selectionRenderer={selection}
          valueProp="name"
          translations={TRANSLATIONS}
          onSelect={this.onChange}
          onAdd={this.onCreateGroup}
          forceFixedDropdownPosition />
      </div>
    )
  }
}

export { GroupMembership }
