import React from 'react'
import { FormattedMessage } from 'react-intl'
import GenericPasswordRequirements from '../../../../Common/GenericPasswordRequirements/GenericPasswordRequirements'
import OutlinedPasswordField from '../../../../Common/Form/OutlinedPasswordField/OutlinedPasswordField'
import { first } from 'lodash'
import classNames from 'classnames'

import './AccountPassword.css'

const REQUIRED_ERRORS_MAP = {
  oldPassword: <FormattedMessage id="CURRENT_PWD_REQUIRED" defaultMessage="Current password is required" />,
  newPassword: <FormattedMessage id="NEW_PWD_REQUIRED" defaultMessage="New password is required" />,
  confirmPassword: <FormattedMessage id="CONFIRM_NEW_PWD_REQUIRED" defaultMessage="Confirm new password is required" />
}

const AccountPassword = props => {
  const { errors = {}, form, genericErrors = [] } = props

  const getError = fieldName => {
    const error = errors[fieldName]
    if (error === 'REQUIRED_PASSWORD') {
      return REQUIRED_ERRORS_MAP[fieldName]
    }

    return error ? <FormattedMessage id={error} defaultMessage="Error" /> : null
  }

  return (
    <div className="account-password profile-section">
      <div className="info-fields">
        <OutlinedPasswordField
          required
          error={getError('oldPassword')}
          onChange={props.onChange}
          onFocus={props.onFocus}
          value={form.oldPassword || ''}
          name="oldPassword"
          floatingLabelText={<FormattedMessage id="CURRENT_PASSWORD" defaultMessage="Current password" />} />
        <OutlinedPasswordField
          required
          errorText={getError('newPassword') || first(genericErrors) || props.maxLimitError}
          onChange={props.onChange}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          value={form.newPassword || ''}
          className={classNames('new-password-field', { 'generic-requirements-error': props.maxLimitError || getError('newPassword') })}
          name="newPassword"
          floatingLabelText={<FormattedMessage id="NEWPWD" defaultMessage="New password" />} />
        {!props.maxLimitError && !getError('newPassword') ? <GenericPasswordRequirements errors={genericErrors} /> : null}
        <OutlinedPasswordField
          required
          errorText={getError('confirmPassword')}
          onChange={props.onChange}
          onFocus={props.onFocus}
          value={form.confirmPassword || ''}
          name="confirmPassword"
          floatingLabelText={<FormattedMessage id="NEWPWDRE" defaultMessage="Confirm new password" />} />
      </div>
    </div>
  )
}

export { AccountPassword }
