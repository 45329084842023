import * as ActionTypes from '../actions/types'

import skylight from 'skylight-js-sdk'

const network = store => next => {
  skylight.events.on(skylight.network.EVENTS.CHANGE, ({ isOnline }) => {
    next({ type: ActionTypes.NETWORK_STATE_CHANGED, isOnline })
  })

  return action => {
    return next(action)
  }
}

export default network
