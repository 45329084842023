import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CursorTooltip } from 'skylight-common'

import './TileList.css'

export const TileList = props => {
  const renderTileActions = () => {
    return props.actions.map((action, i) => (
      <CursorTooltip
        className="home-tile-actions-tooltip"
        key={i}
        tooltip={<FormattedMessage
          id={action.title.id}
          defaultMessage={action.title.defaultMessage} />}>
        <div className="tile" onTouchTap={action.handleClick}>
          <action.icon className="icon" />
          <span className="message">
            <FormattedMessage
              id={action.title.id}
              defaultMessage={action.title.defaultMessage} />
          </span>
        </div>
      </CursorTooltip>
    ))
  }

  return (
    <div className="tile-list">
      {renderTileActions()}
    </div>
  )
}
