import React from 'react'
import { connect } from 'react-redux'
import RaisedButton from 'material-ui/RaisedButton'
import { FormattedMessage } from 'react-intl'
import { CursorTooltip } from 'skylight-common'
import { EventDetailsBase } from './EventDetailsBase'
import { EventDetailsMedia } from './EventDetailsMedia'
import dialogActions from '../../../../../../../actions/dialog'
import eventActions from '../../../../../../../actions/application/events'
import mediaActions from '../../../../../../../actions/media'

import './EventDetails.css'

const EventDetails = props => {
  const { event } = props
  const { mediaItemInfo } = event.item

  const handleViewJsonData = () => props.showSessionEventViewJsonDialog(event.item)

  const renderViewJsonButton = () => {
    return <div className="view-json">
      <CursorTooltip
        tooltip={<FormattedMessage id="VIEW_JSON_DESCRIPTION" defaultMessage="View JSON data for this event." />}
        className="view-json-button-tooltip">
        <RaisedButton
          className="view-json-button"
          label={<FormattedMessage id="VIEW_JSON" defaultMessage="VIEW JSON" />}
          primary
          onTouchTap={handleViewJsonData}
          type="button" />
      </CursorTooltip>
    </div>
  }

  const getEventDetailsComp = () => {
    if (mediaItemInfo) {
      return EventDetailsMedia
    }

    return EventDetailsBase
  }

  const EventDetailsComponent = getEventDetailsComp()

  return <div className="event-details">
    <EventDetailsComponent {...props} />
    <div className="buttons-container">
      {renderViewJsonButton()}
    </div>
  </div>
}

const mapStateToProps = state => ({
  event: state.application.event.item
})

export { EventDetails }

const mapDispatchToProps = {
  ...eventActions,
  ...mediaActions,
  showSessionEventViewJsonDialog: dialogActions.showSessionEventViewJsonDialog
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails)
