import React, { Component } from 'react'
import { Table, Column, FormattedDateTime } from 'skylight-common'
import { FormattedMessage } from 'react-intl'

import './SsrcVideoSend.css'

const SCROLL_TOP_PIXELS = 3
const LOAD_REPORT_TIMEOUT = 300

const formatSpeed = (v) => {
  if (v === -1) return <FormattedMessage id="NOT_AVAILABLE" defaultMessage="n/a" />
  return Math.round((v || 0) / 1024) + ' KB/s'
}

class SsrcVideoSend extends Component {
  fixedTableBody = null
  scrollableTableBody = null
  allowLoadReport = true
  loadReportTimeout = null
  currentScroll = 0
  allowScroll = true

  componentDidMount() {
    this.addCrossTableScrollHandlers()
  }

  addCrossTableScrollHandlers = () => {
    if (!this.fixedTable || !this.scrollableTable) {
      return
    }

    this.fixedTableBody = this.fixedTable.getElementsByTagName('tbody')[0]
    this.scrollableTableBody = this.scrollableTable.getElementsByTagName('tbody')[0]
    this.fixedTableBody.addEventListener('scroll', this.addScrollTopHandler(this.scrollableTableBody))
    this.scrollableTableBody.addEventListener('scroll', this.addScrollTopHandler(this.fixedTableBody))
  }

  addScrollTopHandler = (element) => {
    return (e) => {
      this.currentScroll = e.target.scrollTop
      if (!element || element.scrollTop === this.currentScroll) {
        return
      }

      if (Math.abs(element.scrollTop - this.currentScroll) < SCROLL_TOP_PIXELS) {
        return
      }

      setTimeout(() => {
        element.scrollTop = this.currentScroll
      }, 0)
    }
  }

  onReportLoad = query => {
    if (!this.allowLoadReport) {
      return
    }

    this.props.onCallSsrcVideoSendQuery({ ...query, callId: this.props.callId })
    this.allowLoadReport = false
    this.loadReportTimeout = setTimeout(() => { this.allowLoadReport = true }, LOAD_REPORT_TIMEOUT)
  }

  renderFirstColumn = () => {
    const list = this.props.list
    return <div className="fixed-table" ref={fixedTable => { this.fixedTable = fixedTable }}>
      <Table
        showCheckboxes={false}
        className="fixed"
        showHover={true}
        query={list.query}
        items={list.items}
        total={list.total}
        isLoading={list.isLoading}
        done={list.done || !!list.error}
        onQuery={this.onReportLoad} >
        <Column
          name="timestamp"
          sortable={true}
          title={<FormattedMessage id="CALL_REPORT_TIMESTAMP" defaultMessage="Timestamp"/>}
          render={item => <FormattedDateTime value={item.timestamp}/>} />
      </Table>
    </div>
  }

  renderSecondaryColumns = () => {
    const list = this.props.list

    return <div className="scrollable-table" ref={scrollableTable => { this.scrollableTable = scrollableTable }}>
      <Table
        showCheckboxes={false}
        className="scrollable"
        showHover={true}
        query={list.query}
        items={list.items}
        total={list.total}
        isLoading={list.isLoading}
        done={list.done || !!list.error}
        emptyLabel={<FormattedMessage
          id="EMPTY_CALL_SSRC_REPORT_LIST"
          defaultMessage="There are no ssrc video send logs" />
        }
        onQuery={this.onReportLoad} >
        <Column
          name="source"
          sortable={false}
          title={<FormattedMessage id="CALL_REPORT_SOURCE" defaultMessage="Source"/>} />

        {/* From BweForVideo */}
        <Column
          name="sendBandwidth"
          sortable={false}
          title={<FormattedMessage id="CALL_REPORT_SEND_BANDWIDTH" defaultMessage="Send Bandwidth"/>}
          render={item => formatSpeed(item.availableOutgoingBitrate / 8)} />
        <Column
          name="sendSpeed"
          sortable={false}
          title={<FormattedMessage id="CALL_REPORT_SEND_SPEED" defaultMessage="Send Bandwidth"/>}
          render={item => formatSpeed(item.sendSpeed)} />
        <Column
          name="receiveSpeed"
          sortable={false}
          title={<FormattedMessage id="CALL_REPORT_RECEIVE_SPEED" defaultMessage="Receive Bandwidth"/>}
          render={item => formatSpeed(item.receiveSpeed)} />
        <Column
          name="transmitBitrate"
          sortable={false}
          title={<FormattedMessage id="CALL_REPORT_TRANSMIT_BYTE_RATE" defaultMessage="Transmit Byterate"/>}
          render={item => formatSpeed(item.transmitBitrate / 8)} />
        {/* End of From BweForVideo */}

        <Column
          name="packetsLost"
          sortable={false}
          title={<FormattedMessage id="CALL_REPORT_PACKETS_LOST" defaultMessage="Packets Lost"/>} />
        <Column
          name="firsReceived"
          sortable={false}
          title={<FormattedMessage id="CALL_REPORT_FIRS_RECEIVED" defaultMessage="Firs Received"/>} />
        <Column
          name="frameRateSent"
          sortable={false}
          render={ item => item.frameRateSent === -1
            ? <FormattedMessage id="NOT_AVAILABLE" defaultMessage="n/a" />
            : item.frameRateSent }
          title={<FormattedMessage id="CALL_REPORT_FRAME_RATE_SENT" defaultMessage="Frame Rate Sent"/>} />
        <Column
          name="bytesSent"
          sortable={false}
          title={<FormattedMessage id="CALL_REPORT_BYTES_SENT" defaultMessage="Bytes Sent"/>} />
        <Column
          name="frameWidthSent"
          sortable={false}
          title={<FormattedMessage id="CALL_REPORT_FRAME_WIDTH_SENT" defaultMessage="Frame width sent"/>} />
        <Column
          name="frameHeightSent"
          sortable={false}
          title={<FormattedMessage id="CALL_REPORT_FRAME_HEIGHT_SENT" defaultMessage="Frame Height Sent"/>} />
        <Column
          name="resolutionLimited"
          sortable={false}
          title={<FormattedMessage id="CALL_REPORT_RESOLUTION_LIMITED" defaultMessage="Resolution Limited"/>} />
        {/* <Column
          name="temp"
          title={<FormattedMessage id="CALL_REPORT_TEMP" defaultMessage="Temp (C)"/>} /> */}
      </Table>
    </div>
  }

  render() {
    return <div className="ssrc-video-send">
      {this.renderFirstColumn()}
      {this.renderSecondaryColumns()}
    </div>
  }
}

export { SsrcVideoSend }
