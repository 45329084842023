import React from 'react'
import Toggle from 'material-ui/Toggle'
import { FormattedMessage } from 'react-intl'

import './LdapPicker.css'

const LdapPicker = props => {
  return (
    <div className="ldap-picker">
      <div className="title">
        <FormattedMessage id="LDAP_TITLE" defaultMessage="LDAP Federation" />
      </div>
      <div className="description">
        <FormattedMessage id="LDAP_DESCRIPTION" defaultMessage="" />
      </div>
      <Toggle
        className="toggle-control"
        toggled={props.isToggled}
        onToggle={(e, v) => props.onToggleLdap(v)}/>
    </div>
  )
}

export { LdapPicker }
