import * as ActionTypes from '../../actions/types'
import { DEFAULT_QUERY } from '../../lib/media'
import { createListReducer } from 'skylight-common'

const list = createListReducer({
  prefix: ActionTypes.PREFIX_MEDIA.toUpperCase()
}, DEFAULT_QUERY)

const mediaList = (state, payload, all) => {
  const listState = list(state, payload, all)

  switch (payload.type) {
    case ActionTypes.MEDIA_FILTER_TYPE: {
      const hasType = listState.query.allowedTypes.includes(payload.mediaType)
      return {
        ...listState,
        query: {
          ...listState.query,
          allowedTypes: hasType
            ? listState.query.allowedTypes.filter(x => x !== payload.mediaType)
            : [...listState.query.allowedTypes, payload.mediaType]
        }
      }
    }
    case ActionTypes.MEDIA_ITEM_PREVIEW:
      return { ...state, selectedIds: [] }
    default:
      return listState
  }
}

export default mediaList
