import React from 'react'
import FloatingActionButton from 'material-ui/FloatingActionButton'
import IconButton from 'material-ui/IconButton'
import { FormattedMessage } from 'react-intl'
import './CallButton.css'

export const CallButton = props => {
  const {
    isFab = false, disabled = false, icon,
    className, tooltip, tooltipPosition = 'top-center', onTouchTap
  } = props
  const Button = isFab ? FloatingActionButton : IconButton
  const tooltipProps = isFab ? {} : { tooltipPosition, tooltip: <FormattedMessage id={tooltip} /> }

  return <div className={`call-button ${isFab ? 'fab' : 'icon'} ${className || ''}`}>
    <Button disabled={disabled} {...tooltipProps} onTouchTap={onTouchTap}>
      {icon}
    </Button>
  </div>
}
