import * as ActionTypes from '../types'
import { push } from 'react-router-redux'
import { createFilterActions, createListActions, createItemActions } from 'skylight-common'
import skylight from 'skylight-js-sdk'
import { getEvents } from '../../svc/application/events'
import { DEFAULT_QUERY } from '../../lib/application/event'

const LIST_ACTIONS = createListActions({
  prefix: ActionTypes.PREFIX_SESSION_EVENT.toUpperCase(),
  handler: ActionTypes.PREFIX_SESSION_EVENT,
  onGetList: s => s.application.event.list,
  onLoad: async(query, { dispatch, getState }) => {
    const state = getState()
    const { applicationId } = state.application.selectedApplication
    const { item } = state.application.session.item
    return getEvents(applicationId, item.sessionId, query)
  }
})

const FILTER_ACTIONS = createFilterActions({
  baseUrl: '/ng/applications/:applicationId/sessions/:sessionId/events',
  redirect: false,
  selector: state => state.application.event.list.query,
  listPrefix: ActionTypes.PREFIX_SESSION_EVENT.toUpperCase(),
  actionPrefix: ActionTypes.PREFIX_SESSION_EVENT,
  DEFAULT_QUERY,
  loadItems: LIST_ACTIONS.onSessionEventLoadItems,
  push
})

const redirectToNewQuery = FILTER_ACTIONS.onSessionEventQueryUpdated

const sessionSortUpdate = (sortBy, sortAsc) =>
  redirectToNewQuery(q => ({ ...q, sortBy, sortAsc }))

const ITEM_ACTIONS = createItemActions({
  prefix: ActionTypes.PREFIX_SESSION_EVENT.toUpperCase(),
  handler: ActionTypes.PREFIX_SESSION_EVENT,
  onGetList: s => s.application.event.list,
  onLoad: async(item, res, ctx) => item,
  onLoadItems: LIST_ACTIONS.onSessionEventLoadItems
})

const handleEventsDevicesLoad = (events) => (dispatch, getState) => {
  const state = getState()
  const existentDeviceIds = Object.keys(state.application.event.devices)
  const newDeviceIds = events.reduce((acc, event) => {
    const deviceId = event.deviceId
    if (acc.includes(deviceId) || existentDeviceIds.includes(deviceId)) {
      return acc
    }

    acc.push(deviceId)
    return acc
  }, [])
  newDeviceIds.forEach(deviceId => dispatch(handleEventDeviceLoad(deviceId)))
}

const handleEventDeviceLoad = deviceId => async dispatch => {
  try {
    const device = await skylight.we20.device.getById(deviceId)
    dispatch({
      type: ActionTypes.SESSION_EVENT_DEVICE_ADD,
      device
    })
  } catch (e) {
    console.error(e.message)
  }
}

export default {
  ...LIST_ACTIONS,
  ...FILTER_ACTIONS,
  ...ITEM_ACTIONS,
  onEventSortUpdate: sessionSortUpdate,
  onEventsDevicesLoad: handleEventsDevicesLoad
}
