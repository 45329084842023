import React from 'react'
import {
  Dialog,
  FlatButton,
  TextField,
  SelectField,
  Checkbox,
  MenuItem,
  Card,
  CardHeader,
  CardText,
  RaisedButton,
  IconButton
} from 'material-ui'
import QRCode from 'qrcode.react'
import { DialogTitle } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import skylight from 'skylight-js-sdk'
import { HiddenTextField } from '../../../../Common'
import { FIELDS, ADVANCED_FIELDS, validateFields } from './fields'

import DeleteIcon from 'material-ui/svg-icons/navigation/close'
import RightIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-right'
import DownIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down'
import './SetupWifiDialog.css'

const FIELD_MAP = {
  text: { comp: TextField, getHandler: cb => ({ onChange: cb }) },
  menu: { comp: SelectField, getHandler: cb => ({ onChange: (e, i, v) => cb(e, v) }) },
  checkbox: { comp: Checkbox, getHandler: cb => ({ onCheck: cb }) },
  password: { comp: HiddenTextField, getHandler: cb => ({ onChange: cb }) }
}

const CONFIG_KEY = 'WIFI_CONFIGS'
const STATIC_IP_PROPS = ['address', 'subnetMask', 'defaultGateway', 'primaryDNS', 'secondaryDNS']

class SetupWifiDialog extends React.Component {
  state = { items: [], item: {}, code: null, isNewEditing: false, errors: {}, focusedField: null }

  async componentDidMount() {
    let items = await skylight.storage.getItem(CONFIG_KEY) || []
    if (typeof items === 'string') {
      try {
        items = JSON.parse(items).sort((a, b) => a.ssid.localeCompare(b.ssid))
      } catch (e) {
        skylight.logger.error('SetupWifiDialog componentDidMount', e)
      }
    }

    this.setState({ items, isNewEditing: items.length === 0 })

    // Required for QR code to resize
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    this.setState({}) // Just call re-render, consider some kind of debounce
  }

  isOpen = () => {
    const { dialogType, dialog } = this.props

    return !!(dialog[dialogType] || {}).open
  }

  isQRGenerationDisabled = () => {
    const errors = validateFields(this.state.item)

    // TODO: For now, update.
    return Object.keys(errors).length > 0
  }

  getActions = () => {
    const { code } = this.state
    const labels = code
      ? {
          cancel: { id: 'CLOSE', defaultMessage: 'Close' },
          submit: { id: 'SAVE_QR', defaultMessage: 'Save QR code' }
        }
      : {
          cancel: { id: 'CANCEL', defaultMessage: 'Cancel' },
          submit: { id: 'GENERATE_QR', defaultMessage: 'Generate QR code' }
        }

    return [
      <FlatButton
        key="cancel-action"
        className="cancel-action"
        label={<FormattedMessage {...labels.cancel} />}
        onTouchTap={this.hide} />,
      <FlatButton
        primary={true}
        key="submit-action"
        label={<FormattedMessage {...labels.submit} />}
        disabled={code ? false : this.isQRGenerationDisabled()}
        onTouchTap={code ? this.saveSettings : this.generateQRCode} />
    ]
  }

  hide = () => {
    const { onHideDialog, dialogType } = this.props

    onHideDialog(dialogType)
  }

  onChange = (p, v) => {
    const item = { ...this.state.item, [p]: v }
    const errors = validateFields(item)
    this.setState({ item, errors })
  }

  onChangeConfig = (e, i, v) => {
    this.setState(s => ({ item: s.items.find(x => x.ssid === v) || {} }))
  }

  saveSettings = () => {
    const items = [
      // Use trim to make sure that items saved in localStorage previously does not contain whitespaces.
      // Commenting out as a part of https://upskill.atlassian.net/browse/SKY-5947
      // Might be confusing, but technically these are different networks...
      ...this.state.items.filter(x => x.ssid/* .trim() */ !== this.state.item.ssid/* .trim() */),
      this.state.item
    ]

    skylight.storage.setItem(CONFIG_KEY, items)

    this.hide()
  }

  convertItem = () => {
    // Wrap ssid in quotes before generating QR code to prevent client to treat it as HEX.
    // See for details - https://developer.android.com/reference/android/net/wifi/WifiConfiguration.html#SSID
    const item = { ...this.state.item, ssid: `"${this.state.item.ssid}"` }
    // staticIp might be false, and api expects {} or null.
    if (!item.hasOwnProperty('staticIp')) {
      return item
    }

    // staticIp field should be nested
    const result = { staticIp: item.staticIp ? {} : null }
    Object.keys(item).forEach(k => {
      if (k === 'staticIp') { // Prevent override staticIp
        return
      }

      if (item.staticIp && STATIC_IP_PROPS.includes(k)) {
        result.staticIp[k] = item[k]
      } else {
        result[k] = item[k]
      }
    })

    return result
  }

  generateQRCode = () => {
    let code = null
    try {
      code = JSON.stringify(this.convertItem())
      this.setState({ code })
    } catch (e) {
      skylight.logger.error('SetupWifiDialog generateQRCode', e)
    }
  }

  deleteConfig = (e, ssid) => {
    e.stopPropagation()

    const items = this.state.items.filter(x => x.ssid !== ssid)
    skylight.storage.setItem(CONFIG_KEY, items)
    // Enable edit mode if all items are removed.
    this.setState({ items, isNewEditing: items.length === 0 })
  }

  onExpandChange = v => {
    // When advanced settings expandable is closed - reset advanced settings values
    if (!v && this.state.isNewEditing) {
      this.setState(s => ({ item: { ...s.item, staticIp: null, hidden: false } }))
    }
  }

  onFocus = (e, prop) => {
    this.setState({ focusedField: prop })
  }

  handleValue = prop => {
    const value = (this.state.item[prop] || '').trim()
    if (prop === 'ssid') {
      // Handle `ssid` property differently, trim to verify if not empty, but leave spaces as is.
      return value ? this.state.item[prop] : value
    }

    return value
  }

  onBlur = (e, prop) => {
    const item = { ...this.state.item, [prop]: this.handleValue(prop) }
    const errors = validateFields(item)

    this.setState({ item, errors, focusedField: null })
  }

  // TODO: Cleanup this mess.
  renderField = x => {
    const isDisplayAllowed = x.allowDisplay ? x.allowDisplay(this.state) : true
    if (!isDisplayAllowed) {
      return null
    }

    const { item, errors, focusedField } = this.state
    const { comp: Field, getHandler } = FIELD_MAP[x.type]
    const valueProp = x.type === 'checkbox' ? 'checked' : 'value'
    const fieldProps = {
      ...getHandler((e, v) => this.onChange(x.name, v)),
      [x.type === 'checkbox' ? 'label' : 'floatingLabelText']:
        <FormattedMessage id={x.label.id} defaultMessage={x.label.default} />,
      [valueProp]: item[x.name] || (valueProp === 'checked' ? false : '')
    }
    if (x.type === 'menu') {
      fieldProps.selectedMenuItemStyle = { color: '#00aaed' }
    }

    if (x.type === 'text' || x.type === 'password') {
      fieldProps.autoComplete = 'off'
      fieldProps.autoFocus = !!x.autoFocus
      fieldProps.onFocus = e => this.onFocus(e, x.name)
      fieldProps.onBlur = e => this.onBlur(e, x.name)
    }

    // Make sure it is not a checkbox.
    // According to feedback - https://upskill.atlassian.net/browse/SKY-4598?focusedCommentId=21404#comment-21404
    // Do not display empty field error, only disable button.
    // Do not display an error for focused field.
    if (x.type !== 'checkbox' && item[x.name] && x.name !== focusedField) {
      const error = errors[x.name]
      fieldProps.errorText = error
        ? <FormattedMessage id={error.name || error} values={error.value ? { value: error.value } : {}} defaultMessage="Error" />
        : null
    }

    return <Field
      key={x.name}
      name={`setup-wifi-editor-field-${x.name}`}
      className={`setup-wifi-editor-field ${x.type}`}
      {...fieldProps}>
        {
          x.values
            ? x.values.map(x => <MenuItem
            key={x.v}
            className="setup-wifi-editor-menu-item"
            value={x.v}
            primaryText={x.l ? <FormattedMessage id={x.l.id} defaultMessage={x.l.def} /> : x.v} />)
            : null
        }
      </Field>
  }

  renderEditorFields = () =>
    this.isEditingAllowed() ? FIELDS.map(this.renderField) : null

  renderAdvancedFields = () => {
    if (!this.isEditingAllowed()) {
      return null
    }

    return (
      <Card className="setup-wifi-editor-expandable-container" onExpandChange={this.onExpandChange}>
        <CardHeader
          className="setup-wifi-editor-expandable-header"
          title={<FormattedMessage id="ADVANCED_OPTIONS" defaultMessage="Advanced options" />}
          openIcon={<DownIcon />}
          closeIcon={<RightIcon />}
          actAsExpander={true}
          showExpandableButton={true} />
        <CardText className="setup-wifi-editor-expandable-content" expandable={true}>
          {ADVANCED_FIELDS.map(this.renderField)}
        </CardText>
      </Card>
    )
  }

  renderAddSelect = () => {
    const { items, isNewEditing, item } = this.state

    if (isNewEditing || items.length === 0 || Object.keys(item).length > 0) {
      return null
    }

    return (
      <div className="add-select-wrapper">
        <SelectField
          className="setup-wifi-editor-field"
          floatingLabelText={<FormattedMessage id="SELECT_EXISTING_NETWORK" defaultMessage="Select existing network" />}
          value={this.state.item.ssid}
          listStyle={{ overflow: 'hidden', display: 'block' }}
          selectedMenuItemStyle={{ color: '#00aaed' }}
          onChange={this.onChangeConfig}>
            {items.map((x, i) => {
              const rightIcon = (
                <IconButton
                  className="wifi-editor-delete-network-button"
                  tooltip={<FormattedMessage id="FORGET_NETWORK" defaultMessage="Forget network" />}
                  tooltipPosition="bottom-left"
                  onTouchTap={e => this.deleteConfig(e, x.ssid)}>
                  <DeleteIcon className="setup-wifi-editor-delete-icon" />
                </IconButton>
              )

              return (
                <MenuItem
                  key={i}
                  className="setup-wifi-editor-menu-item"
                  value={x.ssid}
                  primaryText={x.ssid}
                  rightIcon={rightIcon} />
              )
            })}
          </SelectField>
          <RaisedButton
            className="add-network-button"
            label={<FormattedMessage id="ADD_NETWORK" defaultMessage="Add network" />}
            primary={true}
            onTouchTap={() => this.setState({ isNewEditing: true })} />
      </div>
    )
  }

  isEditingAllowed = () => this.state.isNewEditing || Object.keys(this.state.item).length > 0

  renderEditor = () => {
    return (
      <div className="setup-wifi-editor">
        <div className="setup-wifi-editor-title">
          <FormattedMessage id="WIFI_QR_GEN_TITLE" defaultMessage="WiFi QR Code Generator" />
          <FormattedMessage id="WIFI_QR_GEN_BODY" defaultMessage="Generate a QR code to connect your device to WiFi." />
        </div>
        <div className="setup-wifi-editor-body">
          {this.renderAddSelect()}
          {this.renderEditorFields()}
          {this.renderAdvancedFields()}
        </div>
      </div>
    )
  }

  // Update dialog size on window resize to adjust QR code size.
  getSize = () => Math.min(window.innerHeight, window.innerWidth)

  // Use around 70% of window for qr code size.
  renderQRCode = () => {
    return (
      <div className="setup-wifi-qr-code">
        <QRCode
          value={this.state.code}
          size={this.getSize() * 0.7} />
      </div>
    )
  }

  render() {
    // Remove paddings/other indentatins from width, around 90%.
    const { code } = this.state
    const contentStyle = code
      ? {
          width: this.getSize() * 0.9,
          maxWidth: 'none'
        }
      : {}

    return (
      <Dialog
        title={<DialogTitle id="WIFI_QR_GEN_TITLE" defaultMessage="WiFi QR Code Generator" />}
        open={this.isOpen()}
        className="dialog-modal setup-wifi-dialog"
        contentClassName="setup-wifi-content"
        bodyClassName={`setup-wifi-body ${code ? 'qr-body' : ''}`}
        actionsContainerClassName="setup-wifi-footer"
        actions={this.getActions()}
        autoScrollBodyContent={!code}
        autoDetectWindowHeight={!code}
        contentStyle={contentStyle}
        onRequestClose={() => this.props.onHideDialog(this.props.dialogType)}>
        {code ? this.renderQRCode() : this.renderEditor()}
      </Dialog>
    )
  }
}

export { SetupWifiDialog }
