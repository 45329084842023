import * as ActionTypes from '../../../actions/types'
import { DEFAULT_EVENTS_QUERY } from '../../../lib/activity'
import { createListReducer } from 'skylight-common'

const list = createListReducer({
  prefix: 'ACTIVITY'
}, DEFAULT_EVENTS_QUERY)

const eventList = (state, payload, all) => {
  const defaultDeletionState = {
    isDeleting: false
  }
  const listState = {
    ...list(state, payload, all),
    ...defaultDeletionState
  }

  switch (payload.type) {
    case ActionTypes.ACTIVITY_LIST_DELETE:
      return { ...listState, isDeleting: true }
    case ActionTypes.ACTIVITY_LIST_DELETE_SUCCESS:
      return { ...listState, isDeleting: false }
    case ActionTypes.ROUTER_LOCATION_CHANGE:
      return { ...listState, ...defaultDeletionState }
    default:
      return listState
  }
}

export default eventList
