import React from 'react'
import Toggle from 'material-ui/Toggle'
import { FormattedMessage } from 'react-intl'

import './AuthPicker.css'

const AuthPicker = props => {
  const { authApp, onChange, onError } = props
  const item = authApp.item || {}
  const original = authApp.original || {}
  const enableSaml = item.enabled

  const onToggleSaml = v => {
    if (item.isDefault) {
      return onError('AUTH_NO_PROVIDERS_ERROR')
    }

    if (!v && original) {
      // Reset options to original.
      onChange('options', original.options)
    }
    onChange('enabled', v)
  }

  return (
    <div className="auth-picker">
      <div className="title">
        <FormattedMessage
          id="SAML_AUTH_TITLE"
          defaultMessage="SAML authentication"/>
      </div>
      <div className="description">
        <FormattedMessage id="SAML_AUTH_DESCRIPTION" defaultMessage="" />
      </div>
      <Toggle
        className="toggle-control"
        toggled={enableSaml}
        onToggle={(e, v) => onToggleSaml(v)}/>
    </div>
  )
}

export { AuthPicker }
