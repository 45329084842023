import React from 'react'
import { Settings } from './Settings'
import './Profile.css'

const Profile = (props) => {
  const { profile } = props

  return (
    <div className="profile">
      <Settings
        {...props}
        settings={profile.settings} />
    </div>
  )
}

export { Profile }
