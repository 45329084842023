import React from 'react'
import { FormattedMessage } from 'react-intl'
import FloatingActionButton from 'material-ui/FloatingActionButton'
import callSdk from 'skylight-call-sdk'
import PhotoIcon from 'material-ui/svg-icons/image/photo'
import UploadIcon from 'material-ui/svg-icons/file/file-upload'
import './MediaGallery.css'
import { onFilesChanged, ALLOWED_EXTENSIONS } from '../../lib/files'

export const MediaGallery = props => {
  const { call, items, onSelect, renderItem } = props
  const onMediaItemClick = (e, item, index) => {
    e.preventDefault()
    onSelect(item, index)
  }

  const onFileChange = (e) => {
    const files = Array.from(e.target.files)
    e.target.value = '' // Reset input file.
    onFilesChanged(props, files)
  }

  const isUploadDisabled = callSdk.lib.ui.isFileTransfering(call)

  const onUpload = (e) => document.getElementById('upload-button').click()

  const { allowedExtensions = ALLOWED_EXTENSIONS.join(', ') } = props

  return (
    <div className="media-gallery">
      {items.length === 0 && <div className="no-items">
        <PhotoIcon />
        <FormattedMessage id="CALL_MEDIA_GALLERY_NO_ITEMS" defaultMessage="No shared media" />
      </div>}
      {items.filter(x => !!x).map((item, index) =>
        <div className="media-gallery-item" key={index}>
          <div className="content" onClick={e => onMediaItemClick(e, item, index)}>
            {renderItem
              ? renderItem(item)
              : <img alt="" src={item.file.uri || item.file.src} />}
          </div>
        </div>
      )}

      <FloatingActionButton
        className="upload fab"
        disabled={isUploadDisabled}
        onTouchTap={onUpload}>
        <UploadIcon />
      </FloatingActionButton>
      <input
        accept={allowedExtensions}
        id="upload-button"
        className="upload-button"
        multiple
        onChange={onFileChange}
        type="file" />
    </div>
  )
}
