import React from 'react'
import { CardTags } from './CardTags'
import { ItemProps } from '../../../../Common'
import { TextInputTile } from '../TextInputAttachments'
import { DecisionTile } from '../DecisionAttachments'
import './CaptureDetails.css'

const TILE_MAP = {
  decision: DecisionTile,
  textInput: TextInputTile
}

const APP_PROPS = [
  { field: 'name', title: 'APPLICATION_NAME', type: 'string' }
]

const DETAILS_PROPS = [
  { field: 'sequenceId', title: 'ACTIVITY_SEQUENCE_ID', type: 'string' },
  { field: 'id', title: 'ACTIVITY_CARD_ID', type: 'string' },
  { field: 'updatedAt', title: 'LAST_UPDATED_ON', type: 'date' },
  {
    field: 'tags',
    className: 'tags-prop',
    title: 'CARD_TAGS',
    type: 'render',
    render: _ => null /* Rendering in item props has overflow/scroll issues */
  }
]

const CaptureDetails = (props) => {
  const { item } = props
  const parent = props.attachment.parent

  const Tile = TILE_MAP[item.cardType]

  return (
    <div className="attachment-capture-details">
      <Tile {...props} item={item} isDetails={true} />
      <ItemProps item={parent} props={APP_PROPS} className="parent-props" />
      <ItemProps item={item} props={DETAILS_PROPS} />
      <CardTags item={item} />
    </div>
  )
}

export { CaptureDetails }
