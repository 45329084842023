import * as ActionTypes from '../actions/types'

const initialState = {
  isLoading: false,
  error: null
}

const redirect = (state = initialState, payload, all) => {
  switch (payload.type) {
    case ActionTypes.REDIRECTION_START:
      return { ...state, isLoading: true }
    case ActionTypes.REDIRECTION_ERROR:
      return { ...state, isLoading: false, error: payload.error }
    default:
      return state
  }
}

export default redirect
