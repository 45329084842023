import React from 'react'
import get from 'lodash/get'
import { formatDateTime, ExportIcon, CursorTooltip } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import { FlatButton } from 'material-ui'
import Divider from 'material-ui/Divider'
import _ from 'lodash'

import './LastExported.css'

const METADATA_FIELDS = [
  {
    name: { id: 'EXPORT_VERSION', defaultMessage: 'Version' },
    getValue: e => get(e, 'version')
  },
  {
    name: { id: 'EXPORT_STATUS', defaultMessage: 'Status' },
    getValue: e => _.startCase(_.toLower(get(e, 'versionStage', '')))
  },
  {
    name: { id: 'EXPORTED_AT', defaultMessage: 'Exported' },
    getValue: e => {
      const timestamp = get(e, 'modifiedAt') || get(e, 'createdAt', '')
      return timestamp ? formatDateTime(timestamp) : ''
    }
  }
]

const LastExported = ({ applicationExport = {}, onDownloadAppExport }) => {
  const renderDownloadButton = () => {
    if (!applicationExport.export) return null

    return (
      <CursorTooltip tooltip={<FormattedMessage id="ACTION_DOWNLOAD" defaultMessage="Download" />}>
        <FlatButton
          className="download-button"
          label={<FormattedMessage id="ACTION_DOWNLOAD" defaultMessage="Download" />}
          onTouchTap={() => onDownloadAppExport(get(applicationExport, 'export.exportId'), get(applicationExport, 'export.version'))}
          primary />
      </CursorTooltip>
    )
  }

  const renderExportContent = () => {
    if (!applicationExport.export) {
      return (
        <div className="no-export-available">
          <FormattedMessage id="NO_EXPORT_AVAILABLE" defaultMessage="No existing exports. Navigate to “Versions” page and select an application version to export." />
        </div>
      )
    }

    return (
      <div className="export-metadata-fields">
        {METADATA_FIELDS.map((f, i) => {
          return <div className="export-metadata-field" key={i}>
            <FormattedMessage {...f.name} />:
            <span className="metadata-field-value"> {f.getValue(applicationExport.export)}</span>
          </div>
        })}
      </div>
    )
  }

  return <div className="last-application-export">
    <div className="last-export-header">
      <div className="last-export-header-info-wrapper">
        <div className="export-icon-wrapper">
          <ExportIcon className="export-icon" />
        </div>
        <div className="last-export-header-fields">
          <div className="exported-file">
            <FormattedMessage id="EXPORTED_FILE" defaultMessage="Exported File" />
          </div>
          <div className="download-export">
            <FormattedMessage id="DOWNLOAD_LATEST_EXPORT" defaultMessage="Download the latest export." />
          </div>
        </div>
      </div>
      {renderDownloadButton()}
    </div>
    <Divider />
    <div className="last-application-export-content">
      {renderExportContent()}
    </div>
  </div>
}

export { LastExported }
