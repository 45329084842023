import React from 'react'
import { FormattedMessage } from 'react-intl'
import FlatButton from 'material-ui/RaisedButton'
import { isAndroid } from '../../../../../lib/browser'

import PhoneIcon from 'material-ui/svg-icons/communication/phonelink-ring'
import './ClientAppLogin.css'

const ClientAppLogin = (props) => {
  if (!isAndroid()) {
    return null
  }

  const onLaunchApp = e => {
    e.preventDefault()
    props.onLaunchApp(props.token)
  }

  const getLabel = () => {
    if (!props.token) {
      return (
        <div className="app-login-text-area">
          <FormattedMessage id="CLIENT_APP_RELOGIN_INFO" defaultMessage="Please re-login to launch Skylight mobile application" />
        </div>
      )
    }

    return (
      <div className="app-login-text-area">
        <FormattedMessage id="CLIENT_APP_LOGIN" defaultMessage="Tap to login to Skylight mobile application" />
        <FormattedMessage id="CLIENT_APP_LOGIN_INFO" defaultMessage="(must be installed)" />
      </div>
    )
  }

  return (
    <div className="client-app-login">
      <FlatButton
        className="client-login-button"
        icon={<PhoneIcon />}
        label={getLabel()}
        onTouchTap={props.token ? onLaunchApp : props.toLogin} />
    </div>
  )
}

export { ClientAppLogin }
