import React from 'react'
import { AuthenticationTabs } from './AuthenticationTabs'
import { connect } from 'react-redux'
import ldapActions from '../../../../../actions/ldap'
import { withRouter } from 'react-router-dom'
import appActions from '../../../../../actions/app'

class Authentication extends React.Component {
  render() {
    const { match } = this.props
    return (
      <div className="admin">
        <AuthenticationTabs {...this.props} type={match.params.type} />
      </div>
    )
  }
}

const authenticationActions = {
  ...ldapActions,
  ...appActions
}

export default withRouter(connect(null, authenticationActions)(Authentication))
