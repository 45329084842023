import React from 'react'
import skylight from 'skylight-js-sdk'

import { Route, Switch } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { CONFIG, Loading } from 'skylight-common'

import { SignIn } from './SignIn'
import { FrontlineSignin } from './FrontlineSignin'
import { RealmSignin } from './RealmSignin'
import { MissingAccount } from './MissingAccount/MissingAccount'
import { DomainLink } from './DomainLink/DomainLink'

import { ChangePasswordDialog } from '../../containers/dialogs'

import './Login.css'

const Login = (props) => {
  const { match } = props
  const { signin, realm } = props.login
  const CarbonComp = CONFIG.useCarbon ? RealmSignin : SignIn
  const SignInComp = skylight.utils.isFrontline() ? FrontlineSignin : SignIn

  const handleAutofill = (newValue, value, action) => {
    if (newValue && newValue !== value) {
      action(newValue)
    }
  }

  const renderDomainLink = () => {
    // match.isExact is used to differentiate a LoginDomain page from LoginUserForm page,
    // only Login Domain page has match.isExact property and we don't want to show
    // link to current domain on it.
    if (realm.realm && !match.isExact) {
      return <DomainLink realm={realm} history={props.history} />
    }
  }

  const isLoading = signin.isSigningIn || realm.isLoading

  return (
    <div className="login-container">
      <div className="login">
        {!isLoading && renderDomainLink()}
        <img className="logo" src="/assets/img/skylight-logo.png" alt="logo" />
        <Loading
          isLoading={isLoading}
          error={signin.error && typeof signin.error === typeof ''
            ? <FormattedMessage id={signin.error} defaultMessage="UNEXPECTED_ERROR" />
            : null}
          message="SIGNING">
          <div className="login-wrapper">
            <Switch>
              <Route
                path={match.url}
                exact
                render={() => <CarbonComp {...props} handleAutofill={handleAutofill} />} />
              <Route
                path={`${match.url}/missing-account`}
                exact
                component={MissingAccount} />
              <Route
                path={`${match.url}/sso`}
                exact
                component={null} />
              <Route
                path={`${match.url}/:realm`}
                render={r => <SignInComp
                  {...props}
                  urlRealm={r.match.params.realm}
                  handleAutofill={handleAutofill} />} />
            </Switch>
            <ChangePasswordDialog />
          </div>
        </Loading>
      </div>
      <div className="login-brand">Powered by Upskill – A TeamViewer Company</div>
    </div>
  )
}

export { Login }
