import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const sessionConnected = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M4.37864078,1 L3,2.37864078 L6.89320388,6.26213592 L5.14563107,8 L10,8 L10,3.14563107 L8.26213592,4.89320388 M14,8 C10.14,8 7,11.13 7,15 C7,18.8659932 10.1340068,22 14,22 C17.86,22 21,18.87 21,15 C21,11.1340068 17.8659932,8 14,8 M14,10 C16.7525773,10 19,12.2371134 19,15 C19,16.3260824 18.4732158,17.597852 17.5355339,18.5355339 C16.597852,19.4732158 15.3260824,20 14,20 C11.2371134,20 9,17.7628866 9,15 C9,12.2385763 11.2385763,10 14,10 M13,12 L13,16.0036166 L16.2385787,18 L17,16.5895118 L14.5228426,15.0596745 L14.5228426,12" id="path-1"></path>
    </SvgIcon>
  )
}

export default sessionConnected
