import * as ActionTypes from '../../../actions/types'
import { createItemReducer } from 'skylight-common'

const baseItem = createItemReducer({ actionPrefix: ActionTypes.PREFIX_LDAP_APP })

const ldapApp = (state, action) => {
  state = baseItem(state, action)
  switch (action.type) {
    case ActionTypes.LDAPAPP_FORCE_SYNC_LOAD:
      return { ...state, isSyncLoaded: true, syncError: false }
    case ActionTypes.LDAPAPP_FORCE_SYNC_LOADED:
    case ActionTypes.LDAPAPP_FORCE_SYNC_ABORT:
      return { ...state, isSyncLoaded: false, syncError: false }
    case ActionTypes.LDAPAPP_FORCE_SYNC_LOAD_ERROR:
      return { ...state, isSyncLoaded: false, syncError: true }
    case ActionTypes.LDAPAPP_CUSTOM_MAPPINGS_UPDATE_SUCCESS:
      return {
        ...state,
        item: { ...state.item, customMappings: action.customMappings },
        original: { ...state.original, customMappings: action.customMappings }
      }
    case ActionTypes.LDAPAPP_CUSTOM_FILTERS_UPDATE_SUCCESS:
      return {
        ...state,
        item: { ...state.item, customFilters: action.customFilters },
        original: { ...state.original, customFilters: action.customFilters }
      }
    default:
      return state
  }
}

export default ldapApp
