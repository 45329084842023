import React from 'react'
import { FooterButtons } from './FooterButtons'
import './CallFooter.css'

export const CallFooter = props => {
  return (
    <div className="call-footer">
      <FooterButtons {...props} />
    </div>
  )
}
