import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { TokenSettings } from '../../../../Admin/AdminAuth/TokenSettings'
import adminActions from '../../../../../../actions/admin'
import { FooterActionButtons } from '../../../../../Common/FooterActionButtons'
import { OutlinedTextField } from '../../../../../Common/Form/OutlinedTextField'

import './TokensAndTimers.css'

class TokensAndTimers extends React.Component {
  componentDidMount() {
    this.props.onRealmLoad()
  }

  render() {
    const { realm } = this.props.auth
    const hasChanged = realm.hasChanged

    const onSave = () => {
      realm.hasChanged && this.props.onRealmSave(realm.item)
    }

    const onCancel = () => {
      this.props.onRealmCancel()
    }

    return (
      <div className={classNames('tokens-and-timers', this.props.className)}>
        <TokenSettings textFieldComp={OutlinedTextField} realm={realm} onChange={this.props.onRealmChange} />
        {hasChanged && <FooterActionButtons onCancel={onCancel} onSave={onSave} />}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.admin.auth
})

export default connect(mapStateToProps, adminActions)(TokensAndTimers)
