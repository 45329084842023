import React from 'react'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'

import { TrimmingTextField, Form, DialogTitle } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import { ADD_TOKEN } from './dialogTypes'

import './AddTokenDialog.css'

const AddTokenDialog = ({ onHideDialog, dialog, dialogType, ...props }) => {
  const { open } = dialog[dialogType] || {}
  const { item } = props.item
  const isEnabled = item && item.name && !props.item.isSaving

  const onSubmit = () => {
    isEnabled && props.onApiTokenSave(item)
  }

  const onClose = () => {
    onHideDialog(ADD_TOKEN)
  }

  const dialogActions = [
      <FlatButton
        key="close-action"
        className="close-action"
        label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
        onTouchTap={onClose}/>,
      <FlatButton
        key="create-action"
        label={<FormattedMessage id="ACTION_CREATE" defaultMessage="Create" />}
        primary
        keyboardFocused
        disabled={!isEnabled}
        onTouchTap={onSubmit}/>
  ]

  return (
    <div className="add-token-wrapper">
      <Dialog
        title={<DialogTitle
          id="TOKEN_ADD_DIALOG_TITLE"
          defaultMessage="Add new token" />}
        actions={dialogActions}
        modal={false}
        open={!!open}
        className="dialog-modal add-token-dialog"
        autoDetectWindowHeight={false}
        onRequestClose={onClose}>
        <div className="add-token-content">
          <Form onSubmit={onSubmit}>
            <TrimmingTextField
              className="name"
              floatingLabelText={<FormattedMessage id="NAME" defaultMessage="Name" />}
              value={item.name || ''}
              onChange={(e, v) => props.onApiTokenChange('name', v)}/>
            <TrimmingTextField
              className="description"
              floatingLabelText={<FormattedMessage id="DESCRIPTION_OPTIONAL" defaultMessage="Description (optional)" />}
              value={item.description || ''}
              onChange={(e, v) => props.onApiTokenChange('description', v)}/>
          </Form>
        </div>
      </Dialog>
    </div>
  )
}

export { AddTokenDialog }
