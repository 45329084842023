import * as ActionTypes from '../actions/types'
import { get } from 'lodash'

const initialState =
{
  user: null,
  auth: null,
  staySigned: null,
  ssoToken: null,
  scopes: [],
  isRefreshing: false,
  clientAppLogin: false

  // auth: {
  //   "access_token":"token",
  //   "expires_in":60,
  //   "refresh_expires_in":1800,
  //   "refresh_token":"refresh_token",
  //   "token_type":"bearer",
  //   "id_token":"id_token",
  //   "not-before-policy":0,
  //   "session_state":"d45f9c8a-67d0-4560-835d-d1da049cb725"}
}

const auth = (state = initialState, payload, all) => {
  switch (payload.type) {
    case ActionTypes.APP_INIT:
      return state
    case ActionTypes.AUTH_SIGNED_IN:
      return {
        ...state,
        user: payload.user,
        auth: payload.auth,
        scopes: payload.scopes,
        staySigned: payload.staySigned
      }
    case ActionTypes.AUTH_REFRESHING_TOKEN:
      return { ...state, isRefreshing: true }
    case ActionTypes.AUTH_USER_REFRESHED:
      return { ...state, user: { ...state.user, ...payload.user } }
    case ActionTypes.AUTH_REFRESH_TOKEN_SUCCESS:
      return { ...state, auth: payload.auth, isRefreshing: false }
    case ActionTypes.AUTH_REFRESH_TOKEN_ERROR:
      return { ...state, isRefreshing: false }
    case ActionTypes.SSO_SET_TOKEN:
      return { ...state, ssoToken: payload.token }
    case ActionTypes.USER_SIGNOUT:
      return { ...initialState, clientAppLogin: state.clientAppLogin }
    case ActionTypes.AUTH_EXPIRED:
      return initialState
    case ActionTypes.CLIENT_APP_LOGIN:
      return { ...state, clientAppLogin: true }
    case ActionTypes.CLIENT_APP_LAUNCH:
    case ActionTypes.CLIENT_APP_LAUNCH_ERROR:
      return { ...state, clientAppLogin: false }
    case ActionTypes.PROFILE_UPDATED:
      return { ...state, user: { ...state.user, ...get(payload, 'user', {}) } }
    default:
      return state
  }
}

export default auth
