export const DEFAULT_QUERY = {
  limit: 200,
  skip: 0,
  sortBy: 'nameSortingValue',
  sortAsc: true,
  filter: {},
  filters: []
}

export const PARTICIPANT_TYPES = {
  USER: 'user',
  GROUP: 'group'
}
