import skylight from 'skylight-js-sdk'
import * as ActionTypes from './types'
import { browserPermissions } from './dialog'
import { isCallingSupported } from '../lib/browser'
import { getUserMedia } from '../lib/calls'

const requestDesktopNotifications = () => {
  return Notification.requestPermission().then(res => {
    return {
      name: 'desktop',
      status: res === 'granted' && true
    }
  })
}

const requestMediaPermission = () => {
  return getUserMedia()
    .then(stream => {
      try {
        stream.getTracks().forEach(track => track.stop())
      } catch (e) {
        skylight.logger.error('requestMediaPermission', e)
      }

      return {
        name: 'media',
        status: stream.active
      }
    }).catch(e => {
      skylight.logger.error('requestMediaPermission', e)
      return { name: 'media', status: false }
    })
}

let popupsAvail = false

const requestPopUp = () => {
  return new Promise(resolve => {
    if (popupsAvail) {
      resolve({
        name: 'popup',
        status: true
      })
    }
    const checkUrl = '/assets/ppptest.html'
    window.open(checkUrl, '', 'width=25,height=25')
    window.addEventListener('message', e => {
      if (e.data.scope !== 'popup') return
      popupsAvail = true
      resolve({
        name: 'popup',
        status: true
      })
    })
    setTimeout(() => {
      if (!popupsAvail) {
        resolve({
          name: 'popup',
          status: false
        })
      }
    }, 2000) // wait 2 seconds until realizing that popups are blocked
  })
}

export const checkBrowserPermissions = askAgain => dispatch => {
  if (!isCallingSupported()) {
    return
  }

  dispatch({ type: ActionTypes.CHECK_BROWSER_PERMISSIONS })
  const permissionsWasAsked = localStorage.getItem('permissionsWasAsked')
  const savedPermissions = [
    localStorage.getItem('popup'),
    localStorage.getItem('media'),
    localStorage.getItem('desktop')
  ]

  if (savedPermissions.every(item => item !== null)) {
    dispatch({ type: ActionTypes.SET_PERMISSIONS_STORE, permissions: savedPermissions })
  } else if (askAgain || !permissionsWasAsked) {
    requestPopUp()
      .then(res => {
        dispatch({ type: ActionTypes.SET_PERMISSIONS_DIALOG_TAB, activeTab: !res.status ? 2 : 3 })
        dispatch(browserPermissions())
        localStorage.setItem('permissionsWasAsked', true)
      })
  }
}

export const savePermissions = () => dispatch => {
  dispatch({ type: ActionTypes.SAVE_PERMISSIONS })

  const popUpCheckWithTimeout = () => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(requestPopUp())
      }, 1300)
    })
  }

  return Promise.all([
    requestDesktopNotifications(),
    requestMediaPermission(),
    popUpCheckWithTimeout()
  ]).then(values => {
    values.forEach(item => localStorage.setItem(item.name, item.status))
    dispatch({ type: ActionTypes.SET_PERMISSIONS_STORE, permissions: values })
  })
}

export const setPermissionDialogTab = tabIndex => ({
  type: ActionTypes.SET_PERMISSIONS_DIALOG_TAB,
  activeTab: tabIndex
})

export default {
  onCheckBrowserPermissions: checkBrowserPermissions,
  onSavePermissions: savePermissions,
  onSetPermissionDialogTab: setPermissionDialogTab
}
