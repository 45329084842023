import { CONFIG } from 'skylight-common'
import { isUserOnline } from './presence'

// Added numbers for OOTB sorting by status.
export const CALL_STATUSES = {
  OFFLINE: '0_OFFLINE',
  READY: '1_READY',
  BUSY: '2_BUSY'
}

export const getUserCallStatus = (users) => {
  if (users.length === 0 || !users.some(u => isUserOnline(users, u))) {
    return CALL_STATUSES.OFFLINE
  }

  if (users.some(u => u.status === 'busy')) {
    return CALL_STATUSES.BUSY
  }

  return CALL_STATUSES.READY
}

// Temporary solution until live is released.
export const isLiveEnabled = () => {
  return CONFIG.enableLive
}

export const getUserMedia = async params => {
  try {
    if (window.localStorage.simulateNoVideo) {
      throw new Error('Requested device not found')
    }
    return await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
  } catch (e) {
    if (e.code === 8 ||
      e.name === 'NotFoundError' ||
      (e.message && e.message.includes('Requested device not found'))) { // Need to figure out better way.
      return await navigator.mediaDevices.getUserMedia({ audio: true, video: false })
    }

    throw e
  }
}
