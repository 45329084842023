import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const addApplication = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M14.7866559,13.3940007 L9.85087298,15.5932671 C9.73952521,15.6424347 9.61967761,15.6666019 9.49983,15.6666019 C9.38848223,15.6666019 9.27713446,15.6474348 9.17258655,15.6032673 L1,12.2632023 L1,10.4590006 L9.48453031,13.9249013 L15.7567222,11.1307011 C15.241327,11.7692234 14.8956963,12.545496 14.7866559,13.3940007 Z M15.4723904,16.421852 L9.85087298,18.9266652 C9.73952521,18.9758329 9.61967761,19 9.49983,19 C9.38848223,19 9.27713446,18.980833 9.17258655,18.9366654 L1,15.5966005 L1,13.7923987 L9.48453031,17.2582995 L14.8398064,14.8725763 C14.9534934,15.4324942 15.1712178,15.9556482 15.4723904,16.421852 Z M9.50017,12.3328704 C9.38202236,12.3328704 9.26472471,12.3087032 9.15422692,12.2612023 L1.50437991,8.92780415 C1.19838603,8.79363488 1.00033999,8.49612909 1.00033999,8.16612267 C1.00033999,7.83611626 1.19838603,7.53861047 1.50437991,7.40527455 L9.15422692,4.0718764 C9.37437251,3.9760412 9.6251175,3.9760412 9.84526309,4.0718764 L17.4951101,7.40527455 C17.801954,7.53861047 18,7.83611626 18,8.16612267 C18,8.49612909 17.801954,8.79363488 17.4951101,8.92780415 L9.84526309,12.2612023 C9.7347653,12.3087032 9.61746765,12.3328704 9.50017,12.3328704 Z M24,13 L24,15 L21,15 L21,18 L19,18 L19,15 L16,15 L16,13 L19,13 L19,10 L21,10 L21,13 L24,13 Z" />
    </SvgIcon>
  )
}

export default addApplication
