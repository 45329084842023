import React from 'react'
import { FormattedMessage } from 'react-intl'
import Divider from 'material-ui/Divider'
import { withRouter, Switch, Route } from 'react-router-dom'
import RecoverPasswordRequest from './RecoverPasswordRequest'
import RecoverPasswordSubmit from './RecoverPasswordSubmit'
import { connect } from 'react-redux'
import profileActions from '../../../actions/profile'

import './RecoverPassword.css'

class RecoverPassword extends React.Component {
  getRealmFromUrl = () => new URLSearchParams(this.props.location.search).get('domain')

  componentDidMount() {
    this.props.onRecoverPasswordRealmChange(this.getRealmFromUrl())
  }

  render() {
    const { match } = this.props

    return (
      <div className="recover-password__container">
        <div className="recover-password__content">
          <img className="skylight-logo" src="/assets/img/skylight-logo-blue.png" alt="logo" />
          <div className="recover-password-text">
            <FormattedMessage id="RECOVER_PASSWORD" defaultMessage="Recover password" />
          </div>
          <Divider className="divider" />
          <Switch>
            <Route
              exact
              path={match.url}
              component={RecoverPasswordRequest} />
            <Route
              exact
              path={`${match.url}/submit`}
              component={RecoverPasswordSubmit} />
          </Switch>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  login: state.login
})

const mapActionsToProps = {
  ...profileActions
}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(RecoverPassword))
