import * as ActionTypes from './types'
import { CONFIG } from 'skylight-common'
import skylight from 'skylight-js-sdk'

const DEFAULT_LOCALE = 'en'

export const switchLocale = (id) => (dispatch) => {
  dispatch({ type: ActionTypes.APP_LOCALE_LOAD })

  // Do not change to another locale.
  // TODO: Update once translation languages are added.
  id = DEFAULT_LOCALE

  skylight.http.getJSON(`/assets/i18n/${id}.json?${CONFIG.version}`)
    .then((t) => dispatch({ type: ActionTypes.APP_LOCALE_LOADED, id, translations: t }))
    .catch(e => {
      // Fail silently if locale file was not found and load DEFAULT.
      if (e.status === 404 && id !== DEFAULT_LOCALE) {
        dispatch(switchLocale(DEFAULT_LOCALE))
      } else {
        dispatch({ type: ActionTypes.APP_LOCALE_LOAD_ERROR, error: 'Failed to load translations.' })
      }
    })
}

// /users uses language instead of locale for now,
// hence using both locale and language.
// TODO: Update once /users switches to locale.
export const switchLocaleForUser = (user) => async(dispatch) => {
  user = user ? { ...user, locale: user.locale || user.language } : user

  const locale = (user || {}).locale || await skylight.storage.getItem('locale')
  switchLocale(locale || DEFAULT_LOCALE)(dispatch)

  if (!user || skylight.utils.isFrontline()) return
  if (user.locale) {
    await skylight.storage.setItem('locale', user.locale)
    return
  }

  // `realm` prop might not be set on user.
  // const realm = user.realm || getCurrentRealmName()
  const realmName = user.realm || skylight.auth.getRealmFromToken(skylight.auth.accessToken)
  try {
    const realm = await skylight.domain.getByName(realmName)
    dispatch(switchLocale(locale || DEFAULT_LOCALE))
    if (locale) await skylight.storage.setItem('locale', locale)
    return realm.locale
  } catch (_) {}
}

export default {
  onSwitchLocale: switchLocale,
  onSwitchLocaleForUser: switchLocaleForUser
}
