// TODO: figure out what does this reducer do

const initialState = {
  title: 'Home'
}

const home = (state = initialState, payload, all) => {
  switch (payload.type) {
    default:
      return state
  }
}

export default home
