import React from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import Checkbox from 'material-ui/Checkbox'
// import Toggle from 'material-ui/Toggle'
import { Loading, Form } from 'skylight-common'
import { PinLock } from './PinLock'
import { CaptureQuality } from './CaptureQuality'
import { FooterActionButtons } from '../../../Common'
import './ClientSettings.css'

const SETTINGS_SECTION_PROPS = {
  ALL_DEVICES: [{ name: 'hideLogout', label: { id: 'LOGOUT', default: 'Logout' } }],
  SMART_GLASSES: [
    { name: 'hideBluetoothHID', label: { id: 'BLUETOOTH_HID', default: 'Bluetooth HID' } },
    { name: 'hideBluetoothSPP', label: { id: 'BLUETOOTH_SPP_GATT', default: 'Bluetooth SPP/GATT' } },
    { name: 'hideExitSkylight', label: { id: 'EXIT_SKYLIGHT', default: 'Exit Skylight' } },
    { name: 'hideWiFi', label: { id: 'WIFI_SETTINGS', default: 'WiFi Settings' } },
    { name: 'hideHardwareSettings', label: { id: 'HARDWARE_SETTINGS', default: 'Hardware Settings' } },
    { name: 'hideTroubleShooting', label: { id: 'TROUBLESHOOTING', default: 'Troubleshooting' } }
  ],
  MOBILE: [
    { name: 'hideAssignmentsTab', label: { id: 'ASSIGNMENTS', default: 'Assignments' } },
    { name: 'hideCallingTab', label: { id: 'CALLS', default: 'Calls' } }
  ]
}

const SETTINGS_PROPS = Object.values(SETTINGS_SECTION_PROPS).reduce((acc, v) => acc.concat(v), [])

class ClientSettings extends React.Component {
  componentDidMount() {
    this.props.onRealmLoad()
  }

  // Values seems to be reversed on the API side (hide as opposed to show).
  // hence flipping values according to UI.
  flipValues = (form) => {
    const result = {}
    Object.keys(form).forEach(k => { result[k] = !form[k] })

    return result
  }

  getFormDefaults = () => {
    const form = {}
    SETTINGS_PROPS.forEach(x => {
      form[x.name] = x.defaultValue || false
    })

    return form
  }

  onChange = (type, prop, v) => {
    const value = { ...this.props.realm.item[type] }
    value[prop] = !v // flip by design.

    this.props.onRealmChange(type, value)
  }

  onChangeMobile = (prop, v) => {
    const value = { ...this.props.realm.item.mobileSettings }
    value[prop] = !v // flip by design.

    // According to requirements:
    // An admin can deselect either Calling or Assignments but not both -
    // i.e., at least one option must be checked.
    if ((prop === 'hideAssignmentsTab' || prop === 'hideCallingTab') && !v) {
      const otherProp = prop === 'hideAssignmentsTab' ? 'hideCallingTab' : 'hideAssignmentsTab'
      if (value[otherProp]) {
        value[otherProp] = false
      }
    }

    this.props.onRealmChange('mobileSettings', value)
  }

  onChangeClient = (prop, value) => {
    this.onChange('clientSettings', prop, value)
  }

  onPinChange = (prop, value) => {
    this.props.onRealmChange(prop, value)
  }

  onSave = (e) => {
    e.stopPropagation()
    this.props.onRealmSave(this.props.realm.item)
  }

  onCancel = () => this.props.onRealmCancel()

  render() {
    const { realm } = this.props
    const form = realm.item
      ? this.flipValues({ ...realm.item.clientSettings, ...realm.item.mobileSettings })
      : this.getFormDefaults()

    return (
      <div className={classNames('client-settings', this.props.className)}>
        <Loading
          isLoading={realm.isLoading}
          error={realm.loadError
            ? <FormattedMessage
            id={realm.loadError}
            defaultMessage="Failed to retrieve realm configuration." />
            : null}>
          <Form
            hasChanged={realm.hasChanged}
            onSubmit={this.onSave} onEscapePress={this.onCancel}>
            {/* <div className="settings-section glass-camera">
            <FormattedMessage id="GLASS_CAMERA_TITLE" defaultMessage="Glass camera application" />
            <FormattedMessage id="GLASS_CAMERA_DESCRIPTION" />
            <div className="control-wrapper">
              <Toggle
                name="enableGlassCamera"
                toggled={!!form.enableGlassCamera}
                onToggle={this.onChange} />
            </div>
          </div>
          <div className="settings-section kiosk-mode">
            <FormattedMessage id="KIOSK_MODE_TITLE" defaultMessage="Kiosk mode" />
            <FormattedMessage id="KIOSK_MODE_DESCRIPTION" />
            <div className="control-wrapper">
            <Toggle
              name="enableKiosk"
              toggled={!!form.enableKiosk}
              onToggle={this.onChange} />
            </div>
          </div> */}

            <div className="settings-section settings-menu">
              <div className="section-title">
                <FormattedMessage id="GLOBAL_SETTINGS" defaultMessage="Global Settings" />
              </div>
              <div className="section-content">
                <div className="title">
                  <FormattedMessage id="MENU_VISIBILITY_TITLE" defaultMessage="Menu visibility" />
                </div>
                <div className="description">
                  <FormattedMessage
                    id="MENU_VISIBILITY_DESCRIPTION"
                    defaultMessage="Configure which menu items are visible to users on a given device." />
                </div>
                <div className="control-wrapper">
                  {Object.keys(SETTINGS_SECTION_PROPS).map(k =>
                    <div key={k} className="control-section">
                      <FormattedMessage id={k} />
                      {SETTINGS_SECTION_PROPS[k].map(x => <Checkbox
                        key={x.name}
                        className={`checkbox-control ${form[x.name] ? 'checked' : ''}`}
                        name={x.name}
                        checked={!!form[x.name]}
                        label={<FormattedMessage id={x.label.id} defaultMessage={x.label.default} />}
                        onCheck={(_, v) => {
                          const action = k === 'MOBILE' ? this.onChangeMobile : this.onChangeClient
                          action(x.name, v)
                        }} />)}
                    </div>)}
                </div>
              </div>
            </div>

            <div className="settings-section">
              <div className="section-title">
                <FormattedMessage id="SMART_GLASSES_SETTINGS" defaultMessage="Smart Glasses Settings" />
              </div>
              <div className="section-content">
                <PinLock realm={realm} onChange={this.onPinChange} />
                <CaptureQuality item={realm.item} name="videoCaptureQuality" onChange={this.props.onRealmChange} />
              </div>
            </div>

            {realm.hasChanged && <FooterActionButtons onCancel={this.onCancel} onSave={this.onSave} />}
          </Form>
        </Loading>
      </div>
    )
  }
}

export { ClientSettings }
