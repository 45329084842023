import { getImageFromFile } from './svc'

export const ALLOWED_EXTENSIONS = [
  '.png',
  '.jpg',
  '.jpeg',
  '.bmp',
  '.gif',
  '.pdf'
]

const isPdf = x => x.name.toLowerCase().endsWith('.pdf')

export const onFilesChanged = (props, files) => {
  const { onUploadFile, onSetPdf, onSetSnackbar, onShowMedia } = props
  onSetSnackbar({ source: 'CALL_FILES_DROPPED' })
  let hasError = false
  files = Array.from(files) // FileList -> [File].
  files.forEach(f => {
    const extension = f.name.substring(f.name.lastIndexOf('.')).toLowerCase()
    if (!ALLOWED_EXTENSIONS.includes(extension)) {
      hasError = true
    }
  })

  if (hasError) {
    onSetSnackbar({ source: 'CALL_FILES_ERROR' })
    return
  }

  const pdfFiles = files.filter(isPdf).map(x => ({
    id: x.name,
    file: x
  }))
  if (pdfFiles.length > 1) {
    onSetSnackbar({ source: 'CALL_MULTIPLE_PDF_ERROR' })
    return
  }

  const imgFiles = files.filter(x => !isPdf(x))
  imgFiles.forEach(async x => {
    const img = await getImageFromFile(x)
    img.id = ''
    onUploadFile(img)
  })

  onSetPdf(pdfFiles)
  onShowMedia && onShowMedia(true)
}
