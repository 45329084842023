import React from 'react'
import ParticipantList from './ParticipantList/ParticipantList'

const Permissions = props => {
  return (
    <div className="permissions">
      <ParticipantList />
    </div>
  )
}

export { Permissions }
