import * as ActionTypes from './types'
import { push } from 'react-router-redux'
import { matchPath } from 'react-router-dom'
import { createFilterActions, createItemActions, createListActions, validate } from 'skylight-common'
import skylight from 'skylight-js-sdk'

import { ASSIGNMENT_FILTER_TYPES, ATTACHMENT_DEFAULT_QUERY, DEFAULT_QUERY } from '../lib/assignment'
import {
  archiveAssignment,
  assignWorkflow as assignWorkflowSvc,
  deleteAssignment,
  getAssignmentCards,
  getMyAssignments,
  getPendingAssignments,
  getTeamAssignments,
  removeTask,
  updateAssignmentCard
} from '../svc/assignments'
import { ASSIGNMENT } from '../svc/scopes'
import { ASSIGNMENT_SET_PENDING_TOTAL } from './types'

const makeQuery = (state, params) => {
  const { scopes } = state.auth || {}
  if (!params) {
    const { pathname } = state.router.location
    const match = matchPath(pathname, { path: '/assignments/:type?', exact: true }) || {}
    params = match.params || {}
  }

  return {
    ...state.query,
    params: {
      ...params,
      type: scopes && !scopes.includes(ASSIGNMENT.READ) ? 'my' : params.type,
      isAdmin: scopes && scopes.includes(ASSIGNMENT.DELETE) // Only admin users has delete scope.
    }
  }
}

const loadSvc = async(query, ctx) => {
  // Handle `specific` cases if params is not defined, such as browser `back`.
  // See - https://upskill.atlassian.net/browse/SKY-6378
  if (!query.params) {
    console.warn('query `params` is not defined')
    const { auth, router } = ctx.getState()
    query = makeQuery({ query, auth, router })
  }

  let queryType = query.params && query.params.type
  let load = null

  switch (queryType) {
    case ASSIGNMENT_FILTER_TYPES.MY:
      load = getMyAssignments
      break
    case ASSIGNMENT_FILTER_TYPES.PENDING:
      load = getPendingAssignments
      break
    default:
      load = getTeamAssignments
      queryType = ASSIGNMENT_FILTER_TYPES.TEAM
      ctx.dispatch(checkPendingAssignments())
  }

  return load(query).then(data => {
    const { query: nextQuery, items } = ctx.getState().assignment.list
    // Hack, think of a better solution.
    if (nextQuery.params && nextQuery.params.type !== query.params.type) {
      console.log('Query type mistmach error, returning original items')

      // Think, maybe should throw here? Will result in list error though...
      return items
    }

    data = data || [] // API sends null if no assignments
    return data
    // const { params } = getState().assignment.list.query
    // if (params && params.type && params.type !== queryType) {
    //   return
    // }
  })
}

const remove = async(ids, items) => {
  return await Promise.all(
    items.map((item) => item.isTask ? removeTask(item.task.assignmentId) : deleteAssignment(item.id)))
}

const LIST_ACTIONS = createListActions({
  prefix: ActionTypes.PREFIX_ASSIGNMENT.toUpperCase(),
  handler: ActionTypes.PREFIX_ASSIGNMENT,
  onGetList: s => s.assignment.list,
  onLoad: loadSvc,
  onDelete: remove
})

const init = (state, params) => (dispatch) => {
  const query = makeQuery(state, params)
  const type = query.params && query.params.type

  if (type === 'pending') {
    dispatch({ type: LIST_ACTIONS.types.LIST_UPDATE_QUERY, query })
  } else {
    dispatch(LIST_ACTIONS.onAssignmentLoadItems(query))
  }
}

const FILTER_ACTIONS = createFilterActions({
  baseUrl: '/assignments',
  redirect: false,
  selector: state => state.assignment.list.query,
  listPrefix: ActionTypes.PREFIX_LIST_ASSIGNMENTS,
  actionPrefix: 'Assignment',
  DEFAULT_QUERY,
  loadItems: LIST_ACTIONS.onAssignmentLoadItems,
  push
})

// const redirectToNewQuery = FILTER_ACTIONS.onAssignmentQueryUpdated

const assignWorkflow = (user) =>
  ({ type: ActionTypes.ASSIGNMENT_WORKFLOW_ASSIGN, user })

const doAssignWorkflow = (workflow, users) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.ASSIGNMENT_WORKFLOW_ASSIGNING })
  const assignments = []
  workflow.forEach(wf => {
    users.forEach(user => {
      assignments.push(assignWorkflowSvc(wf, user.id))
    })
  })

  Promise.all(assignments)
    .then(x => {
      dispatch({ type: ActionTypes.ASSIGNMENT_WORKFLOW_ASSIGN_SUCCESS, workflows: workflow, users })
      dispatch(LIST_ACTIONS.onAssignmentLoadItems(getState().assignment.list.query))
    })
    .catch(error => dispatch({ type: ActionTypes.ASSIGNMENT_WORKFLOW_ASSIGN_ERROR, error }))
}

// const removeAssignment = (ids) => {
//   return (dispatch, getState) => {
//     const items = getState().assignment.list.items.filter(x => ids.includes(x.id))
//     dispatch({type: ActionTypes.PREFIX_LIST_ASSIGNMENTS + ActionTypes.LIST_REMOVING})

//     Promise.all(items.map((item) => item.isTask ? removeTask(item.task.assignmentId) : deleteAssignment(item.id)))
//       .then(() => dispatch({type: ActionTypes.PREFIX_LIST_ASSIGNMENTS + ActionTypes.LIST_REMOVED}))
//       .catch((error) => dispatch({
//         type: ActionTypes.PREFIX_LIST_ASSIGNMENTS + ActionTypes.LIST_REMOVE_ERROR,
//         error
//       }))
//       .then(() => {
//         dispatch(loadAssignments(getState().assignment.list.query))
//       })
//   }
// }

const unassignWorkflow = (ids) => (dispatch, getState) => {
  dispatch({ type: ActionTypes.ASSIGNMENT_WORKFLOW_UNASSIGNING })
  Promise.all(ids.map(x => archiveAssignment(x, { assignedTo: '' })))
    .then(() => dispatch({ type: ActionTypes.ASSIGNMENT_WORKFLOW_UNASSIGN_SUCCESS }))
    .catch(error => {
      dispatch({ type: ActionTypes.ASSIGNMENT_WORKFLOW_UNASSIGN_ERROR, error })
    })
    .then(() => {
      dispatch(LIST_ACTIONS.onAssignmentLoadItems(getState().assignment.list.query))
    })
}

const assignmentByApp = app => (dispatch, getState) => {
  const { query } = getState().assignment.list
  const prevFilters = query.filters || []
  const newFilters = prevFilters.includes('name') ? prevFilters : [...prevFilters, 'name']

  dispatch(push({
    pathname: '/assignments',
    query: {
      ...query,
      filter: { ...query.filter, name: [app.id] },
      filters: newFilters
    }
  }))
}

const addDecision = (x, allMedia) => {
  const decision = {
    card: x,
    cardType: 'decision',
    label: x.label,
    cardId: x.id,
    id: x.id,
    required: x.required,
    sequenceId: x.sequenceId,
    assignmentId: x.assignmentId,
    tags: x.tags,
    updatedAt: x.updated,
    choices: Object.keys(x.component.choices || {})
      .map(k => {
        const c = x.component.choices[k]

        return { label: c.label, selected: c.selected }
      })
  }

  decision.media = allMedia
    .filter(m => m.properties && x.id === m.properties.cardId && x.sequenceId === m.properties.sequenceId)
    .map(m => ({ ...x, ...m, card: x, cardType: 'media', mediaType: 'decision' }))

  return decision

  // const p = Promise.resolve([]).then(media => {
  //   d.media =
  //   return d
  // })

  // all.push(p)
}

const addTextInput = (x) => {
  const textInput = {
    card: x,
    cardType: 'textInput',
    label: x.label,
    cardId: x.id,
    id: x.id,
    required: x.required,
    updatedAt: x.updated,
    sequenceId: x.sequenceId,
    assignmentId: x.assignmentId,
    tags: x.tags,
    input: x.layout.input,
    helpText: x.layout.helpText
  }

  return textInput
}

const addMedia = (x, capture, allMedia) => {
  capture = capture.replace('/content', '')
  const id = capture.slice(capture.lastIndexOf('/') + 1)
  const found = allMedia.find(x => x.id === id)
  if (!found) {
    return null
  }
  return { ...x, ...found, tags: x.tags, cardType: 'media', card: x, mediaType: null }
  // const captures = x.component.captures
  //   .map(c => ({
  //     card: x,
  //     cardType: 'media',
  //     id: c.slice(c.lastIndexOf('/') + 1),
  //     cardId: x.id,
  //     required: x.required,
  //     sequenceId: x.sequenceId,
  //     assignmentId: x.assignmentId,
  //     tags: x.tags,
  //   }))

  // captures.map(getMediaFromCapture).forEach(p => all.push(p))
}

export const convertToAttachments = async(assignmentId, items, ctx) => {
  const attachments = []
  let allMedia = await skylight.media.list({
    'prop.assignmentId': assignmentId,
    limit: 1000 // default 30 is just not enough
  })
  allMedia = allMedia.map(x => ({ ...x, name: x.filename })) // Required by download.

  items.forEach(x => {
    if (x.layout && x.layout.layoutType === 'textInput') {
      attachments.push(addTextInput(x))
    }

    const type = x && x.component && x.component.componentType
    switch (type) {
      case 'decision': {
        const decision = addDecision(x, allMedia)
        attachments.push(decision)
        decision.media.forEach(m => attachments.push(m))
        break
      }
      case 'captureAudio':
      case 'capturePhoto':
      case 'captureVideo': {
        const captures = x.component.captures
        if (captures) {
          captures.forEach(mediaCapture => {
            attachments.push(addMedia(x, mediaCapture, allMedia))
          })
        }
        break
      }
      default: break
    }
  })

  if (attachments.some(x => !x)) {
    ctx.dispatch({ type: 'ASSIGNMENT_ATTACHMENT_ITEM_LOAD_ERROR' })
  }

  return attachments.filter(x => !!x)
}

const deleteAttachments = async(ids, items) => {
  // TODO: Update once other attachment types implemented.

  // Only unique cards
  const uniqueCards = []
  items.forEach(x => {
    const { card } = x
    if (!uniqueCards.some(c => c.id === card.id && c.sequenceId === card.sequenceId)) {
      uniqueCards.push(card)
    }
  })

  // Removes only capture type cards
  const cards = uniqueCards
    .filter(x => x.component.captures && x.component.captures.length > 0)
    .map(x => ({
      ...x,
      component: {
        ...x.component,
        captures: x.component.captures
          .filter(c => !ids.some(id => c.includes(id)))
      }
    }))
  console.log('cards', cards)

  // TODO: Probably need to add catch to each properties change/delete to prevent inconsistencies
  // on captures/properties level.
  return await Promise.all(
    // Or replace `saveAttachment()` with `skylight.media.deleteById(x.id)`
    items.map(x => saveAttachment({
      ...x,
      properties: {
        assignmentId: '',
        assignmentName: '',
        cardId: '',
        sequenceId: ''
      }
    })).concat(cards.map(c => updateAssignmentCard(c)))
  )
}

const COMMON_ATTACHMENT = {
  prefix: ActionTypes.PREFIX_ASSIGNMENT_ATTACHMENT.toUpperCase(),
  handler: ActionTypes.PREFIX_ASSIGNMENT_ATTACHMENT,
  onGetList: s => s.assignment.attachment.list
}

const ATTACHMENT_LIST_ACTIONS = createListActions({
  ...COMMON_ATTACHMENT,
  onLoad: async(query, ctx) => {
    const items = await getAssignmentCards(query.id, query)
    return await convertToAttachments(query.id, items, ctx)
  },
  onDelete: deleteAttachments
})

const ATTACHMENT_FILTER_ACTIONS = createFilterActions({
  baseUrl: '/assignments/attachments/:id',
  redirect: false,
  selector: state => state.assignment.attachment.list.query,
  listPrefix: ActionTypes.PREFIX_ASSIGNMENT_ATTACHMENT.toUpperCase(),
  actionPrefix: ActionTypes.PREFIX_ASSIGNMENT_ATTACHMENT,
  DEFAULT_QUERY: ATTACHMENT_DEFAULT_QUERY,
  loadItems: ATTACHMENT_LIST_ACTIONS[`on${ActionTypes.PREFIX_ASSIGNMENT_ATTACHMENT}LoadItems`],
  push
})

const saveAttachment = async(item) => {
  // Get media item to ensure we update only valid media item props.
  // It's important since item argument has a bunch of extra fields.
  const mediaItem = await skylight.media.getItemById(item.id)
  mediaItem.etag = item.etag // Ensure we do not overwrite latest changes.
  // Update only two fields we care about.
  mediaItem.filename = item.name // Known inconsistency between api and web
  mediaItem.description = item.description
  mediaItem.properties = item.properties || mediaItem.properties
  return await skylight.media.updateItem(mediaItem)
}

const validateAttachment = item => {
  const validationFields = [
    { name: 'name', required: true }
  ]

  return validate(item, validationFields)
}

const ATTACHMENT_ITEM_ACTIONS = createItemActions({
  ...COMMON_ATTACHMENT,
  onSave: saveAttachment,
  onValidate: validateAttachment,
  onLoad: async(item) => {
    const latest = await skylight.media.getItemById(item.id)
    return {
      ...item,
      name: latest.filename,
      description: latest.description,
      etag: latest.etag
    }
  },
  onLoadItems: ATTACHMENT_LIST_ACTIONS[`on${ActionTypes.PREFIX_ASSIGNMENT_ATTACHMENT}LoadItems`]
})

const cancelAssignment = () => ({
  type: ActionTypes.ASSIGNMENT_WORKFLOW_CANCEL_ASSIGN
})

const onAssignmentAttachmentLoadRoute = (query) => async(dispatch) => {
  dispatch(ATTACHMENT_LIST_ACTIONS.onAssignmentAttachmentLoadItems(query))
  try {
    dispatch({ type: ActionTypes.ASSIGNMENT_ATTACHMENT_LOAD_PARENT, id: query.id })
    const assignment = await skylight.assignment.getById(query.id)
    dispatch({ type: ActionTypes.ASSIGNMENT_ATTACHMENT_PARENT_LOADED, assignment })
  } catch (error) {
    skylight.logger.error('onAssignmentAttachmentLoadRoute', error)
    dispatch({ type: ActionTypes.ASSIGNMENT_ATTACHMENT_LOAD_PARENT_ERROR, error })
  }
}

const checkPendingAssignments = () => async dispatch => {
  const fakeQuery = { limit: 1, filter: {} }
  const pending = await getPendingAssignments(fakeQuery)
  const pendingTotal = pending.page.total

  if (pendingTotal) {
    dispatch({ type: ASSIGNMENT_SET_PENDING_TOTAL, pendingTotal })
  }
}

export default {
  onInitAssignments: init,
  ...LIST_ACTIONS,
  ...FILTER_ACTIONS,
  ...ATTACHMENT_LIST_ACTIONS,
  ...ATTACHMENT_FILTER_ACTIONS,
  ...ATTACHMENT_ITEM_ACTIONS,
  onAssignWorkflow: assignWorkflow,
  onCancelAssignment: cancelAssignment,
  onWorkflowAssigned: doAssignWorkflow,
  onUnassignWorkflow: unassignWorkflow,
  onAssignmentByApp: assignmentByApp,
  onAssignmentAttachmentLoadRoute
}
