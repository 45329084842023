import React from 'react'
import { FormattedMessage } from 'react-intl'

import './TwoColumnHeader.css'

function TwoColumnHeader({ firstTitleId, secondTitleId }) {
  return (
    <div className="two-column-header-wrapper">
      <div className="two-column-header-item">
        <FormattedMessage id={firstTitleId} defaultMessage="Skylight Attribute" />
      </div>
      <div className="two-column-header-item">
        <FormattedMessage id={secondTitleId} defaultMessage="LDAP Attribute" />
      </div>
    </div>
  )
}

export { TwoColumnHeader }
