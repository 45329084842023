import React from 'react'
import IconMenu from 'material-ui/IconMenu'
import MenuItem from 'material-ui/MenuItem'
import IconButton from 'material-ui/IconButton'
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert'
import PhoneIcon from 'material-ui/svg-icons/communication/phone'
import VideoCamIcon from 'material-ui/svg-icons/av/videocam'
import RemoveIcon from 'material-ui/svg-icons/action/highlight-off'
import './DetailsMenu.css'

const DetailsMenu = () => {
  return (
    <div className="details-menu">
      <IconMenu
        iconButtonElement={<IconButton><MoreVertIcon /></IconButton>}
        anchorOrigin={{ horizontal: 'middle', vertical: 'bottom' }}
        targetOrigin={{ horizontal: 'right', vertical: 'top' }}>
        <MenuItem primaryText="Video Call" leftIcon={<VideoCamIcon />} />
        <MenuItem primaryText="Call" leftIcon={<PhoneIcon />} />
        <MenuItem primaryText="Delete" leftIcon={<RemoveIcon />} />
      </IconMenu>
    </div>
  )
}

export { DetailsMenu }
