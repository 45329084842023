import React from 'react'
import { Dialog, FlatButton } from 'material-ui'
import { FormattedMessage } from 'react-intl'
import WarningIcon from 'material-ui/svg-icons/alert/warning'
import { NEW_USER } from '../Dialogs/dialogTypes'

import { CopyArea } from '../../../Common/CopyArea'

import './TemporaryPasswordDialog.css'

export const TemporaryPasswordDialog = props => {
  const isOpen = () => {
    const { dialogType, dialog } = props

    return !!(dialog[dialogType] || {}).open
  }

  const renderDialogTitle = () => {
    return (
      <div className="temporary-password-dialog-title">
        <WarningIcon className="dialog-icon" />
        <FormattedMessage className="dialog-title" id="TEMPORARY_PASSWORD_TITLE" defaultMessage="Temporary password" />
      </div>
    )
  }

  const onHide = () => {
    props.onHideDialog(props.dialogType)
    props.onHideDialog(NEW_USER)
  }

  const getPassword = () => {
    const dialogParams = props.dialog[props.dialogType].params
    return dialogParams && dialogParams.password
  }

  return (
    <Dialog
      title={renderDialogTitle()}
      open={isOpen()}
      className="dialog-modal temporary-password-dialog"
      contentClassName="temporary-password-content"
      bodyClassName="temporary-password-body"
      actionsContainerClassName="temporary-password-footer"
      autoScrollBodyContent
      onRequestClose={onHide}>
      <div className="temporary-password-body-container">
        <FormattedMessage className="temporary-password-description" id="TEMPORARY_PASSWORD_DESCRIPTION" />
        <CopyArea text={getPassword()} tooltip="COPY_PASSWORD" />
        <div className="step-buttons">
          <FlatButton
            label={<FormattedMessage id="CLOSE" defaultMessage="Close" />}
            disableTouchRipple
            disableFocusRipple
            primary
            onTouchTap={onHide} />
        </div>
      </div>
    </Dialog>
  )
}
