import React from 'react'
import { Form } from 'skylight-common'
import classNames from 'classnames'
import { FooterActionButtons } from '../../../Common'
import { AuthApps } from './AuthApps'
import { TokenSettings } from './TokenSettings'

import './AdminAuth.css'

class AdminAuth extends React.Component {
  componentDidMount() {
    const props = this.props
    props.onRealmLoad()
    props.onAuthAppLoad()
    props.onLdapAppLoad()
  }

  render() {
    const props = this.props
    const { auth } = props
    const { realm, authApp, ldapApp } = auth

    const onSave = () => {
      realm.hasChanged && props.onRealmSave(realm.item)
      authApp.hasChanged && props.onAuthAppSave(authApp.item)
      ldapApp.hasChanged && props.onLdapAppSave(ldapApp.item)
    }

    const onCancel = () => {
      props.onRealmCancel()
      props.onAuthAppCancel()
      props.onLdapAppCancel()
    }

    const hasChanged = realm.hasChanged || authApp.hasChanged || ldapApp.hasChanged

    return (
      <div className={classNames('admin-auth', this.props.className)}>
        <Form hasChanged={hasChanged} onSubmit={onSave} onEscapePress={onCancel}>
          <TokenSettings realm={realm} onChange={props.onRealmChange} />

          <AuthApps
            authApp={authApp}
            ldapApp={ldapApp}
            onChange={props.onAuthAppChange}
            onLdapChange={props.onLdapAppChange}
            onError={props.onAuthAppError} />

          {hasChanged && <FooterActionButtons onCancel={onCancel} onSave={onSave} />}
        </Form>
      </div>
    )
  }
}

export { AdminAuth }
