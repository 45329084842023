import React from 'react'
import { Route } from 'react-router-dom'
import { ActivityTabs } from './ActivityTabs'

const Activities = (props) => {
  const { match } = props
  return <div className="activities">
    <Route
      path={`${match.url}`}
      render={() => <ActivityTabs {...props} type={match.params.type} /> } />
  </div>
}

export { Activities }
