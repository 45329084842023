import * as ActionTypes from '../../actions/types'

const initialState = {
  upload: {}, // { filename  totalBytes  upgradeId }
  upgrades: []
  /*
  [{
    // from application_upgrade_queued to application_upgrade_completed/application_upgrade_errored it should be true
    // and due to upgradeInProgress we can show a pending app version
    inProgress: false,
    // to check if the upgrading version belongs to a selected application
    upgrade: {
      upgradeId,
      applicationId
    }
  }]
  */
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.APPLICATION_UPGRADE_UPLOAD_UPDATE:
      return { ...state, upload: { ...state.upload, ...action.upload } }
    case ActionTypes.APPLICATION_UPGRADE_UPLOAD_RESET:
      return { ...state, upload: {} }
    case ActionTypes.APPLICATION_UPGRADE_SET_UPGRADE_IN_PROGRESS:
      if (action.payload.inProgress) {
        return {
          ...state,
          upgrades: [
            ...state.upgrades,
            action.payload
          ]
        }
      }
      return {
        ...state,
        upgrades: state.upgrades.filter(x => x.upgrade.applicationId !== action.payload.upgrade.applicationId)
      }
    default:
      return state
  }
}
