import React from 'react'
import skylight from 'skylight-js-sdk'
import { Toolbar, ToolbarGroup, ToolbarTitle } from 'material-ui/Toolbar'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { CurrentUserButton, SkylightHelpButton, SwitchPortalVersionButton } from './TopBarActionButton'
import { renderSearchByLocation } from './renderSearchByLocation'
import { ActionButton } from 'skylight-common'
import CircularProgress from 'material-ui/CircularProgress'
import WarningIcon from '../../../Common/Icons/databaseDisconnected'
import { isNextGenUrl } from '../../../../lib/nextGenApplication'
import { canSeeLegacy, canSeeNextGen } from '../../../../svc/scopes'

import './TopBar.css'

const TopBar = (props) => {
  const socket = props.socket
  const nextGenUrl = isNextGenUrl()

  const onNetworkScan = () => {
    props.onNetScanDialog()
  }

  const renderConnectionStatus = () => {
    const checkSocket = canSeeLegacy()
    const isDisconnected = (checkSocket && socket.state !== skylight.socket.STATES.CONNECTED) ||
      props.mqtt.state !== skylight.mqtt.STATES.CONNECTED
    const isConnecting = (checkSocket && socket.state === skylight.socket.STATES.CONNECTING) ||
      props.mqtt.state === skylight.mqtt.STATES.CONNECTING
    const mqttScheduled = props.mqtt.reconnectIn > 0 && props.mqtt.state !== skylight.mqtt.STATES.CONNECTED
    if (!isDisconnected) {
      return null
    }

    let icon = <WarningIcon />
    if (mqttScheduled) {
      icon = <div className="error-text">{props.mqtt.reconnectIn}</div>
    }

    if (isConnecting) {
      icon = <CircularProgress size={18} color="#fff" />
    }

    return <ActionButton
      className="not-connected-button"
      show="always"
      type="icon"
      icon={icon}
      label={<FormattedMessage id="NO_NETWORK" defaultMessage="No network connections. Click to learn more." />}
      onTouchTap={onNetworkScan}
      primary />
  }

  const handleLogoClick = (path) => {
    if (props.router.location.pathname === path) {
      return
    }

    props.history.push(path)
  }

  const renderSkylightLogo = () => {
    let logoSrc = '/assets/img/skylightLogo_white.svg'
    let path = '/'
    if (nextGenUrl) {
      logoSrc = '/assets/img/skylightLogo_black.svg'
      path = '/ng'
    }

    return <div onTouchTap={() => handleLogoClick(path)} className="skylight-logo-container">
      <img
        className="toolbar-title-logo"
        src={logoSrc}
        alt="logo" />
    </div>
  }

  const renderSwitchPortalVersionButton = () => {
    const canSwitchPortalVersion = canSeeLegacy() && canSeeNextGen()
    if (!canSwitchPortalVersion) {
      return null
    }

    return <SwitchPortalVersionButton {...props} />
  }

  const renderToolbarTitleSection = () => {
    return <div className="toolbar-title-section">
      {renderSwitchPortalVersionButton()}
      {renderSkylightLogo()}
    </div>
  }

  return (
    <div className={classNames('top-bar', { 'next-gen': nextGenUrl })}>
      <Toolbar className="main-toolbar">
        <ToolbarTitle className="toolbar-title" text={renderToolbarTitleSection()} />
        <ToolbarGroup className="toolbar-component">
          {renderSearchByLocation(props.location.pathname)}
          <SkylightHelpButton nextGen={nextGenUrl} {...props} />
          <CurrentUserButton {...props} />
          {renderConnectionStatus()}
        </ToolbarGroup>
      </Toolbar>
    </div>
  )
}

export { TopBar }
