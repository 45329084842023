import { combineReducers } from 'redux'
import apiToken from './apiToken'
import auth from './auth'
import live from './live'

export default {
  live,
  apiToken: combineReducers(apiToken),
  auth: combineReducers(auth)
}
