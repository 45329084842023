import React from 'react'
import { GridView, shiftSelect } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import { ApplicationTile } from './ApplicationTile'
import { COLUMNS } from './columns'
import './ApplicationGrid.css'

const ApplicationGrid = (props) => {
  const { list } = props
  const column = COLUMNS.find(x => x.name === list.query.sortBy) || {}

  const onSelect = (v) => {
    props.onWorkflowSelectItems(Array.isArray(v) ? v : [v])
  }

  const onShiftSelect = (id) => {
    const { items, selectedIds } = list
    const result = shiftSelect(id, selectedIds, { items, keyProp: 'id' })
    props.onWorkflowSelectItems(result)
  }

  return (
    <div className="application-grid">
      <GridView
        query={list.query}
        renderItem={<ApplicationTile
          status={props.status}
          renderActions={props.renderItemActions}
          onShiftSelect={onShiftSelect}
          onOpenApplication={props.onOpenApplication}
          onAssignmentByApp={props.onAssignmentByApp} />}
        heading={props.heading}
        onSelectItems={props.onWorkflowSelectItems}
        isLoading={list.isLoading}
        items={list.items}
        groupBy={column.groupBy}
        groupHeader={column.groupHeader}
        done={list.done}
        useFixedHeight
        selectedIds={list.selectedIds}
        cols={4}
        total={list.total}
        gridFooter={props.listFooter}
        emptyLabel={<FormattedMessage
          id="EMPTY_WORKFLOW_LIST"
          defaultMessage="No applications are created yet" />
        }
        onQuery={props.onWorkflowQuery}
        onCheck={onSelect} />
    </div>
  )
}

export { ApplicationGrid }
