import skylight from 'skylight-js-sdk'
import dialogActions, { hideUpgradeDialog } from './dialog'
import * as ActionTypes from './types'
import snackbarActions from './snackbar'
import versionActions from './application/versions'
import { updateSelectedApplication } from './nextGenApplication'
import { versionUpgradeIsFine, VERSION_STAGES } from '../lib/application/version'

let abortController = null

const updateApplicationUpgradeUpload = upload => ({
  type: ActionTypes.APPLICATION_UPGRADE_UPLOAD_UPDATE,
  upload
})

export const applicationUpgrade = ({
  file,
  components,
  comment
}) => async(dispatch, getState) => {
  const state = getState()
  const application = state.application.selectedApplication
  // stage is rewritten in svc/versions and could be 'inactive',
  // if the latest draft has errored or ongoing upgrade
  // that's why we need to find a version with original draft stage
  const draft = state.application.version.list.items.find(
    x => x.originalStage === 'draft' &&
    !x.upgrade
  )

  const body = {
    applicationId: application.applicationId,
    filename: file.name,
    totalBytes: file.size,
    components,
    fromVersion: draft.version,
    comment
  }
  const appUpgrade = await skylight.we20.upgrade.create(body)
  const version = await skylight.we20.version.getById(application.applicationId, appUpgrade.version)

  dispatch(versionActions.onVersionPrependItems([{
    ...version,
    id: version.version,
    stage: VERSION_STAGES.UPGRADE_PENDING
  }]))

  dispatch(hideUpgradeDialog())

  const mediaItem = {
    filename: file.name,
    totalBytes: file.size,
    contentType: file.type,
    title: file.name
  }

  dispatch(updateApplicationUpgradeUpload({
    filename: mediaItem.filename,
    totalBytes: mediaItem.totalBytes,
    upgradeId: appUpgrade.upgradeId
  }))
  dispatch(dialogActions.showUpgradeUploadDialog())

  const onProgress = progress => {
    const patch = { uploadedBytes: progress.uploadedBytes }
    dispatch(updateApplicationUpgradeUpload(patch))
  }

  const uploader = skylight.we20.upgrade.media.uploader(application.applicationId, appUpgrade.upgradeId, mediaItem, null, onProgress)
  abortController = new AbortController()

  try {
    await uploader.uploadFile(file, abortController)
  } catch (e) {
    if (e.name === 'AbortError') {
      dispatch(versionActions.onDeleteVersionUpgrade(appUpgrade))
      dispatch(resetApplicationUpgradeUpload())
      return
    }

    dispatch(setUpgradeInProgress(appUpgrade, false))
    dispatch(updateApplicationUpgradeUpload({ error: true }))
    dispatch(snackbarActions.showUpgradeError())
    return
  }

  // create pending version
  // setUpgradeInProgress & blockBuilderButton is in mqtt actions
  dispatch(dialogActions.hideUpgradeUploadDialog())
  dispatch(snackbarActions.showUpgradingApplicationSnackbar())
}

export const afterApplicationUpgrade = upgrade => async(dispatch, getState) => {
  const state = getState()
  const application = state.application.selectedApplication
  if (application.applicationId !== upgrade.applicationId) return
  const version = {
    ...state.application.version.list.items.find(x => x.version === upgrade.version),
    upgrade
  }
  version.stage = VERSION_STAGES.DRAFT
  if (versionUpgradeIsFine({ upgrade })) {
    delete version.upgrade
  }
  dispatch(versionActions.onVersionListItemUpdate(version))
  dispatch(updateSelectedApplication(upgrade.applicationId))
}

const resetApplicationUpgradeUpload = () => ({ type: ActionTypes.APPLICATION_UPGRADE_UPLOAD_RESET })

export const setUpgradeInProgress = (upgrade, inProgress) => ({
  type: ActionTypes.APPLICATION_UPGRADE_SET_UPGRADE_IN_PROGRESS,
  payload: { inProgress, upgrade }
})

const cancelUpgradeUpload = () => async(dispatch, getState) => {
  const { upload } = getState().application.applicationUpgrade
  dispatch(dialogActions.hideUpgradeUploadDialog())

  if (upload.error) {
    dispatch(resetApplicationUpgradeUpload())
    dispatch(versionActions.onDeleteVersionUpgrade({
      upgradeId: upload.upgradeId,
      applicationId: getState().application.selectedApplication.applicationId
    }))
    return
  }

  if (abortController) abortController.abort()
  abortController = null
}

export default {
  onCancelImportUpload: cancelUpgradeUpload
}
