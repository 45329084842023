import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const sessionDisconnected = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M15.1456311,1 L16.8932039,2.73786408 L13,6.62135922 L14.3786408,8 L18.2621359,4.11650485 L20,5.85436893 L20,1 M10,8 C6.14,8 3,11.13 3,15 C3,18.8659932 6.13400675,22 10,22 C13.86,22 17,18.87 17,15 C17,11.1340068 13.8659932,8 10,8 M10,10 C12.7525773,10 15,12.2371134 15,15 C15,16.3260824 14.4732158,17.597852 13.5355339,18.5355339 C12.597852,19.4732158 11.3260824,20 10,20 C7.2371134,20 5,17.7628866 5,15 C5,12.2385763 7.23857625,10 10,10 M9,12 L9,16.0036166 L12.2385787,18 L13,16.5895118 L10.5228426,15.0596745 L10.5228426,12" id="path-1"></path>
    </SvgIcon>
  )
}

export default sessionDisconnected
