import React from 'react'
import { FormattedMessage } from 'react-intl'
import { formatDateTime } from 'skylight-common'
import { CallStatus } from '../CallStatus'
import { secondsToTimeString } from '../../../../../lib/date'
import { UserDetailsLink } from '../../../../../containers/common'
import { DeviceInfo } from './DeviceInfo'
import './CallDetails.css'

const CALL_PROPS = {
  id: { order: 0, label: { id: 'ACTIVITY_CALL_ID', default: 'Call ID' } },
  status: { order: 1, label: { id: 'ACTIVITY_CALL_STATUS', default: 'Status' }, render: item => <CallStatus status={item.status} /> },
  callerId: {
    order: 2,
    label: { id: 'ACTIVITY_CALL_CALLER', default: 'Caller' },
    render: item => {
      return <UserDetailsLink userId={item.callerId} />
    }
  },
  callerDevice: {
    order: 3,
    label: { id: 'ACTIVITY_CALL_CALLER_DEVICE', default: 'Caller device' },
    render: item => {
      return <DeviceInfo deviceType={item.callerDevice} localData={item.callerData.device} remoteData={item.receiverData.device} />
    }
  },
  receiverId: {
    order: 4,
    label: { id: 'ACTIVITY_CALL_RECEIVER', default: 'Receiver' },
    render: item => {
      return <UserDetailsLink userId={item.receiverId} />
    }
  },
  receiverDevice: {
    order: 5,
    label: { id: 'ACTIVITY_CALL_RECEIVER_DEVICE', default: 'Receiver device' },
    render: item => {
      return <DeviceInfo deviceType={item.receiverDevice} localData={item.receiverData.device} remoteData={item.callerData.device} />
    }
  },
  duration: {
    order: 6,
    label: { id: 'ACTIVITY_CALL_DURATION', default: 'Duration' },
    render: item => {
      return secondsToTimeString(item.duration)
    }
  },
  date: {
    order: 7,
    label: { id: 'ACTIVITY_CALL_DATE', default: 'Date' },
    render: item => {
      return formatDateTime(item.date)
    }
  }
}

const getProps = (item) => {
  const props = []

  for (const prop in item) {
    if (!item.hasOwnProperty(prop)) continue

    const propObj = { prop: prop }
    const callProp = CALL_PROPS[prop]

    if (!callProp) {
      continue
    }

    props.push({ ...propObj, ...callProp })
  }

  // Show known properties in specified order - we'll show the rest in the
  // order received.
  return props.sort((a, b) => { return a.order - b.order })
}

const CallDetails = (props) => {
  const item = props.item.item

  const renderProp = (u) => {
    return <div key={u.prop} className="detail-item">
      <div className="detail-item-title">
        <FormattedMessage className="detail-item-label"
          id={u.label.id} defaultMessage={u.label.default} />
      </div>
      <div className="detail-item-value">
        {u.render ? u.render(item) : item[u.prop]}
      </div>
    </div>
  }

  return (
    <div className="call-details">
      {getProps(item).map(renderProp)}
    </div>
  )
}

export { CallDetails }
