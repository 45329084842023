let loadPromise = null
let pdfJs = null

export function loadPdfScript() {
  if (loadPromise) {
    return loadPromise
  }

  loadPromise = new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.onload = () => {
      pdfJs = window.pdfjsLib
      pdfJs.GlobalWorkerOptions.workerSrc = '/assets/pdf/pdf.worker.js'
      resolve(pdfJs)
    }
    script.onerror = reject
    script.src = '/assets/pdf/pdf.js'
    document.body.appendChild(script)
  })

  return loadPromise
}
