import skylight from 'skylight-js-sdk'

export const PERMISSIONS = skylight.we20.application.permissions

export const DEFAULT_QUERY = {
  view: 'grid',
  limit: 200,
  skip: 0,
  sortBy: 'modifiedAt',
  sortAsc: false,
  filter: { name: [], status: ['active'] },
  filters: ['name', 'status']
}

export const PICKER_QUERY = {
  sortBy: 'name',
  sortAsc: true
}

export const isReadonlyApplication = (applicationId, allAppsPermissions) => {
  const appPermissions = allAppsPermissions[applicationId] || {}
  return !hasPermission(appPermissions, PERMISSIONS.VERSION.UPDATE)
}

export const hasPermission = (appPermissions = {}, permission) => {
  return !!appPermissions[permission]
}

export const DEFAULT_APP_ICON_CODE = 'e913'
