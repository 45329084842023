import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedDateTime, CursorTooltip } from 'skylight-common'
import { formatDate } from '../../../../../../../lib/date'
import UserDetailsLink from '../../../../../Contacts/UserDetails/UserDetailsLink'

const COLUMNS = [
  {
    name: '_id',
    title: 'SESSION_ID',
    groupBy: x => x.sessionId,
    render: item => (
      <CursorTooltip tooltip={item.sessionId}>
        <Link
          to={`sessions/${item.sessionId}/events`}
          className="nav-link">
          {item.sessionId}
        </Link>
      </CursorTooltip>
    )
  },
  {
    name: 'name',
    title: 'NAME',
    render: x => x.name,
    groupBy: x => x.name
  },
  {
    name: 'createdBy',
    title: 'CREATED_BY',
    render: item => <UserDetailsLink nextGen userId={item.createdBy} />,
    groupBy: x => x.createdBy,
    sortable: false
  },
  {
    name: 'createdAt',
    title: 'CREATED',
    render: x => <FormattedDateTime value={x.createdAt} />,
    groupBy: x => formatDate(new Date(x.createdAt))
  },
  {
    name: 'status',
    title: 'STATUS',
    sortable: false,
    render: x => x.status ? `${x.status[0].toUpperCase()}${x.status.slice(1)}` : '',
    groupBy: x => x.status
  },
  {
    name: 'modifiedBy',
    title: 'UPDATED_BY',
    render: item => <UserDetailsLink nextGen userId={item.modifiedBy} />,
    groupBy: x => x.modifiedBy,
    sortable: false
  },
  {
    name: 'modifiedAt',
    title: 'UPDATED_TIME',
    render: x => <FormattedDateTime value={x.modifiedAt} />,
    groupBy: x => formatDate(new Date(x.modifiedAt))
  }
]

export { COLUMNS }
