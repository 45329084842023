const ACTIVE_STATUSES = ['online', 'away']

export const getActiveSessions = sessions => {
  return (sessions || []).filter(session => ACTIVE_STATUSES.includes(session.presence))
}

// Determine if a given user appears in list of online sessions
export const isUserOnline = (users, user) => {
  const userId = user.id || user.userId
  return users.some(u => u.userId.includes(userId))
}
