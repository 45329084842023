import React from 'react'
import { OutlinedTextField } from '../OutlinedTextField'
import withHovered from '../../HOCs/withHovered'
import EyeOn from '../../Icons/EyeOn'
import EyeOff from '../../Icons/eyeOff'
import { CursorTooltip } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'

import './OutlinedPasswordField.css'

class OutlinedPasswordField extends React.Component {
  state = {
    showPassword: false
  }

  onShowPassword = () => {
    this.setState({ showPassword: true })
  }

  onHidePassword = () => {
    this.setState({ showPassword: false })
  }

  renderEyeIcon = hovered => {
    if (this.state.showPassword) {
      return <CursorTooltip tooltip={<FormattedMessage id="HIDE_PASSWORD" defaultMessage="Hide password" />}>
        <EyeOff onTouchTap={this.onHidePassword} className="eye-icon eye-off" />
      </CursorTooltip>
    }

    if (hovered) {
      return <CursorTooltip tooltip={<FormattedMessage id="SHOW_PASSWORD" defaultMessage="Show password" />}>
        <EyeOn onTouchTap={this.onShowPassword} className="eye-icon eye-on" />
      </CursorTooltip>
    }

    return null
  }

  render() {
    const { hoverIn, hoverOut, hovered, className, ...other } = this.props
    const type = this.state.showPassword ? 'text' : 'password'

    return (
      <OutlinedTextField
        {...other}
        className={classNames('outlined-password-field', className)}
        type={type}
        onMouseOver={hoverIn}
        onMouseLeave={hoverOut}>
        {this.renderEyeIcon(hovered)}
      </OutlinedTextField>
    )
  }
}

export default withHovered(OutlinedPasswordField)
