import React, { Component } from 'react'
import { Switch, Route } from 'react-router'
import { CallEventList } from '../../../../containers/activity'
import { CallReports } from '../CallReports'
import { CallDataDetails } from './CallDataDetails'

import './CallData.css'

class CallData extends Component {
  state = {
    item: {}
  }

  componentDidMount() {
    const { match, item } = this.props
    if (!item.item) {
      this.props.onCallLoad(match.params.callId)
    }
  }

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    const { item } = nextProps.item
    if (item && item.id) {
      this.setState({ item: nextProps.item.item })
    }
  }

  render() {
    const { match } = this.props

    return <div className="call-data">
      <div className="call-data-details">
        <CallDataDetails item={this.state.item} />
      </div>
      <Switch>
        <Route
          path={`${match.path}/events`}
          component={CallEventList} />
        <Route
          path={`${match.path}/reports`}
          component={CallReports} />
      </Switch>
    </div>
  }
}

export { CallData }
