import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const callFailed = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M23.71,16.7437923 C20.66,13.7979684 16.54,12 12,12 C7.46,12 3.34,13.7979684 0.29,16.7437923 C0.11,16.9266366 0,17.1805869 0,17.4650113 C0,17.7392777 0.11,17.993228 0.29,18.1760722 L2.77,20.6952596 C2.95,20.8781038 3.2,21 3.5,21 C3.75,21 4,20.8882619 4.18,20.7054176 C4.97,19.9537246 5.86,19.3239278 6.84,18.8261851 C7.17,18.6636569 7.4,18.3182844 7.4,17.9119639 L7.4,14.7629797 C8.85,14.285553 10.39,14.0316027 12,14.0316027 C13.6,14.0316027 15.15,14.285553 16.6,14.7629797 L16.6,17.9119639 C16.6,18.3182844 16.83,18.6636569 17.16,18.8261851 C18.14,19.3239278 19.03,19.9537246 19.82,20.7054176 C20,20.8882619 20.25,21 20.5,21 C20.8,21 21.05,20.8781038 21.23,20.6952596 L23.71,18.1760722 C23.89,17.993228 24,17.7392777 24,17.4650113 C24,17.1805869 23.89,16.9266366 23.71,16.7437923 L23.71,16.7437923 Z M6.5,5.5 L12,11 L19,4 L18,3 L12,9 L7.5,4.5 L11,4.5 L11,3 L5,3 L5,9 L6.5,9 L6.5,5.5 Z" id="path-1"></path>
    </SvgIcon>
  )
}

export default callFailed
