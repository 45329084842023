import React from 'react'
import { connect } from 'react-redux'
import { TextFilterNextGen } from '../../../../Common/NextGen/TextFilterNextGen'
import { GroupFilter } from './GroupFilter'
import contactActions from '../../../../../actions/contact'
import dialogActions from '../../../../../actions/dialog'

const VALUE = 'title'

const GroupTextFilter = props => {
  const onChange = (v) => {
    const filter = { ...props.query.filter, [VALUE]: v || '' }
    props.onGroupFilterUpdated(filter)
  }

  return <TextFilterNextGen
    className="group-text-filter"
    title="PLACEHOLDER_GROUPNAME"
    value={props.query.filter[VALUE]}
    iconFilter={<GroupFilter {...props} />}
    onChange={onChange} />
}

export { GroupTextFilter }

const mapStateToProps = (state) => ({
  query: state.contact.groups.query,
  layout: state.layout,
  authUser: state.auth.user
})

const mapDispatchToProps = {
  ...contactActions,
  onConfirm: dialogActions.onConfirm
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupTextFilter)
