import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const TimeUpdate = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M20.9987834,10.12 L14.2187834,10.12 L16.9587834,7.3 C14.2287834,4.6 9.80878343,4.5 7.07878343,7.2 C4.34878343,9.91 4.34878343,14.28 7.07878343,16.99 C9.80878343,19.7 14.2287834,19.7 16.9587834,16.99 C18.3187834,15.65 18.9987834,14.08 18.9987834,12.1 L20.9987834,12.1 C20.9987834,14.08 20.1187834,16.65 18.3587834,18.39 C14.8487834,21.87 9.14878343,21.87 5.63878343,18.39 C2.13878343,14.92 2.10878343,9.28 5.61878343,5.81 C9.12878343,2.34 14.7587834,2.34 18.2687834,5.81 L20.9987834,3 L20.9987834,10.12 Z M12.4987834,8 L12.4987834,12.25 L15.9987834,14.33 L15.2787834,15.54 L10.9987834,13 L10.9987834,8 L12.4987834,8 Z" />
    </SvgIcon>
  )
}

export default TimeUpdate
