import React from 'react'
import { FormattedMessage } from 'react-intl'
import AddIcon from 'material-ui/svg-icons/content/add'
import UploadIcon from 'material-ui/svg-icons/file/file-upload'
import { SETUP_NEW_APP_TEMPLATE_NAMES } from '../../../../../../lib/nextGenApplication'
import FlatButton from 'material-ui/FlatButton'

import './TemplateSelection.css'

const SETUP_TEMPLATES = {
  [SETUP_NEW_APP_TEMPLATE_NAMES.BLANK_APP]: {
    name: { id: 'BLANK_APP', defaultMessage: 'Blank app' },
    description: { id: 'BLANK_APP_DESCRIPTION', defaultMessage: 'Start with a blank app.' },
    icon: <AddIcon />
  },
  [SETUP_NEW_APP_TEMPLATE_NAMES.IMPORT_FILE]: {
    name: { id: 'IMPORT_FILE', defaultMessage: 'Import file' },
    description: { id: 'IMPORT_FILE_DESCRIPTION', defaultMessage: 'Upload a saved application file.' },
    icon: <UploadIcon />
  }
}

const TemplateSelection = ({ onUpdateCreateAppTemplate, onUpdateCreateAppStep }) => {
  const handleTemplateSelected = template => {
    onUpdateCreateAppTemplate(template)
    onUpdateCreateAppStep(2)
  }

  const renderAvailableTemplates = () => {
    return Object.keys(SETUP_TEMPLATES).map(key => {
      const template = SETUP_TEMPLATES[key]

      return (
        <FlatButton key={key} className="template-item" onTouchTap={() => handleTemplateSelected(key)}>
          <div className="template-item-icon-wrapper">
            {template.icon}
          </div>
          <div className="template-item-info">
            <div className="template-item-name">
              <FormattedMessage {...template.name} />
            </div>
            <div className="template-item-description">
              <FormattedMessage {...template.description} />
            </div>
          </div>
        </FlatButton>
      )
    })
  }

  return (
    <div className="template-selection-container">
      <div className="create-new-app-invitation">
        <FormattedMessage id="CREATE_NEW_APPLICATION" defaultMessage="Create a new application" />
      </div>
      <div className="start-app-development">
        <FormattedMessage id="START_APP_DEVELOPMENT" defaultMessage="Start your app development from an imported file or create it from scratch." />
      </div>
      <div className="templates-wrapper">
        {renderAvailableTemplates()}
      </div>
    </div>
  )
}

export { TemplateSelection }
