import { CONFIG, storage } from 'skylight-common'

export const SEEN_VERSION_KEY = 'seenChanges'

let isReleaseSeen = null

export const isReleaseItemSeen = (key) => {
  const lastSeenVersion = storage.loadItem(key)

  return lastSeenVersion && CONFIG.productVersion === lastSeenVersion
}

export const isNewReleaseSeen = () => {
  if (isReleaseSeen === null) {
    isReleaseSeen = isReleaseItemSeen(SEEN_VERSION_KEY)
  }

  return isReleaseSeen
}

export const markSeenRelease = () => {
  isReleaseSeen = CONFIG.productVersion
  storage.saveItem(SEEN_VERSION_KEY, CONFIG.productVersion)
}
