import React from 'react'
import MediaIcon from 'material-ui/svg-icons/image/photo'
import MessageIcon from 'material-ui/svg-icons/communication/forum'
import MuteIcon from 'material-ui/svg-icons/av/mic'
import UnmuteIcon from 'material-ui/svg-icons/av/mic-off'
import RejectIcon from 'material-ui/svg-icons/communication/call-end'
import { CallButton } from '../../Common'
import { SnapshotIcon } from '../../Common/Icons'
import callSdk from 'skylight-call-sdk'
import './FooterButtons.css'

class FooterButtons extends React.Component {
  render() {
    const props = this.props
    const isMuted = !props.call.media.avState.audio
    const isMainRemote = props.call.ui.media.mainStreamType === callSdk.constants.STREAM_TYPES.REMOTE

    const onReject = () => props.incomingCall(false)

    const onMessage = () => props.showMessage(true)
    const onMuteOrUnmute = () => props.toggleMedia(isMuted, 'audio', false)
    const onSnapshot = () => {
      isMainRemote ? props.requestSnapshot(false) : props.takeSnapshot()
    }
    const onViewMedia = () => props.setShowMedia(true)

    const buttons = [
      {
        action: onMessage,
        icon: <MessageIcon />,
        tooltip: 'CALL_MESSAGE',
        name: 'message'
      },
      {
        action: onMuteOrUnmute,
        icon: isMuted ? <UnmuteIcon /> : <MuteIcon />,
        tooltip: isMuted ? 'CALL_UNMUTE' : 'CALL_MUTE',
        name: isMuted ? 'unmute' : 'mute'
      },
      {
        action: onSnapshot,
        icon: <SnapshotIcon />,
        tooltip: 'CALL_SNAPSHOT',
        name: 'snapshot',
        disabled: !callSdk.lib.hasVideoTrack(isMainRemote ? 'remote' : 'local') ||
          callSdk.lib.ui.isFileTransfering(props.call)
      },
      {
        action: onViewMedia,
        name: 'media',
        tooltip: 'CALL_VIEW_MEDIA',
        icon: <MediaIcon />
      },
      {
        action: onReject,
        icon: <RejectIcon />,
        tooltip: 'CALL_HANGUP',
        name: 'reject'
      }
    ]

    return (
      <div className="footer-buttons">
        {buttons.map(x =>
          <CallButton
            className={`call-fab-tooltip ${x.name}`}
            disabled={x.disabled}
            key={x.name}
            icon={x.icon}
            tooltip={x.tooltip}
            onTouchTap={x.action} />)}
      </div>
    )
  }
}

export { FooterButtons }
