import * as ActionTypes from '../../actions/types'

const initialState = {
  isLoading: false,
  error: null,
  items: []
}

const groupsWithMembers = (state = initialState, payload) => {
  switch (payload.type) {
    case ActionTypes.GROUPS_MEMBERS_LOAD:
      return { ...state, isLoading: true }
    case ActionTypes.GROUPS_MEMBERS_LOADED:
      return { ...state, items: payload.items, isLoading: false }
    case ActionTypes.GROUPS_MEMBERS_LOAD_ERROR:
      return { ...state, error: payload.error, isLoading: false }
    default:
      return state
  }
}

export default groupsWithMembers
