import React from 'react'
import { connect } from 'react-redux'
import { TextFilterNextGen } from '../../../../Common/NextGen/TextFilterNextGen'
import contactActions from '../../../../../actions/contact'
import { UserFilter } from './UserFilter'

const VALUE = 'title'

const UserTextFilter = props => {
  const onChange = (v) => {
    const filter = { ...props.query.filter, [VALUE]: v || '' }
    props.onUserFilterUpdated(filter)
  }

  return <TextFilterNextGen
    className="user-text-filter"
    title="PLACEHOLDER_USERNAME"
    value={props.query.filter[VALUE]}
    iconFilter={<UserFilter {...props} />}
    onChange={onChange} />
}

export { UserTextFilter }

const teamFilter = (state) => ({
  query: state.contact.users.query,
  layout: state.layout,
  authUser: state.auth.user
})

export default connect(teamFilter, contactActions)(UserTextFilter)
