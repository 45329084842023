import React from 'react'
import { ByList } from 'skylight-common'
import { EVENT_TYPES } from '../../../../svc/activities'

const ByEventName = props => {
  return (
    <ByList
      className="by-event-type"
      title="ACTIVITY_EVENT_NAME"
      value={props.value}
      enableSorting={true}
      onChange={props.onChange}
      onRemove={props.onRemove}
      onGetItems={() => Promise.resolve(Object.keys(EVENT_TYPES).map(k => ({ text: k, value: k })))}/>
  )
}

export { ByEventName }
