import * as ActionTypes from '../../../actions/types'

const initialState = null

const parent = (state = initialState, payload) => {
  switch (payload.type) {
    case ActionTypes.ASSIGNMENT_ATTACHMENT_LOAD_PARENT:
      return { id: payload.id }
    case ActionTypes.ASSIGNMENT_ATTACHMENT_PARENT_LOADED:
      return { ...payload.assignment }
    default:
      return state
  }
}

export default parent
