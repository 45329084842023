import React from 'react'
import LinearProgress from 'material-ui/LinearProgress'
import { FormattedMessage } from 'react-intl'
import { CONFIG } from 'skylight-common'

import './Redirect.css'

const REDIRECT_MAP = {
  builder: 'LAUNCH_BUILDER_APP',
  builder2: 'LAUNCH_BUILDER_APP',
  appweb: 'LAUNCH_APP_WEB',
  inspector: 'LAUNCH_INSPECTOR',
  help: 'LAUNCH_HELP_APP',
  error: 'LAUNCH_APP_ERROR_TEXT'
}

function Redirect(props) {
  const { path } = props.match.params
  const appTitle = REDIRECT_MAP[path] || 'LAUNCH_UNKNOWN_APP'

  return (
    <div className={`redirect ${path === 'error' ? 'error' : ''}`}>
      <img className="logo" src="/assets/img/skylight-logo.png" alt="logo" />
      <FormattedMessage id={appTitle} defaultMessage="Launching application" />
      {path === 'error'
        ? (
          <a href={CONFIG.webUrl}>
            <FormattedMessage id="LAUNCH_APP_ERROR_LINK" defaultMessage="Go back to the Web Portal" />
          </a>
          )
        : <LinearProgress className="redirect-loading" mode="indeterminate" />}
    </div>
  )
}

export default Redirect
