import React from 'react'
import { FormattedMessage } from 'react-intl'
import { OutlinedTextField } from '../../../../Common/Form/OutlinedTextField'
import { FormSection } from '../../../../Common/Form/FormSection'

const AccountPhone = (props) => {
  return (
    <FormSection
      className="account-phone"
      title={<FormattedMessage id="PHONE" defaultMessage="Phone" />} >
      <div className="form-fields">
        <div className="form-fields-row">
          <OutlinedTextField
            className="form-field"
            name="officePhone"
            value={props.form.officePhone || ''}
            floatingLabelText={
              <FormattedMessage id="ACCOUNT_OFFICE" defaultMessage="Office" />
            }
            onChange={props.onChange} />
        </div>
        <div className="form-fields-row">
          <OutlinedTextField
            className="form-field"
            name="mobilePhone"
            value={props.form.mobilePhone || ''}
            floatingLabelText={
              <FormattedMessage id="MOBILE" defaultMessage="Mobile" />
            }
            onChange={props.onChange} />
        </div>
      </div>
    </FormSection>
  )
}

export { AccountPhone }
