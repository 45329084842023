import React from 'react'
import { TeamTextFilter, TeamGroupTextFilter } from '../../../../containers/users'
import { CallTextFilter } from '../../../../containers/callUser'
import { ApplicationTextFilter } from '../../../../containers/applications'
import { AssignmentTextFilter, MyAssignmentTextFilter, AttachmentTextFilter } from '../../../../containers/assignment'
import { MediaTextFilter } from '../../../../containers/media'
import { ActivityTextFilter, CallTextFilter as ActivityCallTextFilter } from '../../../../containers/activity'

import { TokenTextFilter } from '../../../../containers/admin'
import { matchPath } from 'react-router-dom'

const FILTER_LIST = {
  '/team': <TeamTextFilter />,
  '/team/users': <TeamTextFilter />,
  '/team/groups': <TeamGroupTextFilter />,
  '/calls': <CallTextFilter />,
  '/applications': <ApplicationTextFilter />,
  '/assignments': <AssignmentTextFilter />,
  '/assignments/attachments/:id': <AttachmentTextFilter />,
  '/assignments/team': <AssignmentTextFilter />,
  '/assignments/my': <MyAssignmentTextFilter />,
  '/assignments/pending': <MyAssignmentTextFilter />,
  '/media': <MediaTextFilter />,
  '/activities': <ActivityTextFilter />,
  '/activities/events': <ActivityTextFilter />,
  '/activities/calls': <ActivityCallTextFilter />,
  '/admin': <TokenTextFilter />,
  '/admin/tokens': <TokenTextFilter />
}

const renderSearchByLocation = (path) => {
  const key = Object.keys(FILTER_LIST)
    .find(k => matchPath(path, { path: k, exact: true }))

  return FILTER_LIST[key] || null
}

export { renderSearchByLocation }
