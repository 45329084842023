import React from 'react'
import CloseIcon from 'material-ui/svg-icons/navigation/close'
import SendIcon from 'material-ui/svg-icons/content/send'
import IconButton from 'material-ui/IconButton'
// import TextField from 'material-ui/TextField'
import { FormattedMessage } from 'react-intl'
import { ChatMessage } from './ChatMessage'
import './CallChat.css'

export const CallChat = props => {
  const { chat } = props.call
  if (!chat.show) return null
  const partner = props.call.connection.partner
  const me = props.user
  const onClose = () => props.showMessage(false)
  const onChange = (e) => {
    props.changeMessage(e.target.value)
  }
  const onSend = () => {
    chat.currentMessage && props.sendMessage(chat.currentMessage)
  }

  const onKeyUp = (e) => {
    if (e.keyCode === 13) onSend()
  }

  return (
    <div className="call-chat">
      <div className="call-chat-header">
        <IconButton onTouchTap={onClose}><CloseIcon /></IconButton>
        <FormattedMessage id="CALL_CHAT_TITLE" defaultMessage="Message" />
      </div>
      {chat.messages.length === 0 && <div className="no-items">
        <FormattedMessage id="CALL_CHAT_NO_MESSAGES" defaultMessage="No messages" />
      </div>}
      {chat.messages.length > 0 && <div className="messages">
        {chat.messages.map((m) => <ChatMessage
          key={m.date}
          me={me}
          message={m}
          partner={partner} />)}
      </div>}
      <div className="new-message">
        <input
          className="message-input"
          name="new-message"
          type="text"
          autoComplete="off"
          value={chat.currentMessage || ''}
          onChange={onChange}
          onKeyUp={onKeyUp} />
        {/* <TextField
          name='new-message'
          className='message-input'
          value={chat.currentMessage || ''}
          onChange={onChange} /> */}
        <IconButton className="send" onTouchTap={onSend} disabled={!chat.currentMessage}>
          <SendIcon />
        </IconButton>
      </div>

    </div>
  )
}
