import React from 'react'

import * as dialogTypes from './dialogTypes'
import { actions, Dialogs as CommonDialogs } from 'skylight-common'
import {
  ActiveFilters,
  AddAssignment,
  AddDeviceDialog,
  AddToExistingGroupDialog,
  AddTokenDialog,
  AssignApplication,
  ConfirmationDialog,
  DuplicateAppDialog,
  EnableBrowserPermissions,
  NetScan,
  NewMediaDialog,
  ResetPasswordDialog,
  SetupWifiDialog,
  ShowTokenCredentialsDialog,
  TemporaryPasswordDialog,
  VersionDialog,
  VerifyCredentialsDialog,
  SetupNewApplication,
  PublishVersion,
  SessionEventJsonDataDialog,
  AddRole,
  AddApplicationParticipants,
  AccountSettingsDialog,
  NewUserDialog,
  ImportArchiveUpload,
  MediaDownloadConfirmation,
  QueryFilterDialog,
  UserAttributesDialog,
  UploadUpgradeApplication
} from '../../../../containers/dialogs'
import AppUpgradeDialog from './AppUpgradeDialog/AppUpgradeDialog'

import './Dialogs.css'

export const DIALOG_MAP = {
  [dialogTypes.ADD_DEVICE]: AddDeviceDialog,
  [actions.dialogTypes.CONFIRM]: ConfirmationDialog,
  [dialogTypes.ENABLE_PERMISSIONS]: EnableBrowserPermissions,
  [dialogTypes.ADD_MEDIA]: NewMediaDialog,
  [dialogTypes.ADD_TOKEN]: AddTokenDialog,
  [dialogTypes.SHOW_TOKEN_CREDENTIALS]: ShowTokenCredentialsDialog,
  [dialogTypes.VERSION]: VersionDialog,
  [dialogTypes.ASSIGN_APPLICATION]: AssignApplication,
  [dialogTypes.ADD_ASSIGNMENT]: AddAssignment,
  [actions.dialogTypes.ACTIVE_FILTERS]: ActiveFilters,
  [dialogTypes.NET_SCAN]: NetScan,
  [dialogTypes.ADD_TO_EXISTING_GROUP]: AddToExistingGroupDialog,
  [dialogTypes.SETUP_WIFI]: SetupWifiDialog,
  [dialogTypes.TEMPORARY_PASSWORD]: TemporaryPasswordDialog,
  [dialogTypes.RESET_PASSWORD]: ResetPasswordDialog,
  [dialogTypes.DUPLICATE_APPLICATION]: DuplicateAppDialog,
  [dialogTypes.VERIFY_CREDENTIALS]: VerifyCredentialsDialog,
  [dialogTypes.SETUP_NEW_APPLICATION]: SetupNewApplication,
  [dialogTypes.PUBLISH_VERSION]: PublishVersion,
  [dialogTypes.SESSION_EVENT_VIEW_JSON]: SessionEventJsonDataDialog,
  [dialogTypes.ADD_APPLICATION_ROLE]: AddRole,
  [dialogTypes.ADD_APPLICATION_PARTICIPANTS]: AddApplicationParticipants,
  [dialogTypes.ACCOUNT_SETTINGS]: AccountSettingsDialog,
  [dialogTypes.NEW_USER]: NewUserDialog,
  [dialogTypes.IMPORT_ARCHIVE_UPLOAD]: ImportArchiveUpload,
  [dialogTypes.MEDIA_DOWNLOAD_CONFIRMATION]: MediaDownloadConfirmation,
  [dialogTypes.QUERY_FILTER_CONFIGURATION]: QueryFilterDialog,
  [dialogTypes.USER_ATTRIBUTES]: UserAttributesDialog,
  [dialogTypes.APP_UPGRADE]: AppUpgradeDialog,
  [dialogTypes.APP_UPGRADE_UPLOAD]: UploadUpgradeApplication
}

const Dialogs = (props) => <CommonDialogs knownTypes={DIALOG_MAP} dialog={props.dialog} />

export { Dialogs }
