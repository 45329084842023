import * as ActionTypes from '../types'
import { push } from 'react-router-redux'
import {
  createFilterActions, createListActions, createItemActions,
  ValidationFieldError, validateRequired
} from 'skylight-common'
import skylight from 'skylight-js-sdk'
import { GROUP_DEFAULT_QUERY } from '../../lib/contact'
import { refreshGroup, refreshUser, addGroupMember } from './common'
import { onRefreshUser } from '../auth'
import { isNextGenUrl } from '../../lib/nextGenApplication'

const loadGroups = async(query) => {
  const groups = await skylight.group.list({ ...query })
  return groups || []
}

const deleteGroups = async(ids, _, ctx) => {
  const result = await Promise.all(ids.map(skylight.group.deleteById))
  ctx.dispatch(onRefreshUser())

  return result
}

const onValidateGroup = (group) => {
  const fields = ['name']
  return validateRequired(group, fields)
}

const onSaveGroup = async(item, ctx) => {
  const group = ctx.getState().contact.group

  try {
    const res = await skylight.group.save(item)
    const groupId = item.id || res.id

    const { members = [], membersToRemove = [] } = group

    const currentUser = ctx.getState().auth.user
    const membersToAdd = item.id ? members : members.filter(m => m.id !== currentUser.id)

    await Promise.all([
      ...membersToAdd.map(x => skylight.membership.addUserToGroup(x.id, groupId)),
      ...membersToRemove.map(x => skylight.membership.removeUserFromGroup(x.id, groupId))
    ])

    // Refresh current user for updated membership.
    ctx.dispatch(onRefreshUser())

    ctx.dispatch(refreshGroup({ id: groupId }))

    // Refresh cached groups
    await skylight.group.refreshCache()

    return { id: groupId }
  } catch (e) {
    // TODO: handle somehow.
    const error = await e.response.json()
    if (e.status === 409 || (error.msg && error.msg.includes('already exists'))) {
      throw new ValidationFieldError({ name: 'ALREADY_EXISTS' })
    }

    throw e
  }
}

const onLoadGroup = async(item, res, ctx) => {
  const group = await skylight.group.getById(item.id || res.id)
  group.totalMembers = (group.members || []).length
  return group
}

const COMMON_GROUP_OPTIONS = {
  prefix: ActionTypes.PREFIX_GROUP.toUpperCase(),
  handler: ActionTypes.PREFIX_GROUP,
  onGetList: s => s.contact.groups
}

const GROUP_LIST_ACTIONS = createListActions({
  ...COMMON_GROUP_OPTIONS,
  onLoad: loadGroups,
  onDelete: deleteGroups
})

const GROUP_ACTIONS = createItemActions({
  ...COMMON_GROUP_OPTIONS,
  onValidate: onValidateGroup,
  onSave: onSaveGroup,
  onLoad: onLoadGroup,
  onLoadItems: GROUP_LIST_ACTIONS.onGroupLoadItems
})

const GROUP_FILTER_ACTIONS = createFilterActions({
  baseUrl: '/team/groups',
  redirect: false,
  selector: state => state.contact.groups.query,
  listPrefix: ActionTypes.PREFIX_GROUP.toUpperCase(),
  actionPrefix: ActionTypes.PREFIX_GROUP,
  DEFAULT_QUERY: GROUP_DEFAULT_QUERY,
  loadItems: GROUP_LIST_ACTIONS.onGroupLoadItems,
  useLocalQuery: true
})

// Override default item handler.
const onGroupNew = (item, ids, items, groupsPath) => {
  const members = (items || []).filter((x) => (ids || []).includes(x.id))

  return (dispatch) => {
    if (members.length > 0) {
      const redirectPath = isNextGenUrl() ? '/ng/groups' : 'team/groups'
      dispatch(push(redirectPath))
    }

    dispatch({
      type: ActionTypes.GROUP_NEW,
      members,
      group: {}
    })
  }
}

const setNewGroupMembers = (members) => ({
  type: ActionTypes.GROUP_SET_NEW_MEMBERS,
  members
})

const setRemoveGroupMembers = (member) => ({
  type: ActionTypes.GROUP_SET_REMOVE_MEMBERS,
  member
})

const onCreateUserGroup = (user, group) => {
  return (dispatch, getState) => {
    dispatch({ type: ActionTypes.GROUP_SAVING })
    skylight.group.save(group)
      .then(group => {
        dispatch({ type: ActionTypes.GROUP_SAVED })

        const currentUser = getState().auth.user
        if (currentUser.id === user.id) {
          refreshUser(user)(dispatch)
          return
        }

        dispatch(addGroupMember(user, group))
      })
      .catch(e => {
        console.log('onCreateUserGroup', e)
        dispatch({ type: ActionTypes.GROUP_SAVE_ERROR, error: e.message })
      })
      .then(x => {
        const query = getState().contact.groups.query
        dispatch(GROUP_LIST_ACTIONS[`on${ActionTypes.PREFIX_GROUP}LoadItems`](query))
      })
  }
}

const onGroupDeleteItems = (ids, query) => async(dispatch) => {
  const { groupIds } = query.filter
  const hasQueryChanges = groupIds && ids.some(x => groupIds.includes(x))

  // Look if query.filter has the same group ids that groups.selectedIds does
  // If it is, delete them from a query
  if (hasQueryChanges) {
    dispatch(GROUP_FILTER_ACTIONS.onGroupFilterUpdated({
      ...query.filter, groupIds: query.filter.groupIds.filter(id => !ids.includes(id))
    }))
  }

  dispatch(GROUP_LIST_ACTIONS.onGroupDeleteItems(ids))

  // Refresh cached groups
  await skylight.group.refreshCache()
}

export default {
  ...GROUP_LIST_ACTIONS,
  ...GROUP_ACTIONS,
  ...GROUP_FILTER_ACTIONS,
  onSetNewGroupMembers: setNewGroupMembers,
  onSetRemoveGroupMembers: setRemoveGroupMembers,
  onGroupNew,
  onCreateUserGroup, // Creates a group and assigns a user to it (group membership)
  onGroupDeleteItems
}
