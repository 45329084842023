import skylight from 'skylight-js-sdk'

import socketActions from '../actions/socket'
import presenceActions from '../actions/presence'
import * as ActionTypes from '../actions/types'

import { store } from '../containers/store'

import { createSession } from '../svc/sessions'

const ACTIONS = {
  SESSION_HASH_UPDATED: presenceActions.onRefreshSessions,
  CALL_SETUP: socketActions.onCallSetup
}

let sessionHash = null

const init = () => {
  // If sockets are disabled b/c of legacy has been disabled, then
  // those events would not be dispatched by skylight-js-sdk,
  // and sessions will not be refreshed.
  skylight.events.on('sessionCreate', e => {
    e.session = createSession()
  })

  skylight.events.on('socketConnectResult', result => {
    if (result.sessionId && !result.error) {
      const authToken = skylight.auth.accessToken

      store.dispatch(presenceActions.onRefreshSessions(null, authToken))

      skylight.events.on('socketMessage', message => {
        if (message.type === 'PONG') {
          if (message.payload !== sessionHash) {
            // Sometimes session hash update event might get lost, so ensuring the latest hash on pong.
            sessionHash = message.payload
            store.dispatch(ACTIONS.SESSION_HASH_UPDATED(null, authToken))
          }
        } else if (ACTIONS.hasOwnProperty(message.type)) {
          store.dispatch(ACTIONS[message.type](message, authToken))
        } else {
          store.dispatch({ ...message, type: `${ActionTypes.SOCKET_PREFIX}_${message.type}` })
        }
      })

      skylight.events.on('socketStateChanged', state => {
        store.dispatch({ type: ActionTypes.SOCKET_STATE_CHANGED, state })
      })
    }
  })
}

export default {
  init
}
