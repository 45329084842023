import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  GridView, SortPicker, GridHeader,
  groupBySection, localSort
} from 'skylight-common'
import { COMMON_COLUMNS, onSelect, onSortUpdate } from '../attachment-utils'
import DecisionIcon from '../../../../Common/Icons/multipleChoice'
import { filterDecisionAttachment } from '../../../../../lib/assignment'
import { DecisionGridItem } from './DecisionGridItem'
import './DecisionAttachments.css'

const COLUMNS = []

const DecisionAttachments = (props) => {
  const list = props.attachment.list
  const query = list.query.decision
  let items = list.items.filter(x => x.cardType === 'decision')
  items = filterDecisionAttachment(items, list.query.filter)
  if (!items.length) {
    return null
  }

  const columns = [...COLUMNS, ...COMMON_COLUMNS]

  const heading = p => <GridHeader
    {...p}
    actions={[
    <SortPicker
      key="sort-query"
      query={query}
      columns={columns}
      onChange={(sBy, sAsc) => onSortUpdate(props)(sBy, sAsc, 'decision')} />]} />

  return (
    <div className="assignment-decision-attachments">
      <div className="assignment-attachments-title capture-attachments-title">
        <DecisionIcon />
        <FormattedMessage id="MULTIPLE_CHOICE" defaultMessage="Multiple choice" />
      </div>
      <GridView
        {...groupBySection(columns, query.sortBy)}
        items={localSort(items, query)}
        query={query}
        heading={heading}
        total={list.items.length}
        renderItem={DecisionGridItem}
        selectedIds={list.selectedIds}
        cols={1}
        done={true}
        onQuery={() => {}}
        onSelect={onSelect(props)}
        onCheck={null} />
    </div>
  )
}

export { DecisionAttachments }
