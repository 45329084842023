import React from 'react'
import { Tabs, Tab } from 'material-ui/Tabs'
import { FormattedMessage } from 'react-intl'
import { UserList } from '../UserList/UserList'
import { GroupList } from '../GroupList/GroupList'
import './ContactTabs.css'

const ContactTabs = (props) => {
  const type = props.type || 'users'

  const onChange = (v) => {
    props.redirect(`/contacts/${v}`)
  }

  return <div className="contact-tabs">
    <Tabs value={type} className="dashboard-tabs" onChange={onChange}>
      <Tab
        label={<FormattedMessage id="TAB_USERS" defaultMessage="Users" />}
        value="users">
        <UserList {...props} />
      </Tab>
      <Tab
        label={<FormattedMessage id="TAB_GROUPS" defaultMessage="Groups" />}
        value="groups">
        <GroupList {...props} />
      </Tab>
    </Tabs>
  </div>
}

export { ContactTabs }
