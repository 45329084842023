import React from 'react'
import './UserTitle.css'

export const UserTitle = (props) => {
  const { user } = props
  if (!user) return null
  return <div className="user-title">
    {user.firstName || user.lastName
      ? `${user.firstName} ${user.lastName}`
      : user.username}
  </div>
}
