import skylight from 'skylight-js-sdk'

const {
  ASSIGNMENT,
  CARD,
  SEQUENCE,
  USER,
  GROUP,
  MESSAGE,
  INTEGRATION,
  FILE,
  DATA_APP,
  WORKFLOW,
  APPLICATION,
  FA_APPLICATION
} = skylight.scopes

const canSeeLegacy = () => skylight.auth.isLegacyEnabled()
const canSeeNextGen = () => skylight.auth.isNextGenEnabled()
const isApplicationAdmin = () => skylight.auth.hasScopes([
  skylight.utils.isFrontline() ? FA_APPLICATION.ADMIN : APPLICATION.ADMIN
])

export {
  ASSIGNMENT,
  CARD,
  SEQUENCE,
  USER,
  GROUP,
  MESSAGE,
  INTEGRATION,
  FILE,
  DATA_APP,
  WORKFLOW,
  APPLICATION,
  canSeeLegacy,
  canSeeNextGen,
  isApplicationAdmin,
  FA_APPLICATION
}
