// import { combineReducers } from '../lib/combineReducers'
import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import { reducers } from 'skylight-common'
import { reducers as call } from 'skylight-call-sdk'

import app from './app'
import auth from './auth'
import callUser from './callUser'
import home from './home'
import locale from './locale'
import login from './login'
import contact from './contact'
import device from './device'
import activity from './activity'
import assignment from './assignment'
import browser from './browser'
import profile from './profile'
import presence from './presence'
import redirection from './redirection'
import error from './error'
import media from './media'
import mqtt from './mqtt'
import netscan from './netscan'
import network from './network'
import workflow from './workflow'
import admin from './admin'
import job from './job'
import application from './application'
import snackbar from './snackbar'

const state = combineReducers({
  activity: combineReducers(activity),
  admin: combineReducers(admin),
  app,
  assignment: combineReducers(assignment),
  auth,
  browser: combineReducers(browser),
  call,
  callUser: combineReducers(callUser),
  contact: combineReducers(contact),
  device: combineReducers(device),
  dialog: reducers.dialog,
  error,
  job,
  home,
  layout: combineReducers(reducers.layout),
  locale,
  login: combineReducers(login),
  media: combineReducers(media),
  mqtt,
  netscan,
  network,
  presence: combineReducers(presence),
  profile: combineReducers(profile),
  redirection,
  router: routerReducer,
  workflow: combineReducers(workflow),
  application: combineReducers(application),
  snackbar
})

export default state
