import skylight from 'skylight-js-sdk'

import * as ActionTypes from '../actions/types'

const initialState = {
  state: skylight.mqtt.STATES.CONNECTING,
  reconnectIn: 0
}

const mqtt = (state = initialState, payload, all) => {
  switch (payload.type) {
    case ActionTypes.MQTT_STATE_CHANGED:
      return { ...state, state: payload.state }
    case ActionTypes.MQTT_RECONNECT_IN:
      return { ...state, reconnectIn: payload.reconnectIn }
    default:
      return state
  }
}

export default mqtt
