import React from 'react'

import './DeviceInfo.css'

const DeviceInfo = props => {
  const { deviceType } = props
  // const renderDescription = () => {
  //   let localAddress = 'n/a'
  //   let remoteAddress = 'n/a'
  //   if (localData && remoteData) {
  //     localAddress = localData.port ? `${localData.ip}:${localData.port}` : localData.ip
  //     remoteAddress = remoteData.port ? `${remoteData.ip}:${remoteData.port}` : remoteData.ip
  //     if (!localData.ip || !remoteData.ip) {
  //       return null
  //     }
  //   }

  //   return <div className="device-info-description">
  //     {localAddress && <span>Local: {localAddress}</span>}
  //     {remoteAddress && <span>Remote: {remoteAddress}</span>}
  //   </div>
  // }

  return <div className="device-info">
    <div className="device-info-title">{deviceType}</div>
    {/* {renderDescription()} */}
  </div>
}

export { DeviceInfo }
