import React from 'react'
import { TextFilter } from 'skylight-common'
import { ActivityFilter } from '../../../../containers/activity'

const VALUE = 'title'

const ActivityTextFilter = props => {
  const value = props.query.filter && props.query.filter.title ? props.query.filter.title : ''
  const onChange = (v) => {
    const value = v.trim()
    const filter = { ...props.query.filter, [VALUE]: value }
    props.onActivityFilterUpdated(filter)
  }

  return <TextFilter
    value={value}
    className="activity-text-filter"
    title="PLACEHOLDER_EVENT"
    iconFilter={<ActivityFilter />}
    onChange={onChange} />
}

export { ActivityTextFilter }
