import React from 'react'
import * as snackbarTypes from './snackbarTypes'

import * as snackbars from '../../../../containers/snackbars'

import './Snackbar.css'

const SNACKBAR_MAP = {
  [snackbarTypes.ERROR_CREATING_DRAFT]: snackbars.SnackbarErrorCreatingDraft,
  [snackbarTypes.SNACKBAR_LOADING]: snackbars.SnackbarLoading,
  [snackbarTypes.SNACKBAR_COMPLETE]: snackbars.SnackbarComplete,
  [snackbarTypes.ERROR_ARCHIVING_APPLICATION]: snackbars.SnackbarErrorArchivingApplication,
  [snackbarTypes.SNACKBAR_SUCCESS]: snackbars.SnackbarSuccess,
  [snackbarTypes.SNACKBAR_MESSAGE]: snackbars.SnackbarMessage,
  [snackbarTypes.SNACKBAR_INCOMPATIBLE_IMPORT_VERSION]: snackbars.SnackbarIncompatibleVersionError,
  [snackbarTypes.SNACKBAR_UPGRADE_ERROR]: snackbars.SnackbarUpgradeError
}

const Snackbar = props => {
  const { snackbar } = props

  const SNACKBAR_COMPONENTS = Object.keys(SNACKBAR_MAP)
    .filter(x => snackbar.hasOwnProperty(x) && snackbar[x].open)
    .map(key => {
      const SnackbarComp = SNACKBAR_MAP[key]
      return <SnackbarComp key={key} snackbarType={key} />
    })

  return <div className="snackbars">{SNACKBAR_COMPONENTS}</div>
}

export { Snackbar }
