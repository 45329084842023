import React from 'react'
import LinearProgress from 'material-ui/LinearProgress'
import './GroupLoading.css'

const GroupLoading = ({ isLoading }) => {
  const loading = 'group-loading' + (isLoading ? ' loading' : '')
  return <div className={loading}>
      <LinearProgress mode="indeterminate" />
    </div>
}

export { GroupLoading }
