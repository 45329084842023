import React from 'react'
import { FormattedMessage } from 'react-intl'
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton'
import RaisedButton from 'material-ui/RaisedButton'
import RadioButtonChecked from 'material-ui/svg-icons/toggle/radio-button-checked'
import RadioButtonUnchecked from 'material-ui/svg-icons/toggle/radio-button-unchecked'
import CircularProgress from 'material-ui/CircularProgress'
import { FooterActionButtons } from '../../../Common'
import { DeleteIcon, Form } from 'skylight-common'
import { PrivacyAnonymousInfo } from './PrivacyAnonymousInfo'

import './AdminPrivacy.css'

const getButtons = props => [
  { label: { title: 'IDENTIFIABLE', description: 'ADMIN_IDENT_PRIVACY' }, value: 'identifiable' },
  {
    label: {
      title: 'ANONYMOUS',
      titleValues: { value: '' },
      description: 'ADMIN_ANON_PRIVACY',
      postfix: p => <span onTouchTap={p.onTouchTap} className="privacy-learn-more"><FormattedMessage id="LEARN_MORE" defaultMessage="Learn more" /></span>
    },
    value: 'anonymous'
  },
  {
    label: { title: 'DISABLED', description: 'ADMIN_DISABLED_PRIVACY' },
    value: 'disabled',
    children: () => {
      return <RaisedButton
      className="admin-privacy-button"
      onTouchTap={(e) => {
        (() => props.onConfirm(
          () => props.onDeleteActivities(), {
            title: 'ACTIVITIES_DELETE_DIALOG_TITLE',
            message: 'ACTIVITIES_DELETE_DIALOG_DESCRIPTION',
            action: 'ACTION_DELETE'
          }
        ))()
      }}>
        <div className="admin-privacy-button-body">
          {props.activity.isDeleting ? <CircularProgress size={30} /> : <DeleteIcon/>}
          <FormattedMessage id="ACTIVITIES_DELETE_LABEL" defaultMessage="Delete user activity logs" />
        </div>
      </RaisedButton>
    }
  }
]

// UI only, update once API is ready.
export class AdminPrivacy extends React.Component {
  state = { isDialogOpen: false }

  componentDidMount() {
    this.props.onRealmLoad()
  }

  onCancel = () => {
    this.props.onRealmCancel()
  }

  onToggleDialog = (e) => {
    e && e.preventDefault && e.preventDefault()
    this.setState(s => ({ isDialogOpen: !s.isDialogOpen }))
  }

  onSave = () => {
    const { realm } = this.props.auth
    realm.hasChanged && this.props.onRealmSave(realm.item)
  }

  renderRadioButton = (buttonProps, value) => {
    const checked = value === buttonProps.value
    const hasBody = checked && buttonProps.children
    const PrivacyButtonIcon = checked ? RadioButtonChecked : RadioButtonUnchecked

    const radioButtonLabel = <div className="privacy-button-label">
      <div className="privacy-button-title-container">
        <div>
          <PrivacyButtonIcon className={`privacy-button-radio-icon ${checked ? 'checked' : ''}`} />
        </div>
        <div className="privacy-button-title">
          <FormattedMessage id={buttonProps.label.title} values={buttonProps.label.titleValues} />
          <FormattedMessage id={buttonProps.label.description} />
          {buttonProps.label.postfix && buttonProps.label.postfix({ onTouchTap: this.onToggleDialog })}
        </div>
      </div>
      <div className="privacy-button-body-container">
        {hasBody && buttonProps.children()}
      </div>
    </div>

    return <RadioButton
      className={'privacy-radio-button'}
      key={buttonProps.value}
      value={buttonProps.value}
      label={radioButtonLabel} />
  }

  render() {
    const BUTTONS = getButtons(this.props)
    const { item, hasChanged } = this.props.auth.realm
    const value = (item && item.privacyLevel) || BUTTONS[0].value

    return (
      <div className="admin-privacy">
        <Form hasChanged={hasChanged} onSubmit={this.onSave} onEscapePress={this.onCancel}>
          <div className="title">
            <FormattedMessage id="ADMIN_PRIVACY_TITLE" defaultMessage="User activity logging" />
          </div>
          <div className="description"><FormattedMessage id="ADMIN_PRIVACY_DESCRIPTION" /></div>
          <RadioButtonGroup
            className="admin-radio-group privacy-radio-group"
            name="privacyLevel"
            valueSelected={value}
            defaultSelected={value}
            onChange={(e, v) => this.props.onRealmChange(e.target.name, v)}>
            {BUTTONS.map(button => this.renderRadioButton(button, value))}
          </RadioButtonGroup>
          {hasChanged && <FooterActionButtons
            onCancel={this.onCancel}
            onSave={this.onSave} />}
        </Form>
        <PrivacyAnonymousInfo open={this.state.isDialogOpen} onClose={this.onToggleDialog} />
      </div>
    )
  }
}
