import * as ActionTypes from '../types'
import skylight from 'skylight-js-sdk'
import groupMemberActions from './groupMember'
import { hideDialog } from '../dialog'
import { showError } from '../error'
import * as dialogTypes from '../../App/Home/Layout/Dialogs/dialogTypes'

export const refreshUser = (user, changedProps, action) => dispatch => {
  skylight.user.getById(user.id).then(user => {
    skylight.user.refreshCache()

    return skylight.user.getGroups(user.id).then(groups => {
      const updatedUser = { ...user, groups }
      if (changedProps) {
        changedProps.forEach(key => {
          dispatch({
            type: `${ActionTypes.PREFIX_USER.toUpperCase()}_CHANGE`,
            prop: key,
            value: updatedUser[key]
          })
        })
      }

      dispatch({
        type: `LIST_${ActionTypes.PREFIX_USER.toUpperCase()}_UPDATE`,
        item: updatedUser
      })

      action && dispatch(action(updatedUser))
    })
  })
}

export const refreshGroup = group => {
  return dispatch => {
    skylight.group.getById(group.id).then(item => {
      const log = { ...item, totalMembers: (item.members || []).length }
      console.log('refresh group', log)
      dispatch({
        type: `LIST_${ActionTypes.PREFIX_GROUP.toUpperCase()}_UPDATE`,
        item: { ...item, totalMembers: (item.members || []).length }
      })
      dispatch(groupMemberActions.onGroupMemberLoadItems({ id: item.id }))
    })
  }
}

export const addGroupMember = (user, group) => {
  return (dispatch, getState) => {
    // Update user groups locally to prevent flickering.
    // Worst case, we refresh the user's data if error occurs.
    const currentUser = getState().contact.user.item
    const userGroups = user.groups || []
    const previewUser = { ...user, groups: [...userGroups, group] }
    dispatch({ type: ActionTypes.PREFIX_LIST_USERS + ActionTypes.LIST_UPDATE, item: previewUser })

    dispatch({ type: ActionTypes.GROUP_MEMBER_ADDING_START })

    skylight.membership
      .addUserToGroup(user.id, group.id)
      .then(() => {
        dispatch({ type: ActionTypes.GROUP_MEMBER_ADDING_SUCCESS })
      })
      .catch(() => {
        dispatch({ type: ActionTypes.GROUP_MEMBER_ADDING_ERROR })
      })
      .then(() => {
        const authRefreshAction =
          user.id === getState().auth.user.id ? user => ({ type: ActionTypes.AUTH_USER_REFRESHED, user }) : null

        dispatch(refreshUser(user, currentUser ? ['groups'] : null, authRefreshAction))
      })
  }
}

export const onAddMembersToGroups = (users, groups) => dispatch => {
  let currentUser

  dispatch({ type: ActionTypes.ADD_MEMBERS_TO_GROUPS_START })
  groups.forEach(groupId => {
    return Promise.all(
      users.map(user => {
        return skylight.membership
          .addUserToGroup(user.id, groupId)
          .then(() => {
            currentUser = user

            dispatch({
              type: ActionTypes.ADD_MEMBERS_TO_GROUPS_SUCCESS
            })

            // hide dialog
            dispatch(hideDialog(dialogTypes.ADD_TO_EXISTING_GROUP))

            // show snackbar with success message
            dispatch(showError(ActionTypes.ADD_MEMBERS_TO_GROUPS_SUCCESS, { className: 'snackbar-common-success' }))
          })
          .catch(() => {
            // show error snackbar
            dispatch({ type: ActionTypes.ADD_MEMBERS_TO_GROUPS_ERROR, className: 'snackbar-common-error' })

            // hide dialog
            dispatch(hideDialog(dialogTypes.ADD_TO_EXISTING_GROUP))
          })
          .then(() => {
            // refresh users list
            dispatch(refreshUser(currentUser))

            // remove selection of users and groups
            dispatch({ type: `LIST_${ActionTypes.PREFIX_USER.toUpperCase()}_SELECTED`, selectedIds: [] })
            dispatch({ type: `LIST_${ActionTypes.PREFIX_GROUP.toUpperCase()}_SELECTED`, selectedIds: [] })
          })
      })
    )
  })
}
