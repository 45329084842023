import skylight from 'skylight-js-sdk'

import * as ActionTypes from './types'
import { CONFIG, initMultiTabAuth, downloadFile, actions } from 'skylight-common'
import { push } from 'react-router-redux'
import { switchLocaleForUser } from './locale'
import { NG_DOCS_URL, NG_WHATS_NEW_URL, getVersions } from '../svc/app'
import { getSsoToken, ssoData } from '../svc/login'
import { isAndroid, isFirefox, isIE } from '../lib/browser'
import mqttConfig from '../svc/mqtt'
import mediaClient from '../svc/mediaClient'
import { getCurrentLayoutFromStorage, LAYOUT_VERSIONS } from '../svc/layout'
import { checkBrowserPermissions } from '../actions/browser'

const initApp = () => {
  return async(dispatch, getState) => {
    initMultiTabAuth()
    await skylight
      .setup(CONFIG.webUrl)
      .withMediaClient(mediaClient)
      .withMqtt(true, mqttConfig.service, mqttConfig.topics)
      .withSocket(true)
      .withFormData(URLSearchParams)
      .init(process.env.NODE_ENV === 'test' ? CONFIG : null)

    if (isFirefox || isIE) {
      skylight.logger.debug = () => {} // Edge and FF performance optimization.
    }
    dispatch({ type: ActionTypes.APP_INIT })
    const { auth = {} } = getState()
    dispatch(switchLocaleForUser(auth.user))
    dispatch(actions.nav.onLoadDrawerSectionStatus())
    // dispatch(releaseActions.onCheckReleases())
  }
}

const redirect = (path) => {
  return dispatch => dispatch(push(path))
}

export const redirectToApp = (redirectSearch) => {
  return async dispatch => {
    const storedRedirectSearch = redirectSearch || (await skylight.storage.getItem('redirectSearch')) || ''
    // Might be useful for troubleshooting on-prem scenarios.
    console.log('redirectToApp', storedRedirectSearch)
    const { redirect: redirectPath = '/', redirectUrl = '', ...rest } = skylight.http.queryStrToObj(storedRedirectSearch)
    const search = skylight.http.asQueryString(rest)
    if (redirectUrl) {
      const decodedUrl = window.decodeURIComponent(redirectUrl)
      const redirectTo = decodedUrl.startsWith('/') ? decodedUrl : `/${decodedUrl}`
      dispatch(redirect(redirectTo))
      return
    }

    const parsedPath = window.decodeURIComponent(redirectPath)
    switch (parsedPath) {
      case 'builder':
      case 'ng/builder':
        return dispatch(goToBuilder(parsedPath, search))
      case 'appweb':
        return dispatch(goToAppWeb(parsedPath, search))
      case 'inspector':
        return dispatch(goToInspector(parsedPath, search))
      case 'help':
        return dispatch(goToHelp(parsedPath, false, search))
      default: {
        // Unsupported app or root. Navigate to root.
        const layoutVersion = await getCurrentLayoutFromStorage()
        const url = layoutVersion === LAYOUT_VERSIONS.NG ? '/ng' : '/'
        await dispatch(redirect(url))
        await dispatch(checkBrowserPermissions())
      }
    }
  }
}

const goToAppWeb = (_, search) => dispatch => {
  dispatch(redirect('/redirect/appweb'))
  window.location.replace(`${CONFIG.appClientUrl}${search}`)
}

const goToInspector = (_, search) => dispatch => {
  dispatch(redirect('/redirect/inspector'))
  window.location.replace(`${CONFIG.appClientUrl}inspector${search}`)
}

const goToBuilder = (builderPath, search) => {
  return dispatch => {
    // Replace /redirect/url with /url
    if (builderPath === 'ng/builder') {
      dispatch(redirect('/redirect/builder2'))
      window.location.replace(`${CONFIG.appBuilderUrl}${search}`)
    } else if (builderPath === 'builder') {
      dispatch(redirect('/redirect/builder'))
      window.location.replace(`${CONFIG.builderUrl}${search}`)
    } else {
      console.error('Unexpected path for goToBuilder', builderPath, search)
    }
  }
}

const redirectToHelp = () => dispatch => {
  dispatch(redirect('/redirect/help'))
  dispatch(goToHelp())
}

const redirectToNextGenDocumentation = () => _dispatch => {
  window.open(NG_DOCS_URL)
}

const redirectToNextGenWhatsNew = () => _dispatch => {
  window.open(NG_WHATS_NEW_URL)
}

const goToHelp = (path, useNewWindow, search = '') => async dispatch => {
  dispatch({ type: ActionTypes.REDIRECTION_START })

  let { redirectUrl = '' } = skylight.http.queryStrToObj(search)
  // Usually URL would contain special characters, at least '=' and '#' signs
  // encoded as %3D and %23 which should not be part of URL when we redirect user.
  redirectUrl = decodeURIComponent(redirectUrl)

  const headers = { authorization: skylight.auth.bearer }

  try {
    const baseWebUrl = '/' // too complicated. CONFIG.webUrl || window.location.origin
    const authUrl = skylight.utils.resolveUrl(baseWebUrl, '/help/authenticate')
    await skylight.http.post(authUrl, {}, headers)
    let helpUrl = path || skylight.utils.resolveUrl(CONFIG.helpUrl, redirectUrl)
    console.log('helpUrl resolved to ', helpUrl, 'from ', CONFIG.helpUrl, redirectUrl)
    helpUrl = skylight.utils.resolveUrl(baseWebUrl, helpUrl)
    if (useNewWindow) {
      window.open(helpUrl)
    } else {
      dispatch(redirect('/redirect/help'))
      window.location.replace(helpUrl)
    }
  } catch (e) {
    skylight.logger.error('goToHelp', e)
    dispatch(redirect('/redirect/error'))
    if (e.status === 401) {
      window.top.location.href = '/login'
    }
  }
}

const closeDetails = (list) => ({
  type: ActionTypes.HIDE_DETAILS
})

const onNativeUserEvent = e => ({
  type: ActionTypes.APP_NATIVE_USER_EVENT
})

const onGetVersions = () => dispatch => {
  dispatch({ type: ActionTypes.APP_VERSIONS_LOAD })
  getVersions()
    .then(versions => dispatch({ type: ActionTypes.APP_VERSIONS_LOADED, versions }))
    .catch(error => dispatch({ type: ActionTypes.APP_VERSIONS_ERROR, error }))
}

const INTENT_HOST = 'skylight.upskill.io/loginfromweb'
const INTENT_ACTION = 'android.intent.action.VIEW'
const INTENT_PACKAGE = 'io.upskill.skylight'

export const startAndroid = token => async dispatch => {
  if (isAndroid()) {
    dispatch({ type: ActionTypes.CLIENT_APP_LAUNCH })

    try {
      if (ssoData) {
        token = await getSsoToken(ssoData)
      }

      if (!token) {
        dispatch({ type: ActionTypes.CLIENT_APP_LAUNCH_ERROR })
        return
      }

      const qrJson = JSON.stringify({
        // Backwards compatibility with Android client..
        apiUrl: skylight.utils.resolveUrl(CONFIG.apiUrl, '/v1'),
        mqttUrl: CONFIG.mqttUrl,
        wsUrl: CONFIG.socketUrl,
        ssoToken: token
      })

      const href = `intent://${INTENT_HOST}#Intent;` +
        `action=${INTENT_ACTION};` +
        `package=${INTENT_PACKAGE};` +
        'scheme=http;' +
        `S.QrJson=${window.encodeURIComponent(qrJson)};` +
        'end'

      downloadFile(href)
    } catch (e) {
      dispatch({ type: ActionTypes.CLIENT_APP_LAUNCH_ERROR })
    }
  }
}

export default {
  initApp,
  redirect,
  goToHelp,
  goToBuilder,
  redirectToHelp,
  redirectToApp,
  redirectToNextGenDocumentation,
  redirectToNextGenWhatsNew,
  onCloseDetails: closeDetails,
  onNativeUserEvent,
  onGetVersions,
  onStartAndroid: startAndroid
}
