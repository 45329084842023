import React from 'react'
import { FormattedMessage } from 'react-intl'
import IconButton from 'material-ui/IconButton'
import { DeleteIcon } from 'skylight-common'
import SecurityTrimmer from '../../../../../containers/SecurityTrimmer'
import { USER } from '../../../../../svc/scopes'

const UserDetailsMenu = props => {
  const { user } = props

  const onDelete = () => {
    props.onConfirm(
      () => props.onUserDeleteItems([user.item.id]),
      {
        title: 'USERS_REMOVE_DIALOG_TITLE',
        message: 'USERS_REMOVE_DIALOG_DESCRIPTION',
        action: 'ACTION_DELETE_USER'
      }
    )
  }

  return (
    <SecurityTrimmer scopes={[USER.DELETE, USER.EDIT]}>
      <IconButton
        tooltip={
          <FormattedMessage id="DELETE_USER" defaultMessage="Delete User" />
        }
        tooltipPosition="bottom-left"
        onTouchTap={onDelete}>
        <DeleteIcon />
      </IconButton>
    </SecurityTrimmer>
  )
}

export { UserDetailsMenu }
