import React from 'react'
import classNames from 'classnames'
import Checkbox from 'material-ui/Checkbox'

import './CheckboxField.css'

const CheckboxField = props => {
  return <div className={classNames('checkbox-field', props.className)}>
    <Checkbox
      className="checkbox-control"
      name={props.name}
      checked={props.checked}
      onCheck={props.onChange} />
    {props.title}
  </div>
}

export { CheckboxField }
