import * as ActionTypes from '../../actions/types'

const initialState = {}

const permissions = (state = initialState, payload) => {
  switch (payload.type) {
    case ActionTypes.APPLICATION_PERMISSIONS_ADD:
      return { ...state, [payload.applicationId]: payload.permissions }
    default:
      return state
  }
}

export default permissions
