import * as ActionTypes from '../actions/types'
import { clearItemStatus } from '../actions/workflow'

const IGNORE_ERRORS = [
  ActionTypes.APP_CLEAR_ERROR,
  ActionTypes.SIGNIN_ERROR,
  ActionTypes.SIGNIN_REALM_ERROR,
  ActionTypes.APP_ERROR,
  ActionTypes.SHOW_ERROR,
  ActionTypes.LDAPAPP_FORCE_SYNC_LOAD_ERROR,
  ActionTypes.PROFILE_GENERIC_ERRORS_UPDATE,
  ActionTypes.PROFILE_FORM_ERRORS_DELETE,
  ActionTypes.PROFILE_PASSWORD_RESET_ERROR,
  ActionTypes.SIGNIN_ERRORS_CLEAR
  // Errors are shown in page, no need to duplicate.
]

const ACTION_PARAMS_MAP = {
  [ActionTypes.WORKFLOW_APPLICATION_ASSIGN_ERROR]: (a, n) => ({
    className: 'snackbar-common-error',
    actionName: a.item.name,
    action: () => n(clearItemStatus(a.id))
  }),
  [ActionTypes.APPLICATION_SINGLE_OWNER_REMOVE_ERROR]: () => ({ className: 'snackbar-common-error' }),
  [ActionTypes.APPLICATION_ALL_OWNERS_REMOVE_ERROR]: () => ({ className: 'snackbar-common-error' }),
  LDAPAPP_SAVE_ERROR: () => ({ className: 'snackbar-common-error' }),
  [ActionTypes.LDAPAPP_CUSTOM_MAPPINGS_UPDATE_ERROR]: () => ({ className: 'snackbar-common-error' }),
  [ActionTypes.LDAPAPP_CUSTOM_FILTERS_UPDATE_ERROR]: () => ({ className: 'snackbar-common-error' })
}

const clearError = (store, translation, next) => {
  setTimeout(() => {
    const { error } = store.getState()
    if (error && error.translation === translation) {
      next({ type: ActionTypes.APP_CLEAR_ERROR })
    }
  }, 3000) // TODO: Consider passing optional timeout in params.
}

const error = store => next => action => {
  let res
  const { type } = action
  if (type === ActionTypes.SHOW_ERROR) {
    clearError(store, action.error.translation, next)
  }

  if (type && type.includes('_ERROR') && !type.includes('_FIELD_ERROR') && !IGNORE_ERRORS.includes(type)) {
    // Ensure message is not empty.
    next({ ...action, error: action.error || 'UNEXPECTED_ERROR' })
    // TODO: handle custom cases.
    let translation = type
    if (translation.includes('LIST_REMOVE_ERROR')) {
      translation = 'ERROR_DELETE'
    }

    const getParams = ACTION_PARAMS_MAP[type]
    const params = getParams ? getParams(action, next) : { className: action.className }

    res = next({
      type: ActionTypes.APP_ERROR,
      error: {
        translation,
        params,
        message: action.message || action.error
      }
    })

    clearError(store, translation, next)
  } else {
    res = next(action)
  }

  return res
}

export default error
