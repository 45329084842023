import skylight from 'skylight-js-sdk'

import * as ActionTypes from './types'
import { showError } from './error'
import { getActiveSessions } from '../lib/presence'

const refreshSessions = () => async dispatch => {
  try {
    const sessions = await skylight.session.list()
    const users = getActiveSessions(sessions)
    dispatch({ type: ActionTypes.PRESENSE_USERS_LOADED, users })
  } catch (e) {
    skylight.logger.error('getSessions', e)
    dispatch(showError('SESSION_LIST_LOAD_ERROR'))
  }
}

const removeSession = session => async dispatch => {
  try {
    await skylight.session.deleteById(session.sessionId)
    dispatch(refreshSessions())
  } catch (e) {
    skylight.logger.error('removeSession', e, session)
    dispatch(showError('SESSION_DELETE_ERROR'))
  }
}

export default {
  onRefreshSessions: refreshSessions,
  onRemoveSession: removeSession
}

export const getSessions = async() => {
  const sessions = await skylight.session.list()
  return getActiveSessions(sessions)
}
