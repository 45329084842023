import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Loading, Form } from 'skylight-common'
import { AccountInfo } from './AccountInfo'
import { AccountPhone } from './AccountPhone'
import { AccountLocation } from './AccountLocation'
import { AccountLocalization } from './AccountLocalization'
import { Preferences } from './Preferences'

import './Settings.css'

const Settings = (props) => {
  const { settings } = props

  const onChange = (e, v, propName = '') => {
    const { name = propName } = e.target
    props.onProfileFieldChanged(name, v, 'form')
  }

  const onChangePreferences = (e, v) => {
    const { name } = e.target
    props.onProfileFieldChanged(name, v, 'preferencesForm')
  }

  return (
    <Loading
      isLoading={!settings.form}
      error={settings.error && <FormattedMessage id="PROFILE_LOAD_ERROR" defaultMessage="Failed to load profile" />}>
      <div className="settings-wrapper">
        <div className="profile-settings">
          <Form onSubmit={props.onSave}>
            <AccountInfo
              form={settings.form}
              errors={settings.errors}
              profileImage={settings.profileImage}
              onChange={onChange}
              onUploadImage={props.onUploadedProfileImage} />
            <AccountPhone form={settings.form} errors={settings.errors} onChange={onChange} />
            <AccountLocation form={settings.form} errors={settings.errors} onChange={onChange} />
            <AccountLocalization
              form={settings.form}
              errors={settings.errors}
              onChange={onChange}
              onSwitchLocale={props.onSwitchLocale}
              onSwitchLocaleForUser={props.onSwitchLocaleForUser} />
          </Form>
          <Preferences settings={settings} onChange={onChangePreferences} />
        </div>
      </div>
    </Loading>
  )
}

export { Settings }
