import React from 'react'
import { Toggle } from 'material-ui'
import { FormattedMessage } from 'react-intl'
import './MediaAutosaveSection.css'

const MediaAutosaveSection = (props) => {
  const liveSettings = props.form.liveSettings || {}

  return (
    <div className="media-autosave-section toggle-section">
      <div className="title">
        <FormattedMessage
          id="AUTOSAVE_MEDIA_TITLE"
          defaultMessage="Auto-Save Media" />
      </div>
      <div className="description">
        <FormattedMessage
          id="AUTOSAVE_MEDIA_DESCRIPTION"
          defaultMessage="When media is captured during a call, automatically save the files to Skylight Web." />
      </div>
      <Toggle
        className="toggle-control"
        name="autoSaveMedia"
        toggled={liveSettings.autoSaveMedia}
        onToggle={(e, v) => props.onChange('liveSettings', { ...liveSettings, autoSaveMedia: v })} />
    </div>
  )
}

export { MediaAutosaveSection }
