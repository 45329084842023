import React from 'react'
import PropTypes from 'prop-types'

import { FormBuilder } from 'skylight-common'
import { SAML_FIELDS } from '../../../../../../lib/admin'
import { LdapOptions } from '../LdapOptions'
import { LdapPicker } from '../LdapPicker'

import './SamlOptions.css'

const SamlOptions = props => {
  const { authApp, ldapApp, onChange, onLdapChange } = props
  const { item, errors, hasChanged } = authApp
  const { original } = ldapApp || {}

  const onToggleLdap = value => {
    if (!value && original) {
      onLdapChange('options', original.options)
    }
    onLdapChange('ldapEnabled', value)
  }

  const onFormFieldChange = (prop, value) => {
    const options = { ...authApp.item.options }
    options[prop] = value
    onChange('options', options)
  }

  const onLdapFormFieldChange = (prop, value) => onLdapChange(prop, value)

  const renderLdapPicker = () => {
    const { ldapEnabled = false } = ldapApp.item || {}
    return (
      <div>
        <LdapPicker isToggled={ldapEnabled} onToggleLdap={onToggleLdap} {...props} />
        {ldapEnabled && <LdapOptions {...props} onFormFieldChange={onLdapFormFieldChange} />}
      </div>
    )
  }

  if (!item || !item.enabled) {
    return null
  }

  return (
    <div className="nested-component-example">
      <FormBuilder
        className="saml-options"
        heading=""
        item={item.options}
        fields={SAML_FIELDS}
        errors={errors}
        originalChanged={hasChanged}
        onFormFieldChange={onFormFieldChange}
        fieldTypes={props.fieldTypes}/>
      {props.withLdapPicker ? renderLdapPicker() : null}
    </div>
  )
}

SamlOptions.propTypes = {
  authApp: PropTypes.object,
  ldapApp: PropTypes.object,
  onChange: PropTypes.func.isRequired
}

SamlOptions.defaultProps = {
  withLdapPicker: true,
  fieldTypes: null
}

export { SamlOptions }
