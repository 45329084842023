import * as ActionTypes from '../actions/types'

const initialState = {
  id: 'en',
  translations: {}
}

const home = (state = initialState, payload, all) => {
  switch (payload.type) {
    case ActionTypes.APP_LOCALE_LOADED:
      return { ...state, id: payload.id, translations: payload.translations }
    default:
      return state
  }
}

export default home
