import React from 'react'
import { FormattedMessage } from 'react-intl'
import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'
import { TokenActionMenu } from './TokenActionMenu'
import { isMobile } from 'skylight-common'
import './TokenActionButtons.css'

const TokenActionButtons = (props) => {
  const { workflow, item } = props
  const { selectedIds, items } = workflow.list

  const hasSubscriptionChanged = () => {
    const matched = items
      .filter(x => x.integrationId === item.item.id)
      .map(x => x.workflowId)

    return matched.length !== selectedIds.length ||
      matched.some(x => !selectedIds.includes(x))
  }

  const onSubscribe = () => {
    props.onMapTokenToWorkflows(item.item, workflow.list)
  }

  return (
    <div className={`token-action-buttons ${selectedIds.length > 0 ? 'has-selected' : ''}`}>
      {isMobile() && <TokenActionMenu {...props} />}
      {hasSubscriptionChanged() && <div className="actions">
        <FlatButton
          label={<FormattedMessage id="CANCEL" defaultMessage={'CANCEL'} />}
          onTouchTap={() => props.onCloseDetails(props.tokens)} />
        <RaisedButton
          className="submit-button"
          label={<FormattedMessage id="SAVE" defaultMessage="Save" />}
          primary={true}
          onTouchTap={onSubscribe} />
      </div>}
    </div>
  )
}

export { TokenActionButtons }
