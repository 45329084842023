import React, { Component } from 'react'
import classNames from 'classnames'
import ArrowExpandDownIcon from '../../../Icons/arrowExpandDown'

import './CollapsibleSection.css'

class CollapsibleSection extends Component {
  state = {
    open: false
  }

  renderHeaderIcon = () => {
    return <ArrowExpandDownIcon />
  }

  handleHeaderClick = () => {
    this.setState({ open: !this.state.open })
  }

  render() {
    return <div className={classNames('collapsible-section', this.props.className, { open: this.state.open })}>
      <div className="collapsible-section-header" onClick={this.handleHeaderClick}>
        <div className="collapsible-section-title">
          {this.props.title}
        </div>
        <div className="collapsible-section-icon">{this.renderHeaderIcon()}</div>
      </div>
      <div className="collapsible-section-body">
        {this.props.children}
      </div>
    </div>
  }
}

export { CollapsibleSection }
