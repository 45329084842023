import React from 'react'
import { connect } from 'react-redux'
import { ItemsPicker } from '../../../../Common/ItemsPicker/ItemsPicker'
import PeopleIcon from 'material-ui/svg-icons/social/group'
import GroupDetailsLink from '../../../Contacts/GroupDetails/GroupDetailsLink'

import contactActions from '../../../../../actions/contact'

import './GroupMembership.css'

const TRANSLATIONS = {
  MENU_PICKER_LOADING: 'LOADING',
  MENU_PICKER_ADD: 'GROUP_CREATE_BY_NAME',
  MENU_PICKER_SEARCH: 'GROUP_SEARCH_BY_NAME'
}

class GroupMembershipComp extends React.PureComponent {
  state = {
    isEnabled: false
  }

  onChange = (group, checked) => {
    const user = this.props.user

    if (!checked) {
      this.props.onAddGroupMember(user, group)
    } else {
      this.props.onRemoveGroupMember(user, group)
    }
  }

  onCreateGroup = (groupName) => {
    this.props.onCreateUserGroup(this.props.user, { name: groupName })
  }

  onEnable = () => {
    this.setState({ isEnabled: true })
  }

  render() {
    const props = this.props
    const user = props.user
    const userGroups = user.groups || []
    const groups = props.groups.items

    return (
      <div className="group-membership-next-gen">
        <ItemsPicker
          itemIcon={<PeopleIcon />}
          renderItem={item => <GroupDetailsLink nextGen group={item} />}
          isLoading={props.groups.isLoading}
          items={groups}
          selectedItems={userGroups}
          valueProp="name"
          translations={TRANSLATIONS}
          onChange={this.onChange}
          onAdd={this.onCreateGroup}
          forceFixedDropdownPosition />
      </div>
    )
  }
}

export { GroupMembershipComp }

const groupMembership = (state, props) => ({
  groups: state.contact.groups
})

const groupMembershipActions = contactActions

export default connect(groupMembership, groupMembershipActions)(GroupMembershipComp)
