import React from 'react'
import classNames from 'classnames'

import './DashboardActionButton.css'

const DashboardActionButton = ({ onClick, className, text, ...rest }) => {
  const handleClick = () => {
    onClick && onClick()
  }

  return (
    <button
      type="button"
      className={classNames('dashboard-action-button', className)}
      onClick={handleClick}
      {...rest}>
      {text}
    </button>
  )
}

export { DashboardActionButton }
