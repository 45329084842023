import React from 'react'

import './DataTable.css'

const DataTable = props => {
  const { fieldSelectors, dataObj } = props

  return <div className="data-table-container">
    {(fieldSelectors || []).map((f, i) => (
      <div className="data-table-row" key={i}>
        <div className="data-table-field-name">{f.name}</div>
        <div className="data-table-field-value">{f.render(dataObj)}</div>
      </div>
    ))}
  </div>
}

export { DataTable }
