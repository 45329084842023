import * as ActionTypes from '../types'
import { createListActions } from 'skylight-common'
import skylight from 'skylight-js-sdk'

const loadGroupMembers = async(query) => {
  const g = await skylight.group.getById(query.id)
  const userIds = g.members ? g.members.map(x => x.id) : []
  const users = await Promise.all(userIds.map(skylight.user.getUserFromCacheById))
  return users
}

const GROUP_MEMBER_LIST_ACTIONS = createListActions({
  prefix: ActionTypes.PREFIX_GROUP_MEMBER.toUpperCase(),
  handler: ActionTypes.PREFIX_GROUP_MEMBER,
  onGetList: s => s.contact.groupMembers,
  onLoad: loadGroupMembers,
  onDelete: (ids) => { throw new Error('delete group members not implemented') }// deleteGroups
})

export default {
  ...GROUP_MEMBER_LIST_ACTIONS
}
