import mqttHandler from '../actions/mqtt'
import * as ActionTypes from '../actions/types'

import skylight from 'skylight-js-sdk'

const getReconnectInSeconds = (start, interval) => {
  const now = Date.now()
  return Math.max(0, Math.ceil((start + interval - now) / 1000))
}

const mqtt = store => next => {
  let reconnectStart
  let reconnectInterval
  skylight.events.on(skylight.mqtt.EVENTS.MESSAGE, ({ topic, message }) => {
    const topicSegments = topic.split('/')
    const isNextGenTopic = topicSegments.includes('v1')
    if (topicSegments.length > 3) { // Dispatch mqtt `mqtt-tasks` action
      next(mqttHandler({
        type: isNextGenTopic ? topicSegments[3] : topicSegments[2],
        topic,
        payload: message
      }))
    }
  })

  skylight.events.on(skylight.mqtt.EVENTS.CONNECTION_STATE_CHANGE, state => {
    next({ type: ActionTypes.MQTT_STATE_CHANGED, state })
  })

  skylight.events.on(skylight.mqtt.EVENTS.RECONNECT_SCHEDULED, reconnectTimeout => {
    reconnectStart = Date.now()
    next({
      type: ActionTypes.MQTT_RECONNECT_IN,
      reconnectIn: getReconnectInSeconds(reconnectStart, reconnectTimeout)
    })

    clearInterval(reconnectInterval)
    reconnectInterval = setInterval(() => {
      const reconnectIn = getReconnectInSeconds(reconnectStart, reconnectTimeout)
      next({
        type: ActionTypes.MQTT_RECONNECT_IN,
        reconnectIn
      })

      if (reconnectIn <= 0) {
        clearInterval(reconnectInterval)
      }
    }, 1000)
  })

  return action => {
    return next(action)
  }
}

export default mqtt
