import React from 'react'
import { Switch, Route } from 'react-router'
import { CallList, CallData } from '../../../../containers/activity'

const Calls = props => {
  return <Switch>
    <Route
      path={`${props.match.url}/:callId`}
      component={CallData} />
    <Route
      path={`${props.match.url}`}
      component={CallList} />
  </Switch>
}

export { Calls }
