import React from 'react'
import { Route } from 'react-router-dom'
import { ContactTabs } from './ContactTabs/ContactTabs'
import './Contacts.css'

const Contacts = (props) => {
  const { match } = props
  return <div className="contacts">
    <Route
      path={`${match.url}`}
      render={() => <ContactTabs {...props} type={match.params.type} /> } />
  </div>
}

export { Contacts }
