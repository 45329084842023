import React from 'react'
import CircularProgress from 'material-ui/CircularProgress'
import { FormattedMessage } from 'react-intl'
import './CallReconnecting.css'

export const CallReconnecting = props => {
  return (
    <div className="call-reconnecting">
      <FormattedMessage id="CALL_TRY_RECONNECT" defaultMessage="Trying to re-establish connection..." />
      <CircularProgress />
    </div>
  )
}
