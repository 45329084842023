import React from 'react'
import RaisedButton from 'material-ui/RaisedButton'
import classNames from 'classnames'

import './LargeRaisedButton.css'

export default ({ primary = false, type = 'button', icon, label, className = '', ...other }) => {
  return (
    <RaisedButton
      className={classNames('login-large-button', className, { primary })}
      primary={primary}
      fullWidth
      type={type}
      {...other}>
      <div className="login-large-button-content">
        {icon}
        <div className="login-large-button-label">{label}</div>
      </div>
    </RaisedButton>
  )
}
