import React from 'react'
import { ItemProps } from '../../../Common'
import { isMobile } from 'skylight-common'
import { ShowCredentialsLink } from '../TokenList'
import './TokenItemDetails.css'

const PROPS = [
  { field: 'name', title: 'NAME', type: 'string' },
  { field: 'id', title: 'INTEGRATION_ID', type: 'string' },
  { field: 'creator', title: 'CREATOR', type: 'user' },
  { field: 'description', title: 'DESCRIPTION', type: 'string' }
]

const TokenItemDetails = (props) => {
  if (!isMobile()) {
    return null
  }

  return (
    <div className="token-item-details">
      <ShowCredentialsLink
        token={props.item}
        tooltipPosition="bottom-left"
        onShowTokenCredentials={props.onShowTokenCredentials} />
      <ItemProps item={props.item} props={PROPS} />
    </div>
  )
}

export { TokenItemDetails }
