import React from 'react'
import classNames from 'classnames'
import SelectField from 'material-ui/SelectField'
import ArrowExpandDown from '../../../Common/Icons/arrowExpandDown'
import { OutlinedField } from '../OutlinedField'

import './OutlinedSelectField.css'

const OutlinedSelectField = props => {
  const selectedMenuItemStyle = {
    color: '#00aaed',
    ...props.selectedMenuItemStyle
  }

  return (
    <OutlinedField
      {...props}
      className={classNames('outlined-select-field', props.className)}>
      <SelectField
        className="select-field"
        dropDownMenuProps={{
          iconButton: <ArrowExpandDown className="items-picker-arrow" />
        }}
        selectedMenuItemStyle={selectedMenuItemStyle}
        name={props.name}
        disabled={props.disabled}
        onChange={props.onChange}
        value={props.value}>
        {props.children}
      </SelectField>
    </OutlinedField>
  )
}

export { OutlinedSelectField }
