import React from 'react'
import { FormattedMessage } from 'react-intl'

export const CallStatus = (props) => {
  const { connection = {} } = props
  const state = connection.state || 'LOADING'

  return (
    <div className="call-status">
      <FormattedMessage id={`CALL_STATUS_${state}`} defaultMessage={state} />
    </div>
  )
}
