import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Tooltip, UserTitle, CursorTooltip } from 'skylight-common'
import classNames from 'classnames'
import get from 'lodash/get'
import GlyphIcon from '../../../../Common/GlyphIcon/GlyphIcon'
import ArchiveApplicationIcon from 'material-ui/svg-icons/content/archive'
import WarningIcon from 'material-ui/svg-icons/alert/warning'
import { formatDate } from '../../../../../lib/date'
import CircularProgress from 'material-ui/CircularProgress'
import { isFailedImport } from '../../../../../lib/application/exportImport'
import { DEFAULT_APP_ICON_CODE } from '../../../../../lib/application'

import './ApplicationTile.css'

class ApplicationTile extends Component {
  getClassName = (nextProps) => {
    const { item, selectedIds } = nextProps || this.props
    const isArchived = item.archived
    const importing = item.importing
    const isSelected = selectedIds.length === 1 && selectedIds.includes(item.id)
    const isMultiSelected = selectedIds.length > 1 && selectedIds.includes(item.id)

    return classNames('next-gen-application-tile', {
      archived: isArchived,
      selected: isSelected,
      importing,
      'multi-selected': isMultiSelected
    })
  }

  onSelect = (e) => {
    e.stopPropagation()
    const { selectedIds } = this.props
    const { id, importing } = this.props.item
    if (importing) return
    const { metaKey, ctrlKey, shiftKey } = e.nativeEvent
    if (shiftKey) {
      this.props.onShiftSelect(id)
      return
    }

    const sel = metaKey || ctrlKey
      ? (selectedIds.includes(id)
          ? selectedIds.filter(x => x !== id)
          : [...selectedIds, id])
      : id

    this.props.onCheck(sel)
  }

  onOpenDashboard = () => {
    const { item, onApplicationDashboardOpen } = this.props
    if (item.importing || isFailedImport(item)) return
    onApplicationDashboardOpen(item)
  }

  shouldComponentUpdate(nextProps) {
    const { item } = this.props
    const { item: nextItem } = nextProps

    // Rerender only if className or the item itself was updated.
    return item !== nextItem ||
      this.getClassName() !== this.getClassName(nextProps)
  }

  renderArchivedIcon = () => {
    return <Tooltip
      className="icon-wrapper"
      tooltipPosition={{
        horizontalPosition: 'right',
        verticalPosition: 'bottom'
      }}
      tooltip={<FormattedMessage id="APPLICATION_ARCHIVED" defaultMessage="Archived" />}>
      <ArchiveApplicationIcon className="top-left-icon archived-icon" />
    </Tooltip>
  }

  renderLeftIcon = item => {
    if (!item) {
      return null
    }

    const isArchived = item.archived
    if (isArchived) {
      return this.renderArchivedIcon()
    }

    return null
  }

  renderMainIcon = item => {
    if (!item) return null

    const importing = item.importing
    if (importing) {
      return <CircularProgress size={46} thickness={6} />
    }

    if (isFailedImport(item)) {
      return <WarningIcon className="icon failed-import-icon" />
    }

    const applicationIconName = get(item, 'icon.name', DEFAULT_APP_ICON_CODE)
    return <GlyphIcon code={applicationIconName} />
  }

  renderActions = item => {
    const importing = item.importing
    if (importing) {
      return <FormattedMessage className="importing-message" id="IMPORTING" defaultMessage="Importing..." />
    }

    return this.props.renderActions(item)
  }

  render() {
    const { item, permissions } = this.props
    const importing = item.importing
    const failedImport = isFailedImport(item)
    // onDoubleClick should not work for mobile devices.
    return (
      <div
        className={this.getClassName()}
        onTouchTap={this.onSelect}
        onDoubleClick={this.onOpenDashboard}>
        <CursorTooltip
          className="content"
          tooltip={importing ? <FormattedMessage id="IMPORTING_APPLICATION" defaultMessage="Importing application" /> : null}>
          {this.renderLeftIcon(item, permissions)}
          {this.renderMainIcon(item)}
          <Tooltip tooltip={item.description}>
            <div className="name">
              {item.name}
              {failedImport
                ? (
                <div className="import-failed">
                  <FormattedMessage id="IMPORT_FAILED" className="Import failed" />
                </div>)
                : null}
            </div>
          </Tooltip>
          <div className="details">
            <div className="creator">
              <UserTitle user={item.createdBy} />
            </div>
            <div className="time">{formatDate(item.modifiedAt)}</div>
          </div>
        </CursorTooltip>
        <div className="actions">
          {this.renderActions(item)}
        </div>
      </div>
    )
  }
}

export { ApplicationTile }
