import React, { Component } from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { Loading, Form } from 'skylight-common'
import { FooterActionButtons } from '../../../Common'

import { BandwidthSection } from './BandwidthSection'
import { MediaAutosaveSection } from './MediaAutosaveSection'
import { ServerSection } from './ServerSection'

import './AdminLive.css'

const FIELDS = [
  { prop: 'url', required: true, label: { id: 'ADMIN_LIVE_URL', default: 'Enter STUN or TURN URL' } },
  { prop: 'username', required: false, label: { id: 'USERNAME', default: 'Username' } },
  { prop: 'credential', required: false, label: { id: 'PASSWORD', default: 'Password' }, type: 'password' }
]

class AdminLive extends Component {
  componentDidMount() {
    this.props.onTurnConfigLoad()
    this.props.onRealmLoad()
  }

  getBandwidthForm = () => {
    const { bandwidthForm } = this.state

    // API returns 500 if bandwidthLimit is not null/0 on disabled
    return bandwidthForm.bandwidthLimitEnabled
      ? bandwidthForm
      : { ...bandwidthForm, bandwidthLimit: null }
  }

  onCancel = () => {
    const { live, realm, onRealmCancel, onTurnConfigCancel } = this.props
    if (live.hasChanged) onTurnConfigCancel()
    if (realm.hasChanged) onRealmCancel()
  }

  onSave = () => {
    const { live, realm, onRealmSave, onTurnConfigSave } = this.props
    if (live.hasChanged) onTurnConfigSave(live.item)
    if (realm.hasChanged) onRealmSave(realm.item)
  }

  render() {
    const {
      live, realm, onRealmChange, onTurnConfigChange, onConfirm,
      onTurnConfigSave, onTurnConfigLoad
    } = this.props
    const hasChanged = realm.hasChanged || live.hasChanged
    const isLoading = realm.isLoading || live.isLoading || !realm.item || !live.item
    const loadError = realm.loadError || live.loadError
    const liveItem = { enabled: false, ...live.item }

    return (
      <div className={classNames('admin-live', this.props.className)}>
        <Loading
          isLoading={isLoading} error={loadError
            ? <FormattedMessage
              id={loadError}
              defaultMessage="Failed to retrieve realm configuration." />
            : null}>
          <Form
            hasChanged={hasChanged}
            onSubmit={() => /* form.enabled && */ hasChanged && this.onSave()}
            onEscapePress={() => hasChanged && this.onCancel()}>
            <BandwidthSection form={realm.item} onChange={onRealmChange} />
            <MediaAutosaveSection form={realm.item} onChange={onRealmChange} />
            <ServerSection
              form={liveItem}
              original={live.original}
              errors={live.errors}
              fields={FIELDS}
              onConfirm={onConfirm}
              onChange={onTurnConfigChange}
              onSave={onTurnConfigSave}
              onLoad={onTurnConfigLoad} />
            {/* TODO: Move to a separate component */}
            {hasChanged && <FooterActionButtons
              onCancel={this.onCancel}
              onSave={this.onSave} />}
          </Form>
        </Loading>
      </div>
    )
  }
}

export { AdminLive }
