import { CONFIG } from 'skylight-common'
import skylight from 'skylight-js-sdk'

export const NG_DOCS_URL = CONFIG.ngDocsUrl || 'https://docs.upskill.io'
export const NG_WHATS_NEW_URL = `${NG_DOCS_URL}/whats-new`

export const getVersions = () => {
  const APPS = [
    {
      title: 'APP_WEB',
      getVersion: () => Promise.resolve(CONFIG.version)
    },
    {
      title: 'APP_BUILDER',
      getVersion: async() => {
        const health = await skylight.http.getJSON(`${CONFIG.builderUrl}/health`)
        return health.Version
      }
    }
  ]

  return Promise.all(APPS.map(app => {
    return app.getVersion().then(version => ({
      title: app.title,
      version: version
    })).catch(error => {
      const err = {
        ...new Error(error.message),
        title: app.title,
        error: true
      }
      return err
    })
  })).then(apps => apps.filter(x => x !== null))
}
