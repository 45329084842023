import React from 'react'
import { Toggle, TextField, SelectField, MenuItem } from 'material-ui'
import { FormattedMessage } from 'react-intl'
import { RequiredLabel } from 'skylight-common'

import './PinLock.css'

const PinLock = props => {
  const { item, errors } = props.realm
  if (!item) return null

  return <div className="pin-lock">
    <div className="title">
      <FormattedMessage id="REALM_PIN_LOCK" defaultMessage="PIN lock" />
    </div>
    <div className="description"><FormattedMessage id="REALM_PIN_TOOLTIP" /></div>
    <Toggle
      className="pin-toggle"
      toggled={item.pinTimeoutEnabled}
      onToggle={(e, v) => props.onChange('pinTimeoutEnabled', v)} />

    {item.pinTimeoutEnabled && <div className="config-wrapper">
      <SelectField
        className="pin-length dropdown-with-hover"
        floatingLabelText={<RequiredLabel>
          <FormattedMessage id="REALM_PIN_LENGTH" defaultMessage="Pin character length" />
        </RequiredLabel>}
        errorText={errors.pinLockLength
          ? <FormattedMessage
            id={errors.pinLockLength}
            defaultMessage="Required" />
          : null}
        selectedMenuItemStyle={{ color: '#00aaed' }}
        value={item.pinLockLength}
        onChange={(e, k, v) => props.onChange('pinLockLength', v)}>
        {[...Array(8).keys()].map(x => <MenuItem key={x} value={x + 2} primaryText={x + 2} />)}
      </SelectField>
      <TextField
        className="pin-lock-message"
        value={item.pinLockMessage}
        floatingLabelText={
          <FormattedMessage id="REALM_PIN_MESSAGE" defaultMessage="Pin lock message" />
        }
        onChange={(e, v) => props.onChange('pinLockMessage', v)} />
      <div className="description"><FormattedMessage id="REALM_MESSAGE_DESCRIPTION" /></div>
    </div>}

  </div>
}

export { PinLock }
