import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Loading, SortPicker, MaterialIcon,
  GridHeader, isMobile
  , ActionButton, ListActionButton, ListFooterStats, DeleteIcon
} from 'skylight-common'
import { ApplicationGrid } from './ApplicationGrid'
import { ApplicationTable } from './ApplicationTable'

import skylight from 'skylight-js-sdk'
import AddApplicationIcon from '../../../Common/Icons/addApplication'
import { WORKFLOW } from '../../../../svc/scopes'
import { COLUMNS } from './columns'

import ListIcon from 'material-ui/svg-icons/action/view-list'
import GridIcon from 'material-ui/svg-icons/action/view-module'

import AssignIcon from 'material-ui/svg-icons/social/person-add'
import LockIcon from 'material-ui/svg-icons/action/lock'
import CopyIcon from 'material-ui/svg-icons/image/filter-none'
import IconButton from 'material-ui/IconButton'

import './ApplicationList.css'

const customTitle = (
  <span className="lock-application-modal-title">
    <LockIcon />
    <FormattedMessage
      id="APPLICATIONS_LOCK_DIALOG_TITLE"
      defaultMessage="Are you sure you want to lock the application(s)?" />
  </span>
)

// TODO: Cleanup, seems messy.
const ApplicationList = (props) => {
  const { list, user } = props
  const { view } = list.query
  const nextView = view === 'list' ? 'grid' : 'list'
  const selectedItems = list.selectedIds.map(id => list.items.find(el => el.id === id))
  const showLock = !!selectedItems.filter(x => x && !x.isLocked).length
  const showDuplicate = selectedItems.length === 1 && selectedItems[0].isLocked

  const onAssignmentByApp = (item) => {
    props.onClearItemStatus(item.id)
    props.onAssignmentByApp(item)
  }

  const onChangeView = (e) => {
    e.preventDefault()
    props.onChangeApplicationView(nextView)
  }

  const onDeleteWorkflows = (e) => {
    e.preventDefault()
    props.onConfirm(
      () => props.onWorkflowDeleteItems(list.selectedIds),
      {
        title: 'APPLICTIONS_REMOVE_DIALOG_TITLE',
        message: 'APPLICTIONS_REMOVE_DIALOG_DESCRIPTION',
        action: 'ACTION_DELETE_APPLICATIONS'
      }
    )
  }

  const onLockApplications = () => {
    props.onConfirm(
      () => props.onLockApplications(selectedItems.filter(x => !x.isLocked).map(x => x.id)),
      {
        customTitle,
        message: 'APPLICATIONS_LOCK_DIALOG_DESCRIPTION',
        action: 'ACTION_LOCK_APPLICATION',
        className: 'lock-application-modal'
      }
    )
  }

  const onDuplicateApplication = () => {
    props.onShowDuplicateAppDialog(selectedItems[0])
  }

  const isOwnWorkflow = () => {
    return user.role === 'manager' && list.items
      .filter(x => list.selectedIds.includes(x.id))
      .every(x => x.createdBy === user.id)
  }

  const isUnlockedWorkflows = () => {
    return selectedItems.every(x => !x.isLocked)
  }

  const isDeleteAllowed = () => isOwnWorkflow() && isUnlockedWorkflows()

  const getHeadingActions = () => {
    const actions = [
      {
        icon: LockIcon,
        hidden: !showLock,
        label: { id: 'ACTION_LOCK_APPLICATION', default: 'Lock application' },
        action: onLockApplications,
        scopes: [WORKFLOW.LOCK],
        predicate: () => false
      },
      {
        icon: CopyIcon,
        hidden: !showDuplicate,
        className: 'copy-icon',
        label: { id: 'ACTION_DUPLICATE_APPLICATION', default: 'Duplicate application' },
        action: onDuplicateApplication,
        scopes: [WORKFLOW.LOCK],
        predicate: () => false
      },
      {
        icon: AssignIcon,
        show: 'multi',
        label: { id: 'ACTION_ASSIGN_APPLICATION', default: 'Assign application' },
        action: () => props.onAssignApplication(list.selectedIds),
        scopes: []
      },
      {
        icon: DeleteIcon,
        show: 'selected',
        label: { id: 'ACTION_DELETE_APPLICATION', default: 'Delete application' },
        action: onDeleteWorkflows,
        scopes: [WORKFLOW.DELETE, WORKFLOW.DELETE_LOCKED],
        predicate: isDeleteAllowed
      },
      {
        icon: view === 'list' ? GridIcon : ListIcon,
        show: 'always',
        label: { id: `ACTION_${nextView.toUpperCase()}_VIEW`, default: 'Switch view' },
        action: onChangeView,
        scopes: []
      }
    ]
      .filter(a => !a.hidden && (skylight.auth.hasScopes(a.scopes) || (a.predicate ? a.predicate() : true)))
      .map(a => <ActionButton
        key={a.label.id}
        icon={<a.icon className={a.className} />}
        show={a.show}
        tooltipPosition={a.tooltipPosition}
        label={<FormattedMessage id={a.label.id} defaultMessage={a.label.default} />}
        onTouchTap={a.action} />)

    if (view === 'grid') {
      actions.push(<SortPicker
        query={list.query}
        columns={COLUMNS.filter(x => x.sortable !== false)}
        onChange={props.onApplicationSortUpdate} />)
    }

    return actions
  }

  const onItemAction = (e, action) => {
    e.stopPropagation()
    action()
  }

  const getItemActions = item => {
    return [
      {
        icon: 'mode_edit',
        action: () => props.onOpenApplication(item),
        className: 'application-edit',
        tooltip: { id: 'ACTION_EDIT_APPLICATION', default: 'Edit application' },
        isDesktop: true
      },
      {
        icon: 'assignment',
        action: () => props.onAssignmentByApp(item),
        tooltip: { id: 'ACTION_VIEW_ASSIGNMENTS', default: 'View assignments' }
      },
      {
        icon: 'person_add',
        action: () => props.onAssignApplication([item.id]),
        tooltip: { id: 'ACTION_ASSIGN_APPLICATION', default: 'Assign application' }
      }
    ].filter(x => x.isDesktop ? !isMobile() : true)
      .map(a => <IconButton
        key={a.action}
        className={`item-action-button ${a.className || ''}`}
        tooltipPosition="bottom-center"
        tooltip={<FormattedMessage
          id={a.tooltip.id}
          defaultMessage={a.tooltip.default} />}
        onTouchTap={e => onItemAction(e, a.action)}><MaterialIcon icon={a.icon} /></IconButton>)
  }

  const renderApplicationView = () => {
    const ListView = view === 'list' ? ApplicationTable : ApplicationGrid

    const listProps = view === 'list'
      ? {
          tableHeaderActions: getHeadingActions()
        }
      : {}

    return (
      <ListView
        {...props}
        {...listProps}
        onAssignmentByApp={onAssignmentByApp}
        renderItemActions={getItemActions}
        listFooter={list.selectedIds.length > 0 &&
        <ListFooterStats list={list} onUnselect={props.onWorkflowSelectItems} />}
        heading={p => <GridHeader {...p} includeCheckbox={true} actions={getHeadingActions()} />} />
    )
  }

  return (
    <div className="dashboard-list application-list">
      <Loading
        isLoading={list.isLoading && list.items.length === 0}
        message="LOADING_APPLICATION_LIST"
        error={list.error
          ? <FormattedMessage
          id="EMPTY_WORKFLOW_LIST"
          defaultMessage="No applications are created yet" />
          : null}>
        {renderApplicationView()}
      </Loading>
      {!isMobile() && <ListActionButton
        tooltip={<FormattedMessage id="NEW_APPLICATION" defaultMessage="New application" />}
        icon={<AddApplicationIcon />}
        onAction={props.onNewWorkflow} />}
    </div>
  )
}

export { ApplicationList }
