import React from 'react'
import { Snackbar } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import CircularProgress from 'material-ui/CircularProgress'

import './SnackbarLoading.css'

const SnackbarLoading = props => {
  const { snackbar, snackbarType } = props
  const { open, params } = snackbar[snackbarType] || {}

  const renderMessage = () => {
    const { message } = params
    return <FormattedMessage id={message} />
  }

  return (
    <Snackbar className="snackbar-loading" open={open}>
      <div className="snackbar-message">
        {renderMessage()}
      </div>
      <div className="loading-indicator">
        <CircularProgress size={20} thickness={3} />
      </div>
    </Snackbar>
  )
}

export { SnackbarLoading }
