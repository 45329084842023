import * as ActionTypes from '../../actions/types'

const initialState = {
  username: '',
  password: '',
  realm: '',
  errors: {},
  isSigningIn: false,
  staySigned: true,
  errorSignOut: false
}

const signin = (state = initialState, payload, all) => {
  switch (payload.type) {
    case ActionTypes.SIGNIN_USERNAME_CHANGED:
      return { ...state, username: payload.value, errors: {} }
    case ActionTypes.SIGNIN_PASSWORD_CHANGED:
      return { ...state, password: payload.value, errors: {} }
    case ActionTypes.SIGNIN_REALM_CHANGED:
      return { ...state, realm: payload.value, errors: {} }
    case ActionTypes.SIGNIN_REALM_SIGNIN:
      return { ...state, realm: payload.realm || state.realm }
    case ActionTypes.SIGNIN_STAY_SIGNED_CHANGED:
      return { ...state, staySigned: payload.value }
    case ActionTypes.SIGNIN_SIGNIN:
      return { ...state, isSigningIn: true }
    case ActionTypes.SIGNIN_ERROR:
      return { ...state, password: '', errors: payload.errors, isSigningIn: false }
    case ActionTypes.AUTH_SIGNED_IN:
      return { ...initialState, username: state.username, realm: state.realm, staySigned: state.staySigned }
    case ActionTypes.AUTH_REFRESH_TOKEN_ERROR:
      return { ...state, errorSignOut: true }
    case ActionTypes.SIGNIN_IDP_TOKEN_LOADING:
      return { ...state, isSigningIn: true }
    case ActionTypes.SIGNIN_IDP_TOKEN_ERROR:
      return { ...state, isSigningIn: false, error: 'AUTH_IDP_ERROR' }
    case ActionTypes.SIGNIN_IDP_TOKEN_LOADED:
      return { ...state, isSigningIn: false }
    case ActionTypes.SIGNIN_ERRORS_CLEAR:
      return { ...state, errors: {} }
    default:
      return state
  }
}

export default signin
