import React from 'react'
import CircularProgress from 'material-ui/CircularProgress'

export const Loader = () => (
  <div className="progress-indicator-wrapper">
    <div className="progress-indicator">
      <CircularProgress />
    </div>
  </div>
)
