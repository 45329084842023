import React from 'react'
import EventList from './EventList/EventList'

const Events = props => {
  return (
    <div className="events">
      <EventList applicationId={props.applicationId} sessionId={props.sessionId} />
    </div>
  )
}

export { Events }
