import React, { Component } from 'react'
import { UserAttributesMapping } from './UserAttributesMapping'
import { SKYLIGHT_ATTRIBUTES, SKYLIGHT_ATTRIBUTES_MAP } from '../../../../../lib/admin'

const NONE_ATTRIBUTE = { value: null, title: 'LDAP_FIELD_METHOD_NONE' }

class UserAttributesBody extends Component {
  getAvailableOptions(includeValue = '', additionalOptions = []) {
    const { customMappings } = this.props

    return [...additionalOptions, ...SKYLIGHT_ATTRIBUTES]
      .filter(({ value }) => {
        return value === includeValue || !customMappings.some(({ skylightAttribute }) => skylightAttribute === value)
      })
      .sort((a, b) => `${a.value}`.localeCompare(`${b.value}`))
  }

  onChange = (idx) => (field, value) => {
    if (value === null) {
      return
    }

    this.props.onMappingChange(idx, field, value)
  }

  onDelete = (idx) => () => {
    this.props.onRemoveMapping(idx)
  }

  render() {
    const { customMappings, enableNewRow } = this.props
    const availableOptions = this.getAvailableOptions()
    return (
      <div className="user-attributes-body">
        {customMappings.map(({ skylightAttribute, ldapAttribute }, idx) =>
          <UserAttributesMapping
            key={skylightAttribute}
            skylightAttribute={skylightAttribute}
            ldapAttribute={ldapAttribute}
            options={this.getAvailableOptions(skylightAttribute, SKYLIGHT_ATTRIBUTES_MAP[skylightAttribute])}
            onChange={this.onChange(idx)}
            onDelete={this.onDelete(idx)} />
        )}
        {availableOptions.length > 1 && <UserAttributesMapping
          key={customMappings.length}
          options={[NONE_ATTRIBUTE, ...availableOptions]}
          onChange={this.onChange(customMappings.length)}
          disabled={!enableNewRow} />}
        {customMappings.length === 0 && <UserAttributesMapping
          key="newRow"
          options={[NONE_ATTRIBUTE]}
          disabled />}
      </div>
    )
  }
}

export { UserAttributesBody }
