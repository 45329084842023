import React from 'react'
import UserDetailsLink from '../../../../Contacts/UserDetails/UserDetailsLink'
import { formatDateTime } from 'skylight-common'

export const DEFAULT_DETAILS_PROPS = [
  { field: 'name', title: 'APPLICATION_NAME', type: 'string' },
  { field: 'createdBy', title: 'IMPORTED_BY', type: 'render', render: item => <UserDetailsLink nextGen userId={item.createdBy} /> },
  { field: 'createdAt', title: 'IMPORTED_ON', type: 'render', render: item => formatDateTime(item.createdAt) },
  { field: 'import.error.message', title: 'ERROR', type: 'string', className: 'error-message-field' },
  { field: 'import.error.code', title: 'ERROR_CODE', type: 'string' }
]
