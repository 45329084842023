import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { canSeeNextGen } from '../../../svc/scopes'

import ApplicationDashboard from './Applications/ApplicationDashboard/ApplicationDashboard'
import { Applications } from './Applications'
import UserList from './Users/UserList/UserList'
import GroupList from './Groups/GroupList/GroupList'
import { NextGenHomePage } from '../../../containers/homePage'
import Language from './DomainSettings/Language/Language'
import Authentication from './DomainSettings/Authentication/Authentication'

import './HomeNextGen.css'

const HomeNextGen = props => {
  if (!canSeeNextGen()) {
    return <Redirect to="/" />
  }

  return <div className="home-body-next-gen">
    <Route path={`${props.match.url}/applications`} exact component={Applications} />
    <Route path={`${props.match.url}/applications/:applicationId`} render={routeProps => <ApplicationDashboard match={routeProps.match} />} />
    <Route path={`${props.match.url}/users`} component={UserList} />
    <Route path={`${props.match.url}/groups`} component={GroupList} />
    <Route path={`${props.match.url}/auth/:type?`} render={Authentication} />
    <Route path={`${props.match.url}/locale`} component={Language} />
    <Route path={`${props.match.url}`} exact render={() => <NextGenHomePage {...props} />} />
  </div>
}

export { HomeNextGen }
