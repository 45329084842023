import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import SearchIcon from 'material-ui/svg-icons/action/search'

import { TextFilter } from 'skylight-common'

const { string, bool, number, oneOfType, func, element } = PropTypes

const DialogTitle = props => {
  return (
    <div className="add-to-existing-group-dialog-title-wrapper">
      <div className="add-to-existing-group-dialog-title">
        <p>
          <FormattedMessage id={props.id} defaultMessage={props.defaultMessage} />
        </p>
      </div>
      {props.isGroups && (
        <div className="dialog-search-filter-wrapper">
          <TextFilter
            value={props.searchValue}
            onChange={props.onSearchChange}
            title="SEARCH_GROUPS"
            iconFilter={<SearchIcon />}/>
        </div>
      )}
    </div>
  )
}

DialogTitle.propTypes = {
  id: string.isRequired,
  defaultMessage: string,
  isGroups: bool.isRequired,
  searchValue: string.isRequired,
  onSearchChange: func.isRequired
}

const DialogFooter = ({ isUserPick }) => {
  return (
    <div className="footer-wrapper">
      {isUserPick && (
        <div className="footer-content">
          <CircleIcon element={2} text="Select group(s):" active={false} />
        </div>
      )}
    </div>
  )
}

DialogFooter.propTypes = { isUserPick: bool }

const CircleIcon = ({ element, text, active }) => {
  return (
    <div className="circle-wrapper">
      <div className={`circle-number-${active ? 'blue' : 'grey'}`}>{element}</div>
      <div className="circle-number-text">{text}</div>
    </div>
  )
}

CircleIcon.propTypes = {
  element: oneOfType([number, string, element]).isRequired,
  text: string.isRequired,
  active: bool
}

export { DialogTitle, DialogFooter, CircleIcon }
