import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const addRole = props => (
  <SvgIcon {...props}>
    <path d="M6,16 L6,19 L8.61144614,19.0012798 C8.73817177,19.1119372 8.8677351,19.2192692 8.99999921,19.3231615 L9,21 L6,21 L6,24 L4,24 L4,21 L1,21 L1,19 L4,19 L4,16 L6,16 Z M12,1 L21,5 L21,11 C21,16.55 17.16,21.74 12,23 C11.3075497,22.8309133 10.6388705,22.5910531 10.0000527,22.289047 L9.99943712,20.0115884 C10.6254713,20.3872326 11.2960393,20.6931311 12,20.92 C14.11,20.24 15.92,18.85 17.13,17 C17.47,16.5 17.76,16 18,15.47 C18,13.79 15.29,12.47 12,12.47 C9.02847643,12.47 6.53009819,13.5712865 6.0742501,15.0002192 L3.66755938,15.0008679 C3.2352577,13.7253914 3,12.3737234 3,11 L3,11 L3,5 L12,1 Z M12,5 C10.3431458,5 9,6.34314575 9,8 C9,9.65685425 10.3431458,11 12,11 C13.6568542,11 15,9.65685425 15,8 C15,6.34314575 13.6568542,5 12,5 Z" id="path-1" />
  </SvgIcon>
)

export default addRole
