import * as ActionTypes from '../../actions/types'
import { createItemReducer, getListActionTypes } from 'skylight-common'

const initialState = {
  previewId: null
}

const baseItem = createItemReducer({
  actionPrefix: ActionTypes.PREFIX_MEDIA,
  initialState
})

const item = (prevState = initialState, payload, all) => {
  const state = baseItem(prevState, payload)
  const listActions = getListActionTypes(state.prefix)
  switch (payload.type) {
    // case ActionTypes.MEDIA_LIST_SELECTED:
    //   if (payload.selectedIds.length === 1) {
    //     const found = payload.items.find(x => x.id === payload.selectedIds[0])
    //     if (found) {
    //       return {mode: 'view', item: found, mediaView: state.mediaView, previewId: state.previewId}
    //     }
    //   }
    //   return {...initialState, mediaView: state.mediaView}

    // case ActionTypes.MEDIA_EDIT:
    //   return { ...state, mode: 'edit', original: payload.media }

    // case ActionTypes.MEDIA_PROP_CHANGE:
    //   return {...state, item: {...state.item, [payload.prop]: payload.value}}

    // case ActionTypes.MEDIA_EDIT_CANCEL:
    //   return { ...state, mode: 'view', item: state.original, original: null }
    // case ActionTypes.MEDIA_SAVE_SUCCESS:
    //   return {...state, mode: 'view', item: {...state.item, ...payload.item}}
    // case ActionTypes.PREFIX_LIST_GROUPS + ActionTypes.LIST_REMOVING:
    // return {...initialState, previewId: state.previewId}
    // case ActionTypes.PREFIX_LIST_GROUPS + ActionTypes.LIST_REMOVED:
    //   return initialState
    case ActionTypes.MEDIA_ITEM_PREVIEW:
      return {
        ...state,
        item: state.item && payload.item ? payload.item : state.item,
        previewId: payload.id
      }
    case listActions.LIST_SELECTED:
      return { ...state, previewId: prevState.previewId }
    // case ActionTypes.PREFIX_LIST_MEDIA + ActionTypes.LIST_REMOVED:
    //   return {...state, previewId: null}
    default:
      return state
  }
}

export default item
