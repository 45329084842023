import * as ActionTypes from '../../../../actions/types'
import { DEFAULT_BWE_FOR_VIDEO_QUERY } from '../../../../lib/activity'
import { createListReducer } from 'skylight-common'

const list = createListReducer({
  prefix: 'CALLBWEFORVIDEO'
}, DEFAULT_BWE_FOR_VIDEO_QUERY)

const bweForVideoList = (state, payload, all) => {
  const listState = list(state, payload, all)

  switch (payload.type) {
    case ActionTypes.ROUTER_LOCATION_CHANGE:
      return { ...listState }
    default:
      return listState
  }
}

export default bweForVideoList
