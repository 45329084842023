import * as ActionTypes from '../../actions/types'

const initialState = {
  pwdValidationError: null,
  isTokenInvalid: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.PASSWORD_RESET_VALIDATION_ERROR_UPDATE: {
      return {
        ...state,
        pwdValidationError: action.error
      }
    }
    case ActionTypes.PASSWORD_RESET_TOKEN_INVALID_UPDATE: {
      return {
        ...state,
        isTokenInvalid: action.isTokenInvalid
      }
    }
    default:
      return state
  }
}
