import { matchPath } from 'react-router-dom'
import * as applicationMenu from './applicationMenu'
import * as mainMenu from './mainMenu'
import * as nextGenMainMenu from './nextGenMainMenu'
import { isNextGenUrl } from '../../../../../lib/nextGenApplication'

const navigationMenus = {
  '/ng/applications/:applicationId': applicationMenu
}

export const getNavigationItems = (props, isMobile) => {
  const path = props.location.pathname
  const menusKey = Object.keys(navigationMenus)
    .find(key => !!matchPath(path, { path: key }))
  const matchParams = matchPath(path, { path: menusKey }).params
  const navigationMenu = navigationMenus[menusKey] || (isNextGenUrl() ? nextGenMainMenu : mainMenu)

  if (isMobile) {
    return navigationMenu.getMobileItems(props, matchParams)
  }

  return navigationMenu.getItems(props, matchParams)
}
