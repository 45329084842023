import React from 'react'
import { FormattedMessage } from 'react-intl'

const BASE = '/assignments'

const AssignmentTitle = props => {
  const item = props.assignmentAttachment.parent || {}

  return item
    ? <span>{item.name || <FormattedMessage
      id="UNNAMED_ASSIGNMENT"
      defaultMessage="Unnamed assignment" />}</span>
    : null
}

const PendingAssignment = props => {
  return <span><FormattedMessage id="PENDING" defaultMessage="Pending"/></span>
}

export const ASSIGNMENT_ROUTES = [
  { path: BASE, breadcrumb: 'LOCATION_ASSIGNMENTS' },
  { path: `${BASE}/pending`, breadcrumb: () => <PendingAssignment /> },
  { path: `${BASE}/attachments/:id`, breadcrumb: () => <AssignmentTitle /> }
]
