import React from 'react'
import { markSeenRelease, isNewReleaseSeen } from '../../../../lib/releases'
import { CONFIG, LeftNav as LeftNavBase, isMobile } from 'skylight-common'
import { getNavigationItems } from './navigationItems'
import { NavigationController } from './NavigationController'

import './LeftNav.css'

class LeftNav extends React.Component {
  state = {
    isNewReleaseSeen: isNewReleaseSeen()
  }

  toAbout = () => {
    this.props.onShowVersionDialog()
  }

  toProfile = () => {
    this.props.history.push('/profile')
  }

  toReleases = () => {
    if (!this.state.isNewReleaseSeen) {
      markSeenRelease()
      this.setState({ isNewReleaseSeen: true })
    }

    this.props.goToHelp(CONFIG.helpWhatsNewUrl, true)
  }

  getNavItems = (props) => {
    return getNavigationItems(props, isMobile())
  }

  render() {
    const extendedProps = {
      ...this.props,
      isNewReleaseSeen: this.state.isNewReleaseSeen,
      toAbout: this.toAbout,
      toReleases: this.toReleases,
      toProfile: this.toProfile
    }

    const { nav } = this.props.layout
    return (
      <div className="navigation-drawer-container">
        <LeftNavBase {...extendedProps} navigationItems={this.getNavItems(this.props)} />
        {!nav.isDocked && <NavigationController handleClick={() => this.props.onNavToggle(!nav.isOpened)} />}
      </div>
    )
  }
}

export { LeftNav }
