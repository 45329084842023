import React from 'react'
import { ByUser, ByDateRange } from 'skylight-common'
import { ByStatus } from './ByStatus'
import { ByParticipant } from './ByParticipant'
import { FilterToolbarNextGen } from '../../../../../../Common/NextGen/FilterToolbarNextGen'
import { PERMISSIONS } from '../../../../../../../lib/application'
import './SessionFilter.css'

const getFilters = (filterProps = {}) => [
  { text: 'STATUS', value: 'status', props: { title: 'STATUS' }, comp: ByStatus },
  { text: 'PARTICIPANTS', value: 'participants', props: { title: 'PARTICIPANTS', filterProps }, isDisabled: () => !filterProps.permissions[PERMISSIONS.SESSION.READ_ANY], comp: ByParticipant },
  { text: 'DATE_CREATED', value: 'createdAt', props: { title: 'DATE_CREATED' }, comp: ByDateRange },
  { text: 'CREATED_BY', value: 'createdBy', props: { title: 'CREATED_BY', filterProps }, comp: ByUser },
  { text: 'DATE_UPDATED', value: 'modifiedAt', props: { title: 'DATE_UPDATED' }, comp: ByDateRange },
  { text: 'LAST_UPDATED_BY', value: 'modifiedBy', props: { title: 'LAST_UPDATED_BY', filterProps }, comp: ByUser }
]

const SessionFilter = props => {
  const filterProps = {
    urlPath: '/ng/users',
    onSelectItems: props.onUserSelectItems,
    role: props.authUser.role,
    applicationId: props.application.applicationId,
    permissions: props.permissions[props.application.applicationId] || {}
  }

  const filters = getFilters(filterProps)
    .filter(f => typeof f.isDisabled === 'function' ? !f.isDisabled() : !f.isDisabled)

  return <FilterToolbarNextGen
    className="session-filter"
    filters={filters}
    query={props.query}
    layout={props.layout}
    onFilterUpdated={props.onSessionFilterUpdated}
    onFiltersUpdated={props.onSessionFiltersUpdated}
    onFilterRemoved={props.onSessionFilterRemoved} />
}

export { SessionFilter }
