import React from 'react'
import GroupDetailsLink from '../GroupDetails/GroupDetailsLink'
import { isNextGenUrl } from '../../../../lib/nextGenApplication'

import './GroupsLinks.css'

const GroupsLinks = (props) => {
  const { groups } = props
  if (!groups) {
    return '' // Errorless leaving, but this is unexpected.
  }

  const renderGroupLinks = () => groups.map((g, i) => {
    const isLastItem = i === groups.length - 1
    const separator = isLastItem ? '' : ', '
    return (
      <span key={i}>
        <GroupDetailsLink
          group={g}
          nextGen={isNextGenUrl()} />
        {separator}
      </span>
    )
  })

  return (
    <div className="groups-links">
      {renderGroupLinks()}
    </div>
  )
}

export { GroupsLinks }
