import React from 'react'
import { UserTitle, getUserTitle, FormattedDateTime, MEDIA_COLUMNS, DetailsLink } from 'skylight-common'
import skylight from 'skylight-js-sdk'
import { FormattedMessage } from 'react-intl'
import { GROUP } from '../../../../svc/scopes'

const renderDetailsLink = (u, action) => <DetailsLink
  item={u}
  path="/team/users"
  renderLink={getUserTitle}
  onNavigateToDetails={action} />

const RENDER_MAP = {
  type: item => <span>{item.type}</span>,
  createdAt: item => <FormattedDateTime value={item.createdAt} />,
  updatedAt: item => <FormattedDateTime value={item.updatedAt} />,
  owner: (item, action, scopes) => <UserTitle
    render={skylight.auth.hasScopes([GROUP.EDIT])
      ? u => renderDetailsLink(u, action)
      : null}
    renderError={() => <FormattedMessage id="NO_OWNER" defaultMessage="No owner" />}
    user={item.owner} />
}

export const COLUMNS = MEDIA_COLUMNS.map(x => {
  const render = RENDER_MAP[x.name]

  return render ? { ...x, render } : x
})
