import skylight from 'skylight-js-sdk'
import { getDevice } from './devices'
import { SESSION_STATUSES } from '../../lib/application/session'
import { ListQueryBuilder } from './listQueryBuilder'

const getQueryFilters = (query) => {
  const filters = {}

  const { filter } = query

  const statuses = filter.status || []
  if (statuses.length === 1) {
    filters.status = statuses[0]
  }

  if (Array.isArray(filter.participants) && filter.participants.length) {
    filters.participants = filter.participants.join(',')
  }

  return filters
}

export const getAllSessions = async(applicationId) => {
  const query = {
    limit: 200
  }

  const sessions = []

  do {
    const { data, page } = await skylight.we20.session.list(applicationId, query)
    sessions.push(...data)
    query.nextCursor = page.nextCursor
  } while (query.nextCursor)

  return sessions
}

export const getSessions = async(applicationId, query) => {
  const { createdBy, createdAt, modifiedBy, modifiedAt, name } = query.filter

  const sessionQuery = new ListQueryBuilder({
    limit: query.limit,
    sort: `${query.sortAsc ? '' : '-'}${query.sortBy}`,
    ...getQueryFilters(query)
  })
    .byName(name)
    .byCreatedBy(createdBy)
    .byCreatedAt(createdAt)
    .byModifiedAt(modifiedAt)
    .byModifiedBy(modifiedBy)
    .buildQuery()

  if (query.nextCursor) {
    sessionQuery.nextCursor = query.nextCursor
  }

  const sessions = await skylight.we20.session.list(applicationId, sessionQuery)
  return {
    data: sessions.data.reduce((acc, x) => {
      if (x.sessionId) {
        const item = {
          ...x,
          id: x.sessionId
        }

        acc.push(item)
      }

      return acc
    }, []),
    page: sessions.page
  }
}

export const closeSession = async(applicationId, sessionId, deviceId) => {
  if (!deviceId) {
    const device = await getDevice()
    deviceId = device.deviceId
  }

  const session = await skylight.we20.session.getById(applicationId, sessionId)
  const closedSession = {
    sessionId: session.sessionId,
    applicationId: session.applicationId,
    version: session.version,
    name: session.name,
    description: session.description,
    participants: session.participants,
    status: SESSION_STATUSES.CLOSED,
    properties: session.properties
  }

  await skylight.we20.session.update(applicationId, sessionId, closedSession, deviceId)
}

export const closeSessions = async(applicationId, sessionIds) => {
  const device = await getDevice()
  const deviceId = device.deviceId
  await Promise.all(sessionIds.map(async sessionId => {
    await closeSession(applicationId, sessionId, deviceId)
  }))
}
