import * as ActionTypes from '../../actions/types'

const initialState = {
  by: 'file',
  files: [],
  url: '',
  urlError: '',
  isUploading: false,
  error: null
}

const upload = (state = initialState, payload, all) => {
  switch (payload.type) {
    case ActionTypes.NEW_MEDIA_IMAGE_SELECTED:
      return { ...initialState, files: payload.files, isUploading: false }
    case ActionTypes.NEW_MEDIA_IMAGE_UPLOADING:
      return { ...state, isUploading: true }
    case ActionTypes.NEW_MEDIA_IMAGE_UPLOAD_ERROR:
      return { ...state, error: payload.error, isUploading: false }
    case ActionTypes.NEW_MEDIA_IMAGE_UPLOADED:
      return { ...state, files: [], isUploading: false }
    case ActionTypes.NEW_MEDIA_SET_FILES:
      return { ...state, files: payload.files }
    case ActionTypes.NEW_MEDIA_SET_URL:
      return { ...state, url: payload.url, urlError: null }
    case ActionTypes.NEW_MEDIA_URL_ERROR:
      return { ...state, urlError: payload.error }
    case ActionTypes.NEW_MEDIA_IMAGE_CLEAR:
      return initialState
    case ActionTypes.NEW_MEDIA_CHANGE_BY:
      return { ...state, by: payload.by, urlError: null }
    default:
      return state
  }
}

export default upload
