import * as ActionTypes from '../../actions/types'
import { DEFAULT_QUERY } from '../../lib/assignment'
import { createListReducer } from 'skylight-common'

const list = createListReducer({
  prefix: ActionTypes.PREFIX_ASSIGNMENT.toUpperCase()
}, DEFAULT_QUERY)

const assignmentList = (state, payload) => {
  const listState = list(state, payload)

  switch (payload.type) {
    case ActionTypes.ASSIGNMENT_WORKFLOW_UNASSIGN_SUCCESS:
      return { ...listState, selectedIds: [] }
    case ActionTypes.ASSIGNMENT_LIST_LOADED:
      return { ...listState, queryType: payload.queryType }
    case ActionTypes.ASSIGNMENT_SET_PENDING_TOTAL:
      return { ...state, pendingTotal: payload.pendingTotal }
    default:
      return listState
  }
}

export default assignmentList
