import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import activityActions from '../actions/activity'
import contactActions from '../actions/contact'

import { ActivityList as ActivityListComp } from '../App/Home/Activities/ActivityList'
import { ActivityDetails as ActivityDetailsComp } from '../App/Home/Activities/ActivityDetails'
import {
  ActivityFilter as ActivityFilterComp,
  ActivityTextFilter as ActivityTextFilterComp
} from '../App/Home/Activities/ActivityFilter'

import { Calls as CallsComp } from '../App/Home/Activities/Calls'
import { CallList as CallListComp } from '../App/Home/Activities/Calls/CallList'
import { CallDetails as CallDetailsComp } from '../App/Home/Activities/Calls/CallDetails'
import { CallActionMenu as CallActionMenuComp } from '../App/Home/Activities/Calls/CallActionMenu'
import {
  CallFilter as CallFilterComp,
  CallTextFilter as CallTextFilterComp
} from '../App/Home/Activities/Calls/CallFilter'
import { CallEventList as CallEventListComp } from '../App/Home/Activities/CallEvents'
import { CallEventDetails as CallEventDetailsComp } from '../App/Home/Activities/CallEvents/CallEventDetails'
import {
  CallEventFilter as CallEventFilterComp,
  CallEventTextFilter as CallEventTextFilterComp
} from '../App/Home/Activities/CallEvents/CallEventFilter'
import { CallData as CallDataComp } from '../App/Home/Activities/CallData'
import { SsrcVideoSend as SsrcVideoSendReportComp } from '../App/Home/Activities/CallReports/SsrcVideoSend'
import { BweForVideo as BweForVideoReportComp } from '../App/Home/Activities/CallReports/BweForVideo'

const eventList = (state, props) => ({
  list: state.activity.event.list,
  scopes: state.auth.scopes
})

const eventListActions = {
  ...activityActions,
  onUserSelectItems: contactActions.onUserSelectItems
}

const eventDetails = (state, props) => ({
  item: state.activity.event.item
})

const eventDetailsActions = {
  ...activityActions
}

const eventFilter = (state, props) => ({
  query: state.activity.event.list.query,
  layout: state.layout
})

const eventFilterActions = {
  ...activityActions
}

const callList = (state, props) => ({
  list: state.activity.call.list,
  scopes: state.auth.scopes
})

const callListActions = {
  ...activityActions,
  onUserSelectItems: contactActions.onUserSelectItems
}

const callDetailsActions = {
  ...activityActions
}

const callDetails = (state, props) => ({
  item: state.activity.call.item
})

const callFilter = (state, props) => ({
  query: state.activity.call.list.query,
  layout: state.layout
})

const callFilterActions = {
  ...activityActions
}

const callEventList = (state, props) => ({
  list: state.activity.call.event.list,
  item: state.activity.call.event.item,
  scopes: state.auth.scopes
})

const callEventListActions = {
  ...activityActions
}

const callEventDetailsActions = {
  ...activityActions
}
const callEventDetails = (state, props) => ({
  item: state.activity.call.event.item
})

const callEventFilter = (state, props) => ({
  query: state.activity.call.event.list.query,
  layout: state.layout
})

const callEventFilterActions = {
  ...activityActions
}

const ssrcVideoSendReportProps = state => ({
  list: state.activity.call.ssrcVideoSend.list
})

const ssrcVideoSendReportActions = {
  ...activityActions
}

const bweForVideoReportProps = state => ({
  list: state.activity.call.bweForVideo.list
})

const bweForVideoReportActions = {
  ...activityActions
}

const ActivityList = connect(eventList, eventListActions)(ActivityListComp)
const ActivityDetails = connect(eventDetails, eventDetailsActions)(ActivityDetailsComp)
const ActivityFilter = connect(eventFilter, eventFilterActions)(ActivityFilterComp)
const ActivityTextFilter = connect(eventFilter, eventFilterActions)(ActivityTextFilterComp)

const Calls = withRouter(CallsComp)
const CallList = connect(callList, callListActions)(CallListComp)
const CallDetails = connect(callDetails, callDetailsActions)(CallDetailsComp)
const CallActionMenu = withRouter(connect(callDetails, callDetailsActions)(CallActionMenuComp))
const CallFilter = connect(callFilter, callFilterActions)(CallFilterComp)
const CallTextFilter = connect(callFilter, callFilterActions)(CallTextFilterComp)

const CallData = connect(callDetails, callDetailsActions)(CallDataComp)

const CallEventList = connect(callEventList, callEventListActions)(CallEventListComp)
const CallEventDetails = connect(callEventDetails, callEventDetailsActions)(CallEventDetailsComp)
const CallEventFilter = connect(callEventFilter, callEventFilterActions)(CallEventFilterComp)
const CallEventTextFilter = connect(callEventFilter, callEventFilterActions)(CallEventTextFilterComp)

const SsrcVideoSendReport = connect(ssrcVideoSendReportProps, ssrcVideoSendReportActions)(SsrcVideoSendReportComp)
const BweForVideoReport = connect(bweForVideoReportProps, bweForVideoReportActions)(BweForVideoReportComp)

export {
  ActivityList, ActivityDetails, ActivityFilter, ActivityTextFilter,
  Calls, CallList, CallDetails, CallActionMenu, CallFilter, CallTextFilter,
  CallData, CallEventList, CallEventDetails, CallEventFilter, CallEventTextFilter,
  SsrcVideoSendReport, BweForVideoReport
}
