import React from 'react'
import { Snackbar } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import WarningIcon from 'material-ui/svg-icons/alert/warning'
import FlatButton from 'material-ui/FlatButton'

import './SnackbarErrorArchivingApplication.css'

const SnackbarErrorArchivingApplication = props => {
  const { snackbar, snackbarType } = props
  const { open } = snackbar[snackbarType] || {}

  const handleClose = () => {
    props.onHideSnackbar(snackbarType)
  }

  const handleRetry = e => {
    e.stopPropagation()
    handleClose()
    props.onArchiveApplication()
  }

  return (
    <Snackbar autocloseSeconds={4} className="snackbar-error-archiving-application" open={open} onRequestClose={handleClose}>
      <div className="warning-icon">
        <WarningIcon />
      </div>
      <div className="snackbar-message">
        <FormattedMessage id="ERROR_ARCHIVING_APPLICATION" defaultMessage="Error archiving application" />
      </div>
      <div className="action-button">
        <FlatButton
          key="retry-button"
          label={<FormattedMessage id="RETRY" defaultMessage="Retry" />}
          onTouchTap={handleRetry}
          primary />
      </div>
    </Snackbar>
  )
}

export { SnackbarErrorArchivingApplication }
