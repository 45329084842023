import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const help = (props) => {
  return (
    <SvgIcon {...props} width="64px" height="64px" viewBox="0 0 64 64">
      <g id="Artboard-Copy" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="graduation-cap">
            <rect id="Rectangle" x="0" y="0" width="64" height="64"></rect>
            <g id="fill" transform="translate(2.666667, 8.000000)" fill="#00AAED">
                <path d="M2.66666667,37.3333333 C4.13866667,37.3333333 5.33333333,38.528 5.33333333,40 L5.33333333,45.3333333 C5.33333333,46.8053333 4.13866667,48 2.66666667,48 C1.19466667,48 0,46.8053333 0,45.3333333 L0,40 C0,38.528 1.19466667,37.3333333 2.66666667,37.3333333 Z M29.3333333,34.2213333 C29.6586667,34.2213333 29.9813333,34.1626667 30.2906667,34.0453333 L48,27.232 L48,34.704 C48,35.712 47.4293333,36.6346667 46.5253333,37.088 C41.96,39.3706667 37.2346667,42.2053333 29.3333333,42.2053333 C21.4266667,42.2053333 16.7146667,39.376 12.1413333,37.088 C11.2373333,36.6346667 10.6666667,35.712 10.6666667,34.704 L10.6666667,26.6666667 L28.3066667,34.016 C28.6346667,34.152 28.984,34.2213333 29.3333333,34.2213333 Z M57.192,13.6153333 C58.1306667,14.0846667 58.7066667,15.0606667 58.664,16.1113333 C58.6213333,17.162 57.9626667,18.0873333 56.9893333,18.4766667 L29.3706667,29.1273333 L5.33333333,19.114 L5.33333333,31.3726667 C5.33333333,32.8446667 4.13866667,34.0393333 2.66666667,34.0393333 C1.192,34.0393333 0,32.8446667 0,31.3726667 L0,16.002 C0,15.7246667 0.122666667,15.1726667 0.285333333,14.85 C0.525333333,14.346 0.933333333,13.8846667 1.472,13.6153333 L28.1386667,0.282 C28.8906667,-0.094 29.776,-0.094 30.5253333,0.282 L57.192,13.6153333 Z" id="Fill-70"></path>
            </g>
        </g>
    </g>
    </SvgIcon>
  )
}

export default help
