import React from 'react'
import { TextFilter } from 'skylight-common'
import { AttachmentFilter } from './AttachmentFilter'

const VALUE = 'text'

const AttachmentTextFilter = props => {
  const value = props.query.filter && props.query.filter.text ? props.query.filter.text : ''
  const onChange = (v) => {
    const value = v.trim()
    const filter = { ...props.query.filter, [VALUE]: value }
    props.onAssignmentAttachmentFilterUpdated(filter)
  }

  return <TextFilter
    value={value}
    className="attachment-text-filter"
    title={props.title || 'SEARCH'}
    iconFilter={<AttachmentFilter {...props} />}
    onChange={onChange} />
}

export { AttachmentTextFilter }
