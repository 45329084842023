import React from 'react'
import { connect } from 'react-redux'
import { UserTitle, getUserTitle, DetailsLink } from 'skylight-common'
import skylight from 'skylight-js-sdk'
import { GROUP } from '../../../../svc/scopes'

import contactActions from '../../../../actions/contact'

const renderDetailsLink = (u, action, nextGen) => <DetailsLink
  item={u}
  path={nextGen ? '/ng/users' : '/team/users'}
  renderLink={getUserTitle}
  onNavigateToDetails={action} />

const UserDetailsLink = props => <UserTitle
  shouldLoadUser
  render={skylight.auth.hasScopes([GROUP.EDIT])
    ? u => renderDetailsLink(u, item => props.onUserSelectItems([item.id], [item]), props.nextGen)
    : null}
  user={props.userId} />

export { UserDetailsLink }

const mapDispatchToProps = {
  onUserSelectItems: contactActions.onUserSelectItems
}

export default connect(null, mapDispatchToProps)(UserDetailsLink)
