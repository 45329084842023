import { connect } from 'react-redux'
import { withBreadcrumbs } from './withBreadcrumbs'

import { Breadcrumbs as BreadcrumbsComp } from '../../App/Common/Breadcrumbs'
import { ASSIGNMENT_ROUTES } from '../../App/Home/Assignments/AssignmentAttachments/breadcrumbRoutes'
import { ACTIVITY_ROUTES } from '../../App/Home/Activities/breadcrumbsRoutes'
import { APP_DASHBOARD_ROUTES } from '../../App/Home/HomeNextGen/Applications/breadcrumbRoutes'

const routes = [
  ...ASSIGNMENT_ROUTES,
  ...ACTIVITY_ROUTES,
  ...APP_DASHBOARD_ROUTES
]

const apps = (state, props) => ({
  assignmentAttachment: state.assignment.attachment,
  router: state.router // Need to pass updated location if using react-router `push`
})

const Breadcrumbs = connect(apps)(withBreadcrumbs(routes)(BreadcrumbsComp))

export { Breadcrumbs }
