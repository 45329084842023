import { CONFIG } from 'skylight-common'
import * as ActionTypes from './types'
import { signout } from './auth'
import { showError } from './error'
import { actions } from 'skylight-call-sdk'
import skylight from 'skylight-js-sdk'
import { isLiveEnabled } from '../lib/calls'

// Launch EyeSight in a new tab
const call = (userToCall, currentUser, enableVideo, presence) => async(dispatch) => {
  console.log('call!', userToCall, currentUser, enableVideo, presence)

  try {
    if (isLiveEnabled()) {
      dispatch(actions.connection.initOutgoingCall(userToCall))
      return
    }

    const authData = await skylight.auth.tryLoadAuthDataFromStorage()
    if (authData) {
      // Cache our partner's user object to avoid an API call in Skylight Live
      await skylight.storage.setItem('callPartner', userToCall)

      // localCallUrl only relevant when testing EyeSight locally on its own port
      const callUrl = `${CONFIG.localCallUrl || ''}/call/send/${userToCall.id}`

      window.open(callUrl, 'Skylight Live')
      return
    }
  } catch (e) {
    skylight.logger.error('call', e)
  }

  dispatch(signout())
  dispatch(showError('SESSION_EXPIRED'))
}

const callExternalUser = (userToCall, currentUser) => ({
  type: ActionTypes.CALL_EXTERNAL_USER_ERROR
})

const callBusyUser = (userToCall, currentUser) => ({
  type: ActionTypes.CALL_BUSY_USER_ERROR
})

export default {
  onCall: call,
  onCallExternalUser: callExternalUser,
  onCallBusyUser: callBusyUser
}
