import React from 'react'
import PropTypes from 'prop-types'
import { ByList, getUserTitle } from 'skylight-common'
import { getAllSessionsParticipants } from '../../../../../../../svc/application/participants'

const getItems = applicationId => getAllSessionsParticipants(applicationId).then(participants =>
  participants
    .map(item => {
      let text = ''
      if (item.type === 'user') {
        text = getUserTitle(item)
      } else {
        text = item.name
      }

      return {
        text: text,
        value: item.id,
        ...item
      }
    }))

const getItemIcon = item => item.type === 'user' ? 'person' : 'group'

const ByParticipant = props => {
  return <ByList
    className="by-participant"
    title={props.title}
    value={props.value}
    withChip
    filterProps={{ ...props.filterProps, chipIcon: getItemIcon }}
    uncheckedIcon={getItemIcon}
    onChange={props.onChange}
    onRemove={props.onRemove}
    placeholder={props.placeholder}
    onGetItems={() => getItems(props.filterProps.applicationId)} />
}

ByParticipant.defaultProps = {
  placeholder: { id: 'ANYONE', defaultMessage: 'Anyone' }
}

ByParticipant.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired
}

export { ByParticipant }
