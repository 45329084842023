import React from 'react'
import { AdminLanguage } from '../../../Admin/AdminLanguage'
import { connect } from 'react-redux'
import adminActions from '../../../../../actions/admin'
import localeActions from '../../../../../actions/locale'
import { OutlinedSelectField } from '../../../../Common/Form/OutlinedSelectField'

import './Language.css'

const Language = props => {
  return <div className="language-container">
    <AdminLanguage {...props} selectFieldComp={OutlinedSelectField} withTooltip />
  </div>
}

const mapStateToProps = state => ({
  user: state.auth.user
})

const mapActionsToProps = {
  ...adminActions,
  ...localeActions
}

export default connect(mapStateToProps, mapActionsToProps)(Language)
