import React from 'react'
import { ByList } from 'skylight-common'
import { HIDE_CAPTURED_KEY, HIDE_UNANSWERED_KEY } from '../../../../../../lib/assignment'
import './ByMultipleChoice.css'

const ByNameOfChoice = props => {
  const decision = props.filterProps.items.filter(x => x.cardType === 'decision')
  const getChoiceOptions = () => {
    const map = {}
    decision.forEach(x => {
      x.choices.forEach(c => { map[c.label] = null })
    })
    const options = Object.keys(map).map(key => ({ text: key, value: key }))
    return Promise.resolve(options)
  }

  // Stupid but simple way to distinguish between decision options and actual responses.
  const onChange = (v) => {
    props.onChange([...props.decisions, ...v])
  }

  return (
    <ByList
      className="by-name-of-choice"
      title="FILTER_BY_CHOICE_NAME"
      value={props.value}
      renderFilter={() => null}
      enableSorting={false}
      onChange={onChange}
      onRemove={props.onRemove}
      onGetItems={getChoiceOptions} />
  )
}

const OPTIONS = [
  { text: 'HIDE_CAPTURED', value: HIDE_CAPTURED_KEY },
  { text: 'HIDE_UNANSWERED', value: HIDE_UNANSWERED_KEY }
]

const getOptions = () => Promise.resolve(OPTIONS)

const ByMultipleChoice = props => {
  const value = props.value || []
  // Stupid but simple way to distinguish between decision options and actual responses.
  const decisions = value.filter(x => x === HIDE_CAPTURED_KEY || x === HIDE_UNANSWERED_KEY)
  const responses = value.filter(x => x !== HIDE_CAPTURED_KEY && x !== HIDE_UNANSWERED_KEY)

  return (
    <ByList
      className="by-multiple-choice"
      pickerClassName="multiple-choice-picker"
      title="MULTIPLE_CHOICE"
      value={value}
      enableSorting={false}
      renderFilter={() => <ByNameOfChoice {...props} value={responses} decisions={decisions} />}
      onChange={props.onChange}
      onRemove={props.onRemove}
      onGetItems={getOptions} />
  )
}

export { ByMultipleChoice }
