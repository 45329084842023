import React from 'react'
import { FormattedMessage } from 'react-intl'
import CircularProgress from 'material-ui/CircularProgress'
import ErrorIcon from 'material-ui/svg-icons/alert/warning'
import { Tooltip } from 'skylight-common'
import './ApplicationStatusIcon.css'

const TOOLTIP_MAP = {
  loading: 'ASSIGN_LOADING',
  error: 'ASSIGN_ERROR'
}

// TODO: Update implementation, add tooltips.
const ApplicationStatusIcon = (props) => {
  const { item } = props
  const status = props.status[item.id] || ''
  const onTouchTap = e => {
    e.stopPropagation()
    if (status === 'error') {
      props.onError(item)
    }
  }

  const getIcon = () => {
    switch (status) {
      case 'loading':
        return <CircularProgress size={20} thickness={2} />
      case 'error':
        return <ErrorIcon />
      default:
        return null
    }
  }

  const Icon = getIcon()

  return Icon
    ? <Tooltip
      tooltip={<FormattedMessage id={TOOLTIP_MAP[status]} />}
      className={`application-status-icon ${status}`}><div onTouchTap={onTouchTap}>{Icon}</div></Tooltip>
    : null
}

export { ApplicationStatusIcon }
