import React from 'react'
import { DEFAULT_DETAILS_PROPS, ERRORED_UPGRADE_DETAILS_PROPS } from './versionDetailsProps'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'
import { OutlinedField } from '../../../../../../Common/Form/OutlinedField'
import { versionUpgradeIsErrored } from '../../../../../../../lib/application/version'

export const VersionDetails = ({ item, ...props }) => {
  const upgradeIsErrored = versionUpgradeIsErrored(item)
  const listOfProps = upgradeIsErrored
    ? ERRORED_UPGRADE_DETAILS_PROPS
    : DEFAULT_DETAILS_PROPS
  return (
    <div className="next-gen-details version-details">
      {listOfProps.map(x => {
        const fieldContent = x.render
          ? x.render(item)
          : get(item, x.field)
        return <div key={x.field} className="item-prop-next-gen">
          <OutlinedField
            className={x.className}
            floatingLabelText={(
              <FormattedMessage
                id={x.title} />
            )}
            disabled>
            <div className="item-prop-body">{fieldContent}</div>
          </OutlinedField>
        </div>
      })}
    </div>
  )
}
