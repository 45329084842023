import React from 'react'
import { FormattedMessage } from 'react-intl'
import { OutlinedTextField } from '../../Common/Form/OutlinedTextField'
import EmailIcon from 'material-ui/svg-icons/communication/email'
import { connect } from 'react-redux'
import { requestPasswordReset } from '../../../actions/profile'
import LoginPageButton from '../../Common/LargeRaisedButton/LargeRaisedButton'

import './RecoverPasswordRequest.css'

class RecoverPasswordRequest extends React.Component {
  state = {
    username: '',
    error: null
  }

  onUsernameChange = username => {
    this.setState({ username, error: null })
  }

  validateUsername = () => {
    const { username } = this.state
    if (!username) return { id: 'NO_USERNAME_PASS_RECOVERY_ERROR', defaultMessage: 'Opps. Please enter your username.' }

    return null
  }

  onRecoverPassword = e => {
    e.preventDefault()

    const error = this.validateUsername()
    if (error) {
      this.setState({ error })
      return
    }

    this.setState({ error: null })
    this.props.onRecoverPassword(this.state.username)
  }

  render() {
    return (
      <form className="recover-password-request" onSubmit={this.onRecoverPassword}>
        <div className="we-all-forget-things">
          <FormattedMessage id="WE_ALL_FORGET_THINGS_SOMETIMES" defaultMessage="Don’t worry, we all forget things sometimes." />
        </div>
        <OutlinedTextField
          required
          onFocus={() => this.setState({ error: null })}
          error={this.state.error}
          onChange={(_, v) => this.onUsernameChange(v)}
          value={this.state.username}
          className="username-text-field"
          name="username"
          floatingLabelText={<FormattedMessage id="USERNAME" defaultMessage="Username" />} />
        <LoginPageButton primary type="submit" icon={<EmailIcon />} label={<FormattedMessage id="SEND_RECOVERY_LINK" defaultMessage="Send me a recovery link" />} />
        <div className="contact-admin-tip">
          <FormattedMessage
            id="CONTACT_ADMIN_FORGOT_USERNAME"
            defaultMessage="Contact your administrator if you cannot remember the username associated with your account." />
        </div>
      </form>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({
  onRecoverPassword: username => {
    dispatch(requestPasswordReset(username))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordRequest)
