import { MEDIA_QUERY, shiftSelect, isMobile } from 'skylight-common'
export const DEFAULT_FILTERS = MEDIA_QUERY.FILTERS
export const DEFAULT_MEDIA_TYPES = MEDIA_QUERY.MEDIA_TYPES
export const DEFAULT_FILTER = MEDIA_QUERY.FILTER
export const DEFAULT_QUERY = MEDIA_QUERY.QUERY

const isResourceItem = (type) => {
  return type === 'application/zip' || type === 'text/html'
}

const CLICK_TIMEOUT = 200

let clicksTimeout = null

const clearClickTimeout = () => {
  clearTimeout(clicksTimeout)
  clicksTimeout = null
}

const getMediaItemsIds = (item, selectedIds) => {
  let ids = []
  const isSelected = selectedIds.includes(item.id)

  if (isSelected) {
    ids = selectedIds.filter(id => id !== item.id)
  } else {
    ids = [...selectedIds, item.id]
  }

  return ids
}

// Methods for selecting media items and open media item preview.
// Used in MediaGrid, DecisionMedia and MediaAttachments components
// Each component uses a different function for selecting / previewing items,
// which is passed directly to this methods.
const onShiftSelect = (id, props, cb) => {
  const { selectedIds, items } = props
  const result = shiftSelect(id, selectedIds, { items, keyProp: 'id' })
  cb(result)
}

const onSelect = (item, e, props, cb) => {
  const { selectedIds } = props
  const { shiftKey, detail } = e.nativeEvent

  if (shiftKey) {
    onShiftSelect(item.id, props, cb)
    return
  }

  // if single click
  if (detail === 1 || isMobile()) {
    clicksTimeout = setTimeout(() => {
      // clear clickTimeout
      clearClickTimeout()

      // do selection
      const ids = getMediaItemsIds(item, selectedIds)
      cb(ids)
    }, CLICK_TIMEOUT)
  }
}

const onPreview = (item, e, cb) => {
  if ((clicksTimeout !== null && e.nativeEvent.detail === 2) || isMobile()) {
    // clear timeout
    clearClickTimeout()

    // open preview
    cb(item.id)
  }
}

export default {
  isResourceItem,
  onShiftSelect,
  onSelect,
  onPreview
}
