import { LastSyncedComp } from './LastSynced'
import { connect } from 'react-redux'
import ldapActions from '../../../../../../../actions/ldap'

const mapStateToProps = ({ admin: { auth } }) => ({
  isSyncLoaded: auth.ldapApp.isSyncLoaded,
  syncError: auth.ldapApp.syncError,
  hasChanged: auth.ldapApp.hasChanged
})

const LastSynced = connect(
  mapStateToProps,
  ldapActions
)(LastSyncedComp)

export { LastSynced }
