import { connect } from 'react-redux'

import authActions from '../actions/auth'
import { Logout as LogoutComp } from '../App/Home/Logout/Logout'

const dispatchActions = {
  onSignout: () => authActions.onSignout()
}

export default connect(null, dispatchActions)(LogoutComp)
