import React from 'react'
import { FormattedMessage } from 'react-intl'
import { formatDateTime } from 'skylight-common'
import { UserDetailsLink } from '../../../../containers/common'
import './ActivityDetails.css'

const KNOWN_ACTIVITY_PROPS = {
  eventType: { order: 0, label: { id: 'ACTIVITY_EVENT_NAME', default: 'Event' } },
  timestamp: {
    order: 1,
    label: { id: 'CREATED_TIME', default: 'Created' },
    render: item => {
      return formatDateTime(item.timestamp)
    }
  },
  username: {
    order: 2,
    label: { id: 'ACTIVITY_USERNAME', default: 'Username' },
    render: item => {
      return <UserDetailsLink userId={item.userId} linkText={user => user.username} />
    }
  },
  assignmentId: { order: 3, label: { id: 'ACTIVITY_ASSIGNMENT_ID', default: 'Assignment ID' } },
  cardId: { order: 4, label: { id: 'ACTIVITY_CARD_ID', default: 'Card ID' } },
  resourceUrl: { order: 5, label: { id: 'ACTIVITY_RESOURCE_URL', default: 'Resource URL' } },
  sequenceId: { order: 6, label: { id: 'ACTIVITY_SEQUENCE_ID', default: 'Sequence ID' } },
  deviceId: { order: 7, label: { id: 'ACTIVITY_DEVICE_ID', default: 'Device ID' } },
  createdBy: {
    order: 8,
    label: { id: 'CREATED_BY', default: 'Created by' },
    render: item =>
    <UserDetailsLink userId={item.createdBy} linkText={user => user.username} />
  }
}

// These are redundant or unnecessary to display
const IGNORE_ACTIVITY_PROPS = ['id', 'metadata', 'created', 'name']

const getProps = (item) => {
  const props = []

  for (const prop in item) {
    if (!item.hasOwnProperty(prop) || IGNORE_ACTIVITY_PROPS.includes(prop)) continue

    let propObj = { prop: prop }
    const knownProp = KNOWN_ACTIVITY_PROPS[prop]

    if (knownProp) {
      propObj = { ...propObj, ...knownProp }
    } else {
      propObj = { ...propObj, order: 999, label: { id: prop, default: prop } }
    }

    props.push(propObj)
  }

  // Show known properties in specified order - we'll show the rest in the
  // order received.
  return props.sort((a, b) => { return a.order - b.order })
}

const renderDefaultProp = v =>
  typeof v === 'object' ? JSON.stringify(v, null, 2) : v

const ActivityDetails = (props) => {
  const item = props.item.item

  const renderProp = (u) => {
    return <div key={u.prop} className="detail-item">
      <FormattedMessage id={u.label.id} defaultMessage={u.label.default} />
      <div className="detail-item-value">
        {u.render ? u.render(item) : renderDefaultProp(item[u.prop])}
      </div>
    </div>
  }

  return (
    <div className="activity-details">
      {getProps(item).map(renderProp)}
    </div>
  )
}

export { ActivityDetails }
