export const replaceItem = (items = [], itemIndex, newItem) => {
  return [
    ...items.slice(0, itemIndex),
    newItem,
    ...items.slice(itemIndex + 1)
  ]
}

export const addItem = (items = [], newItem) => {
  return [
    ...items,
    newItem
  ]
}

export const addOrReplaceItem = (items = [], itemIndex, newItem) => {
  if (!Number.isInteger(itemIndex) || itemIndex < 0) {
    return addItem(items, newItem)
  } else {
    return replaceItem(items, itemIndex, newItem)
  }
}

export const addOrReplaceItemByField = (items = [], newItem, searchFieldName, searchFieldValue) => {
  if (!searchFieldName || !searchFieldValue) {
    return items
  }

  const itemIndex = items
    .findIndex(item => item[searchFieldName] === searchFieldValue)

  return addOrReplaceItem(items, itemIndex, newItem)
}
