import * as ActionTypes from '../actions/types'
import skylight from 'skylight-js-sdk'

const getSocketHandlers = () => {
  if (!skylight.auth.user || !skylight.auth.isLegacyEnabled()) {
    return []
  }

  return [{ type: 'socket', isLoading: false, error: false, title: 'NET_SCAN_WEB_SOCKET', value: '-' }]
}

const getItems = () =>
  [
    ...getSocketHandlers(),
    { type: 'mqtt', isLoading: false, error: false, title: 'NET_SCAN_MQTT', value: '-' },
    { type: 'api', isLoading: false, error: false, title: 'NET_SCAN_API', value: '-' }
  ]

const initialState = {
  isLoading: true,
  items: getItems(),
  now: null
}

const netScan = (state = initialState, payload) => {
  switch (payload.type) {
    case ActionTypes.NETWORK_STATE_CHANGED:
      if (payload.isOnline) return state
      return {
        ...state,
        now: payload.now,
        isLoading: false,
        items: getItems().map(x => ({ ...x, error: true }))
      }
    case ActionTypes.NET_SCAN_LOAD:
      return {
        ...state,
        now: payload.now,
        isLoading: true,
        items: getItems().map(x => ({ ...x, isLoading: true }))
      }
    case ActionTypes.NET_SCAN_LOADED:
      if (payload.now !== state.now) return state // Ensure latest handler.
      return {
        ...state,
        isLoading: false,
        items: state.items.map(x => x.isLoading ? { ...x, isLoading: false, error: true } : x)
      }
    case ActionTypes.NET_SCAN_ITEM_LOADED:
      if (payload.now !== state.now) return state // Ensure latest handler.
      return {
        ...state,
        isLoading: state.items.some(x => x.isLoading),
        items: state.items.map(x => {
          if (x.type === payload.item.type) {
            return { ...x, isLoading: false, ...payload.item }
          }

          return x
        })
      }
    default:
      return state
  }
}

export default netScan
