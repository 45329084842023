import React from 'react'
import { CallList } from '../../../../containers/callUser'
// import {UserList} from './UserList/UserList'
// import {Tabs, Tab} from 'material-ui/Tabs'
// import {FormattedMessage} from 'react-intl'
// import {CallGroupList} from './CallGroupList/CallGroupList'

const CallTabs = props => {
  // Favro issue: https://favro.com/card/60fe74a05e7da9c931484a9a/Ups-3782
  // const type = props.match.params.type || 'call-groups'
  //
  // const onChange = (v) => {
  //   props.redirect(`/calls/${v}`)
  // }
  //
  // return (
  //   <Tabs
  //     value={type}
  //     className="dashboard-tabs"
  //     onChange={onChange}
  //   >
  //     <Tab
  //       label={<FormattedMessage id="TAB_CALL_GROUPS" defaultMessage="Call Groups" />}
  //       value="call-groups">
  //       <CallGroupList {...props} />
  //     </Tab>
  //     <Tab
  //       label={<FormattedMessage id="TAB_USERS" defaultMessage="Users" />}
  //       value="users">
  //       <UserList {...props}/>
  //     </Tab>
  //   </Tabs>
  // )

  return <CallList {...props}/>
}

export { CallTabs }
