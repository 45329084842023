import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import contactActions from '../../../../../actions/contact'
import dialogActions from '../../../../../actions/dialog'
import appActions from '../../../../../actions/app'

import { FormattedMessage } from 'react-intl'
import {
  ActionButton,
  ListActionButton,
  ListFooterStats,
  GridHeader,
  Table,
  Column,
  Loading,
  MaterialIcon,
  UserTitle,
  CursorTooltip
} from 'skylight-common'

import GroupMembership from '../GroupMembership/GroupMembership'
import { USER, GROUP } from '../../../../../svc/scopes'
import NewUserIcon from 'material-ui/svg-icons/social/person-add'
import NewGroupIcon from 'material-ui/svg-icons/social/group-add'
import SignoutIcon from '../../../../Common/Icons/accountOff'
import RemoveIcon from '../../../../Common/Icons/removeTrash'
import { TableNextGen } from '../../../../Common/NextGen/TableNextGen'
import UserTextFilter from '../UserFilter/UserTextFilter'

import skylight from 'skylight-js-sdk'

import { filterUsers } from '../../../../../lib/contact'

import './UserList.css'

const EmptyLabel = (
  <FormattedMessage id="EMPTY_USER_LIST" defaultMessage="You do not have other users assigned to you." />
)

const UserList = props => {
  const { users } = props
  const items = filterUsers(users.items, users.query)
  const detailsOpen = users.selectedIds.length === 1
  const selectedAny = users.selectedIds.length > 0

  const onDelete = () => {
    props.onConfirm(() => props.onUserDeleteItems(users.selectedIds), {
      title: 'USERS_REMOVE_DIALOG_TITLE',
      message: 'USERS_REMOVE_DIALOG_DESCRIPTION',
      action: 'ACTION_DELETE_USER'
    })
  }

  const onSignout = () => {
    props.onConfirm(() => props.onSignoutUsers(users.selectedIds), {
      title: 'USERS_SIGNOUT_DIALOG_TITLE',
      message: 'USERS_SIGNOUT_DIALOG_DESCRIPTION',
      action: 'ACTION_SIGNOUT_USER'
    })
  }

  const onSelect = ids => {
    props.onUserSelectItems(ids, items)
  }

  const onCreateGroup = () => {
    props.onGroupNew({ name: '', description: '' }, users.selectedIds, items)
  }

  const onCreateUser = () => {
    props.onUserSelectItems([], null, true)
    props.onUserNew()
    props.showNewUserDialog()
  }

  const getHeadingActions = () => {
    return [<UserTextFilter key="filter" />]
  }

  const footerActions = [
    {
      className: 'new-group',
      show: 'selected',
      label: { id: 'ACTION_ADD_TO_GROUP', default: 'Add to group' },
      action: onCreateGroup,
      primary: true,
      scopes: [GROUP.CREATE],
      icon: <NewGroupIcon />,
      type: 'icon',
      children: [
        {
          label: <FormattedMessage id="ACTION_ADD_TO_NEW_GROUP" defaultMessage="Add to new" />,
          action: onCreateGroup
        },
        {
          label: <FormattedMessage id="ACTION_ADD_TO_EXISTING_GROUP" defaultMessage="Add to existing" />,
          action: () => props.onShowAddToExistingGroupDialog()
        }
      ],
      popoverOptions: {
        anchorOrigin: { vertical: 'center', horizontal: 'right' },
        transformOrigin: { vertical: 'center', horizontal: 'right' }
      }
    },
    {
      show: 'selected',
      label: { id: 'ACTION_SIGNOUT_USERS', default: 'Signout user(s)' },
      action: onSignout,
      scopes: [USER.DELETE],
      primary: true,
      icon: <SignoutIcon />,
      type: 'icon'
    },
    {
      show: 'selected',
      label: { id: 'ACTION_DELETE_USER', default: 'Delete user(s)' },
      action: onDelete,
      scopes: [USER.DELETE],
      primary: true,
      icon: <RemoveIcon className="delete-icon" />,
      type: 'icon'
    }
  ]

  const getFooterActions = () => {
    const filtered = footerActions
      .filter(a => skylight.auth.hasScopes(a.scopes))
      .map(a => <CursorTooltip key={a.label.id} tooltip={<FormattedMessage id={a.label.id} defaultMessage={a.label.default} />} >
        <ActionButton
          className={a.className || ''}
          show={a.show}
          label={<FormattedMessage id={a.label.id} defaultMessage={a.label.default} />}
          onTouchTap={a.action || null}
          disabled={a.disabled}
          type={a.type}
          icon={a.icon || null}
          primary={a.primary}
          menuItems={a.children ? a.children : null}
          popoverOptions={a.popoverOptions} />
        </CursorTooltip>
      )

    return <div className="footer-actions">{filtered}</div>
  }

  const getTableFooter = () => {
    if (!users.selectedIds.length) {
      return null
    }

    return <ListFooterStats
      list={{ ...users, items }}
      onUnselect={onSelect}
      actions={getFooterActions()} />
  }

  const getTitle = () => {
    const oneAccount = users.total === 1
    return <div className="user-list-title">
      <span className="user-list-items-number">{users.total}</span>
      <FormattedMessage id={`USER_ACCOUNT${oneAccount ? '' : 'S'}`} defaultMessage={`user account${oneAccount ? '' : 's'}`} />
    </div>
  }

  return (
    <div className={`dashboard-list user-list-next-gen ${detailsOpen ? 'details-open' : ''}`}>
      <GridHeader title={getTitle()} actions={getHeadingActions()} />
      <Loading
        isLoading={users.isLoading && users.items.length === 0}
        message="LOADING_USER_LIST"
        error={users.error ? EmptyLabel : null}>
        <TableNextGen
          showCheckboxes
          tableFooter={getTableFooter()}
          isLoading={users.isLoading}
          checkboxIcon={() => <MaterialIcon icon="person" />}
          showHover
          query={users.query}
          items={items}
          total={users.total}
          done={users.done}
          selectedRows={users.selectedIds}
          name="userList"
          emptyLabel={EmptyLabel}
          onQuery={props.onUserQuery}
          onRowsSelected={onSelect}>
          <Column
            key="name"
            name="firstName,lastName"
            title={<FormattedMessage id="NAME" defaultMessage="Name" />}
            render={item => <UserTitle {...props} user={item} />} />
          <Column
            key="role"
            name="role"
            className="role-column"
            title={<FormattedMessage id="ACCOUNTTYPE" defaultMessage="Account Type" />}
            render={item => item.role} />
          <Column
            key="username"
            name="username"
            title={<FormattedMessage id="USERNAME" defaultMessage="Username" />}
            render={item => item.username} />
          <Column
            key="groups"
            name="groups"
            title={<FormattedMessage id="GROUP_MEMBERSHIP" defaultMessage="Group membership" />}
            onTouchTap={e => {
              e.stopPropagation()
            }}
            sortable={false}
            display={Table.showWide}
            render={(item, index) => <GroupMembership user={item} isList />} />
        </TableNextGen>
      </Loading>
      {skylight.auth.hasScopes([USER.CREATE]) && (
        <ListActionButton
          className={classNames('list-action-button', { 'item-selected': selectedAny })}
          icon={<NewUserIcon />}
          tooltip={<FormattedMessage id="ACTION_ADD_USER" default="New user" />}
          onAction={onCreateUser} />
      )}
    </div>
  )
}

export { UserList }

const mapStateToProps = (state, props) => ({
  users: state.contact.users,
  groups: state.contact.groups,
  scopes: state.auth.scopes
})

const mapDispatchToProps = {
  ...contactActions,
  ...dialogActions,
  onCloseDetails: appActions.onCloseDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList)
