import React from 'react'
import { MediaDetails } from './MediaDetails'
import { CaptureDetails } from './CaptureDetails'
import './AttachmentDetails.css'

const DETAILS_MAP = {
  media: MediaDetails,
  decision: CaptureDetails,
  textInput: CaptureDetails
}

const AttachmentDetails = (props) => {
  const item = { ...props.attachment.item.item }
  const { errors } = props.attachment.item
  const Comp = DETAILS_MAP[item.cardType]
  if (!Comp) {
    return null
  }

  return (
    <div className="attachment-details">
      <Comp {...props} item={item} errors={errors} />
    </div>
  )
}

export { AttachmentDetails }
