import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import skylight from 'skylight-js-sdk'
import { CONFIG } from 'skylight-common'
import LoginFormButton from '../LoginFormButton/LoginFormButton'

import './FrontlineSignin.css'

class FrontlineSignin extends Component {
  renderErrorOrHint = () => {
    const { errors } = this.props.login.signin
    const key = ['frontline'].find(x => errors[x])
    if (key) {
      return (
        <div className="error-text">
          <FormattedMessage
            id={errors[key]}
            defaultMessage="An error occurred during login. Please try again or contact administrator." />
        </div>
      )
    }

    // Return a default hint if no error to render.
    return null
  }

  async authenticateWithCode(urlSearch) {
    const authParams = {
      code: urlSearch.get('code'),
      redirectUri: location.origin + location.pathname
    }
    this.props.loginWithFrontline(authParams)
  }

  componentDidMount() {
    const { urlRealm, login, onStartSignIn, redirectWithExistingAuth } = this.props
    const urlSearch = new URLSearchParams(location.search)
    const { errors } = this.props.login.signin
    if (urlSearch.has('code')) {
      if (errors.frontline) return
      if (this.props.auth.auth !== null) return redirectWithExistingAuth()
      onStartSignIn()
      this.authenticateWithCode(urlSearch)
        .catch(err => {
          console.log('auth.err', err)
        })
      return
    }
    if (!login.signin.realm && urlRealm) {
      this.props.onRealmSignin(urlRealm, true)
      return
    }

    this.redirectOnRealmError()
  }

  get webUrl() {
    return window.origin
  }
  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    this.redirectOnRealmError(nextProps)
  }

  redirectOnRealmError = (nextProps) => {
    const { redirect, login } = nextProps || this.props
    if (login.realm.realmError && CONFIG.useCarbon) {
      redirect('/login')
    }
  }

  onFrontlineOauth = async() => {
    if (this.props.auth.auth) {
      this.props.redirectWithExistingAuth()
      return
    }

    const authUrl = await skylight.getAuthUrl({
      redirectUri: skylight.utils.resolveUrl(this.webUrl, `/login/${this.props.login.realm.realm}`)
    })

    window.location = authUrl
  }

  render() {
    return (
      <div className="signin">
        <LoginFormButton
          className="signin-frontline-btn"
          label={<FormattedMessage id="Sign in with Frontline Oauth" defaultMessage="Sign in with Frontline Oauth" />}
          primary
          fullWidth
          onClick={this.onFrontlineOauth}/>
          {this.renderErrorOrHint()}
      </div>
    )
  }
}

export { FrontlineSignin }
