import React from 'react'
import OutlinedMenuPicker from '../../../../../Common/Form/OutlinedMenuPicker/OutlinedMenuPicker'
import { FormattedMessage } from 'react-intl'
import { SOLUTIONS } from '../../../../../../lib/application/solution'
import WindowCloseMedium from '../../../../../Common/Icons/windowCloseMedium'

class SolutionPicker extends React.Component {
  onChange = (item, selected) => {
    if (selected) {
      this.props.onSolutionsChange(this.props.selectedSolutions.filter(x => x !== item))
    } else {
      this.props.onSolutionsChange([...this.props.selectedSolutions, item])
    }
  }

  onCloseIconClick = (e, item) => {
    e.stopPropagation()
    this.onChange(item, true)
  }

  render() {
    const { selectedSolutions } = this.props

    return <OutlinedMenuPicker
      onIconClick={this.onCloseIconClick}
      floatingLabelText={<FormattedMessage id="SOLUTION" defaultMessage="Solution" />}
      placeholder={<FormattedMessage id="SELECT_SOLUTION" defaultMessage="Select a solution..." />}
      rightIcon={WindowCloseMedium}
      searchDisabled
      renderItem={item => item}
      onChange={this.onChange}
      items={Object.values(SOLUTIONS)}
      selectedItems={selectedSolutions} />
  }
}

export default SolutionPicker
