import skylight from 'skylight-js-sdk'

import * as ActionTypes from '../actions/types'

const initialState = {
  isOnline: skylight.network.isConnected
}

const network = (state = initialState, payload) => {
  switch (payload.type) {
    case ActionTypes.NETWORK_STATE_CHANGED:
      return { ...state, isOnline: payload.isOnline }
    default:
      return state
  }
}

export default network
