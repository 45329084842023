import React, { Component } from 'react'

const DEFAULT_PHASE_SHIFT = -0.15
const NUMBER_OF_WAVES = 5
const WAVE_DENSITY = 5
const WAVE_FREQUENCY = 1.5
const PRIMARY_WAVE_COLOR = '#00aaed'
const PRIMARY_WAVE_LINE_WIDTH = 2
const SECONDARY_WAVE_COLOR = '#d3d3d3'
const SECONDARY_WAVE_LINE_WIDTH = 0.8
const AMPLITUDE_MULTIPLIER = 2
const TIME_CORRECTION_DIFF_SECONDS = 1

class AudioVisualizer extends Component {
  audioCtx = null
  canvasCtx = null
  requestAnimationFrame = null
  animFrameId = null
  analyser = null
  sourceNode = null
  arrayBuffer = null
  phaseShift = DEFAULT_PHASE_SHIFT
  phase = 1

  setAudioContext = () => {
    window.AudioContext = window.AudioContext || window.webkitAudioContext
    this.audioCtx = new window.AudioContext()
    this.audioCtx.suspend()
  }

  setAnalyser = () => {
    this.analyser = this.audioCtx.createAnalyser()
    this.analyser.smoothingTimeConstant = 0.6
    this.analyser.fftSize = 512
  }

  setFrequencyData = () => {
    this.frequencyData = new Uint8Array(this.analyser.frequencyBinCount)
  }

  setRequestAnimationFrame = () => {
    this.requestAnimationFrame = window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame
  }

  setBufferSourceNode = () => {
    this.sourceNode = this.audioCtx.createBufferSource()
    this.sourceNode.connect(this.analyser)
  }

  setCanvasContext = () => {
    this.canvasCtx = this.canvas.getContext('2d')
  }

  onAudioLoad = async() => {
    try {
      const res = await this.getAudioFile()
      const buffer = await this.audioCtx.decodeAudioData(res)
      this.arrayBuffer = buffer
      this.onAudioPlay(buffer)
    } catch (e) {
      console.log('decodeAudioData error', e)
    }
  }

  onAudioPlay = (buffer) => {
    this.sourceNode.buffer = buffer
    this.sourceNode.start(0)
    this.onRenderFrame()
  }

  getAudioFile = async() => {
    const res = await fetch(this.props.src)
    return res.arrayBuffer()
  }

  onRenderFrame = () => {
    if (!this.canvas) {
      return
    }

    this.animFrameId = requestAnimationFrame(this.onRenderFrame)
    this.analyser.getByteFrequencyData(this.frequencyData)
    this.onRenderWave()
  }

  onRenderWave = () => {
    const { isPlaying, progress } = this.props
    const { canvas } = this

    this.canvasCtx.clearRect(0, 0, canvas.width, canvas.height)

    this.phase += this.phaseShift
    const numberOfWaves = this.props.numberOfWaves || NUMBER_OF_WAVES
    const averageFrequency = this.frequencyData.reduce((a, b) => a + b) / this.frequencyData.length
    const playingLevel = averageFrequency * AMPLITUDE_MULTIPLIER
    const amplitude = !isPlaying && !progress ? 0 : playingLevel

    for (let i = 0; i <= numberOfWaves; i++) {
      if (i === numberOfWaves) {
        this.drawWave(this.canvasCtx, amplitude, PRIMARY_WAVE_COLOR, PRIMARY_WAVE_LINE_WIDTH)
      } else {
        const lineWidthMax = 0.1
        const lineWidthGrade = 0.02
        const lineWidth = SECONDARY_WAVE_LINE_WIDTH * (lineWidthMax - (i * lineWidthGrade))
        const lineAmplitudeGrade = 0.3
        const lineAmplitude = amplitude - amplitude * (i * lineAmplitudeGrade)
        this.drawWave(this.canvasCtx, lineAmplitude, SECONDARY_WAVE_COLOR, lineWidth)
      }
    }
  }

  drawWave(canvasCtx, amplitude, color, lineWidth) {
    canvasCtx.beginPath()
    canvasCtx.lineWidth = lineWidth || PRIMARY_WAVE_LINE_WIDTH
    canvasCtx.strokeStyle = color || PRIMARY_WAVE_COLOR
    const frequency = this.props.frequency || WAVE_FREQUENCY
    const density = this.props.density || WAVE_DENSITY
    const { canvas } = this
    const halfWidth = canvas.width / 2
    const halfHeight = canvas.height / 2
    const maxAmplitude = halfHeight - canvasCtx.lineWidth
    const normedAmplitude = Math.min(amplitude, maxAmplitude)

    canvasCtx.beginPath()
    for (let x = 0; x < (canvas.width + density); x = x + density) {
      const scaling = -Math.pow(1 / halfWidth * (x - halfWidth), 2) + 1
      const y = scaling * normedAmplitude * Math.sin(2 * Math.PI * (x / canvas.width) * frequency + this.phase) + halfHeight
      if (x === 0) {
        canvasCtx.moveTo(x, y)
      } else {
        canvasCtx.lineTo(x, y)
      }

      canvasCtx.stroke()
    }
  }

  onPlayingPause = () => {
    this.phaseShift = 0
    this.audioCtx.suspend()
  }

  onPlayingResume = () => {
    this.phaseShift = DEFAULT_PHASE_SHIFT
    this.audioCtx.resume()
  }

  componentDidMount() {
    this.setAudioContext()
    this.setAnalyser()
    this.setFrequencyData()
    this.setRequestAnimationFrame()
    this.setBufferSourceNode()
    this.setCanvasContext()
    this.onRenderFrame()
    this.onAudioLoad()
  }

  onTimeCorrection = (time) => {
    this.setBufferSourceNode()
    this.sourceNode.buffer = this.arrayBuffer
    this.sourceNode.start(0, time)
  }

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    const { isPlaying: wasPlaying, currentTime: previousTime } = this.props
    const { isPlaying, currentTime } = nextProps

    if (wasPlaying !== isPlaying) {
      isPlaying ? this.onPlayingResume() : this.onPlayingPause()
    }

    if (currentTime !== previousTime && Math.abs(previousTime - currentTime) >= TIME_CORRECTION_DIFF_SECONDS) {
      this.onTimeCorrection(currentTime)
    }
  }

  componentWillUnmount() {
    this.audioCtx.close()
  }

  render() {
    const { width, height } = this.props

    return (
      <div className={`audio-visualizer ${this.props.className || ''}`}>
        <div className="audio-visualizer-canvas-wrapper">
          <canvas
            ref={canvas => { this.canvas = canvas }}
            className="audio-visualizer-canvas"
            width={ width }
            height={ height }>
          </canvas>
        </div>
      </div>
    )
  }
}

export { AudioVisualizer }
