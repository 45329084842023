import * as ActionTypes from './types'
import {
  removeMediaItem, updateMediaItem,
  getItemMetadata, getMediaItems,
  createFilterActions, createListActions, createItemActions, validate,
  downloadRawMediaItem
} from 'skylight-common'
import { DEFAULT_QUERY } from '../lib/media'
import { push } from 'react-router-redux'
import skylight from 'skylight-js-sdk'

// const MEDIA_LIST_PREFIX = ActionTypes.PREFIX_LIST_MEDIA

const loadMediaItems = async(query) => {
  return getMediaItems(query)
}

const deleteMediaItems = async(_, items) => {
  console.log('deleting', items, _)
  return Promise.all(items.map(x => removeMediaItem(x).catch(error => ({ error }))))
}

const saveMediaItem = async(item) => {
  return updateMediaItem(item)
  // .then(() => getItemMetadata(item))
}

const loadMediaItem = async(item) => {
  console.log('loading ', item)
  item = await getItemMetadata(item)
  console.log('loaded ', item)
  return item
}

const onValidateMediaItem = (media) => {
  const validationFields = [
    { name: 'name', required: true }
  ]

  return validate(media, validationFields)
}

const COMMON_MEDIA_OPTIONS = {
  prefix: ActionTypes.PREFIX_MEDIA.toUpperCase(),
  handler: ActionTypes.PREFIX_MEDIA,
  onGetList: s => s.media.list
}

const MEDIA_LIST_ACTIONS = createListActions({
  ...COMMON_MEDIA_OPTIONS,
  onLoad: loadMediaItems,
  onDelete: deleteMediaItems
})

const MEDIA_ITEM_ACTIONS = createItemActions({
  ...COMMON_MEDIA_OPTIONS,
  onValidate: onValidateMediaItem,
  onSave: saveMediaItem,
  onLoad: loadMediaItem,
  onLoadItems: MEDIA_LIST_ACTIONS.onMediaLoadItems
})

const FILTER_ACTIONS = createFilterActions({
  baseUrl: '/media',
  redirect: false,
  selector: state => state.media.list.query,
  listPrefix: ActionTypes.PREFIX_MEDIA.toUpperCase(),
  actionPrefix: ActionTypes.PREFIX_MEDIA,
  DEFAULT_QUERY,
  loadItems: MEDIA_LIST_ACTIONS.onMediaLoadItems,
  push
})

const redirectToNewQuery = FILTER_ACTIONS.onMediaQueryUpdated

const selectFile = (files) => ({
  type: ActionTypes.NEW_MEDIA_IMAGE_SELECTED,
  files: files
})

const clearFile = () => ({
  type: ActionTypes.NEW_MEDIA_IMAGE_CLEAR
})

export const uploadedMedia = (result) => {
  return (dispatch, getState) => {
    const hasFailedUploads = result.some(f => f.error)
    const type = hasFailedUploads
      ? ActionTypes.NEW_MEDIA_IMAGE_UPLOAD_ERROR
      : ActionTypes.NEW_MEDIA_IMAGE_UPLOADED

    dispatch({ type }) // Consider passing error.
    const resetQuery = { ...getState().media.list.query, skip: 0 }
    dispatch(MEDIA_LIST_ACTIONS.onMediaLoadItems(resetQuery))
  }
}

const previewMediaItem = id => (dispatch, getState) => {
  const item = id
    ? (getState().media.list.items.find(x => x.id === id) || { id })
    : null

  dispatch({ type: ActionTypes.MEDIA_ITEM_PREVIEW, id, item })
}

const changeMediaBy = (by) => ({
  type: ActionTypes.NEW_MEDIA_CHANGE_BY,
  by
})

const setMediaUploadFiles = (files) => ({
  type: ActionTypes.NEW_MEDIA_SET_FILES,
  files
})

const setMediaUploadUrl = (url) => {
  return dispatch => {
    dispatch({ type: ActionTypes.NEW_MEDIA_SET_URL, url })
    if (!url) {
      dispatch({ type: ActionTypes.NEW_MEDIA_URL_ERROR, error: 'MEDIA_URL_REQUIRED' })
    }
  }
}

const changeMediaView = view =>
  redirectToNewQuery(query => ({ ...query, view }))

// const editMedia = (media) => ({
//   type: ActionTypes.MEDIA_EDIT,
//   media
// })

// const cancelEditMedia = () =>({
//   type: ActionTypes.MEDIA_EDIT_CANCEL
// })

// const changeMediaProp = (prop, value) => ({
//   type: ActionTypes.MEDIA_PROP_CHANGE,
//   prop,
//   value
// })

// const saveMedia = (item, query) => (dispatch, getState) => {
//   dispatch({ type: ActionTypes.MEDIA_SAVING })

//   updateMediaItem(item)
//     .then(() => getItemMetadata(item))
//     .then(item => {
//       dispatch({ type: ActionTypes.MEDIA_SAVE_SUCCESS, item })
//     })
//     .catch(err => {
//       dispatch({ type: ActionTypes.MEDIA_SAVE_ERROR, error: err.message })
//     })
// }

const filterMedia = (query, mediaType) => ({
  type: ActionTypes.MEDIA_FILTER_TYPE,
  query,
  mediaType
})

const download = (item) => async dispatch => {
  try {
    await downloadRawMediaItem(item)
    dispatch({ type: ActionTypes.MEDIA_FILE_DOWNLOADED })
  } catch (error) {
    skylight.logger.error('actions.media.download', error)
    dispatch({ type: ActionTypes.MEDIA_FILE_DOWNLOAD_ERROR, error })
  }
}

const mediaByAssignment = assignment => (dispatch, getState) => {
  const { query } = getState().media.list
  dispatch(push({
    pathname: '/media',
    query: { ...query, filter: { ...query.filter, assignment } }
  }))
}

const sortUpdated = (sortBy, sortAsc) =>
  redirectToNewQuery(query => ({ ...query, sortBy, sortAsc }))

const tableQuery = query => (dispatch, getState) => {
  const prevQuery = getState().media.list.query
  if (query.sortBy !== prevQuery.sortBy || query.sortAsc !== prevQuery.sortAsc) {
    dispatch(redirectToNewQuery(q => query))
  } else {
    dispatch(loadMediaItems(query))
  }
}

const navigateToDetails = (itemId, item) => dispatch => {
  dispatch(redirectToNewQuery(query => ({ ...query, filter: { ...query.filter, title: itemId } })))
  dispatch(MEDIA_LIST_ACTIONS.onMediaSelectItems([itemId], [item]))
}

export default {
  // onLoadMediaItems: loadMediaItems, -> onMediaLoadItems
  // onSelectMediaItems: selectMediaItems, -> onMediaSelectItems
  // onDeselectMediaItems: deselectMediaItems, -> onMediaSelectItems
  // onRemoveMediaItems: removeMediaItems, -> onMediaDeleteItems
  ...FILTER_ACTIONS,
  ...MEDIA_LIST_ACTIONS,
  ...MEDIA_ITEM_ACTIONS,
  onPreviewMediaItem: previewMediaItem,
  onNewMediaSelect: selectFile,
  onNewMediaClear: clearFile,
  onNewMediaByChanged: changeMediaBy,
  onNewMediaUploadFiles: setMediaUploadFiles,
  onNewMediaUploadUrl: setMediaUploadUrl,
  onChangeMediaView: changeMediaView,
  // onEditMedia: editMedia, -> onMediaChangeMode
  // onCancelEditMedia: cancelEditMedia, -> onMediaCancel
  // onChangeMediaProp: changeMediaProp, -> onMediaChange
  onUploadedMedia: uploadedMedia,
  // onSaveMedia: saveMedia, -> onMediaSave
  onMediaTypeFilter: filterMedia,
  onDownloadMediaItem: download,
  onMediaSortUpdated: sortUpdated,
  onMediaByAssignment: mediaByAssignment,
  onMediaTableQuery: tableQuery,
  onNavigateToDetails: navigateToDetails
}
