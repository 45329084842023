import React, { Component } from 'react'
import InfoQuestionSolid from '../../../../../../Common/Icons/InfoQuestionSolid'

class FieldControlTooltip extends Component {
  state = { showText: false }

  handleMouseEnter = () => {
    this.setState({ showText: true })
  }

  handleMouseLeave = () => {
    this.setState({ showText: false })
  }

  render() {
    const { text } = this.props
    return (
      <div className="field-control-tooltip">
        <InfoQuestionSolid
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          className="field-control-tooltip-icon" />
        {this.state.showText && <div className="field-control-tooltip-text">
          {text}
        </div>}
      </div>
    )
  }
}

export { FieldControlTooltip }
