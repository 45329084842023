import skylight from 'skylight-js-sdk'
import { json2csv } from '../../lib/csv'
import { downloadFile } from 'skylight-common'

export const exportCallEvents = (events, filter) => {
  events = events || []
  events = events.map(x => ({
    ...x,
    callerData: JSON.stringify(x.callerData),
    receiverData: JSON.stringify(x.receiverData)
  }))

  // Build CSV for export
  const eventsStr = json2csv(events, ['id', 'status', 'name'])

  // Download
  const blob = new Blob([eventsStr], { type: 'application/octet-stream' })
  downloadFile(window.URL.createObjectURL(blob), 'Skylight Call Events.csv')
}

const transformEvent = (event, callerSessionId) => {
  const source = event.sessionId === callerSessionId ? 'caller' : 'receiver'
  return {
    id: event.logId,
    timestamp: event.createdAt,
    type: event.type,
    source: source,
    payload: JSON.stringify(event.stats)
  }
}

export const getCallEvents = async(query) => {
  const roomId = query.callId
  const room = await skylight.analytics.getRoomById(roomId)
  const sortBy = query.sortBy === 'timestamp' ? 'createdAt' : query.sortBy
  const eventQuery = {
    sort: `${query.sortAsc ? '' : '-'}${sortBy}`,
    limit: query.limit
  }

  if (query.skip && query.nextCursor) {
    eventQuery.nextCursor = query.nextCursor
  }

  const call = await skylight.analytics.getRoomLogsById(roomId, eventQuery)
  const callerSessionId = room.participants.find(x => x.creator).session.sessionId
  const events = call.data
    .map(e => transformEvent(e, callerSessionId))

  return { data: events, page: call.page }
}
