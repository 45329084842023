import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const nextGenApplication = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M2,5.5 L11,1 L11,9.5 L2,13.5 L2,5.5 Z M12,15.5 L22,19.5 L13,23.5 L11,23.5 L2,19.5 L12,15.5 Z M12,11 L22,15.5 L22,17.5 L12,13.5 L2,17.5 L2,15.5 L12,11 Z M13,1 L22,5.5 L22,13.5 L13,9.5 L13,1 Z" />
    </SvgIcon>
  )
}

export default nextGenApplication
