import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const InfoQuestionSolid = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M15.07,11.25 L14.17,12.17 C13.45,12.89 13,13.5 13,15 L11,15 L11,14.5 C11,13.39 11.45,12.39 12.17,11.67 L13.41,10.41 C13.78,10.05 14,9.55 14,9 C14,7.89 13.1,7 12,7 C10.8954305,7 10,7.8954305 10,9 L8,9 C8,6.790861 9.790861,5 12,5 C14.209139,5 16,6.790861 16,9 C16,9.88 15.64,10.67 15.07,11.25 L15.07,11.25 Z M13,19 L11,19 L11,17 L13,17 L13,19 Z M12,2 C6.4771525,2 2,6.4771525 2,12 C2,14.6521649 3.0535684,17.195704 4.92893219,19.0710678 C6.80429597,20.9464316 9.3478351,22 12,22 C14.6521649,22 17.195704,20.9464316 19.0710678,19.0710678 C20.9464316,17.195704 22,14.6521649 22,12 C22,6.47 17.5,2 12,2 Z" />
    </SvgIcon>
  )
}

export default InfoQuestionSolid
