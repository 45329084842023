import React, { Children } from 'react'
import skylight from 'skylight-js-sdk'
import './SecurityTrimmer.css'

const SecurityTrimmer = (props) => {
  const allowed = Children.toArray(props.children)
    .filter((c) => skylight.auth.hasScopes(props.scopes, !!props.inverted))

  return allowed.length > 0
    ? (
    <div className="security-trimmer">
      {allowed}
    </div>
      )
    : null
}

export { SecurityTrimmer }
