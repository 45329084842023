import signin from './signin'
import realm from './realm'
import verify from './verify'
import pwdReset from './pwdReset'

export default {
  signin,
  realm,
  verify,
  pwdReset
}
