import React from 'react'
import RolesList from './RolesList/RolesList'

const Roles = props => (
  <div className="roles">
    <RolesList />
  </div>
)

export { Roles }
