import React from 'react'
import { TextFilter } from 'skylight-common'
import { AssignmentFilter } from '../../../../containers/assignment'

const VALUE = 'title'

const AssignmentTextFilter = props => {
  const value = props.query.filter && props.query.filter.title ? props.query.filter.title : ''
  const onChange = (v) => {
    const value = v.trim()
    const filter = { ...props.query.filter, [VALUE]: value }
    props.onAssignmentFilterUpdated(filter)
  }

  return <TextFilter
    value={value}
    className="assignment-text-filter"
    title={props.title || 'SEARCH'}
    iconFilter={<AssignmentFilter />}
    onChange={onChange} />
}

export { AssignmentTextFilter }
