import { connect } from 'react-redux'

import * as snackbarComps from '../App/Home/Layout/Snackbars'
import snackbarActions from '../actions/snackbar'
import appVersionActions from '../actions/application/versions'
import nextGenApplicationActions from '../actions/nextGenApplication'

const errorCreatingDraft = state => ({
  snackbar: state.snackbar
})

const errorCreatingDraftActions = {
  ...snackbarActions,
  ...appVersionActions
}

const SnackbarErrorCreatingDraft = connect(errorCreatingDraft, errorCreatingDraftActions)(snackbarComps.SnackbarErrorCreatingDraft)

const loading = state => ({
  snackbar: state.snackbar
})

const loadingActions = {
  ...snackbarActions
}

const SnackbarLoading = connect(loading, loadingActions)(snackbarComps.SnackbarLoading)

const complete = state => ({
  snackbar: state.snackbar
})

const completeActions = {
  ...snackbarActions
}

const SnackbarComplete = connect(complete, completeActions)(snackbarComps.SnackbarComplete)

const archiving = state => ({
  snackbar: state.snackbar
})

const archivingActions = {
  ...snackbarActions,
  ...nextGenApplicationActions
}

const SnackbarErrorArchivingApplication = connect(archiving, archivingActions)(snackbarComps.SnackbarErrorArchivingApplication)

const success = state => ({
  snackbar: state.snackbar
})

const successActions = {
  ...snackbarActions
}

const SnackbarSuccess = connect(success, successActions)(snackbarComps.SnackbarSuccess)

const SnackbarMessage = connect(success, snackbarActions)(snackbarComps.SnackbarMessage)

const SnackbarIncompatibleVersionError = connect(success, snackbarActions)(snackbarComps.SnackbarIncompatibleVersionError)
const SnackbarUpgradeError = connect(success, snackbarActions)(snackbarComps.SnackbarUpgradeError)

export {
  SnackbarErrorCreatingDraft,
  SnackbarLoading,
  SnackbarComplete,
  SnackbarErrorArchivingApplication,
  SnackbarSuccess,
  SnackbarMessage,
  SnackbarIncompatibleVersionError,
  SnackbarUpgradeError
}
