import React from 'react'

// https://stackoverflow.com/questions/6312993/javascript-seconds-to-time-string-with-format-hhmmss
const getDuration = (start, now) => {
  const duration = (now - start) / 1000
  const totalSeconds = parseInt(duration, 10)
  let hours = Math.floor(totalSeconds / 3600)
  let minutes = Math.floor((totalSeconds - (hours * 3600)) / 60)
  let seconds = totalSeconds - (hours * 3600) - (minutes * 60)

  if (hours < 10) { hours = '0' + hours }
  if (minutes < 10) { minutes = '0' + minutes }
  if (seconds < 10) { seconds = '0' + seconds }

  return `${hours}:${minutes}:${seconds}`
}

class CallDuration extends React.Component {
  constructor(props) {
    super(props)
    const now = new Date()
    this.state = {
      start: now,
      now
    }
    this.interval = setInterval(() => {
      this.setState({ now: new Date() })
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const duration = getDuration(this.state.start, this.state.now)
    return (
      <div className="call-duration">
        {duration}
      </div>
    )
  }
}

export { CallDuration }
