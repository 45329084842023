import React from 'react'
import { FormattedMessage } from 'react-intl'
import skylight from 'skylight-js-sdk'

export default (props) => {
  const { authApps, onStartSignIn } = props
  if (!authApps || authApps.length === 0) return null

  const onSignInIdp = async(e, app) => {
    e.preventDefault()
    const redirectSearch = window.location.search
    await skylight.storage.setItem('redirectSearch', redirectSearch)

    onStartSignIn()

    const url = window.location

    window.location.href = `${app.url}?url=${url.protocol}//${url.host}/login/sso`
  }

  return (
    <div className="sign-in-picker">
      <div className="sign-in-using">
        <FormattedMessage id="SAML_OR" defaultMessage="or" />
      </div>
      <div className="sign-in-saml">
        <a
          href="saml"
          className="btn"
          onClick={(e) => onSignInIdp(e, authApps[0])}>
          <FormattedMessage id="SIGN_IN_SAML" defaultMessage="Sign in with SAML" />
        </a>
      </div>
    </div>
  )
}
