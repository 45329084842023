import { combineReducers } from 'redux'
import list from './list'
import item from './item'
import event from './event'
import ssrcVideoSend from './ssrcVideoSend'
import bweForVideo from './bweForVideo'

export default {
  list,
  item,
  event: combineReducers(event),
  ssrcVideoSend: combineReducers(ssrcVideoSend),
  bweForVideo: combineReducers(bweForVideo)
}
