import React, { Component } from 'react'
import classNames from 'classnames'
import { OutlinedField } from '../OutlinedField'
import { TextField } from 'material-ui'

import './OutlinedTextField.css'

class OutlinedTextField extends Component {
  state = {
    focused: false
  }

  handleFocus = e => {
    this.props.onFocus && this.props.onFocus(e)
    this.setState({ focused: true })
  }

  handleBlur = e => {
    this.props.onBlur && this.props.onBlur(e)
    this.setState({ focused: false })
  }

  handleChange = event => {
    this.props.onChange && this.props.onChange(event, event.target.value)
  }

  renderTextField = (fieldProps) => {
    const {
      disabled,
      readonly,
      value
    } = this.props

    if (disabled || readonly) {
      return <div className="text-field">
        <span>{value}</span>
      </div>
    }

    const { textFieldRef, ...other } = fieldProps

    return <TextField
      {...other}
      hintStyle={{ opacity: value ? 0 : 0.33 }}
      ref={textFieldRef}
      type={this.props.type}
      disabled={this.props.disabled}
      multiLine={this.props.multiLine}
      className="text-field"
      value={this.props.value}
      name={this.props.name}
      onChange={this.handleChange}
      onFocus={this.handleFocus}
      onBlur={this.handleBlur} />
  }

  render() {
    const {
      className,
      floatingLabelText,
      required,
      error,
      errorText,
      children,
      onMouseOver,
      onMouseLeave,
      ...other
    } = this.props

    return <OutlinedField
      {...other}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      error={error || errorText}
      floatingLabelText={floatingLabelText}
      required={required}
      focused={this.state.focused}
      className={classNames('outlined-text-field', className)}>
      {this.renderTextField(other)}
      {children}
    </OutlinedField>
  }
}

export { OutlinedTextField }
