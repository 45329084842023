export const MQTT_EVENT_TYPES = {
  IMPORT: {
    IMPORT_ERRORED: 'application_import_errored',
    IMPORT_COMPLETED: 'application_import_completed'
  },
  EXPORT: {
    EXPORT_QUEUED: 'application_export_queued',
    EXPORT_IN_PROGRESS: 'application_export_in_progress',
    EXPORT_COMPLETED: 'application_export_completed',
    EXPORT_ERRORED: 'application_export_errored'
  },
  UPGRADE: {
    CREATED: 'application_upgrade_created',
    QUEUED: 'application_upgrade_queued',
    INPROGRESS: 'application_upgrade_in_progress',
    COMPLETED: 'application_upgrade_completed',
    ERRORED: 'application_upgrade_errored',
    DELETED: 'application_upgrade_deleted'
  }
}
