import React from 'react'
import { ByList } from 'skylight-common'
import { CALL_DEVICES } from '../../../../../lib/activity'

const ByCallDevice = props => {
  return (
    <ByList
      className="by-caller-device"
      title="CALL_DEVICE_NAME"
      placeholder={props.placeholder}
      value={props.value}
      enableSorting={true}
      onChange={props.onChange}
      onRemove={props.onRemove}
      onGetItems={() => Promise.resolve(CALL_DEVICES.map(k => ({ text: k, value: k })))}/>
  )
}

export { ByCallDevice }
