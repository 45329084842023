import React from 'react'
import { ByList } from 'skylight-common'
import './ByTextInput.css'

const OPTIONS = [
  { text: 'HIDE_EMPTY_INPUTS', value: 'Hide empty inputs' }
]

const getOptions = () => Promise.resolve(OPTIONS)

const ByTextInput = props => {
  return (
    <ByList
      className="by-text-input"
      title="TEXT_INPUT"
      value={props.value}
      renderFilter={() => null}
      enableSorting={false}
      onChange={props.onChange}
      onRemove={props.onRemove}
      onGetItems={getOptions} />
  )
}

export { ByTextInput }
