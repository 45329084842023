import React from 'react'
import { EVENT_SOURCES } from '../../../../../../../lib/application/event'

const eventTypeExtractor = event => {
  switch (event.source) {
    case EVENT_SOURCES.TWILIO: {
      if (event.eventType === 'session_call_event' && event.call) {
        return event.call.StatusCallbackEvent
      }

      return event.eventType
    }
    case EVENT_SOURCES.SKYLIGHT:
    default: {
      return event.eventType
    }
  }
}

export const EventType = ({ event }) => {
  return <span>{eventTypeExtractor(event)}</span>
}
