import React from 'react'
import { Dialog } from 'material-ui'
import { DialogTitle } from 'skylight-common'
import FileUpload from './FileUpload/FileUpload'

import './ImportArchiveUpload.css'

export class ImportArchiveUpload extends React.Component {
  componentDidMount() {
    window.addEventListener('beforeunload', this.handleUnload)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleUnload)
  }

  handleUnload = e => {
    e.preventDefault()
    // Older browsers supported custom message
    e.returnValue = ''
  }

  render() {
    const { dialog, dialogType, upload, onCancelImportUpload } = this.props
    const { open } = dialog[dialogType] || {}

    return (
      <div>
        <Dialog
          bodyClassName="import-archive-upload-body"
          className="dialog-modal import-archive-upload"
          title={<DialogTitle id="UPLOADING_FILE" defaultMessage="Uploading file" />}
          modal={false}
          autoDetectWindowHeight={false}
          contentStyle={{ maxWidth: '543px' }}
          open={!!open}>
          <FileUpload upload={upload} onCancelImportUpload={onCancelImportUpload} />
        </Dialog>
      </div>
    )
  }
}
