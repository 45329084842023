import React from 'react'
import { FormattedMessage } from 'react-intl'
import './AssignmentDescription.css'

const STATUS_CLASS = {
  complete: 'complete',
  inProgress: 'in-progress',
  error: 'error'
}

const AssignmentDescription = (props) => {
  const { item } = props
  if (!item.isTask) {
    return <span className="assignment-description">{item.description}</span>
  }

  const className = STATUS_CLASS.hasOwnProperty(item.status) ? STATUS_CLASS[item.status] : ''
  const translation = `ASSIGNMENT_${item.status.toUpperCase()}`
  return <span className={`assignment-description ${className}`}>
    <FormattedMessage id={translation} defaultMessage="N/A" />
    </span>
}

export { AssignmentDescription }
