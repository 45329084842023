import React from 'react'
import Dialog from 'material-ui/Dialog'

import { DialogTitle } from 'skylight-common'
import { AssignWorkflow } from '../../../Assignments/AssignmentDetails/AssignWorkflow'
import { ApplicationTextFilter } from '../../../../../containers/applications'

import './AddAssignment.css'

const PREVENT_PATH_SAVE = true // Prevents saving filter value by location

class AddAssignment extends React.Component {
  state = { step: 0 }

  onHide = () => {
    this.props.onHideDialog(this.props.dialogType)
    // Clears filters and list on dialog close.
    this.props.onWorkflowResetItems()
  }

  onWorkflowAssigned = (items, users) => {
    this.props.onWorkflowAssigned(items, users)
    this.onHide()
  }

  onStepChange = step => {
    this.setState({ step })
  }

  render() {
    const { dialog, dialogType } = this.props
    const { open, params } = dialog[dialogType] || {}
    const { item } = this.props.assignment

    return (
      <div className="add-assignment">
        <Dialog
          title={<DialogTitle id="ASSIGNMENT_ADD_DIALOG" defaultMessage="Add new assignment">
            <ApplicationTextFilter preventPathSave={PREVENT_PATH_SAVE} />
          </DialogTitle>}
          actions={[]}
          modal={false}
          open={!!open}
          className="dialog-modal add-connect-app-dialog"
          contentClassName={`add-connect-app-dialog-content ${this.state.step !== 0 ? 'filter-disabled' : ''}`}
          bodyClassName="add-connect-app-dialog-body"
          autoScrollBodyContent={true}
          onRequestClose={this.onHide}>
          <AssignWorkflow
            {...this.props}
            onWorkflowAssigned={this.onWorkflowAssigned}
            onStepChange={this.onStepChange}
            onCancel={this.onHide}
            onWorkflowQuery={q => this.props.onWorkflowQuery(q, { preventPathSave: PREVENT_PATH_SAVE })}
            assignmentItem={{ ...item, user: params.user }} />
        </Dialog>
      </div>
    )
  }
}

export { AddAssignment }
