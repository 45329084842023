import React from 'react'

const SelectedItems = props => {
  const { items, itemIcon, renderItem } = props
  const renderItems = () => {
    return items.map((item, i) =>
      <div key={i} className="selected-item">
        {itemIcon}
        {renderItem(item)}
      </div>
    )
  }

  return <div className="selected-items">
    {renderItems()}
  </div>
}

export { SelectedItems }
