import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const signOut = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M9.92,15.59 L7.33,13 L17,13 L17,11 L7.33,11 L9.92,8.41 L8.5,7 L3.5,12 L8.5,17 L9.92,15.59 Z M5,3 C3.8954305,3 3,3.8954305 3,5 L3,9.67 L5,7.67 L5,5 L19,5 L19,19 L5,19 L5,16.33 L3,14.33 L3,19 C3,20.1045695 3.8954305,21 5,21 L19,21 C20.11,21 21,20.1 21,19 L21,5 C21,3.89 20.11,3 19,3 L5,3 Z" id="shape01"/>
    </SvgIcon>
  )
}

export default signOut
