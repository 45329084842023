import React from 'react'
import { UserTitle, getUserTitle, DetailsLink } from 'skylight-common'
import skylight from 'skylight-js-sdk'
import { USER } from '../../../svc/scopes'

const UserDetailsLink = props => {
  const { linkText } = props

  const getLinkText = user => {
    if (!!linkText && typeof linkText === 'string') {
      return linkText
    }

    if (typeof linkText === 'function') {
      return linkText(user)
    }

    return getUserTitle(user)
  }

  const renderUserTitle = user => {
    const canSeeUserDetails = skylight.auth.hasScopes([USER.EDIT])
    if (canSeeUserDetails) {
      return <DetailsLink
        item={user}
        path="/team/users"
        renderLink={getLinkText}
        onNavigateToDetails={x => props.onUserSelectItems([x.id], [x])} />
    }

    return <span>{user.username || user.userId}</span>
  }

  return <div className="user-details-link">
    <UserTitle user={props.userId} render={renderUserTitle} />
  </div>
}

export { UserDetailsLink }
