import React from 'react'
import { FormattedMessage } from 'react-intl'
import MenuItem from 'material-ui/MenuItem'
import { UserImage } from '../../../../Common/UserImage'
import { OutlinedTextField } from '../../../../Common/Form/OutlinedTextField'
import { OutlinedSelectField } from '../../../../Common/Form/OutlinedSelectField'
import { FormSection } from '../../../../Common/Form/FormSection'
import './AccountInfo.css'

const AccountInfo = (props) => {
  return (
    <FormSection
      className="account-info-section"
      title={<FormattedMessage id="ACCOUNT_INFO" defaultMessage="Account Info" />}
      actions={<UserImage
        className="account-info-avatar"
        src={props.form.avatar}
        disableFooter
        onUploadImage={props.onUploadImage} />}>
      <div className="form-fields">
        <div className="form-fields-row">
          <OutlinedTextField
            className="form-field"
            name="username"
            value={props.form.username || ''}
            error={props.errors.username}
            required
            disabled={!!props.form.id}
            floatingLabelText={
              <FormattedMessage id="USERNAME" defaultMessage="Username" />
            }
            onChange={props.onChange} />
          <OutlinedSelectField
            className="form-field"
            required
            floatingLabelText={<FormattedMessage id="ACCOUNT_TYPE" defaultMessage="Account type" />}
            disabled={!!props.form.id}
            error={props.errors.role}
            onChange={(e, i, v) => props.onChange(e, v, 'role')}
            value={props.form.role}>
            <MenuItem value="user" primaryText={<FormattedMessage id="USER" defaultMessage="User" />} />
            <MenuItem value="manager" primaryText={<FormattedMessage id="MANAGER" defaultMessage="Manager" />} />
            <MenuItem value="admin" primaryText={<FormattedMessage id="ADMIN" defaultMessage="Admin" />} />
          </OutlinedSelectField>
        </div>
        <div className="form-fields-row">
          <OutlinedTextField
            className="form-field"
            name="firstName"
            value={props.form.firstName || ''}
            error={props.errors.firstName}
            floatingLabelText={
              <FormattedMessage id="FIRSTNAME" defaultMessage="First Name" />
            }
            onChange={props.onChange} />
        </div>
        <div className="form-fields-row">
          <OutlinedTextField
            className="form-field"
            name="lastName"
            value={props.form.lastName || ''}
            error={props.errors.lastName}
            floatingLabelText={
              <FormattedMessage id="LASTNAME" defaultMessage="Last Name" />
            }
            onChange={props.onChange} />
        </div>
        <div className="form-fields-row">
          <OutlinedTextField
            className="form-field"
            name="jobTitle"
            value={props.form.jobTitle || ''}
            error={props.errors.jobTitle}
            floatingLabelText={
              <FormattedMessage id="JOBTITLE" defaultMessage="Job title" />
            }
            onChange={props.onChange} />
        </div>
        <div className="form-fields-row">
          <OutlinedTextField
            className="form-field"
            name="email"
            required={props.form.role === 'admin'}
            value={props.form.email || ''}
            error={props.errors.email}
            floatingLabelText={
              <FormattedMessage id="EMAIL" defaultMessage="Email" />
            }
            onChange={props.onChange} />
        </div>
      </div>
    </FormSection>
  )
}

export { AccountInfo }
