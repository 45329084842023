import React from 'react'
import { Snackbar } from 'skylight-common'
import { FormattedMessage } from 'react-intl'

import './SnackbarSuccess.css'

const DEFAULT_AUTOCLOSE_TIMEOUT = 3

const SnackbarSuccess = props => {
  const { snackbar, snackbarType } = props
  const { open, params } = snackbar[snackbarType] || {}

  const handleClose = () => {
    props.onHideSnackbar(snackbarType)
  }

  const renderMessage = () => {
    const { message } = params
    return <FormattedMessage id={message} defaultMessage="Success" />
  }

  const autoCloseTimeout = params.autoCloseTimeout || DEFAULT_AUTOCLOSE_TIMEOUT
  return (
    <Snackbar autocloseSeconds={autoCloseTimeout} className="snackbar-success" open={open} onRequestClose={handleClose}>
      <div className="snackbar-message">
        {renderMessage()}
      </div>
    </Snackbar>
  )
}

export { SnackbarSuccess }
