import React from 'react'
import { OutlinedTextField } from '../../Form/OutlinedTextField'

const FieldPassword = props => {
  const {
    name,
    value,
    label,
    disabled,
    required,
    multiline,
    error,
    onChange
  } = props

  return (
    <div className={`field field-password ${required ? 'required' : ''}`}>
      <OutlinedTextField
        name={name}
        required={required}
        value={value || ''}
        disabled={disabled}
        errorText={error}
        floatingLabelText={label}
        multiLine={multiline}
        type="password"
        onChange={(e, value) => onChange(name, value)}/>
    </div>
  )
}

export { FieldPassword }
