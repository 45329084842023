import React from 'react'
import Progress from 'material-ui/CircularProgress'
import './PdfPage.css'

// const renderFullSizePage = (ctx, pageNum) => {
//   const {canvas} = ctx
//   return renderPage(ctx, pageNum, canvas, 2.0).then(pageNum => {
//     // save canvas image as data url
//     ctx.pageImageUrl = canvas.toDataURL('image/png', 1.0) // full quality
//   })
// }

class PdfPage extends React.Component {
  constructor(props) {
    super(props)

    this.renderPage = (state) => {
      const { pdf, pageNumber, scale, isRendering } = state
      const canvas = this.canvas
      if (!pdf || !pageNumber || !canvas || isRendering) return
      this.setState({ isRendering: true })
      setTimeout(() => {
        // User already scrolled too much, no need to render this page anymore.
        if (this.isUnmounted) {
          return
        }

        pdf.getPage(pageNumber).then((page) => {
          const viewport = page.getViewport({ scale })

          const context = canvas.getContext('2d')
          if (!context) return null

          canvas.height = viewport.height
          canvas.width = viewport.width

          const renderContext = {
            canvasContext: context,
            viewport: viewport
          }

          this.renderTask = page.render(renderContext)
          this.renderTask.promise.then(() => {
            this.setState({ isRendering: false })
          }).catch(err => {
            console.log('PdfPage.render.error', err)
          })
        })
      }, 300) // Prevent render during the scroll.
    }

    this.setStateFromProps = (props, isMounted) => {
      const state = {
        pageNumber: props.pageNumber,
        pdf: props.pdf,
        scale: props.scale,
        renderPromise: null
      }

      if (isMounted) {
        this.setState(state, () => {
          this.renderPage(state)
        })
      } else {
        this.state = state
      }
    }

    this.setStateFromProps(props, false)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.pageNumber !== nextProps.pageNumber ||
      this.state.isRendering !== nextState.isRendering ||
      this.state.pdf !== nextState.pdf
    )
  }

  // eslint-disable-next-line
  componentWillReceiveProps (nextProps) {
    if (nextProps.pageNumber === this.state.pageNumber &&
      nextProps.pdf === this.state.pdf) {
      return
    }

    this.setStateFromProps(nextProps, true)
  }

  componentWillUnmount() {
    this.isUnmounted = true
    this.setState = () => {}
    // Cancel heavy render task.
    this.renderTask && this.renderTask.cancel()
  }

  render() {
    const className = `pdf-page ${this.props.className || ''} ${this.state.pdf && !this.state.isRendering ? '' : 'loading'}`
    const setCanvasRef = ref => {
      if (ref) {
        const shouldRender = this.canvas !== ref
        this.canvas = ref
        shouldRender && this.renderPage(this.state)
      }
    }

    const onClick = (e) => {
      this.props.onClick && this.props.onClick(e)
    }

    return (
      <div className={className}>
        <div className="loading-indicator">
          <Progress />
        </div>
        <canvas ref={setCanvasRef} onClick={onClick} />
      </div>
    )
  }
}

export { PdfPage }
