import React from 'react'
import { FilterToolbar, ByDateRange } from 'skylight-common'
import { DEFAULT_EVENTS_QUERY } from '../../../../lib/activity'
import { ByEventName } from './ByEventName'
import './ActivityFilter.css'

const FILTERS = [
  { text: 'ACTIVITY_EVENT_NAME', value: 'eventTypes', comp: ByEventName },
  { text: 'CREATED_TIME', value: 'timestamp', props: { title: 'CREATED_TIME' }, comp: ByDateRange }
]

const ActivityFilter = props => <FilterToolbar
  className="activity-filter"
  filters={FILTERS}
  query={props.query}
  layout={props.layout}
  defaultQuery={DEFAULT_EVENTS_QUERY}
  onFilterUpdated={props.onActivityFilterUpdated}
  onFiltersUpdated={props.onActivityFiltersUpdated}
  onFilterRemoved={props.onActivityFilterRemoved} />

export { ActivityFilter }
