import { connect } from 'react-redux'

import contactActions from '../actions/contact'

import { GroupMembership as GroupMembershipComp } from '../App/Home/Contacts/GroupMembership/GroupMembership'
import { UserDetailsLink as UserDetailsLinkComp } from '../App/Common'

const groupMembership = (state, props) => ({
  groups: state.contact.groups
})

const groupMembershipActions = contactActions

const userDetailsLink = (state, props) => ({})

const userDetailsLinkActions = contactActions

const GroupMembership = connect(groupMembership, groupMembershipActions)(GroupMembershipComp)
const UserDetailsLink = connect(userDetailsLink, userDetailsLinkActions)(UserDetailsLinkComp)

export { GroupMembership, UserDetailsLink }
