import skylight from 'skylight-js-sdk'
import * as ActionTypes from './types'
import { v4 as uuidv4 } from 'uuid'
import { subscribeToClientEvents, unsubscribeFromClientEvents, clientId } from '../svc/mqtt'

const TIMEOUT = 3000

const createSocketHandler = () => {
  const self = {
    type: 'socket',
    promise: null,
    handler: message => {
      if (message.type === 'PONG') {
        self.promise.resolve()
      }
    },
    run: () => {
      if (skylight.socket.isDisconnected()) {
        throw new Error('socket disconnected')
      }
      return new Promise((resolve, reject) => {
        self.promise = { resolve, reject }
        skylight.events.on('socketMessage', self.handler)
        skylight.socket.emit('PING')
      })
    },
    dispose: () => {
      skylight.events.off('socketMessage', self.handler)
    }
  }
  return self
}

const createMqttHandler = (realm) => {
  const self = {
    messageId: null,
    type: 'mqtt',
    handler: ({ message }) => {
      self.promise.resolve()
      console.log('netscan: got mqtt message', message)
      if (message && message.messageId === self.messageId) {
        self.promise.resolve()
      }
    },
    run: async() => {
      if (!skylight.mqtt.isConnected) {
        throw new Error('MQTT not connected')
      }

      console.log('netscan: subscribe to client events', clientId)
      await subscribeToClientEvents()
      console.log('netscan: subscribed', clientId)

      self.messageId = uuidv4()

      // 'publish' a message
      const payload = {
        messageId: self.messageId,
        deviceTimestamp: new Date().toISOString()
      }

      return new Promise((resolve, reject) => {
        self.promise = { resolve, reject }
        skylight.events.on(skylight.mqtt.EVENTS.MESSAGE, self.handler)
        console.log('netscan: publishing mqtt ping message', clientId, payload)
        skylight.mqtt.publish(`v1/realms/${realm}/clients/${clientId}/ping`, payload)
      })
    },
    dispose: async() => {
      console.log('netscan: unsubscribing from client events')
      await unsubscribeFromClientEvents()
      skylight.events.off(skylight.mqtt.EVENTS.MESSAGE, self.handler)
    }
  }

  return self
}

const createApiHandler = () => ({
  type: 'api',
  run: async() => {
    await skylight.user.getById(skylight.auth.user.id)
  }
})

let timeout

const onLoad = () => (dispatch, getState) => {
  const { user } = getState().auth
  clearTimeout(timeout)
  const now = Date.now()
  dispatch({ type: ActionTypes.NET_SCAN_LOAD, now })

  const socketHandlers = skylight.auth.isLegacyEnabled() ? [createSocketHandler()] : []

  const handlers = [
    ...socketHandlers,
    createMqttHandler(user.realm),
    createApiHandler()
  ]

  handlers.forEach(async x => {
    try {
      x.now = now
      let value = await x.run()
      if (!value) {
        value = `${Date.now() - x.now}ms`
      }
      dispatch({ type: ActionTypes.NET_SCAN_ITEM_LOADED, now, item: { type: x.type, value } })
    } catch (e) {
      dispatch({ type: ActionTypes.NET_SCAN_ITEM_LOADED, now, item: { type: x.type, error: true } })
    } finally {
      x.dispose && x.dispose()
      x.isDisposed = true
    }

    if (!handlers.some(x => !x.isDisposed)) {
      dispatch({ type: ActionTypes.NET_SCAN_LOADED, now })
      clearTimeout(timeout)
    }
  })

  timeout = setTimeout(() => {
    handlers.forEach(async x => {
      if (!x.isDisposed && x.dispose) {
        await x.dispose()
      }
    })
    dispatch({ type: ActionTypes.NET_SCAN_LOADED, now })
  }, TIMEOUT)
}

export default {
  onLoad
}
