import React from 'react'
import MenuItem from 'material-ui/MenuItem'
import { FormattedMessage } from 'react-intl'
import { OutlinedSelectField } from '../../../../Common/Form/OutlinedSelectField'
import { FormSection } from '../../../../Common/Form/FormSection'
import { SUPPORTED_LOCALES } from '../../../../../svc/locale'
import { storage } from 'skylight-common'
import './AccountLocalization.css'

const AccountLocalization = (props) => {
  const onChangeLanguage = (e, _, v) => {
    props.onChange(e, v, 'locale')
    if (v) {
      props.onSwitchLocale(v)
    } else {
      storage.removeItem('locale')
      props.onSwitchLocaleForUser(props.form)
    }
  }

  const localeValue = props.form.locale || ''

  return (
    <FormSection
      className="account-localization"
      title={<FormattedMessage id="ACCOUNT_LOCALIZATION" defaultMessage="Localization" />}>
      <div className="form-fields">
        <div className="form-fields-row">
          <OutlinedSelectField
            className="form-field"
            floatingLabelText={
              <FormattedMessage id="LANGUAGE" defaultMessage="Language" />
            }
            onChange={onChangeLanguage}
            value={localeValue}>
            <MenuItem value="" primaryText={<FormattedMessage id="DOMAIN_DEFAULT" defaultMessage="Domain default" />} />
            {SUPPORTED_LOCALES.map(x => <MenuItem key={x.value} value={x.value} primaryText={x.title} />)}
          </OutlinedSelectField>
        </div>
      </div>
    </FormSection>
  )
}

export { AccountLocalization }
