import React from 'react'
import { FormattedMessage } from 'react-intl'
import CircularProgress from 'material-ui/CircularProgress'
import IconButton from 'material-ui/IconButton'
import {
  Column,
  DeleteIcon,
  HeadingActions,
  isPhone,
  ListActionButton,
  ListFooterStats,
  Loading,
  MaterialIcon,
  Table
} from 'skylight-common'
import AssignIcon from '../../../Common/Icons/assignmentCreated'
import ArchiveIcon from 'material-ui/svg-icons/content/archive'
import skylight from 'skylight-js-sdk'
import { ASSIGNMENT } from '../../../../svc/scopes'
import './AssignmentList.css'
import { PendingIndicator } from '../PendingIndicator/PendingIndicator'
import { ASSIGNMENT_FILTER_TYPES } from '../../../../lib/assignment'

const EmptyLabel = <FormattedMessage
  id="EMPTY_ASSIGNMENTS_LIST"
  defaultMessage="There are no active assignments." />

class AssignmentList extends React.Component {
  getRowClass = (item) => {
    if (item.archived) {
      return 'archived'
    } else if (!item.isTask) {
      return ''
    }

    const status = item.status === 'error' ? 'error' : 'in-progress'
    return `wf-task ${status}`
  }

  onNavigateToUser = (item) => {
    this.props.onUserSelectItems([item.id], [item])
  }

  getCheckboxIcon = (item) => {
    if (!item.isTask) {
      return <MaterialIcon
        className="skylight-icon"
        icon={item.archived ? 'archive' : 'assignment'} />
    }

    if (item.status === 'error') {
      return <MaterialIcon
        className="error-icon"
        icon="assignment_late" />
    }

    return <CircularProgress size={20} thickness={2} className="checkbox-icon" />
  }

  isItemSelectable = (x) => x && x.status !== 'inProgress'

  onSelect = (ids) => {
    const { items } = this.props.assignmentList
    ids = ids.length > 1
      ? ids
        .map(id => items.find(i => i.id === id))
        .filter(this.isItemSelectable)
        .map(i => i.id)
      : ids

    this.props.onAssignmentSelectItems(ids, items)
  }

  removeAssignments = (ids) => {
    this.props.onAssignmentDeleteItems(ids || this.props.assignmentList.selectedIds)
    this.props.onShowSnackbar('DELETING_ASSIGNMENT')
  }

  onDelete = (selectedIds) => {
    this.props.onConfirm(
      () => this.removeAssignments(selectedIds),
      {
        title: 'ASSIGNMENT_DELETE_DIALOG_TITLE_MULTI',
        message: 'ASSIGNMENT_DELETE_DIALOG_DESCRIPTION',
        action: 'ACTION_DELETE_ASSIGNMENT'
      }
    )
  }

  onAssign = () => {
    const { user, type } = this.props
    const action = isPhone() ? 'onAssignWorkflow' : 'onShowNewAssignmentDialog'

    this.props[action](type === 'my' ? user : null)
  }

  onUnassign = () => {
    const { selectedIds } = this.props.assignmentList
    this.props.onUnassignWorkflow(selectedIds)
    this.props.onShowSnackbar('ARCHIVING_ASSIGNMENT')
  }

  onCapturedMedia = () => {
    const { selectedIds } = this.props.assignmentList
    this.props.onMediaByAssignment(selectedIds.join(','))
  }

  onDeleteTask = (e, item) => {
    e && e.stopPropagation()
    this.onDelete([item.id])
  }

  renderItemActions = (item) => {
    return item.isTask && item.status === 'error'
      ? <IconButton
        className="item-action-button"
        tooltipPosition="bottom-left"
        tooltip={<FormattedMessage id="ACTION_REMOVE_TASK" defaultMessage="Remove" />}
        onTouchTap={e => this.onDeleteTask(e, item)}>
      <MaterialIcon icon="cancel" />
      </IconButton>
      : null
  }

  getHeadingActions() {
    const { assignmentList: list } = this.props
    const items = list.selectedIds.map(id => list.items.find(x => x.id === id))
    let hasTasks = false
    let hasAssignments = false
    items.forEach(item => {
      item.isTask ? hasTasks = true : hasAssignments = true
    })
    const hasUnarchived = items.filter(x => !x.isTask && !x.archived).length > 0
    const hasSelected = list.selectedIds.length > 0

    let headingActions = [
      // https://upskill.atlassian.net/browse/SKY-905
      // {
      //   name: 'media',
      //   show: 'single',
      //   label: { id: 'CAPTURED_MEDIA', default: 'Captured media' },
      //   action: this.onCapturedMedia,
      //   hidden: !hasSelected,
      //   primary: true,
      //   icon: MediaIcon
      // },
      {
        name: 'archive',
        show: 'selected',
        label: { id: 'ACTION_UNASSIGN_WORKFLOW', default: 'Unassign' },
        action: this.onUnassign,
        hidden: !hasSelected,
        disabled: !hasUnarchived,
        icon: ArchiveIcon,
        scopes: [ASSIGNMENT.ASSIGN]
      },
      {
        name: 'delete',
        show: 'selected',
        label: { id: 'ACTION_DELETE_ASSIGNMENT', default: 'Delete Assignment(s)' },
        action: () => this.onDelete(),
        hidden: !hasSelected,
        primary: true,
        icon: DeleteIcon,
        scopes: [ASSIGNMENT.DELETE]
      }
    ]

    if (hasTasks && hasAssignments) {
      headingActions.forEach(x => { x.disabled = true }) // Let's not confuse user.
    }

    if (hasTasks && !hasAssignments) {
      headingActions = headingActions.filter(x => x.name === 'delete')
    }

    return headingActions
      .filter((a) => skylight.auth.hasScopes(a.scopes))
  }

  getListClass = () => {
    const { selectedIds } = this.props.assignmentList

    return [
      'assignment-list',
      'dashboard-list',
      selectedIds.length === 1 ? 'details-open' : ''
    ].join(' ')
  }

  showPending = () => {
    this.props.redirect('assignments/pending')
  }

  isPending = () => {
    return this.props.type === ASSIGNMENT_FILTER_TYPES.PENDING
  }

  getTableFooter = () => {
    const list = this.props.assignmentList
    const hasSelected = list.selectedIds.length > 0

    if (hasSelected) {
      return <ListFooterStats list={list} onUnselect={this.onSelect} />
    }

    if (!!list.pendingTotal && this.props.type === ASSIGNMENT_FILTER_TYPES.TEAM) {
      return <PendingIndicator onClick={this.showPending} total={list.pendingTotal} />
    }

    return null
  }

  render() {
    const { assignmentList: list, columns = [], scopes } = this.props
    const done = this.isPending() ? list.done : true

    return (
      <div className={this.getListClass()}>
        <Loading
          isLoading={!this.isPending() && list.isLoading}
          message="LOADING_ASSIGNMENT_LIST"
          error={list.error ? EmptyLabel : null}>
          <Table
            showRowNames={true}
            showCheckboxes={true}
            tableHeaderActions={[<HeadingActions key="heading-actions" actions={this.getHeadingActions()} />]}
            rowClassName={item => this.getRowClass(item)}
            checkboxIcon={(item) => this.getCheckboxIcon(item)}
            showHover={true}
            tableFooter={this.getTableFooter()}
            isLoading={list.isLoading}
            query={list.query}
            items={list.items}
            total={list.total}
            done={done}
            selectedRows={list.selectedIds}
            name="assignmentList"
            emptyLabel={EmptyLabel}
            preventItemSelect={this.isItemSelectable}
            onQuery={this.props.onAssignmentQuery}
            onRowsSelected={this.onSelect}>
            {columns.map((c) =>
              <Column
                key={c.name}
                name={c.name}
                className={c.className || ''}
                sortable={c.sortable !== false}
                title={<FormattedMessage id={c.title.id} defaultMessage={c.title.default} />}
                render={c.render
                  ? x =>
                    c.render(x, this.props, scopes)
                  : null}
                display={c.display} />
            )}

            <Column
              display="fit-tablet"
              className="actions"
              isAction={true}
              render={this.renderItemActions} />
          </Table>
        </Loading>
        {skylight.auth.hasScopes([ASSIGNMENT.ASSIGN]) && <ListActionButton
          icon={<AssignIcon />}
          tooltip={<FormattedMessage id="ACTION_ASSIGN_WORKFLOW" default="New assignment" />}
          onAction={this.onAssign} />}
      </div>
    )
  }
}

export { AssignmentList }
