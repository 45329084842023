export const PREFIX = 'Activity'

// Removed as a part of - https://upskill.atlassian.net/browse/SKY-654
export const DEFAULT_EVENT_TYPES = [
  // 'assignment-created',
  // 'assignment-deleted',
  'call-attempt',
  'call-completed',
  'call-connected',
  'call-dropped',
  'call-failed',
  'call-timeout',
  'user-login',
  'user-logout'
]

export const DEFAULT_EVENTS_QUERY = {
  sortBy: 'timestamp',
  sortAsc: false,
  filters: ['eventTypes', 'timestamp'],
  filter: {
    eventTypes: DEFAULT_EVENT_TYPES
  },
  skip: 0,
  limit: 50
}

export const DEFAULT_CALL_QUERY = {
  sortBy: 'createdAt',
  sortAsc: false,
  skip: 0,
  limit: 10
}

export const DEFAULT_CALL_TYPES = [
  'attempt',
  'completed',
  'connected',
  'dropped',
  'failed'
]

export const DEFAULT_CALLS_QUERY = {
  sortBy: 'createdAt',
  sortAsc: false,
  filters: [],
  filter: {},
  skip: 0,
  limit: 50
}

export const CALL_DEVICES = [
  'mobile',
  'wearable',
  'web'
]

export const CALL_SOURCES = [
  'caller',
  'receiver'
]

export const DEFAULT_CALL_EVENT_QUERY = {
  sortBy: 'timestamp',
  sortAsc: false,
  filters: [],
  filter: {},
  skip: 0,
  limit: 50
}

export const CALL_EVENT_TYPES = [
  'CALL_SETUP',
  'CALL_SETUP_ACK',
  'OFFER',
  'ANSWER',
  'ICE',
  'CALL_STATS'
]

export const DEFAULT_SSRC_VIDEO_SEND_QUERY = {
  sortBy: 'timestamp',
  sortAsc: false,
  filters: [],
  filter: {},
  skip: 0,
  limit: 50
}

export const DEFAULT_BWE_FOR_VIDEO_QUERY = {
  sortBy: 'timestamp',
  sortAsc: false,
  filters: [],
  filter: {},
  skip: 0,
  limit: 50
}
