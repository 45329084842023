import React from 'react'
import { FilterToolbar, ByUser, CheckboxFilter } from 'skylight-common'

import './CallUserFilter.css'

const getFilters = (filterProps = {}) => [
  { text: 'USERNAME', value: 'userIds', props: { title: 'SELECT_USERS', filterProps }, comp: ByUser },
  { value: 'showUnavailable', isAdvanced: true, props: { title: 'SHOW_UNAVAILABLE', defaultTitle: 'Show unavailable' }, comp: CheckboxFilter },
  { value: 'showAll', isAdvanced: true, props: { title: 'SHOW_ALL', defaultTitle: 'Include users from other groups' }, comp: CheckboxFilter }
]

const CallUserFilter = props => {
  const filterProps = {
    urlPath: '/team/users',
    onSelectItems: props.onUserSelectItems,
    role: props.authUser.role
  }

  const filters = getFilters(filterProps)

  return (
    <FilterToolbar
      className="call-user-filter"
      filters={filters}
      query={props.query}
      layout={props.layout}
      onFilterUpdated={props.onCallUserFilterUpdated}
      onFiltersUpdated={props.onCallUserFiltersUpdated}
      onFilterRemoved={props.onCallUserFilterRemoved}/>
  )
}

export { CallUserFilter }
