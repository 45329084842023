import React from 'react'
import {
  ByUser,
  ByDateRange
} from 'skylight-common'
import { FilterToolbarNextGen } from '../../../../../../Common/NextGen/FilterToolbarNextGen'
import { ByEventType } from './ByEventType'
import { ByDeviceType } from './ByDeviceType'

import './EventFilter.css'

const getFilters = (props, filterProps = {}) => [
  { text: 'EVENT_TYPE', value: 'eventTypes', props: { title: 'EVENT_TYPE' }, comp: componentProps => <ByEventType {...props} {...componentProps} /> },
  { text: 'DATE_CREATED', value: 'createdAt', props: { title: 'DATE_CREATED' }, comp: ByDateRange },
  { text: 'CREATED_BY', value: 'createdBy', props: { title: 'CREATED_BY', filterProps }, comp: ByUser },
  { text: 'DEVICE', value: 'devices', props: { title: 'DEVICE' }, comp: componentProps => <ByDeviceType {...props} {...componentProps} /> }
]

const EventFilter = props => {
  const filterProps = {
    urlPath: '/team/users',
    onSelectItems: props.onUserSelectItems,
    role: props.authUser.role
  }

  const filters = getFilters(props, filterProps)

  return <FilterToolbarNextGen
    className="session-filter"
    filters={filters}
    query={props.query}
    layout={props.layout}
    onFilterUpdated={props.onSessionEventFilterUpdated}
    onFiltersUpdated={props.onSessionEventFiltersUpdated}
    onFilterRemoved={props.onSessionEventFilterRemoved} />
}

export { EventFilter }
