import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import NewUserIcon from 'material-ui/svg-icons/social/person-add'
import PersonIcon from 'material-ui/svg-icons/social/person'
import GroupIcon from 'material-ui/svg-icons/social/group'
import { GridHeader, Column, Loading, ListFooterStats, ListActionButton, CursorTooltip } from 'skylight-common'
import DeleteIcon from '../../../../../../Common/Icons/removeTrash'
import { EmptyListView } from './EmptyListView'
import { COLUMNS } from './columns'
import IconButton from 'material-ui/IconButton'
import { DashboardActionButton } from '../../../Common/DashboardActionButton'
import { TableNextGen } from '../../../../../../Common/NextGen/TableNextGen'
import applicationParticipantActions from '../../../../../../../actions/application/participants'
import contactActions from '../../../../../../../actions/contact'
import dialogActions from '../../../../../../../actions/dialog'
import { PERMISSIONS, hasPermission } from '../../../../../../../lib/application'
import { isApplicationAdmin } from '../../../../../../../svc/scopes'

import './ParticipantList.css'

const removeConfirmationDialogTitle = (
  <span className="remove-application-participants-modal-title">
    <DeleteIcon />
    <FormattedMessage
      id="APPLICATION_PARTICIPANTS_REMOVE_DIALOG_TITLE"
      defaultMessage="Are you sure you want to remove these user(s) or group(s)?" />
  </span>
)

const ParticipantList = (props) => {
  const { list, application, permissions } = props
  const { applicationId } = application
  const applicationPermissions = permissions[applicationId] || {}

  const hasRolePermission = (rolePermission) => {
    return isApplicationAdmin() || hasPermission(applicationPermissions, rolePermission)
  }
  const canUpdateRole = hasRolePermission(PERMISSIONS.ROLE.UPDATE)

  const onDeleteItems = () => {
    props.onConfirm(
      () => {
        props.onApplicationParticipantsRemoveSelected()
      },
      {
        customTitle: removeConfirmationDialogTitle,
        message: 'APPLICATION_PARTICIPANTS_REMOVE_DIALOG_DESCRIPTION',
        action: 'ACTION_REMOVE_APPLICATION_PARTICIPANTS',
        className: 'remove-application-participants-modal'
      }
    )
  }

  const renderFooterActions = item => {
    if (!canUpdateRole) {
      return null
    }

    return <div className="footer-actions">
      <CursorTooltip tooltip={<FormattedMessage id="ACTION_REMOVE" defaultMessage="Remove" />} >
        <IconButton
          className="item-action-button"
          key="delete"
          onTouchTap={onDeleteItems}>
          <DeleteIcon className="delete-icon" />
        </IconButton>
      </CursorTooltip>
    </div>
  }

  const renderTableFooter = () => {
    return list.selectedIds.length > 0
      ? <ListFooterStats list={list} onUnselect={props.onApplicationParticipantSelectItems} actions={renderFooterActions()} />
      : null
  }

  const renderParticipantsTable = () => {
    if (!applicationId) {
      return null
    }

    return (
      <div className="participant-table">
        <TableNextGen
          tableHeading={props.heading}
          showCheckboxes
          tableHeaderActions={props.tableHeaderActions}
          checkboxIcon={item => item.type === 'group' ? <GroupIcon /> : <PersonIcon />}
          showHover
          isLoading={list.isLoading}
          query={list.query}
          items={list.items}
          total={list.total}
          done={list.done}
          selectedRows={list.selectedIds}
          name="appParticipantList"
          tableFooter={renderTableFooter()}
          emptyLabel={<EmptyListView />}
          onQuery={props.onApplicationParticipantQuery}
          onRowsSelected={props.onApplicationParticipantSelectItems}>
          {COLUMNS.map(x => <Column
            key={x.name}
            name={x.name}
            sortable={x.sortable !== false}
            title={<FormattedMessage id={x.title} defaultMessage={x.title} />}
            render={x.render ? item => x.render(item, props) : null} />)}
        </TableNextGen>
      </div>
    )
  }

  const renderListActionButton = () => {
    if (!canUpdateRole) {
      return null
    }

    const buttonClassName = list.selectedIds.length ? 'item-selected' : ''
    return <ListActionButton
      className={buttonClassName}
      icon={<NewUserIcon />}
      tooltip={<FormattedMessage id="ACTION_ADD_APPLICATION_PARTICIPANT" default="Add users" />}
      onAction={props.onShowAddApplicationParticipantDialog} />
  }

  const handleManageRolesButtonClick = () => {
    const { applicationId } = props.application
    props.onManageRolesOpen(applicationId)
  }

  const getTitle = () => {
    return <div className="participant-list-title">{application.name}</div>
  }

  const getHeaderActions = () => [<DashboardActionButton
    key="manage-roles-button"
    className="manage-roles-button"
    onClick={handleManageRolesButtonClick}
    text={<FormattedMessage id="MANAGE_ROLES" defaultMessage="Manage roles" />} />]

  const isLoading = applicationId && list.isLoading && list.items.length === 0
  return (
    <div className="participant-list">
      <GridHeader title={getTitle()} actions={getHeaderActions()} />
      <Loading
        isLoading={isLoading}
        message="LOADING_APPLICATION_PARTICIPANT_LIST"
        error={list.error
          ? <FormattedMessage
          id="EMPTY_APPLICATION_PARTICIPANT_LIST"
          defaultMessage="No participants are added yet" />
          : null}>
        {renderParticipantsTable()}
      </Loading>
      {renderListActionButton()}
    </div>
  )
}

export { ParticipantList }

const mapStateToProps = (state) => ({
  list: state.application.participant.list,
  user: state.auth.user,
  application: state.application.selectedApplication,
  role: state.application.role,
  permissions: state.application.permissions
})

const participantsListActions = {
  ...applicationParticipantActions,
  onUserSelectItems: contactActions.onUserSelectItems,
  onShowAddApplicationParticipantDialog: dialogActions.showAddApplicationParticipantDialog,
  onConfirm: dialogActions.onConfirm
}

const ParticipantListContainer = connect(mapStateToProps, participantsListActions)(ParticipantList)

export default ParticipantListContainer
