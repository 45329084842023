const IS_IP = {
  validate: v => /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}$/.test(v),
  error: 'FORM_INVALID_IP_ERROR'
}

// See for details:
// WEP, WPA/WPA2 - https://askubuntu.com/a/304482/648162
// 802.1x - https://www.cisco.com/assets/sol/sb/AP541N_GUI/AP541N_1_9_2/help_802_1X_Authentication.htm
const PWD_MIN_LENGTH = {
  WEP: 5,
  'WPA/WPA2': 8,
  '802.1x': 1
}

const isValidPwd = x => {
  const min = PWD_MIN_LENGTH[x.security]
  if (!min) {
    return ''
  }

  return x.secret ? (x.secret.length < min ? { name: 'INVALID_PWD_MIN', value: min } : '') : 'REQUIRED'
}

export const FIELDS = [{
  type: 'text',
  label: { id: 'NETWORK_NAME', default: 'Network Name (SSID)' },
  name: 'ssid',
  validate: x => x.ssid ? '' : 'REQUIRED',
  autoFocus: true
},
{
  type: 'menu',
  label: { id: 'ENCRYPTION_TYPE', default: 'Encryption Type' },
  name: 'security',
  validate: x => x.security ? '' : 'REQUIRED',
  values: [{ v: 'NONE', l: { id: 'NONE', def: 'None' } }, { v: 'WEP' }, { v: 'WPA/WPA2' }, { v: '802.1x' }]
},
// Does not seem to present in pristine
// {
//   type: 'text',
//   label: { id: 'IDENTITY', default: 'Identity' },
//   name: 'identity',
//   allowDisplay: s => s.item.security === '802.1x'
// },
{
  type: 'text',
  label: { id: 'NETWORK_USERNAME', default: 'Network Username' },
  validate: x => x.security === '802.1x' ? (x.username ? '' : 'REQUIRED') : '',
  name: 'username',
  allowDisplay: s => s.item.security === '802.1x'
},
{
  type: 'password',
  label: { id: 'PASSWORD', default: 'Password' },
  validate: isValidPwd,
  allowDisplay: s => s.item.security !== 'NONE',
  name: 'secret'
},
{
  type: 'menu',
  label: { id: 'EAP_METHOD', default: 'EAP Method' },
  validate: x => x.security === '802.1x' ? (x.eap ? '' : 'REQUIRED') : '',
  name: 'eap',
  // 'SIM', 'AKA', 'FAST' does not seem to present in pristine
  // , {v: 'SIM'}, {v: 'AKA'}, {v: 'FAST'}
  values: [{ v: 'NONE', l: { id: 'NONE', def: 'None' } }, { v: 'PEAP' }, { v: 'TLS' }, { v: 'TTLS' }, { v: 'PWD' }],
  allowDisplay: s => s.item.security === '802.1x'
},
{
  type: 'menu',
  label: { id: 'PHASE_2_AUTH', default: 'Phase 2 Authentication' },
  validate: x => x.security === '802.1x' ? (x.phase2 ? '' : 'REQUIRED') : '',
  name: 'phase2',
  values: [{ v: 'NONE', l: { id: 'NONE', def: 'None' } }, { v: 'MSCHAPV2' }, { v: 'GTC' }],
  allowDisplay: s => s.item.security === '802.1x'
},
// Does not seem to present in pristine
// {
//   type: 'menu',
//   label: { id: 'CA_CERT', default: 'CA certificate' },
//   name: 'caCert',
//   values: [{v: null, l: {id: 'NONE', def: 'None'}}, {v: 'Don\'t validate'}, {v: 'Validate'}],
//   allowDisplay: s => ['PEAP', 'TLS', 'TTLS', 'FAST'].includes(s.item.phase2)
// },
{
  type: 'text',
  label: { id: 'ANONYMOUS_IDENTITY_OPT', default: 'Anonymous Identity (optional)' },
  name: 'anonymous',
  allowDisplay: s => s.item.security === '802.1x'
}]

export const ADVANCED_FIELDS = [
  {
    type: 'checkbox',
    label: { id: 'HIDDEN_SSID', default: 'Hidden SSID' },
    name: 'hidden'
  },
  {
    type: 'checkbox',
    label: { id: 'STATIC_IP', default: 'Static IP' },
    name: 'staticIp'
  },
  {
    type: 'text',
    label: { id: 'IP_ADDRESS', default: 'IP Address' },
    name: 'address',
    validate: x => x.staticIp ? (IS_IP.validate(x.address) ? '' : IS_IP.error) : '',
    rules: IS_IP,
    allowDisplay: s => s.item.staticIp
  },
  {
    type: 'text',
    label: { id: 'SUBNET_MASK', default: 'Subnet Mask' },
    name: 'subnetMask',
    validate: x => x.staticIp ? (IS_IP.validate(x.subnetMask) ? '' : IS_IP.error) : '',
    allowDisplay: s => s.item.staticIp
  },
  {
    type: 'text',
    label: { id: 'DEFAULT_GATEWAY', default: 'Default Gateway' },
    name: 'defaultGateway',
    validate: x => x.staticIp ? (IS_IP.validate(x.defaultGateway) ? '' : IS_IP.error) : '',
    allowDisplay: s => s.item.staticIp
  },
  {
    type: 'text',
    label: { id: 'PRIMARY_DNS_OPT', default: 'Primary DNS (optional)' },
    name: 'primaryDNS',
    validate: x => (x.staticIp && x.primaryDNS) ? (IS_IP.validate(x.primaryDNS) ? '' : IS_IP.error) : '',
    allowDisplay: s => s.item.staticIp
  },
  {
    type: 'text',
    label: { id: 'SECONDARY_DNS_OPT', default: 'Secondary DNS (optional)' },
    name: 'secondaryDNS',
    validate: x => (x.staticIp && x.secondaryDNS) ? (IS_IP.validate(x.secondaryDNS) ? '' : IS_IP.error) : '',
    allowDisplay: s => s.item.staticIp
  }]

const FIELDS_TO_VALIDATE = FIELDS.concat(ADVANCED_FIELDS).filter(x => x.validate)
export const validateFields = item => {
  const errors = {}
  FIELDS_TO_VALIDATE.forEach(f => {
    const error = f.validate(item)
    if (error) {
      errors[f.name] = error
    }
  })

  return errors
}
