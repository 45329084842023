import React from 'react'
import { MediaElement, getMediaItemUrl } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { UploadUserImage } from './UploadUserImage'
import './UserImage.css'

const DEFAULT_PROFILE = '/assets/img/profile-blue.png'

class UserImage extends React.Component {
  state = { open: false }

  onToggleDialog = () => {
    this.setState(state => ({ open: !state.open }))
  }

  getImageSrc = () => {
    return this.props.src ? getMediaItemUrl(this.props.src) : DEFAULT_PROFILE
  }

  setHandler = () => {
    return this.props.isEditable ? { onTouchTap: this.onToggleDialog } : {}
  }

  renderFooter = () => {
    const { isEditable, disableFooter } = this.props
    if (!isEditable || disableFooter) {
      return null
    }

    return <div className="user-image-footer">
      <FormattedMessage id="ADD_PHOTO" defaultMessage="Add photo" />
    </div>
  }

  render() {
    const { isEditable } = this.props
    const className = classNames('user-image', this.props.className, { editable: isEditable })

    return (
      <div className={className} {...this.setHandler()}>
        <MediaElement src={this.getImageSrc()} fileType="image" alt="avatar" />
        {this.renderFooter()}
        {this.state.open && <UploadUserImage
          onHideDialog={this.onToggleDialog}
          onUpload={this.props.onUploadImage} />}
      </div>
    )
  }
}

UserImage.defaultProps = {
  isEditable: true
}

export { UserImage }
