import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const arrowExpandDown = (props) => {
  return (
    <SvgIcon {...props}>
      <polygon points="16.59 8.59 12 13.17 7.41 8.59 6 10 12 16 18 10" />
    </SvgIcon>
  )
}

export default arrowExpandDown
