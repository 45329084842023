import React from 'react'
import PropTypes from 'prop-types'
import Button from 'material-ui/FlatButton'

import { FormattedMessage } from 'react-intl'

import { PeoplePicker } from 'skylight-common'
import { DialogFooter, CircleIcon } from './dialogParts'

const UserSelect = props => {
  return (
    <div className="pick-users-wrapper">
      <div className="selected-users-wrapper">
        <CircleIcon element={1} text="Selected user(s):" active />
      </div>
      <div className="user-select-line" />
      <div className="picker-wrapper">
        <form id="user-select-form" onSubmit={props.onContinue}>
          <PeoplePicker
            autoFocus
            showIcons
            selected={props.selectedUsers}
            includeUsers={props.isUserPick}
            includeGroups={!props.isUserPick}
            includeCurrentUser
            onSelect={props.onChipSelect}/>
          <div className="dialog-users-action-wrapper">
            <Button className="cancel-button" onClick={props.onCancel}>
              <FormattedMessage id="CANCEL" defaultMessage="Cancel" />
            </Button>
            <Button
              className={`continue-button ${props.selectedUsers.length < 1 ? 'disabled' : ''}`}
              onClick={props.onContinue}>
              <FormattedMessage id="CONTINUE" defaultMessage="Continue" />
            </Button>
          </div>
        </form>
      </div>
      <DialogFooter isUserPick={props.isUserPick} />
    </div>
  )
}

UserSelect.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isUserPick: PropTypes.bool.isRequired,
  selectedUsers: PropTypes.array.isRequired,
  onChipSelect: PropTypes.func.isRequired
}

export { UserSelect }
