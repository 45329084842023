import React from 'react'
import { formatDate } from '../../../../lib/date'
import { UserTitle } from 'skylight-common'

export const allColumns = [
  {
    name: 'name',
    title: { id: 'APPLICATION_NAME', default: 'Workflow name' },
    render: item => <span data-tooltip={item.name}>{item.name}</span>
  },
  {
    name: 'createdBy',
    title: { id: 'OWNER', default: 'Owner' },
    sortable: false,
    render: x => <UserTitle user={x.createdBy} />,
    groupHeader: x => <UserTitle user={x} />
  },
  {
    name: 'modifiedAt',
    title: { id: 'UPDATED_TIME', default: 'Last Modified' },
    render: x => formatDate(new Date(x.modifiedAt)),
    groupBy: x => formatDate(new Date(x.modifiedAt))
  }
]
