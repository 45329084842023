import React from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { GENERIC_PASSWORD_REQUIREMENTS } from '../../../svc/login'

import './GenericPasswordRequirements.css'

class GenericPasswordRequirements extends React.Component {
  render() {
    const { errors = [] } = this.props

    return <div className="password-requirements">
      {GENERIC_PASSWORD_REQUIREMENTS.map(x => (
        <FormattedMessage key={x.name} {...x.intl}>
          {txt => <span className={classNames('password-requirement', { error: errors.includes(x.name) })}>{txt}</span>}
        </FormattedMessage>
      ))}
    </div>
  }
}

export default GenericPasswordRequirements
