import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'
import { PeoplePicker, DialogTitle } from 'skylight-common'
import './AssignApplication.css'

const KEYS = {
  ENTER: 13
}

class AssignApplication extends Component {
  state = { users: [] }

  getDialogActions = () => {
    return [
      <FlatButton
        key="cancel-action"
        className="cancel-action"
        label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
        primary={true}
        onTouchTap={this.onHide} />,
      <RaisedButton
        key="assign-action"
        label={<FormattedMessage id="ASSIGN" defaultMessage="Assign" />}
        primary={true}
        keyboardFocused={true}
        disabled={this.state.users.length === 0}
        onTouchTap={this.onAssign} />
    ]
  }

  getDialogData = () => {
    const { dialog = {}, dialogType } = this.props

    return dialog[dialogType] || {}
  }

  isDialogOpened = () => {
    const { open } = this.getDialogData()

    return !!open
  }

  onHide = () => {
    this.props.onHideDialog(this.props.dialogType)
  }

  onAssign = () => {
    const users = PeoplePicker.getSelectedUsers(this.state.users)

    if (users.length < 1) {
      return
    }

    const { params: apps } = this.getDialogData()
    this.props.onApplicationsAssigned(apps, users)
    this.onHide()
  }

  selectUsers = (users) => {
    this.setState({ users })
  }

  handleKeyDown = e => {
    if (e.keyCode === KEYS.ENTER) {
      this.handleEnterDown()
    }
  }

  handleEnterDown = () => {
    this.onAssign()
  }

  render() {
    return (
      <Dialog
        className="dialog-modal assign-application"
        title={<DialogTitle id="ASSIGN_APPLICATION" defaultMessage="Assign an application"/>}
        actions={this.getDialogActions()}
        open={this.isDialogOpened()}
        bodyClassName="assign-application-body"
        autoScrollBodyContent={true}
        onRequestClose={this.onHide}>
        <div
          className="assign-application-container"
          tabIndex="0"
          onKeyDown={this.handleKeyDown}>
          <div className="label">
            <FormattedMessage
              id="ASSIGN_WORKFLOW_STEP_2"
              defaultMessage="Enter the user or group name to assign" />
          </div>
          <PeoplePicker
            includeCurrentUser={true}
            includeGroups={true}
            floatingLabelText={<FormattedMessage
              id="ASSIGN_TIP"
              defaultMessage="Enter the user or group name to assign" />}
            autoFocus={true}
            selected={this.state.users}
            onSelect={this.selectUsers} />
        </div>
      </Dialog>
    )
  }
}

export { AssignApplication }
