import React, { PureComponent } from 'react'
import Dialog from 'material-ui/Dialog'
import CircularProgress from 'material-ui/CircularProgress'
import RaisedButton from 'material-ui/RaisedButton'
import TextField from 'material-ui/TextField'
import CloseIcon from 'material-ui/svg-icons/navigation/close'

import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { DialogTitle, Form } from 'skylight-common'

import { HiddenTextField } from '../../../../Common/HiddenTextField'

import './VerifyCredentialsDialog.css'

class VerifyCredentialsDialog extends PureComponent {
  state = { password: '' }

  onClose = () => {
    this.clearErrors()
    this.props.onHideDialog(this.props.dialogType)
  }

  clearErrors = () => {
    const { errors, onVerifyCredentialsClearErrors } = this.props
    errors.password && onVerifyCredentialsClearErrors()
  }

  onSubmit = () => {
    const authData = {
      username: this.props.auth.user.username,
      password: this.state.password,
      realm: this.props.auth.user.realm
    }

    this.props.onVerifyCredentials(authData)
    this.setState({ password: '' })
  }

  onChange = e => {
    this.clearErrors()
    this.setState({ password: e.target.value })
  }

  renderPasswordError = () => {
    const { errors } = this.props
    return (
      <FormattedMessage
        id={errors.password}
        defaultMessage="Invalid account password. Try again.">
        {text => <span className="verify-dialog error-message"> {text} </span>}
      </FormattedMessage>
    )
  }

  renderLoader = () => {
    return (
      <div className="loader-wrapper">
        <CircularProgress />
      </div>
    )
  }

  render() {
    const { dialog, dialogType, auth, errors, isLoading } = this.props
    const dialogProps = dialog[dialogType] || {}

    const signInLink = (
      <Link
        to={`/login/${auth.user.realm}`}
        className="nav-link"
        onClick={this.onClose}>
        <FormattedMessage id="SIGN_IN" defaultMessage="Sign in" />
      </Link>
    )

    return (
      <Dialog
        title={
          <DialogTitle
            id="VERIFY_CREDENTIALS_TITLE"
            defaultMessage="Verify your credentials to proceed">
            <div className="close-icon-wrapper">
              <CloseIcon className="close-icon" onTouchTap={this.onClose} />
            </div>
          </DialogTitle>
        }
        modal={false}
        open={!!dialogProps.open}
        className={`dialog-modal verify-credentials-dialog ${isLoading ? 'dialog-dimmed' : ''}`}
        contentClassName="dialog-content-wrapper"
        bodyClassName="dialog-body-wrapper"
        onRequestClose={this.onClose}>
        {isLoading && this.renderLoader()}
        <div className="content">
          <Form onSubmit={this.onSubmit} errors={errors}>
            <div className="dialog-form-fields">
              <TextField
                name="username"
                floatingLabelText={
                  <FormattedMessage id="USERNAME" defaultMessage="Username" />
                }
                className="verify-dialog input username disabled"
                value={auth.user.username}/>

              <HiddenTextField
                name="password"
                className="verify-dialog input password"
                type="password"
                autoComplete="off"
                autoFocus={true}
                floatingLabelText={
                  <FormattedMessage id="PASSWORD" defaultMessage="Password" />
                }
                errorText={errors.password ? this.renderPasswordError() : null}
                value={this.state.password}
                onChange={this.onChange}/>
            </div>
            <div className="dialog-actions-wrapper">
              <RaisedButton
                key="verify"
                primary
                className="verify-button"
                label={<FormattedMessage id="VERIFY" defaultMessage="Verify" />}
                disabled={!this.state.password}
                type="submit"/>
            </div>
          </Form>

          <div className="dialog-footer-wrapper">
            <FormattedMessage
              id="SIGN_IN_WITH_A_DIFFERENT_ACCOUNT"
              defaultMessage={`Not you? ${signInLink} with a different account.`}
              values={{ value: signInLink }}>
              {(text, element, description) => (
                <span className="footer-text">
                  {text}
                  {element}
                  {description}
                </span>
              )}
            </FormattedMessage>
          </div>
        </div>
      </Dialog>
    )
  }
}

export { VerifyCredentialsDialog }
