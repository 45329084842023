import React from 'react'
import Dialog from 'material-ui/Dialog'
import { injectIntl } from 'react-intl'
import AcceptIcon from 'material-ui/svg-icons/communication/call'
import RejectIcon from 'material-ui/svg-icons/communication/call-end'
import callSdk from 'skylight-call-sdk'
import { CallButton, CallStatus, UserAvatar, UserTitle } from '../Common'
import { presentNotification } from '../../../lib/notification'
import { Sound } from '../../Common/Sound'
import './CallNotification.css'

const OPEN_STATES = [
  callSdk.constants.CALL_STATES.INCOMING,
  callSdk.constants.CALL_STATES.CONTACTING,
  callSdk.constants.CALL_STATES.CALLING
]

const getNotificationTitle = user => {
  if (!user) {
    return ''
  }

  return `${user.firstName} ${user.lastName}`.trim() || user.username
}

class CallNotificationComponent extends React.Component {
  notification = null

  isIncoming = prevProps => {
    const { state } = (prevProps || this.props).call.connection

    return state === callSdk.constants.CALL_STATES.INCOMING
  }

  showNotification = async() => {
    const title = getNotificationTitle(this.props.call.connection.partner)
    const { messages } = this.props.intl

    const options = {
      body: messages.CALL_NOTIFICATION_BODY,
      icon: '/android-chrome-256x256.png'
      // sound: '/assets/sound/sound_calling_01.mp3'
    }

    const onClick = (e, n) => {
      window.focus()
      n.close()
    }

    this.notification = await presentNotification(title, options, onClick)
  }

  componentDidUpdate(prevProps) {
    const wasIncoming = this.isIncoming(prevProps)

    if (!wasIncoming && this.isIncoming()) {
      this.showNotification()
    } else if (wasIncoming && !this.isIncoming()) {
      // Close notification on accept/reject, hangup from caller side.
      this.closeNotification()
    }
  }

  closeNotification = () => {
    if (this.notification) {
      this.notification.close()
    }
  }

  onAccept = () => {
    this.props.incomingCall(true)
  }

  onHangup = () => {
    this.props.hangup()
  }

  render() {
    const { connection } = this.props.call

    const isIncoming = this.isIncoming()
    const isOpen = OPEN_STATES.includes(connection.state)

    return (
      <Dialog
        className="call-notification"
        contentClassName="call-notification-content"
        bodyClassName="call-notification-body"
        open={isOpen}
        modal>
        <Sound isPrevented={!isIncoming} source="/assets/sound/UI_skylight_call_initiated_01.wav" />
        <div>
          <div className="call-notification-title">
            <UserAvatar user={connection.partner} />
            <div className="user-call-title">
              <CallStatus connection={connection} />
              <UserTitle user={connection.partner} />
            </div>
          </div>
          <div className="call-toolbar">
            <CallButton
              className="reject"
              icon={<RejectIcon />}
              isFab
              title={isIncoming ? 'DISMISS' : 'CALL_END'}
              onTouchTap={this.onHangup} />
            {isIncoming && <CallButton
              className="accept"
              icon={<AcceptIcon />}
              isFab
              title="CALL_ACCEPT"
              onTouchTap={this.onAccept} />}
          </div>
        </div>
      </Dialog>
    )
  }
}

const CallNotification = injectIntl(CallNotificationComponent)

export { CallNotification }
