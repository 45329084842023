import React from 'react'
import skylight from 'skylight-js-sdk'
import './UserAvatar.css'

const getInitial = (s) => {
  if (!s) return ''
  s = s.trim()
  if (!s) return ''
  return s.substring(0, 1).toUpperCase()
}

const getInitials = (user) => {
  return (getInitial(user.firstName) + getInitial(user.lastName)) || getInitial(user.username)
}

class UserAvatar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      avatarUrl: null
    }
  }

  async showUser(nextProps) {
    const current = this.state.user || {}
    const user = nextProps.user || {}
    if (user.id === current.id) return
    this.setState({ user })
    if (user.avatar) {
      try {
        // TODO: move to sdk.
        const file = await skylight.media.downloadFileById(`${user.avatar}`)
        const blob = await file.blob()
        const avatarUrl = window.URL.createObjectURL(blob)
        this.setState({ avatarUrl })
      } catch (error) {
        skylight.logger.error('UserAvatar', error)
      }
    }
  }

  async componentDidMount() {
    this.showUser(this.props)
  }

  // eslint-disable-next-line
  async componentWillReceiveProps (nextProps) {
    this.showUser(nextProps)
  }

  render() {
    const { user, avatarUrl } = this.state
    if (!user) return null
    return (
      <div className={`user-avatar ${this.props.className || ''} ${avatarUrl ? '' : 'initials'}`}>
        {!avatarUrl && <div className="initials">{getInitials(user)}</div>}
        {avatarUrl && <img src={avatarUrl} alt={user.username} />}
      </div>
    )
  }
}

export { UserAvatar }
