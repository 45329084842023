import React from 'react'
import { PARTICIPANT_TYPES } from '../../../../../../../lib/application/participant'
import UserDetailsLink from '../../../../../Contacts/UserDetails/UserDetailsLink'
import GroupDetailsLink from '../../../../../Contacts/GroupDetails/GroupDetailsLink'
import { ParticipantRolesPicker } from './ParticipantRolesPicker/ParticipantRolesPicker'

const COLUMNS = [
  {
    name: 'nameSortingValue',
    title: 'NAME',
    render: item => {
      switch (item.type) {
        case PARTICIPANT_TYPES.USER:
          return <UserDetailsLink nextGen userId={item.id} />
        case PARTICIPANT_TYPES.GROUP:
          return <GroupDetailsLink nextGen group={item} />
        default:
          return item.name
      }
    }
  },
  {
    name: 'email',
    title: 'EMAIL',
    render: x => x.email,
    groupBy: x => x.email
  },
  {
    name: 'role',
    title: 'ROLE',
    sortable: false,
    render: (item, props) => <ParticipantRolesPicker {...props} participant={item} />,
    groupBy: x => x.role
  }
]

export { COLUMNS }
