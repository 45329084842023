import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Table, Column, Loading } from 'skylight-common'
import { CallGroupDetailsHeading } from '../CallGroupDetailsHeading/CallGroupDetailsHeading'
import { OnlinePersonIcon, OnlinePhoneIcon } from '../PresenceIcons/PresenceIcons'
import { MembersFilter } from '../..'
import './CallGroupDetails.css'

class CallGroupDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFiltersMenuOpen: false,
      filters: {
        showUnavailable: true
      }
    }
  }

  showUnavailable = users => {
    const usersOnlineIds = this.props.presence.users.map(user => user.userId)
    const onlyPresence = []

    users.forEach(user => {
      if (usersOnlineIds.includes(user.id)) {
        onlyPresence.push(user)
      }
    })

    return this.state.filters.showUnavailable ? users : onlyPresence
  }

  composeFilters = (...filters) => data =>
    filters.reduceRight((value, func) => func(value), data)

  withFilters = users => {
    return this.composeFilters(
      this.showUnavailable
    )(users)
  }

  setFilter = filter => {
    this.setState({
      ...this.state,
      filters: {
        ...this.state.filters,
        [filter]: !this.state.filters[filter]
      }
    })
  }

  resetFilters = () => {
    this.setState({
      ...this.state,
      filters: {
        showUnavailable: true
      }
    })
  }

  onOpenFilterMenu = e => {
    e.preventDefault()

    this.setState({
      ...this.state,
      isFiltersMenuOpen: true,
      anchorEl: e.currentTarget
    })
  }

  onCloseFilterMenu = () => {
    this.setState({
      ...this.state,
      isFiltersMenuOpen: false
    })
  }

  onUserCall = (e, item) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.onCall(item, this.props.auth.user)
  }

  // eslint-disable-next-line
  componentWillReceiveProps() {
    this.resetFilters()
  }

  render() {
    const { groupMembers, groups, group } = this.props.contact
    const { users } = this.props.presence

    return (
      <div>
        <Loading
          isLoading={groupMembers.isLoading && groupMembers.items.length === 0}
          message="LOADING_CALL_GROUP_DETAILS"
          error={groupMembers.error}>
          <Table
            tableHeading={() => {
              return (
                <CallGroupDetailsHeading
                  groups={groups}
                  group={group.item}
                  {...this.props}/>
              )
            }}
            disableRowSelecting
            query={groupMembers.query}
            items={this.withFilters(groupMembers.items)}
            selectedRows={groupMembers.selectedIds}
            onQuery={(query) => this.props.onLoadGroupMembers(query)}>

            <Column
              className="user-icon-column"
              key="userIcon"
              name="userIcon"
              render={(item) => <OnlinePersonIcon user={item} users={users}/>}/>
            <Column
              key="username"
              name="username"
              title={<FormattedMessage id="USERNAME" defaultMessage="Username" />}
              render={(item) => item.username}/>
            <Column
              className="user-available-tol-call-column"
              key="userAvailableToCall"
              name="userAvailableToCall"
              render={(item) => {
                return (
                  <OnlinePhoneIcon
                    user={item}
                    users={users}
                    onClick={(e) => this.onUserCall(e, item)}/>
                )
              }}
              renderHeader={(c) => {
                return (
                  <MembersFilter
                    anchorEl={this.state.anchorEl}
                    onOpenFilterMenu={this.onOpenFilterMenu}
                    onCloseFilterMenu={this.onCloseFilterMenu}
                    isFiltersMenuOpen={this.state.isFiltersMenuOpen}
                    filters={this.state.filters}
                    onSetFilter={(filter) => this.setFilter(filter)}/>
                )
              }}/>

          </Table>
        </Loading>
      </div>
    )
  }
}

export { CallGroupDetails }
