import { connect } from 'react-redux'

import dialogActions from '../actions/dialog'
import appActions from '../actions/app'
import authActions from '../actions/auth'
import errorActions from '../actions/error'

import { HomePage as LegacyHomePageComp } from '../App/Home/HomeLegacy/HomePage/HomePage'
import { HomePage as NextGenHomePageComp } from '../App/Home/HomeNextGen/HomePage/HomePage'

const homePage = (state, props) => ({
  auth: state.auth
})

const homePageActions = {
  ...dialogActions,
  ...appActions,
  ...authActions,
  ...errorActions
}

const LegacyHomePage = connect(homePage, homePageActions)(LegacyHomePageComp)
const NextGenHomePage = connect(homePage, homePageActions)(NextGenHomePageComp)

export { LegacyHomePage, NextGenHomePage }
