import React, { Component } from 'react'
import { Dialog, FlatButton } from 'material-ui'
import { connect } from 'react-redux'
import ArchiveIcon from 'material-ui/svg-icons/content/archive'
import { FormattedMessage } from 'react-intl'
import { archiveApplication } from '../../../../../actions/nextGenApplication'
import { hideArchiveAppConfirmation } from '../../../../../actions/dialog'
import './ArchiveAppConfirmationDialog.css'
import { get } from 'lodash'
import { ARCHIVE_APP_CONFIRMATION } from '../dialogTypes'

class ArchiveAppConfirmationDialogComponent extends Component {
  componentWillUnmount() {
    this.onClose()
  }

  onClose = () => {
    this.props.onRequestClose()
  }

  onArchive = () => {
    this.props.onArchive()
    this.props.onRequestClose()
  }

  actions = [
    <FlatButton
      key="cancel"
      className="close-action"
      label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
      onClick={this.onClose}/>,
    <FlatButton
      key="ok"
      label={<FormattedMessage id="ARCHIVE" defaultMessage="Archive" />}
      primary
      style={{ color: '#DD2C00' }}
      onClick={this.onArchive}/>
  ]

  render() {
    const {
      open
    } = this.props
    return (
      <Dialog
        className="archive-app-confirmation-dialog"
        contentStyle={{ maxWidth: '533' }}
        titleStyle={{ fontSize: 20, display: 'flex', alignItems: 'center', padding: '12px 24px' }}
        title={<div className="archive-app-confirmation-dialog__title"><ArchiveIcon color="#DD2C00" /> Archive application?</div>}
        open={open}
        bodyStyle={{ paddingBottom: 0 }}
        actions={this.actions}
        onRequestClose={this.onCancel}>
        <div className="archive-app-confirmation-dialog__text"><FormattedMessage id="ARCHIVE_CONFIRMATION_WARNING" defaultMessage="The application will be archived, but not deleted. Please follow steps in the developer documentation to recover the application data." /></div>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  open: get(state.dialog, [ARCHIVE_APP_CONFIRMATION, 'open'], false)
})

const mapDispatchToProps = {
  onArchive: archiveApplication,
  onRequestClose: hideArchiveAppConfirmation
}

const ArchiveAppConfirmationDialog = connect(mapStateToProps, mapDispatchToProps)(ArchiveAppConfirmationDialogComponent)

export default ArchiveAppConfirmationDialog
