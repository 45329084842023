import React from 'react'
import { AutoSizer, List } from 'react-virtualized'
import { FormattedMessage } from 'react-intl'
import { PdfPage } from '../PdfPage'
import './PdfPageList.css'

export const PdfPageList = props => {
  const { numPages, pdf, scale, isLoading } = props
  if (isLoading) {
    return (
      <div className="pdf-page-list loading">
        <FormattedMessage id="PDF_LOADING" defaultMessage="Loading PDF Document..." />
      </div>
    )
  }

  const noRows = () => (
    <div className="no-pages">
      <FormattedMessage id="PDF_NO_PAGES" defaultMessage="No pages found" />
    </div>
  )

  const onClick = (pageNumber) => {
    props.onChange && props.onChange(pageNumber)
  }

  const rowRenderer = ({ index, isScrolling, key, style }) => {
    const pageNumber = index + 1
    const className = `pdf-page-row ${pageNumber === props.pageNumber ? 'selected' : ''}`
    return <div className={className} key={index} style={style}>
      <div className="page-number">{pageNumber}</div>
      <PdfPage pageNumber={pageNumber} pdf={pdf} scale={scale}
        onClick={() => onClick(pageNumber)} />
    </div>
  }

  return (
    <div className="pdf-page-list">
      <AutoSizer>
        {({ width, height }) => {
          return (
            <List
              className="list"
              height={height}
              overscanRowCount={1}
              noRowsRenderer={noRows}
              rowCount={numPages}
              rowHeight={200}
              rowRenderer={rowRenderer}
              width={width}/>
          )
        }
        }
      </AutoSizer>
    </div>
  )
}
