import { PERMISSIONS } from '../application'

export const DEFAULT_QUERY = {
  limit: 200,
  skip: 0,
  sortBy: '',
  sortAsc: false,
  filter: {},
  filters: []
}

export const DEFAULT_ROLES = {
  OWNER: 'Owner',
  DEVELOPER: 'Developer',
  MANAGER: 'Manager',
  USER: 'User'
}

const ROLES_SORT_PRIORITIES = {
  [DEFAULT_ROLES.OWNER]: 1,
  [DEFAULT_ROLES.DEVELOPER]: 2,
  [DEFAULT_ROLES.MANAGER]: 3,
  [DEFAULT_ROLES.USER]: 4
}

export const sortRoles = roles => {
  const defaultRoles = Object.values(DEFAULT_ROLES)

  return roles.sort((a, b) => {
    if (defaultRoles.includes(a.name) && defaultRoles.includes(b.name)) {
      return ROLES_SORT_PRIORITIES[a.name] - ROLES_SORT_PRIORITIES[b.name]
    }

    if (defaultRoles.includes(a.name)) return -1
    if (defaultRoles.includes(b.name)) return 1

    return a.name.localeCompare(b.name)
  })
}

const PERMISSION_CATEGORIES = {
  APPLICATION: 'Application',
  VERSION: 'Version',
  ROLE: 'Role',
  SESSION: 'Session',
  SESSION_MEDIA: 'Media',
  SESSION_ASSIGNMENT: 'Assignment'
}

const getRolePermission = (id, defaultMessage, permissions, title, subPermission = false) => ({
  description: { id, defaultMessage },
  permissions: Array.isArray(permissions) ? permissions : [permissions],
  subPermission,
  title
})

export const ROLE_PERMISSIONS = {
  [PERMISSION_CATEGORIES.APPLICATION]: [
    getRolePermission(
      'APPLICATION_ARCHIVE_PERMISSION',
      'Archive and unarchive application',
      { permission: PERMISSIONS.APPLICATION.ARCHIVE })
  ],
  [PERMISSION_CATEGORIES.VERSION]: [
    getRolePermission(
      'VERSION_READ_PUBLISHED',
      'Allows installation of published application versions on client devices.',
      { permission: PERMISSIONS.VERSION.READ_PUBLISHED }
    ),
    getRolePermission('VERSION_UPDATE_PERMISSION', 'Access and edit draft versions', { permission: PERMISSIONS.VERSION.UPDATE }),
    getRolePermission('VERSION_PUBLISH_PERMISSION', 'Publish an application', { permission: PERMISSIONS.VERSION.PUBLISH })
  ],
  [PERMISSION_CATEGORIES.ROLE]: [
    getRolePermission('ROLE_READ_ANY_PERMISSION', 'View all application roles', { permission: PERMISSIONS.ROLE.READ_ANY }),
    getRolePermission('ROLE_READ_SELF_PERMISSION', 'View your own role', { permission: PERMISSIONS.ROLE.READ_SELF }),
    getRolePermission('ROLE_CREATE_PERMISSION', 'Create application role and set role permissions', { permission: PERMISSIONS.ROLE.CREATE }),
    getRolePermission('ROLE_UPDATE_PERMISSION', 'Edit application role and assign users and groups', { permission: PERMISSIONS.ROLE.UPDATE }),
    getRolePermission('ROLE_DELETE_PERMISSION', 'Delete application role', { permission: PERMISSIONS.ROLE.DELETE })
  ],
  [PERMISSION_CATEGORIES.SESSION]: [
    getRolePermission('SESSION_CREATE_PERMISSION', 'Create a session', { permission: PERMISSIONS.SESSION.CREATE }),
    getRolePermission(
      'SESSION_VIEW_SESSIONS',
      'View sessions', [
        { permission: PERMISSIONS.SESSION.READ_SELF }, { permission: PERMISSIONS.SESSION.READ_ANY }
      ]),
    getRolePermission(
      'SESSION_EDIT_SESSIONS',
      'Allows updates to session data and custom session events.',
      [{ permission: PERMISSIONS.SESSION.UPDATE_SELF }, { permission: PERMISSIONS.SESSION.UPDATE_ANY }])
  ],
  [PERMISSION_CATEGORIES.SESSION_MEDIA]: [
    getRolePermission(
      'SESSION_UPLOAD_MEDIA',
      'Upload media for session', [
        { permission: PERMISSIONS.SESSION.MEDIA_CREATE_SELF },
        { permission: PERMISSIONS.SESSION.MEDIA_CREATE_ANY }
      ]),
    getRolePermission(
      'UPDATE_SESSION_MEDIA_ANNOTATION',
      'Update session media annotation', [
        { permission: PERMISSIONS.SESSION.MEDIA_ANNOTATIONS_UPDATE_SELF },
        { permission: PERMISSIONS.SESSION.MEDIA_ANNOTATIONS_UPDATE_ANY }
      ], 'Annotation: Update', true),
    getRolePermission(
      'EDIT_SESSION_MEDIA_TITLE',
      'Edit the title of session media', [
        { permission: PERMISSIONS.SESSION.MEDIA_TITLE_UPDATE_SELF },
        { permission: PERMISSIONS.SESSION.MEDIA_TITLE_UPDATE_ANY }
      ], 'Title: Update', true),
    getRolePermission(
      'EDIT_SESSION_MEDIA_LABELS',
      'Edit the labels on session media', [
        { permission: PERMISSIONS.SESSION.MEDIA_LABELS_UPDATE_SELF },
        { permission: PERMISSIONS.SESSION.MEDIA_LABELS_UPDATE_ANY }
      ], 'Labels: Update', true),
    getRolePermission(
      'EDIT_SESSION_MEDIA_PROPERTIES',
      'Edit the session media meta properties', [
        { permission: PERMISSIONS.SESSION.MEDIA_PROPERTIES_UPDATE_SELF },
        { permission: PERMISSIONS.SESSION.MEDIA_PROPERTIES_UPDATE_ANY }
      ], 'Properties: Update', true),
    getRolePermission(
      'SESSION_VIEW_MEDIA',
      'View session media', [
        { permission: PERMISSIONS.SESSION.MEDIA_READ_SELF },
        { permission: PERMISSIONS.SESSION.MEDIA_READ_ANY }
      ]),
    getRolePermission(
      'READ_SESSION_MEDIA_ANNOTATION',
      'Read session media annotation', [
        { permission: PERMISSIONS.SESSION.MEDIA_ANNOTATIONS_READ_SELF },
        { permission: PERMISSIONS.SESSION.MEDIA_ANNOTATIONS_READ_ANY }
      ], 'Annotation: Read', true),
    getRolePermission(
      'VIEW_SESSION_MEDIA_TITLE',
      'View the title of session media', [
        { permission: PERMISSIONS.SESSION.MEDIA_TITLE_READ_SELF },
        { permission: PERMISSIONS.SESSION.MEDIA_TITLE_READ_ANY }
      ], 'Title: Read', true),
    getRolePermission(
      'VIEW_SESSION_MEDIA_LABELS',
      'View the labels on session media', [
        { permission: PERMISSIONS.SESSION.MEDIA_LABELS_READ_SELF },
        { permission: PERMISSIONS.SESSION.MEDIA_LABELS_READ_ANY }
      ], 'Labels: Read', true),
    getRolePermission(
      'VIEW_SESSION_MEDIA_PROPERTIES',
      'View the session media meta properties', [
        { permission: PERMISSIONS.SESSION.MEDIA_PROPERTIES_READ_SELF },
        { permission: PERMISSIONS.SESSION.MEDIA_PROPERTIES_READ_ANY }
      ], 'Properties: Read', true),
    getRolePermission(
      'SESSION_ARCHIVE_MEDIA',
      'Archive and unarchive session media', [
        { permission: PERMISSIONS.SESSION.MEDIA_ARCHIVE_SELF },
        { permission: PERMISSIONS.SESSION.MEDIA_ARCHIVE_ANY }
      ])
  ],
  [PERMISSION_CATEGORIES.SESSION_ASSIGNMENT]: [
    getRolePermission('SESSION_PARTICIPANTS_UPDATE_PERMISSION', 'Modify session participants', { permission: PERMISSIONS.SESSION.PARTICIPANTS_UPDATE })
  ]
}

export const SUPER_PERMISSIONS_MAP = {
  [PERMISSIONS.SESSION.MEDIA_CREATE_SELF]: [
    PERMISSIONS.SESSION.MEDIA_ANNOTATIONS_UPDATE_SELF,
    PERMISSIONS.SESSION.MEDIA_TITLE_UPDATE_SELF,
    PERMISSIONS.SESSION.MEDIA_LABELS_UPDATE_SELF,
    PERMISSIONS.SESSION.MEDIA_PROPERTIES_UPDATE_SELF
  ],
  [PERMISSIONS.SESSION.MEDIA_CREATE_ANY]: [
    PERMISSIONS.SESSION.MEDIA_ANNOTATIONS_UPDATE_ANY,
    PERMISSIONS.SESSION.MEDIA_TITLE_UPDATE_ANY,
    PERMISSIONS.SESSION.MEDIA_LABELS_UPDATE_ANY,
    PERMISSIONS.SESSION.MEDIA_PROPERTIES_UPDATE_ANY
  ],
  [PERMISSIONS.SESSION.MEDIA_READ_SELF]: [
    PERMISSIONS.SESSION.MEDIA_ANNOTATIONS_READ_SELF,
    PERMISSIONS.SESSION.MEDIA_TITLE_READ_SELF,
    PERMISSIONS.SESSION.MEDIA_LABELS_READ_SELF,
    PERMISSIONS.SESSION.MEDIA_PROPERTIES_READ_SELF
  ],
  [PERMISSIONS.SESSION.MEDIA_READ_ANY]: [
    PERMISSIONS.SESSION.MEDIA_ANNOTATIONS_READ_ANY,
    PERMISSIONS.SESSION.MEDIA_TITLE_READ_ANY,
    PERMISSIONS.SESSION.MEDIA_LABELS_READ_ANY,
    PERMISSIONS.SESSION.MEDIA_PROPERTIES_READ_ANY
  ]
}
