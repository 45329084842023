import * as ActionTypes from '../../actions/types'

const initialState = {
  step: 1,
  template: null,
  isLoading: false
}

const newApplication = (state = initialState, payload) => {
  switch (payload.type) {
    case ActionTypes.CREATE_APP_STEP_UPDATE:
      return { ...state, step: payload.step }
    case ActionTypes.CREATE_APP_TEMPLATE_UPDATE:
      return { ...state, template: payload.template }
    case ActionTypes.CREATE_APP_IS_LOADING_UPDATE:
      return { ...state, isLoading: payload.isLoading }
    default:
      return state
  }
}

export default newApplication
