import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import BlankCheckboxIcon from 'material-ui/svg-icons/toggle/check-box-outline-blank'
import { GridHeader, Column, Loading, ListFooterStats, MediaGallery } from 'skylight-common'
import { TableNextGen } from '../../../../../../Common/NextGen/TableNextGen'
import EventTextFilter from '../EventFilter/EventTextFilter'
import { EmptyListView } from './EmptyListView'
import { COLUMNS } from './columns'
import { AppDashboardBreadcrumbs } from '../../../../../Layout/TopBar/Breadcrumbs/AppDashboardBreadcrumbs'

import appEventActions from '../../../../../../../actions/application/events'
import appSessionActions from '../../../../../../../actions/application/sessions'
import contactActions from '../../../../../../../actions/contact'
import mediaActions from '../../../../../../../actions/media'
import dialogActions from '../../../../../../../actions/dialog'

import './EventList.css'

class EventList extends Component {
  componentDidMount() {
    this.loadSession()
  }

  componentDidUpdate(prevProps) {
    this.loadSession()
  }

  loadSession = () => {
    const sessionId = (this.props.session || {}).sessionId
    if (!sessionId) {
      this.props.onSessionLoad({ sessionId: this.props.sessionId })
    }
  }

  onNavigateToUser = (item) => {
    this.props.onUserSelectItems([item.id], [item])
  }

  renderMediaGallery = props => {
    const { previewId, items, selectedIds } = props.list
    if (!previewId) {
      return null
    }

    const media = items.find(item => item.id === previewId).mediaItemInfo
    return <MediaGallery
      items={[media]}
      list={{ selectedIds: [media] }}
      currentId={media.id}
      detailsOpened={selectedIds.length === 1}
      onPreviewMediaItem={props.onSessionEventPreviewItem}
      onShowError={() => {}}
      onDownloadMediaItem={props.onDownloadMediaItem}
      onShowDownloadMediaConfirmationDialog={props.showDownloadMediaConfirmationDialog}
      onMediaSelectItems={() => this.props.onSessionEventSelectItems([previewId])} />
  }

  renderTableFooter = () => {
    const { list, onSessionEventSelectItems } = this.props
    return list.selectedIds.length > 0
      ? <ListFooterStats list={list} onUnselect={onSessionEventSelectItems} />
      : null
  }

  renderEventsTable = () => {
    const { list, applicationId, heading } = this.props
    if (!applicationId) {
      return null
    }

    return (
      <div className="app-event-table">
        <TableNextGen
          tableHeading={heading}
          checkboxIcon={() => <BlankCheckboxIcon />}
          showHover
          isLoading={list.isLoading}
          query={list.query}
          items={list.items}
          total={list.total}
          done={list.done}
          selectedRows={list.selectedIds}
          name="appSessionEventList"
          tableFooter={this.renderTableFooter()}
          emptyLabel={<EmptyListView />}
          onQuery={this.props.onSessionEventQuery}
          onRowsSelected={this.props.onSessionEventSelectItems}>
          {COLUMNS.map(x => <Column
            key={x.name}
            name={x.name}
            sortable={x.sortable !== false}
            title={<FormattedMessage id={x.title} defaultMessage={x.title} />}
            render={x.render ? item => x.render(item, this.props) : null} />)}
        </TableNextGen>
      </div>
    )
  }

  getTitle =() => {
    return <div className="event-list-title">{this.props.application.name}</div>
  }

  getHeaderActions = () => [<EventTextFilter key="filter" />]

  render() {
    const { application, session, list } = this.props
    const isLoading = !application.applicationId || !session || (list.isLoading && list.items.length === 0)
    return (
      <div className="event-list">
        {this.renderMediaGallery(this.props)}
        <GridHeader title={this.getTitle()} actions={this.getHeaderActions()} />
        <AppDashboardBreadcrumbs />
        <Loading
          isLoading={isLoading}
          message="LOADING_APP_SESSION_EVENT_LIST"
          error={list.error
            ? <FormattedMessage
            id="EMPTY_APP_SESSION_EVENT_LIST"
            defaultMessage="You have no events to view." />
            : null}>
          {this.renderEventsTable()}
        </Loading>
      </div>
    )
  }
}

export { EventList }

const mapStateToProps = (state) => ({
  list: state.application.event.list,
  devices: state.application.event.devices,
  session: state.application.session.item.item,
  permissions: state.application.permissions,
  user: state.auth.user,
  application: state.application.selectedApplication
})

const eventListActions = {
  ...appEventActions,
  ...dialogActions,
  onDownloadMediaItem: mediaActions.onDownloadMediaItem,
  onSessionLoad: appSessionActions.onSessionLoad,
  onUserSelectItems: contactActions.onUserSelectItems
}

const EventListContainer = connect(mapStateToProps, eventListActions)(EventList)

export default EventListContainer
