import React from 'react'
import { FormattedMessage } from 'react-intl'
import DevicesIcon from 'material-ui/svg-icons/action/important-devices'
import WiFiIcon from 'material-ui/svg-icons/notification/wifi'
import HelpIcon from '../../../../Common/Icons/help'

import './TileList.css'

export const TileList = props => {
  const pairDevice = () => {
    props.onShowAddDeviceDialog(props.auth, { timeout: 60 })
  }

  const setupWiFi = () => {
    props.onShowSetupWifiDialog()
  }

  const openHelp = () => {
    props.redirectToHelp()
  }

  return (
    <div className="tile-list">
      <div className="tile" onTouchTap={setupWiFi}>
        <WiFiIcon className="icon" />
        <span className="message">
          <FormattedMessage
            id="SETUP_WIFI_DEVICE"
            defaultMessage="Setup WiFi on your device" />
        </span>
      </div>
      <div className="tile" onTouchTap={pairDevice}>
        <DevicesIcon className="icon" />
        <span className="message">
          <FormattedMessage
            id="PAIR_DEVICE"
            defaultMessage="Pair a device with your account" />
        </span>
      </div>
      <div className="tile" onTouchTap={openHelp}>
        <HelpIcon className="icon" />
        <span className="message">
          <FormattedMessage
            id="VIEW_HELP"
            defaultMessage="View skylight help" />
        </span>
      </div>
    </div>
  )
}
