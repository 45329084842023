import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Snackbar } from 'material-ui'

export const withDownloadSnackbar = (Comp) => {
  return class extends React.Component {
    items = []
    downloadFn = null

    constructor(props) {
      super(props)
      this.state = { downloadSnackbarOpen: false }
    }

    continueDownload = () => {
      setTimeout(() => {
        this.items.forEach(this.downloadFn)
      }, 0) // Ensure we close snack bar first.
    }

    cancelDownload = () => {
      return this.closeDownloadSnackbar('cancel')
    }

    onDownload = (items, downloadFn) => {
      if (!items || !downloadFn) throw new Error('items or download callback not supplied')
      this.items = items
      this.downloadFn = downloadFn
      this.setState({ downloadSnackbarOpen: true })
    }

    closeDownloadSnackbar = (reason) => {
      if (reason !== 'cancel') this.continueDownload()
      this.setState({ downloadSnackbarOpen: false })
    }

    render() {
      return <div>
          <Comp {...this.props} onDownload={this.onDownload} />
          <Snackbar
            className="media-snackbar"
            open={this.state.downloadSnackbarOpen}
            action={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
            message={<FormattedMessage id="DOWNLOAD_SNACKBAR" defaultMessage="Preparing to download..." />}
            autoHideDuration={5000}
            onActionTouchTap={this.cancelDownload}
            onRequestClose={this.closeDownloadSnackbar}/>
        </div>
    }
  }
}
