import React from 'react'
import {
  FilterToolbar, ByApplication, ByUser,
  ByDateRange
} from 'skylight-common'

import './ApplicationFilter.css'

const getFilters = (filterProps = {}) => [
  { text: 'APPLICATION_NAME', value: 'name', comp: ByApplication },
  { text: 'DATE_CREATED', value: 'createdAt', props: { title: 'DATE_CREATED' }, comp: ByDateRange },
  { text: 'OWNER', value: 'createdBy', props: { title: 'OWNER', filterProps }, comp: ByUser }
]

const ApplicationFilter = props => {
  const filterProps = {
    urlPath: '/team/users',
    onSelectItems: props.onUserSelectItems,
    role: props.authUser.role
  }

  const filters = getFilters(filterProps)

  return <FilterToolbar
    className="application-filter"
    filters={filters}
    query={props.query}
    layout={props.layout}
    onFilterUpdated={props.onWorkflowFilterUpdated}
    onFiltersUpdated={props.onWorkflowFiltersUpdated}
    onFilterRemoved={props.onWorkflowFilterRemoved} />
}

export { ApplicationFilter }
