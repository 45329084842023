import React from 'react'
import { TextFilter } from 'skylight-common'
import { CallFilter } from '../../../../../containers/callUser'

const VALUE = 'title'

const CallUserTextFilter = props => {
  const onChange = (v) => {
    const filter = { ...props.query.filter, [VALUE]: v }
    props.onCallUserFilterUpdated(filter)
  }

  return <TextFilter
    className="user-text-filter"
    title="PLACEHOLDER_NAME"
    value={props.query.filter[VALUE]}
    iconFilter={<CallFilter />}
    onChange={onChange} />
}

export { CallUserTextFilter }
