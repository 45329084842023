import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const sequenceFlow = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M18,22 L3,22 C2.448,22 2,21.552 2,21 L2,6 L4,6 L4,20 L18,20 L18,22 Z M21,2 C21.552,2 22,2.448 22,3 L22,17 C22,17.552 21.552,18 21,18 L7,18 C6.448,18 6,17.552 6,17 L6,3 C6,2.448 6.448,2 7,2 L21,2 Z M11.75,13 L11.75,7.27272727 L14,7.27272727 L11,4 L8,7.27272727 L10.25,7.27272727 L10.25,13 L11.75,13 Z M17,16 L20,12.7272727 L17.75,12.7272727 L17.75,7 L16.25,7 L16.25,12.7272727 L14,12.7272727 L17,16 Z" id="path-1"></path>
    </SvgIcon>
  )
}

export default sequenceFlow
