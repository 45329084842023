import * as ActionTypes from '../../../actions/types'
import { DEFAULT_CALLS_QUERY } from '../../../lib/activity'
import { createListReducer } from 'skylight-common'

const list = createListReducer({
  prefix: 'CALL'
}, DEFAULT_CALLS_QUERY)

const callList = (state, payload, all) => {
  const listState = list(state, payload, all)

  switch (payload.type) {
    case ActionTypes.ROUTER_LOCATION_CHANGE:
      return { ...listState }
    default:
      return listState
  }
}

export default callList
