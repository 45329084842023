import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './App.js'
import './index.css'
import { unregister } from './registerServiceWorker'
import skylight from 'skylight-js-sdk'
import datadogRum from 'skylight-datadog-rum'

datadogRum.init(skylight)

ReactDOM.render(<App />, document.getElementById('root'))
// Disabling service worker as it prevents browser cache update:
// https://github.com/facebookincubator/create-react-app/blob/master/packages/react-scripts/template/README.md#opting-out-of-caching
unregister()
