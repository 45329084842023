import React from 'react'
import { CallButton, UserAvatar, UserTitle } from '../Common'

import ShareIcon from 'material-ui/svg-icons/communication/screen-share'
import StopShareIcon from 'material-ui/svg-icons/communication/stop-screen-share'
import { CallDuration } from './CallDuration'
import './CallHeader.css'

export const CallHeader = props => {
  const { connection } = props.call
  const isSharing = false
  const shareBtn = isSharing
    ? { icon: <StopShareIcon />, title: 'CALL_STOP_SHARE', onTouchTap: () => {} }
    : { icon: <ShareIcon />, title: 'CALL_SHARE', onTouchTap: () => {} }
  return (
    <div className="call-header">
      <div className="left">
        <UserAvatar user={connection.partner} />
        <div className="title-duration">
          <UserTitle user={connection.partner} />
          <CallDuration />
        </div>
      </div>
      <div className="right">
        {false && <CallButton
          className="share"
          {...shareBtn} />}
      </div>
    </div>
  )
}
