import React from 'react'
import { FormattedMessage } from 'react-intl'
import IconButton from 'material-ui/IconButton'
import SettingIcon from 'material-ui/svg-icons/action/settings'
import './UserAttributesPicker.css'

const styles = {
  smallIcon: {
    width: 24,
    height: 24,
    padding: 0
  }
}

const UserAttributesPicker = ({ showUserAttributesDialog }) => {
  return (
    <div className="attributes-picker">
      <FormattedMessage
        className="attributes-picker-text"
        id="LDAP_USER_ATTRIBUTES"
        defaultMessage="User Attributes" />
      <IconButton
        className="attributes-picker-button"
        style={styles.smallIcon}
        onTouchTap={showUserAttributesDialog}
        tooltip={
          <FormattedMessage
            id="LDAP_TOOLTIP_USER_ATTRIBUTES"
            defaultMessage="LDAP_TOOLTIP_CERT_VERIFICATION" />
        }
        tooltipPosition="bottom-center">
        <SettingIcon className="attributes-picker-icon" />
      </IconButton>
    </div>
  )
}

export { UserAttributesPicker }
