/* eslint-disable */
export const isCallingSupported = () => {
  const { userAgent='' } = window.navigator
  if (!userAgent.includes('Chrome')) {
    return false
  }

  if (userAgent.includes('AppleWebKit') && !userAgent.includes('Macintosh')) {
    return false
  }

  if (userAgent.includes('Android')) {
    return false
  }

  return true
}

export const isAndroid = () => {
  const { userAgent='' } = window.navigator

  return userAgent.toLowerCase().includes('android')
}

export const AUTH_STEPS_TOTAL = 3

export const isFirefox = typeof InstallTrigger !== 'undefined'

// Safari 3.0+ "[object HTMLElementConstructor]" 
export const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

// Internet Explorer 6-11
export const isIE = /*@cc_on!@*/false || !!document.documentMode;
