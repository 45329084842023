import React, { Component } from 'react'

export default (WrappedComponent) => class extends Component {
  state = {
    hovered: false
  }

  hoverIn = () => this.setState({ hovered: true })

  hoverOut = () => this.setState({ hovered: false })

  render() {
    return <WrappedComponent {...this.state} {...this.props} hoverIn={this.hoverIn} hoverOut={this.hoverOut} />
  }
}
