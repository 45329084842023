import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import MenuItem from 'material-ui/MenuItem'
import { OutlinedSelectField } from '../../Form/OutlinedSelectField'

class FieldSelect extends Component {
  renderItems = () => {
    const { options = [] } = this.props.fieldProps
    return options.map((option, i) =>
      <MenuItem
        key={i}
        value={option.value}
        primaryText={<FormattedMessage id={option.title} defaultMessage={option.title} />} />
    )
  }

  getFormattedTitle = title => {
    return title ? <FormattedMessage id={title} defaultMessage={title} /> : null
  }

  renderSelection = value => {
    const { options = [] } = this.props.fieldProps

    const title = (options.find(option => option.value === value) || {}).title
    return this.getFormattedTitle(title)
  }

  onChange = (e, k, value) => this.props.onChange(this.props.name, value)

  render() {
    const {
      name,
      value,
      label,
      disabled,
      required,
      error
    } = this.props

    return (
      <div className={classNames('field field-select', { required })}>
        <OutlinedSelectField
          name={name}
          required={required}
          value={value}
          disabled={disabled}
          errorText={error}
          floatingLabelText={label}
          selectionRenderer={this.renderSelection}
          onChange={this.onChange}>
          {this.renderItems()}
        </OutlinedSelectField>
      </div>
    )
  }
}

export { FieldSelect }
