import React, { Component } from 'react'
import classNames from 'classnames'
import { RequiredLabel } from 'skylight-common'
import { ActionButton, Loader } from '../components'
import { FilterItem } from './FilterItem'
import { FormattedMessage } from 'react-intl'
import { FIELDS } from './utils'

import './CustomFilters.css'

class CustomFiltersComp extends Component {
  onAddFilter = () => {
    const { onChange, value } = this.props
    const newItem = FIELDS.reduce((obj, next) => ({ ...obj, [next.prop]: next.defaultValue }), {})

    onChange('customFilters', [newItem, ...value])
  }

  onChangeFilter = (prop, value, index) => {
    const { onChange } = this.props
    const newCustomFilters = this.props.value.slice()
    newCustomFilters[index] = { ...newCustomFilters[index], [prop]: value }

    onChange('customFilters', newCustomFilters)
  }

  onFilterDelete = idx => {
    const { onChange, value } = this.props
    const customFilters = value.filter((item, index) => index !== idx)

    onChange('customFilters', customFilters)
  }

  renderFilterItems = () => {
    const { fieldProps, value } = this.props
    const textField = fieldProps.textField

    return value.map((item, index) => (
      <FilterItem
        TextField={textField}
        key={index}
        item={item}
        idx={index}
        onFilterDelete={this.onFilterDelete}
        onChange={this.onChangeFilter} />
    ))
  }

  getTitle = () => {
    const { fieldProps } = this.props
    const { required } = fieldProps

    const title = <FormattedMessage id={fieldProps.title} defaultMessage="Add custom user LDAP filter (optional)" />

    if (required) {
      return <RequiredLabel>{title}</RequiredLabel>
    }
    return title
  }

  render() {
    const { fieldClassName, fieldProps, isLoading, isSyncLoaded } = this.props
    const { required } = fieldProps

    if (isLoading) {
      return <Loader />
    }

    return (
      <div className={fieldClassName}>
        <div className={classNames('field', 'field-custom-filters', {
          required,
          'field-disabled': isSyncLoaded
        })}>
          <div className="label-wrapper">
            <div className="label-button">
              <ActionButton
                icon="add"
                className="add-filter-button"
                labelId="ADD_CUSTOM_FILTER"
                tooltipPosition="bottom-center"
                defaultMessage="Add custom filter"
                onTouchTap={this.onAddFilter} />
            </div>
            <label className="label-title">{this.getTitle()}</label>
          </div>
          {this.renderFilterItems()}
        </div>
      </div>
    )
  }
}

export { CustomFiltersComp }
