import React from 'react'
import FullscreenIcon from 'material-ui/svg-icons/navigation/fullscreen'
import IconButton from 'material-ui/IconButton'
import './ImagePreview.css'

const ImagePreview = props => {
  const { item, title } = props
  const imgTitle = item ? (item.title || item.name) : title

  return <div className="image-preview">
      <img
        src={props.objectUrl}
        alt={imgTitle}
        onError={e => { e.currentTarget.style.display = 'none' }} />

      <div className="preview-bar">
        <span className="name">{imgTitle}</span>
        <IconButton onClick={() => props.onPreviewMediaItem(item.id)}>
          <FullscreenIcon />
        </IconButton>
      </div>
    </div>
}

export { ImagePreview }
