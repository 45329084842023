import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import './MissingAccount.css'

const MissingAccount = (props) => {
  return <div className="missing-account">
      <h3><FormattedMessage id="ACCOUNTASSISTANT" defaultMessage="Account Assistent" /></h3>

      <div className="no-matching-account"><FormattedMessage id="NOMATCHINGACCOUNT" defaultMessage="No matching account" /></div>

      <div>
        <Link to="/login"><FormattedMessage id="TRYAGAIN" defaultMessage="Try Again" /></Link>
        &nbsp;<FormattedMessage id="OR" defaultMessage="or" />
        &nbsp;<FormattedMessage id="CONTACTUS" defaultMessage="Contact us" />
      </div>
    </div>
}

export { MissingAccount }
