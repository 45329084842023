import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'
import { Tabs, Tab } from 'material-ui/Tabs'
import { DialogTitle } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import { FooterActionButtons, TwoColumnHeader } from '../../../../Common'
import { GroupBody } from './GroupBody'
import { RoleBody } from './RoleBody'
import { ROLES_LIST } from '../../../../../lib/admin'

import './QueryFilterDialog.css'

const SYNC_TYPE = {
  GROUP: 'group',
  ROLE: 'role'
}

const QUERY_TABS = {
  GROUP: 'group',
  ROLE: 'role'
}

const headerMapper = {
  [QUERY_TABS.GROUP]: 'LDAP_SKYLIGHT_GROUP',
  [QUERY_TABS.ROLE]: 'LDAP_SKYLIGHT_ROLE'
}

class QueryFilterDialog extends Component {
  constructor(props) {
    super()
    const { ldapApp: { original } } = props
    const { customFilters } = original
    const [groupFilters, roleFilters] = this.partition(customFilters, ({ syncType }) => syncType === SYNC_TYPE.GROUP)

    this.state = {
      tab: QUERY_TABS.GROUP,
      hasChanged: false,
      groupFilters,
      roleFilters: [...roleFilters, { syncType: SYNC_TYPE.ROLE, name: 'user' }]
    }
  }

  onSave = () => {
    const { groupFilters = [], roleFilters = [] } = this.state
    const filters = [...groupFilters, ...roleFilters].filter(f => f.name && f.filter)
    this.props.onLdapCustomFiltersUpdate(filters)
    this.onHide()
  }

  partition(arr = [], predicate = () => true) {
    const truthyValues = []
    const falsyValues = []
    for (const item of arr) {
      if (predicate(item)) {
        truthyValues.push(item)
      } else {
        falsyValues.push(item)
      }
    }
    return [truthyValues, falsyValues]
  }

  onTabChange = (tab) => {
    this.setState({ tab })
  }

  onGroupFilterChange = (id, value) => {
    const { groupFilters } = this.state
    const clonedGroupFlterMappings = groupFilters.map((groupFilter) => ({ ...groupFilter }))
    clonedGroupFlterMappings[id] = { syncType: SYNC_TYPE.GROUP, ...value }
    this.setState({
      groupFilters: clonedGroupFlterMappings,
      hasChanged: true
    })
  }

  handleRoleFiltersChange = roleFilters => {
    this.setState({ roleFilters, hasChanged: true })
  }

  onRemoveFilter= (idx, prop) => {
    const clonedFilters = this.state[prop].map((value) => ({ ...value }))
    clonedFilters.splice(idx, 1)

    this.setState({
      [prop]: clonedFilters,
      hasChanged: true
    })
  }

  areAllFiltersFilled(filters) {
    return filters.every(({ name = null, filter = '' }) => {
      return name && filter
    })
  }

  onHide = () => {
    this.props.onHideDialog(this.props.dialogType)
  }

  renderDialogTitle = () => (
    <DialogTitle
      id="LDAP_SECTION_QUERY"
      defaultMessage="Query / Filter Configuration">
      <FormattedMessage
        id="LDAP_QUERY_DIALOG_DESCRIPTION"
        defaultMessage="LDAP_QUERY_DIALOG_DESCRIPTION">
        {content => <span className="dialog-subtitle">{content}</span>}
      </FormattedMessage>
    </DialogTitle>
  )

  render() {
    const { tab, groupFilters, roleFilters, hasChanged } = this.state
    const hasFilters = !!groupFilters.length || !!roleFilters.length
    const { dialog, dialogType, groups } = this.props
    const { open } = dialog[dialogType] || {}
    const headersProps = {
      firstTitleId: headerMapper[tab],
      secondTitleId: 'LDAP_LDAP_FILTER'
    }

    return (
      <div className="query-filter">
        <Dialog
          title={this.renderDialogTitle()}
          modal={false}
          open={open}
          className="dialog-modal query-filter-dialog"
          contentClassName="query-filter-dialog-content"
          bodyClassName="query-filter-dialog-body"
          onRequestClose={this.onHide}>
          <Tabs
            value={tab}
            className="query-filter-dialog-body-tabs"
            onChange={this.onTabChange}>
            <Tab
              label={
                <FormattedMessage
                  id="LDAP_TAB_GROUP"
                  defaultMessage="Group Filters" />
              }
              value={QUERY_TABS.GROUP} />
            <Tab
              label={
                <FormattedMessage
                  id="LDAP_TAB_ROLE"
                  defaultMessage="Role Filters" />
              }
              value={QUERY_TABS.ROLE} />
          </Tabs>
          <TwoColumnHeader {...headersProps} />
          {tab === QUERY_TABS.GROUP && (
            <GroupBody
              groups={groups}
              groupFilters={groupFilters}
              onGroupFilterChange={this.onGroupFilterChange}
              enableNewRow={this.areAllFiltersFilled(groupFilters)}
              onDelete={this.onRemoveFilter} />
          )}
          {tab === QUERY_TABS.ROLE && <RoleBody
            roles={ROLES_LIST}
            roleFilters={roleFilters}
            onRoleFiltersChange={this.handleRoleFiltersChange}
            onDelete={this.onRemoveFilter} />}
          <FooterActionButtons
            onCancel={this.onHide}
            onSave={this.onSave}
            isSubmitDisabled={!hasChanged || !hasFilters} />
        </Dialog>
      </div>
    )
  }
}

export { QueryFilterDialog }
