import React from 'react'
import { CONFIG } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { SkylightHelpMenu } from './SkylightHelpMenu'

const SkylightHelpMenuLegacy = props => {
  const ACTIONS = [
    {
      type: 'skylight-help',
      title: <FormattedMessage id="SKYLIGHT_HELP" defaultMessage="Skylight Help" />,
      handler: () => { props.redirectToHelp() }
    },
    {
      type: 'whats-new',
      title: <FormattedMessage id="WHATS_NEW_IN_SKYLIGHT" defaultMessage="What's new in Skylight" />,
      handler: () => { props.goToHelp(CONFIG.helpWhatsNewUrl, true) }
    },
    {
      type: 'about-skylight-web',
      title: <FormattedMessage id="ABOUT_SKYLIGHT_WEB" defaultMessage="About Skylight Web" />,
      handler: () => { props.onShowVersionDialog() }
    }
  ]

  return (
    <SkylightHelpMenu
      {...props}
      className={classNames('skylight-help-menu-legacy', props.className)}
      actions={ACTIONS} />
  )
}

export { SkylightHelpMenuLegacy }
