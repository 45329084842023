export const DEFAULT_QUERY = {
  limit: 200,
  skip: 0,
  sortBy: 'createdAt',
  sortAsc: false,
  filter: {},
  filters: []
}

export const PICKER_QUERY = {
  sortBy: 'createdAt',
  sortAsc: true
}

export const EVENT_SOURCES = {
  SKYLIGHT: 'skylight',
  TWILIO: 'twilio'
}
