import React from 'react'
import tapEventInjector from 'react-tap-event-plugin'
import { store } from './containers/store'
import { Provider } from 'react-redux'
import { Root } from './containers/layout'

tapEventInjector()

const App = props => {
  return <Provider store={store}>
      <Root />
    </Provider>
}

export { App }
