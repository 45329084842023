import * as ActionTypes from '../../actions/types'

const initialState = {
  isLoading: false,
  errors: {}
}

const verify = (state = initialState, payload) => {
  switch (payload.type) {
    case ActionTypes.VERIFY_CREDENTIALS_REQUEST:
      return { ...state, isLoading: true }
    case ActionTypes.VERIFY_CREDENTIALS_RESPONSE:
      return { ...state, isLoading: false }
    case ActionTypes.VERIFY_CREDENTIALS_FIELD_ERROR:
      return { ...state, errors: payload.errors, isLoading: false }
    case ActionTypes.CLEAR_VERIFY_CREDENTIALS_FIELD_ERROR:
      return initialState
    default:
      return state
  }
}

export default verify
