import * as ActionTypes from '../../actions/types'

const initialState = []

const users = (state = initialState, payload, all) => {
  switch (payload.type) {
    case ActionTypes.PRESENSE_USERS_LOADED:
      return payload.users
    default:
      return state
  }
}

export default users
