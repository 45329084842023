import * as ActionTypes from './types'
import skylight from 'skylight-js-sdk'
import { createItemActions, validate } from 'skylight-common'
import { getDefaultLdap } from '../svc/admin'
import { LDAP_FIELDS, JOB_STATUSES, JOB_TIMEOUT, DEFAULT_LDAP_SERVER_PORT, DEFAULT_LDAP_CONNECTION_OPTION } from '../lib/admin'
import { showError } from './error'

export const LDAP_APP_ACTIONS = createItemActions({
  prefix: ActionTypes.PREFIX_LDAP_APP.toUpperCase(),
  handler: ActionTypes.PREFIX_LDAP_APP,
  onLoad: async() => {
    let ldapApp
    try {
      ldapApp = await skylight.ldap.get()

      // syncInterval is minutes - convert to hours for display. integers only
      ldapApp.syncInterval = Math.round(Math.max(ldapApp.syncInterval / 60, 1))
      ldapApp.serverPort = ldapApp.serverPort || DEFAULT_LDAP_SERVER_PORT
      ldapApp.connectionSecurity = ldapApp.connectionSecurity || DEFAULT_LDAP_CONNECTION_OPTION

      return ldapApp || getDefaultLdap()
    } catch (e) {
      skylight.logger.error('LdapError', e)

      if (e.status === 404) {
        return getDefaultLdap() // for returning ldap config with default values
      }
      throw e
    }
  },
  onValidate: (item, store) => {
    const errors = validate(item, LDAP_FIELDS)

    if (errors) {
      store.dispatch(showError('LDAPAPP_SAVE_ERROR', { className: 'snackbar-common-error' }))
      return errors
    }
  },
  onSave: (item, ctx) => {
    const original = ctx.getState().admin.auth.ldapApp.original
    // No changes were really made.
    if (original && !item.id && item.ldapEnabled === original.ldapEnabled) return

    // syncInterval displayed in hours - convert to minutes to store. integers only.
    item.syncInterval = Math.max(Math.round(item.syncInterval) * 60, 1)
    item.serverPort = item.serverPort ? Number(item.serverPort) : DEFAULT_LDAP_SERVER_PORT

    return skylight.ldap.update(item)
  },
  onDelete: async() => {
    return skylight.ldap.delete()
  }
})

let statusSyncTimeout = null

const completeForceSync = () => dispatch => {
  statusSyncTimeout && clearTimeout(statusSyncTimeout)

  dispatch(LDAP_APP_ACTIONS.onLdapAppLoad())
  dispatch({ type: ActionTypes.LDAPAPP_FORCE_SYNC_LOADED })
}

const completeForceSyncWithError = () => dispatch => {
  statusSyncTimeout && clearTimeout(statusSyncTimeout)
  dispatch({ type: ActionTypes.LDAPAPP_FORCE_SYNC_LOAD_ERROR })
}

const onLdapAbortSync = () => dispatch => {
  statusSyncTimeout && clearTimeout(statusSyncTimeout)
  dispatch({ type: ActionTypes.LDAPAPP_FORCE_SYNC_ABORT })
}

export const onLdapForceSync = () => async dispatch => {
  if (statusSyncTimeout) {
    clearTimeout(statusSyncTimeout)
    statusSyncTimeout = null
  }

  dispatch({ type: ActionTypes.LDAPAPP_FORCE_SYNC_LOAD })

  // let numberOfTries = 0

  const checkJobStatus = async() => {
    const allJobs = await skylight.ldap.getJobsStatus()

    // Sort the jobs to make a job with the most recent time to be the last one
    allJobs.sort((a, b) => new Date(a.startTime) - new Date(b.startTime))

    // Get last job
    const job = allJobs[allJobs.length - 1]

    // numberOfTries += 1

    if (job && job.status === JOB_STATUSES.SUCCEEDED) {
      dispatch(completeForceSync())
    } else if (!job || job.status === JOB_STATUSES.FAILED) {
      dispatch(completeForceSyncWithError())
    } else if (job.status === JOB_STATUSES.ACTIVE) {
      statusSyncTimeout = setTimeout(async() => {
        await checkJobStatus()
      }, JOB_TIMEOUT)
    }
  }

  try {
    await skylight.ldap.forceSync()

    await checkJobStatus()
  } catch (e) {
    dispatch(completeForceSyncWithError())
    throw e
  }
}

const customMappingsUpdateSuccess = (customMappings) => ({
  type: ActionTypes.LDAPAPP_CUSTOM_MAPPINGS_UPDATE_SUCCESS,
  customMappings
})

const customMappingsUpdateError = (error) => ({
  type: ActionTypes.LDAPAPP_CUSTOM_MAPPINGS_UPDATE_ERROR,
  error
})

const onLdapCustomMappingsUpdate = (customMappings) => async(dispatch, getState) => {
  const { original } = getState().admin.auth.ldapApp
  try {
    await skylight.ldap.update({ ...original, customMappings })
    dispatch(customMappingsUpdateSuccess(customMappings))
  } catch (e) {
    dispatch(customMappingsUpdateError(e))
  }
}

const customFiltersUpdateSuccess = (customFilters) => ({
  type: ActionTypes.LDAPAPP_CUSTOM_FILTERS_UPDATE_SUCCESS,
  customFilters
})

const customFiltersUpdateError = (error) => ({
  type: ActionTypes.LDAPAPP_CUSTOM_FILTERS_UPDATE_ERROR,
  error
})

const onLdapCustomFiltersUpdate = (customFilters) => async(dispatch, getState) => {
  const { original } = getState().admin.auth.ldapApp
  try {
    await skylight.ldap.update({ ...original, customFilters })
    dispatch(customFiltersUpdateSuccess(customFilters))
  } catch (e) {
    dispatch(customFiltersUpdateError(e))
  }
}

export default {
  ...LDAP_APP_ACTIONS,
  onLdapForceSync,
  onLdapAbortSync,
  onLdapCustomMappingsUpdate,
  onLdapCustomFiltersUpdate
}
