import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Loading } from 'skylight-common'
import { AuthPicker } from './AuthPicker'
import { SamlOptions } from './SamlOptions'

import './AuthApps.css'

const AuthApps = props => {
  const { authApp } = props

  return (
    <Loading
      isLoading={authApp.isLoading}
      error={
        authApp.loadError
          ? (
          <FormattedMessage
            id={authApp.loadError}
            defaultMessage="Failed to load SAML configuration"/>
            )
          : null
      }>
      <div className="auth-apps">
        <AuthPicker {...props} />
        <SamlOptions {...props} />
      </div>
    </Loading>
  )
}

export { AuthApps }
