import React from 'react'
import { FilterToolbar } from 'skylight-common'
import classNames from 'classnames'
import FilterSearchIcon from '../../Icons/filterSearch'

import './FilterToolbarNextGen.css'

const FilterToolbarNextGen = props => {
  return <FilterToolbar
    {...props}
    className={classNames('filter-toolbar-next-gen', props.className)}
    icon={FilterSearchIcon} />
}

export { FilterToolbarNextGen }
