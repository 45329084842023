import React from 'react'
import { FormattedMessage } from 'react-intl'
import CircularProgress from 'material-ui/CircularProgress'
import Visibility from 'material-ui/svg-icons/action/visibility'
import { Tooltip } from 'skylight-common'

import './PendingIndicator.css'

export const PendingIndicator = props => {
  const { total, onClick } = props

  return (
    <div className="list-footer pending-assignments-indicator" onClick={onClick}>
      <Tooltip tooltip={<FormattedMessage id="VIEW_PENDING_ASSIGNMENTS" defaultMessage="View pending assignments" />}
               key={total}>
        <div className="tooltip-container">
          <CircularProgress size={24} thickness={2} className="icon spinner-icon" />
          <Visibility className="icon visibility-icon" />
          <div className="pending-assignments-label">
            <div className="tooltip-children-container">
              <FormattedMessage id="PENDING_ASSIGNMENTS" defaultMessage="Pending assignments" />
              <span>:</span>
            </div>
            <span className="pending-size">{total}</span>
          </div>
        </div>
      </Tooltip>
    </div>
  )
}
