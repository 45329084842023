import Konva from 'konva'
import { getHtmlImage } from '../../lib/svc'

// EyeSight clone.
const calculateScaleFactor = (elementWidth, elementHeight, maxWidth, maxHeight) => {
  const offset = { x: 0, y: 0 }
  // default is no scale
  let scaleFactorWidth = 1
  let scaleFactorHeight = 1

  if (elementWidth > maxWidth || elementHeight > maxHeight) { // need to scale image to fit stage
    // We calculate both scale factors: width and height.
    // The smallest scale factor is the one we'll use so we don't lose any of the image
    scaleFactorWidth = maxWidth / elementWidth
    scaleFactorHeight = maxHeight / elementHeight
  }

  const factor = scaleFactorWidth < scaleFactorHeight ? scaleFactorWidth : scaleFactorHeight

  offset.y = Math.floor((maxHeight - (elementHeight * factor)) / 2)
  offset.x = Math.floor((maxWidth - (elementWidth * factor)) / 2)

  return {
    factor: factor,
    offset: offset,
    width: Math.round(elementWidth * factor),
    height: Math.round(elementHeight * factor)
  }
}

const resizeKonvaElement = (element, maxWidth, maxHeight) => {
  const elementWidth = element.width()
  const elementHeight = element.height()

  // calculate scale
  const scaleObj = calculateScaleFactor(elementWidth, elementHeight, maxWidth, maxHeight)

  // apply scale
  element.scale({
    x: scaleObj.factor,
    y: scaleObj.factor
  })

  // return scale object so we can deal with offsets
  return scaleObj
}

// match the drawing overlay to the imageOverlay
const resizeDrawingOverlay = (ctx, imageWidth, imageHeight) => {
  const { drawOverlay } = ctx
  const maxWidth = ctx.stage.width()
  const maxHeight = ctx.stage.height()
  drawOverlay.width(imageWidth)
  drawOverlay.height(imageHeight)

  const scaleDraw = resizeKonvaElement(drawOverlay, maxWidth, maxHeight)
  drawOverlay.x(scaleDraw.offset.x)
  drawOverlay.y(scaleDraw.offset.y)

  // Calculate scale factor and offsets for annotations
  const cCenterX = maxWidth / 2
  const cCenterY = maxHeight / 2
  const scaleFactorWidth = maxWidth / imageWidth
  const scaleFactorHeight = maxHeight / imageHeight
  const scaleFactor = scaleFactorWidth < scaleFactorHeight ? scaleFactorWidth : scaleFactorHeight

  ctx.drawing.scaleFactor = scaleFactor
  ctx.drawing.offsetLeft = cCenterX - (imageWidth * scaleFactor) / 2
  ctx.drawing.offsetTop = cCenterY - (imageHeight * scaleFactor) / 2

  // (Re)draw local and remote annotations for this image.
  drawLocalAnnotations(ctx)
  drawRemoteAnnotations(ctx)
}

const refreshCursor = (ctx) => {
  if (!ctx.layers.drawing.visible()) return
  const assetName = ctx.gestureMode === 'draw' ? 'pen.cur' : 'move.svg'
  ctx.container.style.cursor = `url('assets/cursors/${assetName}'),auto`
}

/* DRAWING HANDLERS */
const startDrawing = (ctx) => {
  if (!ctx.layers.drawing.visible()) return
  ctx.drawing.isDrawing = true

  ctx.drawing.currentLine = {
    oid: ctx.currentImageId,
    color: ctx.drawing.color.replace('#', ''),
    points: []
  }

  const line = new Konva.Line({
    stroke: ctx.drawing.color,
    strokeWidth: 8, // $scope.drawing.scaleFactor,
    tension: 1,
    width: ctx.container.clientWidth,
    height: ctx.container.clientHeight
  })

  ctx.localAnnotations.push(line)

  // Note: We add to the image layer - for some reason adding to the drawing
  // layer caused random incorrect mouse events to fire.
  ctx.layers.images.add(line)
}

const continueDrawing = ctx => {
  if (!ctx.layers.drawing.visible()) return
  if (!ctx.drawing.isDrawing) return

  const index = ctx.localAnnotations.length - 1
  const line = ctx.drawing.currentLine

  const pos = ctx.stage.getPointerPosition()
  line.points.push(pos.x)
  line.points.push(pos.y)

  // just update our last annotation with the new points
  ctx.localAnnotations[index].points(line.points)

  // We'll use batchDraw here that takes care of performance hit for drawing every movement.
  ctx.layers.images.batchDraw()
}

const endDrawing = ctx => {
  if (!ctx.layers.drawing.visible()) return
  if (!ctx.drawing.isDrawing) return

  ctx.drawing.isDrawing = false
  ctx.drawing.lastPos = null

  ctx.container.style.cursor = 'default'

  // Add the local annotation to the image data object for tracking
  const line = ctx.drawing.currentLine

  // Convert the line before storing it to the full scale.
  let n = 0
  line.points = line.points.map(pt => {
    // If the point is even (X value) the shift it from the left offset.
    if (n % 2 === 0) {
      pt = pt - ctx.drawing.offsetLeft
    } else {
      // If odd (Y Value) shift from the top offset
      pt = pt - ctx.drawing.offsetTop
    }
    n = n + 1
    return pt / ctx.drawing.scaleFactor
  })

  const newLines = [...ctx.item.localAnnotations, line]
  ctx.preventRedraw = true
  ctx.options.sendAnnotations(ctx.item.id, newLines)
  refreshCursor(ctx)

  // TODO!
  // imageService.addLocalAnnotation($scope.currentImageId, line);
  // checkCanUndoAnnotations($scope.currentImageId);

  // if(callService.state == callService.states.inCall){
  //   sendLocalAnnotations();
  // }
}

const drawMouseHandler = {
  over: refreshCursor,
  down: startDrawing,
  move: continueDrawing,
  up: endDrawing
}

const startMoving = (ctx) => {
  ctx.move = {
    current: ctx.stage.getPointerPosition()
  }
}

const continueMoving = (ctx) => {
  if (!ctx.move) return
  const newPos = ctx.stage.getPointerPosition()
  ctx.drawing.offsetLeft += newPos.x - ctx.move.current.x
  ctx.drawing.offsetTop += newPos.y - ctx.move.current.y
  ctx.imageOverlay.x(ctx.drawing.offsetLeft)
  ctx.imageOverlay.y(ctx.drawing.offsetTop)
  ctx.drawOverlay.x(ctx.drawing.offsetLeft)
  ctx.drawOverlay.y(ctx.drawing.offsetTop)

  drawAnnotations(ctx)

  ctx.move.current = newPos
}

const drawAnnotations = (ctx) => {
  drawLocalAnnotations(ctx)
  drawRemoteAnnotations(ctx)
  ctx.stage.draw()
}

const endMoving = (ctx) => {
  ctx.move = null
}

const moveMouseHandler = {
  over: refreshCursor,
  down: startMoving,
  move: continueMoving,
  up: endMoving
}

const getMouseHandler = (ctx) => {
  return ctx.gestureMode === 'draw' ? drawMouseHandler : moveMouseHandler
}

const mouseHandler = {
  over: ctx => getMouseHandler(ctx).over(ctx),
  down: ctx => {
    // Do not use refs due to performance considerations.
    const mediaEditor = document.querySelector('.media-editor')
    if (mediaEditor) {
      mediaEditor.className = 'media-editor drawing'
    }
    getMouseHandler(ctx).down(ctx)
  },
  move: ctx => getMouseHandler(ctx).move(ctx),
  up: ctx => {
    // Do not use refs due to performance considerations.
    const mediaEditor = document.querySelector('.media-editor')
    if (mediaEditor) {
      mediaEditor.className = 'media-editor'
    }
    getMouseHandler(ctx).up(ctx)
  }
}

const drawLocalAnnotations = (ctx) => {
  ctx.localAnnotations.forEach(line => line.remove())
  ctx.localAnnotations = []

  const lines = ctx.item.localAnnotations

  lines.forEach(line => {
    const kLine = new Konva.Line({
      stroke: '#' + line.color,
      strokeWidth: 8 / ctx.drawing.scaleFactor,
      tension: 1,
      width: ctx.stage.width(),
      height: ctx.stage.height(),
      points: line.points,
      scale: {
        x: ctx.drawing.scaleFactor,
        y: ctx.drawing.scaleFactor
      }
    })

    // Move the line by the offsets that get calculated after resizing the image
    kLine.move({
      x: ctx.drawing.offsetLeft,
      y: ctx.drawing.offsetTop
    })

    // Draw the lines.
    ctx.localAnnotations.push(kLine)
    ctx.layers.images.add(kLine)
  })
  ctx.layers.images.batchDraw()
}

const drawRemoteAnnotations = ctx => {
  ctx.remoteAnnotations.forEach(line => line.remove())
  ctx.remoteAnnotations = []

  const lines = ctx.item.remoteAnnotations

  // If we get an empty annotation array the remote client cleared so we
  // need to clear our canvas
  // TODO: Figure out how to handle undo
  if (lines.length === 0) {
    // clearRemoteAnnotations();
  }

  lines.forEach(line => {
    const kLine = new Konva.Line({
      stroke: '#' + line.color,
      strokeWidth: 8 / ctx.drawing.scaleFactor,
      tension: 1,
      width: ctx.stage.width(),
      height: ctx.stage.height(),
      points: line.points,
      scale: {
        x: ctx.drawing.scaleFactor,
        y: ctx.drawing.scaleFactor
      }
    })

    kLine.move({
      x: ctx.drawing.offsetLeft,
      y: ctx.drawing.offsetTop
    })

    // Draw the lines.
    ctx.remoteAnnotations.push(kLine)
    ctx.layers.images.add(kLine)
  })

  ctx.layers.images.batchDraw()
}

const drawImage = (ctx) => {
  const { container, imageOverlay, item, stage } = ctx
  return getHtmlImage(item.file.src).then(img => {
    ctx.img = { width: img.width, height: img.height }
    ctx.imgElem = img
    imageOverlay.setImage(img)
    const maxWidth = container.clientWidth
    const maxHeight = container.clientHeight

    stage.width(maxWidth)
    stage.height(maxHeight)
    const scale = resizeKonvaElement(imageOverlay, maxWidth, maxHeight)
    imageOverlay.x(scale.offset.x)
    imageOverlay.y(scale.offset.y)
    ctx.originalScale = scale.factor
    resizeDrawingOverlay(ctx, img.width, img.height)

    stage.draw()
    zoom(ctx, ctx.zoom)
  })
}

const redraw = async(ctx, prevItem = {}) => {
  if (ctx.preventRedraw) {
    console.log('prevented redraw one time')
    ctx.preventRedraw = false
    return
  }

  if (ctx.item.id !== prevItem.id) {
    ctx.layers.images.clear()
    await drawImage(ctx)
  }

  drawLocalAnnotations(ctx)
  drawRemoteAnnotations(ctx)
}

const getCenter = (ctx) => {
  return {
    scaleFactor: ctx.drawing.scaleFactor,
    x: (ctx.stage.width() - ctx.imageOverlay.width() * ctx.drawing.scaleFactor) / 2,
    y: (ctx.stage.height() - ctx.imageOverlay.height() * ctx.drawing.scaleFactor) / 2
  }
}

const zoom = (ctx, zoom) => {
  const zoomDelta = zoom / ctx.zoom
  // console.log('set zoom', ctx.zoom, '->', zoom, '\t\tscaleFactor:', ctx.drawing.scaleFactor, '->', ctx.originalScale * zoom)
  ctx.zoom = zoom
  const oldCenter = getCenter(ctx)
  ctx.drawing.scaleFactor = ctx.originalScale * zoom
  const scaleObj = { x: ctx.drawing.scaleFactor, y: ctx.drawing.scaleFactor }

  const center = getCenter(ctx)
  let delta = {
    x: (oldCenter.x - ctx.drawing.offsetLeft) * zoomDelta,
    y: (oldCenter.y - ctx.drawing.offsetTop) * zoomDelta
  }
  if (zoom === 1) {
    delta = { x: 0, y: 0 }
  }
  ctx.drawing.offsetLeft = center.x - delta.x
  ctx.drawing.offsetTop = center.y - delta.y

  ctx.imageOverlay
    .scale(scaleObj)
    .x(ctx.drawing.offsetLeft)
    .y(ctx.drawing.offsetTop)

  ctx.drawOverlay
    .scale(scaleObj)
    .x(ctx.drawing.offsetLeft)
    .y(ctx.drawing.offsetTop)

  drawAnnotations(ctx)
}

const getPreview = async(ctx) => {
  let snapshotWidth = ctx.item.file.width
  let snapshotHeight = ctx.item.file.height

  // downscale, if necessary.  chrome doesn't seem to want to download super high-res files
  const maxDimensionForDownload = 1600
  let scaleFactor = 1

  const largerDimension = snapshotWidth > snapshotHeight
    ? 'width'
    : 'height'

  if (ctx.img[largerDimension] > maxDimensionForDownload) {
    scaleFactor = maxDimensionForDownload / ctx.img[largerDimension]
  }

  snapshotWidth = snapshotWidth * scaleFactor
  snapshotHeight = snapshotHeight * scaleFactor

  const kDownloadStage = new Konva.Stage({
    container: ctx.container, // id of container <div>
    width: snapshotWidth,
    height: snapshotHeight
  })
  const kSnapshotLayer = new Konva.Layer()

  // add snapshot image
  // const imgElem = await getHtmlImage(ctx.item.file.src)
  const kSnapshotImage = new Konva.Image({
    image: ctx.imgElem,
    width: snapshotWidth,
    height: snapshotHeight
  })
  kSnapshotLayer.add(kSnapshotImage)

  // add annotations
  const lines = [...ctx.item.localAnnotations, ...ctx.item.remoteAnnotations]

  lines.forEach(function(line) {
    const kLine = new Konva.Line({
      stroke: '#' + line.color,
      strokeWidth: 8,
      tension: 1,
      width: snapshotWidth,
      height: snapshotHeight,
      points: line.points,
      scale: { x: scaleFactor, y: scaleFactor }
    })

    kSnapshotLayer.add(kLine)
  })

  kDownloadStage.add(kSnapshotLayer)
  const uri = kDownloadStage.toDataURL()
  // Cleanup the stage
  kDownloadStage.destroy()
  return uri

  // let linkData = kDownloadStage.toDataURL({mimeType: 'image/jpeg'})

  // add dateTime metaData from when the snapshot was taken
  // let dateTaken = moment(snapshotImage.dateTaken).format('YYYY:MM:DD HH:MM:SS')

  // let zeroth = {}
  // let exif = {}
  // zeroth[piexif.ImageIFD.DateTime] = dateTaken
  // exif[piexif.ExifIFD.DateTimeOriginal] = dateTaken

  // let exifObj = {"0th":zeroth, "Exif":exif}
  // let exifString = piexif.dump(exifObj)
  // let linkData = piexif.insert(exifString, linkData)

  // Timestamp the file name and automatically download the image
  // let downloadDate = moment()
  // let link = document.createElement('a')
  // link.download = 'Skylight ' + downloadDate.format('YYYY-MM-DD') + ' at ' +
  //   downloadDate.format('hh.mm.ss.SSS A') + '.jpeg'
  // link.href = linkData
  // link.click()
}

export const initDrawer = (container, options) => {
  const { item } = options
  const maxWidth = container.clientWidth
  const maxHeight = container.clientHeight
  const stage = new Konva.Stage({
    container,
    width: maxWidth,
    height: maxHeight
  })

  const imageOverlay = new Konva.Image()
  const imageLayer = new Konva.Layer()
  imageLayer.add(imageOverlay)
  stage.add(imageLayer)

  const drawOverlay = new Konva.Rect(
    {
      width: 100,
      height: 100
    }
  )
  const drawLayer = new Konva.Layer()
  drawLayer.add(drawOverlay)
  stage.add(drawLayer)

  const ctx = {
    container,
    currentImageId: item.id,
    drawing: {
      currentLine: {},
      scaleFactor: 1,
      isDrawing: false,
      canDraw: false,
      lastPos: null,
      showingImage: false,
      offsetLeft: 0,
      offsetTop: 0,
      color: options.color,
      colors: [
        '#03a9f4', // paleBlue
        '#ffff00', // yellow
        '#f44336', // red
        '#ffffff', // white
        '#212121' // darkGrey
      ]
    },
    drawingColor: '#ff0000',
    drawOverlay,
    gestureMode: 'draw',
    lastPos: null,
    layers: {
      images: imageLayer,
      drawing: drawLayer
    },
    localAnnotations: [],
    imageOverlay,
    isDrawing: false,
    item,
    stage,
    offsetTop: 0,
    offsetLeft: 0,
    options,
    remoteAnnotations: [],
    zoom: 1
  }

  if (options.isEditable) {
    const handler = mouseHandler

    drawOverlay.on('mouseover', () => handler.over(ctx))
    drawOverlay.on('mousedown touchstart', () => handler.down(ctx))
    drawOverlay.on('mouseup touchend mouseleave mouseout', () => handler.up(ctx))
    drawOverlay.on('mousemove touchmove', () => handler.move(ctx))
    drawOverlay.on('wheel', e => {
      e.evt.preventDefault()
      const now = new Date()
      if (now - (ctx.wheelAt || 0) < 500) {
        // ctx.wheelAt = now
        return
      }
      ctx.wheelAt = now

      const delta = Math.max(-1, Math.min(1, (e.evt.wheelDelta || -e.evt.detail)))
      options.onZoom(ctx.zoom + delta)
    })
  }

  redraw(ctx)

  return {
    ctx,
    get item() {
      return ctx.item
    },
    set item(item) {
      const prev = ctx.item
      ctx.item = item
      redraw(ctx, prev)
    },
    getPreview: () => getPreview(ctx),
    redraw,
    get zoom() {
      return ctx.zoom
    },
    set zoom(newZoom) {
      if (newZoom !== ctx.zoom) {
        ctx.preventRedraw = false
        refreshCursor(ctx)
        zoom(ctx, newZoom)
      }
    }
  }
}
