import React from 'react'
import { ByList } from 'skylight-common'
import { APPLICATION_STATUSES } from '../../../../../../lib/nextGenApplication'

const OPTIONS = [
  { text: 'STATUS_ACTIVE', value: APPLICATION_STATUSES.ACTIVE },
  { text: 'STATUS_ARCHIVED', value: APPLICATION_STATUSES.ARCHIVED }
]

const getOptions = () => Promise.resolve(OPTIONS)

const getValue = originalValue => {
  return originalValue.filter(x => OPTIONS.some(o => o.value === x))
}

const ByStatus = props => {
  const value = getValue(props.value)

  return <ByList
    className="by-status"
    title="APPLICATION_STATUS"
    value={value}
    enableSorting={false}
    placeholder={{ id: 'NONE', defaultMessage: 'None' }}
    onChange={props.onChange}
    onRemove={props.onRemove}
    onGetItems={() => getOptions()} />
}

export { ByStatus }
