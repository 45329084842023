import React from 'react'
import classNames from 'classnames'
import { FieldControl, SelectWithSearch, CursorTooltip } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import DeleteIcon from 'material-ui/svg-icons/navigation/close'
import GroupIcon from '../../../../../Common/Icons/users/group/UserGroupExisting'
import GroupAddIcon from '../../../../../Common/Icons/users/group/UserGroupAdd'

import './Mappings.css'

export function GroupMapping({
  options = [],
  group = null,
  filter,
  onDelete,
  onChange = () => {},
  disabled
}) {
  const filterProps = {
    field: 'filter',
    name: 'filter',
    type: 'text',
    disabled: disabled || group === null
  }

  const Icon = disabled ? GroupAddIcon : GroupIcon

  const handleChange = (field, value) => {
    onChange({ name: group, filter, [field]: value })
  }

  return (
    <div className={classNames('mapping-row', { disabled })}>
      <div className="mapping-row__group-picker">
        <Icon className="row-icon" />
        <SelectWithSearch
          itemIcon={<GroupIcon />}
          value={group}
          onChange={handleChange}
          options={options}
          field="name"
          disabled={disabled} />
      </div>
      <div className="mapping-row__filter-picker">
        <FieldControl fieldProps={filterProps} value={filter} onChange={handleChange} />
        {onDelete &&
          <CursorTooltip
            className="mapping-row__delete-icon-tooltip"
            tooltip={<FormattedMessage id="ACTION_REMOVE" defaultMessage="Remove" />}>
            <DeleteIcon className="mapping-row__delete-icon" onTouchTap={onDelete} />
          </CursorTooltip>}
      </div>
    </div>
  )
}
