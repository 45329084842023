import React from 'react'
import { OutlinedField } from '../../../../../Common/Form/OutlinedField'
import { DEFAULT_DETAILS_PROPS } from './applicationDetailsProps'
import { FormattedMessage } from 'react-intl'
import get from 'lodash/get'

const ApplicationDetails = ({ item, ...props }) => {
  const itemProps = props.itemProps || DEFAULT_DETAILS_PROPS

  const renderItemProps = () => {
    return itemProps.map((prop, i) => {
      const fieldContent = prop.render ? prop.render(item) : get(item, prop.field)
      return <div key={i} className="item-prop-next-gen">
        <OutlinedField className={prop.className} floatingLabelText={<FormattedMessage id={prop.title} defaultMessage={prop.title} />} disabled>
          <div className="item-prop-body">{fieldContent}</div>
        </OutlinedField>
      </div>
    })
  }

  return (
    <div className="next-gen-details application-details">
      {renderItemProps()}
    </div>
  )
}

export { ApplicationDetails }
