import React from 'react'
import { getMediaType, MaterialIcon } from 'skylight-common'

const MediaIcon = (props) => {
  const { item } = props
  if (!item) {
    return null
  }

  const mediaType = getMediaType(item).split('/').shift()

  let icon = ''
  switch (mediaType) {
    case 'video':
      icon = 'movie_creation'
      break
    case 'image':
    default:
      icon = 'photo_size_select_actual'
      break
  }

  return <MaterialIcon className="skylight-icon" icon={icon} />
}

export { MediaIcon }
