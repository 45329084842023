import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { formatDateTime } from 'skylight-common'
import IconButton from 'material-ui/IconButton'
import RefreshIcon from 'material-ui/svg-icons/navigation/refresh'
import { Loader } from '../components'

const LastSyncedComp = props => {
  const formattedTime = props.value ? formatDateTime(props.value) : null

  const onTouchTap = () => {
    props.onLdapForceSync()
  }

  const renderMessage = () => {
    if (props.syncError) {
      return (
        <div className="ldap-force-sync-error">
          <FormattedMessage
            id="LDAPAPP_FORCE_SYNC_LOAD_ERROR"
            defaultMessage="An error occurred when trying to force LDAP sync. Please try again."/>
        </div>
      )
    }

    if (formattedTime) {
      return (
        <FormattedMessage
          id="LDAP_APP_LAST_SYNCED"
          defaultMessage="Last synced {value}"
          values={{ value: ` ${formattedTime}` }} />
      )
    }
  }

  if (props.isSyncLoaded) {
    return <Loader />
  }

  return (
    <div className="ldap-last-synced">
      {renderMessage()}
      <IconButton
        className="ldap-force-sync"
        tooltip={<FormattedMessage id="LDAP_FORCE_SYNC_NOW" defaultMessage="Sync now" />}
        onTouchTap={onTouchTap}
        disabled={props.hasChanged}>
        <RefreshIcon className="ldap-force-sync-button" />
      </IconButton>
    </div>
  )
}

LastSyncedComp.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  syncError: PropTypes.bool,
  isSyncLoaded: PropTypes.bool
}

export { LastSyncedComp }
