import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from 'material-ui/FlatButton'
import GroupIcon from 'material-ui/svg-icons/action/supervisor-account'
import CheckIcon from 'material-ui/svg-icons/navigation/check'

import { FormattedMessage } from 'react-intl'

import { Table, Column } from 'skylight-common'
import { DialogFooter, CircleIcon } from './dialogParts'

class GroupSelect extends Component {
  componentDidMount() {
    document.addEventListener('keydown', this.handleEnterKeyPress)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEnterKeyPress)
  }

  handleEnterKeyPress = event => {
    if (event.keyCode === 13) {
      this.props.onSave()
    }
  }

  render() {
    const { groups, selectedGroups } = this.props
    return (
      <div className="pick-groups-wrapper">
        <div className="selected-groups-wrapper">
          <div className="selected-item">
            <CircleIcon element={<CheckIcon />} text="Selected user(s):" active />
          </div>
          <div className="group-select-line" />
          <div className="selected-item">
            <CircleIcon element={2} text="Selected group(s):" active />
          </div>
        </div>
        <div className="table-wrapper">
          <Table
            showCheckboxes
            checkboxIcon={() => <GroupIcon />}
            showHover
            query={groups.query}
            items={this.props.items}
            total={groups.total}
            selectedRows={groups.selectedIds}
            name="addMultipleUsersToGroup"
            emptyLabel={<FormattedMessage id="NO_GROUPS_FOUND" defaultMessage="No groups found" />}
            onQuery={this.props.onGroupQuery}
            onRowsSelected={this.props.onGroupSelect}>
            <Column title={<FormattedMessage id="NAME" defaultMessage="Name" />} name="name" />
          </Table>
        </div>
        <div className="dialog-group-action-wrapper">
          <Button className="cancel-button" onClick={this.props.onCancel}>
            <FormattedMessage id="CANCEL" defaultMessage="Cancel" />
          </Button>
          <Button
            className={`continue-button ${selectedGroups.length < 1 ? 'disabled' : ''}`}
            onClick={this.props.onSave}>
            <FormattedMessage id="ADD" defaultMessage="Add" />
          </Button>
        </div>
        <DialogFooter isUserPick={this.props.isUserPick} />
      </div>
    )
  }
}

GroupSelect.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isUserPick: PropTypes.bool.isRequired,
  selectedGroups: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired
}

GroupSelect.defaultProps = {
  items: []
}

export { GroupSelect }
