import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const calendarToday = (props) => {
  return <SvgIcon {...props}>
    <path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z" />
  </SvgIcon>
}

export default calendarToday
