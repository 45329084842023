import React from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { SelectField, MenuItem } from 'material-ui'
import { Form, RequiredLabel, CursorTooltip } from 'skylight-common'
import { FooterActionButtons } from '../../../Common'
import { SUPPORTED_LOCALES } from '../../../../svc/locale'
import skylight from 'skylight-js-sdk'
import './AdminLanguage.css'

class AdminLanguage extends React.Component {
  state = {
    original: null,
    realm: null,
    error: null
  }

  async componentDidMount() {
    try {
      const realmName = this.props.user.realm ||
        skylight.auth.getRealmFromToken(skylight.auth.accessToken)
      const realm = await skylight.domain.getByName(realmName)
      realm.locale = realm.locale || null
      this.setState({ realm, original: realm })
    } catch (error) {
      this.setState({ error })
      this.props.onAdminLanguageError(error)
    }
  }

  componentWillUnmount() {
    this.setState = () => {}
  }

  onChange(locale) {
    const realm = { ...this.state.realm, locale }
    this.setState({ realm, error: null })
  }

  onSave = async() => {
    try {
      await skylight.domain.save(this.state.realm)
      const realmName = this.props.user.realm ||
        skylight.auth.getRealmFromToken(skylight.auth.accessToken)
      const realm = await skylight.domain.getByName(realmName)
      realm.locale = realm.locale || null
      this.setState({ realm, original: realm })
      this.props.onSwitchLocaleForUser(this.props.user)
    } catch (error) {
      this.setState({ error })
      this.props.onAdminLanguageError(error)
    }
  }

  onCancel = () => {
    this.setState({ error: null, realm: this.state.original })
  }

  render() {
    const hasChanged = this.state.realm && this.state.realm.locale !== this.state.original.locale
    const selectedLocale = (this.state.realm && this.state.realm.locale) || 'en'
    const localeTitle = SUPPORTED_LOCALES.find(x => x.value === selectedLocale).title

    return <div className={classNames('admin-language', this.props.className)}>
      <Form
        hasChanged={hasChanged}
        onSubmit={() => hasChanged && this.onSave()}
        onEscapePress={() => hasChanged && this.onCancel()}>
        <div className="title">
          <FormattedMessage id="LANGUAGE" defaultMessage="Language" />
        </div>
        <div className="description">
          <FormattedMessage
            id="ADMIN_LANGUAGE_DESCRIPTION"
            defaultMessage="Choose the default language for users on the domain." />
        </div>
        <div className="picker">
          <CursorTooltip tooltip={this.props.withTooltip ? <span>{localeTitle}</span> : null}>
            <this.props.selectFieldComp
              className="select dropdown-with-hover"
              floatingLabelText={<RequiredLabel><FormattedMessage id="ADMIN_LANGUAGE_TITLE" /></RequiredLabel>}
              value={selectedLocale}
              selectedMenuItemStyle={{ color: '#00aaed' }}
              onChange={(e, k, v) => this.onChange(v)}>
              {SUPPORTED_LOCALES.map(x => (
                <MenuItem key={x.value} value={x.value} primaryText={x.title} className="language-item">
                  <CursorTooltip key={x.value} tooltip={this.props.withTooltip ? <span>{x.title}</span> : null}>
                    {x.title}
                  </CursorTooltip>
                </MenuItem>
              ))}
            </this.props.selectFieldComp>
          </CursorTooltip>

          {!!this.state.error && <div className="error">
            <FormattedMessage id="ADMIN_LANGUAGE_ERROR" defaultMessage="Error" />
                                 </div>}
        </div>

        {hasChanged && <FooterActionButtons
          onCancel={this.onCancel}
          onSave={this.onSave} />}
      </Form>
           </div>
  }
}

AdminLanguage.defaultProps = {
  selectFieldComp: SelectField,
  withTooltip: false
}

export { AdminLanguage }
