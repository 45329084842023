import React from 'react'
import { FormattedMessage } from 'react-intl'

import './SectionTitle.css'

const SectionTitle = ({ title, titleAction = null }) => {
  return (
    <div className="field-control">
      <div className="field-section">
        <FormattedMessage id={title} defaultMessage={title} />
        {titleAction}
      </div>
    </div>
  )
}

export { SectionTitle }
