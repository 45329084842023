import React from 'react'
import { FormattedMessage } from 'react-intl'
import CheckIcon from 'material-ui/svg-icons/navigation/check'
import UncheckIcon from 'material-ui/svg-icons/toggle/check-box-outline-blank'
import NoItemIcon from '../../../../Common/Icons/noitem'
import { DecisionMedia } from '../../../../../containers/assignment'
import './DecisionTile.css'

const DecisionTile = props => {
  const { item } = props

  const renderIcon = c => {
    const Icon = c.selected ? CheckIcon : UncheckIcon

    const className = [
      'decision-tile-icon',
      c.selected ? 'decision-tile-icon-check' : 'decision-tile-icon-uncheck'
    ].join(' ')

    return <Icon className={className} />
  }

  const renderSelected = () => {
    if (item.choices.length === 0) {
      return (
        <div className="decision-tile-choice decision-tile-choice-empty">
          <NoItemIcon />
          <FormattedMessage id="NO_ANSWER" defaultMessage="No answer set" />
        </div>
      )
    }

    return item.choices.map((c, i) => (
      <div key={i} className={`decision-tile-choice ${c.selected ? 'check' : 'uncheck'}`}>
        {renderIcon(c)}
        <div className="decision-tile-answer">{c.label}</div>
      </div>
    ))
  }

  return (
    <div className="assignment-decision-tile">
      <div className="decision-tile-label">{item.label}</div>
      <div className="decision-tile-choices">
        {renderSelected()}
      </div>
      <DecisionMedia {...props} />
    </div>
  )
}

export { DecisionTile }
