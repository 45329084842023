import React from 'react'
import { FormattedMessage } from 'react-intl'
import MenuItem from 'material-ui/MenuItem'
import { DeleteIcon, DetailsMenu } from 'skylight-common'
import './TokenActionMenu.css'

const TokenActionMenu = (props) => {
  const { item } = props.item

  const onDelete = () => {
    props.onConfirm(
      () => props.onApiTokenDeleteItems([item.id]),
      {
        title: 'TOKEN_REMOVE_DIALOG_TITLE',
        message: 'TOKEN_REMOVE_DIALOG_DESCRIPTION'
      }
    )
  }

  return (
    <DetailsMenu className="token-action-menu">
      <MenuItem
        primaryText={<FormattedMessage id="TOKEN_REMOVE_ACTION" defaultMessage="Remove" />}
        leftIcon={<DeleteIcon />}
        onTouchTap={onDelete} />
    </DetailsMenu>
  )
}

export { TokenActionMenu }
