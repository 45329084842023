import skylight from 'skylight-js-sdk'
import { DATA_TYPES } from '../lib/assignment'

/** Gets associated workflow details */
export const getAssignmentDetails = (id) => skylight.assignment.getById(id)

const getFilters = (query) => ({
  byAssignedTo: a => !query.filter.assignedTo || !query.filter.assignedTo.length ||
    query.filter.assignedTo.includes(a.assignedTo),
  byAssignedBy: a => !query.filter.assignedBy || !query.filter.assignedBy.length ||
    query.filter.assignedBy.includes(a.createdBy),
  byAssignmentId: a => !query.filter.assignmentId || query.filter.assignmentId.includes(a.id),
  byName: a => !query.filter.name || !query.filter.name.length ||
    query.filter.name.some(n => a.workflowId === n),
  byTitle: a => !query.filter.title ||
    (a.name && a.name.toLowerCase().includes(query.filter.title.trim().toLowerCase())),
  byStatus: a => {
    const status = query.filter.status
    if (!status || status.length === 0) {
      return true
    }

    if (a.isComplete && status.includes('completed')) {
      return true
    }

    if (!a.isTask) {
      if (a.archived && !status.includes('archived')) {
        return false
      }

      if (!a.archived && !status.includes('assigned')) {
        return false
      }
    }

    if (a.isTask && !status.includes('error') && a.status === 'error') {
      return false
    }

    if (a.isTask && !status.includes('inProgress') && a.status === 'inProgress') {
      return false
    }

    return true
  }
})

const getFilter = (query) => {
  const filters = getFilters(query)
  const filterList = Object.keys(filters).map(x => filters[x])
  return item => {
    for (let i = 0; i < filterList.length; i++) {
      if (!filterList[i](item)) {
        return false
      }
    }

    return true
  }
}

export const getMyAssignments = async(query) => {
  const includeArchived = !query.filter.status || query.filter.status.includes('archived')
  const includeCompleted = !query.filter.status || query.filter.status.includes('completed')
  const filter = getFilter(query)
  const assignments = await skylight.assignment.list({
    archived: includeArchived,
    completed: includeCompleted
  })
  return assignments.filter(filter)
}

export const assignWorkflow = (workflow, userId) => {
  const { fade, workflowId, description, integrationId, name, title } = workflow
  const apiWorkflow = {
    description,
    fade,
    workflowId,
    integrationId,
    assignedTo: userId,
    name: name || title
  }
  return skylight.workflow.v2.assign(workflowId, userId, apiWorkflow)
}

/** Archives a workflow assignment */
export const archiveAssignment = (id) => {
  return skylight.assignment.archiveById(id)
}

/** Deletes a workflow assignment */
export const deleteAssignment = (id) => {
  return skylight.assignment.deleteById(id)
}

/** Removes a workflow task */
export const removeTask = (id) => {
  return skylight.workflow.v2.task.deleteById(id)
}

const WF_SORT_FIELDS = {
  created: 'createdAt',
  updated: 'modifiedAt'
}

export const getWorkflowTasks = async query => {
  const workflowQuery = { limit: query.limit }
  if (query.sortBy) {
    // See `getAssignmentFromTask` below. Keep name field consistency with assignments.
    const sortBy = WF_SORT_FIELDS[query.sortBy] || query.sortBy
    workflowQuery.sort = `${query.sortAsc ? '' : '-'}${sortBy}`
  }

  if (query.nextCursor) {
    workflowQuery.nextCursor = query.nextCursor
  }

  const tasks = await skylight.workflow.v2.task.list(workflowQuery)

  return {
    data: tasks.data.filter(x => x.status !== 'complete').filter(x => x.taskId),
    page: tasks.page
  }
}

const getAssignmentFromTask = (task) => {
  const assignment = {
    name: task.name,
    created: new Date(task.createdAt).toISOString(),
    updated: new Date(task.modifiedAt).toISOString() /* consistent sorting */,
    isTask: true,
    error: task.error,
    id: task.taskId,
    status: task.status,
    createdBy: task.modifiedBy,
    task
  }

  if (assignment.status === 'queued') {
    assignment.status = 'inProgress'
    assignment.task.status = 'inProgress'
  }

  return assignment
}

export const getAssignmentById = (id) => {
  return skylight.assignment.getById(id)
}

const filterById = (query) => {
  const filter = getFilter(query)

  const assignmentIds = query.filter.assignmentId.split(',')
  return Promise.all(assignmentIds.map(getAssignmentById))
    .then(assignments => assignments.filter(filter))
}

export const getAssignments = async(query) => {
  if (query.filter.assignmentId) {
    return filterById(query)
  }

  const { isAdmin } = query.params || {}
  const { assignedTo } = query.filter
  const hasAssignedTo = assignedTo && assignedTo.length > 0

  let userIds = []

  const allRealm = !!isAdmin && !hasAssignedTo
  if (!allRealm) {
    userIds = hasAssignedTo
      ? assignedTo
      : (await skylight.user.getCachedUsers()).map(u => u.id)
  }

  const includeArchived = !query.filter.status ||
    query.filter.status.length === 0 || query.filter.status.includes('archived')
  const includeCompleted = !query.filter.status ||
    query.filter.status.length === 0 || query.filter.status.includes('completed')
  const listQuery = {
    allRealm,
    archived: includeArchived,
    completed: includeCompleted,
    userIds
  }

  return await skylight.assignment.list(listQuery)
}

export const getTeamAssignments = (query) => {
  const filter = getFilter(query)
  return getAssignments(query)
    .then(assignments => assignments.filter(filter))
    .catch(x => {
      console.log('team assignments error ', x)
      return []
    })
}

export const getPendingAssignments = async query => {
  const filter = getFilter(query)
  return await getWorkflowTasks(query)
    .then(tasks => ({ data: tasks.data.map(getAssignmentFromTask).filter(filter), page: tasks.page }))
    .catch(x => {
      console.log('error ', x)
      return { data: [] }
    })
}

export const getAssignmentCards = async(id, query) => {
  if (!query) {
    query = { type: DATA_TYPES }
  }
  const cardRes = await skylight.assignment.getCardsById(id, query)
  return cardRes.cards
}

export const updateAssignmentCard = async card => {
  return skylight.assignment.card.update(card.assignmentId, card.sequenceId, card)
}
