import React from 'react'
import { FormattedMessage } from 'react-intl'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import { SESSION_EVENT_VIEW_JSON } from '../dialogTypes'
import CopyContent from 'material-ui/svg-icons/content/content-copy'
import IconButton from 'material-ui/IconButton'
import _ from 'lodash'

import './SessionEventJsonDataDialog.css'

const COPIED_TIMEOUT = 5000

class SessionEventJsonDataDialog extends React.Component {
  state = { isCopied: false }
  textAreaRef = null

  formatEventData = () => {
    const { params: event } = this.props.dialog[this.props.dialogType] || {}
    const eventClone = _.cloneDeep(event)
    delete eventClone.mediaItemInfo
    if (eventClone.device) {
      delete eventClone.device.device.formattedName
    }

    return JSON.stringify(eventClone, undefined, 2)
  }

  formattedEventData = this.formatEventData()
  lineNumbers = this.formattedEventData.split('\n').map((_line, i) => i + 1).join(' \n')

  onCopyToClipboard = () => {
    const clipboardListener = e => {
      e.clipboardData.setData('text/plain', this.formattedEventData)
      e.preventDefault()
    }

    this.executeCopy(clipboardListener)

    this.setState({ isCopied: true })

    clearTimeout(this.copyTimeout)

    this.copyTimeout = setTimeout(() => {
      this.setState({ isCopied: false })
    }, COPIED_TIMEOUT)
  }

  executeCopy = clipboardListener => {
    document.addEventListener('copy', clipboardListener)
    document.execCommand('copy')
    document.removeEventListener('copy', clipboardListener)
  }

  onClose = () => this.props.onHideDialog(SESSION_EVENT_VIEW_JSON)

  renderDialogTitle = () => {
    return (
      <div className="session-event-dialog-title">
        <FormattedMessage className="dialog-title" id="EVENT_DETAILS" defaultMessage="Event details" />
      </div>
    )
  }

  setContentRef = ref => {
    return (this.textAreaRef = ref)
  }

  render() {
    const { open, params: token } = this.props.dialog[this.props.dialogType] || {}

    if (!token) {
      return null
    }
    const dialogActions = [
      <FlatButton
        key="cancel"
        className="cancel-action"
        label={<FormattedMessage id="CLOSE" defaultMessage="Close" />}
        onTouchTap={this.onClose} />
    ]

    return (
      <div className="session-event-data-wrapper">
        <Dialog
          title={this.renderDialogTitle()}
          actions={dialogActions}
          modal={false}
          open={!!open}
          className="dialog-modal session-event-data-dialog"
          bodyClassName="dialog-modal-body"
          autoDetectWindowHeight={false}
          onRequestClose={this.onClose}>
          <div className="session-event-data-content">
            <div className="event-data">
              <IconButton
                className="show-copy-content-button"
                tooltip={<FormattedMessage id={this.state.isCopied ? 'COPIED' : 'COPY_JSON'} default="Copy JSON" />}
                tooltipPosition="bottom-left"
                onTouchTap={this.onCopyToClipboard}>
                <CopyContent />
              </IconButton>
              <div className="event-data-container">
                <pre className="event-data-line-numbers">
                  {this.lineNumbers}
                </pre>
                <pre
                  ref={this.setContentRef}
                  readOnly
                  className="event-data-json">
                  {this.formattedEventData}
                </pre>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

export { SessionEventJsonDataDialog }
