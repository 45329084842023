import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Menu } from '../Menu'
import classNames from 'classnames'

import NextGenApplicationIcon from '../../../Common/Icons/nextGenApplication'
import ApplicationIcon from '../../../Common/Icons/applications'
import { isNextGenUrl } from '../../../../lib/nextGenApplication'

import './SwitchPortalVersionMenu.css'

const SwitchPortalVersionMenu = props => {
  const nextGenUrl = isNextGenUrl()
  const ACTIONS = [
    {
      title: <FormattedMessage id="LEGACY_APPLICATIONS" defaultMessage="Legacy Applications" />,
      handler: () => { props.history.push('/') },
      leftIcon: <ApplicationIcon />,
      isActive: !nextGenUrl
    },
    {
      title: <FormattedMessage id="NEXT_GEN_APPLICATIONS" defaultMessage="Next-Gen Applications" />,
      handler: () => { props.history.push('/ng') },
      leftIcon: <NextGenApplicationIcon />,
      isActive: nextGenUrl
    }
  ]

  return (
    <Menu
      {...props}
      className={classNames('switch-portal-version-menu', props.className)}
      width={props.width}
      actions={ACTIONS}>
      <div className="switch-portal-version-menu-title">
        <FormattedMessage id="SWITCH_TO" defaultMessage="Switch to" />
      </div>
    </Menu>
  )
}

export { SwitchPortalVersionMenu }
