import * as ActionTypes from '../actions/types'
import * as dialogTypes from '../App/Home/Layout/Dialogs/dialogTypes'
import { getSsoToken, ssoData } from '../svc/login'
import { showError } from './error'
import { CONFIG, actions } from 'skylight-common'
import netscanActions from './netscan'

import skylight from 'skylight-js-sdk'
import nextGenApplicationActions from './nextGenApplication'

export const showDialog = (dialogType, params = null) => ({
  type: actions.types.DIALOG_SHOW,
  dialogType,
  params
})

export const hideDialog = (dialogType) => ({
  type: actions.types.DIALOG_HIDE,
  dialogType
})

const onLoadToken = (ssoToken, dispatch) => {
  const token = JSON.stringify({
    // Backwards compatibility with Android client..
    apiUrl: skylight.utils.resolveUrl(CONFIG.apiUrl, '/v1'),
    wsUrl: CONFIG.socketUrl,
    mqttUrl: CONFIG.mqttUrl,
    webUrl: CONFIG.webUrl,
    ssoToken: ssoToken
  })

  dispatch({
    type: ActionTypes.DEVICE_TOKEN_LOADED,
    token
  })
}

export const showAddDeviceDialog = (auth, params) => {
  return dispatch => {
    if (!ssoData) {
      dispatch(showVerifyCredentialsDialog())
      dispatch(showError('SESSION_EXPIRED'))
      return
    }
    // add extra request to check remote signout
    skylight.user.getById(skylight.auth.user.id)
      .then(() => {
        dispatch({ type: actions.types.DIALOG_SHOW, dialogType: dialogTypes.ADD_DEVICE, params })
        getSsoToken(ssoData)
          .then(token => onLoadToken(token, dispatch))
          .catch((e) => {
            console.error('getSsoToken', e)
            dispatch({ type: ActionTypes.DEVICE_TOKEN_LOAD_ERROR, error: 'DEVICE_TOKEN_LOAD_ERROR' })
          })
      })
  }
}

export const showNewMediaDialog = () => ({
  type: actions.types.DIALOG_SHOW,
  dialogType: dialogTypes.ADD_MEDIA
})

export const browserPermissions = () => ({
  type: actions.types.DIALOG_SHOW,
  dialogType: dialogTypes.ENABLE_PERMISSIONS
})

export const showVersionDialog = () => ({
  type: actions.types.DIALOG_SHOW,
  dialogType: dialogTypes.VERSION
})

export const assignApplication = params => ({
  type: actions.types.DIALOG_SHOW,
  dialogType: dialogTypes.ASSIGN_APPLICATION,
  params
})

const newAssignment = (user) => ({
  type: actions.types.DIALOG_SHOW,
  dialogType: dialogTypes.ADD_ASSIGNMENT,
  params: { user }
})

const onNetScanDialog = () => dispatch => {
  dispatch(netscanActions.onLoad())
  dispatch({
    type: ActionTypes.DIALOG_SHOW,
    dialogType: dialogTypes.NET_SCAN
  })
}

const showAddToExistingGroupDialog = () => dispatch => {
  dispatch({
    type: ActionTypes.DIALOG_SHOW,
    dialogType: dialogTypes.ADD_TO_EXISTING_GROUP
  })
}

const showSetupWifiDialog = () => ({
  type: ActionTypes.DIALOG_SHOW,
  dialogType: dialogTypes.SETUP_WIFI
})

const showResetPasswordDialog = () => ({
  type: ActionTypes.DIALOG_SHOW,
  dialogType: dialogTypes.RESET_PASSWORD
})

const showDuplicateAppDialog = item => ({
  type: ActionTypes.DIALOG_SHOW,
  dialogType: dialogTypes.DUPLICATE_APPLICATION,
  params: { item, value: `${item.name} Copy` }
})

const showVerifyCredentialsDialog = () => ({
  type: ActionTypes.DIALOG_SHOW,
  dialogType: dialogTypes.VERIFY_CREDENTIALS
})

const showSetupNewApplicationDialog = () => dispatch => {
  dispatch(nextGenApplicationActions.onUpdateCreateAppTemplate(null))
  dispatch(nextGenApplicationActions.onUpdateCreateAppStep(1))
  dispatch({
    type: ActionTypes.DIALOG_SHOW,
    dialogType: dialogTypes.SETUP_NEW_APPLICATION
  })
}

const hideSetupNewApplicationDialog = () => dispatch => {
  dispatch({
    type: ActionTypes.DIALOG_HIDE,
    dialogType: dialogTypes.SETUP_NEW_APPLICATION
  })
}

const showPublishVersionDialog = (version, versions) => ({
  type: ActionTypes.DIALOG_SHOW,
  dialogType: dialogTypes.PUBLISH_VERSION,
  params: { version, versions }
})

const showSessionEventViewJsonDialog = event => ({
  type: ActionTypes.DIALOG_SHOW,
  dialogType: dialogTypes.SESSION_EVENT_VIEW_JSON,
  params: { ...event }
})

const showAddApplicationParticipantDialog = () => ({
  type: ActionTypes.DIALOG_SHOW,
  dialogType: dialogTypes.ADD_APPLICATION_PARTICIPANTS
})

const showAddApplicationRoleDialog = () => ({
  type: ActionTypes.DIALOG_SHOW,
  dialogType: dialogTypes.ADD_APPLICATION_ROLE
})

const showAccountSettingsDialog = () => ({
  type: ActionTypes.DIALOG_SHOW,
  dialogType: dialogTypes.ACCOUNT_SETTINGS
})

const showNewUserDialog = () => ({
  type: ActionTypes.DIALOG_SHOW,
  dialogType: dialogTypes.NEW_USER
})

const showImportArchiveUploadDialog = () => ({
  type: ActionTypes.DIALOG_SHOW,
  dialogType: dialogTypes.IMPORT_ARCHIVE_UPLOAD
})

const hideImportArchiveUploadDialog = () => hideDialog(dialogTypes.IMPORT_ARCHIVE_UPLOAD)

const showDownloadMediaConfirmationDialog = mediaItem => ({
  type: ActionTypes.DIALOG_SHOW,
  dialogType: dialogTypes.MEDIA_DOWNLOAD_CONFIRMATION,
  params: { mediaItem }
})

const showQueryFilterDialog = () => ({
  type: ActionTypes.DIALOG_SHOW,
  dialogType: dialogTypes.QUERY_FILTER_CONFIGURATION
})

const showUserAttributesDialog = () => ({
  type: ActionTypes.DIALOG_SHOW,
  dialogType: dialogTypes.USER_ATTRIBUTES
})

export const showArchiveAppConfirmation = () => showDialog(dialogTypes.ARCHIVE_APP_CONFIRMATION)
export const hideArchiveAppConfirmation = () => hideDialog(dialogTypes.ARCHIVE_APP_CONFIRMATION)
export const showUpgradeDialog = () => showDialog(dialogTypes.APP_UPGRADE)
export const hideUpgradeDialog = () => hideDialog(dialogTypes.APP_UPGRADE)
export const showUpgradeUploadDialog = () => showDialog(dialogTypes.APP_UPGRADE_UPLOAD)
export const hideUpgradeUploadDialog = () => hideDialog(dialogTypes.APP_UPGRADE_UPLOAD)

export default {
  ...actions.dialog,
  onShowAddDeviceDialog: showAddDeviceDialog,
  onBrowserPermissions: browserPermissions,
  onShowNewMediaDialog: showNewMediaDialog,
  onShowNewAssignmentDialog: newAssignment,
  onShowAddTokenDialog: (query) => showDialog(dialogTypes.ADD_TOKEN, query),
  onShowTokenCredentials: (token) => showDialog(dialogTypes.SHOW_TOKEN_CREDENTIALS, token),
  onShowVersionDialog: showVersionDialog,
  onAssignApplication: assignApplication,
  onNetScanDialog,
  onShowAddToExistingGroupDialog: showAddToExistingGroupDialog,
  onShowSetupWifiDialog: showSetupWifiDialog,
  onShowResetPasswordDialog: showResetPasswordDialog,
  onShowDuplicateAppDialog: showDuplicateAppDialog,
  showSetupNewApplicationDialog,
  hideSetupNewApplicationDialog,
  showPublishVersionDialog,
  showSessionEventViewJsonDialog,
  showAddApplicationRoleDialog,
  showAddApplicationParticipantDialog,
  showAccountSettingsDialog,
  showNewUserDialog,
  showImportArchiveUploadDialog,
  hideImportArchiveUploadDialog,
  showDownloadMediaConfirmationDialog,
  showQueryFilterDialog,
  showUserAttributesDialog,
  showUpgradeUploadDialog,
  hideUpgradeUploadDialog
}
