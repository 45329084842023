import React from 'react'
import PropTypes from 'prop-types'
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton'
import NewIcon from 'material-ui/svg-icons/content/add-box'
import { ActionButton } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import { LocalServiceForm } from './LocalServiceForm'

import './ServerSection.css'

const onChangeIceServer = (props, index, prop, val) => {
  // Build new array of iceServers upon a change to any field on any iceServer.
  const newIceServers = props.form.iceServers.slice()
  newIceServers[index][prop] = val
  props.onChange('iceServers', newIceServers)
}

const onRemoveIceServer = (props, index) => {
  // Confirm before removing specified iceServer
  props.onConfirm(
    () => {
      const newIceServers = props.form.iceServers.slice()
      newIceServers.splice(index, 1)
      const newConfig = { ...props.form, iceServers: newIceServers }
      props.onSave(newConfig)
      props.onLoad()
    },
    {
      title: 'CONFIRM_TITLE',
      message: 'ICE_SERVER_REMOVE_DIALOG_DESCRIPTION'
    }
  )
}

const onAddIceServer = props => {
  const newIceServer = props.fields.reduce((obj, item) => {
    obj[item.prop] = ''
    return obj
  }, {})

  // Add a new ice server to the beginning of the list
  const newIceServers = [newIceServer, ...props.form.iceServers]

  props.onChange('iceServers', newIceServers)
}

const getIceServers = props => {
  return props.form.iceServers.map((iceServer, index) => {
    const theKey = 'iceServer' + index

    return (
      <LocalServiceForm
        key={theKey}
        iceServerIndex={index}
        values={iceServer}
        fields={props.fields}
        errors={props.errors}
        onRemove={() => {
          return onRemoveIceServer(props, index)
        }}
        onChange={(prop, val) => {
          return onChangeIceServer(props, index, prop, val)
        }}/>
    )
  })
}

const CONFIG_NAMES = {
  TWILIO: 'twilio',
  LOCAL: 'local',
  NONE: 'none'
}

const hasFakeDefaults = servers => {
  return servers.some(server => server.url === FAKE_DEFAULTS.url)
}

const FIELDS = [
  {
    value: CONFIG_NAMES.TWILIO,
    label: { id: 'ADMIN_LIVE_TWILIO', defaultMessage: 'Twilio Global Network Traversal Service' }
  },
  { value: CONFIG_NAMES.LOCAL, label: { id: 'ADMIN_LIVE_LOCAL', defaultMessage: 'Local CoTURN Service' } },
  { value: CONFIG_NAMES.NONE, label: { id: 'ADMIN_LIVE_NONE', defaultMessage: 'None' } }
]

const FAKE_DEFAULTS = { url: 'stun:disabled', credential: '', username: '' }

const mapConfigValues = {
  [CONFIG_NAMES.TWILIO]: false,
  [CONFIG_NAMES.LOCAL]: true,
  [CONFIG_NAMES.NONE]: true
}

const ServerSection = props => {
  const renderRadioButtons = () => {
    return FIELDS.map(x => {
      return (
        <RadioButton
          key={x.value}
          value={x.value}
          label={<FormattedMessage id={x.label.id} defaultMessage={x.label.defaultMessage} />}/>
      )
    })
  }

  const onChange = (e, value) => {
    let newIceServers = props.original.iceServers.slice()
    if (value === CONFIG_NAMES.NONE && !hasFakeDefaults(props.form.iceServers)) {
      // Use api values to prevent save with invalid items.
      newIceServers = [...props.original.iceServers, FAKE_DEFAULTS]
    } else if (value === CONFIG_NAMES.LOCAL && hasFakeDefaults(props.form.iceServers)) {
      newIceServers = props.form.iceServers.filter(server => server.url !== FAKE_DEFAULTS.url)
    }

    props.onChange('iceServers', newIceServers, 'form')
    props.onChange(e.target.name, mapConfigValues[value], 'form')
  }

  const getActiveButton = form => {
    if (!form.enabled) {
      return CONFIG_NAMES.TWILIO
    }

    if (form.enabled && hasFakeDefaults(form.iceServers)) {
      return CONFIG_NAMES.NONE
    }

    if (form.enabled && !hasFakeDefaults(form.iceServers)) {
      return CONFIG_NAMES.LOCAL
    }
  }

  return (
    <div className="server-section">
      <div className="title">
        <FormattedMessage id="ADMIN_LIVE_TITLE" defaultMessage="STUN/TURN Server Configuration" />
      </div>
      <div className="config-radio-group-container">
        <RadioButtonGroup
          className="admin-radio-group config-radio-group"
          name="enabled"
          valueSelected={getActiveButton(props.form)}
          defaultSelected={getActiveButton(props.form)}
          onChange={onChange}>
          {renderRadioButtons()}
        </RadioButtonGroup>
        <div className="add-server-container">
          {props.form.enabled && !hasFakeDefaults(props.form.iceServers) && (
            <ActionButton
              icon={<NewIcon />}
              className="add-server-button"
              label={<FormattedMessage id="ADD_SERVER" defaultMessage="Add additional server" />}
              onTouchTap={() => {
                onAddIceServer(props)
              }}/>
          )}
        </div>
      </div>
      {props.form.enabled && !hasFakeDefaults(props.form.iceServers) && getIceServers(props)}
    </div>
  )
}

ServerSection.propTypes = {
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

export { ServerSection }
