import * as ActionTypes from './types'
import { getMediaItemUrl, validatePassword } from 'skylight-common'
import skylight from 'skylight-js-sdk'
import {
  ssoData,
  setSsoData,
  getRealm,
  GENERIC_PASSWORD_REQUIREMENTS_NAMES,
  REQUIRED_PASSWORD_FIELDS
} from '../svc/login'
import { showError } from './error'
import { replace } from 'react-router-redux'
import snackbar from './snackbar'
import jwtDecode from 'jwt-decode'
import loginActions, { signin as signinAction } from './login'
import appActions from './app'

import * as dialogTypes from '../App/Home/Layout/Dialogs/dialogTypes'

import { history } from '../containers'

const snackbarError = { className: 'snackbar-common-error' }
const invalidPassword = 'INVALID_PWD_MESSAGE'

export const profileFieldChanged = (name, value, formName) => ({
  type: ActionTypes.PROFILE_FIELD_CHANGED,
  name,
  value,
  formName
})

export const passwordFormClear = () => ({
  type: ActionTypes.CLEAR_RESET_PASSWORD_FORM
})

/**
 * Shows error message for is/not temporary password
 * @param {errors}
 * @param {temporary} - if password reset type is 'temporary'
 */
export const showPasswordResetError = errors => dispatch => {
  // To show errors in form fields instead snackbar, use other action type
  // cause action types with _ERROR in it automatically intercepted by middleware
  // and displayed in the snackbar with a text associated to it
  dispatch({
    type: ActionTypes.PROFILE_PASSWORD_RESET_ERROR,
    className: 'snackbar-common-error',
    errors
  })
}

export const showProfileUpdateError = errors => dispatch => {
  dispatch({
    type: ActionTypes.PROFILE_UPDATE_ERROR,
    className: 'snackbar-common-error',
    errors
  })
}

export const showPasswordResetSuccess = () => dispatch => {
  dispatch(showError(ActionTypes.PROFILE_PASSWORD_HAD_RESET, { className: 'snackbar-common-success' }))
}

export const uploadedProfileImage = (result) => (dispatch) => {
  dispatch({ type: ActionTypes.MEDIA_IMAGE_UPLOAD })

  if (result.id) {
    const image = getMediaItemUrl(result.id)
    dispatch({ type: ActionTypes.MEDIA_IMAGE_UPLOADED, image })
    dispatch({
      type: ActionTypes.PROFILE_FIELD_CHANGED,
      name: 'avatar',
      value: result.id,
      formName: 'form'
    })
  } else {
    dispatch({ type: ActionTypes.MEDIA_IMAGE_UPLOAD_ERROR, error: result.error })
  }
}

export const cancelProfileUpdate = () => ({
  type: ActionTypes.PROFILE_UPDATE_CANCEL
})

const getProfileFormValidationErrors = form => {
  const validationFields = {
    email: [
      {
        isValid: value => !!value,
        shouldValidate: form => form.role === 'admin',
        errorMessage: { id: 'EMAIL_INVALID_BLANK', defaultMessage: 'Email cannot be blank' }
      },
      {
        isValid: value => {
          const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return regexp.test(String(value).toLowerCase())
        },
        shouldValidate: form => !!form.email,
        errorMessage: { id: 'EMAIL_INVALID_FORMAT', defaultMessage: 'Invalid email format' }
      }
    ]
  }

  return Object.keys(validationFields).reduce((acc, fieldName) => {
    const fieldValidators = validationFields[fieldName]
    const value = form[fieldName]
    const failedValidator = fieldValidators.find(validator => {
      if (validator.shouldValidate && validator.shouldValidate(form)) {
        return !validator.isValid(value)
      }

      return false
    })

    if (failedValidator) {
      acc[fieldName] = failedValidator.errorMessage
    }

    return acc
  }, {})
}

const validateProfileForm = form => {
  const validationErrors = getProfileFormValidationErrors(form)
  const isValid = !Object.keys(validationErrors).length

  return {
    valid: isValid,
    validationErrors
  }
}

export const profileUpdate = (form, id) => async(dispatch) => {
  const validationResult = validateProfileForm(form)
  if (!validationResult.valid) {
    dispatch(showProfileUpdateError(validationResult.validationErrors, snackbarError))
    return
  }
  dispatch({ type: ActionTypes.PROFILE_UPDATE })
  form = { ...form, language: form.locale }
  try {
    await skylight.user.save(form)
    dispatch({ type: ActionTypes.PROFILE_UPDATED, user: form })
    if (form.locale) {
      skylight.storage.setItem('locale', form.locale)
    }
  } catch (e) {
    dispatch({
      type: ActionTypes.PROFILE_UPDATE_ERROR,
      errors: { serverError: e.message }
    })
  }
}

const changePassword = async(form, ctx) => {
  await skylight.user.resetPassword(form.oldPassword, form.newPassword, form.captchaToken)
  ctx.dispatch({ type: ActionTypes.DIALOG_HIDE, dialogType: dialogTypes.RESET_PASSWORD })

  // Show success message in snackbar
  ctx.dispatch(showPasswordResetSuccess())
}

const changeTempPassword = async(form, ctx) => {
  const { signin, realm } = ctx.getState().login
  const { username } = signin
  const { config } = realm

  await skylight.user.resetTemporaryPassword(form.oldPassword, form.newPassword, username, realm.realm, form.captchaToken)
  ctx.dispatch({ type: ActionTypes.DIALOG_HIDE, dialogType: dialogTypes.CHANGE_PASSWORD })
  ctx.dispatch(signinAction(
    {
      ...signin,
      password: form.newPassword,
      location: history.location,
      url: config ? config.url : null
    }
  ))
}

/**
 * Resets password.
 * @param {form} - password form data
 * @param {isTemporary} - a boolean value which indicates password reset type
 */
const resetPassword = (form, isTemporary) => async(dispatch, getState) => {
  dispatch({ type: ActionTypes.PROFILE_PASSWORD_RESET })
  const errors = validatePassword(form)

  if (errors) {
    dispatch(showPasswordResetError(errors))
    return
  }

  try {
    if (isTemporary) {
      await changeTempPassword(form, { dispatch, getState })
    } else {
      await changePassword(form, { dispatch })
    }

    console.log('no error has been catched')
    dispatch({ type: ActionTypes.PROFILE_PASSWORD_HAD_RESET })
    setSsoData({ ...ssoData, password: form.newPassword })
  } catch (e) {
    const error = await e.response.json()
    // Display generic error.
    if (e.status === 400 || e.status === 409 || e.status === 429) {
      if (error.msg === 'invalid password') {
        Object.values(REQUIRED_PASSWORD_FIELDS).forEach(x => dispatch(profileFieldChanged(x, '', 'passwordForm')))
        dispatch(profileGenericErrorsUpdate([GENERIC_PASSWORD_REQUIREMENTS_NAMES.SAME_AS_PREVIOUS]))
        return
      }
      // Show error in form.
      dispatch(showPasswordResetError({ oldPassword: invalidPassword }, snackbarError))
      return
    }

    dispatch(showPasswordResetError({ serverError: e.message }))
  }
}

// Request password reset via email
export const requestPasswordReset = username => async(dispatch, getState) => {
  dispatch(replace('/recover-password/submit'))
  const { realm } = getState().login.realm
  if (!realm) return

  try {
    await skylight.user.requestPasswordReset(realm, username)
  } catch (e) {
    console.error('something went wrong requesting password reset', e)
  }
}

export const submitPasswordReset = (newPassword, token, captchaToken) => async(dispatch) => {
  try {
    await skylight.user.submitPasswordReset(newPassword, token, captchaToken)
  } catch (e) {
    const error = await e.response.json()
    if (e.status === 400 && error.msg === 'invalid request') {
      dispatch({ type: ActionTypes.PASSWORD_RESET_VALIDATION_ERROR_UPDATE, error: { id: 'PWD_DOES_NOT_MATCH_SECURITY_REQ' } })
      return
    }

    if (e.status === 401 && error.msg === 'token is invalid') {
      console.error('token is invalid')
      dispatch(isTokenInvalidUpdate(true))
      return
    }

    console.error('something went wrong submitting password reset', e)
    dispatch(showError('SOMETHING_WENT_WRONG', { className: 'snackbar-common-error' }))
    return
  }

  const { realm, username } = jwtDecode(token)
  dispatch(snackbar.showSuccessSnackbar('PASSWORD_RESET_SUCCESS'))
  dispatch(loginActions.onUsernameChange(username))
  dispatch(replace(`/login/${realm}`))
}

export const profileGenericErrorsUpdate = errors => ({
  type: ActionTypes.PROFILE_GENERIC_ERRORS_UPDATE,
  errors: Array.isArray(errors) ? errors : [errors]
})

export const profileErrorsDelete = field => ({
  type: ActionTypes.PROFILE_FORM_ERRORS_DELETE,
  field
})

export const isTokenInvalidUpdate = isTokenInvalid => ({
  type: ActionTypes.PASSWORD_RESET_TOKEN_INVALID_UPDATE,
  isTokenInvalid
})

export const recoverPasswordRealmChange = realmFromUrl => async(dispatch, getState) => {
  if (realmFromUrl) {
    try {
      await getRealm(realmFromUrl)
    } catch (e) {
      console.error(`realm ${realmFromUrl} does not exist`)
      dispatch(appActions.redirect('/'))
      return
    }

    dispatch(loginActions.onRealmChange(realmFromUrl))
  }

  const { realm } = getState().login.realm
  if (!realm) {
    dispatch(appActions.redirect('/'))
  }
}

export default {
  onProfileFieldChanged: profileFieldChanged,
  onProfileUpdate: profileUpdate,
  onUploadedProfileImage: uploadedProfileImage,
  onResetPassword: resetPassword,
  onCancelProfileUpdate: cancelProfileUpdate,
  onClearResetPasswordForm: passwordFormClear,
  onProfileGenericErrorsUpdate: profileGenericErrorsUpdate,
  onProfileErrorsDelete: profileErrorsDelete,
  onRecoverPasswordRealmChange: recoverPasswordRealmChange
}
