import React from 'react'
import { FilterToolbar, /* ByApplication, */ ByText, ByUser } from 'skylight-common'
import { ByStatus } from './ByStatus'
import { ASSIGNMENT_FILTER_TYPES, DEFAULT_QUERY } from '../../../../lib/assignment'

import './AssignmentFilter.css'

const getFilters = (type, filterProps = {}) => {
  return [
    { text: 'ASSIGNMENT_STATUS', value: 'status', props: { type }, comp: ByStatus },
    // {text: 'APPLICATION_NAME', value: 'name', comp: ByApplication},
    { text: 'ASSIGNED_TO', value: 'assignedTo', props: { title: 'ASSIGNED_TO', filterProps }, comp: ByUser },
    { text: 'ASSIGNED_BY', value: 'assignedBy', props: { title: 'ASSIGNED_BY', filterProps }, comp: ByUser },
    { text: 'ASSIGNMENT_ID', value: 'assignmentId', props: { title: 'PLACEHOLDER_ASSIGNMENT_ID' }, comp: ByText }
  ]
}

const AssignmentFilter = props => {
  const { type } = props.query.params || {}

  const filterProps = {
    urlPath: '/team/users',
    onSelectItems: props.onUserSelectItems,
    role: props.user.role
  }

  let FILTERS = getFilters(type, filterProps)

  if ((props.user && props.user.role === 'user') || type === ASSIGNMENT_FILTER_TYPES.PENDING) {
    FILTERS = FILTERS.filter(item => item.value !== 'assignedTo')
  }
  return <FilterToolbar
    className="assignment-filter"
    filters={FILTERS}
    query={props.query}
    defaultQuery={DEFAULT_QUERY}
    layout={props.layout}
    onFilterUpdated={props.onAssignmentFilterUpdated}
    onFiltersUpdated={props.onAssignmentFiltersUpdated}
    onFilterRemoved={props.onAssignmentFilterRemoved} />
}

export { AssignmentFilter }
