import React from 'react'
import { TextFilter } from 'skylight-common'
import { CallEventFilter } from '../../../../../containers/activity'

const VALUE = 'id'

const CallEventTextFilter = props => {
  const value = props.query.filter && props.query.filter[VALUE] ? props.query.filter[VALUE] : ''
  const onChange = (v) => {
    const value = v.trim()
    const filter = { ...props.query.filter, [VALUE]: value }
    props.onCallEventFilterUpdated(filter)
  }

  return <TextFilter
    value={value}
    className="call-event-text-filter"
    title="CALL_EVENT_FILTER_PLACEHOLDER"
    iconFilter={<CallEventFilter />}
    onChange={onChange} />
}

export { CallEventTextFilter }
