import React from 'react'
import { TextFilter } from 'skylight-common'
import classNames from 'classnames'
import FilterSearchIcon from '../../Icons/filterSearch'

import './TextFilterNextGen.css'

const TextFilterNextGen = props => {
  return <TextFilter
    {...props}
    className={classNames('text-filter-next-gen', props.className)}
    searchFieldClassName="input-open"
    icon={FilterSearchIcon} />
}

export { TextFilterNextGen }
