import React, { Component } from 'react'
import Popover from 'material-ui/Popover'
import classNames from 'classnames'
import { TopBarActionButton } from './TopBarActionButton'

import './TopBarPopoverButton.css'

class TopBarPopoverButton extends Component {
  state = {
    open: false,
    mouseOverButton: false,
    mouseOverPopover: false
  }

  handlePopoverOpenEvent = e => {
    this.setState({
      open: !this.state.open,
      anchorEl: e.currentTarget
    })
  }

  handleButtonClick = e => {
    this.handlePopoverOpenEvent(e)
  }

  handleRequestClose = () => {
    this.setState({
      open: false,
      mouseOverButton: false,
      mouseOverPopover: false
    })
  }

  handleMouseEnterButton = e => {
    if (!this.props.openOnHover || this.state.open) {
      return
    }

    this.handlePopoverOpenEvent(e)
  }

  handleMouseLeaveButton = e => {
    if (!this.props.openOnHover) {
      return
    }

    setTimeout(() => {
      if (!this.state.mouseOverPopover) {
        this.handleRequestClose()
      }
    }, 100)
    this.setState({ mouseOverButton: false })
  }

  handleMouseEnterPopover = _e => {
    if (!this.props.openOnHover) {
      return
    }

    this.setState({ mouseOverPopover: true })
  }

  handleMouseLeavePopover = _e => {
    if (!this.props.openOnHover) {
      return
    }

    this.handleRequestClose()
  }

  renderPopoverBody = () => {
    return React.cloneElement(this.props.popoverBody, {
      onMenuItemClick: this.handleRequestClose,
      onMouseEnter: this.handleMouseEnterPopover,
      onMouseLeave: this.handleMouseLeavePopover
    })
  }

  render() {
    return (
      <TopBarActionButton
        {...this.props}
        className={classNames('top-bar-popover-button', this.props.className)}
        active={this.state.open}
        onMouseEnter={this.handleMouseEnterButton}
        onMouseLeave={this.handleMouseLeaveButton}
        onClick={this.handleButtonClick}>
        {this.props.children}
        <Popover
          className="top-bar-popover"
          useLayerForClickAway={false}
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          onRequestClose={this.handleRequestClose}>
          {this.renderPopoverBody()}
        </Popover>
      </TopBarActionButton>
    )
  }
}

export { TopBarPopoverButton }
