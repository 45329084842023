import React from 'react'
import { FormattedMessage } from 'react-intl'
import { OutlinedField } from '../../../../../../Common/Form/OutlinedField'
import { DEFAULT_DETAILS_PROPS } from './eventDetailsProps'

const EventDetailsBase = (props) => {
  const { item } = props.event

  const renderItemProps = () => {
    return itemProps.map((prop, i) => {
      const fieldContent = prop.render ? prop.render(item) : item[prop.field]
      return <div key={i} className="item-prop-next-gen">
        <OutlinedField floatingLabelText={<FormattedMessage id={prop.title} defaultMessage={prop.title} />} disabled>
          <div className="item-prop-body">{fieldContent}</div>
        </OutlinedField>
      </div>
    })
  }

  const itemProps = props.itemProps || DEFAULT_DETAILS_PROPS
  return (
    <div className="event-details-base">
      {renderItemProps()}
    </div>
  )
}

export { EventDetailsBase }
