import React from 'react'
import { FormattedMessage } from 'react-intl'
import { PeoplePicker } from 'skylight-common'
import { GroupMemberList } from '../GroupMemberList/GroupMemberList'
import { GroupLoading } from './GroupLoading'
import FlatButton from 'material-ui/FlatButton'
import AddIcon from 'material-ui/svg-icons/content/add-circle-outline'
import './GroupMembers.css'

const GroupMembers = (props) => {
  const { members, isAddingMember, isLoading, mode } = props.group
  const groupMembers = props.groupMembers.items || []
  const onSelect = (members) => {
    props.onSetNewGroupMembers(members)
  }

  return (
    <div className="group-members">
      <GroupLoading isLoading={isLoading} />
      <div className="details-item">
        {isAddingMember
          ? <div className="picker-wrapper">
          <span className="title title-item">
            <FormattedMessage id="GROUP_PICK" defaultMessage="Enter name or email addresses" />
          </span>
          <PeoplePicker
            useLocalQuery={false}
            queryDebounceTime={1000}
            selected={members}
            excludedIds={groupMembers.map(m => m.id)}
            includeCurrentUser={true}
            autoFocus={true}
            showIcons={true}
            disabled={isLoading}
            onSelect={onSelect} />
        </div>
          : <FlatButton
          className="action-add"
          label={
            <FormattedMessage id="ACTION_ADD_USER" defaultMessage="New user" />
          }
          primary={true}
          icon={<AddIcon />}
          fullWidth={true}
          onTouchTap={props.onAddingGroupMember} />}
      </div>
      {mode !== 'new' && <GroupMemberList {...props} listMode={false} />}
    </div>
  )
}

export { GroupMembers }
