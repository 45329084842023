import React from 'react'
import classNames from 'classnames'
import { DETAILS, showDetails, renderDetails } from './details-list'
import { isNextGenUrl } from '../../../lib/nextGenApplication'

import './Details.css'

// Additional statefull component allows to maintain actual
// details drawer state (open/close).
class DetailsComp extends React.Component {
  componentDidMount() {
    this.props.onToggleDrawer(true)
  }

  componentWillUnmount() {
    this.props.onToggleDrawer(false)
  }

  render() {
    const { match, ...props } = this.props

    return <div className={classNames('details', 'show', { 'next-gen': isNextGenUrl() })}>
      {renderDetails(match, props)}
    </div>
  }
}

const Details = (props) => {
  const match = DETAILS.find(x => showDetails(x, props.location.pathname, props))
  if (!match) {
    return null
  }

  return <DetailsComp {...props} match={match} />
}

export { Details }
