import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import { PeoplePicker, DialogTitle } from 'skylight-common'
import { RolesPicker } from '../../../HomeNextGen/Applications/ApplicationDashboard/Permissions/ParticipantList/RolesPicker/RolesPicker'
import './AddApplicationParticipants.css'

const KEYS = {
  ENTER: 13
}

const DEFAULT_ROLE_NAME = 'User'

class AddApplicationParticipants extends Component {
  state = {
    participants: [],
    selectedRoleIds: []
  }

  componentDidMount() {
    this.setDefaultRoles()
  }

  setDefaultRoles = () => {
    const { roles } = this.props
    const defaultRole = roles.find(role => role.name === DEFAULT_ROLE_NAME)
    if (!defaultRole) {
      return
    }

    this.setState({ selectedRoleIds: [defaultRole.roleId] })
  }

  getDialogActions = () => {
    const { participants, selectedRoleIds } = this.state
    return [
      <FlatButton
        key="cancel-action"
        className="cancel-action"
        label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
        primary
        onTouchTap={this.onHide} />,
      <FlatButton
        key="add-users-action"
        label={<FormattedMessage id="ADD_USER_S" defaultMessage="ADD USER(S)" />}
        primary
        keyboardFocused
        disabled={!participants.length || !selectedRoleIds.length}
        onTouchTap={this.onAddParticipants} />
    ]
  }

  getDialogData = () => {
    const { dialog = {}, dialogType } = this.props

    return dialog[dialogType] || {}
  }

  isDialogOpened = () => {
    const { open } = this.getDialogData()

    return !!open
  }

  onHide = () => {
    this.props.onHideDialog(this.props.dialogType)
  }

  onAddParticipants = () => {
    const { participants, selectedRoleIds } = this.state

    if (!participants.length || !selectedRoleIds.length) {
      return
    }

    const appParticipants = participants.map(p => ({
      id: p.id,
      type: p.isGroup ? 'group' : 'user'
    }))

    this.props.onAddApplicationParticipants(appParticipants, selectedRoleIds)
    this.onHide()
  }

  selectParticipants = (participants) => {
    this.setState({ participants })
  }

  handleKeyDown = e => {
    if (e.keyCode === KEYS.ENTER) {
      this.handleEnterDown()
    }
  }

  handleEnterDown = () => {
    this.onAddParticipants()
  }

  onRoleChange = roleName => {
    this.setState({ selectedRole: roleName })
  }

  handleRoleAdd = roleId => {
    this.setState({
      selectedRoleIds: [...this.state.selectedRoleIds, roleId]
    })
  }

  handleRoleRemove = roleId => {
    this.setState({
      selectedRoleIds: [...this.state.selectedRoleIds.filter(id => roleId !== id)]
    })
  }

  handleManageRolesClick = () => {
    this.onHide()
    this.props.onManageRolesOpen()
  }

  renderRoleSelectField = () => {
    const selectedRoles = this.props.roles.filter(role => this.state.selectedRoleIds.includes(role.id))
    return <RolesPicker
      items={this.props.roles}
      selectedRoles={selectedRoles}
      onRoleAdd={this.handleRoleAdd}
      onRoleRemove={this.handleRoleRemove}
      onManageRolesClick={this.handleManageRolesClick}/>
  }

  render() {
    return (
      <Dialog
        className="dialog-modal add-application-participants"
        title={<DialogTitle id="ADD_USERS" defaultMessage="Add users" />}
        actions={this.getDialogActions()}
        open={this.isDialogOpened()}
        bodyClassName="add-application-participants-body"
        autoScrollBodyContent
        onRequestClose={this.onHide}>
        <div
          className="add-application-participants-container"
          tabIndex="0"
          onKeyDown={this.handleKeyDown}>
          <div className="people-picker-container">
            <div className="label">
              <FormattedMessage id="USER_S" defaultMessage="User(s)" />
            </div>
            <PeoplePicker
              useLocalQuery={false}
              queryDebounceTime={1000}
              className="application-participants-picker"
              popoverClassName="application-participants-picker-popover"
              includeCurrentUser
              includeGroups
              showIcons
              floatingLabelText={<FormattedMessage
                id="ADD_PARTICIPANT_TIPS"
                defaultMessage="Type a name, group or email address" />}
              selected={this.state.participants}
              onSelect={this.selectParticipants} />
          </div>
          <div className="role-picker-container">
            <div className="label">
              <FormattedMessage id="ROLE_S" defaultMessage="Role(s)" />
            </div>
            {this.renderRoleSelectField(this.state.selectedRole)}
          </div>
        </div>
      </Dialog>
    )
  }
}

export { AddApplicationParticipants }
