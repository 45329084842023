import React, { Component } from 'react'
import CopyIcon from 'material-ui/svg-icons/content/content-copy'
import { FormattedMessage } from 'react-intl'
import IconButton from 'material-ui/IconButton'

import './CopyArea.css'

const COPIED_TEXT = 'COPIED'
const COPY_TEXT = 'COPY'
const COPY_TIMEOUT_DELAY = 5000

export class CopyArea extends Component {
  state = {
    isCopied: false
  }

  componentWillUnmount() {
    clearTimeout(this.copyTimeout)
  }

  getTooltipText = () => {
    return this.state.isCopied ? COPIED_TEXT : (this.props.tooltip || COPY_TEXT)
  }

  onCopyText = (text) => {
    const listener = e => {
      e.clipboardData.setData('text/plain', text)
      e.preventDefault()
    }

    document.addEventListener('copy', listener)
    document.execCommand('copy')
    document.removeEventListener('copy', listener)

    this.setState({ isCopied: true })
    clearTimeout(this.copyTimeout)
    this.copyTimeout = setTimeout(() => {
      this.setState({ isCopied: false })
    }, COPY_TIMEOUT_DELAY)
  }

  render() {
    const { text } = this.props

    return (
      <div className="copy-area">
        <span className="copy-text">
            {text}
        </span>
        <IconButton
          tooltipPosition="top-left"
          tooltip={<FormattedMessage id={this.getTooltipText()} defaultMessage="Copy contents" />}
          onTouchTap={() => this.onCopyText(text)} className="copy-btn"><CopyIcon /></IconButton>
      </div>
    )
  }
}
