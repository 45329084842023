import { connect } from 'react-redux'

import { AdminAuth as AdminAuthComp } from '../App/Home/Admin/AdminAuth'
import { TokenList as TokenListComp } from '../App/Home/Admin/TokenList'
import { TokenDetails as TokenDetailsComp, TokenActionButtons as TokenActionButtonsComp } from '../App/Home/Admin/TokenDetails'

import { AdminLive as AdminLiveComp } from '../App/Home/Admin/AdminLive'
import { TokenTextFilter as TokenTextFilterComp } from '../App/Home/Admin/TokenList/TokenFilter'
import { AdminLanguage as AdminLanguageComp } from '../App/Home/Admin/AdminLanguage'
import { ClientSettings as ClientSettingsComp } from '../App/Home/Admin/ClientSettings'
import { AdminPrivacy as AdminPrivacyComp } from '../App/Home/Admin/AdminPrivacy'
import { QueryFilterPicker as QueryFilterPickerComp } from '../App/Home/Admin/AdminAuth/AuthApps/LdapOptions/QueryFilterPicker'
import { UserAttributesPicker as UserAttributesPickerComp } from '../App/Home/Admin/AdminAuth/AuthApps/LdapOptions/UserAttributesPicker'

import adminActions from '../actions/admin'
import wfActions from '../actions/workflow'
import dialogActions from '../actions/dialog'
import appActions from '../actions/app'
import localeActions from '../actions/locale'
import activityActions from '../actions/activity'

const tokenList = (state, props) => ({
  list: state.admin.apiToken.list
})

const tokenListActions = {
  ...adminActions,
  ...dialogActions
}

const tokenDetails = (state, props) => ({
  item: state.admin.apiToken.item,
  workflow: { list: state.workflow.list },
  tokens: { prefix: state.admin.apiToken.list.prefix }
})

const tokenDetailsActions = {
  ...wfActions,
  onShowTokenCredentials: dialogActions.onShowTokenCredentials
}

const actionButtonsActions = {
  ...adminActions,
  onConfirm: dialogActions.onConfirm,
  onCloseDetails: appActions.onCloseDetails
}

const auth = (state, props) => ({
  auth: state.admin.auth
})

const live = (state) => ({
  live: state.admin.live,
  realm: state.admin.auth.realm
})

const client = (state) => ({
  realm: state.admin.auth.realm
})

const tokenFilter = (state) => ({
  query: state.admin.apiToken.list.query,
  layout: state.layout
})

const language = (state) => ({
  user: state.auth.user
})

const languageActions = {
  ...adminActions,
  ...localeActions
}

const adminPrivacy = (state, props) => ({
  auth: state.admin.auth,
  activity: state.activity.event.list
})

const adminPrivacyActions = {
  ...adminActions,
  ...dialogActions,
  onDeleteActivities: activityActions.onDeleteActivities
}

const adminLiveActions = {
  ...adminActions,
  ...dialogActions
}

const queryFilterPicker = state => ({
  error: state.admin.auth.ldapApp.errors.customFilters
})

const TokenList = connect(tokenList, tokenListActions)(TokenListComp)
const TokenDetails = connect(tokenDetails, tokenDetailsActions)(TokenDetailsComp)
const TokenActionButtons = connect(tokenDetails, actionButtonsActions)(TokenActionButtonsComp)
const AdminAuth = connect(auth, adminActions)(AdminAuthComp)
const AdminLive = connect(live, adminLiveActions)(AdminLiveComp)
const TokenTextFilter = connect(tokenFilter, adminActions)(TokenTextFilterComp)
const AdminLanguage = connect(language, languageActions)(AdminLanguageComp)
const ClientSettings = connect(client, adminActions)(ClientSettingsComp)
const AdminPrivacy = connect(adminPrivacy, adminPrivacyActions)(AdminPrivacyComp)
const QueryFilterPicker = connect(queryFilterPicker, {
  showQueryFilterDialog: dialogActions.showQueryFilterDialog
})(QueryFilterPickerComp)
const UserAttributesPicker = connect(null, {
  showUserAttributesDialog: dialogActions.showUserAttributesDialog
})(UserAttributesPickerComp)

export {
  TokenList, TokenDetails, AdminAuth, AdminLive, TokenActionButtons,
  TokenTextFilter, AdminLanguage, ClientSettings, AdminPrivacy,
  QueryFilterPicker, UserAttributesPicker
}
