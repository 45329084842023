import * as ActionTypes from '../types'
import { push } from 'react-router-redux'
import skylight from 'skylight-js-sdk'
import { createFilterActions, createListActions, createItemActions } from 'skylight-common'
import { DEFAULT_QUERY, VERSION_STAGES, VERSION_TEMPLATE } from '../../lib/application/version'
import dialogActions, { hideDialog } from '../dialog'
import snackbarActions from '../snackbar'
import * as dialogTypes from '../../App/Home/Layout/Dialogs/dialogTypes'
import { getVersions } from '../../svc/application/versions'
import { showError } from '../error'

const LIST_ACTIONS = createListActions({
  prefix: ActionTypes.PREFIX_VERSION.toUpperCase(),
  handler: ActionTypes.PREFIX_VERSION,
  onGetList: s => s.application.version.list,
  onLoad: async(query, { dispatch, getState }) => {
    let applicationId
    if (query.applicationId) {
      ({ applicationId } = query)
    } else {
      const { application } = getState();
      ({ applicationId } = application.selectedApplication)
    }
    const q = { ...query }
    delete q.applicationId
    return getVersions(applicationId, query).then((x) => {
      dispatch(snackbarActions.hideLoadingSnackbar())
      return x
    })
  }
})

const FILTER_ACTIONS = createFilterActions({
  baseUrl: '/ng/applications/:applicationId/versions',
  redirect: false,
  selector: state => state.application.version.list.query,
  listPrefix: ActionTypes.PREFIX_VERSION.toUpperCase(),
  actionPrefix: ActionTypes.PREFIX_VERSION,
  DEFAULT_QUERY,
  loadItems: LIST_ACTIONS.onVersionLoadItems,
  push
})

const ITEM_ACTIONS = createItemActions({
  prefix: ActionTypes.PREFIX_VERSION.toUpperCase(),
  handler: ActionTypes.PREFIX_VERSION,
  onSave: _ => {},
  onGetList: s => s.application.version.list,
  onLoad: async(item, res, ctx) => item,
  onLoadItems: LIST_ACTIONS.onVersionLoadItems
})

const startPublishingVersion = (version, versions) => (dispatch, getState) => {
  dispatch(dialogActions.showPublishVersionDialog(version, versions))
}

const publishVersion = (version, comment) => async(dispatch, getState) => {
  dispatch(hideDialog(dialogTypes.PUBLISH_VERSION))
  const { selectedApplication } = getState().application
  const { applicationId, publishedVersion: currentPublishedVersion } = selectedApplication
  const versionList = getState().application.version.list.items

  if (version && currentPublishedVersion !== version) {
    let response = null
    const foundVersion = versionList.find(x => x.id === version)
    const currentPublished = versionList.find(x => x.stage === VERSION_STAGES.PUBLISHED)
    try {
      dispatch(snackbarActions.showPublishingSnackbar())
      if (comment) {
        await skylight.we20.version.patch(applicationId, version, { comment })
      }

      const newPendingVersion = { ...foundVersion, stage: VERSION_STAGES.PENDING }
      dispatch(LIST_ACTIONS.onVersionListItemUpdate(newPendingVersion))

      response = await skylight.we20.version.publish(applicationId, version)

      const newPublishedVersion = { ...foundVersion, comment, stage: VERSION_STAGES.PUBLISHED }
      dispatch(LIST_ACTIONS.onVersionListItemUpdate(newPublishedVersion))

      if (currentPublished) {
        dispatch(LIST_ACTIONS.onVersionListItemUpdate({ ...currentPublished, stage: VERSION_STAGES.INACTIVE }))
      }
    } catch (e) {
      console.error(e)
      const restoreVersion = { ...foundVersion }
      dispatch(LIST_ACTIONS.onVersionListItemUpdate(restoreVersion))
      return
    } finally {
      dispatch(snackbarActions.hideLoadingSnackbar())
    }

    const application = {
      publishedVersion: response.version,
      lastPublished: response.modifiedAt || response.createdAt
    }
    dispatch({ type: ActionTypes.SELECTED_APP_UPDATE, application })
  }

  dispatch(createDraft())
}

const createDraft = () => async(dispatch, getState) => {
  const { selectedApplication } = getState().application
  const { applicationId } = selectedApplication

  let draftVersion = null
  const { draftVersion: currentDraftVersion } = selectedApplication
  const newPendingVersion = {
    ...VERSION_TEMPLATE,
    id: currentDraftVersion + 1,
    version: currentDraftVersion + 1,
    stage: VERSION_STAGES.PENDING,
    createdAt: new Date().toISOString()
  }

  try {
    dispatch(snackbarActions.showCreatingDraftSnackbar())
    dispatch(LIST_ACTIONS.onVersionPrependItems([newPendingVersion]))

    const { version } = await skylight.we20.version.create(applicationId)
    const patchResponse = await skylight.we20.version.patch(applicationId, version, { comment: '' })
    draftVersion = version

    dispatch(LIST_ACTIONS.onVersionListItemUpdate({ ...patchResponse, id: version }))
  } catch (e) {
    dispatch(LIST_ACTIONS.onVersionListItemRemove({ id: newPendingVersion.id }))
    dispatch(snackbarActions.showErrorCreatingDraftSnackbar())
    return
  } finally {
    dispatch(snackbarActions.hideLoadingSnackbar())
  }

  dispatch({
    type: ActionTypes.SELECTED_APP_UPDATE,
    application: {
      draftVersion,
      currentVersion: { version: draftVersion, stage: VERSION_STAGES.DRAFT }
    }
  })

  dispatch(snackbarActions.showPublishCompleteSnackbar())
}

const deleteVersionUpgrade = upgrade => async(dispatch, getState) => {
  try {
    if (!upgrade) throw new Error('NO_UPGRADE_FOUND_FOR_VERSION')
    await skylight.we20.upgrade.delete(upgrade)
    dispatch(LIST_ACTIONS.onVersionSelectItems([]))
    // delete the version from the list
    dispatch(LIST_ACTIONS.onVersionListItemRemove({ id: upgrade.version }))
  } catch (error) {
    if (error.message === 'NO_UPGRADE_FOUND_FOR_VERSION') {
      dispatch(showError(error.message, { className: 'snackbar-common-error' }))
      return
    }
    dispatch(showError('ERROR_DELETING_VERSION', { className: 'snackbar-common-error' }))
  }
}

export default {
  ...LIST_ACTIONS,
  ...FILTER_ACTIONS,
  ...ITEM_ACTIONS,
  onStartPublishingVersion: startPublishingVersion,
  onPublishVersion: publishVersion,
  onDeleteVersionUpgrade: deleteVersionUpgrade
}
