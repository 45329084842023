import { CONFIG } from 'skylight-common'

export const isCaptchaEnabled = () => {
  const config = CONFIG.recaptcha
  return config && config.siteKey
}

export const getSiteKey = () => {
  if (!isCaptchaEnabled()) throw new Error('Captcha is disabled')
  return CONFIG.recaptcha.siteKey
}

export const getCaptchaToken = async() => {
  if (!isCaptchaEnabled()) return null
  const grecaptcha = window.grecaptcha
  return new Promise((resolve) => {
    grecaptcha.ready(function() {
      resolve(grecaptcha.execute(getSiteKey(), { action: 'submit' }))
    })
  })
}
