import React from 'react'
import ApplicationList from './ApplicationList/ApplicationList'

const Applications = (props) => {
  return (
    <div className="applications-next-gen">
      <ApplicationList />
    </div>
  )
}

export { Applications }
