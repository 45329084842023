import { CONFIG } from 'skylight-common'
import { signout } from './auth'
import { showError } from './error'
import skylight from 'skylight-js-sdk'
import { isLiveEnabled } from '../lib/calls'

const callSetup = (payload, authToken) => async(dispatch) => {
  if (isLiveEnabled()) return // Is handled by call middleware.
  const sessionId = payload.from
  const callId = payload.payload
  const userId = payload.fromUserId

  // Cache our partner's user object to avoid an API call in Skylight Live
  const userToCall = await skylight.user.getUserFromCacheById(userId)
  await skylight.storage.setItem('callPartner', userToCall)

  // Make sure we have auth in place before opening call tab
  const authData = await skylight.storage.getItem('authData')
  if (authData) {
    // Launch EyeSight in a new tab
    const callUrl = `${CONFIG.localCallUrl || ''}/call/receive/${sessionId}/${callId}/${userId}`
    window.open(callUrl, 'Skylight Live')
  } else {
    dispatch(signout())
    dispatch(showError('SESSION_EXPIRED'))
  }
}

export default {
  onCallSetup: callSetup
}
