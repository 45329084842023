import React from 'react'
import PropTypes from 'prop-types'

import { ActionButton } from '../components'
import { FormattedMessage } from 'react-intl'
import { TrimmingTextField } from 'skylight-common'
import { FIELDS } from './utils'

const FilterItem = props => {
  const onFieldChange = (prop, event) => {
    const value = event.target.value
    props.onChange(prop, value, props.idx)
  }

  const renderFields = () => {
    const TextField = props.TextField || TrimmingTextField

    return FIELDS.map((x, i) => (
      <TextField
        key={x.prop}
        name={x.name}
        disabled={x.disabled}
        className="additional-custom-filter-item"
        value={props.item[x.prop]}
        floatingLabelText={<FormattedMessage id={x.title} defaultMessage={x.defaultMessage} />}
        type={x.type}
        fullWidth
        onChange={value => onFieldChange(x.prop, value)}/>
    ))
  }

  return (
    <div className="add-additional-filter-form-wrapper">
      <div className="form-wrapper">
        <div className="add-additional-filter-form">
          <div className="form-content">{renderFields()}</div>
        </div>
        <div className="delete-button-wrapper">
          <ActionButton
            icon="delete"
            className="delete-filter-button"
            labelId="DELETE_FILTER"
            defaultMessage="Delete filter"
            onTouchTap={() => props.onFilterDelete(props.idx)}/>
        </div>
      </div>
    </div>
  )
}

FilterItem.propTypes = {
  item: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  onFilterDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}

export { FilterItem }
