import React from 'react'
import { Snackbar } from 'skylight-common'
import { FormattedMessage } from 'react-intl'

import './SnackbarMessage.css'
import classNames from 'classnames'

const DEFAULT_AUTOCLOSE_TIMEOUT = 4

const SnackbarMessage = props => {
  const { snackbar, snackbarType } = props
  const { open, params } = snackbar[snackbarType] || {}

  const handleClose = () => {
    props.onHideSnackbar(snackbarType)
  }

  const renderMessage = () => {
    const { message } = params
    return <FormattedMessage id={message} defaultMessage="Success" />
  }

  const autoCloseTimeout = params.autoCloseTimeout || DEFAULT_AUTOCLOSE_TIMEOUT
  return (
    <Snackbar autocloseSeconds={autoCloseTimeout} className={classNames('snackbar-message', {
      [`snackbar-message--${params.type}`]: params.type
    })} open={open} onRequestClose={handleClose}>
      <div className="snackbar-message__container">
        <div className="snackbar-message__text">
          {renderMessage()}
        </div>
      </div>
    </Snackbar>
  )
}

export { SnackbarMessage }
