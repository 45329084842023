import React from 'react'
import PropTypes from 'prop-types'

import { ActionButton as IconButton, MaterialIcon } from 'skylight-common'
import { FormattedMessage } from 'react-intl'

const iconType = {
  add: <MaterialIcon icon="add" />,
  delete: <MaterialIcon icon="delete" />
}

const ActionButton = props => {
  const { className, icon, labelId, defaultMessage, onTouchTap, tooltipPosition } = props
  return (
    <IconButton
      icon={iconType[icon]}
      className={className}
      tooltipPosition={tooltipPosition}
      label={<FormattedMessage id={labelId} defaultMessage={defaultMessage} />}
      onTouchTap={onTouchTap}/>
  )
}

ActionButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  labelId: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string,
  onTouchTap: PropTypes.func.isRequired
}

ActionButton.defaultProps = {
  className: '',
  defaultMessage: ''
}

export { ActionButton }
