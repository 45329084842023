import React from 'react'
import { FormattedMessage } from 'react-intl'
import NextGenApplicationIcon from '../../../../Common/Icons/nextGenApplication'

import './EmptyListView.css'

const EmptyListView = props => {
  return (
    <div className="empty-list-view">
      <NextGenApplicationIcon className="application-icon" />
      <FormattedMessage
        id="EMPTY_APPLICATIONS_LIST"
        defaultMessage="You have no applications to view." />
    </div>
  )
}

export { EmptyListView }
