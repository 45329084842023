import React from 'react'
import { VERSION_STAGES } from '../../../../../../../../lib/application/version'

import './VersionStage.css'
import { FormattedMessage } from 'react-intl'

const VersionStage = ({ stage }) => {
  return (
    <div className={`version-stage ${stage}`}>
      <FormattedMessage id={stage} />
      {stage === VERSION_STAGES.PENDING ? '...' : ''}
    </div>
  )
}

export { VersionStage }
