import React from 'react'
import classNames from 'classnames'

import './FormSection.css'

const FormSection = props => {
  return <div className={classNames('form-section', props.className)}>
    <div className="form-section-title">
      {props.title}
    </div>
    <div className="form-section-body">
      <div className="form-section-actions">
        {props.actions}
      </div>
      {props.children}
    </div>
  </div>
}

export { FormSection }
