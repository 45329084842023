import React from 'react'
import { FormattedMessage } from 'react-intl'
import UploadIcon from 'material-ui/svg-icons/file/file-upload'
import './CallDropZone.css'
import { onFilesChanged } from '../lib/files'

const CLASS_BODY = 'call-drop-zone-body'

class CallDropZone extends React.Component {
  timer = null
  state = {
    isVisible: false
  }

  hide = (force) => {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.setState({ isVisible: false })
    }, force ? 0 : 1000)
  }

  show = () => {
    clearTimeout(this.timer)
    if (!this.state.isVisible) {
      this.setState({ isVisible: true })
    }
  }

  onKeyUp = (e) => {
    if (e.keyCode === 27) {
      // This will not really work, because drag over event would fire soon.
      this.hide(true)
    }
  }

  isEventInsideElement = (e, element) => {
    if (!e || !element) return false
    const rect = element.getBoundingClientRect()
    return e.clientX > rect.left &&
      e.clientX < rect.left + rect.width &&
      e.clientY > rect.top &&
      e.clientY < rect.top + rect.height
  }

  onDragOver = (e) => {
    e.preventDefault()
    this.body.className = this.isEventInsideElement(e, this.body)
      ? `${CLASS_BODY} file-over`
      : CLASS_BODY

    // You will not grasp this with your mind. Or cover with a common label.
    // For drag&drop is one of a kind – believe in it, if you are able...
    this.show()
    this.hide()
  }

  onDrop = (e) => {
    e.preventDefault()
    this.hide(true)
    if (!this.isEventInsideElement(e, this.body)) {
      return
    }

    const files = e.dataTransfer.files
    onFilesChanged(this.props, files)
  }

  preventDefault = (e) => {
    e.preventDefault()
  }

  componentDidMount() {
    window.addEventListener('keyup', this.onKeyUp)
    window.addEventListener('dragover', this.onDragOver)
    window.addEventListener('drop', this.preventDefault)
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.onKeyUp)
    window.removeEventListener('dragover', this.onDragOver)
    window.removeEventListener('drop', this.preventDefault)
  }

  render() {
    const className = `call-drop-zone ${this.state.isVisible ? 'show' : 'hide'}`
    return (
      <div className={className} onClick={this.props.onClick}>
        <div className="call-drop-zone-content">
          <div className="upload-header">
            <FormattedMessage id="CALL_DROP_ZONE_HEADER" defaultMessage="Upload file(s) to the call" />
          </div>
          <div className="call-drop-zone-body" ref={body => { this.body = body }}>
            <div className="upload-icon">
              <UploadIcon />
            </div>
            <div className="upload-title">
              <FormattedMessage id="CALL_DROP_ZONE_TITLE" defaultMessage="Upload file(s) to the call" />
            </div>
            <div className="upload-description">
              <FormattedMessage id="CALL_DROP_ZONE_DESCRIPTION" defaultMessage="You will be able to share and annotate files while on your call." />
            </div>
          </div>
        </div>
        <div className="overlay"
          onDrop={this.onDrop}>
        </div>
      </div>
    )
  }
}

export { CallDropZone }
