import React from 'react'
import { Form } from 'skylight-common'
import { Dialog } from 'material-ui'
import { FormattedMessage } from 'react-intl'

import * as dialogTypes from './dialogTypes'

import { AccountPassword } from '../../Profile/Settings/AccountPassword'

import { AUTH_STEPS_TOTAL } from '../../../../lib/browser'
import CaptchaBadge from '../../../Common/Captcha/CaptchaBadge'
import { getCaptchaToken } from '../../../Common/Captcha/captcha'

import './ChangePasswordDialog.css'
import LargeRaisedButton from '../../../Common/LargeRaisedButton/LargeRaisedButton'
import { GENERIC_PASSWORD_REQUIREMENTS, MAX_PASSWORD_LENGTH, REQUIRED_PASSWORD_FIELDS } from '../../../../svc/login'
import get from 'lodash/get'

class ChangePasswordDialog extends React.Component {
  state = {
    maxLimitError: null
  }

  componentDidMount() {
    if (this.isOpen()) {
      this.setupCancelListener()
    }
  }

  componentWillUnmount() {
    this.removeCancelListener()
  }

  setupCancelListener = () => {
    window.addEventListener('beforeunload', this.handleUnload)
  }

  removeCancelListener = () => {
    window.removeEventListener('beforeunload', this.handleUnload)
  }

  handleUnload = e => {
    e.preventDefault()
    // Chrome requires returnValue to be set.
    e.returnValue = 'modified'
  }

  isOpen = (props) => {
    const { dialogType, dialog } = props || this.props

    return !!(dialog[dialogType] || {}).open
  }

  onHide = () => {
    this.props.onHideDialog(this.props.dialogType)
  }

  onChangePassword = (e, v) => {
    const { name } = e.target
    this.props.onProfileFieldChanged(name, v, 'passwordForm')
  }

  onSubmit = async() => {
    const { settings } = this.props.profile
    const { passwordForm, genericErrors } = settings

    if (genericErrors.length) return

    if (get(passwordForm, REQUIRED_PASSWORD_FIELDS.NEW_PASSWORD, '').length > MAX_PASSWORD_LENGTH) {
      Object.values(REQUIRED_PASSWORD_FIELDS).forEach(x => this.props.onProfileFieldChanged(x, '', 'passwordForm'))
      this.setState({ maxLimitError: { id: 'PWD_DOES_NOT_MEET_SECURITY_REQ', defaultMessage: 'Password does not meet security requirements.' } })
      return
    }

    const isTemporary = true
    const captchaToken = await getCaptchaToken()

    this.props.onResetPassword({ ...settings.passwordForm, captchaToken }, isTemporary)
  }

  renderDialogTitle = () => {
    const permissionsWasAsked = localStorage.getItem('permissionsWasAsked')

    return (
      <div className="dialog-title">
        <FormattedMessage id="CHANGE_YOUR_PASSWORD" defaultMessage="Change your password" />
        {!permissionsWasAsked && <span className="steps">1 of {AUTH_STEPS_TOTAL}</span>}
      </div>
    )
  }

  isSubmitDisabled = () => {
    const { settings } = this.props.profile
    const keys = Object.keys(settings.passwordForm)

    return keys.length !== 3 || keys.some(k => !settings.passwordForm[k])
  }

  onBlur = e => {
    const { settings } = this.props.profile
    const { passwordForm } = settings || {}

    const { name } = e.target
    if (name === REQUIRED_PASSWORD_FIELDS.NEW_PASSWORD) {
      const genericErrors = GENERIC_PASSWORD_REQUIREMENTS
        .filter(x => x.validate({ password: get(passwordForm, REQUIRED_PASSWORD_FIELDS.NEW_PASSWORD, ''), username: get(this.props, 'login.signin.username', '') }))
        .map(x => x.name)
      this.props.onProfileGenericErrorsUpdate(genericErrors)
    }
  }

  onFocus = e => {
    const { name } = e.target
    if (name === REQUIRED_PASSWORD_FIELDS.NEW_PASSWORD) {
      this.setState({ maxLimitError: null })
    }

    this.props.onProfileErrorsDelete(name)
  }

  render() {
    const { settings } = this.props.profile
    const { errors = {}, genericErrors } = settings || {}

    return (
      <Dialog
        title={this.renderDialogTitle()}
        open={this.isOpen()}
        overlayClassName="change-password-overlay"
        className="dialog-modal change-password-dialog"
        contentClassName="change-password-content"
        bodyClassName="change-password-body"
        actionsContainerClassName="change-password-footer"
        autoScrollBodyContent={true}>
        <Form className="change-password-body-container" onSubmit={this.onSubmit}>
          <FormattedMessage id="CHANGE_YOUR_PASSWORD_DESCRIPTION"
                            className="change-password-description"
                            defaultMessage="Change your temporary password to keep your account secure." />
          <AccountPassword
            maxLimitError={this.state.maxLimitError}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            errors={errors}
            form={settings.passwordForm}
            genericErrors={genericErrors}
            onChange={this.onChangePassword} />
          <div className="step-buttons">
            <LargeRaisedButton
              primary
              className="update-button"
              key="update-button"
              label={<FormattedMessage id="CHANGE_PASSWORD" defaultMessage="Change password" />}
              type="submit" />
          </div>
          <div className="captcha-badge-container">
            <CaptchaBadge />
          </div>
        </Form>
      </Dialog>
    )
  }
}

ChangePasswordDialog.defaultProps = {
  dialogType: dialogTypes.CHANGE_PASSWORD
}

export { ChangePasswordDialog }
