import React from 'react'
import { Route } from 'react-router-dom'
import { TeamTabs } from './TeamTabs'

export const Team = props => {
  const { match } = props

  return (
    <div className="team-wrapper">
      <Route
        path={match.url}
        render={() => <TeamTabs {...props} type={match.params.type} /> }/>
    </div>
  )
}
