import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import adminActions from '../../../../../../actions/admin'
import { AuthPicker } from '../../../../Admin/AdminAuth/AuthApps/AuthPicker'
import { Loading } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import { FooterActionButtons } from '../../../../../Common/FooterActionButtons'
import { SamlOptions } from '../../../../Admin/AdminAuth/AuthApps/SamlOptions'

import './SAMLAuth.css'
import { NEXT_GEN_FIELD_TYPES } from '../../../../../Common/FormBuilder/NextGenFieldTypes'

class SAMLAuth extends React.Component {
  componentDidMount() {
    this.props.onAuthAppLoad()
  }

  render() {
    const { auth } = this.props
    const { authApp } = auth

    const onSave = () => {
      authApp.hasChanged && this.props.onAuthAppSave(authApp.item)
    }

    const onCancel = () => {
      this.props.onAuthAppCancel()
    }

    return (
      <div className={classNames('saml-auth', this.props.className)}>
        <Loading
          isLoading={authApp.isLoading}
          error={
            authApp.loadError
              ? (
              <FormattedMessage
                id={authApp.loadError}
                defaultMessage="Failed to load SAML configuration" />
                )
              : null
          }>
          <div className="saml-auth-content">
            <AuthPicker
              authApp={authApp}
              onChange={this.props.onAuthAppChange}
              onError={this.props.onAuthAppError} />
            <SamlOptions
              fieldTypes={NEXT_GEN_FIELD_TYPES}
              authApp={authApp}
              onChange={this.props.onAuthAppChange}
              withLdapPicker={false} />
            {authApp.hasChanged ? <FooterActionButtons onCancel={onCancel} onSave={onSave} /> : null}
          </div>
        </Loading>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.admin.auth
})

export default connect(mapStateToProps, adminActions)(SAMLAuth)
