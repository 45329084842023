import { withRouter } from 'react-router-dom'
import { makeContainer } from './makeContainer'

import * as home from '../App/Home'
import * as contacts from '../App/Home/Contacts'
// import * as devices from '../App/Home/Devices'
import * as activities from '../App/Home/Activities'
import * as assignments from '../App/Home/Assignments'
import * as profile from '../App/Home/Profile'
import * as calls from '../App/Home/Calls'
import * as media from '../App/Home/Media'
import * as team from '../App/Home/Team'
import * as admin from '../App/Home/Admin'
import * as applications from '../App/Home/Applications'

const Home = withRouter(makeContainer(home.Home))
const Contacts = withRouter(makeContainer(contacts.Contacts))
// const Devices = withRouter(makeContainer(devices.Devices))
const Activities = withRouter(makeContainer(activities.Activities))
const Assignments = withRouter(makeContainer(assignments.Assignments))
const Profile = withRouter(makeContainer(profile.Profile))
const Calls = withRouter(makeContainer(calls.Calls))
const Media = withRouter(makeContainer(media.Media))
const Team = withRouter(makeContainer(team.Team))
const Admin = withRouter(makeContainer(admin.Admin))
const Applications = withRouter(makeContainer(applications.Applications))

export {
  Home,
  Contacts,
  /* Devices, */
  Activities,
  Assignments,
  Profile,
  Calls,
  Media,
  Team,
  Admin,
  Applications
}

export { makeContainer } from './makeContainer'
export { history } from './history'
