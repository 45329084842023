import React from 'react'
import IconButton from 'material-ui/IconButton'
import NavigationMenuIcon from 'material-ui/svg-icons/navigation/menu'
import { FormattedMessage } from 'react-intl'

import './NavigationController.css'

const NavigationController = props => {
  return (
    <div className="navigation-controller-wrapper">
      <IconButton
        tooltipPosition="bottom-right"
        tooltip={<FormattedMessage
          id="VIEW_NAVIGATION"
          defaultMessage="View navigation" />}
        onTouchTap={props.handleClick}
        className="hamburger-button">
        <NavigationMenuIcon />
      </IconButton>
    </div>
  )
}

export { NavigationController }
