import React from 'react'
import { Toggle, SelectField, MenuItem } from 'material-ui'
import { FormattedMessage } from 'react-intl'
import { RequiredLabel } from 'skylight-common'
import './BandwidthSection.css'

const BANDWIDTH_VALUES = [1000, 500, 300, 150]

const BandwidthSection = (props) => {
  const onToggle = (v) => {
    props.onChange('bandwidthLimitEnabled', v, 'bandwidthForm')
    if (v) {
      props.onChange('bandwidthLimit', 1000, 'bandwidthForm')
    }
  }
  return (
    <div className="bandwidth-section toggle-section">
      <div className="title">
        <FormattedMessage
          id="BANDWIDTH_LIMIT"
          defaultMessage="Bandwidth Limit" />
      </div>
      <div className="description">
        <FormattedMessage id="BANDWIDTH_LIMIT_DESCRIPTION" />
      </div>
      <Toggle
        className="toggle-control"
        name="bandwidthLimitEnabled"
        toggled={props.form.bandwidthLimitEnabled}
        onToggle={(e, v) => onToggle(v)} />
      {props.form.bandwidthLimitEnabled && <SelectField
        className="bandwidth-select dropdown-with-hover"
        floatingLabelText={<RequiredLabel><FormattedMessage id="MAX_BANDWIDTH" /></RequiredLabel>}
        value={props.form.bandwidthLimit || 1000}
        selectedMenuItemStyle={{ color: '#00aaed' }}
        onChange={(e, k, v) => props.onChange('bandwidthLimit', v, 'bandwidthForm')}>
        {BANDWIDTH_VALUES.map(x => <MenuItem key={x} value={x}
          primaryText={<span>{x} <FormattedMessage id="KBIT_SECOND_MEASURE" /></span>} />)}
      </SelectField>}
    </div>
  )
}

export { BandwidthSection }
