import * as ActionTypes from '../../actions/types'
import { addOrReplaceItemByField, addItem } from '../../lib/array'

const initialState = {
  userGroups: []
}

const userGroups = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CONTACT_LOAD_GROUPS:
      return {
        ...state,
        userGroups: addItem(
          state.userGroups,
          {
            userId: action.userId,
            isLoading: true
          }
        )
      }
    case ActionTypes.CONTACT_GROUPS_LOADED:
      return {
        ...state,
        userGroups: addOrReplaceItemByField(
          state.userGroups,
          {
            userId: action.userId,
            userGroups: action.groups,
            isLoading: false
          },
          'userId',
          action.userId
        )
      }
    case ActionTypes.CONTACT_LOAD_GROUPS_ERROR:
      return {
        ...state,
        userGroups: addOrReplaceItemByField(
          state.userGroups,
          {
            userId: action.userId,
            userGroups: [],
            isLoading: false,
            error: action.error
          },
          'userId',
          action.userId
        )
      }
    case ActionTypes.ROUTER_LOCATION_CHANGE:
      return {
        ...state,
        userGroups: []
      }

    default:
      return state
  }
}

export default userGroups
