import React from 'react'
import classNames from 'classnames'
import IconButton from 'material-ui/IconButton'
import { CursorTooltip } from 'skylight-common'

import './TopBarActionButton.css'

const TopBarActionButton = props => {
  const handleClick = e => {
    props.onClick && props.onClick(e)
  }

  const className = classNames('top-bar-action-button', props.className, { active: props.active })
  return (
    <div onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} onClick={handleClick} className={className}>
      <CursorTooltip tooltip={props.tooltip}>
        <IconButton disableTouchRipple>
          {props.children}
        </IconButton>
      </CursorTooltip>
    </div>
  )
}

export { TopBarActionButton }
