import _ from 'lodash'

class AbortError extends Error {
  name = 'AbortError'
  message = 'Aborted'
}

export const uploadedMediaRequestCreator = (method, onRequestProgress) => (url, body, headers, options) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open(method, url)

    const abort = () => {
      xhr.abort()
    }

    if (headers) {
      Object.keys(headers).forEach(key => {
        if (headers[key] === undefined || headers[key] === null) {
          delete headers[key]
        } else {
          xhr.setRequestHeader(key, headers[key])
        }
      })
    }

    xhr.onload = function() {
      if (this.status >= 200 && this.status < 300) {
        resolve({
          response: xhr.response,
          etag: xhr.getResponseHeader('etag')
        })
      } else {
        const error = new Error('XHR error')
        error.response = xhr.response
        reject(error)
      }
    }

    xhr.onerror = reject

    if (xhr.upload && onRequestProgress) {
      xhr.upload.onprogress = _.throttle(e => {
        // e.loaded depends on the content-length of the request
        // content-length of the request is defined by file and metadata
        // extract approximate length of metadata from total uploaded size
        onRequestProgress(e.loaded - 183)
      }, 200)
    }

    if (options.abortController) {
      const { signal } = options.abortController
      signal.addEventListener('abort', abort)

      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          signal.removeEventListener('abort', abort)
        }
      }
    }

    xhr.onabort = function() {
      reject(new AbortError())
    }

    xhr.send(body)
  })
}
