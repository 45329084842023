import React from 'react'
import Chip from 'material-ui/Chip'

const CardTags = (props) => {
  const tags = props.item.cardType === 'media' ? props.item.card.tags : props.item.tags
  if (!tags || !Array.isArray(tags)) return null

  return <div className="attachment-tag-wrapper">{tags.map((t, i) =>
    <Chip key={i} className="attachment-tag-chip">{t}</Chip>)}
  </div>
}

export { CardTags }
