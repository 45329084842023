import * as ActionTypes from './types'
import { createFilterActions, createListActions } from 'skylight-common'
import skylight from 'skylight-js-sdk'
import { push } from 'react-router-redux'
import { getUserCallStatus } from '../lib/calls'
import { CALL_DEFAULT_QUERY as DEFAULT_QUERY } from '../lib/contact'

const excludeMe = (users, getState) => {
  const state = getState ? getState() : null
  return state ? users.filter(u => u.id !== state.auth.user.id) : users
}

const getCallStatus = (users, getState) => {
  const state = getState ? getState() : null
  if (!state) {
    return users
  }

  const { users: presenceUsers } = state.presence

  return users.map(u =>
    ({ ...u, status: getUserCallStatus(presenceUsers.filter(p => p.userId === u.id)) })
  )
}

const loadUsers = async(query, ctx) => {
  // Removed filter props from query since Warden doesn't support url queries yet.
  // Delete two lines below when support will be added.
  const q = { ...query }
  delete q.filter
  let users = await skylight.user.list({ ...q })

  users.forEach(u => {
    u.groups = u.groups || []
  })
  // TODO: Remove if/when implemented on backend.
  if (query.includeMe === false) {
    users = excludeMe(users, ctx.getState)
  }

  users = getCallStatus(users, ctx.getState)
  return users
}

const LIST_ACTIONS = createListActions({
  prefix: ActionTypes.PREFIX_CALL_USER.toUpperCase(),
  handler: ActionTypes.PREFIX_CALL_USER,
  onGetList: s => s.callUser.list,
  onLoad: loadUsers
})

const FILTER_ACTIONS = createFilterActions({
  baseUrl: '/calls',
  redirect: false,
  selector: state => state.callUser.list.query,
  listPrefix: ActionTypes.PREFIX_CALL_USER.toUpperCase(),
  actionPrefix: ActionTypes.PREFIX_CALL_USER,
  DEFAULT_QUERY,
  loadItems: LIST_ACTIONS.onCallUserLoadItems,
  push
})

export default {
  ...LIST_ACTIONS,
  ...FILTER_ACTIONS
}
