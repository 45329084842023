
import { combineReducers } from 'redux'
import list from './list'
import permissions from './permissions'
import selectedApplication from './selectedApplication'
import session from './session'
import event from './event'
import participant from './participant'
import role from './role'
import version from './version'
import newApplication from './newApplication'
import applicationExport from './export'
import applicationImport from './import'
import applicationUpgrade from './upgrade'

export default {
  list,
  permissions,
  selectedApplication,
  newApplication,
  applicationExport,
  applicationImport,
  applicationUpgrade,
  session: combineReducers(session),
  event: combineReducers(event),
  participant: combineReducers(participant),
  role: combineReducers(role),
  version: combineReducers(version)
}
