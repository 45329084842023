import React from 'react'
import { TeamList, TeamGroupList } from '../../../../containers/users'
import { Redirect } from 'react-router-dom'
import { TabBar } from 'skylight-common'

const DEFAULT_PATHNAME = '/team/users'

const TABS = [
  {
    label: { id: 'TAB_USERS', defaultMessage: 'Users' },
    value: 'users',
    renderChildren: (type) => type === 'users' && <TeamList />
  },
  {
    label: { id: 'TAB_GROUPS', defaultMessage: 'Groups' },
    value: 'groups',
    renderChildren: (type, props) => type !== 'users' && <TeamGroupList {...props} />
  }
]

export const TeamTabs = props => {
  const type = props.type
  if (!type) {
    return <Redirect to={{ pathname: DEFAULT_PATHNAME }} />
  }

  const onChange = v => props.redirect(`/team/${v}`)

  return <TabBar tabs={TABS} className="team-tabs" onChange={onChange} type={type} />
}
