// import React from 'react'
import { isCaptchaEnabled, getSiteKey } from './captcha'

let isScriptLoaded = false
const CaptchaScript = () => {
  if (!isCaptchaEnabled()) return null
  if (!isScriptLoaded) {
    isScriptLoaded = true
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${getSiteKey()}`
    document.body.appendChild(script)
  }

  return null
}

export default CaptchaScript
