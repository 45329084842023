import * as ActionTypes from '../actions/types'

const initialState = {
  open: false,
  type: null,
  status: null,
  remain: false
}

const STATUS_IN_PROGRESS = 'inProgress'
const STATUS_QUEUED = 'queued'
const IN_PROGRESS_STATUSES = [STATUS_IN_PROGRESS, STATUS_QUEUED]

const job = (state = initialState, payload) => {
  switch (payload.type) {
    case ActionTypes.JOB_RESET_STATUS:
      return { ...initialState }
    case ActionTypes.JOB_TASK_STATUS_CHANGED:
      return {
        open: true,
        type: 'tasks',
        remain: payload.status in IN_PROGRESS_STATUSES,
        assignmentId: payload.assignmentId,
        status: payload.status === STATUS_QUEUED ? STATUS_IN_PROGRESS : payload.status
      }
    case ActionTypes.APP_ERROR:
      return payload.error
    default: return state
  }
}

export default job
