const LOCALE = navigator.language || 'en-US'
const DEFAULT_FORMATTER = new Intl.DateTimeFormat(LOCALE,
  { year: 'numeric', month: '2-digit', day: '2-digit' })

export const formatDate = (date, config = null) => {
  const formatter = config
    ? new Intl.DateTimeFormat(LOCALE, config)
    : DEFAULT_FORMATTER

  // Should work if date is a Date object by itself too.
  return formatter.format(new Date(date))
}

export const secondsToTimeString = seconds => {
  seconds = parseInt(seconds, 10)
  let hours = Math.floor(seconds / 3600)
  let minutes = Math.floor((seconds - (hours * 3600)) / 60)
  seconds = seconds - (hours * 3600) - (minutes * 60)

  hours = hours < 10 ? `0${hours}` : hours
  minutes = minutes < 10 ? `0${minutes}` : minutes
  seconds = seconds < 10 ? `0${seconds}` : seconds

  return `${hours}:${minutes}:${seconds}`
}
