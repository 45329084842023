import React from 'react'
import { GridView, MediaGridItem, isMobile } from 'skylight-common'
import { listMediaItems, onCheckMediaItem } from '../attachment-utils'
import media from '../../../../../lib/media'

import './DecisionMedia.css'

export const DecisionMedia = props => {
  const mediaItems = listMediaItems(props.item.media, props.attachment.list.query)
  if (mediaItems.length === 0) return null
  const { selectedIds } = props.attachment.list
  const isDetails = props.isDetails

  const onSelectMediaItems = (item, e) => {
    return media.onSelect(item, e, { selectedIds, items: mediaItems }, props.onAssignmentAttachmentSelectItems)
  }

  const onOpenPreview = (item, e) => {
    return media.onPreview(item, e, props.onAssignmentAttachmentPreviewItem)
  }

  return (
    <div className="decision-media">
      <GridView
        className="media-grid-view"
        items={mediaItems}
        query={{}}
        heading={''}
        total={mediaItems.length} // TODO: Test, maybe should provide filtered items length.
        renderItem={<MediaGridItem /* tooltipProps={TOOLTIP_PROPS} */ />}
        selectedIds={selectedIds}
        cols={isDetails ? 3 : selectedIds.length === 1 ? 4 : 6}
        done={true}
        hideCheckbox={true}
        isDoubleClick={!isMobile()}
        onQuery={() => {}}
        onSelect={onSelectMediaItems}
        onCheck={isDetails ? null : onCheckMediaItem(props)}
        onOpenPreview={onOpenPreview}/>
    </div>
  )
}
