import * as ActionTypes from '../actions/types'

const initialState = {}

const snackbar = (state = initialState, payload) => {
  switch (payload.type) {
    case ActionTypes.SNACKBAR_SHOW:
      return { ...state, [payload.snackbarType]: { open: true, params: payload.params } }
    case ActionTypes.SNACKBAR_HIDE:
      return { ...state, [payload.snackbarType]: { open: false, params: null } }
    default:
      return state
  }
}

export default snackbar
