import React from 'react'
import { OutlinedTextField } from '../../Form/OutlinedTextField'

export const FieldText = props => {
  const {
    name,
    value,
    label,
    disabled,
    required,
    multiline,
    error,
    onChange
  } = props

  return <div className={`field field-text ${required ? 'required' : ''}`}>
    <OutlinedTextField
      name={name}
      required={required}
      value={value || ''}
      disabled={disabled}
      errorText={error}
      floatingLabelText={label}
      multiLine={multiline}
      onChange={(e, value) => onChange(name, value)} />
  </div>
}
