import { connect } from 'react-redux'
import appActions from '../actions/app'

const mapStateToProps = (state, props) => {
  return {
    app: { ...state.app },
    router: { ...state.router }
  }
}

const dispatchActions = {
  ...appActions
}

const makeContainer = comp => connect(mapStateToProps, dispatchActions)(comp)

export { makeContainer }
