import React from 'react'

import { Breadcrumbs } from '../../../../../../containers/breadcrumbs'

import './AppDashboardBreadcrumbs.css'

const AppDashboardBreadcrumbs = props => {
  return <div className="app-dashboard-breadcrumbs">
    <Breadcrumbs transitionIcon="›" />
  </div>
}

export { AppDashboardBreadcrumbs }
