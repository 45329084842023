import { connect } from 'react-redux'
import appActions from '../actions/app'
import assignmentActions from '../actions/assignment'
import contactActions from '../actions/contact'
import workflowActions from '../actions/workflow'
import dialogActions from '../actions/dialog'
import mediaActions from '../actions/media'
import errorActions from '../actions/error'

import { AssignmentList as AssignmentListComp } from '../App/Home/Assignments/AssignmentList/AssignmentList'
import { AssignmentDetails as AssignmentDetailsComp } from '../App/Home/Assignments/AssignmentDetails/AssignmentDetails'
import { AssignmentActionMenu as AssignmentActionMenuComp } from '../App/Home/Assignments/AssignmentDetails/AssignmentActionMenu'
import { AssignmentTitle as AssignmentTitleComp } from '../App/Home/Assignments/AssignmentDetails/AssignmentTitle'
import { AssignWorkflow as AssignWorkflowComp } from '../App/Home/Assignments/AssignmentDetails/AssignWorkflow'
import { AssignmentAttachments as AssignmentAttachmentsComp } from '../App/Home/Assignments/AssignmentAttachments'
import { AttachmentDetails as AttachmentDetailsComp } from '../App/Home/Assignments/AssignmentAttachments/AttachmentDetails'
import {
  AssignmentFilter as AssignmentFilterComp,
  AssignmentTextFilter as AssignmentTextFilterComp,
  MyAssignmentTextFilter as MyAssignmentTextFilterComp
} from '../App/Home/Assignments/AssignmentFilter'
import {
  AttachmentTextFilter as AttachmentTextFilterComp
} from '../App/Home/Assignments/AssignmentAttachments/AttachmentFilter'
import { AttachmentIcon as AttachmentIconComp } from '../App/Home/Assignments/AssignmentAttachments/AttachmentIcon'

import { MediaAttachments as MediaAttachmentsComp } from '../App/Home/Assignments/AssignmentAttachments/MediaAttachments'
import { DecisionMedia as DecisionMediaComp } from '../App/Home/Assignments/AssignmentAttachments/DecisionAttachments/DecisionMedia'
import { DecisionAttachments as DecisionAttachmentsComp } from '../App/Home/Assignments/AssignmentAttachments/DecisionAttachments'
import { TextInputAttachments as TextInputAttachmentsComp } from '../App/Home/Assignments/AssignmentAttachments/TextInputAttachments'
import { FooterActionButtons } from 'skylight-common'

const list = (state, props) => ({
  scopes: state.auth.scopes,
  user: state.auth.user,
  assignmentList: state.assignment.list,
  attachment: state.assignment.attachment
})

const listActions = {
  ...assignmentActions,
  ...dialogActions,
  onMediaByAssignment: mediaActions.onMediaByAssignment,
  onUserSelectItems: contactActions.onUserSelectItems,
  onShowSnackbar: errorActions.onShowError,
  redirect: appActions.redirect
}

const details = (state, props) => ({
  assignmentItem: state.assignment.item,
  scopes: state.auth.scopes,
  isAttachmentLoading: state.assignment.attachment.isLoading
})

const detailsActions = {
  ...assignmentActions,
  ...dialogActions,
  onMediaByAssignment: mediaActions.onMediaByAssignment,
  onShowSnackbar: errorActions.onShowError
}

const wf = (state, props) => ({
  workflow: state.workflow
})

const wfActions = {
  ...assignmentActions,
  ...workflowActions
}

const filter = (state, props) => ({
  query: state.assignment.list.query,
  user: state.auth.user,
  layout: state.layout
})

const filterActions = {
  ...assignmentActions,
  onUserSelectItems: contactActions.onUserSelectItems
}

const attachment = (state, props) => ({
  attachment: state.assignment.attachment
})

const attachmentActions = {
  ...assignmentActions,
  onConfirm: dialogActions.onConfirm,
  onDownloadAttachment: mediaActions.onDownloadMediaItem
}

const attachmentFilter = (state, props) => ({
  items: state.assignment.attachment.list.items,
  query: state.assignment.attachment.list.query,
  layout: state.layout
})

const attachmentIconActions = {
  onLoadAttachment: assignmentActions.onLoadAttachment
}

const decisionMedia = (state) => ({
  attachment: state.assignment.attachment
})
const decisionMediaActions = assignmentActions

const mediaAttachment = (state) => ({
  attachment: state.assignment.attachment
})
const mediaAttachmentActions = assignmentActions

const textInputAttachment = (state) => ({
  attachment: state.assignment.attachment
})
const textInputAttachmentActions = assignmentActions

const attachmentItem = (state) => ({
  item: state.assignment.attachment.item
})

const AssignmentList = connect(list, listActions)(AssignmentListComp)
const AssignmentDetails = connect(details, detailsActions)(AssignmentDetailsComp)
const AssignmentActionMenu = connect(details, detailsActions)(AssignmentActionMenuComp)
const AssignmentTitle = connect(details, detailsActions)(AssignmentTitleComp)
const AssignWorkflow = connect(wf, wfActions)(AssignWorkflowComp)
const AssignmentFilter = connect(filter, filterActions)(AssignmentFilterComp)
const AssignmentTextFilter = connect(filter, filterActions)(AssignmentTextFilterComp)
const MyAssignmentTextFilter = connect(filter, filterActions)(MyAssignmentTextFilterComp)
const AssignmentAttachments = connect(attachment, attachmentActions)(AssignmentAttachmentsComp)
const AttachmentDetails = connect(attachment, attachmentActions)(AttachmentDetailsComp)
const AttachmentTextFilter = connect(attachmentFilter, assignmentActions)(AttachmentTextFilterComp)
const AttachmentIcon = connect(attachment, attachmentIconActions)(AttachmentIconComp)

const MediaAttachments = connect(mediaAttachment, mediaAttachmentActions)(MediaAttachmentsComp)
const DecisionAttachments = connect(attachment, attachmentActions)(DecisionAttachmentsComp)
const DecisionMedia = connect(decisionMedia, decisionMediaActions)(DecisionMediaComp)
const TextInputAttachments = connect(textInputAttachment, textInputAttachmentActions)(TextInputAttachmentsComp)

const AttachmentActionButtons = connect(attachmentItem, assignmentActions)(FooterActionButtons)

export {
  AssignmentList, AssignmentDetails, AssignmentActionMenu,
  AssignmentTitle, AssignWorkflow, AssignmentFilter, AssignmentTextFilter,
  MyAssignmentTextFilter, AssignmentAttachments, AttachmentDetails,
  AttachmentTextFilter, AttachmentIcon,
  MediaAttachments, DecisionAttachments, DecisionMedia, TextInputAttachments,
  AttachmentActionButtons
}
