import React from 'react'
import { Snackbar } from 'skylight-common'
import DoneIcon from 'material-ui/svg-icons/action/done'
import { FormattedMessage } from 'react-intl'

import './SnackbarComplete.css'

const DEFAULT_AUTOCLOSE_TIMEOUT = 3

const SnackbarComplete = props => {
  const { snackbar, snackbarType } = props
  const { open, params } = snackbar[snackbarType] || {}

  const handleClose = () => {
    props.onHideSnackbar(snackbarType)
  }

  const renderMessage = () => {
    const { message } = params
    return <FormattedMessage id={message} defaultMessage="Complete" />
  }

  const autoCloseTimeout = params.autoCloseTimeout || DEFAULT_AUTOCLOSE_TIMEOUT
  return (
    <Snackbar autocloseSeconds={autoCloseTimeout} className="snackbar-complete" open={open} onRequestClose={handleClose}>
      <div className="done-icon">
        <DoneIcon />
      </div>
      <div className="snackbar-message">
        {renderMessage()}
      </div>
    </Snackbar>
  )
}

export { SnackbarComplete }
