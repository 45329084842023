import React from 'react'
import { FormattedMessage } from 'react-intl'
// import IconButton from 'material-ui/IconButton'
import {
  Table, GridHeader, Column, Tooltip,
  /* MaterialIcon, */ formatDateTime
} from 'skylight-common'

import './CallUserSessions.css'

const SESSION_DETAILS_PROPS = [
  {
    name: 'application',
    label: { id: 'APPLICATION', defaultMessage: 'Application' },
    render: item => {
      if (!item.application || !item.application.name) {
        return <FormattedMessage id="NOT_AVAILABLE" defaultMessage="N/A" />
      }

      return <span>{`${item.application.name} ${item.application.version}`}</span>
    }
  },
  {
    name: 'device',
    label: { id: 'DEVICE', defaultMessage: 'Device' },
    render: item => renderDeviceInfo(item.device)
  },
  { name: 'created', label: { id: 'CONNECTED_SINCE', defaultMessage: 'Connected Since' }, render: item => <span>{formatDateTime(item.created)}</span> }
]

const renderDeviceInfo = device => {
  if (!device || !device.name) {
    return <FormattedMessage id="NOT_AVAILABLE" defaultMessage="N/A" />
  }

  const deviceType = device.type ? `${device.type[0].toUpperCase()}${device.type.slice(1)}` : ''

  return <span>{`${deviceType} (${device.name} ${device.version})`}</span>
}

const CallUserSessions = props => {
  const { sessions } = props

  const renderSessionDetails = item => <div className="session-details">
        {SESSION_DETAILS_PROPS.map(prop => {
          return <div className="session-details-item" key={prop.name}>
            <FormattedMessage {...prop.label} />
            <span>: {prop.render ? prop.render(item) : item[prop.name]}</span>
          </div>
        })}
    </div>

  // NOTE: Commented until an ability to remove other users sessions will be available on API side.
  // const renderActionsColumn = () => {
  //   return <Column
  //     key="actions"
  //     title=""
  //     className="actions"
  //     render={item => <div className="list-actions">
  //       <IconButton
  //         className="remove-member"
  //         tooltip={<FormattedMessage id="ACTION_REMOVE" defaultMessage="Remove" />}
  //         onTouchTap={() => props.onRemoveSession(item)} >
  //         <MaterialIcon icon="cancel" />
  //       </IconButton>
  //     </div>} />
  // }

  return <div className="call-user-sessions dashboard-list">
    <Table
      showHover={true}
      items={sessions}
      query={{}}
      name="callsUserSessionList"
      tableHeading={props => <GridHeader
        {...props}
        title={<FormattedMessage id="ACTIVE_SESSIONS" defaultMessage="Active sessions" />} />
      }
      showRowNames={false}
      disableRowSelecting={true} >
      <Column
        key="device"
        name="device"
        sortable={false}
        className="session-device"
        title={<FormattedMessage id="DEVICE" defaultMessage="Device" />}
        render={item => <Tooltip
          horizontalPosition="left"
          verticalPosition="bottom"
          tooltip={renderSessionDetails(item)}>
            {renderDeviceInfo(item.device)}
          </Tooltip>} />
      <Column
        key="presence"
        name="presence"
        sortable={false}
        className="session-presence"
        title={<FormattedMessage id="PRESENCE" defaultMessage="Presence" />}
        render={item => <div>{item.presence}</div>} />
      {/* renderActionsColumn() */}
    </Table>
  </div>
}

export { CallUserSessions }
