import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const nextGenApplicationNew = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M11,1 L11,9.5 L2,13.5 L2,5.5 L11,1 Z M13,1 L22,5.5 L22,13.5 L13,9.5 L13,1 Z M12,15.5 L14.8108129,16.6242248 C14.2965647,17.4609627 14,18.4458481 14,19.5 C14,20.7870887 14.4421097,21.9709171 15.1827506,22.9079068 L13,24 L11,24 L2,19.5 L12,15.5 Z M21,16 L21,19 L24,19 L24,21 L21,21 L21,24 L19,24 L19,21 L16,21 L16,19 L19,19 L19,16 L21,16 Z M12,11 L18.7748905,14.0473734 C17.7870727,14.1774708 16.8818649,14.5697157 16.1314316,15.1519437 L12,13.5 L2,17.5 L2,15.5 L12,11 Z" />
    </SvgIcon>
  )
}

export default nextGenApplicationNew
