import React from 'react'
import { UserDetails } from '../../../Contacts/UserDetails'
import { CallUserSessions } from '../CallUserSessions'
import './CallUserDetails.css'

const CallUserDetails = props => {
  const { presenceUsers, user } = props

  const renderSessions = () => {
    const sessions = (presenceUsers || []).filter(s => s.userId === user.item.id)
    if (!sessions.length) {
      return null
    }

    return <CallUserSessions {...props} sessions={sessions} />
  }

  return <div className="call-user-details">
    <UserDetails {...props} />
    {renderSessions()}
  </div>
}

export { CallUserDetails }
