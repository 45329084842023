import { Call as CallComp, CallNotification as CallNotificationComp } from '../App/Call'

import callSdk from 'skylight-call-sdk'

import { connect } from 'react-redux'

const callState = (state) => ({
  call: state.call,
  user: state.auth.user
})

const callActions = {
  ...callSdk.actions.attachment,
  ...callSdk.actions.chat,
  ...callSdk.actions.connection,
  ...callSdk.actions.device,
  ...callSdk.actions.media,
  ...callSdk.actions.ui
}

const Call = connect(callState, callActions)(CallComp)
const CallNotification = connect(callState, callActions)(CallNotificationComp)

export { Call, CallNotification }
