import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const scanCompleted = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M4,4 L10,4 L10,10 L4,10 L4,4 L4,4 Z M20,4 L20,10 L14,10 L14,4 L20,4 L20,4 Z M14,15 L16,15 L16,13 L14,13 L14,11 L16,11 L16,13 L18,13 L18,11 L20,11 L20,13 L18,13 L18,15 L20,15 L20,18 L18,18 L18,20 L16,20 L16,18 L13,18 L13,20 L11,20 L11,16 L14,16 L14,15 L14,15 Z M16,15 L16,18 L18,18 L18,15 L16,15 L16,15 Z M4,20 L4,14 L10,14 L10,20 L4,20 L4,20 Z M6,6 L6,8 L8,8 L8,6 L6,6 L6,6 Z M16,6 L16,8 L18,8 L18,6 L16,6 L16,6 Z M6,16 L6,18 L8,18 L8,16 L6,16 L6,16 Z M4,11 L6,11 L6,13 L4,13 L4,11 L4,11 Z M9,11 L13,11 L13,15 L11,15 L11,13 L9,13 L9,11 L9,11 Z M11,6 L13,6 L13,10 L11,10 L11,6 L11,6 Z M2,2 L2,6 L0,6 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 L6,0 L6,2 L2,2 L2,2 Z M22,0 C23.1045695,6.76353751e-17 24,0.8954305 24,2 L24,6 L22,6 L22,2 L18,2 L18,0 L22,0 L22,0 Z M2,18 L2,22 L6,22 L6,24 L2,24 C0.8954305,24 1.3527075e-16,23.1045695 0,22 L0,18 L2,18 L2,18 Z M22,22 L22,18 L24,18 L24,22 C24,23.1045695 23.1045695,24 22,24 L18,24 L18,22 L22,22 Z" id="path-1"></path>
    </SvgIcon>
  )
}

export default scanCompleted
