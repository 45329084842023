import React from 'react'
import { Table, Column, FormattedDateTime } from 'skylight-common'
import { FormattedMessage } from 'react-intl'

import './BweForVideo.css'

const formatSpeed = (v) => {
  if (v === -1) return <FormattedMessage id="NOT_AVAILABLE" defaultMessage="n/a" />
  return Math.round((v || 0) / 1024) + ' KB/s'
}

const BweForVideo = props => {
  const list = props.list

  const onQuery = query => {
    return props.onCallBweForVideoQuery({ ...query, callId: props.callId })
  }

  return <div className="ssrc-video-send">
    <Table
    showCheckboxes={false}
    showHover={true}
    query={list.query}
    items={list.items}
    total={list.total}
    isLoading={list.isLoading}
    done={list.done || !!list.error}
    selectedRows={list.selectedIds}
    name="bweForVideoReport"
    emptyLabel={<FormattedMessage
      id="EMPTY_CALL_BWE_REPORT_LIST"
      defaultMessage="There are no BWE for video logs." />
    }
    onQuery={onQuery} >
      <Column
        name="createdAt"
        sortable={true}
        title={<FormattedMessage id="CALL_REPORT_TIMESTAMP" defaultMessage="Timestamp"/>}
        render={item => <FormattedDateTime value={item.createdAt}/>} />
      <Column
        name="source"
        sortable={false}
        title={<FormattedMessage id="CALL_REPORT_SOURCE" defaultMessage="Source"/>} />
      <Column
        name="sendBandwidth"
        sortable={false}
        title={<FormattedMessage id="CALL_REPORT_SEND_BANDWIDTH" defaultMessage="Send Bandwidth"/>}
        render={item => formatSpeed(item.availableOutgoingBitrate / 8)} />
      <Column
        name="sendSpeed"
        sortable={false}
        title={<FormattedMessage id="CALL_REPORT_SEND_SPEED" defaultMessage="Send Bandwidth"/>}
        render={item => formatSpeed(item.sendSpeed)} />
      <Column
        name="receiveSpeed"
        sortable={false}
        title={<FormattedMessage id="CALL_REPORT_RECEIVE_SPEED" defaultMessage="Receive Bandwidth"/>}
        render={item => formatSpeed(item.receiveSpeed)} />
      <Column
        name="transmitBitrate"
        sortable={false}
        title={<FormattedMessage id="CALL_REPORT_TRANSMIT_BYTE_RATE" defaultMessage="Transmit Byterate"/>}
        render={item => formatSpeed(item.transmitBitrate / 8)} />
    </Table>
  </div>
}

export { BweForVideo }
