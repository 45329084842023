import React from 'react'
import { Snackbar } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import WarningIcon from 'material-ui/svg-icons/alert/warning'

import './SnackbarIncompatibleVersionError.css'

const SnackbarIncompatibleVersionError = props => {
  const { snackbar, snackbarType } = props
  const { open } = snackbar[snackbarType] || {}

  const handleClose = () => {
    props.onHideSnackbar(snackbarType)
  }

  return (
    <Snackbar autocloseSeconds={3} className="snackbar-incompatible-version" open={open} onRequestClose={handleClose}>
      <div className="warning-icon">
        <WarningIcon />
      </div>
      <div className="snackbar-incompatible-version__container">
        <FormattedMessage id="INCOMPATIBLE_IMPORT_VERSION" defaultMessage="Incompatible version - Application exported from newer platform version is incompatible with this environment." />
      </div>
    </Snackbar>
  )
}

export { SnackbarIncompatibleVersionError }
