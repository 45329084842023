import React from 'react'
import { FilterToolbar } from 'skylight-common'
import { DEFAULT_CALL_EVENT_QUERY } from '../../../../../lib/activity'
import { ByCallEventType } from './ByCallEventType'

import './CallEventFilter.css'

const FILTERS = [
  { text: 'CALL_EVENT_TYPE', value: 'type', comp: ByCallEventType }
]

const CallEventFilter = props => <FilterToolbar
  className="call-event-filter"
  filters={FILTERS}
  query={props.query}
  layout={props.layout}
  defaultQuery={DEFAULT_CALL_EVENT_QUERY}
  onFilterUpdated={props.onCallEventFilterUpdated}
  onFiltersUpdated={props.onCallEventFiltersUpdated}
  onFilterRemoved={props.onCallEventFilterRemoved} />

export { CallEventFilter }
