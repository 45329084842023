import React from 'react'
import { TabBar } from 'skylight-common'
import { Redirect } from 'react-router-dom'
import TokensAndTimers from '../TokensAndTimers/TokensAndTimers'
import SAMLAuth from '../SAML/SAMLAuth'
import LDAP from '../LDAP/LDAP'

import './AuthenticationTabs.css'

const DEFAULT_PATHNAME = '/ng/auth/tokens'

const TABS = [
  {
    label: { id: 'TOKENS_AND_TIMERS_TAB', defaultMessage: 'Tokens & timers' },
    value: 'tokens',
    renderChildren: (type, props) => type === 'tokens' && <TokensAndTimers className="tab-bar-content" />
  },
  {
    label: { id: 'SAML_TAB', defaultMessage: 'SAML' },
    value: 'saml',
    renderChildren: (type, props) => type === 'saml' && <SAMLAuth className="tab-bar-content" />
  },
  {
    label: { id: 'LDAP_TAB', defaultMessage: 'LDAP' },
    value: 'ldap',
    renderChildren: (type, props) => type === 'ldap' && <LDAP className="tab-bar-content" />
  }
]

const AuthenticationTabs = props => {
  const onChange = v => props.redirect(`/ng/auth/${v}`)

  const { type } = props
  if (!type) {
    return <Redirect to={{ pathname: DEFAULT_PATHNAME }} />
  }

  return <TabBar tabs={TABS} className="auth-tabs" onChange={onChange} type={type} />
}

export { AuthenticationTabs }
