import HomeIcon from 'material-ui/svg-icons/action/home'
import AssignmentsIcon from 'material-ui/svg-icons/action/assignment'
import CallsIcon from 'material-ui/svg-icons/communication/call'
import ActivityIcon from 'material-ui/svg-icons/action/list'
import MediaIcon from 'material-ui/svg-icons/image/image'
import GroupIcon from 'material-ui/svg-icons/social/group'
import SettingsIcon from 'material-ui/svg-icons/action/settings'
import ApplicationsIcon from '../../../../Common/Icons/applications'
import SignOutIcon from '../../../../Common/Icons/signOut'

import { INTEGRATION, GROUP, ASSIGNMENT } from '../../../../../svc/scopes'

export const getItems = (_props, _matchParams) => [
  { title: 'MENU_HOME', route: '/', id: 'home', icon: HomeIcon, default: 'Home', exact: true },
  { title: 'MENU_MANAGE', id: 'manage', default: 'Manage' },
  {
    title: 'MENU_APPLICATIONS',
    route: '/applications',
    id: 'applications',
    icon: ApplicationsIcon,
    default: 'Applications',
    scopes: [ASSIGNMENT.READ]
  },
  {
    title: 'ASSIGNMENTS',
    route: '/assignments',
    id: 'assignments',
    icon: AssignmentsIcon,
    default: 'Assignments'
  },
  {
    title: 'CALLS',
    route: '/calls',
    id: 'calls',
    icon: CallsIcon,
    default: 'Calls',
    desktop: true
  },
  {
    title: 'MENU_MEDIA',
    route: '/media',
    id: 'media',
    icon: MediaIcon,
    default: 'Media'
  },
  {
    title: 'MENU_ADMINISTRATION',
    id: 'administration',
    default: 'Administration',
    scopes: [GROUP.EDIT]
  },
  {
    title: 'MENU_ACTIVITY_LOG',
    route: '/activities',
    id: 'activity',
    icon: ActivityIcon,
    default: 'Activity log',
    scopes: [GROUP.EDIT]
  },
  {
    title: 'MENU_DOMAIN_SETTINGS',
    route: '/admin',
    id: 'admin',
    icon: SettingsIcon,
    default: 'Domain settings',
    scopes: [INTEGRATION.READ]
  },
  {
    title: 'MENU_USER_GROUPS',
    route: '/team',
    id: 'team',
    icon: GroupIcon,
    default: 'User & groups',
    scopes: [GROUP.EDIT]
  },
  {
    title: 'MENU_SIGNOUT',
    id: 'menu_signout',
    icon: SignOutIcon,
    default: 'Sign Out',
    handler: p => p.signout(),
    stickToBottom: true,
    tooltipPosition: 'top'
  }
]

export const getMobileItems = (props, matchParams) => getItems(props, matchParams)
