import React from 'react'
import { formatDateTime } from 'skylight-common'
import UserDetailsLink from '../../../../../Contacts/UserDetails/UserDetailsLink'

export const DEFAULT_DETAILS_PROPS = [
  { field: 'eventType', title: 'SESSION_EVENT_TYPE', type: 'string' },
  { field: 'eventId', title: 'EVENT_ID', type: 'string' },
  { field: 'sessionId', title: 'SESSION_ID', type: 'string' },
  { field: 'createdBy', title: 'USERNAME', type: 'render', render: item => <UserDetailsLink nextGen userId={item.createdBy} /> },
  { field: 'createdAt', title: 'TIME_CREATED', type: 'render', render: item => formatDateTime(item.createdAt) }
]

export const MEDIA_DETAILS_PROPS = [
  ...DEFAULT_DETAILS_PROPS
]
