import React from 'react'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import NextIcon from 'material-ui/svg-icons/image/navigate-next'
import PrevIcon from 'material-ui/svg-icons/image/navigate-before'
import { FormattedMessage } from 'react-intl'
import { cropImage, getImageFromFileSrc } from '../../../lib/svc'
import { PdfEditor } from '../PdfEditor'

import callSdk from 'skylight-call-sdk'

import './PdfEditorDialog.css'

class PdfEditorDialog extends React.Component {
  render() {
    const { files, selectedIndex, preview } = this.props.pdf
    const { onPreviewChange, onCancel, onSelect, onUpload } = this.props
    const pdf = files[selectedIndex]
    // const pdfTitle = (pdf ? pdf.file.name : '').replace(/\.pdf/i, '')

    const onUploadImage = () => {
      const crop = preview.crop
      this.props.onPreviewChange({ crop: null, imgUrl: preview.imgUrl })
      cropImage(preview.imgUrl, crop)
        .then(imgSrc => {
          // Get valid dimensions for the cropped file.
          getImageFromFileSrc(imgSrc, callSdk.lib.uniqueId.get())
            .then(file => {
              onUpload(file)
            })
            .catch(e => {
              console.error('onUploadImage', 'getImageFromFileSrc', e)
            })
        })
        .catch(e => {
          console.error('cropImage', e)
        })
      onCancel()
    }
    const onNext = () => onSelect(selectedIndex + 1)
    const onPrev = () => onSelect(selectedIndex - 1)

    const getActions = () => {
      let isUploadDisabled = true
      if (preview && preview.crop &&
        // Ensure crop area has some selection.
        preview.crop.width > 0 && preview.crop.height > 0) {
        // actions.push(UPLOAD)
        isUploadDisabled = false
      }
      const CANCEL = <FlatButton
        label={<FormattedMessage id="CLOSE" defaultMessage="Close" />}
        primary={false}
        onClick={onCancel}/>
      const UPLOAD = <FlatButton
        label={<FormattedMessage id="UPLOAD" defaultMessage="Upload" />}
        primary
        onClick={onUploadImage}
        disabled={isUploadDisabled}/>
      const NEXT = <FlatButton
        className="next-btn"
        label={<FormattedMessage id="NEXT" defaultMessage="Next" />}
        icon={<NextIcon />}
        onClick={onNext}/>
      const PREV = <FlatButton
        className="prev-btn"
        label={<FormattedMessage id="PREV" defaultMessage="Previous" />}
        icon={<PrevIcon />}
        onClick={onPrev}/>
      const actions = [CANCEL, UPLOAD]
      if (selectedIndex > 0) actions.push(PREV)
      if (selectedIndex < files.length - 1) actions.push(NEXT)
      return actions
    }

    return (
      <Dialog
        actions={getActions()}
        autoScrollBodyContent
        className="pdf-editor-dialog"
        modal={false}
        open={files.length > 0}
        onRequestClose={onCancel}
        title={<div><FormattedMessage
          id="PDF_PREVIEW"
          defaultMessage="Click+drag on a page to select the area to upload" /></div>}>
        <div className="pdf-editor-dialog-body">
          <PdfEditor pdf={pdf} preview={preview} onChange={onPreviewChange} />
        </div>
      </Dialog>
    )
  }
}

export { PdfEditorDialog }
