import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Tooltip } from 'skylight-common'
import ArrowIcon from 'material-ui/svg-icons/navigation/chevron-right'
import { isCardSelected } from '../attachment-utils'
import './AttachmentGridItem.css'

const AttachmentTooltip = props =>
  <div className="attachment-grid-item-tooltip">
    <div><FormattedMessage id="GRID_TOOLTIP_CARD_ID" />: <span>{props.item.id}</span></div>
    <div><FormattedMessage id="GRID_TOOLTIP_SEQUENCE_ID" />: <span>{props.item.sequenceId}</span></div>
  </div>

const AttachmentGridItem = props => {
  const { item, selectedIds } = props
  const isSelected = isCardSelected(item, selectedIds)
  const className = [
    'assignment-attachment-grid-item',
    isSelected ? 'active' : ''
  ].join(' ')

  return (
    <Tooltip className="assignment-attachment-grid-item-wrapper"
      tooltip={<AttachmentTooltip item={props.item} />}>
      <div className={className}>
        {props.children}
        <ArrowIcon className="assignment-attachment-selected-icon" />
      </div>
    </Tooltip>
  )
}

export { AttachmentGridItem }
