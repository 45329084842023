import React from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import { SectionTitle } from '../SectionTitle'
import { QueryFilterPicker } from '../../../../../../../containers/admin'

import './QueryFilterSection.css'

const defaultLdapQUeryDescription = 'Assign Skylight user roles and groups based on LDAP queries (“filters). At least one filter is required.'

const QueryFilterSection = ({ sectionClassName, title }) => {
  return (
    <div className={classNames('ldap-section', sectionClassName)}>
      {title && <SectionTitle title={title} />}
      <div className="query-filter-section-body">
        <FormattedMessage id="LDAP_QUERY_DESCRIPTION" defaultMessage={defaultLdapQUeryDescription}>
          {(content) => <div className="query-filter-section-description">{content}<span className="required">*</span></div>}
        </FormattedMessage>
        <QueryFilterPicker />
      </div>
    </div>
  )
}

export { QueryFilterSection }
