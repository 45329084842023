import skylight from 'skylight-js-sdk'
import { Room } from '../utils'

export const getBweForVideoReport = async(query) => {
  const roomId = query.callId
  const room = await skylight.analytics.getRoomById(roomId)

  const sortBy = query.sortBy === 'timestamp' ? 'createdAt' : query.sortBy

  const sort = `${query.sortAsc ? '' : '-'}${sortBy}`
  const logQuery = { sort, limit: query.limit }
  if (query.skip && query.nextCursor) {
    logQuery.nextCursor = query.nextCursor
  }

  const reportsRes = await skylight.analytics.getRoomLogsById(roomId, logQuery)
  const items = new Room(room, { sortOrder: query.sortAsc ? 1 : -1 }).aggregate(reportsRes.data)

  return { data: items, page: reportsRes.page }
}
