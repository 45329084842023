import * as ActionTypes from '../../../actions/types'
import { ATTACHMENT_DEFAULT_QUERY } from '../../../lib/assignment'
import { createListReducer } from 'skylight-common'

const base = createListReducer({
  prefix: ActionTypes.PREFIX_ASSIGNMENT_ATTACHMENT.toUpperCase()
}, ATTACHMENT_DEFAULT_QUERY)

const attachment = (state, payload) => {
  const newState = base(state, payload)

  switch (payload.type) {
    case ActionTypes.ASSIGNMENT_ATTACHMENT_PREVIEW:
      return { ...newState, previewId: payload.previewId }
    default: return newState
  }
}

export default attachment
