import React from 'react'
import { Form, MediaDetailsProps, MediaPreview } from 'skylight-common'
import { ItemProps } from '../../../../Common'
import { CardTags } from './CardTags'

import './MediaDetails.css'

const DETAILS_PROPS = [
  {
    field: 'tags',
    className: 'tags-prop',
    title: 'CARD_TAGS',
    type: 'render',
    render: _ => null /* Rendering in item props has overflow/scroll issues */
  }
]

const MediaDetails = (props) => {
  const { item } = props
  const itemState = props.attachment.item
  const mediaProps = {
    ...props,
    onMediaSelectItems: () => {},
    onPreviewMediaItem: props.onAssignmentAttachmentPreviewItem,
    onMediaChange: props.onAssignmentAttachmentChange
  }

  const handleSubmit = () => {
    props.onAssignmentAttachmentSave(item)
  }

  return (
    <div className="attachment-media-details media-details">
      {!props.attachment.list.previewId && <div className="media-details-file">
        <MediaPreview {...mediaProps} item={item} />
      </div>}
      <div className="media-details-list">
        <Form onSubmit={handleSubmit} hasChanged={itemState.hasChanged} onCancel={props.onAssignmentAttachmentCancel}>
          <MediaDetailsProps isEditable={itemState.mode === 'edit'} {...mediaProps} item={item} />
        </Form>
      </div>
      <ItemProps
        item = {item}
        props={DETAILS_PROPS} />
      <CardTags item={item} />
    </div>
  )
}

export { MediaDetails }
