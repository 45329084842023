import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { formatDateTime, UserTitle, CursorTooltip } from 'skylight-common'
import IconButton from 'material-ui/IconButton'
import ArchiveIcon from 'material-ui/svg-icons/content/archive'
import { isReadonlyApplication, PERMISSIONS } from '../../../../../../lib/application'
import { DataTable } from './DataTable/DataTable'
import publish from '../../../../../Common/Icons/publish'
import ContentCopyIcon from 'material-ui/svg-icons/content/content-copy'
import { DashboardActionButton } from '../../Common/DashboardActionButton'
import { LastExported } from './LastExported'
import get from 'lodash/get'
import { VERSION_STAGES } from '../../../../../../lib/application/version'
import applicationActions from '../../../../../../actions/nextGenApplication'
import versionActions from '../../../../../../actions/application/versions'
import applicationExportActions from '../../../../../../actions/applicationExport'
import ArchiveAppConfirmationDialog from '../../../../Layout/Dialogs/ArchiveAppConfirmationDialog/ArchiveAppConfirmationDialog'
import { showArchiveAppConfirmation } from '../../../../../../actions/dialog'
import TimeUpdate from '../../../../../Common/Icons/users/time/time-update'
import './GeneralInformation.css'

class GeneralInformation extends Component {
  canReadVersions = (props) => {
    const {
      selectedApplication,
      permissions
    } = props
    const { archived } = selectedApplication
    const applicationPermissions = permissions[selectedApplication.applicationId] || {}
    const canReadVersions = applicationPermissions[PERMISSIONS.VERSION.READ_PUBLISHED] && !archived
    return canReadVersions
  }

  checkAndLoadVersions(props) {
    const {
      applicationId
    } = props
    if (this.canReadVersions(props)) {
      props.onVersionQuery({
        ...props.versionQuery,
        applicationId
      })
    }
  }

  componentDidMount() {
    this.checkAndLoadVersions(this.props)
  }

  componentDidUpdate(props) {
    if (props.selectedApplication.applicationId !== this.props.selectedApplication.applicationId ||
      Object.keys(props.permissions).length !== Object.keys(this.props.permissions).length) {
      this.checkAndLoadVersions(this.props)
    }
  }

  onItemAction = (e, action) => {
    e.stopPropagation()
    action()
  }

  handlePublish = () => {
    const {
      selectedApplication: { draftVersion },
      versions
    } = this.props
    this.props.onStartPublishingVersion(draftVersion, versions)
  }

  handleArchive = () => {
    this.props.showArchiveAppConfirmation()
  }

  handleUnarchive = () => {
    this.props.onUnarchiveApplication()
  }

  getBuilderButtonDisabled = () => this.props.upgrades.some(
    x => x.upgrade.applicationId === this.props.applicationId
  )

  getActionItems(props) {
    const { applicationId, permissions, selectedApplication, versionsDone } = props
    const applicationPermissions = permissions[applicationId] || {}
    const hasVersionPublishPermissions = applicationPermissions[PERMISSIONS.VERSION.PUBLISH]
    const hasArchivePermissions = applicationPermissions[PERMISSIONS.APPLICATION.ARCHIVE]
    const isArchived = selectedApplication.archived
    return [
      {
        icon: ArchiveIcon,
        tooltip: { id: 'ACTION_ARCHIVE_APPLICATION', default: 'Archive application' },
        action: this.handleArchive,
        isDisabled: () => isArchived || !hasArchivePermissions
      },
      {
        icon: TimeUpdate,
        tooltip: { id: 'ACTION_UNARCHIVE_APPLICATION', default: 'Unarchive' },
        action: this.handleUnarchive,
        isDisabled: () => !isArchived
      },
      {
        icon: ContentCopyIcon,
        tooltip: { id: 'ACTION_DUPLICATE_APPLICATION', default: 'Duplicate application' },
        action: () => console.log('duplicate'),
        isDisabled: () => true
      },
      {
        icon: publish,
        tooltip: { id: 'ACTION_PUBLISH_APPLICATION', default: 'Publish application' },
        action: this.handlePublish,
        isDisabled: () => isArchived || !hasVersionPublishPermissions || !versionsDone
      },
      {
        render: this.renderAppBuilderButton,
        isDisabled: () => isArchived || isReadonlyApplication(applicationId, permissions)
      }
    ].filter((a) => {
      if (typeof a.isDisabled === 'function') {
        return !a.isDisabled()
      }

      return true
    }).map((a, i, arr) => {
      if (typeof a.render === 'function') {
        return a.render()
      }

      return (
        <CursorTooltip key={i} tooltip={<FormattedMessage id={a.tooltip.id} defaultMessage={a.tooltip.default} />}>
          <IconButton
            className={`item-action-icon-button ${a.className || ''}`}
            onTouchTap={e => this.onItemAction(e, a.action)}><a.icon className="icon" /></IconButton>
        </CursorTooltip>
      )
    })
  }

  openBuilder = () => {
    this.props.onBuilderOpen(this.props.applicationId)
  }

  renderAppBuilderButton = () => (
    <DashboardActionButton
      key="app-builder-button"
      disabled={this.getBuilderButtonDisabled()}
      className="app-builder-button"
      onClick={this.openBuilder}
      text={<FormattedMessage id="APPLICATION_BUILDER" defaultMessage="APP BUILDER" />} />
  )

  onDownloadAppExport = (exportId, version) => {
    const { applicationId } = this.props
    this.props.onDownloadApplicationExport(applicationId, exportId, version)
  }

  render() {
    const fieldSelectors = [
      {
        name: 'Name',
        render: a => a.name
      },
      {
        name: 'Description',
        render: a => a.description
      },
      {
        name: 'Owner',
        render: a => <UserTitle shouldLoadUser user={a.createdBy} />
      },
      {
        name: 'Created',
        render: a => a.createdAt && formatDateTime(a.createdAt)
      },
      {
        name: 'Version',
        render: a => get(a, 'currentVersion.version')
      },
      {
        name: 'Status',
        render: a => {
          const status = get(a, 'currentVersion.stage')
          const isPublished = status === VERSION_STAGES.PUBLISHED
          const isDraft = status === VERSION_STAGES.DRAFT
          const isArchived = a.archived
          let message = ''
          if (isArchived) message = <FormattedMessage id="APPLICATION_ARCHIVED" defaultMessage="Archived" />
          else if (isPublished) message = <FormattedMessage id="APPLICATION_PUBLISHED" defaultMessage="Published" />
          else if (isDraft) message = <FormattedMessage id="APPLICATION_DRAFT" defaultMessage="Draft" />
          return message
        }
      },
      {
        name: 'Last Published',
        render: a => a.lastPublished && formatDateTime(a.lastPublished)
      },
      {
        name: 'App ID',
        render: a => a.applicationId
      }
    ].filter(x => !x.disabled)

    return (
      <div className="general-information-container">
        <div className="general-information-header">
          <div className="general-information-title">
            {this.props.selectedApplication.name}
          </div>
          <div className="general-information-actions">
            {this.getActionItems(this.props)}
          </div>
        </div>
        <div className="general-information-body">
          <div className="general-information-section">
            <div className="general-information-data-table">
              <DataTable fieldSelectors={fieldSelectors} dataObj={this.props.selectedApplication} />
            </div>
          </div>
        </div>
        <LastExported applicationExport={this.props.applicationExport} onDownloadAppExport={this.onDownloadAppExport} />
        <ArchiveAppConfirmationDialog />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedApplication: state.application.selectedApplication,
  versions: state.application.version.list.items,
  versionsDone: state.application.version.list.done,
  permissions: state.application.permissions,
  versionQuery: state.application.version.list.query,
  applicationExport: state.application.applicationExport,
  upgrades: state.application.applicationUpgrade.upgrades,
  pathname: state.router.location.pathname
})

const applicationListActions = {
  ...applicationActions,
  ...versionActions,
  ...applicationExportActions,
  showArchiveAppConfirmation
}

const GeneralInformationContainer = connect(mapStateToProps, applicationListActions)(GeneralInformation)

export default GeneralInformationContainer
