import * as React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

function publish(props) {
  return (
    <SvgIcon {...props}>
      <path d="M20,22 L16.14,20.45 C16.84,18.92 17.34,17.34 17.65,15.73 L20,22 M7.86,20.45 L4,22 L6.35,15.73 C6.66,17.34 7.16,18.92 7.86,20.45 M12,2 C12,2 17,4 17,12 C17,15.1 16.25,17.75 15.33,19.83 C15,20.55 14.29,21 13.5,21 L10.5,21 C9.71,21 9,20.55 8.67,19.83 C7.76,17.75 7,15.1 7,12 C7,4 12,2 12,2 M12,12 C13.1,12 14,11.1 14,10 C14,8.9 13.1,8 12,8 C10.9,8 10,8.9 10,10 C10,11.1 10.9,12 12,12 Z" id="Shape" />
    </SvgIcon>
  )
}

export default publish
