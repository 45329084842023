import React from 'react'
import { FormattedDateTime } from 'skylight-common'
import { formatDate } from '../../../../../../../lib/date'
import { VersionStage } from './VersionStage/VersionStage'
import UserDetailsLink from '../../../../../Contacts/UserDetails/UserDetailsLink'
import { versionUpgradeIsFine } from '../../../../../../../lib/application/version'

export const COLUMNS = [
  {
    name: 'version',
    title: 'VERSION',
    render: x => x.version
  },
  {
    name: 'name',
    title: 'APPLICATION_NAME',
    render: x => x.name
  },
  {
    name: 'description',
    title: 'APPLICATION_DESCRIPTION',
    render: x => x.description
  },
  {
    name: 'createdAt',
    title: 'CREATED',
    render: x => <FormattedDateTime value={x.createdAt} />,
    groupBy: x => formatDate(new Date(x.createdAt))
  },
  {
    name: 'createdBy',
    title: 'CREATED_BY',
    render: item => <UserDetailsLink nextGen userId={item.createdBy} />,
    groupBy: x => x.createdBy
  },
  {
    name: 'status',
    title: 'STATUS',
    sortable: false,
    render: x => <VersionStage stage={versionUpgradeIsFine(x) ? x.stage : 'IMPORT_FAILED'} />,
    groupBy: x => x.stage
  },
  {
    name: 'comments',
    title: 'COMMENTS',
    sortable: false,
    render: x => versionUpgradeIsFine(x) ? x.comment : ''
  }
]
