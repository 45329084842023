import React from 'react'
import { formatDate } from '../../../../lib/date'
import { UserTitle, getUserTitle, FormattedDateTime, DetailsLink } from 'skylight-common'
import skylight from 'skylight-js-sdk'
import { GROUP } from '../../../../svc/scopes'

const renderDetailsLink = (u, action) => <DetailsLink
  item={u}
  path="/team/users"
  renderLink={getUserTitle}
  onNavigateToDetails={action} />

const COLUMNS = [
  {
    name: 'name',
    title: 'APPLICATION_NAME',
    groupBy: x => x.name
  },
  {
    name: 'createdBy',
    title: 'OWNER',
    sortable: false,
    render: (item, action, scopes) => <UserTitle
      render={skylight.auth.hasScopes([GROUP.EDIT])
        ? u => renderDetailsLink(u, action)
        : null}
      user={item.createdBy} />,
    groupBy: x => x.createdBy,
    groupHeader: x => <UserTitle user={x} />
  },
  {
    name: 'modifiedAt',
    title: 'UPDATED_TIME',
    render: x => <FormattedDateTime value={x.modifiedAt} />,
    groupBy: x => formatDate(new Date(x.modifiedAt))
  },
  {
    name: 'modifiedBy',
    title: 'LAST_MODIFIED_BY',
    render: (item, action) => <UserTitle
      render={skylight.auth.hasScopes([GROUP.EDIT]) ? u => renderDetailsLink(u, action) : null}
      user={item.modifiedBy} />,
    groupBy: x => x.modifiedBy,
    groupHeader: x => <UserTitle user={x} />
  },
  {
    name: 'description',
    title: 'DESCRIPTION',
    sortable: false,
    groupBy: x => x.description
  }
]

export { COLUMNS }
