import skylight from 'skylight-js-sdk'
import { formatRealm } from '../lib/login'

const IS_SAML_KEY = 'IS_SAML_AUTH'

export let ssoData = null

export const setSsoData = sso => {
  ssoData = sso
}

// Make sure to store a value indicating whether user was signed in via IDP or locally.
// Introduced as a part of https://upskill.atlassian.net/browse/SKY-4795
// Should be removed once this flag is moved to a user/profile object.
// Using localStorage instead of skylight.storage wrapper to handle it synchronously.
export const getIsSamlAuth = () => {
  try {
    return JSON.parse(localStorage.getItem(IS_SAML_KEY))
  } catch (e) {
    return false
  }
}
// async () => skylight.storage.getItem(IS_SAML_KEY)

const setIsSamlAuth = async value => skylight.storage.setItem(IS_SAML_KEY, value)

export const clearIsSamlAuth = () => skylight.storage.removeItem(IS_SAML_KEY)

export const getRealm = (name) => {
  return skylight.utils.isFrontline()
    ? {
        authApps: []
      }
    : skylight.api.get(`/v1/authentication/login/realms/${name}`)
}

export const signin = async(username, password, realm) => {
  ssoData = { username, password, realm }
  await setIsSamlAuth(false)
  const authData = await skylight.authenticate({ username, password, realm })
  const user = await skylight.getCurrentUser(authData)
  return {
    scopes: user.scopes,
    auth: authData,
    user: user,
    realm
  }
}

export const signinFrontline = (authData) => {
  skylight.auth.trySetUserFromAccessToken(authData.access_token)
  const user = skylight.auth.userValue
  skylight.auth.user = user
  return {
    scopes: user.scopes,
    auth: authData,
    user: user,
    realm: user.realm
  }
}

const getIdpSsoToken = async() => {
  const sso = await skylight.api.post('/v1/authentication/idp/sso', {})
  return sso.token
}

export const getSsoToken = async(ssoData, preventSignOut) => {
  const { username, password, realm, idp } = ssoData

  if (idp) {
    return getIdpSsoToken()
  }

  const formattedRealm = formatRealm(realm)
  const ssoUrl = `/v1/authentication/login/realms/${formattedRealm}/sso`
  const formData = { username, password, realm: formattedRealm }
  const sso = await skylight.api.post(ssoUrl, formData, {}, { preventSignOut })

  return sso.token
}

export const signInIdp = async(realm, code) => {
  const url = `/v1/authentication/idp/auth/${realm}?code=${code}`
  const authData = await skylight.api.get(url)
  ssoData = { idp: true }
  await setIsSamlAuth(true)
  skylight.auth.authData = authData
  const user = await skylight.getCurrentUser(authData)
  return { auth: authData, user, scopes: user.scopes }
}

export const REQUIRED_PASSWORD_FIELDS = {
  OLD_PASSWORD: 'oldPassword',
  NEW_PASSWORD: 'newPassword',
  CONFIRM_NEW_PASSWORD: 'confirmPassword'
}

export const GENERIC_PASSWORD_REQUIREMENTS_NAMES = {
  MIN_CHARS_LIMIT: 'min-chars-limit',
  MATCHES_USERNAME: 'matches-username',
  SAME_AS_PREVIOUS: 'previous-10-pwds'
}

export const GENERIC_PASSWORD_REQUIREMENTS = [
  {
    name: GENERIC_PASSWORD_REQUIREMENTS_NAMES.MIN_CHARS_LIMIT,
    intl: { id: 'PASS_REQ_8_CHARS', defaultMessage: '8 characters minimum.' },
    validate: ({ password }) => password.length < 8
  },
  {
    name: GENERIC_PASSWORD_REQUIREMENTS_NAMES.MATCHES_USERNAME,
    intl: { id: 'PASS_REQ_NOT_MATCH_USERNAME', defaultMessage: 'Cannot be the same as your username.' },
    validate: ({ password, username }) => password === username
  },
  {
    name: GENERIC_PASSWORD_REQUIREMENTS_NAMES.SAME_AS_PREVIOUS,
    intl: { id: 'PASS_NOT_SAME_AS_10_PREVIOUS', defaultMessage: 'Cannot be the same as your previous 10 passwords.' },
    validate: () => false
  }
]

export const MAX_PASSWORD_LENGTH = 40
