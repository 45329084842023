import React from 'react'
import { SecuritySection } from './SecuritySection'
import { QueryFilterSection } from './QueryFilterSection'
import { BaseSection } from './BaseSection'
import {
  LDAP_SERVER_FIELDS,
  LDAP_SECURITY_FIELDS,
  LDAP_ADMIN_FIELDS,
  LDAP_USER_FIELDS
} from '../../../../../../lib/admin'
import { UserAttributesPicker } from '../../../../../../containers/admin'
import './LdapOptions.css'

const LdapOptions = ({ ldapApp, onFormFieldChange, fieldTypes }) => {
  const { item, errors, hasChanged } = ldapApp

  if (!item || !item.ldapEnabled) {
    return null
  }

  const sectionProps = {
    item,
    errors,
    onFormFieldChange,
    originalChanged: hasChanged
  }

  return (
    <div className="ldap-options">
      <BaseSection
        title="LDAP_SECTION_SERVER"
        fields={LDAP_SERVER_FIELDS}
        fieldTypes={fieldTypes}
        {...sectionProps} />
      <SecuritySection
        title="LDAP_SECTION_SECURITY"
        fields={LDAP_SECURITY_FIELDS}
        fieldTypes={fieldTypes}
        {...sectionProps} />
      <BaseSection
        title="LDAP_SECTION_ADMIN"
        fields={LDAP_ADMIN_FIELDS}
        fieldTypes={fieldTypes}
        {...sectionProps} />
      <BaseSection
        title="LDAP_SECTION_USER"
        fields={LDAP_USER_FIELDS}
        fieldTypes={fieldTypes}
        titleAction={<UserAttributesPicker />}
        {...sectionProps} />
      <QueryFilterSection
        title="LDAP_SECTION_QUERY" />
    </div>
  )
}

export { LdapOptions }
