import React from 'react'
import MenuItem from 'material-ui/MenuItem'
import CallIcon from 'material-ui/svg-icons/communication/call'
import { FormattedMessage } from 'react-intl'
import { isUserOnline } from '../../../../../lib/presence'
import { isMobile, DetailsMenu } from 'skylight-common'
import './CallUserActionButtons.css'

const CallUserActionButtons = props => {
  if (!isMobile() || !isUserOnline(props.presenceUsers, props.user.item)) {
    return null
  }

  return <DetailsMenu className="call-user-action-buttons">
    <MenuItem
      leftIcon={<CallIcon />}
      primaryText={
        <FormattedMessage id="CALL" defaultMessage="Call" />
      }
      onTouchTap={() => props.onCall(props.user.item, props.authUser)} />
    </DetailsMenu>
}

export { CallUserActionButtons }
