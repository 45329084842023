import React from 'react'
import { connect } from 'react-redux'
import { VersionFilter } from './VersionFilter'
import { TextFilterNextGen } from '../../../../../../Common/NextGen/TextFilterNextGen'
import versionsActions from '../../../../../../../actions/application/versions'
import contactActions from '../../../../../../../actions/contact'

const VALUE = 'version'

const VersionTextFilter = props => {
  const value = props.query.filter && props.query.filter.title ? props.query.filter.title : ''
  const params = { preventPathSave: props.preventPathSave }

  const onChange = (v) => {
    const value = v.trim()
    const filter = { ...props.query.filter, [VALUE]: value }
    props.onVersionFilterUpdated(filter, params)
  }

  return <TextFilterNextGen
    value={value}
    className="version-text-filter"
    title="SEARCH"
    iconFilter={<VersionFilter
      {...props}
      onSessionFilterUpdated={f => props.onVersionFilterUpdated(f, params)}
      onSessionFiltersUpdated={f => props.onVersionFiltersUpdated(f, params)}
      onFilterRemoved={t => props.onVersionFilterRemoved(t, params)} />}
    onChange={onChange} />
}

const mapStateToProps = (state) => ({
  query: state.application.version.list.query,
  layout: state.layout,
  authUser: state.auth.user
})

const filterActions = {
  ...versionsActions,
  onUserSelectItems: contactActions.onUserSelectItems
}

const VersionTextFilterContainer = connect(mapStateToProps, filterActions)(VersionTextFilter)

export default VersionTextFilterContainer
