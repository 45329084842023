import React, { Component } from 'react'
import { Dialog, FlatButton } from 'material-ui'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import FileUpload from 'material-ui/svg-icons/file/file-upload'
import { APP_UPGRADE } from '../dialogTypes'
import { hideUpgradeDialog } from '../../../../../actions/dialog'
import { showError } from '../../../../../actions/error'
import './AppUpgradeDialog.css'
import { TypePicker } from './TypePicker'
import { ImportFromFileTab } from '../SetupNewApplication/ImportFromFileTab/ImportFromFileTab'
import classNames from 'classnames'
import { applicationUpgrade } from '../../../../../actions/applicationUpgrade'

class AppUpgradeDialog extends Component {
  state = {
    updates: [
      'scripts',
      'views'
    ],
    comment: '',
    stage: 'type-picking' // 'type-picking' | 'file-picking'
  }

  toggleUpdate = (update) => () => {
    const { updates } = this.state
    const index = updates.indexOf(update)
    if (index === -1) {
      updates.push(update)
    } else {
      updates.splice(index, 1)
    }
    this.setState({ updates })
  }

  onChangeComment = (e, comment) => {
    this.setState({ comment })
  }

  renderActions = () => {
    return this.state.stage === 'type-picking'
      ? [
        <FlatButton
          key="cancel"
          primary
          label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
          onClick={this.props.close}/>,
        <FlatButton
          key="confirm"
          disabled={this.state.updates.length === 0}
          label={<FormattedMessage id="CONTINUE" defaultMessage="Continue" />}
          primary
          onClick={() => this.setState({ stage: 'file-picking' })}/>
        ]
      : []
  }

  renderTitle = () => this.state.stage === 'type-picking'
    ? (
      <div className="archive-app-confirmation-dialog__title">
          <FileUpload color="rgba(65, 64, 66, 1)" />
          <FormattedMessage id="UPGRADE_APPLICATION" defaultMessage="Upgrade application" />
        </div>
      )
    : null

  getMaxWidth = () => {
    if (this.state.stage === 'type-picking') return 533
    if (this.state.stage === 'file-picking') return 650
    return 600
  }

  render() {
    const {
      open,
      close,
      onShowError,
      onApplicationUpgrade
    } = this.props
    return (
      <Dialog
        contentStyle={{ maxWidth: this.getMaxWidth() }}
        titleStyle={{ fontSize: 20, display: 'flex', alignItems: 'center', padding: '12px 24px' }}
        title={this.renderTitle()}
        open={open}
        bodyStyle={{ paddingBottom: 0 }}
        actions={this.renderActions()}
        bodyClassName={classNames('dialog-body app-upgrade-dialog', {
          'file-picking': this.state.stage === 'file-picking'
        })}
        onRequestClose={close}>
        {this.state.stage === 'type-picking' && (
          <TypePicker
            comment={this.state.comment}
            onChangeComment={this.onChangeComment}
            updates={this.state.updates}
            toggleUpdate={this.toggleUpdate} />
        )}
        {this.state.stage === 'file-picking' && (
          <ImportFromFileTab
            onUpload={(x) => onApplicationUpgrade({
              file: x,
              components: this.state.updates,
              comment: this.state.comment
            })}
            onShowError={onShowError}>
            {/* will be needed soon */}
            {/* <div className="app-upgrade-file-upload-text">
              <FormattedMessage
                id="DOWNLOAD_THE_LATEST_VERSION"
                values={{
                  link: <a href="#" target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="HERE" defaultMessage="here" />
                  </a>
                }} />
            </div> */}
          </ImportFromFileTab>
        )}
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  open: state.dialog[APP_UPGRADE]?.open
})

const mapDispatchToProps = {
  close: hideUpgradeDialog,
  onShowError: showError,
  onApplicationUpgrade: applicationUpgrade
}

export default connect(mapStateToProps, mapDispatchToProps)(AppUpgradeDialog)
