import React from 'react'
import { ByList } from 'skylight-common'
import './ByTag.css'

const ByTag = props => {
  const getOptions = () => {
    const map = {}
    props.filterProps.items
      .forEach(x => x.tags.forEach(t => { map[t] = null }))

    const options = Object.keys(map).map(key => ({ text: key, value: key }))

    return Promise.resolve(options)
  }

  return (
    <ByList
      className="by-tag-filter"
      title="TAG_NAME"
      value={props.value}
      enableSorting={false}
      renderFilter={() => null}
      onChange={props.onChange}
      onRemove={props.onRemove}
      onGetItems={getOptions} />
  )
}

export { ByTag }
