import React, { Component } from 'react'
import { Step, Stepper, StepLabel, StepContent } from 'material-ui/Stepper'
import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'
import { FormattedMessage } from 'react-intl'
import { PeoplePicker, isPhone, Loading } from 'skylight-common'
import { WorkflowPicker } from '../../Workflow'
// import {AssignmentOptions} from './AssignmentOptions'
import './AssignWorkflow.css'

const KEYS = {
  ENTER: 13
}

class AssignWorkflow extends Component {
  state = {
    stepIndex: 0,
    selectedUsers: [],
    // permanentAssignment: false,
    finished: false
  }

  onChangeStep = (stepIndex) => {
    if (stepIndex < 0) {
      return
    }

    const finished = stepIndex >= 1
    this.setState({ stepIndex, finished })

    this.props.onStepChange && this.props.onStepChange(stepIndex, finished)
  }

  onSelectUser = (selectedUsers) => {
    this.setState({ selectedUsers })
  }

  // Removed as a part of https://favro.com/card/60fe74a05e7da9c931484a9a/Ups-4157
  // onTogglePermanent = (e, permanentAssignment) => {
  //   e.stopPropagation()
  //   this.setState({ permanentAssignment })
  // }

  onAssignWorkflow = () => {
    const { items = [], selectedIds } = this.props.workflow.list
    const users = PeoplePicker.getSelectedUsers(this.state.selectedUsers)
    console.log('assigning to ', users)

    this.props.onWorkflowAssigned(
      items.filter((w) => selectedIds.includes(w.workflowId)),
      users
    )
  }

  renderStepButtons = (allowNext = true) => {
    const { stepIndex, finished } = this.state
    const onNext = () => {
      if (!finished) {
        this.onChangeStep(stepIndex + 1)
        return
      }

      this.onAssignWorkflow()
    }

    const onPrev = () => {
      if (stepIndex > 0) {
        this.onChangeStep(stepIndex - 1)
        return
      }

      this.props.onCancel && this.props.onCancel()
    }

    return (
      <div className="step-buttons">
        <FlatButton
          label={<FormattedMessage id="CANCEL" defaultMessage={'Cancel'} />}
          disableTouchRipple={true}
          disableFocusRipple={true}
          onTouchTap={onPrev} />
        <RaisedButton
          className="submit-button"
          label={<FormattedMessage
            id={finished ? 'ASSIGN' : 'CONTINUE'}
            defaultMessage={finished ? 'Assign' : 'Next'} />}
          disabled={!allowNext}
          primary={true}
          onTouchTap={onNext} />
      </div>
    )
  }

  onKeyPress = (e) => {
    if (e.keyCode === KEYS.ENTER) {
      this.onEnterPress(e)
    }
  }

  onEnterPress = (e) => {
    const { stepIndex, finished, selectedUsers } = this.state
    const { list } = this.props.workflow

    if (stepIndex === 0 && list.selectedIds.length < 1) {
      return
    }

    if (stepIndex === 1 && selectedUsers.length < 1) {
      return
    }

    if (!finished) {
      this.onChangeStep(stepIndex + 1)
      return
    }

    if (['BODY', 'INPUT'].includes(e.target.tagName)) {
      this.onAssignWorkflow()
    }
  }

  setInitialUser = (user) => {
    if (user) {
      this.setState({ selectedUsers: [user] })
    }
  }

  getPickerColumns = () => {
    return isPhone() ? ['name'] : ['name', 'createdBy', 'modifiedAt']
  }

  componentDidMount() {
    // Requires additional testing.
    const { /* onLoadWorkflows, workflow, */ assignmentItem } = this.props
    // onLoadWorkflows(workflow.list.query)
    this.setInitialUser(assignmentItem.user)
  }

  componentWillUnmount() {
    this.props.onWorkflowSelectItems([])
  }

  render() {
    const { selectedUsers } = this.state
    const { list } = this.props.workflow
    const isAssigning = this.props.assignmentItem.isAssigning

    return (
      <div
        onKeyDown={this.onKeyPress}
        tabIndex="0"
        className="assign-workflow" >
        <Loading
          isLoading={isAssigning}
          message="ASSIGN_LOADING">
          <Stepper activeStep={this.state.stepIndex} orientation="vertical">
            <Step>
              <StepLabel>
                <FormattedMessage id="ASSIGN_WORKFLOW_STEP_1" defaultMessage={'Select an application(s) to assign'} />
              </StepLabel>
              <StepContent className="workflow-pick-step">
                <WorkflowPicker {...this.props} showRowNames={!isPhone()} columnNames={this.getPickerColumns()} />
                {this.renderStepButtons(list.selectedIds.length > 0)}
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <FormattedMessage id="ASSIGN_WORKFLOW_STEP_2" defaultMessage={'Select user(s) to recieve assignment'} />
              </StepLabel>
              <StepContent className="user-pick-step">
                <PeoplePicker
                  includeGroups={true}
                  includeCurrentUser={true}
                  selected={selectedUsers}
                  autoFocus={true}
                  onSelect={this.onSelectUser} />
                {this.renderStepButtons(selectedUsers.length > 0)}
              </StepContent>
            </Step>
            {/* <Step>
              <StepLabel>
                <FormattedMessage id="ASSIGN_WORKFLOW_STEP_3" defaultMessage={'Configure assignment options'} />
              </StepLabel>
              <StepContent className="options-step">
                <AssignmentOptions
                  onToggle={this.onTogglePermanent}
                  renderStepButtons={this.renderStepButtons} />
              </StepContent>
            </Step> */}
          </Stepper>
        </Loading>
      </div>
    )
  }
}

export { AssignWorkflow }
