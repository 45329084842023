import * as ActionTypes from '../../actions/types'

const initialState = {
  exporting: false,
  export: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.APPLICATION_EXPORT_STARTED:
      return { ...state, exporting: true }
    case ActionTypes.APPLICATION_EXPORT_FINISHED:
      return { ...state, exporting: false }
    case ActionTypes.SELECTED_APP_EXPORT_UPDATE:
      return { ...state, export: action.export }
    default:
      return state
  }
}
