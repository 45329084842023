import list from './list'
import item from './item'
import { combineReducers } from 'redux'
import attachment from './attachment'

export default {
  list,
  item,
  attachment: combineReducers(attachment)
}
