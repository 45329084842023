import React, { PureComponent } from 'react'
import Dialog from 'material-ui/Dialog'

import { DialogTitle } from './components/dialogParts'
import { GroupSelect } from './components/GroupSelect'
import { UserSelect } from './components/UserSelect'

import { filterGroups } from '../../../../../lib/contact'

import './AddToExistingGroupDialog.css'

class AddToExistingGroup extends PureComponent {
  state = {
    selectedUsers: [],
    selectedGroups: [],
    pickUsers: true,
    search: '',
    items: filterGroups(this.props.groups.items, this.props.groups.query)
  }

  componentDidMount() {
    const { users } = this.props
    if (users && users.selectedIds.length > 0) {
      const selectedUsers = users.items.filter(user => users.selectedIds.find(id => user.id === id))

      this.setState({ selectedUsers })
    }
  }

  onItemSelect = selectedEntities => {
    if (this.state.pickUsers) {
      this.setState({ selectedUsers: selectedEntities })
    } else {
      this.setState({ selectedGroups: selectedEntities })
      this.props.onGroupSelectItems(selectedEntities, this.state.items)
    }
  }

  handleClose = () => {
    if (this.state.pickUsers) {
      return this.props.onHideDialog(this.props.dialogType)
    }
    this.setState({ pickUsers: true })
  }

  handleGroupSelectClose = () => {
    this.setState({ pickUsers: true, search: '', selectedGroups: [] })
    this.props.onGroupSelectItems([], this.state.items)
  }

  handleContinueClick = () => this.setState({ pickUsers: false })

  handleSaveClick = () => {
    const { selectedGroups, selectedUsers } = this.state
    this.props.onAddMembersToGroups(selectedUsers, selectedGroups)
  }

  handleSearchChange = query => {
    const { groups } = this.props
    const items = filterGroups(groups.items, groups.query)
    const filteredItems = items.filter(item => item.name.includes(query))
    this.setState({ search: query, items: filteredItems })
  }

  renderContent = () => {
    if (this.state.pickUsers) {
      return (
        <UserSelect
          {...this.props}
          isUserPick={this.state.pickUsers}
          onContinue={this.handleContinueClick}
          onCancel={this.handleClose}
          onChipSelect={this.onItemSelect}
          selectedUsers={this.state.selectedUsers}/>
      )
    }
    return (
      <GroupSelect
        {...this.props}
        isUserPick={this.state.pickUsers}
        onSave={this.handleSaveClick}
        onCancel={this.handleGroupSelectClose}
        items={this.state.items}
        selectedGroups={this.state.selectedGroups}
        onGroupSelect={this.onItemSelect}/>
    )
  }

  render() {
    const dialogProps = this.props.dialog[this.props.dialogType] || {}
    return (
      <Dialog
        title={
          <DialogTitle
            id="ADD_TO_EXISTING_GROUP"
            defaultMessage="Add to existing group"
            groups={this.props.groups}
            isGroups={!this.state.pickUsers}
            searchValue={this.state.search}
            items={this.state.items}
            onSearchChange={this.handleSearchChange}/>
        }
        modal={false}
        open={!!dialogProps.open}
        className="dialog-modal add-to-existing-group-dialog"
        contentClassName="dialog-content-wrapper"
        bodyClassName="dialog-body-wrapper"
        onRequestClose={this.handleClose}>
        {this.renderContent()}
      </Dialog>
    )
  }
}

export { AddToExistingGroup }
