import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const PasswordKey = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M6.99956453,18.06 C5.89556453,18.06 4.99856453,17.164 4.99956453,16.06 C4.99956453,14.956 5.89556453,14.06 6.99856453,14.06 C8.10356453,14.059 8.99856453,14.956 8.99956453,16.06 C8.99856453,17.164 8.10356453,18.06 6.99956453,18.06 L6.99956453,18.06 Z M20.2915645,9.423 L21.7055645,8.009 L19.2315645,5.535 L20.6455645,4.122 L18.5235645,2 L9.03456453,11.491 C8.43756453,11.225 7.78956453,11.077 7.11756453,11.061 L7.00156453,11.06 C4.30756453,11.06 2.06456453,13.25 2.00156453,15.941 C1.96856453,17.277 2.45856453,18.544 3.38056453,19.511 C4.30256453,20.478 5.54556453,21.029 6.88056453,21.059 L7.00256453,21.06 C9.69256453,21.06 11.9345645,18.871 11.9975645,16.179 C12.0205645,15.217 11.7645645,14.294 11.2785645,13.489 L14.9895645,9.778 L17.4625645,12.254 L18.8775645,10.839 L16.4035645,8.364 L17.8175645,6.949 L20.2915645,9.423 Z" id="shape01" />
    </SvgIcon>
  )
}

export default PasswordKey
