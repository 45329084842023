import React from 'react'
import { AdminAuth, AdminLive, AdminLanguage, ClientSettings } from '../../../../containers/admin'
import { AdminApiCredentials } from '../AdminApiCredentials'
import { Redirect } from 'react-router-dom'
import { TabBar } from 'skylight-common'

import './AdminTabs.css'

const DEFAULT_PATHNAME = '/admin/tokens'

const TABS = [
  {
    label: { id: 'TAB_API_CREDENTIALS', defaultMessage: 'API credentials' },
    value: 'tokens',
    renderChildren: (type, props) => type === 'tokens' && <AdminApiCredentials className="admin-tab-content" {...props} />
  },
  {
    label: { id: 'TAB_AUTHENTICATION', defaultMessage: 'Authentication' },
    value: 'auth',
    renderChildren: (type) => type === 'auth' && <AdminAuth className="admin-tab-content" />
  },
  {
    label: { id: 'TAB_CLIENT_SETTINGS', defaultMessage: 'Client settings' },
    value: 'client',
    renderChildren: (type) => type === 'client' && <ClientSettings className="admin-tab-content" />
  },
  {
    label: { id: 'LANGUAGE', defaultMessage: 'Language' },
    value: 'locale',
    renderChildren: (type) => type === 'locale' && <AdminLanguage className="admin-tab-content" />
  },
  {
    label: { id: 'TAB_SKYLIGHT_LIVE', defaultMessage: 'Skylight Live' },
    value: 'live',
    renderChildren: (type) => type === 'live' && <AdminLive className="admin-tab-content" />
  }
]

class AdminTabs extends React.Component {
  onChange = (v) => {
    this.props.redirect(`/admin/${v}`)
  }

  render() {
    const type = this.props.type
    if (!type) {
      return <Redirect to={{ pathname: DEFAULT_PATHNAME }} />
    }

    return <TabBar tabs={TABS} className="admin-tabs" onChange={this.onChange} type={type} />
  }
}

export { AdminTabs }
