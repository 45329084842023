import React from 'react'
import { OutlinedField } from '../OutlinedField'
import ArrowDropDown from '../../Icons/arrowDropDown'
import { MenuPicker } from 'skylight-common'
import SelectedItems from './SelectedItems'

import './OutlinedMenuPicker.css'

class OutlinedMenuPicker extends React.Component {
  renderPlaceholder = () => {
    if (this.props.selectedItems.length) return null

    return <div className="selection-hint">
      {this.props.placeholder}
    </div>
  }

  renderSelection = () => {
    const { selectedItems, renderItem, rightIcon, onIconClick } = this.props
    if (!selectedItems.length) return null

    return <SelectedItems items={selectedItems} renderItem={renderItem} rightIcon={rightIcon} onIconClick={onIconClick} />
  }

  render() {
    const {
      items, selectedItems, onChange, searchDisabled,
      floatingLabelText
    } = this.props

    return (
      <OutlinedField className="outlined-menu-picker" floatingLabelText={floatingLabelText}>
        <MenuPicker
          autoWidth={false}
          iconButton={<ArrowDropDown className="items-picker-arrow" />}
          openImmediately={false}
          items={items}
          selected={selectedItems}
          selectionRenderer={this.renderSelection}
          onSelect={onChange}
          searchDisabled={searchDisabled} />
        {this.renderPlaceholder()}
      </OutlinedField>
    )
  }
}

export default OutlinedMenuPicker
