import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { DetailsLink } from 'skylight-common'
import skylight from 'skylight-js-sdk'
import { GROUP } from '../../../../svc/scopes'

import contactActions from '../../../../actions/contact'

const GroupDetailsLink = props => {
  const { group } = props
  if (!skylight.auth.hasScopes([GROUP.EDIT])) {
    return group.name
      ? <span>{group.name}</span>
      : <FormattedMessage id="ANONYMOUS" defaultMessage="Anonymous" values={{ value: '' }} />
  }

  const onNavigate = (item) => {
    props.onGroupMemberLoadItems({ id: item.id })
    props.onGroupSelectItems([item.id], [item])
  }

  return <DetailsLink
    item={group}
    renderLink="name"
    path={props.nextGen ? '/ng/groups' : '/team/groups'}
    onNavigateToDetails={onNavigate} />
}

export { GroupDetailsLink }

const mapDispatchToProps = {
  onGroupMemberLoadItems: contactActions.onGroupMemberLoadItems,
  onGroupSelectItems: contactActions.onGroupSelectItems
}

export default connect(null, mapDispatchToProps)(GroupDetailsLink)
