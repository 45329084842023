import * as ActionTypes from './types'
import { push } from 'react-router-redux'
import { createFilterActions, createListActions } from 'skylight-common'
import { assignWorkflow } from '../svc/assignments'
import { getWorkflows } from '../svc/workflows'
import { DEFAULT_QUERY } from '../lib/workflow'
import skylight from 'skylight-js-sdk'

const LIST_ACTIONS = createListActions({
  prefix: ActionTypes.PREFIX_WORKFLOW.toUpperCase(),
  handler: ActionTypes.PREFIX_WORKFLOW,
  onGetList: s => s.workflow.list,
  onLoad: getWorkflows,
  onDelete: async(ids) => await Promise.all(ids.map(skylight.workflow.v2.deleteById))
})

const FILTER_ACTIONS = createFilterActions({
  baseUrl: '/applications',
  redirect: false,
  selector: state => state.workflow.list.query,
  listPrefix: ActionTypes.PREFIX_WORKFLOW.toUpperCase(),
  actionPrefix: ActionTypes.PREFIX_WORKFLOW,
  DEFAULT_QUERY,
  loadItems: LIST_ACTIONS.onWorkflowLoadItems,
  push
})

const redirectToNewQuery = FILTER_ACTIONS.onWorkflowQueryUpdated

const openBuilder = (appId) => {
  const qs = appId ? skylight.http.asQueryString({ appId }) : ''
  window.open(`/builder/${qs || ''}`)
}

const newWorkflow = () => (dispatch, getState) => {
  dispatch({ type: ActionTypes.WORKFLOW_APPLICATION_NEW })
  openBuilder()
}

const assignApplications = (apps, users) => (dispatch, getState) => {
  const ids = apps
  apps = getState().workflow.list.items.filter(x => apps.includes(x.id))

  dispatch({ type: ActionTypes.WORKFLOW_APPLICATION_ASSIGN, ids })

  const assignments = []
  apps.forEach(a => users.forEach(u => assignments.push(
    assignWorkflow(a, u.id)
      .then(() => dispatch({ type: ActionTypes.WORKFLOW_APPLICATION_ASSIGNED, id: a.id }))
      .catch(() => dispatch({ type: ActionTypes.WORKFLOW_APPLICATION_ASSIGN_ERROR, id: a.id, item: a }))
  )))

  Promise.all(assignments)
}

const changeApplicationView = view =>
  redirectToNewQuery(query => ({ ...query, view }))

export const clearItemStatus = id => ({
  type: ActionTypes.WORKFLOW_APPLICATION_STATUS_CLEAR,
  id
})

export const openApplication = item => (dispatch, getState) => {
  // storage.saveJSON('openApplicationId', item.id) // Consider passing item
  dispatch({ type: ActionTypes.WORKFLOW_APPLICATION_OPEN })

  openBuilder(item.id)
}

const applicationSortUpdate = (sortBy, sortAsc) =>
  redirectToNewQuery(q => ({ ...q, sortBy, sortAsc }))

export const lockApplications = ids => async(dispatch, getState) => {
  await Promise.all(ids.map(id => skylight.workflow.v2.patch({ workflowId: id, isLocked: true })))
  const query = getState().workflow.list.query
  delete query.nextCursor
  dispatch(LIST_ACTIONS[`on${ActionTypes.PREFIX_WORKFLOW}LoadItems`](query))
}

export const duplicateApplication = (name, original) => async(dispatch, getState) => {
  // Get by id to access 'template' property which is required to save a new application.
  const item = await skylight.workflow.v2.getById(original.id)
  const application = { name, description: item.description, rootSequence: item.rootSequence, fade: item.fade, template: item.template }

  await skylight.workflow.v2.create(application)
  const query = getState().workflow.list.query
  delete query.nextCursor
  dispatch(LIST_ACTIONS[`on${ActionTypes.PREFIX_WORKFLOW}LoadItems`](query))
}

export default {
  ...LIST_ACTIONS,
  onChangeApplicationView: changeApplicationView,
  onNewWorkflow: newWorkflow,
  onApplicationsAssigned: assignApplications,
  onClearItemStatus: clearItemStatus,
  onOpenApplication: openApplication,
  onApplicationSortUpdate: applicationSortUpdate,
  onLockApplications: lockApplications,
  onDuplicateApplication: duplicateApplication,
  ...FILTER_ACTIONS
}
