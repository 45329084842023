import React from 'react'
import { NavLink } from 'react-router-dom'
import { Tooltip } from 'skylight-common'

import './Breadcrumbs.css'

const Breadcrumbs = props => {
  const { breadcrumbs = [], transitionIcon = '>' } = props

  const renderBreadcrumb = (breadcrumb, match) => {
    if (React.isValidElement(breadcrumb)) {
      return React.cloneElement(breadcrumb, { ...props, match })
    }

    return breadcrumb
  }

  const renderTransitionIcon = (isLastItem) => (
    !isLastItem
      ? <span className="breadcrumbs-transition-icon">{transitionIcon}</span>
      : null
  )

  const renderBreadcrumbItem = (match, title, isLastItem) => {
    if (isLastItem) {
      return <span>{title}</span>
    }

    return <NavLink to={match.url}>{title}</NavLink>
  }

  return <div className="breadcrumbs">
    {breadcrumbs.map(({ breadcrumb, path, match, tooltipDisabled = false }, i) => {
      const Title = renderBreadcrumb(breadcrumb, match)
      const isLastItem = i === breadcrumbs.length - 1
      return (
        <Tooltip tooltip={tooltipDisabled ? '' : Title} key={path}>
          <span className="breadcrumbs-item">
            {renderBreadcrumbItem(match, Title, isLastItem)}
            {renderTransitionIcon(isLastItem)}
          </span>
        </Tooltip>
      )
    })}
  </div>
}

export { Breadcrumbs }
