import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const removeTrash = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M18,7 L18,19 C18,20.1 17.1,21 16,21 L16,21 L8,21 C6.9,21 6,20.1 6,19 L6,19 L6,7 L18,7 Z M11,11 L9,11 L9,17 L11,17 L11,11 Z M15,11 L13,11 L13,17 L15,17 L15,11 Z M15,2 L15,4 L19,4 L19,6 L5,6 L5,4 L9,4 L9,2 L15,2 Z" />
    </SvgIcon>
  )
}

export default removeTrash
