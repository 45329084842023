import React from 'react'
import MinusIcon from 'material-ui/svg-icons/content/remove'
import PlusIcon from 'material-ui/svg-icons/content/add'
import './CallZoom.css'

export const CallZoom = props => {
  const { min = 0, max = 100, delta = 25, zoom, onChange } = props
  const onPlus = () => zoom < max && onChange(zoom + delta)
  const onMinus = () => zoom > min && onChange(zoom - delta)

  return (
    <div className="call-zoom">
      <div className={`zoom-btn plus ${zoom >= max ? 'disabled' : ''}`} onTouchTap={onPlus}>
        <PlusIcon />
      </div>
      <div className={`zoom-btn minus ${zoom <= min ? 'disabled' : ''}`} onTouchTap={onMinus}>
        <MinusIcon />
      </div>
    </div>
  )
}
