import React from 'react'
import classNames from 'classnames'
import { FieldControl, CursorTooltip } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import PersonIcon from 'material-ui/svg-icons/social/person'
import PersonAddIcon from 'material-ui/svg-icons/social/person-add'
import DeleteIcon from 'material-ui/svg-icons/navigation/close'

import './UserAttributesMapping.css'

const UserAttributesMapping = ({
  options = [],
  skylightAttribute = null,
  ldapAttribute,
  onDelete,
  onChange,
  disabled
}) => {
  const skylightProps = {
    field: 'skylightAttribute',
    name: 'skylightAttribute',
    type: 'select',
    options,
    disabled
  }
  const ldapProps = {
    field: 'ldapAttribute',
    name: 'ldapAttribute',
    type: 'text',
    disabled: disabled || skylightAttribute === null
  }

  const Icon = disabled ? PersonAddIcon : PersonIcon

  return (
    <div className={classNames('user-atributes-mapping', { disabled })}>
      <div className="user-atributes-mapping__user-prop-picker">
        <Icon className="user-atributes-mapping__person-icon" />
        <FieldControl
          fieldProps={skylightProps}
          defaultValue={null}
          value={skylightAttribute}
          onChange={onChange} />
      </div>
      <div className="user-atributes-mapping__ldap-prop-picker">
        <FieldControl fieldProps={ldapProps} value={ldapAttribute} onChange={onChange} />
        {onDelete &&
          <CursorTooltip
            className="user-atributes-mapping__delete-icon-tooltip"
            tooltip={<FormattedMessage id="ACTION_REMOVE" defaultMessage="Remove" />}>
            <DeleteIcon onTouchTap={onDelete} className="user-atributes-mapping__delete-icon" />
          </CursorTooltip>}
      </div>
    </div>
  )
}

export { UserAttributesMapping }
