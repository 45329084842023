import * as ActionTypes from '../../actions/types'

const initialState = {
  realm: '',
  realmError: null,
  config: null,
  isLoading: false,
  authApps: []
}

const realm = (state = initialState, payload, all) => {
  switch (payload.type) {
    case ActionTypes.SIGNIN_REALM_CHANGED:
      return { ...state, realm: payload.value, realmError: null }
    case ActionTypes.SIGNIN_REALM_ERROR:
      return { ...state, realmError: payload.error, isLoading: false }
    case ActionTypes.SIGNIN_REALM_CONFIG:
      return { ...state, config: payload.realm, authApps: payload.authApps, isLoading: false }
    case ActionTypes.SIGNIN_REALM_SIGNIN: {
      const realm = state.realm || payload.realm
      return {
        ...state,
        realm,
        realmError: realm ? null : 'REQUIRED_REALM',
        isLoading: !!realm
      }
    }
    default:
      return state
  }
}

export default realm
