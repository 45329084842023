import React from 'react'
import { FormattedTime } from 'skylight-common'
import CloseIcon from 'material-ui/svg-icons/navigation/close'
import IconButton from 'material-ui/IconButton'
import { UserAvatar, UserTitle } from '../../Common'

export const UnreadMessage = props => {
  const { message, partner, onClick, onClose } = props
  return (
    <div className="unread-message" onTouchTap={onClick}>
      <div className="avatar"><UserAvatar className="small" user={partner} /></div>
      <div className="title-body">
        <div className="title">
          <UserTitle user={partner} />
          <FormattedTime value={message.date} />
        </div>
        <div className="body">
          {message.message}
        </div>
      </div>
      <div className="close">
        <IconButton onTouchTap={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  )
}
