import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const TagIcon = (props) => {
  return <SvgIcon {...props}>
      <path d="M5,2.999 L15,2.999 L15,6.999 L19,6.999 L19,7.999 L21,7.999 L21,5.585 L16.414,0.999 L5,0.999 C3.897,0.999 3,1.895 3,2.999 L3,7.999 L5,7.999 L5,2.999 Z M19,20.999 L5,20.999 L5,19.999 L3,19.999 L3,20.999 C3,22.102 3.897,22.999 5,22.999 L19,22.999 C20.103,22.999 21,22.102 21,20.999 L21,19.999 L19,19.999 L19,20.999 Z M4,17.999 L4,14.999 L2,14.999 L2,17.999 L0,17.999 L0,9.999 L2,9.999 L2,12.999 L4,12.999 L4,9.999 L12,9.999 L12,10.999 C12,10.559 12.289,10.17 12.71,10.042 C13.131,9.914 13.587,10.078 13.832,10.444 L15,12.196 L16.168,10.444 C16.413,10.078 16.869,9.914 17.29,10.042 C17.711,10.17 18,10.559 18,10.999 L18,17.999 L16,17.999 L16,14.302 L15.832,14.554 C15.461,15.11 14.539,15.11 14.168,14.554 L14,14.302 L14,17.999 L12,17.999 L12,11.999 L10,11.999 L10,17.999 L8,17.999 L8,11.999 L6,11.999 L6,17.999 L4,17.999 Z M21,15.999 L24,15.999 L24,17.999 L19,17.999 L19,9.999 L21,9.999 L21,15.999 Z" />
    </SvgIcon>
}

export default TagIcon
