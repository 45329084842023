import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FieldControl, CursorTooltip } from 'skylight-common'
import { SectionTitle } from '../SectionTitle'
import { FieldControlTooltip } from './FieldControlTooltip'
import './BaseSection.css'

export function getError({ fieldProps, errors }) {
  let { field } = fieldProps
  const error = errors[field]

  if (!error) {
    return
  }

  if (field && field.length > 0) {
    field = field[0].toUpperCase() + field.substring(1)
  }

  return <FormattedMessage id={error} defaultMessage={error} values={{ field }} />
}

const BaseSection = ({
  sectionClassName = '',
  title = '',
  titleAction,
  fields,
  fieldTypes,
  item,
  errors,
  originalChanged,
  onFormFieldChange
}) => {
  const renderControls = () => (
    fields
      .map(fieldProps => {
        const FieldControlComp = (
          <div
            className="field-control-wrapper"
            key={fieldProps.field}>
            <FieldControl
              component={fieldProps.component}
              error={getError({ fieldProps, errors })}
              value={item[fieldProps.field]}
              defaultValue={fieldProps.defaultValue}
              originalChanged={originalChanged}
              onChange={onFormFieldChange}
              fieldTypes={fieldTypes}
              fieldProps={fieldProps} />
            {fieldProps.description && <FieldControlTooltip text={<FormattedMessage id={fieldProps.description} defaultMessage={fieldProps.description} />} />}
          </div>
        )

        if (fieldProps.tooltip) {
          return (
            <CursorTooltip tooltip={fieldProps.tooltip}>
              {FieldControlComp}
            </CursorTooltip>
          )
        }

        return FieldControlComp
      })
  )

  return (
    <div className={`ldap-section ${sectionClassName}`}>
      {title && <SectionTitle title={title} titleAction={titleAction} />}
      {renderControls()}
    </div>
  )
}

export { BaseSection }
