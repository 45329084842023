import React from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { Column, CursorTooltip } from 'skylight-common'
import get from 'lodash/get'
import IconButton from 'material-ui/IconButton'
import ArchiveApplicationIcon from 'material-ui/svg-icons/content/archive'
import WarningIcon from 'material-ui/svg-icons/alert/warning'
import { TableNextGen } from '../../../../Common/NextGen/TableNextGen'
import GlyphIcon from '../../../../Common/GlyphIcon/GlyphIcon'
import { DEFAULT_APP_ICON_CODE } from '../../../../../lib/application'
import { isFailedImport } from '../../../../../lib/application/exportImport'
import { EmptyListView } from './EmptyListView'
import { COLUMNS } from './columns'

import './ApplicationTable.css'

const ApplicationTable = (props) => {
  const { list, scopes } = props

  const getCheckboxIcon = item => {
    const isArchived = item.archived
    if (isArchived) {
      return <ArchiveApplicationIcon />
    }

    if (isFailedImport(item)) {
      return <WarningIcon className="icon failed-import-icon" />
    }

    const applicationIconName = get(item, 'icon.name', DEFAULT_APP_ICON_CODE)
    return <GlyphIcon code={applicationIconName} />
  }

  const onNavigateToUser = (item) => {
    props.onUserSelectItems([item.id], [item])
  }

  const renderItemActions = item => {
    const itemActions = props.getItemActions(item)
    return itemActions.map((a, i) => <CursorTooltip
      key={i}
      tooltip={<FormattedMessage id={a.tooltip.id} defaultMessage={a.tooltip.default} />}>
        <IconButton
        key={i}
        className={`item-action-button ${a.className || ''}`}
        onTouchTap={e => {
          e.stopPropagation()
          a.action()
        }}>
          {a.icon}
        </IconButton>
      </CursorTooltip>)
  }

  return (
    <div className="next-gen-application-table">
      <TableNextGen
        showCheckboxes
        checkboxIcon={getCheckboxIcon}
        showHover
        isLoading={list.isLoading}
        query={list.query}
        items={list.items}
        total={list.total}
        done={list.done}
        selectedRows={list.selectedIds}
        name="applicationList"
        tableFooter={props.listFooter}
        emptyLabel={<EmptyListView />}
        rowClassName={item => classNames('application-row', { archived: item.archived })}
        onQuery={props.onApplicationQuery}
        onRowsSelected={props.onApplicationSelectItems}>
        {COLUMNS.map(x => <Column
          key={x.name}
          name={x.name}
          sortable={x.sortable !== false}
          title={<FormattedMessage id={x.title} defaultMessage={x.title} />}
          render={x.render ? item => x.render(item, onNavigateToUser, scopes) : null} />)}
        <Column
          key="applicationActions"
          className="actions"
          isAction
          render={renderItemActions} />
      </TableNextGen>
    </div>
  )
}

export { ApplicationTable }
