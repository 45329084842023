import { CustomFilters } from '../../src/App/Home/Admin/AdminAuth/AuthApps/LdapOptions/CustomFilters'
import { LastSynced } from '../../src/App/Home/Admin/AdminAuth/AuthApps/LdapOptions/LastSynced'
import { CertVerification } from '../App/Home/Admin/AdminAuth/AuthApps/LdapOptions/CertVerification'

import { VALIDATION_RULES, roundToPrecision, SECONDS_TIME } from 'skylight-common'

export const DEFAULT_LDAP_SERVER_PORT = 389
export const DEFAULT_LDAP_CONNECTION_OPTION = 'NONE'

const CONNECTION_SECURITY_OPTIONS = [
  { value: DEFAULT_LDAP_CONNECTION_OPTION, title: 'LDAP_FIELD_METHOD_NONE' },
  { value: 'TLS', title: 'TLS' },
  { value: 'STARTTLS', title: 'STARTTLS' }
]

export const SAML_FIELDS = [
  { field: 'issuer', name: 'issuer', title: 'SAML_FIELD_ISSUER', type: 'text', required: true, disabled: true },
  { field: 'callbackUrl', name: 'callbackUrl', title: 'SAML_FIELD_CALLBACK_URL', type: 'text', required: true, disabled: true },
  {
    field: 'entryPoint',
    name: 'entryPoint',
    title: 'SAML_FIELD_ENTRY_POINT',
    type: 'text',
    required: true,
    rules: VALIDATION_RULES.isUrl
  },
  { field: 'cert', name: 'cert', title: 'SAML_FIELD_CERTIFICATE', type: 'text', required: true, multiline: true }
]

const isValidSyncInterval = {
  // sync interval should not be longer than 24 hours
  validate: v => (+v) <= 24,
  error: 'LDAP_FIELD_SYNC_INTERVAL_ERROR'
}

const checkCustomFilters = {
  validate: v => !!(v || []).length,
  error: 'LDAP_FIELD_CUSTOM_FILTERS_EMPTY_ERROR'
}

export const isIceUrl = {
  validate: v => ['stun:', 'turn:'].some(x => (v || '').toLowerCase().startsWith(x)),
  error: 'REQUIRED_FIELD_URL'
}

export const LDAP_SERVER_FIELDS = [
  { field: 'vendor', name: 'vendor', title: 'LDAP_FIELD_VENDOR', description: 'LDAP_FIELD_VENDOR_DESCRIPTION', type: 'text' },
  { field: 'serverIP', name: 'serverIP', title: 'LDAP_FIELD_SERVER_IP', description: 'LDAP_FIELD_SERVER_IP_DESCRIPTION', type: 'text', required: true },
  { field: 'serverPort', name: 'serverPort', title: 'LDAP_FIELD_SERVER_PORT', description: 'LDAP_FIELD_SERVER_PORT_DESCRIPTION', type: 'number', defaultValue: DEFAULT_LDAP_SERVER_PORT }
]

export const LDAP_SECURITY_FIELDS = [
  { field: 'connectionSecurity', name: 'connectionSecurity', title: 'LDAP_FIELD_SECURITY_METHOD', description: 'LDAP_FIELD_SECURITY_METHOD_DESCRIPTION', type: 'select', options: CONNECTION_SECURITY_OPTIONS },
  { field: 'certHostname', name: 'certHostname', title: 'LDAP_FIELD_CERT_HOSTNAME', description: 'LDAP_FIELD_CERT_HOSTNAME_DESCRIPTION', type: 'text' },
  { field: 'skipVerifyCert', name: 'skipVerifyCert', title: 'LDAP_FIELD_SKIP_CERT', description: 'LDAP_FIELD_SKIP_CERT_DESCRIPTION', type: 'checkBox', component: CertVerification },
  { field: 'rootCAsPem', name: 'rootCAsPem', title: 'LDAP_FIELD_ROOTCASPEM', description: 'LDAP_FIELD_ROOTCASPEM_DESCRIPTION', type: 'text', multiline: true }
]

export const LDAP_ADMIN_FIELDS = [
  { field: 'bindDN', name: 'bindDN', title: 'LDAP_FIELD_BIND_DN', description: 'LDAP_FIELD_BIND_DN_DESCRIPTION', type: 'text', required: true },
  { field: 'bindCredential', name: 'bindCredential', title: 'LDAP_FIELD_BIND_CREDENTIAL', description: 'LDAP_FIELD_BIND_CREDENTIAL_DESCRIPTION', type: 'password', required: true }
]

export const LDAP_USER_FIELDS = [
  { field: 'baseDN', name: 'baseDN', title: 'LDAP_FIELD_BASE_DN', description: 'LDAP_FIELD_BASE_DN_DESCRIPTION', type: 'text', required: true },
  { field: 'uuidAttribute', name: 'uuidAttribute', title: 'LDAP_FIELD_UUID_ATTRIBUTE', description: 'LDAP_FIELD_UUID_ATTRIBUTE_DESCRIPTION', type: 'text', required: true },
  { field: 'syncInterval', name: 'syncInterval', title: 'LDAP_FIELD_SYNC_INTERVAL', description: 'LDAP_FIELD_SYNC_INTERVAL_DESCRIPTION', rules: [isValidSyncInterval], type: 'number', required: true },
  { field: 'lastSyncedAt', name: 'lastSynced', title: '', type: 'text', component: LastSynced }
]

export const LDAP_FILTERS = [
  { field: 'customFilters', name: 'customFilters', title: 'LDAP_FIELD_CUSTOM_FILTERS', description: 'Custom filters to find LDAP users, sync them as Skylight users, and assign them to Skylight groups and/or roles. Skylight groups are created as necessary. At least one filter is required.', rules: [checkCustomFilters], type: 'text', component: CustomFilters }
]

export const LDAP_FIELDS = [
  ...LDAP_SECURITY_FIELDS,
  ...LDAP_SERVER_FIELDS,
  ...LDAP_ADMIN_FIELDS,
  ...LDAP_USER_FIELDS,
  ...LDAP_FILTERS
]

// See for min/max values - https://github.com/APXLabs/phoenix/blob/3431278317377aef58944fe07ff7472f1fb9208f/api/public.yml#L199
const isMoreThanMin = {
  validate: v => (+v) >= 600,
  error: { id: 'FORM_TOKEN_ERROR_MINUTE', value: 10 }
}

const isLessThanMax = {
  validate: v => (+v) <= 31536000,
  error: 'FORM_INVALID_TOKEN_TIMEOUT_ERROR'
}

const isLessThanTokenMax = {
  validate: v => (+v) <= Infinity, // Remove restriction on token timeout
  error: 'ACCESS_TOKEN_INVALID_TIMEOUT_FIELD_ERROR'
}

const convertValue = (v, d, p) => v ? roundToPrecision(v / d, p) : ''
export const TOKEN_SETTINGS_FIELDS = [
  {
    title: { id: 'USER_SESSION_TIMER', default: 'User session expiration' },
    description: { id: 'USER_SESSION_DESC' },
    label: { id: 'TIME_IN_DAYS', default: 'Time in days', isRequired: true },
    name: 'sessionMaxTimeout',
    type: 'number',
    required: true,
    rules: [isMoreThanMin, isLessThanMax],
    otherProps: { step: 'any', min: 0 },
    convertTo: v => convertValue(v, SECONDS_TIME.day, 3),
    convertFrom: v => roundToPrecision(v * SECONDS_TIME.day)
  },
  {
    title: { id: 'USER_INACTIVITY_TIMER', default: 'User inactivity token expiration' },
    description: { id: 'USER_INACTIVITY_DESC' },
    label: { id: 'TIME_IN_HOURS', default: 'Time in hours', isRequired: true },
    name: 'refreshTokenTimeout',
    type: 'number',
    required: true,
    rules: [isMoreThanMin, isLessThanMax],
    otherProps: { step: 'any', min: 0 },
    convertTo: v => convertValue(v, SECONDS_TIME.hour, 2),
    convertFrom: v => roundToPrecision(v * SECONDS_TIME.hour)
  },
  {
    title: { id: 'USER_API_TIMER', default: 'User API access token expiration' },
    description: { id: 'USER_API_DESC' },
    label: { id: 'TIME_IN_MINUTES', default: 'Time in minutes', isRequired: true },
    name: 'accessTokenTimeout',
    type: 'number',
    required: true,
    rules: [isMoreThanMin, isLessThanTokenMax],
    otherProps: { step: 'any', min: 0 },
    convertTo: v => convertValue(v, SECONDS_TIME.minute, 2),
    convertFrom: v => roundToPrecision(v * SECONDS_TIME.minute)
  }
]

export const TOKEN_SETTINGS_DIALOG_PROPS = {
  refreshTokenTimeout: {
    infoTitleId: 'INACTIVITY_TOKEN',
    infoTitleMessage: 'Inactivity Token',
    infoDescId: 'INACTIVITY_TOKEN_DESC',
    infoDescMessage: 'The inactivity token expiration determines how long the application can be out of contact with the server before the refresh token can no longer be used and the user is logged out. For some devices, the application can be running in the background while it is not actively being used. In that case, the application would only be considered inactive if the device is off or disconnected from wifi.'
  },
  accessTokenTimeout: {
    infoTitleId: 'API_ACCESS_TOKEN',
    infoTitleMessage: 'API access token',
    infoDescId: 'API_ACCESS_TOKEN_DESC',
    infoDescMessage: 'A short-lived JSON Web Token used to access and interact with protected data in the platform.'
  }
}

export const JOB_TIMEOUT = 5000

export const JOB_STATUSES = {
  ACTIVE: 'Active',
  SUCCEEDED: 'Succeeded',
  FAILED: 'Failed'
}

export const ROLES = {
  ADMIN: 'admin',
  USER: 'user',
  MANAGER: 'manager'
}

export const ROLES_LIST = [
  { value: ROLES.USER, title: 'USER' },
  { value: ROLES.MANAGER, title: 'MANAGER' },
  { value: ROLES.ADMIN, title: 'ADMIN' }
]

export const SKYLIGHT_ATTRIBUTES = [
  { value: 'email', title: 'email' },
  { value: 'firstName', title: 'firstName' },
  { value: 'lastName', title: 'lastName' },
  { value: 'jobTitle', title: 'jobTitle' },
  { value: 'officePhone', title: 'officePhone' },
  { value: 'mobilePhone', title: 'mobilePhone' },
  { value: 'location', title: 'location' },
  { value: 'street', title: 'street' },
  { value: 'city', title: 'city' },
  { value: 'state', title: 'state' },
  { value: 'code', title: 'code' }
]

export const SKYLIGHT_ATTRIBUTES_MAP = SKYLIGHT_ATTRIBUTES.reduce((acc, skylightAttribute) => {
  acc[skylightAttribute.value] = skylightAttribute
  return acc
}, {})
