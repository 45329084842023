import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actions } from 'skylight-common'

import { Home as HomeComp } from '../App/Home/Home'

const home = (state, props) => ({
  nav: state.layout.nav,
  router: state.router
})

const homeActions = {
  ...actions.nav
}

const Home = withRouter(connect(home, homeActions)(HomeComp))

export { Home }
