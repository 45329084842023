import * as ActionTypes from '../types'
import { push } from 'react-router-redux'
import { createFilterActions, createListActions, createItemActions, ValidationFieldError } from 'skylight-common'
import { getRoles, updateRole } from '../../svc/application/roles'
import { DEFAULT_QUERY, SUPER_PERMISSIONS_MAP } from '../../lib/application/roles'
import dialogActions from '../dialog'
import skylight from 'skylight-js-sdk'
import * as dialogTypes from '../../App/Home/Layout/Dialogs/dialogTypes'

const LIST_ACTIONS = createListActions({
  prefix: ActionTypes.PREFIX_APPLICATION_ROLE.toUpperCase(),
  handler: ActionTypes.PREFIX_APPLICATION_ROLE,
  onGetList: s => s.application.role.list,
  onLoad: async(query, { dispatch, getState }) => {
    const { application } = getState()
    const { applicationId } = application.selectedApplication
    return getRoles(applicationId)
  }
})

const FILTER_ACTIONS = createFilterActions({
  baseUrl: '/ng/applications/:applicationId/permissions/roles',
  redirect: false,
  selector: state => state.application.role.list.query,
  listPrefix: ActionTypes.PREFIX_APPLICATION_ROLE.toUpperCase(),
  actionPrefix: ActionTypes.PREFIX_APPLICATION_ROLE,
  DEFAULT_QUERY,
  loadItems: LIST_ACTIONS.onApplicationRoleLoadItems,
  push
})

const onSaveApplicationRole = async(role, ctx) => {
  const { applicationId } = ctx.getState().application.selectedApplication

  // filter fine grain media permissions
  for (const superPermission of Object.keys(SUPER_PERMISSIONS_MAP)) {
    if (role.permissions.includes(superPermission)) {
      role.permissions = role.permissions.filter(x => !SUPER_PERMISSIONS_MAP[superPermission].includes(x))
    }
  }

  try {
    if (role.id) {
      const { name, description, permissions } = role
      await updateRole(applicationId, role.id, { name, description, permissions })
      ctx.dispatch(dialogActions.onHideDialog(dialogTypes.ADD_APPLICATION_ROLE))
      return role
    }

    const createdRole = await skylight.we20.role.create(applicationId, role)
    ctx.dispatch(dialogActions.onHideDialog(dialogTypes.ADD_APPLICATION_ROLE))

    return createdRole
  } catch (e) {
    const error = await e.response.json()
    if (e.status === 409 || (error.error && error.error.includes('already exists'))) {
      throw new ValidationFieldError({ name: 'ALREADY_EXISTS' })
    }

    throw e
  }
}

const onValidateApplicationRole = item => {
  const errors = {}

  const namePattern = /^[a-zA-Z0-9 ]{1,30}$/
  if (!item.name || !item.name.match(namePattern)) {
    errors.name = 'INVALID'
  }

  return errors
}

const ITEM_ACTIONS = createItemActions({
  prefix: ActionTypes.PREFIX_APPLICATION_ROLE.toUpperCase(),
  handler: ActionTypes.PREFIX_APPLICATION_ROLE,
  onValidate: onValidateApplicationRole,
  onSave: onSaveApplicationRole,
  onGetList: s => s.application.role.list,
  onLoad: async(item, res, ctx) => item,
  onLoadItems: LIST_ACTIONS.onApplicationRoleLoadItems
})

const refreshRolesList = () => (dispatch, getState) => {
  const state = getState()
  dispatch(LIST_ACTIONS.onApplicationRoleLoadItems(state.application.role.list.query))
}

const applicationRoleNew = () => dispatch => {
  dispatch(ITEM_ACTIONS.onApplicationRoleNew({ name: '', description: '', permissions: [] }))
  dispatch(dialogActions.showAddApplicationRoleDialog())
}

const applicationRoleEdit = () => dispatch => {
  dispatch(ITEM_ACTIONS.onApplicationRoleChangeMode('edit'))
  dispatch(dialogActions.showAddApplicationRoleDialog())
}

const rolePermissionAdd = permission => (dispatch, getState) => {
  const { item: role } = getState().application.role.item
  dispatch(ITEM_ACTIONS.onApplicationRoleChange('permissions', [...role.permissions, permission]))
}

const rolePermissionRemove = permission => (dispatch, getState) => {
  const { item: role } = getState().application.role.item
  dispatch(ITEM_ACTIONS.onApplicationRoleChange('permissions', [...role.permissions.filter(x => x !== permission)]))
}

const applicationRoleSaveSubmitted = () => (dispatch, getState) => {
  const { item: role } = getState().application.role.item
  dispatch(ITEM_ACTIONS.onApplicationRoleSave(role))
}

const applicationRoleDuplicate = () => (dispatch, getState) => {
  const { original } = getState().application.role.item
  const roleDuplicate = {
    ...original,
    name: `${original.name} Copy`,
    description: '',
    id: undefined,
    roleId: undefined,
    participants: []
  }

  dispatch(ITEM_ACTIONS.onApplicationRoleNew(roleDuplicate))
  dispatch(dialogActions.showAddApplicationRoleDialog())
}

const removeSelectedApplicationRole = () => async(dispatch, getState) => {
  const { selectedApplication, role } = getState().application
  const { applicationId } = selectedApplication
  const { roleId } = role.item.original

  try {
    await skylight.we20.role.deleteById(applicationId, roleId)
    dispatch(refreshRolesList())
    dispatch(LIST_ACTIONS.onApplicationRoleSelectItems([]))
  } catch (e) {
    dispatch({ type: ActionTypes.APPLICATION_ROLE_REMOVE_ERROR, message: e.message })
  }
}

export default {
  ...LIST_ACTIONS,
  ...FILTER_ACTIONS,
  ...ITEM_ACTIONS,
  onApplicationRoleNew: applicationRoleNew,
  onApplicationRoleEdit: applicationRoleEdit,
  onRolePermissionAdd: rolePermissionAdd,
  onRolePermissionRemove: rolePermissionRemove,
  onApplicationRoleSaveSubmitted: applicationRoleSaveSubmitted,
  onApplicationRoleDuplicate: applicationRoleDuplicate,
  onRemoveSelectedApplicationRole: removeSelectedApplicationRole
}
