import React from 'react'
import classNames from 'classnames'

const iconCodeToUnicode = code => `&#x${code}`

const GlyphIcon = ({ code, className, ...props }) => (
  <span
    className={classNames('skylight-icons', className)}
    dangerouslySetInnerHTML={{ __html: iconCodeToUnicode(code) }}
    {...props} />
)

export default GlyphIcon
