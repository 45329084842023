import React from 'react'
import skylight from 'skylight-js-sdk'
import { connect } from 'react-redux'
import appActions from '../../actions/app'
import profileActions from '../../actions/profile'
import { AccountPassword } from '../Home/Profile/Settings/AccountPassword'
import LargeRaisedButton from '../Common/LargeRaisedButton/LargeRaisedButton'
import { FormattedMessage } from 'react-intl'
import CaptchaBadge from '../Common/Captcha/CaptchaBadge'
import get from 'lodash/get'
import { GENERIC_PASSWORD_REQUIREMENTS, MAX_PASSWORD_LENGTH, REQUIRED_PASSWORD_FIELDS } from '../../svc/login'
import { getCaptchaToken } from '../Common/Captcha/captcha'
import loginActions from '../../actions/login'

import './ChangeTempPassword.css'

class ChangeTempPassword extends React.Component {
  state = {
    maxLimitError: null
  }

  getUrlParams = () => skylight.http.queryStrToObj(this.props.location.search)

  componentDidMount() {
    const { signin } = this.props.login
    if (signin.username && signin.realm) return

    const { username, domain } = this.getUrlParams()
    if (!username || !domain) {
      this.props.redirect('/')
      return
    }

    this.props.onSetEnvParams(domain, username)
  }

  onFieldChange = (e, v) => {
    const { name } = e.target
    this.props.onProfileFieldChanged(name, v, 'passwordForm')
  }

  onBlur = e => {
    const { passwordForm } = this.props.profile.settings

    const { name } = e.target
    if (name === REQUIRED_PASSWORD_FIELDS.NEW_PASSWORD) {
      const genericErrors = GENERIC_PASSWORD_REQUIREMENTS
        .filter(x => x.validate({ password: get(passwordForm, REQUIRED_PASSWORD_FIELDS.NEW_PASSWORD, ''), username: get(this.getUrlParams(), 'username', '') }))
        .map(x => x.name)
      this.props.onProfileGenericErrorsUpdate(genericErrors)
    }
  }

  onFocus = e => {
    const { name } = e.target
    if (name === REQUIRED_PASSWORD_FIELDS.NEW_PASSWORD) {
      this.setState({ maxLimitError: null })
    }

    this.props.onProfileErrorsDelete(name)
  }

  onSubmit = async e => {
    e.preventDefault()

    const { settings } = this.props.profile
    const { genericErrors, passwordForm } = settings

    if (genericErrors.length) return
    if (get(passwordForm, REQUIRED_PASSWORD_FIELDS.NEW_PASSWORD, '').length > MAX_PASSWORD_LENGTH) {
      // clear all fields
      Object.values(REQUIRED_PASSWORD_FIELDS).forEach(x => this.props.onProfileFieldChanged(x, '', 'passwordForm'))
      this.setState({ maxLimitError: { id: 'PWD_DOES_NOT_MEET_SECURITY_REQ', defaultMessage: 'Password does not meet security requirements.' } })
      return
    }

    const captchaToken = await getCaptchaToken()
    this.props.onResetPassword({ ...passwordForm, captchaToken }, true)
  }

  render() {
    const { settings } = this.props.profile
    const { errors = {}, genericErrors } = settings || {}

    return <div className="change-temp-password__container">
      <form className="change-temp-password__form" onSubmit={this.onSubmit}>
        <FormattedMessage id="CHANGE_PASSWORD" defaultMessage="Change password">
          {txt => <div className="change-temp-password__title">{txt}</div>}
        </FormattedMessage>
        <AccountPassword
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          maxLimitError={this.state.maxLimitError}
          errors={errors}
          form={settings.passwordForm}
          onChange={this.onFieldChange}
          genericErrors={genericErrors} />
        <div className="change-temp-password-password-actions">
          <LargeRaisedButton
            primary
            className="change-temp-password__button"
            key="change-temp-password__button"
            label={<FormattedMessage id="CHANGE_PASSWORD" defaultMessage="Change password" />}
            type="submit" />
        </div>
        <CaptchaBadge />
      </form>
    </div>
  }
}

const mapStateToProps = state => ({
  profile: state.profile,
  login: state.login
})

const mapDispatchToProps = dispatch => ({
  onSetEnvParams: (realm, username) => {
    dispatch(loginActions.onRealmChange(realm))
    dispatch(loginActions.onUsernameChange(username))
  },
  onResetPassword: (form, isTemporary) => {
    dispatch(profileActions.onResetPassword(form, isTemporary))
  },
  onProfileFieldChanged: (name, value, formName) => {
    dispatch(profileActions.onProfileFieldChanged(name, value, formName))
  },
  redirect: path => {
    dispatch(appActions.redirect(path))
  },
  onProfileErrorsDelete: () => {
    dispatch(profileActions.onProfileErrorsDelete())
  },
  onProfileGenericErrorsUpdate: errors => {
    dispatch(profileActions.onProfileGenericErrorsUpdate(errors))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeTempPassword)
