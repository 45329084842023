import React from 'react'
import { FormattedMessage } from 'react-intl'
import LockIcon from 'material-ui/svg-icons/action/lock'
import { Table, Column } from 'skylight-common'
import ApplicationsIcon from '../../../Common/Icons/applications'
import { ApplicationStatusIcon } from './ApplicationStatusIcon'
import { COLUMNS } from './columns'
import './ApplicationTable.css'

const ApplicationTable = (props) => {
  const { list, status, scopes } = props

  const getRowClass = item => {
    return status[item.id] || ''
  }

  const getCheckboxIcon = item => {
    if (item.isLocked) {
      return <LockIcon />
    }
    return status[item.id]
      ? <ApplicationStatusIcon
        item={item}
        status={status}
        onError={props.onAssignmentByApp} />
      : <ApplicationsIcon />
  }

  const onNavigateToUser = (item) => {
    props.onUserSelectItems([item.id], [item])
  }

  return (
    <div className="application-table">
      <Table
        tableHeading={props.heading}
        showCheckboxes={true}
        tableHeaderActions={props.tableHeaderActions}
        rowClassName={getRowClass}
        checkboxIcon={getCheckboxIcon}
        showHover={true}
        isLoading={list.isLoading}
        query={list.query}
        items={list.items}
        total={list.total}
        done={list.done}
        selectedRows={list.selectedIds}
        name="applicationList"
        tableFooter={props.listFooter}
        emptyLabel={<FormattedMessage
          id="EMPTY_WORKFLOW_LIST"
          defaultMessage="No applications are created yet" />
        }
        onQuery={props.onWorkflowQuery}
        onRowsSelected={props.onWorkflowSelectItems}>
        {COLUMNS.map(x => <Column
          key={x.name}
          name={x.name}
          sortable={x.sortable !== false}
          title={<FormattedMessage id={x.title} defaultMessage={x.title} />}
          render={x.render ? item => x.render(item, onNavigateToUser, scopes) : null} />)}
        <Column
          key="applicationActions"
          className="actions"
          isAction={true}
          render={props.renderItemActions} />
      </Table>
    </div>
  )
}

export { ApplicationTable }
