import React from 'react'
import { TextFilter } from 'skylight-common'

const VALUE = 'name'

const TokenTextFilter = props => {
  const onChange = (v) => {
    const filter = { ...props.query.filter, [VALUE]: v }
    props.onApiTokenFilterUpdated(filter)
  }

  return <TextFilter
    value={props.query.filter.name}
    className="token-text-filter"
    title="PLACEHOLDER_NAME"
    onChange={onChange} />
}

export { TokenTextFilter }
