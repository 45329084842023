import skylight from 'skylight-js-sdk'
import React from 'react'
import CircularProgress from 'material-ui/CircularProgress'
import { FormattedMessage } from 'react-intl'

const states = {
  [skylight.mqtt.STATES.DISCONNECTED]: { className: 'error', label: 'MQTT_DISCONNECTED' },
  [skylight.mqtt.STATES.ERROR]: { className: 'error', label: 'MQTT_DISCONNECTED' },
  [skylight.mqtt.STATES.CONNECTED]: { className: 'connected', label: 'MQTT_CONNECTED', show: false },
  [skylight.mqtt.STATES.CONNECTING]: { className: 'connecting', label: 'MQTT_CONNECTING', showProgress: true },
  RECONNECT_IN: { className: 'reconnectingIn', label: 'MQTT_RECONNECTING_IN' },
  OFFLINE: { className: 'error', label: 'NETSCAN_OFFLINE' }
}

export const MqttStatus = ({ mqtt, network }) => {
  let state
  const value = mqtt.reconnectIn + ''
  if (network.isOnline) {
    state = states[mqtt.state]
    if (state.show === false) return null

    if (mqtt.reconnectIn > 0 && mqtt.state !== skylight.mqtt.STATES.CONNECTED) {
      state = states.RECONNECT_IN
    }
  } else {
    state = states.OFFLINE
  }

  return (
    <div className={`mqtt-status ${state.className}`}>
      { state.showProgress && <CircularProgress size={20} className="progress" /> }
      <div className="label">
        <FormattedMessage id={state.label} values={{ value }} />
      </div>
    </div>
  )
}
