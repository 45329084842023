import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const FileTypeZip = (props) => {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" d="M15.414,2 L20,6.586 L20,20 C20,21.103 19.103,22 18,22 L18,22 L6,22 C4.897,22 4,21.103 4,20 L4,20 L4,4 C4,2.897 4.897,2 6,2 L6,2 L15.414,2 Z M14,3 L14,8 L19,8 L14,3 Z M17.25,14 C18.491,14 19.5,15.009 19.5,16.25 C19.5,17.491 18.491,18.5 17.25,18.5 L16.5,18.5 L16.5,20 L15,20 L15,14 L17.25,14 Z M17.25,15.5 L16.5,15.5 L16.5,17 L17.25,17 C17.664,17 18,16.664 18,16.25 C18,15.836 17.664,15.5 17.25,15.5 Z M14,15.5 L13.25,15.5 L13.25,18.5 L14,18.5 L14,20 L11,20 L11,18.5 L11.75,18.5 L11.75,15.5 L11,15.5 L11,14 L14,14 L14,15.5 Z M9.5,20 L5.75,20 C5.474,20 5.219,19.848 5.089,19.604 C4.958,19.36 4.973,19.063 5.126,18.834 L7.349,15.5 L5,15.5 L5,14 L8.75,14 C9.026,14 9.281,14.152 9.411,14.396 C9.542,14.64 9.527,14.937 9.374,15.166 L7.151,18.5 L9.5,18.5 L9.5,20 Z" />
    </SvgIcon>
  )
}

export default FileTypeZip
