import React from 'react'
import { FormattedMessage } from 'react-intl'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import { SHOW_TOKEN_CREDENTIALS } from './dialogTypes'
import WarningIcon from 'material-ui/svg-icons/alert/warning'
import CopyContent from 'material-ui/svg-icons/content/content-copy'
import IconButton from 'material-ui/IconButton'
import { CONFIG, downloadFile } from 'skylight-common'
import skylight from 'skylight-js-sdk'

import './ShowTokenCredentialsDialog.css'

const downloadJSON = (data, filename = 'keys') => {
  const blob = new Blob([data], { type: 'text/json;charset=utf-8' })
  const url = window.URL.createObjectURL(blob)
  downloadFile(url, `${filename}.json`)
}

const COPIED_TIMEOUT = 5000

class ShowTokenCredentialsDialog extends React.Component {
  state = { isCopied: false }
  textAreaRef = null

  formatDataFromToken = () => {
    const { params: token } = this.props.dialog[this.props.dialogType] || {}

    const data = {
      id: token.id,
      username: token.username,
      password: token.password,
      domain: skylight.auth.user.realm,
      apiUrl: CONFIG.apiUrl,
      wsUrl: CONFIG.socketUrl,
      mqttUrl: CONFIG.mqttUrl
    }

    return JSON.stringify(data, undefined, 2)
  }

  formattedToken = this.formatDataFromToken()

  onDownloadCredentials = () => {
    return downloadJSON(this.formattedToken)
  }

  onCopyToClipboard = () => {
    const clipboardListener = e => {
      e.clipboardData.setData('text/plain', this.formattedToken)
      e.preventDefault()
    }

    this.executeCopy(clipboardListener)

    this.setState({ isCopied: true })

    clearTimeout(this.copyTimeout)

    this.copyTimeout = setTimeout(() => {
      this.setState({ isCopied: false })
    }, COPIED_TIMEOUT)
  }

  executeCopy = clipboardListener => {
    document.addEventListener('copy', clipboardListener)
    document.execCommand('copy')
    document.removeEventListener('copy', clipboardListener)
  }

  onClose = () => this.props.onHideDialog(SHOW_TOKEN_CREDENTIALS)

  renderDialogTitle = () => {
    return (
      <div className="token-credentials-dialog-title">
        <WarningIcon className="dialog-icon" />
        <FormattedMessage className="dialog-title" id="SAVE_API_CREDENTIAL" defaultMessage="Save API credential key" />
      </div>
    )
  }

  setContentRef = ref => {
    return (this.textAreaRef = ref)
  }

  render() {
    const { open, params: token } = this.props.dialog[this.props.dialogType] || {}

    if (!token) {
      return null
    }
    const dialogActions = [
      <FlatButton
        key="cancel-action"
        className="cancel-action"
        label={<FormattedMessage id="CLOSE" defaultMessage="Close" />}
        onTouchTap={this.onClose}/>,
      <FlatButton
        key="download-action"
        label={<FormattedMessage id="ACTION_DOWNLOAD" defaultMessage="Download" />}
        primary
        onTouchTap={this.onDownloadCredentials}/>
    ]

    return (
      <div className="show-token-credentials-wrapper">
        <Dialog
          title={this.renderDialogTitle()}
          actions={dialogActions}
          modal={false}
          open={!!open}
          className="dialog-modal show-token-credentials-dialog"
          autoDetectWindowHeight={false}
          onRequestClose={this.onClose}>
          <div className="show-token-credentials-content">
            <div className="show-token-credential-description">
              <FormattedMessage id="SAVE_API_CREDENTIAL_DESC" />
            </div>
            <div className="show-credential-config">
              <IconButton
                className="show-copy-content-button"
                tooltip={<FormattedMessage id={this.state.isCopied ? 'COPIED' : 'COPY_THE_KEY'} default="Copy the key" />}
                tooltipPosition="bottom-left"
                onTouchTap={this.onCopyToClipboard}>
                <CopyContent />
              </IconButton>
              <textarea
                ref={this.setContentRef}
                readOnly
                className="show-credential-config-text"
                value={this.formattedToken}/>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}

export { ShowTokenCredentialsDialog }
