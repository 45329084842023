import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const filterSearch = (props) => {
  return (
    <SvgIcon {...props}>
      <g id="searchFilter" fill="#414042">
        <g id="arrows/expand/down" transform="translate(16.000000, 6.000000)">
          <polygon id="ic_arrows_expand_down_01" opacity="0.33" points="4 8.75 0 5.25 1.71428571 3.75 4 5.75 6.28571429 3.75 8 5.25" />
        </g>
        <g id="window-search" transform="translate(0.000000, 4.000000)" opacity="0.33">
          <path d="M6.66666667,1.33333333 C8.09133333,1.33333333 9.43066667,1.888 10.4373333,2.896 C11.4446667,3.90266667 12,5.242 12,6.66666667 C12,7.63466667 11.738,8.56066667 11.258,9.37266667 L11.258,9.37266667 L14.276,12.3906667 C14.528,12.6426667 14.6666667,12.9773333 14.6666667,13.3333333 C14.6666667,13.69 14.528,14.024 14.276,14.276 C14.0246667,14.528 13.6893333,14.6666667 13.3333333,14.6666667 C12.9773333,14.6666667 12.642,14.528 12.39,14.2753333 L12.39,14.2753333 L9.37266667,11.258 C8.56066667,11.738 7.63466667,12 6.66666667,12 C5.242,12 3.90266667,11.4453333 2.896,10.4373333 C1.88866667,9.43066667 1.33333333,8.09133333 1.33333333,6.66666667 C1.33333333,5.242 1.88866667,3.90266667 2.896,2.896 C3.90266667,1.888 5.242,1.33333333 6.66666667,1.33333333 Z M6.66666667,3.33333333 C4.82222222,3.33333333 3.33333333,4.82222222 3.33333333,6.66666667 C3.33333333,8.51111111 4.82222222,10 6.66666667,10 C8.51111111,10 10,8.51111111 10,6.66666667 C10,4.82222222 8.51111111,3.33333333 6.66666667,3.33333333 Z" id="shape01" />
        </g>
      </g>
    </SvgIcon>
  )
}

export default filterSearch
