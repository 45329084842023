import * as ActionTypes from '../types'
import { push } from 'react-router-redux'
import {
  createFilterActions,
  createItemActions,
  createListActions,
  validate,
  VALIDATION_RULES,
  ValidationFieldError
} from 'skylight-common'
import skylight from 'skylight-js-sdk'
import { USER_DEFAULT_QUERY } from '../../lib/contact'
import { generateTempPassword } from '../../lib/login'
import { setSsoData, ssoData } from '../../svc/login'
import { addGroupMember, onAddMembersToGroups, refreshUser } from './common'
import { hideDialog } from '../dialog'
import * as dialogTypes from '../../App/Home/Layout/Dialogs/dialogTypes'

const excludeMe = (users, getState) => {
  const state = getState ? getState() : null
  return state ? users.filter(u => u.id !== state.auth.user.id) : users
}

const loadUsers = async(query, getState) => {
  let users = await skylight.user.list({ ...query, search: query.filter?.title || '' })// await getUsers({...query})
  users.forEach(u => { u.groups = u.groups || [] })
  // TODO: Remove if/when implemented on backend.
  if (query.includeMe === false) {
    users = excludeMe(users, getState)
  }

  return users
}

const onValidateUser = (user) => {
  const validationFields = [
    {
      name: 'username',
      required: true,
      rules: {
        validate: (v = '') => v === v.trim(),
        error: 'NO_TRAILING_SPACES'
      }
    },
    { name: 'role', required: true },
    { name: 'email', required: user.role === 'admin', rules: VALIDATION_RULES.isEmail, error: 'REQUIRED_VALID' }
  ]

  return validate(user, validationFields)
}

const onLoadUser = async(item, res, ctx) => {
  const user = await skylight.user.getById(item.id || res.id) // await getUserById(item.id || res.id)
  skylight.user.refreshCache()
  return user
}

const onSaveUser = async(user, { dispatch }) => {
  if (!user.id) {
    user.password = generateTempPassword()
  }
  try {
    const res = await skylight.user.save(user)
    await skylight.user.updateRole(user.id || res.id, { role: user.role })

    const savedUser = { ...res, password: user.password }
    if (!user.id) {
      dispatch(hideDialog(dialogTypes.NEW_USER))
      dispatch(resetUserPassword({ ...user, id: savedUser.id, password: undefined }))
    }
    return savedUser
  } catch (e) {
    // TODO: handle somehow.
    const error = await e.response.text()
    if (e.status === 409 || error.includes('already exists')) {
      throw new ValidationFieldError({ username: 'ALREADY_EXISTS' })
    }

    throw e
  }
}

const deleteUsers = async(ids) => {
  await Promise.all(ids.map(skylight.user.deleteById))
  await skylight.user.refreshCache()
}

const COMMON_USER_OPTIONS = {
  prefix: ActionTypes.PREFIX_USER.toUpperCase(),
  handler: ActionTypes.PREFIX_USER,
  onGetList: s => s.contact.users
}

const USER_LIST_ACTIONS = createListActions({
  ...COMMON_USER_OPTIONS,
  onLoad: loadUsers,
  onDelete: deleteUsers
})

const USER_ACTIONS = createItemActions({
  ...COMMON_USER_OPTIONS,
  onValidate: onValidateUser,
  onSave: onSaveUser,
  onLoad: onLoadUser,
  onLoadItems: USER_LIST_ACTIONS.onUserLoadItems
})

const USER_FILTER_ACTIONS = createFilterActions({
  baseUrl: '/team/users', // or /team
  redirect: false,
  selector: state => state.contact.users.query,
  listPrefix: ActionTypes.PREFIX_USER.toUpperCase(),
  actionPrefix: ActionTypes.PREFIX_USER,
  DEFAULT_QUERY: USER_DEFAULT_QUERY,
  loadItems: USER_LIST_ACTIONS.onUserLoadItems,
  push
})

const addingGroupMember = () => ({
  type: ActionTypes.GROUP_MEMBER_ADDING
})

const removeGroupMember = (user, group) => {
  return (dispatch, getState) => {
    dispatch({ type: ActionTypes.GROUP_MEMBER_REMOVING_START })
    // Update user groups locally to prevent flickering.
    // Worst case, we refresh the user's data if error occurs.
    const currentUser = getState().contact.user.item
    const userGroups = user.groups || []
    const previewUser = { ...user, groups: userGroups.filter(g => g.id !== group.id) }
    dispatch({ type: ActionTypes.PREFIX_LIST_USERS + ActionTypes.LIST_UPDATE, item: previewUser })

    skylight.membership.removeUserFromGroup(user.id, group.id)
      .then(() => {
        dispatch({ type: ActionTypes.GROUP_MEMBER_REMOVING_SUCCESS })
      })
      .catch(() => {
        dispatch({ type: ActionTypes.GROUP_MEMBER_REMOVING_ERROR })
      })
      .then(() => {
        // Consider changing to onRefreshUser
        const authRefreshAction = user.id === getState().auth.user.id
          ? user => ({ type: ActionTypes.AUTH_USER_REFRESHED, user })
          : null

        dispatch(refreshUser(user, currentUser ? ['groups'] : null, authRefreshAction))
      })
  }
}

const avatarUploadError = () => ({ type: ActionTypes.MEDIA_IMAGE_UPLOAD_ERROR })

export const resetUserPassword = user => async dispatch => {
  if (!user.id) {
    return
  }
  const password = user.password || generateTempPassword()

  try {
    const useTempPassword = true
    await skylight.user.changePassword(user.id, password, useTempPassword)

    dispatch({ type: ActionTypes.USER_TEMP_PASSWORD_RESET_SUCCESS, password })
  } catch (e) {
    dispatch({ type: ActionTypes.USER_TEMP_PASSWORD_RESET_ERROR })
    throw e
  }
}

const changeUserPassword = (user, password, confirmPassword) => {
  return async(dispatch) => {
    if (!password) {
      dispatch({ type: ActionTypes.USER_SAVE_FIELD_ERROR, errors: { password: 'REQUIRED' } })
      return
    }

    if (!confirmPassword) {
      dispatch({ type: ActionTypes.USER_SAVE_FIELD_ERROR, errors: { confirmPassword: 'REQUIRED' } })
      return
    }

    if (password !== confirmPassword) {
      dispatch({ type: ActionTypes.USER_SAVE_FIELD_ERROR, errors: { confirmPassword: 'NOT_EQUAL' } })
      return
    }

    await skylight.user.changePassword(user.id, password)
    dispatch(USER_ACTIONS.onUserChange('password', ''))
    dispatch(USER_ACTIONS.onUserChange('confirmPassword', ''))
    if (user.id === skylight.auth.user.id) {
      setSsoData({ ...ssoData, password })
    }
  }
}

const onSignoutUsers = (ids) => dispatch => {
  Promise.all(ids.map(skylight.user.signoutById))
    .then(() => {
      dispatch({ type: ActionTypes.SIGNOUT_USERS_SUCCESS_ERROR, className: 'snackbar-common-notification' })
    })
    .catch(() => {
      dispatch({ type: ActionTypes.SIGNOUT_USERS_ERROR, className: 'snackbar-common-error' })
    })
}

export default {
  ...USER_FILTER_ACTIONS,
  ...USER_LIST_ACTIONS,
  ...USER_ACTIONS,

  onRemoveGroupMember: removeGroupMember,
  onAddGroupMember: addGroupMember,
  onAddingGroupMember: addingGroupMember,
  onAddMembersToGroups,

  onChangeUserPassword: changeUserPassword,
  onResetUserPassword: resetUserPassword,
  onAvatarUploadError: avatarUploadError,
  onSignoutUsers
}
