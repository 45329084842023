import skylight from 'skylight-js-sdk'
import DeviceClientRun from '../../../../Common/Icons/deviceClientRun'
import NextGenAppConfigureIcon from '../../../../Common/Icons/nextGenApplicationConfigure'
import QrCodeScanIcon from '../../../../Common/Icons/qrcodeScan'
import WifiStatusIcon from '../../../../Common/Icons/wifiStatus'
import SignOutIcon from '../../../../Common/Icons/signOut'
import LockIcon from 'material-ui/svg-icons/action/lock'
import QuotesIcon from 'material-ui/svg-icons/editor/format-quote'
import PersonIcon from 'material-ui/svg-icons/social/person'
import PeopleIcon from 'material-ui/svg-icons/social/group'

import { FA_APPLICATION, APPLICATION, GROUP, INTEGRATION } from '../../../../../svc/scopes'

const SECTIONS = {
  MY_SKYLIGHT: 'MY_SKYLIGHT',
  APP_DASHBOARD: 'APP_DASHBOARD',
  DOMAIN_SETTINGS: 'DOMAIN_SETTINGS',
  USER_MANAGEMENT: 'USER_MANAGEMENT'
}

export const getItems = (props, matchParams) => {
  const { drawerSections } = props.layout.nav
  const isFrontline = skylight.utils.isFrontline()
  const pairDevice = isFrontline
    ? []
    : [{
        title: 'PAIR_DEVICE_TITLE',
        default: 'Pair a device',
        handler: () => props.onShowAddDeviceDialog(props.auth, { timeout: 60 }),
        id: 'pair_device',
        icon: QrCodeScanIcon
      }]

  return [
    {
      title: { id: 'MY_SKYLIGHT', defaultMessage: 'Home' },
      collapsible: false,
      id: SECTIONS.MY_SKYLIGHT,
      open: true,
      items: [
        {
          title: 'RUN_WEB_CLIENT',
          default: 'Run web client',
          handler: () => { window.open('/ng/client/') },
          id: 'run_web_client',
          icon: DeviceClientRun
        },
        ...pairDevice,
        {
          title: 'SETUP_WIFI',
          default: 'Setup WiFi',
          handler: props.onShowSetupWifiDialog,
          id: 'setup_wifi',
          icon: WifiStatusIcon
        }
      ]
    },
    {
      title: { id: 'APP_DASHBOARD', defaultMessage: 'Application Dashboard' },
      id: SECTIONS.APP_DASHBOARD,
      open: drawerSections[SECTIONS.APP_DASHBOARD],
      collapsible: true,
      withDivider: true,
      scopes: [isFrontline ? FA_APPLICATION.READ : APPLICATION.READ],
      items: [
        {
          title: 'MENU_NEXT_GEN_APPLICATIONS',
          default: 'Applications',
          route: '/ng/applications',
          id: 'menu_next_gen_applications',
          icon: NextGenAppConfigureIcon,
          scopes: [isFrontline ? FA_APPLICATION.READ : APPLICATION.READ]
        }
      ]
    },
    {
      title: { id: 'DOMAIN_SETTINGS', defaultMessage: 'Domain Settings' },
      id: SECTIONS.DOMAIN_SETTINGS,
      open: drawerSections[SECTIONS.DOMAIN_SETTINGS],
      collapsible: true,
      withDivider: true,
      scopes: [INTEGRATION.READ],
      items: [
        {
          title: 'MENU_AUTHENTICATION',
          default: 'Authentication',
          route: '/ng/auth',
          id: 'authentication',
          icon: LockIcon
        },
        // NOTE: Disabled until API will support this (https://upskill.atlassian.net/browse/APP2-1052)
        // {
        //   title: 'MENU_CLIENTS',
        //   default: 'Clients',
        //   route: '/ng/clients',
        //   id: 'clients',
        //   icon: SmartGlassesIcon
        // },
        {
          title: 'MENU_LANGUAGE',
          default: 'Language',
          route: '/ng/locale',
          id: 'language',
          icon: QuotesIcon
        }
      ]
    },
    {
      title: { id: 'USER_MANAGEMENT', defaultMessage: 'User Management' },
      id: SECTIONS.USER_MANAGEMENT,
      open: drawerSections[SECTIONS.USER_MANAGEMENT],
      collapsible: true,
      withDivider: true,
      scopes: [GROUP.EDIT],
      items: [
        {
          title: 'MENU_USERS',
          default: 'Users',
          route: '/ng/users',
          id: 'users',
          icon: PersonIcon
        },
        {
          title: 'MENU_GROUPS',
          default: 'Groups',
          route: '/ng/groups',
          id: 'groups',
          icon: PeopleIcon
        }
      ]
    },
    {
      title: 'MENU_SIGNOUT',
      id: 'menu_signout',
      icon: SignOutIcon,
      default: 'Sign Out',
      handler: p => p.signout(),
      stickToBottom: true,
      tooltipPosition: 'top'
    }
  ]
}

export const getMobileItems = (props, matchParams) => getItems(props, matchParams)
