export const FIELDS = [
  {
    prop: 'syncType',
    required: true,
    name: 'syncType',
    title: 'CUSTOM_FILTERS_SYNC_TYPE',
    defaultMessage: 'Sync Type',
    type: 'text',
    disabled: true,
    defaultValue: 'group'
  },
  {
    prop: 'filter',
    required: true,
    name: 'filter',
    title: 'CUSTOM_FILTERS_FILTER',
    defaultMessage: 'Filter',
    type: 'text',
    defaultValue: ''
  },
  {
    prop: 'name',
    required: true,
    name: 'name',
    title: 'CUSTOM_FILTERS_NAME',
    defaultMessage: 'Name',
    type: 'text',
    defaultValue: ''
  }
]
