export const EXPORT_STATUSES = {
  QUEUED: 'queued',
  IN_PROGRESS: 'inProgress',
  COMPLETED: 'completed',
  ERRORED: 'errored'
}

export const IMPORT_STATUSES = {
  CREATED: 'created',
  IN_PROGRESS: 'inProgress',
  COMPLETED: 'completed',
  DELETED: 'deleted',
  ERRORED: 'errored'
}

export const isFailedImport = application =>
  application && application.import && application.import.status === IMPORT_STATUSES.ERRORED
