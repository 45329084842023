import React from 'react'
import { FormattedMessage } from 'react-intl'
import { IconMenu, IconButton, MenuItem } from 'material-ui'

import {
  GridHeader, isMobile, Loading, SortPicker, MediaGallery,
  DeleteIcon, ListActionButton, ActionButton, ListFooterStats
} from 'skylight-common'

import ListIcon from 'material-ui/svg-icons/action/view-list'
import GridIcon from 'material-ui/svg-icons/action/view-module'
import DownloadIcon from 'material-ui/svg-icons/file/file-download'
import UploadIcon from 'material-ui/svg-icons/file/file-upload'
import ModifyIcon from 'material-ui/svg-icons/content/create'
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert'
import FullscreenIcon from 'material-ui/svg-icons/navigation/fullscreen'

import { ListView } from './ListView'
import { MediaGrid } from './MediaGrid'
import { COLUMNS } from './columns'
import { withDownloadSnackbar } from '../withDownloadSnackbar'

import './MediaList.css'

const filterByFilename = (x, q) =>
  !q.filter.title || q.filter.title
    .toLowerCase()
    .split(',')
    .filter(x => !!x.trim())
    .some(f => (x.name && x.name.toLowerCase().includes(f.trim())) ||
      (x.id && x.id.toLowerCase().includes(f.trim()))
    )

class BaseMediaList extends React.Component {
  getSelectedItems = () => {
    const { list } = this.props

    return list.selectedIds.map(id => list.items.find(x => x.id === id))
  }

  onRemove = (items) => {
    const selectedIds = items && items.length > 0
      ? items.map(i => i.id) // Delete from preview toolbar
      : this.props.list.selectedIds
    this.props.onConfirm(
      () => this.props.onMediaDeleteItems(selectedIds),
      { message: 'MEDIA_REMOVE_DIALOG_DESCRIPTION' }
    )
  }

  onDownload() {
    const items = this.getSelectedItems()
    this.props.onDownload(items, this.props.onDownloadMediaItem)
  }

  onOverflowItemTouchTap = (event, child) => {
    const menuItemId = child && child.props && child.props.id

    if (menuItemId === 'download-media-menu-item') return this.onDownload()
    if (menuItemId === 'upload-media-menu-item') return this.props.onShowNewMediaDialog()

    if (menuItemId === 'fullscreen-media-menu-item') {
      const selectedId =
        this.props.list.selectedIds.length &&
        this.props.list.selectedIds[0]

      if (selectedId) return this.props.onPreviewMediaItem(selectedId)
    }
  }

  onModifyActionClick = () => {
    const selectedItem = this.getSelectedItems()[0]

    this.props.onMediaChangeMode('edit', selectedItem)

    if (isMobile()) {
      // On web mobile layout click on 'modify' button should open
      // properties panel with current selected item
      this.props.onMediaSelectItems([selectedItem.id])
    }
  }

  getHeadingActions = (Icon, value) => {
    const current = value === 'list' ? 'grid' : 'list'
    const disabled = this.props.item.mode === 'edit' || this.props.item.mode === 'new'
    const numSelected = this.props.list.selectedIds.length
    const isAdmin = this.props.user.role === 'admin'

    // Note: UX rules dictate: Only 3 buttons (including overflow) may be visible concurrently
    const actions = [
      <ActionButton
        key="modifyIcon"
        icon={<ModifyIcon/>}
        show="single"
        disabled={disabled}
        label={<FormattedMessage id="ACTION_MODIFY" defaultMessage="Modify" />}
        onTouchTap={this.onModifyActionClick}
        primary={true} />,
      <ActionButton
        key="downloadIcon"
        icon={<DownloadIcon className="media-list-download-icon" />}
        show="multi"
        label={<FormattedMessage id="ACTION_DOWNLOAD" defaultMessage="Download" />}
        onTouchTap={() => this.onDownload()}
        primary={true} />,
      <ActionButton
        key="deleteIcon"
        icon={<DeleteIcon/>}
        show="selected"
        label={<FormattedMessage id={isAdmin ? 'ACTION_DELETE' : 'ACTION_REMOVE'} defaultMessage="Remove" />}
        onTouchTap={() => this.onRemove()}
        primary={true} />,
      <IconMenu
        key="iconMenu"
        className={`overflow-menu media-list-overflow-menu ${numSelected < 1 ? 'hidden' : ''}`}
        iconButtonElement=
          {<IconButton className="skylight-icon overflow-icon"
            tooltip={<FormattedMessage id="OVERFLOW_MORE" defaultMessage="More"/>}>
            <MoreVertIcon />
          </IconButton>}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        targetOrigin={{ horizontal: 'right', vertical: 'top' }}
        onItemTouchTap={this.onOverflowItemTouchTap}>
        {/* Display download button in overflow menu only if selected items is equal to 1 */}
        {numSelected === 1 && <MenuItem
          id="download-media-menu-item"
          className={`skylight-icon ${numSelected < 1 ? 'disabled' : ''}`}
          primaryText={<FormattedMessage id="ACTION_DOWNLOAD" defaultMessage="Download" />}
          leftIcon={<DownloadIcon/>} />}
        <MenuItem
          id="fullscreen-media-menu-item"
          className={`skylight-icon ${numSelected !== 1 ? 'disabled' : ''}`}
          disabled={numSelected !== 1}
          primaryText={<FormattedMessage id="ACTION_FULL_SCREEN" defaultMessage="Full screen" />}
          leftIcon={<FullscreenIcon/>} />
      </IconMenu>,
      ...(numSelected > 0 ? [<div key="divider" className="vertical-divider"></div>] : []),
      <ActionButton
        show="always"
        key="switchView"
        label={<FormattedMessage id={`ACTION_${current.toUpperCase()}_VIEW`} defaultMessage="Switch view" />}
        icon={<Icon/>}
        onTouchTap={() => this.props.onChangeMediaView(current)} />
    ]

    // Show single column filter only for grid view
    if (value === 'grid') {
      actions.push(
        <SortPicker
          query={this.props.list.query}
          columns={COLUMNS.filter(x => x.sortable !== false)}
          onChange={this.props.onMediaSortUpdated} />
      )
    }

    return actions
  }

  heading = (icon, value) => (props) =>
    <GridHeader {...props} includeCheckbox={true} actions={this.getHeadingActions(icon, value)} />

  handleSelectMediaItems = (ids, items) => {
    this.props.onMediaSelectItems(ids, items, isMobile())
  }

  getMediaView = () => {
    const { list } = this.props
    const listProps = {
      ...this.props,
      columns: COLUMNS,
      list: { ...list, items: list.items.filter(x => filterByFilename(x, list.query)) },
      onMediaSelectItems: this.handleSelectMediaItems,
      listFooter: list.selectedIds.length > 0 && <ListFooterStats list={list} onUnselect={this.handleSelectMediaItems} />,
      tableHeaderActions: this.getHeadingActions(GridIcon, 'list')
    }

    return this.props.list.query.view === 'grid'
      ? <MediaGrid {...listProps} heading={this.heading(ListIcon, 'grid')} />
      : <ListView {...listProps} heading={this.heading(GridIcon, 'list')} />
  }

  getClassName = () => {
    const { selectedIds, hideDetails } = this.props.list

    return [
      'media-list dashboard-list',
      selectedIds.length === 1 && !hideDetails ? 'details-open' : ''
    ].join(' ')
  }

  render() {
    const { list, item } = this.props

    return <div className={this.getClassName()}>
      {(item || {}).previewId && <MediaGallery
        {...this.props}
        onEdit={item => this.props.onMediaChangeMode('edit', item)}
        onRemove={this.onRemove}
        currentId={item.previewId}
        items={list.items} />
      }
      <Loading
        isLoading={list.isLoading && list.items.length === 0}
        message="LOADING_MEDIA_LIST"
        error={list.error
          ? <FormattedMessage id="EMPTY_MEDIA_LIST" defaultMessage="You have no shared or captured media" />
          : null
        }>
        {this.getMediaView()}
      </Loading>
      <ListActionButton
        icon={<UploadIcon/>}
        tooltip={<FormattedMessage id="UPLOAD_MEDIA" defaultMessage="Upload" />}
        onAction={this.props.onShowNewMediaDialog} />
    </div>
  }
}

const MediaList = withDownloadSnackbar(BaseMediaList)
export { MediaList }
