import * as ActionTypes from '../../actions/types'

const initialState = {
  permissions: [],
  // 1 is handled as Temporary password change Dialog.
  dialogActiveTab: 2
}

const browserPermissions = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_PERMISSIONS_STORE:
      return {
        ...state,
        permissions: [
          ...action.permissions
        ]
      }

    case ActionTypes.SET_PERMISSIONS_DIALOG_TAB:
      return {
        ...state,
        dialogActiveTab: action.activeTab
      }

    default:
      return state
  }
}

export default browserPermissions
