import * as ActionTypes from '../../actions/types'
import { createItemReducer, getListActionTypes } from 'skylight-common'

const initialState = {
  membersToRemove: [],
  members: [],
  isAddingMember: null
}

const baseItem = createItemReducer({
  actionPrefix: ActionTypes.PREFIX_GROUP,
  defaultMode: 'edit',
  initialState
})

const group = (state, payload) => {
  state = baseItem(state, payload)
  const memberListTypes = getListActionTypes(ActionTypes.PREFIX_GROUP_MEMBER.toUpperCase())

  switch (payload.type) {
    case ActionTypes.GROUP_NEW:
      return {
        ...state,
        mode: 'new',
        errors: {},
        members: payload.members,
        item: {
          name: '',
          description: ''
        },
        isAddingMember: payload.members.length > 0,
        hasChanged: payload.members.length > 0,
        original: null
      }
    case memberListTypes.LIST_LOAD:
      return { ...state, isLoading: true }
    case memberListTypes.LIST_LOADED:
      return { ...state, ...initialState, isLoading: false }
    case memberListTypes.LIST_LOAD_ERROR:
      return { ...state, isLoading: false }
    case ActionTypes.GROUP_MEMBER_ADDING:
      return { ...state, isAddingMember: true }
    case ActionTypes.GROUP_SET_NEW_MEMBERS:
      return { ...state, members: payload.members, hasChanged: true }
    case ActionTypes.GROUP_SET_REMOVE_MEMBERS:
      return {
        ...state,
        membersToRemove: [...state.membersToRemove, payload.member],
        hasChanged: true
      }
    default:
      return state
  }
}

export default group
