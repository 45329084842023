import React from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { SkylightHelpMenu } from './SkylightHelpMenu'

const SkylightHelpMenuNextGen = props => {
  const ACTIONS = [
    {
      type: 'skylight-docs',
      title: <FormattedMessage id="DOCUMENTATION" defaultMessage="Documentation" />,
      handler: () => { props.redirectToNextGenDocumentation() }
    },
    {
      type: 'whats-new',
      title: <FormattedMessage id="WHATS_NEW" defaultMessage="What's new" />,
      handler: () => { props.redirectToNextGenWhatsNew() }
    },
    {
      type: 'about-skylight-web',
      title: <FormattedMessage id="ABOUT_SKYLIGHT_WEB" defaultMessage="About Skylight Web" />,
      handler: () => { props.onShowVersionDialog() }
    }
  ]

  return (
    <SkylightHelpMenu
      {...props}
      className={classNames('skylight-help-menu-next-gen', props.className)}
      actions={ACTIONS} />
  )
}

export { SkylightHelpMenuNextGen }
