import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import skylight from 'skylight-js-sdk'
import {
  Loading, SortPicker, CursorTooltip,
  GridHeader, isMobile, ListActionButton, ListFooterStats
} from 'skylight-common'
import IconButton from 'material-ui/IconButton'
import { ApplicationGrid } from './ApplicationGrid'
import { ApplicationTable } from './ApplicationTable'

import NewApplicationIcon from '../../../../Common/Icons/nextGenApplicationNew'
import DashboardIcon from '../../../../Common/Icons/dashboard'
import { COLUMNS } from './columns'
import ListIcon from 'material-ui/svg-icons/action/view-list'
import GridIcon from 'material-ui/svg-icons/action/view-module'
import { APPLICATION, FA_APPLICATION } from '../../../../../svc/scopes'
import ApplicationTextFilter from '../ApplicationFilter/ApplicationTextFilter'
import { isFailedImport } from '../../../../../lib/application/exportImport'
import RemoveIcon from '../../../../Common/Icons/removeTrash'

import applicationActions from '../../../../../actions/nextGenApplication'
import contactActions from '../../../../../actions/contact'

import './ApplicationList.css'

class ApplicationList extends React.Component {
  componentDidMount() {
    const urlSearch = new URLSearchParams(location.search)

    if (urlSearch.has('create')) {
      this.props.onNewApplication()
    }
  }

  onChangeView = (e) => {
    const { list } = this.props
    const { view } = list.query
    const nextView = view === 'list' ? 'grid' : 'list'
    e.preventDefault()
    this.props.onChangeApplicationView(nextView)
  }

  canCreateApplications = () => this.props.scopes.includes(skylight.utils.isFrontline() ? FA_APPLICATION.CREATE : APPLICATION.CREATE)

  getHeadingActions = () => {
    const { list } = this.props
    const { view } = list.query
    const nextView = view === 'list' ? 'grid' : 'list'
    const actions = [
      {
        icon: view === 'list' ? GridIcon : ListIcon,
        label: { id: `ACTION_${nextView.toUpperCase()}_VIEW`, default: 'Switch view' },
        action: this.onChangeView
      }
    ].map((a, i) => (
      <CursorTooltip key={i} tooltip={<FormattedMessage id={a.label.id} defaultMessage={a.label.default} />}>
        <IconButton
          key={a.label.id}
          onTouchTap={a.action}>
          <a.icon className="skylight-icon" />
        </IconButton>
      </CursorTooltip>
    ))

    actions.push(<ApplicationTextFilter />)

    return actions
  }

  getItemActions = (item) => {
    if (isFailedImport(item)) {
      const { applicationId, importId } = item.import

      return [
        {
          icon: <RemoveIcon />,
          title: { id: 'ACTION_DELETE', default: 'Delete' },
          action: () => this.props.onDeleteApplicationImport(applicationId, importId),
          className: 'application-import-delete',
          tooltip: { id: 'ACTION_DELETE', default: 'Delete' }
        }
      ]
    }

    return [
      {
        icon: <DashboardIcon />,
        title: { id: 'ACTION_OPEN_APPLICATION_DASHBOARD', default: 'OPEN' },
        action: () => this.props.onApplicationDashboardOpen(item),
        className: 'application-open-dashboard',
        tooltip: { id: 'ACTION_OPEN_APPLICATION_DASHBOARD_TOOLTIP', default: 'Open Dashboard' },
        isDesktop: true
      }
    ]
  }

  renderApplicationView = () => {
    const { list } = this.props
    const { view } = list.query

    const ListView = view === 'list' ? ApplicationTable : ApplicationGrid

    const listProps = view === 'list'
      ? {
          tableHeaderActions: this.getHeadingActions()
        }
      : {}

    return (
      <ListView
        {...this.props}
        {...listProps}
        getItemActions={this.getItemActions}
        listFooter={list.selectedIds.length > 0 &&
          <ListFooterStats list={list} onUnselect={this.props.onApplicationSelectItems} />} />
    )
  }

  renderHeader = () => {
    const { list } = this.props
    const { view } = list.query

    const headerTitle = view === 'grid'
      ? <SortPicker
      query={list.query}
      columns={COLUMNS.filter(x => x.sortable !== false)}
      onChange={this.props.onApplicationSortUpdate} />
      : null

    return <GridHeader title={headerTitle} actions={this.getHeadingActions()} />
  }

  renderLoadingErrorView = () => {
    const { list } = this.props

    if (list.isLoading) {
      return null
    }

    if (list.error) {
      return <FormattedMessage
        id="EMPTY_APPLICATION_LIST"
        defaultMessage="You have no applications to view." />
    }

    return null
  }

  renderAddNewApplicationFab = () => {
    const { isDrawerOpen, drawerWidth } = this.props.drawer
    const defaultRightIndent = 36

    return (!isMobile() && this.canCreateApplications()) && <ListActionButton
      style={{ right: isDrawerOpen ? defaultRightIndent + drawerWidth : defaultRightIndent }}
      tooltip={<FormattedMessage id="NEW_APPLICATION" defaultMessage="New application" />}
      icon={<NewApplicationIcon />}
      onAction={this.props.onNewApplication} />
  }

  render() {
    const { list } = this.props

    return (
      <div className="dashboard-list next-gen-application-list">
        {this.renderHeader()}
        <Loading
          isLoading={list.isLoading && list.items.length === 0}
          message="LOADING_APPLICATION_LIST"
          error={this.renderLoadingErrorView()}>
          {this.renderApplicationView()}
        </Loading>
        {this.renderAddNewApplicationFab()}
      </div>
    )
  }
}

export { ApplicationList }

const mapStateToProps = (state) => ({
  list: state.application.list,
  permissions: state.application.permissions,
  scopes: state.auth.scopes,
  user: state.auth.user,
  drawer: state.layout.nav
})

const applicationListActions = {
  ...applicationActions,
  onUserSelectItems: contactActions.onUserSelectItems
}

const ApplicationListContainer = connect(mapStateToProps, applicationListActions)(ApplicationList)

export default ApplicationListContainer
