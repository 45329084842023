import { FieldText } from './FieldText/FieldText'
import { FieldPassword } from './FieldPassword/FieldPassword'
import { FieldNumber } from './FieldNumber/FieldNumber'
import { FieldSelect } from './FieldSelect/FieldSelect'

export const NEXT_GEN_FIELD_TYPES = {
  text: FieldText,
  password: FieldPassword,
  number: FieldNumber,
  select: FieldSelect
}
