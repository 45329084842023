import React from 'react'
import { Dialog } from 'material-ui'
import { DialogTitle } from 'skylight-common'
import { SETUP_NEW_APP_TEMPLATE_NAMES } from '../../../../../lib/nextGenApplication'
import { TemplateSelection } from './TemplateSelection/TemplateSelection'
import classNames from 'classnames'

import { BlankAppTab } from './BlankAppTab/BlankAppTab'
import { ImportFromFileTab } from './ImportFromFileTab/ImportFromFileTab'

import './index.css'

const SETUP_STEPS_COUNT = 2

export const SetupNewApplication = props => {
  const { dialog, dialogType, newApplication } = props
  const { open } = dialog[dialogType] || {}
  const { template, step } = newApplication

  const dialogTitle = (
    <DialogTitle id="SETUP_YOUR_APPLICATION" defaultMessage="Set up your application">
      <span className="tabs-counter">{step} of {SETUP_STEPS_COUNT}</span>
    </DialogTitle>
  )

  const renderDialogContent = () => {
    if (step === 1) {
      return <TemplateSelection {...props} />
    }

    if (template === SETUP_NEW_APP_TEMPLATE_NAMES.BLANK_APP) {
      return <BlankAppTab {...props} />
    }

    return <ImportFromFileTab {...props} onUpload={props.onImportApplication} />
  }

  return (
    <div className="setup-new-app-dialog">
      <Dialog
        title={template !== SETUP_NEW_APP_TEMPLATE_NAMES.IMPORT_FILE ? dialogTitle : null}
        modal={false}
        open={!!open}
        bodyClassName={classNames('dialog-body', {
          importing: template === SETUP_NEW_APP_TEMPLATE_NAMES.IMPORT_FILE
        })}
        className="dialog-modal setup-new-app"
        contentClassName="setup-new-app-content"
        autoDetectWindowHeight={false}
        contentStyle={{ maxWidth: '650px' }}
        onRequestClose={() => props.onHideDialog(dialogType)}>
        {renderDialogContent()}
      </Dialog>
    </div>
  )
}
