import * as ActionTypes from './types'
import * as dialogTypes from '../App/Home/Layout/Dialogs/dialogTypes'
import { push } from 'react-router-redux'
import { signinFrontline, signin as signinSvc, getSsoToken, getRealm, signInIdp, setSsoData } from '../svc/login'
import { switchLocaleForUser } from './locale'
import skylight from 'skylight-js-sdk'
import { formatRealm } from '../lib/login'
import { showAddDeviceDialog } from './dialog'
import appActions, { redirectToApp } from './app'
import { isDesktop } from '../lib/devices'

let redirect = '/'

const enter = (res, dispatch, staySigned) => {
  dispatch({
    type: ActionTypes.AUTH_SIGNED_IN,
    staySigned: !(staySigned === false),
    user: res.user,
    auth: res.auth,
    scopes: res.scopes
  })

  dispatch(switchLocaleForUser(res.user))
}

export const signin = (auth) => {
  return dispatch => {
    const { username, password, realm, staySigned } = auth
    dispatch({ type: ActionTypes.SIGNIN_SIGNIN })
    if (!username || !password) {
      const errors = { username: 'REQUIRED_USERNAME_AND_PASSWORD', password: 'REQUIRED_USERNAME_AND_PASSWORD' }
      dispatch({ type: ActionTypes.SIGNIN_ERROR, errors })
      return
    }

    if (auth.errorSignOut) {
      const { from = {} } = auth.location.state || {}
      redirect = from.pathname || redirect
    }

    // SUP-4105 Web Client: White space is counted in username causing a error
    const trimmedUsername = username.trim()

    signinSvc(trimmedUsername, password, formatRealm(realm), staySigned, auth.url)
      .then((res) => {
        enter(res, dispatch, staySigned)

        getSsoToken({ username: trimmedUsername, password, realm })
          .then(x => dispatch({ type: ActionTypes.SSO_SET_TOKEN, token: x }))
          .catch(x => {
            console.error('set sso error', x)
            dispatch({ type: ActionTypes.SSO_SET_ERROR, error: x })
          })
      })
      .catch(x => {
        console.log('oops sign in error ', x)
        if (x.status === 404) {
          dispatch(push('/login/missing-account'))
        } else if (x.status === 400) {
          if (!isDesktop()) {
            dispatch(appActions.redirect('/change-temp-password'))
            return
          } else {
            dispatch({ type: ActionTypes.USER_TEMP_PASSWORD_EXPIRED })
          }
        }
        dispatch({
          type: ActionTypes.SIGNIN_ERROR,
          errors: {
            realm: x.status === 404 ? 'INVALID_PWD' : 'INVALID_PWD'
          }
        })
      })
  }
}

const onStartSignIn = () => ({ type: ActionTypes.SIGNIN_SIGNIN })

const realmSignin = (realmName, isUrlRealm) => {
  return async dispatch => {
    const formattedRealm = formatRealm(realmName)

    if (!formattedRealm) {
      dispatch({
        type: ActionTypes.SIGNIN_REALM_ERROR,
        error: 'REQUIRED_REALM'
      })
      return
    }
    dispatch({ type: ActionTypes.SIGNIN_REALM_SIGNIN, realm: realmName })

    try {
      const isFrontline = skylight.utils.isFrontline()
      const realm = await getRealm(formattedRealm)
      await skylight.setRealm(formattedRealm)
      const authApps = isFrontline ? [] : await skylight.idp.getAuthMethods(formattedRealm)
      dispatch({ type: ActionTypes.SIGNIN_REALM_CONFIG, realm, authApps })
      const qSearchParams = new URLSearchParams(window.location.search)
      qSearchParams.delete('realm')
      const queryParams = qSearchParams.toString()
      if (queryParams !== '') {
        await skylight.storage.setItem('redirectSearch', queryParams)
      }
      !isUrlRealm && dispatch(push(`/login/${formattedRealm}?${queryParams}`))
      if (qSearchParams.has('sso') && qSearchParams.get('sso') === 'true') {
        dispatch(onStartSignIn())
        if (isFrontline) {
          const authUrl = await skylight.getAuthUrl({
            redirectUri: skylight.utils.resolveUrl(window.origin, `/login/${formattedRealm}`)
          })
          window.location = authUrl
        }
        if (authApps && authApps.length > 0) {
          const url = window.location
          const app = authApps[0]
          window.location.href = `${app.url}?url=${url.protocol}//${url.host}/login/sso`
        }
      }
    } catch (e) {
      skylight.logger.error('realmSignin', e)
      dispatch({
        type: ActionTypes.SIGNIN_REALM_ERROR,
        error: e.status === 404 ? 'INVALID_REALM' : 'ERROR_UNEXPECTED'
      })
    }
  }
}

export const getIdPToken = (realm, code) => {
  return async dispatch => {
    dispatch({ type: ActionTypes.SIGNIN_IDP_TOKEN_LOADING })
    try {
      const res = await signInIdp(realm, code)
      enter(res, dispatch)
      const redirectSearch = await skylight.storage.getItem('redirectSearch')
      await skylight.storage.removeItem('redirectSearch')
      dispatch(redirectToApp(redirectSearch || ''))
    } catch (error) {
      console.log('getIdPToken', error)
      dispatch({ type: ActionTypes.SIGNIN_IDP_TOKEN_ERROR, error })
    }
  }
}

export const loginWithFrontline = (authParams) => {
  return async dispatch => {
    try {
      const authData = await skylight.authenticate(authParams)
      await skylight.auth.setAuthData(authData)
      const res = signinFrontline(authData)
      const user = await skylight.user.getById(res.user.id)
      skylight.auth.user = { ...skylight.auth.user, scopes: user.scopes }
      res.user = { ...user, realm: res.user.realm }
      res.scopes = res.user.scopes

      enter(res, dispatch, true)
      await skylight.storage.removeItem('redirectSearch')
    } catch (error) {
      console.log('loginWithFrontline', error)
      dispatch({ type: ActionTypes.SIGNIN_ERROR, errors: { frontline: 'FROTNLINE_SIGN_IN_FAIL' } })
    }
  }
}

export const redirectWithExistingAuth = () => {
  return dispatch => {
    dispatch(redirectToApp(window.location.search))
  }
}

export const clearVerifyCredentialsErrors = () => ({
  type: ActionTypes.CLEAR_VERIFY_CREDENTIALS_FIELD_ERROR
})

export const verifyCredentials = ssoData => async(dispatch, getState) => {
  const auth = getState().auth
  const preventSignOut = true

  dispatch({ type: ActionTypes.VERIFY_CREDENTIALS_REQUEST })

  try {
    const token = await getSsoToken(ssoData, preventSignOut)

    if (token) {
      dispatch({ type: ActionTypes.VERIFY_CREDENTIALS_RESPONSE })

      setSsoData(ssoData)

      // close modal
      dispatch({ type: ActionTypes.DIALOG_HIDE, dialogType: dialogTypes.VERIFY_CREDENTIALS })

      // open addDeviceDialog
      dispatch(showAddDeviceDialog(auth, { timeout: 60 }))
    }
  } catch (e) {
    if (e.status === 401) {
      dispatch({ type: ActionTypes.VERIFY_CREDENTIALS_FIELD_ERROR, errors: { password: 'INVALID_ACCOUNT_PASSWORD' } })
    }
  }
}

export const signinErrorsClear = () => ({
  type: ActionTypes.SIGNIN_ERRORS_CLEAR
})

export default {
  onStartSignIn,
  loginWithFrontline,
  redirectWithExistingAuth,
  onUsernameChange: (v) => ({ type: ActionTypes.SIGNIN_USERNAME_CHANGED, value: v }),
  onPasswordChange: (v) => ({ type: ActionTypes.SIGNIN_PASSWORD_CHANGED, value: v }),
  onRealmChange: (v) => ({ type: ActionTypes.SIGNIN_REALM_CHANGED, value: v }),
  onStaySignedInChange: (v) => ({ type: ActionTypes.SIGNIN_STAY_SIGNED_CHANGED, value: v }),
  onSignIn: signin,
  onRealmSignin: realmSignin,
  onVerifyCredentials: verifyCredentials,
  onVerifyCredentialsClearErrors: clearVerifyCredentialsErrors,
  onSigninErrorsClear: () => ({ type: ActionTypes.SIGNIN_ERRORS_CLEAR })
}
