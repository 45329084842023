import { connect } from 'react-redux'
import jobActions from '../actions/job'

import { JobStatus as JobStatusComp } from '../App/Home/Job'

const job = (state, props) => state.job

const JobStatus = connect(job, jobActions)(JobStatusComp)

export { JobStatus }
