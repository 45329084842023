export const DEFAULT_QUERY = {
  limit: 200,
  skip: 0,
  sortBy: 'createdAt',
  sortAsc: false,
  filter: {},
  filters: []
}

export const PICKER_QUERY = {
  sortBy: 'createdAt',
  sortAsc: true
}

export const SESSION_STATUSES = {
  OPEN: 'open',
  CLOSED: 'closed'
}
