import React from 'react'
import { formatDate } from '../../../../../lib/date'
import { UserTitle, FormattedDateTime } from 'skylight-common'
import UserDetailsLink from '../../../Contacts/UserDetails/UserDetailsLink'

const COLUMNS = [
  {
    name: 'name',
    title: 'APPLICATION_NAME',
    groupBy: x => x.name
  },
  {
    name: 'createdBy',
    title: 'OWNER',
    sortable: false,
    render: item => <UserDetailsLink nextGen userId={item.createdBy} />,
    groupBy: x => x.createdBy,
    groupHeader: x => <UserTitle user={x} />
  },
  {
    name: 'modifiedAt',
    title: 'UPDATED_TIME',
    render: x => <FormattedDateTime value={x.modifiedAt} />,
    groupBy: x => formatDate(new Date(x.modifiedAt))
  },
  {
    name: 'modifiedBy',
    title: 'LAST_UPDATED_BY',
    render: item => <UserDetailsLink nextGen userId={item.modifiedBy} />,
    groupBy: x => x.modifiedBy,
    sortable: false,
    groupHeader: x => <UserTitle user={x} />
  },
  {
    name: 'description',
    title: 'DESCRIPTION',
    sortable: false,
    groupBy: x => x.description
  },
  {
    name: 'status',
    title: 'APPLICATION_STATUS',
    sortable: false,
    groupBy: x => x.status,
    render: x => `${x.status[0].toUpperCase()}${x.status.slice(1)}`
  }
]

export { COLUMNS }
