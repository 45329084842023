import React from 'react'
import { CallChat, ChatUnread } from './CallChat'
import { CallScreen } from './CallScreen'
import { CallSnackbar } from './CallSnackbar'
import { PdfEditorDialog } from './Common'
import { initCall } from './lib/sdk'
import './Call.css'

initCall()

export const Call = (props) => {
  const isActive = props.call.connection.state !== null
  const className = `call ${isActive ? 'active' : ''}`
  return (
    <div className={className}>
      <CallScreen {...props} />
      <PdfEditorDialog
        pdf={props.call.ui.pdf}
        onPreviewChange={props.setPreview}
        onCancel={() => props.setPdf([])}
        onSelect={props.selectPdf}
        onUpload={props.uploadFile} />
      <CallSnackbar
        snackbar={props.call.ui.snackbar}
        onAction={props.snackbarAction}
        onClose={() => props.setSnackbar(null)} />
      <CallChat {...props} />
      <ChatUnread {...props} />
    </div>
  )
}
