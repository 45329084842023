import * as ActionTypes from '../../actions/types'

const initialState = {
  form: null,
  passwordForm: {},
  preferencesForm: {},
  errors: {},
  original: null,
  profileImage: '',
  hasChanged: null,
  error: null,
  genericErrors: []
}

const settings = (state = initialState, payload, all) => {
  switch (payload.type) {
    case ActionTypes.PROFILE_FIELD_CHANGED:
      return {
        ...state,
        [payload.formName]: {
          ...state[payload.formName],
          [payload.name]: payload.value
        },
        errors: {},
        hasChanged: state.hasChanged
          ? state.hasChanged
          : ['form', 'preferencesForm'].includes(payload.formName)
      }
    case ActionTypes.PROFILE_UPDATE_CANCEL:
      return { ...state, errors: {}, form: state.original, preferencesForm: {}, hasChanged: false }
    case ActionTypes.PROFILE_UPDATED:
      return { ...state, errors: {}, hasChanged: false, original: payload.user }
    case ActionTypes.PROFILE_UPDATE_ERROR:
      return { ...state, errors: payload.errors }
    case ActionTypes.PROFILE_PASSWORD_RESET_ERROR:
      return { ...state, errors: payload.errors }
    case ActionTypes.CLEAR_RESET_PASSWORD_FORM:
    case ActionTypes.PROFILE_PASSWORD_HAD_RESET:
      return { ...state, passwordForm: {}, errors: {} }
    case ActionTypes.MEDIA_IMAGE_UPLOADED:
      return { ...state, profileImage: payload.image }
    case ActionTypes.ROUTER_LOCATION_CHANGE:
      return initialState
    case ActionTypes.AUTH_USER_REFRESHED: {
      const form = payload.user
      return { ...state, form, original: form }
    }
    case ActionTypes.AUTH_REFRESH_USER_ERROR:
      return { ...state, error: payload.type }
    case ActionTypes.PROFILE_GENERIC_ERRORS_UPDATE: {
      return { ...state, genericErrors: [...new Set(payload.errors)] }
    }
    case ActionTypes.PROFILE_FORM_ERRORS_DELETE: {
      const errors = { ...state.errors }
      delete errors[payload.field]
      return {
        ...state,
        errors
      }
    }
    default:
      return state
  }
}

export default settings
