import React from 'react'
import CircularProgress from 'material-ui/CircularProgress'
import SuccessIcon from 'material-ui/svg-icons/navigation/check'
import ErrorIcon from 'material-ui/svg-icons/navigation/close'
import { FormattedMessage } from 'react-intl'

export const Metric = props => {
  const item = props.item
  const Icon = item.error ? ErrorIcon : SuccessIcon
  return (
    <div className="metric">
      <div className={`status ${item.error ? 'error' : ''} ${item.isLoading ? 'loading' : ''}`} >
        {item.isLoading
          ? <CircularProgress size={24} />
          : <Icon />}
      </div>
      <div className={`title ${item.error ? 'error' : ''}`}>
        <FormattedMessage id={item.title} defaultMessage={item.title} />
      </div>
      <div className="value">
        {item.value}
      </div>
    </div>
  )
}
