import React from 'react'
import { FormattedMessage } from 'react-intl'
import './AssignmentTitle.css'

const AssignmentTitle = (props) => {
  const { assignmentItem } = props

  const title = (assignmentItem.mode === 'new')
    ? { id: 'ASSIGN_APPLICATION', default: 'Assign an application' }
    : { id: 'ASSIGNMENT_DETAILS', default: assignmentItem.item.name }

  return <FormattedMessage id={title.id} defaultMessage={title.default} />
}

export { AssignmentTitle }
