import React, { Component } from 'react'
import { RolesPicker } from '../RolesPicker/RolesPicker'
import { DEFAULT_ROLES } from '../../../../../../../../lib/application/roles'
import { PERMISSIONS, hasPermission } from '../../../../../../../../lib/application'
import { isApplicationAdmin } from '../../../../../../../../svc/scopes'

import './ParticipantRolesPicker.css'

class ParticipantRolesPicker extends Component {
  handleParticipantRoleAdd = roleId => {
    const { participant } = this.props
    this.props.onParticipantRoleAdd(participant, roleId)
  }

  handleParticipantRoleRemove = roleId => {
    const { participant } = this.props
    this.props.onParticipantRoleRemove(participant, roleId)
  }

  getPickerItems = selectedRoles => {
    const { role } = this.props
    const ownerRole = selectedRoles.find(r => r.name === DEFAULT_ROLES.OWNER)
    const isOnlyOwner = ownerRole && ownerRole.participants.length === 1

    if (!isOnlyOwner) {
      return role.list.items
    }

    return role.list.items.map(r => ({
      ...r,
      className: r.name === DEFAULT_ROLES.OWNER ? 'only-owner' : ''
    }))
  }

  render() {
    const { application, permissions, participant, role } = this.props
    const { applicationId } = application
    const applicationPermissions = permissions[applicationId] || {}
    const canUpdateRole = isApplicationAdmin() || hasPermission(applicationPermissions, PERMISSIONS.ROLE.UPDATE)

    const selectedRoles = role.list.items.filter(role => participant.roles.includes(role.id))
    return <RolesPicker
      className="participant-roles-picker"
      isLoading={role.list.isLoading}
      items={this.getPickerItems(selectedRoles)}
      selectedRoles={selectedRoles}
      disabled={!canUpdateRole}
      onManageRolesClick={this.props.onManageRolesOpen}
      onRoleAdd={this.handleParticipantRoleAdd}
      onRoleRemove={this.handleParticipantRoleRemove} />
  }
}

export { ParticipantRolesPicker }
