import skylight from 'skylight-js-sdk'
import _ from 'lodash'
import { sortRoles } from '../../lib/application/roles'

export const getRoles = async(applicationId) => {
  const roles = await skylight.we20.role.list(applicationId)
  const sortedRoles = sortRoles(roles)

  return {
    data: sortedRoles.reduce((acc, x) => {
      if (x.roleId) {
        const item = {
          ...x,
          id: x.roleId
        }

        acc.push(item)
      }

      return acc
    }, []),
    page: { nextCursor: null }
  }
}

export const updateRole = async(applicationId, roleId, role) => {
  const payload = _.omit(role, ['id'])
  return skylight.we20.role.update(applicationId, roleId, payload)
}
