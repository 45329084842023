export const COLUMNS = [
  {
    name: 'name',
    title: 'ROLE',
    sortable: false,
    render: x => x.name,
    groupBy: x => x.name
  },
  {
    name: 'description',
    title: 'DESCRIPTION',
    sortable: false,
    render: x => x.description,
    groupBy: x => x.description
  }
]
