import dialogActions from './dialog'
import snackbarActions from './snackbar'
import skylight from 'skylight-js-sdk'
import * as ActionTypes from './types'
import { APPLICATION_STATUSES } from '../lib/nextGenApplication'
import { showError } from './error'
import applicationActions from './nextGenApplication'
import { IMPORT_STATUSES } from '../lib/application/exportImport'

const updateApplicationImportUpload = upload => ({
  type: ActionTypes.APPLICATION_IMPORT_UPLOAD_UPDATE,
  upload
})

const resetApplicationImportUpload = () => ({ type: ActionTypes.APPLICATION_IMPORT_UPLOAD_RESET })

let abortImportUploadController = null

const DELAY_TIME = 300

const importApplication = importFile => async(dispatch, getState) => {
  if (!importFile) return

  dispatch(dialogActions.hideSetupNewApplicationDialog())

  const appImport = await skylight.we20.import.create({
    applicationName: 'Imported application',
    applicationDescription: '',
    filename: importFile.name,
    totalBytes: importFile.size
  })

  const mediaItem = {
    filename: importFile.name,
    totalBytes: importFile.size,
    contentType: importFile.type,
    title: importFile.name
  }

  dispatch(updateApplicationImportUpload({
    filename: mediaItem.filename,
    totalBytes: mediaItem.totalBytes,
    importId: appImport.importId
  }))
  dispatch(dialogActions.showImportArchiveUploadDialog())

  const onProgress = progress => {
    const patch = { uploadedBytes: progress.uploadedBytes }
    dispatch(updateApplicationImportUpload(patch))
  }

  const uploader = skylight.we20.import.media.uploader(appImport.importId, mediaItem, null, onProgress)
  abortImportUploadController = new AbortController()
  try {
    await uploader.uploadFile(importFile, abortImportUploadController)
  } catch (e) {
    if (e.name === 'AbortError') {
      dispatch(resetApplicationImportUpload())
      return
    }

    dispatch(updateApplicationImportUpload({ error: true }))
    return
  }

  dispatch(snackbarActions.showImportingApplicationSnackbar())
  setTimeout(() => {
    dispatch(resetApplicationImportUpload())
    dispatch(dialogActions.hideImportArchiveUploadDialog())
  }, DELAY_TIME)

  const newApp = {
    name: appImport.applicationName,
    id: appImport.applicationId,
    modifiedAt: new Date().toISOString(),
    createdAt: new Date().toISOString(),
    createdBy: appImport.createdBy,
    modifiedBy: appImport.modifiedBy,
    status: APPLICATION_STATUSES.IMPORT,
    importing: true
  }

  dispatch({ type: ActionTypes.APPLICATION_ACTIVE_IMPORTS_ADD, imports: [{ ...newApp, importId: appImport.importId }] })
  dispatch(applicationActions.onApplicationPrependItems([newApp]))
}

const cancelImportUpload = () => async(dispatch, getState) => {
  const { upload } = getState().application.applicationImport
  dispatch(dialogActions.hideImportArchiveUploadDialog())

  if (upload.error) {
    dispatch(resetApplicationImportUpload())
    return
  }

  abortImportUploadController.abort()
  abortImportUploadController = null
}

const applicationImportCompleted = importPayload => async(dispatch, getState) => {
  const { applicationId } = importPayload
  const { importId } = importPayload.import
  const application = await skylight.we20.application.getById(applicationId)
  const newApplication = {
    ...application,
    id: application.applicationId,
    status: APPLICATION_STATUSES.ACTIVE,
    importing: false
  }

  dispatch(applicationActions.onApplicationListItemUpdate(newApplication))
  dispatch({ type: ActionTypes.APPLICATION_ACTIVE_IMPORT_REMOVE, importId })

  const { activeImports } = getState().application.applicationImport
  dispatch(snackbarActions.showImportCompleteSnackbar())
  if (!activeImports.length) dispatch(snackbarActions.hideLoadingSnackbar())
}

const applicationImportFailed = importPayload => async(dispatch, getState) => {
  const { importId, error } = importPayload.import

  dispatch({ type: ActionTypes.APPLICATION_ACTIVE_IMPORT_REMOVE, importId })
  const { activeImports } = getState().application.applicationImport
  if (!activeImports.length) dispatch(snackbarActions.hideLoadingSnackbar())

  if (error.message.includes('newer than expected')) {
    dispatch(snackbarActions.showIncompatibleVersionError())
  } else {
    dispatch(showError('APPLICATION_IMPORT_ERROR', { className: 'snackbar-common-error' }))
  }

  const application = getState().application.list.items.find(x => x.id === importPayload.applicationId)
  dispatch(applicationActions.onApplicationListItemUpdate({
    ...application,
    status: IMPORT_STATUSES.ERRORED,
    importing: false,
    import: importPayload.import
  }))
}

export default {
  onImportApplication: importApplication,
  onApplicationImportCompleted: applicationImportCompleted,
  onApplicationImportFailed: applicationImportFailed,
  onCancelImportUpload: cancelImportUpload
}
