import React from 'react'
import { ByList } from 'skylight-common'
import { CALL_EVENT_TYPES } from '../../../../../lib/activity'

const ByCallEventType = props => {
  return (
    <ByList
      className="by-type"
      title="CALL_EVENT_TYPE"
      value={props.value}
      enableSorting={true}
      onChange={props.onChange}
      onRemove={props.onRemove}
      onGetItems={() => Promise.resolve(CALL_EVENT_TYPES.map(k => ({ text: k, value: k })))}/>
  )
}

export { ByCallEventType }
