import React from 'react'
import Popover, { PopoverAnimationVertical } from 'material-ui/Popover'
import Checkbox from 'material-ui/Checkbox'
import { List, ListItem } from 'material-ui'
import FilterIcon from 'material-ui/svg-icons/content/filter-list'
import './MembersFilter.css'

const MembersFilter = props => {
  const {
    onSetFilter,
    filters,
    isFiltersMenuOpen,
    onOpenFilterMenu,
    onCloseFilterMenu,
    anchorEl
  } = props

  return (
    <div className="members-filter">
      <FilterIcon
        onClick={onOpenFilterMenu}
        className="filter-icon"/>

      <Popover
        className="menu-popover"
        useLayerForClickAway={false}
        open={isFiltersMenuOpen}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
        onRequestClose={onCloseFilterMenu}
        animation={PopoverAnimationVertical}>
        <List>
          <ListItem
            leftCheckbox={
              <Checkbox
                checked={filters.showUnavailable}
                onCheck={() => onSetFilter('showUnavailable')}/>
            }
            primaryText="Show unavailable"/>
        </List>
      </Popover>
    </div>
  )
}

export { MembersFilter }
