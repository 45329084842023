import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const UserGroupAdd = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M13,14 C11,14 7,15 7,17 L7,19 L19,19 L19,17 C19,15 15,14 13,14 L13,14 Z M19.62,14.16 C20.45,14.88 21,15.82 21,17 L21,19 L24,19 L24,17 C24,15.46 21.63,14.5 19.62,14.16 L19.62,14.16 Z M13,12 C14.6568542,12 16,10.6568542 16,9 C16,7.34314575 14.6568542,6 13,6 C11.3431458,6 10,7.34314575 10,9 C10,10.6568542 11.3431458,12 13,12 L13,12 Z M18,12 C19.6568542,12 21,10.6568542 21,9 C21,7.34314575 19.6568542,6 18,6 C17.68,6 17.37,6.05 17.08,6.14 C17.65,6.95 18,7.94 18,9 C18,10.06 17.65,11.04 17.08,11.85 C17.37,11.95 17.68,12 18,12 L18,12 Z M8,11 L5,11 L5,8 L3,8 L3,11 L0,11 L0,13 L3,13 L3,16 L5,16 L5,13 L8,13 L8,11 Z" />
    </SvgIcon>
  )
}

export default UserGroupAdd
