import * as ActionTypes from '../actions/types'

const initialState = null

const error = (state = initialState, payload, all) => {
  switch (payload.type) {
    case ActionTypes.APP_CLEAR_ERROR:
      return initialState
    case ActionTypes.SHOW_ERROR:
    case ActionTypes.APP_ERROR:
      return payload.error
    default: return state
  }
}

export default error
