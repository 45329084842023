import React from 'react'
import skylight from 'skylight-js-sdk'
import { FormattedMessage } from 'react-intl'
import { Menu } from '../Menu'
import classNames from 'classnames'
import { UserAvatar } from '../UserAvatar'

import './CurrentUserMenu.css'

const CurrentUserMenu = props => {
  const { user } = props.auth
  const ACTIONS = skylight.utils.isFrontline()
    ? []
    : [
        {
          title: <FormattedMessage id="ACCOUNT_SETTINGS" defaultMessage="Account Settings" />,
          handler: () => { props.showAccountSettingsDialog() }
        },
        {
          title: <FormattedMessage id="CHANGEPASSWORD" defaultMessage="Change Password" />,
          handler: () => { props.onShowResetPasswordDialog() }
        }
      ]

  const getUserName = () => {
    const { firstName, lastName, username } = user

    if (!firstName && !lastName) {
      return username
    }

    return `${firstName || ''} ${lastName || ''}`.trim()
  }

  return (
    <Menu
      {...props}
      className={classNames('current-user-menu', props.className)}
      width={props.width}
      actions={ACTIONS}>
      <div className="current-user-info-container">
        <div className="current-user-avatar">
          <UserAvatar user={user} />
        </div>
        <div className="current-user-info">
          <div className="current-user-name">{getUserName()}</div>
          <div className="current-user-domain">{user.realm}</div>
        </div>
      </div>
    </Menu>
  )
}

export { CurrentUserMenu }
