import React from 'react'
import { FormattedMessage } from 'react-intl'
import { formatDateTime } from 'skylight-common'

import { CopyArea } from '../../../../Common/CopyArea'

import './CallEventDetails.css'

export const CallEventDetails = props => {
  const getCallProps = () => ({
    timestamp: {
      order: 0,
      label: { id: 'TIMESTAMP', default: 'Timestamp' },
      render: item => {
        return <div>{formatDateTime(item.timestamp)}</div>
      }
    },
    type: { order: 1, label: { id: 'TYPE', default: 'Type' } },
    source: { order: 2, label: { id: 'SOURCE', default: 'Source' } },
    payload: {
      order: 3,
      label: { id: 'PAYLOAD', default: 'Payload' },
      render: item => {
        return <CopyArea text={item.payload} tooltip="COPY" />
      }
    }
  })

  const getProps = (item) => {
    const props = []

    for (const prop in item) {
      if (!item.hasOwnProperty(prop)) continue

      const propObj = { prop: prop }
      const callProp = getCallProps()[prop]

      if (!callProp) {
        continue
      }

      props.push({ ...propObj, ...callProp })
    }

    // Show known properties in specified order - we'll show the rest in the
    // order received.
    return props.sort((a, b) => {
      return a.order - b.order
    })
  }

  const item = props.item.item

  const renderProp = (u) => {
    return <div key={u.prop} className="detail-item">
      <FormattedMessage className="detail-item-label"
                        id={u.label.id} defaultMessage={u.label.default} />
      <div className="detail-item-value">
        {u.render ? u.render(item) : item[u.prop]}
      </div>
    </div>
  }

  return (
    <div className="call-event-details">
      {getProps(item).map(renderProp)}
    </div>
  )
}
