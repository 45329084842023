import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Dialog } from 'material-ui'
import { SKYLIGHT_THEME } from '../../../../../styles/theme'
import { PopupPermissionsTab } from './PopupPermissionsTab'
import { MediaPermissionsTab } from './MediaPermissionsTab'

import { AUTH_STEPS_TOTAL } from '../../../../../lib/browser'

import './index.css'

export const EnableBrowserPermissions = ({ dialog, dialogType, ...props }) => {
  const { open } = dialog[dialogType] || {}
  const { dialogActiveTab } = props.browser.browserPermissions

  return (
    <div className="browser-permissions-dialog">
      <Dialog
        modal={false}
        open={!!open}
        className="dialog-modal browser-permissions"
        contentClassName="browser-permissions-content"
        autoDetectWindowHeight={false}
        contentStyle={{ maxWidth: '650px' }}
        onRequestClose={() => props.onHideDialog(dialogType)}>
        <div>
          <div className="permissions-dialog-header">
            <div
              className="permissions-dialog-header-inner"
              style={{ backgroundColor: SKYLIGHT_THEME.palette.primary1Color }}>
              <FormattedMessage
                id="PERMISSIONS_DIALOG_TITLE"
                default="Skylight Web - First time setup"/>
              <span>{dialogActiveTab} of {AUTH_STEPS_TOTAL}</span>
            </div>
          </div>

          <div>
            {
              dialogActiveTab === 2
                ? PopupPermissionsTab(props)
                : MediaPermissionsTab({ dialogType, ...props })
            }
          </div>
        </div>
      </Dialog>
    </div>
  )
}
