import * as ActionTypes from '../actions/types'

const initialState = {
  isLoading: true,
  isLocaleLoading: true,
  isAuthLoading: true,
  loadingMessage: 'Loading...',
  nativeUserEvent: false,
  versions: null
}

const app = (state = initialState, payload, all) => {
  // console.log('app reducer', payload.type)
  switch (payload.type) {
    case ActionTypes.USER_SIGNOUT_START:
      return {
        ...state,
        isAuthLoading: true,
        isLoading: true
      }
    case ActionTypes.USER_SIGNOUT:
      return {
        ...state,
        isAuthLoading: false,
        isLoading: false
      }
    case ActionTypes.APP_LOCALE_LOADED:
    case ActionTypes.APP_LOCALE_LOAD_ERROR:
      return {
        ...state,
        isLoading: state.isAuthLoading,
        isLocaleLoading: false
      }
    case ActionTypes.AUTH_SIGNIN_MISSING:
    case ActionTypes.AUTH_SIGNED_IN:
      return {
        ...state,
        isLoading: state.isLocaleLoading,
        isAuthLoading: false
      }
    case ActionTypes.APP_NATIVE_USER_EVENT:
      return { ...state, nativeUserEvent: true }
    case ActionTypes.APP_VERSIONS_LOADED:
      return { ...state, versions: payload.versions }
    default:
      return state
  }
}

export default app
