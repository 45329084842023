import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const assignmentCreated = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M9,5 C9,3.34314575 10.3431458,2 12,2 C13.6568542,2 15,3.34314575 15,5 L19,5 C20.1045695,5 21,5.8954305 21,7 L21,20 C21,21.1045695 20.1045695,22 19,22 L5,22 C3.8954305,22 3,21.1045695 3,20 L3,7 C3,5.8954305 3.8954305,5 5,5 L9,5 L9,5 Z M12,4 C11.4477153,4 11,4.44771525 11,5 C11,5.55228475 11.4477153,6 12,6 C12.5522847,6 13,5.55228475 13,5 C13,4.44771525 12.5522847,4 12,4 L12,4 Z M13,13 L13,10 L11,10 L11,13 L8,13 L8,15 L11,15 L11,18 L13,18 L13,15 L16,15 L16,13 L13,13 Z" id="path-1"></path>
    </SvgIcon>
  )
}

export default assignmentCreated
