import React from 'react'
import { ByDateRange, ByUser, FilterToolbar } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
// import {CALL_TYPES} from '../../../../../svc/activities'
import { DEFAULT_CALLS_QUERY } from '../../../../../lib/activity'
import { ByCallDevice } from './ByCallDevice'

import './CallFilter.css'

const FILTERS = [
  // {text: 'CALL_TYPE', value: 'status', comp: props => <ByList
  //   className="by-call-type"
  //   title="CALL_TYPE"
  //   value={props.value}
  //   enableSorting={true}
  //   onChange={props.onChange}
  //   onRemove={props.onRemove}
  //   onGetItems={() => Promise.resolve(
  //     Object.keys(CALL_TYPES).map(k => ({text: k, value: k}))) } />
  // },
  {
    text: 'CALL_DATE_TIME',
    value: 'timestamp',
    props: {
      title: 'CALL_DATE_TIME',
      renderCancelLabel: action => <div
        className="cancel-label-container"
        onTouchTap={e => {
          action && action(undefined)
        }}><FormattedMessage id="CLEAR" defaultMessage="Clear" /></div>
    },
    comp: ByDateRange
  },
  { text: 'CALL_ACCOUNT_NAME', value: 'callerId', props: { title: 'CALL_ACCOUNT_NAME' }, comp: ByUser },
  {
    text: 'CALL_DEVICE',
    value: 'callerDevice',
    props: {
      placeholder: { id: 'ALL', defaultMessage: 'All' }
    },
    comp: ByCallDevice
  }
  // {text: 'CALL_SOURCE', value: 'source', comp: props => <ByList
  //   className="by-source"
  //   title="CALL_SOURCE_NAME"
  //   value={props.value}
  //   enableSorting={true}
  //   onChange={props.onChange}
  //   onRemove={props.onRemove}
  //   onGetItems={() => Promise.resolve(
  //     CALL_SOURCES.map(k => ({text: k, value: k}))) } />
  // },
]

const CallFilter = props => <FilterToolbar
  className="activity-call-filter"
  filters={FILTERS}
  query={props.query}
  layout={props.layout}
  defaultQuery={DEFAULT_CALLS_QUERY}
  onFilterUpdated={props.onCallFilterUpdated}
  onFiltersUpdated={props.onCallFiltersUpdated}
  onFilterRemoved={props.onCallFilterRemoved} />

export { CallFilter }
