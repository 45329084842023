import React from 'react'
import { FormattedMessage } from 'react-intl'
import { RequiredLabel } from 'skylight-common'
import TextField from 'material-ui/TextField'
import AlertIcon from 'material-ui/svg-icons/alert/warning'
import { TokenInfo } from './TokenInfo'
import { TOKEN_SETTINGS_FIELDS, TOKEN_SETTINGS_DIALOG_PROPS } from '../../../../../lib/admin'

import './TokenSettings.css'

const CONVERT_VALUES = {}
TOKEN_SETTINGS_FIELDS.forEach(f => {
  if (f.convertFrom) {
    CONVERT_VALUES[f.name] = f.convertFrom
  }
})

class TokenSettings extends React.Component {
  state = { isDialogOpen: false, dialogProps: null }

  onChange = (e, v) => {
    const { name } = e.target
    const tokenSettings = {
      ...this.props.realm.item.tokenSettings,
      [name]: CONVERT_VALUES[name] ? CONVERT_VALUES[name](v) : v
    }

    this.props.onChange('tokenSettings', tokenSettings)
  }

  onToggleDialog = (dialogProps) => (e) => {
    e && e.preventDefault && e.preventDefault()
    this.setState(s => ({ isDialogOpen: !s.isDialogOpen, dialogProps }))
  }

  closeDialog = (e) => {
    e && e.preventDefault && e.preventDefault()
    this.setState({ isDialogOpen: false, dialogProps: null })
  }

  getErrorMessage = err => {
    if (!err) {
      return null
    }

    const error = typeof err === 'string'
      ? { id: err }
      : { id: err.id, value: { value: err.value } }

    return <FormattedMessage id={error.id} values={error.value} />
  }

  render() {
    const { item, errors } = this.props.realm
    if (!item) {
      return null
    }

    return (
      <div className="admin-token-settings">
      <div className="title">
        <AlertIcon />
        <FormattedMessage id="AUTH_TOKEN_TIMERS" defaultMessage="Authorization tokens and timers" />
      </div>
      <div className="description"><FormattedMessage id="AUTH_TOKEN_TIMERS_DESC" /></div>
        {TOKEN_SETTINGS_FIELDS.map(x =>
          <div key={x.name} className="token-settings-field">
            <div className="token-settings-field-title">
              <FormattedMessage id={x.title.id} defaultMessage={x.title.default || ''} />
            </div>
            <div className="token-settings-field-description">
              <FormattedMessage id={x.description.id} defaultMessage={x.description.default || ''} />
              {(x.name === 'accessTokenTimeout' || x.name === 'refreshTokenTimeout') &&
                <span className="access-token-learn-more" onTouchTap={this.onToggleDialog(TOKEN_SETTINGS_DIALOG_PROPS[x.name])}>
                  <FormattedMessage id="LEARN_MORE" defaultMessage="Learn more" />
                </span>}
            </div>
            <this.props.textFieldComp
              {...(x.otherProps ? x.otherProps : {})}
              className="token-settings-input-field"
              name={x.name}
              type={x.type || 'text'}
              floatingLabelText={<RequiredLabel
                isRequired={x.title.isRequired}><FormattedMessage id={x.label.id} defaultMessage={x.label.default || ''} /></RequiredLabel>}
              errorText={this.getErrorMessage(errors[x.name])}
              value={x.convertTo ? x.convertTo(item.tokenSettings[x.name]) : (item.tokenSettings[x.name] || '')}
              onChange={this.onChange} />
          </div>
        )}
        <TokenInfo
          open={this.state.isDialogOpen}
          infoTitleId={this.state.dialogProps?.infoTitleId}
          infoTitleMessage={this.state.dialogProps?.infoTitleMessage}
          infoDescId={this.state.dialogProps?.infoDescId}
          infoDescMessage={this.state.dialogProps?.infoDescMessage}
          onClose={this.closeDialog} />
      </div>
    )
  }
}

TokenSettings.defaultProps = {
  textFieldComp: TextField
}

export { TokenSettings }
