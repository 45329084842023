import React from 'react'
import PersonIcon from 'material-ui/svg-icons/social/person'
import CallIcon from 'material-ui/svg-icons/communication/call'
import GroupIcon from 'material-ui/svg-icons/action/supervisor-account'
import { isUserOnline } from '../../../../../lib/presence'
import './PresenceIcons.css'

export const OnlinePersonIcon = ({ user, users }) => {
  const iconClassName = isUserOnline(users, user) ? 'icon iconUserOnline' : 'icon'

  return (
    <PersonIcon className={iconClassName}/>
  )
}

export const OnlinePhoneIcon = ({ user, users, onClick }) => {
  const isOnline = isUserOnline(users, user)
  return isOnline ? <CallIcon className="user-call-icon" onTouchTap={onClick}/> : null
}

export const OnlineGroupIcon = ({ groupsWithMembers, group, onlineUsers }) => {
  const currentGroup = (groupsWithMembers.items || []).find(g => g.id === group.id)

  if (onlineUsers && currentGroup) {
    const onlineUsersIds = onlineUsers.map(user => user.userId)
    const currentGroupMembersIds = currentGroup.members.map(member => member.id)
    const matchesUsers = []

    currentGroupMembersIds.forEach(member => {
      if (onlineUsersIds.find(user => user === member)) {
        matchesUsers.push(member)
      }
    })

    return <GroupIcon className={matchesUsers.length !== 0 ? 'icon iconUserOnline' : 'icon'}/>
  }

  return <GroupIcon className="icon"/>
}
