import React from 'react'
import { FormattedMessage } from 'react-intl'
import MediaIcon from 'material-ui/svg-icons/action/perm-media'
import {
  GridView,
  MediaGridItem,
  SortPicker,
  GridHeader,
  groupBySection,
  localSort,
  isMobile
} from 'skylight-common'
import { filterMediaAttachment } from '../../../../../lib/assignment'
import { COMMON_COLUMNS, onSortUpdate, onCheckMediaItem } from '../attachment-utils'
import media from '../../../../../lib/media'

import './MediaAttachments.css'

const TOOLTIP_PROPS = [
  { title: 'NAME', field: 'name' },
  { title: 'OWNER', field: 'owner' },
  { title: 'TYPE', field: 'type' },
  { title: 'SIZE', field: 'size' },
  { title: 'CARD_ID', field: 'id' },
  { title: 'SEQUENCE_ID', field: 'sequenceId' }
]

const COLUMNS = [
  {
    name: 'type',
    title: 'MEDIA_TYPE',
    groupBy: x => x.type
  }
]

const MediaAttachments = props => {
  const list = props.attachment.list
  const query = list.query.media
  let items = list.items.filter(x => x.cardType === 'media' && x.mediaType === null)
  items = filterMediaAttachment(items, list.query.filter)
  if (!items.length) {
    return null
  }

  const columns = [...COLUMNS, ...COMMON_COLUMNS]

  const heading = p => (
    <GridHeader
      {...p}
      includeCheckbox={true}
      actions={[
        <SortPicker key="media-sort" query={query} columns={columns} onChange={(sBy, sAsc) => onSortUpdate(props)(sBy, sAsc, 'media')} />
      ]}/>
  )

  const onSelectMediaItems = (item, e) => {
    return media.onSelect(item, e, list, props.onAssignmentAttachmentSelectItems)
  }

  const onOpenPreview = (item, e) => {
    return media.onPreview(item, e, props.onAssignmentAttachmentPreviewItem)
  }

  return (
    <div className="assignment-media-attachments">
      <div className="assignment-attachments-title capture-attachments-title">
        <MediaIcon />
        <FormattedMessage id="MENU_MEDIA" defaultMessage="Media" />
      </div>
      <GridView
        className="media-grid-view"
        {...groupBySection(columns, query.sortBy)}
        items={localSort(items, query)}
        query={query}
        onSelectItems={props.onAssignmentAttachmentSelectItems}
        heading={heading}
        total={items.length}
        renderItem={<MediaGridItem tooltipProps={TOOLTIP_PROPS} />}
        selectedIds={list.selectedIds}
        cols={list.selectedIds.length === 1 ? 4 : 6}
        done={true}
        hideCheckbox={true}
        isDoubleClick={!isMobile()}
        onQuery={() => {}}
        onSelect={onSelectMediaItems}
        onCheck={onCheckMediaItem(props)}
        onOpenPreview={onOpenPreview}/>
    </div>
  )
}

export { MediaAttachments }
