import skylight from 'skylight-js-sdk'
import { uploadedMediaRequestCreator } from './media'

const mediaClient = {
  uploadFile: async(file, start, end, request, onRequestProgress, abortController) => {
    const { url, method, headers } = request
    const { api } = skylight
    const body = new FormData()
    if (start !== end) {
      const blob = file
      // Important: blob's slice *loses* last byte. See:
      // https://developer.mozilla.org/en-US/docs/Web/API/Blob/slice
      const slice = blob.slice(start, end + 1, blob.type)
      body.append('file', slice)
    }

    const upload = method.toLowerCase() === 'post' ? api.postRaw : api.putRaw
    const res = await upload(url, body, headers, {
      abortController,
      http: uploadedMediaRequestCreator(method.toLowerCase(), onRequestProgress)
    })

    return { etag: res.etag }
  }
}

export default mediaClient
