import React from 'react'
import { FormattedMessage } from 'react-intl'
import Checkbox from 'material-ui/Checkbox'
import { OutlinedTextField } from '../../../../Common/Form/OutlinedTextField'
const checkboxes = [
  {
    type: 'data',
    label: {
      id: 'APPLICATION_DATA',
      defaultMessage: 'Application data'
    }
  },
  {
    type: 'media',
    label: {
      id: 'APPLICATION_MEDIA',
      defaultMessage: 'Application media'
    }
  },
  {
    type: 'scripts',
    label: {
      id: 'SCRIPTS',
      defaultMessage: 'Scripts'
    }
  },
  {
    type: 'views',
    label: {
      id: 'VIEWS_AND_CARDS',
      defaultMessage: 'Views and cards'
    }
  }
]

export const TypePicker = ({
  toggleUpdate,
  updates,
  comment,
  onChangeComment
}) => {
  return (
    <div>
      <FormattedMessage
        id="UPGRADE_APPLICATION_DESCRIPTION"
        defaultMessage="Choose which parts of the application you would like to upgrade. All customizations you have made in these areas will be overwritten. Session data and session media will always be preserved." />
      <div className="app-upgrade-checkboxes">
        {checkboxes.map(({ type, label }) => (
          <div className="app-upgrade-checkbox" key={type}>
            <Checkbox
              labelStyle={{
                fontSize: 14,
                color: 'rgba(0, 0, 0, 0.75)'
              }}
              inputStyle={{
                borderColor: 'rgba(65, 64, 66, 0.33)'
              }}
              key={type}
              label={<FormattedMessage {...label} />}
              onCheck={toggleUpdate(type)}
              checked={updates.includes(type)} />
          </div>
        ))}
      </div>
      <div className="app-upgrade-comment">
        <FormattedMessage id="INCLUDE_COMMENTS_UPGRADE_DIALOG" defaultMessage="Include comments about this upgrade:" />
      </div>
      <OutlinedTextField
        id="comment"
        type="text"
        floatingLabelText={<FormattedMessage id="COMMENTS_OPTIONAL" defaultMessage="Comments (optional)" />}
        value={comment}
        onChange={onChangeComment} />
    </div>
  )
}
