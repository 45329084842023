import React from 'react'
import classNames from 'classnames'
import './LoginFormButton.css'
import { RaisedButton } from 'material-ui'

const LoginFormButton = ({ className = '', ...props }) => (
  <RaisedButton
    primary
    fullWidth
    {...props}
    className={classNames('login-form-button', className)} />
)

export default LoginFormButton
