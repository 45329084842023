import React from 'react'
import { Dialog } from 'material-ui'
import { DialogTitle } from 'skylight-common'
import DownloadIcon from 'material-ui/svg-icons/action/get-app'
import { FormattedMessage } from 'react-intl'
import FlatButton from 'material-ui/FlatButton'

import './MediaDownloadConfirmation.css'

export class MediaDownloadConfirmation extends React.Component {
  handleClose = () => {
    this.props.onHideDialog(this.props.dialogType)
  }

  handleDownload = () => {
    const { dialog, dialogType } = this.props
    const { mediaItem } = dialog[dialogType].params
    this.props.onDownloadMediaItem(mediaItem)
    this.handleClose()
  }

  getDialogActions = () => {
    return [
      <FlatButton
        key="cancel-action"
        className="cancel-action"
        label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
        onTouchTap={this.handleClose}/>,
      <FlatButton
        key="submit-action"
        className="submit-action"
        primary
        label={<FormattedMessage id="DOWNLOAD" defaultMessage="Download" />}
        onTouchTap={this.handleDownload}/>
    ]
  }

  renderTitle = () => {
    return (
      <DialogTitle id="DOWNLOAD_WITHOUT_ANNOTATIONS_TITLE" defaultMessage="Download will not include annotations">
        <DownloadIcon />
      </DialogTitle>
    )
  }

  render() {
    const { dialog, dialogType } = this.props
    const { open } = dialog[dialogType] || {}

    return (
      <Dialog
        actions={this.getDialogActions()}
        bodyClassName="media-download-confirmation-body"
        open={!!open}
        modal={false}
        autoDetectWindowHeight={false}
        title={this.renderTitle()}
        className="dialog-modal media-download-confirmation"
        contentStyle={{ maxWidth: '440px' }}
        onRequestClose={() => this.props.onHideDialog(dialogType)}>
        <FormattedMessage id="DOWNLOAD_WITHOUT_ANNOTATIONS_TEXT" defaultMessage="Downloaded file will not include any annotations." />
      </Dialog>
    )
  }
}
