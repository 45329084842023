import React from 'react'
import { ByList } from 'skylight-common'
import { SESSION_STATUSES } from '../../../../../../../lib/application/session'

const getItems = async() => Object.keys(SESSION_STATUSES).map(key => {
  return {
    text: SESSION_STATUSES[key],
    value: SESSION_STATUSES[key]
  }
})

const ByStatus = props => {
  return <ByList
    className="by-status"
    title="STATUS"
    placeholder={{ id: 'ALL', defaultMessage: 'All' }}
    value={props.value}
    onChange={props.onChange}
    onRemove={props.onRemove}
    onGetItems={getItems} />
}

export { ByStatus }
