import React from 'react'
import Dialog from 'material-ui/Dialog'
import { DialogTitle } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import { UserAttributesBody } from './UserAttributesBody'
import { FooterActionButtons, TwoColumnHeader } from '../../../../Common'

import './UserAttributes.css'

class UserAttributes extends React.Component {
  constructor(props) {
    super()

    const { customMappings } = props.ldap.original
    this.state = {
      customMappings: customMappings || [],
      hasChanged: false
    }
  }

  setProperty(customMappings, id, prop, value) {
    const clonedMappings = customMappings.map((value) => ({ ...value }))
    if (clonedMappings[id]) {
      clonedMappings[id][prop] = value
    } else {
      clonedMappings[id] = {
        [prop]: value
      }
    }

    return clonedMappings
  }

  onMappingChange = (id, prop, value) => {
    const { customMappings } = this.state

    this.setState({
      customMappings: this.setProperty(customMappings, id, prop, value),
      hasChanged: true
    })
  }

  onRemoveMapping = (idx) => {
    const { customMappings } = this.state
    const clonedMappings = customMappings.map((value) => ({ ...value }))
    clonedMappings.splice(idx, 1)

    this.setState({
      customMappings: clonedMappings,
      hasChanged: true
    })
  }

  isAllMappingsFilled() {
    const { customMappings } = this.state

    return customMappings.every(({ skylightAttribute = null, ldapAttribute = '' }) => {
      return skylightAttribute !== null && ldapAttribute !== ''
    })
  }

  onHide = () => {
    this.props.onHideDialog(this.props.dialogType)
  }

  onAttributeSave = () => {
    this.props.onLdapCustomMappingsUpdate(this.state.customMappings)
    this.onHide()
  }

  render() {
    const { dialog = {}, dialogType = '' } = this.props
    const { customMappings = [], hasChanged } = this.state
    const { open } = dialog[dialogType]
    const isAllMappingsFilled = this.isAllMappingsFilled()

    return (
      <div className="user-attributes">
        <Dialog
          title={
            <DialogTitle id="LDAP_USER_ATTRIBUTES" defaultMessage="User Attributes">
              <FormattedMessage id="LDAP_TOOLTIP_USER_ATTRIBUTES" defaultMessage="LDAP_TOOLTIP_USER_ATTRIBUTES">
                {(content) => (
                  <span className="dialog-subtitle">{content}</span>
                )}
              </FormattedMessage>
            </DialogTitle>
          }
          actions={[]}
          modal={false}
          open={!!open}
          className="dialog-modal user-attributes-dialog"
          contentClassName="user-attributes-dialog-content"
          bodyClassName="user-attributes-dialog-body"
          onRequestClose={this.onHide}>
          <TwoColumnHeader firstTitleId="LDAP_SKYLIGHT_ATTRIBUTE" secondTitleId="LDAP_LDAP_ATTRIBUTE" />
          <UserAttributesBody
            customMappings={customMappings}
            onMappingChange={this.onMappingChange}
            enableNewRow={isAllMappingsFilled}
            onRemoveMapping={this.onRemoveMapping} />
          <FooterActionButtons onCancel={this.onHide} onSave={this.onAttributeSave} isSubmitDisabled={!hasChanged || !isAllMappingsFilled} />
        </Dialog>
      </div>
    )
  }
}

export { UserAttributes }
