import React from 'react'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'

import { DialogTitle } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import { NET_SCAN } from '../dialogTypes'
import { Metric } from './Metric'
import { MqttStatus } from './MqttStatus'

import './NetScan.css'

const NetScan = ({ onHideDialog, dialog, dialogType, ...props }) => {
  const { open } = dialog[dialogType] || {}
  const { mqtt, netscan, network } = props
  const items = netscan.items

  const dialogActions = [
    <FlatButton
      key="cancel-action"
      className="cancel-action"
      label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
      onTouchTap={() => onHideDialog(NET_SCAN)}/>,
    <FlatButton
      key="load-action"
      label={<FormattedMessage id="RUN_AGAIN" defaultMessage="Run again" />}
      primary={true}
      disabled={netscan.isLoading}
      onTouchTap={ (_) => props.onLoad() }/>
  ]

  return (
    <div className="netscan-dialog-wrapper">
      <Dialog
        title={<DialogTitle
          id="NET_SCAN_DIALOG_TITLE"
          defaultMessage="Skylight Network Test" />}
        actions={dialogActions}
        modal={false}
        open={!!open}
        className="dialog-modal netscan-dialog"
        autoDetectWindowHeight={false}
        onRequestClose={() => onHideDialog(NET_SCAN)}>
        <div className="netscan">
          <div className="description">
            <FormattedMessage id="NET_SCAN_GENERAL_DESCRIPTION" defaultMessage="The tests below represent connectivity to the Upskill servers." />
          </div>
          <div className="items">
            {items.map((x, i) => <Metric key={i} item={x} />)}
          </div>
          <MqttStatus mqtt={mqtt} network={network}></MqttStatus>
        </div>
      </Dialog>
    </div>
  )
}

export { NetScan }
