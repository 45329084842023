import React from 'react'
import { FormattedMessage } from 'react-intl'
import { BaseSection } from '../BaseSection'
import { DEFAULT_LDAP_CONNECTION_OPTION } from '../../../../../../../lib/admin'

import './SecuritySection.css'

const DEPEND_FIELD = 'connectionSecurity'

const SecuritySection = ({ item, fields, fieldTypes, ...props }) => {
  const disableFields = item[DEPEND_FIELD] === DEFAULT_LDAP_CONNECTION_OPTION

  fields = fields.map(fieldProps => {
    const { field } = fieldProps

    return field === DEPEND_FIELD
      ? fieldProps
      : {
          ...fieldProps,
          disabled: disableFields,
          tooltip: disableFields ? <FormattedMessage id="LDAP_TOOLTIP_METHOD" default="LDAP_TOOLTIP_METHOD" /> : ''
        }
  })

  return (
    <BaseSection
      item={item}
      fields={fields}
      fieldTypes={fieldTypes}
      sectionClassName="security-section"
      {...props} />
  )
}

export { SecuritySection }
