import React from 'react'
import { TrimmingTextField, Form } from 'skylight-common'
// import SelectField from 'material-ui/SelectField'
// import MenuItem from 'material-ui/MenuItem'
// import Checkbox from 'material-ui/Checkbox';
import { FormattedMessage } from 'react-intl'
// import SecurityTrimmer from '../../../../containers/SecurityTrimmer'
// import {GROUP} from '../../../../svc/scopes'
import { GroupLoading } from './GroupLoading'

import './GroupDetails.css'

const GroupDetails = (props) => {
  const { group } = props
  const { item, errors } = group
  const isEditable = props.isEditable

  const handleSubmit = () => {
    group.hasChanged && props.onGroupSave(group.item)
  }

  return (
    <div className="group-details">
      <Form onSubmit={handleSubmit}>
        <GroupLoading isLoading={group.isLoading || group.isSaving} />

        <TrimmingTextField
          disabled={!isEditable}
          value={item.name || ''}
          floatingLabelText={<FormattedMessage id="GROUP_NAME" defaultMessage="Group name" />}
          errorText={errors.name
            ? <FormattedMessage
              id={`${errors.name}_GROUP_NAME`}
              defaultMessage="Field is invalid" />
            : null
          }
          underlineDisabledStyle={{ display: 'none' }}
          onChange={(e, v) => props.onGroupChange('name', v)}/>
        <TrimmingTextField
          disabled={!isEditable}
          value={item.description || ''}
          floatingLabelText={<FormattedMessage id="GROUP_DESCRIPTION" defaultMessage="Group description (optional)" />}
          errorText={errors.description
            ? <FormattedMessage
              id={`${errors.description}_DESCRIPTION`}
              defaultMessage="Field is invalid" />
            : null
          }
          underlineDisabledStyle={{ display: 'none' }}
          onChange={(e, v) => props.onGroupChange('description', v)}/>
        {/* Temporary commented in scope of https://favro.com/card/60fe74a05e7da9c931484a9a/Ups-6029 */}
        {/* <SelectField
          className="type-select"
          floatingLabelText={<FormattedMessage id="GROUP_TYPE" defaultMessage="Group type" />}
          disabled={!isEditable}
          underlineDisabledStyle={{display: 'none'}}
          value={1}>
          <MenuItem value={1} primaryText="Public" />
        </SelectField> */}
        <div className="details-item">
          <span className="title">
            <FormattedMessage id="NUMBER_MEMBERS" defaultMessage="# Members" />
          </span>
          <span className="value">
            {item.totalMembers}
          </span>
        </div>

        {/* <SecurityTrimmer scopes={[GROUP.EDIT]}>
          <div className="set-as-call-group-wrapper">
            <Checkbox
              disabled={group.mode !== 'edit'}
              value={item.isCallGroup}
              onCheck={(e,v) => props.onGroupChange('isCallGroup', v)}
              label={<FormattedMessage id="IS_CALL_GROUP" defaultMessage="Call group" />}
            />
          </div>
        </SecurityTrimmer> */}
      </Form>
    </div>
  )
}
export { GroupDetails }
