import React, { Component } from 'react'
import skylight from 'skylight-js-sdk'
import { GroupDetails } from './GroupDetails'
import { GroupMembers } from './GroupMembers'
import { GROUP } from '../../../../svc/scopes'
import { TabBar } from 'skylight-common'

import './GroupDetailTabs.css'

const EDITABLE_MAP = {
  new: () => true, // Checked on a list level
  edit: (s) => skylight.auth.hasScopes([GROUP.EDIT])
}

class GroupDetailTabs extends Component {
  state = { tab: 'details' }

  onChangeTab = (tab) => {
    this.setState({ tab })
  }

  isAddingGroup = () => {
    const { mode, members } = this.props.group
    return mode === 'new' && members.length > 0
  }

  componentDidMount() {
    const tab = this.isAddingGroup() ? 'members' : this.state.tab
    this.setState({ tab })
  }

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    const errors = nextProps.group.errors
    const hasErrors = Object.keys(errors).length > 0
    if (hasErrors && this.state.tab !== 'details') {
      this.setState({ tab: 'details' })
    }
  }

  render() {
    const { props } = this
    const { mode, isLoading } = props.group
    const action = EDITABLE_MAP[mode]
    const isEditable = !isLoading && !!action && action(props.scopes)

    const TABS = [
      {
        label: { id: 'DETAILS', defaultMessage: 'Details' },
        value: 'details',
        renderChildren: () => <GroupDetails {...props} isEditable={isEditable} />
      },
      {
        label: { id: 'MEMBERS', defaultMessage: 'Members' },
        value: 'members',
        renderChildren: () => <GroupMembers {...props} isEditable={isEditable} />
      }
    ]

    return <TabBar tabs={TABS} className="group-detail-tabs" onChange={this.onChangeTab} type={this.state.tab} />
  }
}

export { GroupDetailTabs }
