import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const assignmentFlow = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M19,3.98069256 L14.82,3.98069256 C14.25,2.41901925 12.53,1.61816114 11,2.17876182 C10.14,2.47908361 9.5,3.13979155 9.18,3.98069256 L5,3.98069256 C3.8954305,3.98069256 3,4.87708353 3,5.98283783 L3,19.9978547 C3,21.103609 3.8954305,22 5,22 L19,22 C20.1045695,22 21,21.103609 21,19.9978547 L21,5.98283783 C21,4.87708353 20.1045695,3.98069256 19,3.98069256 L19,3.98069256 Z M12,3.98069256 C12.5522847,3.98069256 13,4.42888804 13,4.98176519 C13,5.53464234 12.5522847,5.98283783 12,5.98283783 C11.4477153,5.98283783 11,5.53464234 11,4.98176519 C11,4.42888804 11.4477153,3.98069256 12,3.98069256 L12,3.98069256 Z M10,16.9828378 L8,16.9828378 L8,9.98283783 L5,9.98283783 L9,5.98283783 L13,9.98283783 L10,9.98283783 L10,16.9828378 L10,16.9828378 Z M15,19.9860557 L11,15.9860557 L14,15.9860557 L14,8.98605574 L16,8.98605574 L16,15.9860557 L19,15.9860557 L15,19.9860557 Z" id="path-1"></path>
    </SvgIcon>
  )
}

export default assignmentFlow
