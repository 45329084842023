import React from 'react'
import SessionList from './SessionList/SessionList'

const Sessions = props => {
  return (
    <div className="sessions">
      <SessionList />
    </div>
  )
}

export { Sessions }
