import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import LoginPageButton from '../../Common/LargeRaisedButton/LargeRaisedButton'
import HomeIcon from 'material-ui/svg-icons/action/home'
import { connect } from 'react-redux'
import appActions from '../../../actions/app'

import './RecoverPasswordSubmit.css'

const RecoverPasswordSubmit = ({ intl, redirect, login }) => {
  const returnToSkylightLogin = () => {
    const { realm } = login.realm
    redirect(`/login/${realm}`)
  }

  const tryRecoverAgain = () => {
    redirect('/recover-password')
  }

  return <div className="recover-password-submit-container">
    <div className="submit-success-text-wrapper">
      <span className="if-account-exists">{intl.formatMessage({ id: 'IF_ACCOUNT_EXISTS', defaultMessage: 'If an account exits, an email will be sent.' })}</span>
      <span className="click-the-link-wrapper">
        <span className="click-the-link">{intl.formatMessage({ id: 'CLICK_THE_LINK', defaultMessage: 'Click the link' })} </span>
        {intl.formatMessage({ id: 'IN_THE_EMAIL', defaultMessage: 'in the email when it arrives.' })}
      </span>
    </div>
    <LoginPageButton
      onTouchTap={returnToSkylightLogin}
      primary
      icon={<HomeIcon />}
      label={<FormattedMessage id="RETURN_TO_SKYLIGHT_LOGIN" defaultMessage="Return to Skylight Login" />} />
    <LoginPageButton
      onTouchTap={tryRecoverAgain}
      label={<FormattedMessage id="DONT_SEE_AN_EMAIL" defaultMessage="Don't see an email? Try again." />} />
    <span className="need-additional-help">{intl.formatMessage({ id: 'NEED_ADDITIONAL_HELP', defaultMessage: 'Need additional help?' })}</span>
    <span className="contact-your-admin">{intl.formatMessage({ id: 'CONTACT_YOUR_ADMINISTRATOR', defaultMessage: 'Contact your administrator.' })}</span>
  </div>
}

const mapStateToProps = state => ({
  login: state.login
})

const mapActionsToProps = {
  ...appActions
}

export default connect(mapStateToProps, mapActionsToProps)(injectIntl(RecoverPasswordSubmit))
