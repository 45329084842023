// Make sure those do not
export const HIDE_CAPTURED_KEY = '$#$HIDE_CAPTURED$#$'
export const HIDE_UNANSWERED_KEY = '$#$HIDE_UNANSWERED$#$'

export const DEFAULT_QUERY = {
  sortBy: 'updated',
  sortAsc: false,
  filter: { status: ['assigned', 'error', 'inProgress'] },
  filters: ['status'],
  limit: 200,
  skip: 0
}

const SORT_DEFAULT = { sortBy: 'updatedAt', sortAsc: false }
export const ATTACHMENT_DEFAULT_QUERY = {
  media: SORT_DEFAULT,
  decision: SORT_DEFAULT,
  textInput: SORT_DEFAULT,
  filter: { textInput: [/* 'Display empty inputs' */] }, // Do not show empty by default.
  filters: []
}

export const DATA_TYPES = [
  'captureVideo',
  'captureAudio',
  'capturePhoto',
  'decision',
  // 'completion',
  // 'scanning',
  'textInput'
]

export const ASSIGNMENT_FILTER_TYPES = {
  MY: 'my',
  TEAM: 'team',
  PENDING: 'pending'
}

const filterByText = (v, t) => (v || '').toLowerCase().includes(t.toLowerCase())
const filterByRequired = (x, r) => !r || !r.length || x.required
const filterByTags = (x, tags) => {
  if (!tags || !tags.length) return true
  const cardTags = x.cardType === 'media' ? x.card.tags : x.tags
  return cardTags.some(t => tags.includes(t))
}

const commonFilter = (x, f) =>
  filterByTags(x, f.tags) && filterByRequired(x, f.required)

const filterMediaByText = (x, t) => !t ||
  ['name', 'cardId', 'sequenceId'].some(p => filterByText(x[p] || '', t))
const filterByType = (x, m) => !m || !m.length || m.some(t => (x.type || '').includes(t))

export const filterMediaAttachment = (items, filter) => {
  if (!filter) {
    return items
  }

  return items
    .filter(x => filterMediaByText(x, filter.text))
    .filter(x => filterByType(x, filter.media))
    .filter(x => commonFilter(x, filter))
}

const filterDecisionByText = (x, t) => !t || ['label', 'cardId', 'sequenceId']
  .some(p => filterByText(x[p] || '', t) || (x.choices || []).some(c => filterByText(c.label, t)))

export const filterDecisionAttachment = (items, filter) => {
  if (!filter) {
    return items
  }

  items = items.filter(x => filterDecisionByText(x, filter.text))
  items = items.filter(x => commonFilter(x, filter))
  const decisionFilter = filter.decision || []
  if (decisionFilter.length === 0) return items

  if (decisionFilter.includes(HIDE_UNANSWERED_KEY)) {
    items = items.filter(x => (x.choices || []).some(c => c.selected))
  }
  if (decisionFilter.includes(HIDE_CAPTURED_KEY)) {
    items = items.filter(x => x.media && x.media.length > 0)
  }

  const responses = decisionFilter
    .filter(x => x !== HIDE_CAPTURED_KEY && x !== HIDE_UNANSWERED_KEY)
  if (responses.length > 0) {
    items = items.filter(x => {
      return (x.choices || []).some(c => c.selected && responses.includes(c.label))
    })
  }

  return items
}

const filterTextInputByText = (x, t) => !t || ['label', 'input', 'cardId', 'sequenceId']
  .some(p => filterByText(x[p] || '', t))
const filterByInput = (x, d) => !d || !d.includes('Hide empty inputs') || x.input

export const filterTextInputAttachment = (items, filter) => {
  if (!filter) {
    return items
  }

  return items
    .filter(x => filterTextInputByText(x, filter.text))
    .filter(x => filterByInput(x, filter.textInput))
    .filter(x => commonFilter(x, filter))
}
