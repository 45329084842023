import React from 'react'
import { PlaybackToolbar } from './PlaybackToolbar'
import NotSupportedIcon from 'material-ui/svg-icons/av/not-interested'
import { AudioVisualizer } from './AudioVisualizer'
import './AVPreview.css'

const MEDIA_ERRORS = {
  1: 'MEDIA_ERROR_ABORTED',
  2: 'MEDIA_ERROR_NETWORK',
  3: 'MEDIA_ERROR_DECODE',
  4: 'MEDIA_ERROR_SRC_NOT_SUPPORTED'
}

class AVPreview extends React.Component {
  state = {
    isPlaying: false,
    isLoading: true,
    progress: 0,
    duration: 0,
    error: null
  }

  onPlayVideo = () => {
    const { isPreview, onLoadContent } = this.props
    if (isPreview && onLoadContent) {
      onLoadContent()
      return
    }

    this.mediaVideo.play()
    this.setState({
      isPlaying: true
    })
  }

  onPauseVideo = () => {
    this.mediaVideo.pause()
    this.setState({
      isPlaying: false
    })
  }

  onTimeUpdated = () => {
    if (!this.mediaVideo) {
      return
    }

    const progress = this.mediaVideo.currentTime
    if (progress && this.state.progress !== progress) {
      this.setState({ progress })
    }

    if (this.state.progress === this.state.duration) {
      this.setState({
        progress: 0,
        isPlaying: false
      })
    }
  }

  onTimeSet = e => {
    const { duration } = this.mediaVideo
    const { width } = e.target.getBoundingClientRect()
    this.mediaVideo.currentTime = +((duration * e.nativeEvent.offsetX) / width).toFixed(4)
  }

  onHandleVideoLoading = () => {
    if (this.mediaVideo) {
      const { isFullScreen, onLoadContent } = this.props
      // Autoplay `preview` video once it is loaded.
      if (onLoadContent && !isFullScreen) this.onPlayVideo()

      this.setState({
        duration: this.mediaVideo && this.mediaVideo.duration,
        isLoading: false
      })
    }
  }

  onScrollVideoBack = () => {
    this.mediaVideo.currentTime = this.mediaVideo.currentTime - this.props.videoScrollValue
  }

  onScrollVideoForward = () => {
    this.mediaVideo.currentTime = this.mediaVideo.currentTime + this.props.videoScrollValue
  }

  onFullScreenClick = () => {
    this.props.onPreviewMediaItem(this.props.item.id)
    this.onPauseVideo()
  }

  onError = e => {
    const { error } = e.target
    // Likely not a video issue, rather a thumbnail issue etc.
    // Hence prevent showing an error and just continue.
    if (!error) {
      return
    }

    const errorId = MEDIA_ERRORS[error.code] || 'MEDIA_ERROR_DEFAULT'

    this.props.onShowError(errorId)
    this.setState({ error: errorId })
  }

  componentDidMount() {
    this.mediaVideo.addEventListener('timeupdate', this.onTimeUpdated)
    this.mediaVideo.addEventListener('canplay', this.onHandleVideoLoading)
  }

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    if (this.props.src !== nextProps.src) {
      this.setState({ error: null })
    }
  }

  componenWillUnmount() {
    this.mediaVideo.removeEventListener('timeupdate', this.onTimeUpdated)
    this.mediaVideo.removeEventListener('canplay', this.onHandleVideoLoading)
  }

  getCurrentTime = () => {
    if (!this.mediaVideo) {
      return 0
    }

    return this.mediaVideo.currentTime
  }

  renderPreview = () => {
    const { objectUrl, isAudio, isPreview } = this.props

    const VideoElm = <video
      src={isPreview ? null : objectUrl}
      poster={isPreview ? objectUrl : null}
      ref={video => { this.mediaVideo = video }}
      onError={this.onError} />

    return isAudio
      ? <div className="audio-wrapper">
        {!this.state.error && <AudioVisualizer
          width={330}
          height={220}
          isPlaying={this.state.isPlaying}
          progress={this.state.progress}
          currentTime={this.getCurrentTime()}
          src={objectUrl} />}
       {VideoElm}
      </div>
      : VideoElm
  }

  render() {
    const { isPlaying, progress, duration } = this.state
    return (
      <div className="av-preview">
        <div className="av-preview-container">
          {this.state.error
            ? <div className="av-preview-error-icon"><NotSupportedIcon /></div>
            : this.renderPreview()}
          <PlaybackToolbar
            isPlaying={isPlaying}
            duration={duration}
            progress={progress}
            disabled={!!this.state.error}
            onTimeSet={this.onTimeSet}
            onScrollVideoBack={this.onScrollVideoBack}
            playVideo={this.onPlayVideo}
            pauseVideo={this.onPauseVideo}
            onScrollVideoForward={this.onScrollVideoForward}
            onFullScreenClick={this.onFullScreenClick} />
        </div>
      </div>
    )
  }
}

AVPreview.defaultProps = {
  videoScrollValue: 5
}

export { AVPreview }
