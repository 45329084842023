import * as ActionTypes from '../../actions/types'

const initialState = {}

const setLoadingStatus = (state, ids) => {
  const statuses = {}
  ids.forEach(id => { statuses[id] = 'loading' })

  return { ...state, ...statuses }
}

const status = (state = initialState, payload) => {
  switch (payload.type) {
    case ActionTypes.WORKFLOW_APPLICATION_ASSIGN:
      return setLoadingStatus(state, payload.ids)
    case ActionTypes.WORKFLOW_APPLICATION_STATUS_CLEAR:
    case ActionTypes.WORKFLOW_APPLICATION_ASSIGNED:
      return { ...state, [payload.id]: null }
    case ActionTypes.WORKFLOW_APPLICATION_ASSIGN_ERROR:
      return { ...state, [payload.id]: 'error' }
    default:
      return state
  }
}

export default status
