import React from 'react'
import {
  Table, Column, FormattedDateTime, ListFooterStats,
  ExportIcon, MaterialIcon, ActionButton
} from 'skylight-common'
import CircularProgress from 'material-ui/CircularProgress'
import { CallStatus } from '../CallStatus'
import { UserDetailsLink } from '../../../../../containers/common'
import { FormattedMessage } from 'react-intl'
import { exportCallEvents } from '../../../../../svc/activities/callEvents'
import { secondsToTimeString } from '../../../../../lib/date'

import './CallList.css'

class CallList extends React.Component {
  onExport = () => {
    const list = this.props.list
    const items = list.items.filter(item => list.selectedIds.includes(item.id))
    exportCallEvents(items, list.query.filter)
  }

  getCheckboxIcon = (item) => {
    const iconName = item.status === 'failed' ? 'phone_missed' : 'call'
    if (iconName) {
      return <MaterialIcon
        className="skylight-icon"
        icon={iconName} />
    }

    return <CircularProgress size={20} thickness={2} className="checkbox-icon" />
  }

  getFooterAction = () => {
    return <ActionButton
        icon={<ExportIcon />}
        show="always"
        className="table-heading-button skylight-icon export-icon"
        label={<FormattedMessage id="EXPORT_CSV" defaultMessage="Export to CSV" />}
        onTouchTap={this.onExport} />
  }

  render() {
    const list = this.props.list

    return <div className="activity-call-list">
      <Table
        showCheckboxes={true}
        checkboxIcon={this.getCheckboxIcon}
        showHover={true}
        query={list.query}
        items={list.items}
        total={list.total}
        isLoading={list.isLoading}
        done={list.done || !!list.error}
        selectedRows={list.selectedIds}
        name="activityCallList"
        tableFooter={list.selectedIds.length > 0 && <ListFooterStats list={list} onUnselect={this.props.onCallSelectItems} actions={this.getFooterAction()} />}
        emptyLabel={<FormattedMessage
          id="EMPTY_CALLS_LIST"
          defaultMessage="There are no call logs." />
        }
        onQuery={this.props.onCallQuery}
        onRowsSelected={ids => this.props.onCallSelectItems(ids, list.items)}>
        <Column
          name="status"
          sortable={false}
          title={<FormattedMessage id="ACTIVITY_CALL_STATUS" defaultMessage="Status"/>}
          render={item => <CallStatus status={item.status} />} />
        <Column
          name="caller"
          sortable={false}
          title={<FormattedMessage id="ACTIVITY_CALL_CALLER" defaultMessage="Caller"/>}
          render={item => <UserDetailsLink userId={item.callerId} />} />
        <Column
          sortable={false}
          name="callerDevice"
          title={<FormattedMessage id="ACTIVITY_CALL_CALLER_DEVICE" defaultMessage="Caller device"/>}/>
        <Column
          sortable={false}
          name="receiver"
          title={<FormattedMessage id="ACTIVITY_CALL_RECEIVER" defaultMessage="Receiver"/>}
          render={item => <UserDetailsLink userId={item.receiverId} />} />
        <Column
          sortable={false}
          name="receiverDevice"
          title={<FormattedMessage id="ACTIVITY_CALL_RECEIVER_DEVICE" defaultMessage="Receiver device"/>}/>
        <Column
          name="createdAt"
          sortable={true}
          title={<FormattedMessage id="ACTIVITY_CALL_DATE" defaultMessage="Date"/>}
          render={item => <FormattedDateTime value={item.date}/>} />
        <Column
          name="duration"
          sortable={false}
          title={<FormattedMessage id="ACTIVITY_CALL_DURATION" defaultMessage="Duration"/>}
          render={item => secondsToTimeString(item.duration)} />
      </Table>
    </div>
  }
}

export { CallList }
