import * as ActionTypes from './types'
import { push } from 'react-router-redux'
import { createFilterActions, createListActions, CONFIG } from 'skylight-common'
import skylight from 'skylight-js-sdk'
import dialogActions, { hideDialog } from './dialog'
import { showError } from './error'
import { DEFAULT_QUERY } from '../lib/application'
import { getApplication, loadApplications, getLastApplicationExport } from '../svc/applicationsNextGen'
import * as dialogTypes from '../App/Home/Layout/Dialogs/dialogTypes'
import { getLastPublishedVersion } from '../svc/application/versions'
import snackbarActions from './snackbar'
import { getApplicationCurrentVersion } from '../lib/nextGenApplication'
import { omit } from 'lodash'

const LIST_ACTIONS = createListActions({
  prefix: ActionTypes.PREFIX_APPLICATION.toUpperCase(),
  handler: ActionTypes.PREFIX_APPLICATION,
  onGetList: s => s.application.list,
  onLoad: async(query, ctx) => {
    const { data, page } = await loadApplications(query)
    const { activeImports } = ctx.getState().application.applicationImport

    return { data: [...activeImports, ...data], page }
  },
  onDelete: async ids => Promise.all(ids.map(skylight.we20.applications.archiveById))
})

const FILTER_ACTIONS = createFilterActions({
  baseUrl: '/ng/applications',
  redirect: false,
  selector: state => state.application.list.query,
  listPrefix: ActionTypes.PREFIX_APPLICATION.toUpperCase(),
  actionPrefix: ActionTypes.PREFIX_APPLICATION,
  DEFAULT_QUERY,
  loadItems: LIST_ACTIONS.onApplicationLoadItems,
  push
})

const redirectToNewQuery = FILTER_ACTIONS.onApplicationQueryUpdated

const newApplication = () => dispatch => {
  dispatch(dialogActions.showSetupNewApplicationDialog())
}

const newBlankApplication = (name, description, solutions) => async(dispatch, getState) => {
  let app = null
  try {
    dispatch(updateCreateAppIsLoading(true))
    const query = getState().application.list.query
    app = await skylight.we20.application.bootstrap({ name, description, solutions }, CONFIG.compatibilityVersion)
    // load application list again
    dispatch(LIST_ACTIONS.onApplicationLoadItems(omit(query, ['nextCursor'])))
  } catch (e) {
    dispatch(showError('SETUP_BLANK_APP_ERROR', { className: 'snackbar-common-error' }))
    console.error('An error occurred during creating an app', e)
    return
  } finally {
    dispatch(updateCreateAppIsLoading(false))
  }
  window.open(`/ng/builder/?appId=${app.applicationId}`)
  dispatch(hideDialog(dialogTypes.SETUP_NEW_APPLICATION))
}

const changeApplicationView = view =>
  redirectToNewQuery(query => ({ ...query, view }))

const applicationSortUpdate = (sortBy, sortAsc) =>
  redirectToNewQuery(q => ({ ...q, sortBy, sortAsc }))

const loadPermissionsForApplication = application => async(dispatch, getState) => {
  if (application.archived) {
    return
  }

  const state = getState()
  const userId = state.auth.user.id

  const permissions = await skylight.we20.user.permissions(application.applicationId, userId)

  dispatch({
    type: ActionTypes.APPLICATION_PERMISSIONS_ADD,
    applicationId: application.applicationId,
    permissions
  })
}

const openApplicationDashboard = application => async dispatch => {
  dispatch({
    type: ActionTypes.SELECTED_APP_UPDATE,
    application: {
      ...application,
      lastPublished: '',
      archived: application.archived || false,
      currentVersion: null
    }
  })

  dispatch(push(`/ng/applications/${application.id}/general`))
  dispatch({ type: ActionTypes.SELECTED_APP_EXPORT_UPDATE, export: null })
}

export const updateSelectedApplication = applicationId => async(dispatch, getState) => {
  const { selectedApplication } = getState().application
  try {
    const application = selectedApplication.archived ? selectedApplication : await getApplication(applicationId)
    application.modifiedAt = application.modifiedAt || application.createdAt

    const lastPublishedVersion = await getLastPublishedVersion(applicationId)
    application.lastPublished = lastPublishedVersion ? lastPublishedVersion.modifiedAt : ''

    await dispatch(loadPermissionsForApplication(application))
    application.currentVersion = getApplicationCurrentVersion(application)

    dispatch({ type: ActionTypes.SELECTED_APP_UPDATE, application })

    const lastApplicationExport = await getLastApplicationExport(applicationId)
    lastApplicationExport && dispatch({ type: ActionTypes.SELECTED_APP_EXPORT_UPDATE, export: lastApplicationExport })
  } catch (e) {
    console.error('an error occurred during loading application', e)
  }
}

const updateCreateAppStep = step => dispatch => {
  dispatch({ type: ActionTypes.CREATE_APP_STEP_UPDATE, step })
}

const updateCreateAppTemplate = template => dispatch => {
  dispatch({ type: ActionTypes.CREATE_APP_TEMPLATE_UPDATE, template })
}

const updateCreateAppIsLoading = isLoading => ({
  type: ActionTypes.CREATE_APP_IS_LOADING_UPDATE,
  isLoading
})

const openBuilder = applicationId => dispatch => {
  const queryString = applicationId ? `?appId=${applicationId}` : ''
  window.open(`/ng/builder/${queryString}`)
}

export const archiveApplication = () => async(dispatch, getState) => {
  const { applicationId } = getState().application.selectedApplication

  dispatch(snackbarActions.showArchivingApplicationSnackbar())
  try {
    await skylight.we20.application.archiveById(applicationId)
  } catch (e) {
    dispatch(snackbarActions.showErrorArchivingApplication())
    return
  } finally {
    dispatch(snackbarActions.hideLoadingSnackbar())
  }

  dispatch(snackbarActions.showArchivingCompleteSnackbar())
  dispatch(push('/ng/applications'))
}

export const unarchiveApplication = () => async(dispatch, getState) => {
  const { applicationId } = getState().application.selectedApplication
  try {
    await skylight.we20.application.unarchiveById(applicationId)
    dispatch(push('/ng/applications'))
  } catch (e) {
    dispatch(snackbarActions.showMessageSnackbar('UNARCHIVE_APPLICATION_ERROR', 'error'))
  }
}

const deleteApplicationImport = (applicationId, importId) => async dispatch => {
  try {
    await skylight.we20.import.deleteById(importId)
  } catch (e) {
    console.error('an error occurred deleting import', e)
    return
  }

  dispatch(LIST_ACTIONS.onApplicationListItemRemove({ id: applicationId }))
  dispatch(LIST_ACTIONS.onApplicationSelectItems([]))
}

export default {
  ...LIST_ACTIONS,
  onChangeApplicationView: changeApplicationView,
  onNewApplication: newApplication,
  onApplicationSortUpdate: applicationSortUpdate,
  ...FILTER_ACTIONS,
  onApplicationPermissionsLoad: loadPermissionsForApplication,
  onApplicationDashboardOpen: openApplicationDashboard,
  onUpdateSelectedApplication: updateSelectedApplication,
  onNewBlankApplication: newBlankApplication,
  onUpdateCreateAppStep: updateCreateAppStep,
  onUpdateCreateAppTemplate: updateCreateAppTemplate,
  onBuilderOpen: openBuilder,
  onArchiveApplication: archiveApplication,
  onUnarchiveApplication: unarchiveApplication,
  onDeleteApplicationImport: deleteApplicationImport
}
