import React, { Component } from 'react'
import classNames from 'classnames'

import './Collapse.css'

class Collapse extends Component {
  state = {
    open: false
  }

  componentDidMount() {
    const { open } = this.props
    this.setState({ open })
  }

  handleToggle = e => {
    e.stopPropagation()

    this.setState({ open: !this.state.open })
  }

  render() {
    const { children, collapseIcon, headerText } = this.props
    const { open } = this.state

    return (
      <div className="collapse-container">
        <div className="collapse-header" onClick={this.handleToggle}>
          <div className={classNames('collapse-icon', { open })}>{collapseIcon}</div>
          <div className="collapse-header-text">{headerText}</div>
        </div>
        <div className={classNames('collapse-content', { open })}>
          {children}
        </div>
      </div>
    )
  }
}

Collapse.defaultProps = {
  open: false
}

export { Collapse }
