import { connect } from 'react-redux'
import workflowActions from '../actions/workflow'
import dialogActions from '../actions/dialog'
import assignmentActions from '../actions/assignment'
import contactActions from '../actions/contact'
import {
  ApplicationList as ApplicationListComp
} from '../App/Home/Applications/ApplicationList'
import {
  ApplicationTextFilter as ApplicationTextFilterComp
} from '../App/Home/Applications/ApplicationFilter'

const applicationList = (state) => ({
  list: state.workflow.list,
  status: state.workflow.status,
  scopes: state.auth.scopes,
  user: state.auth.user
})

const applicationListActions = {
  ...dialogActions,
  ...workflowActions,
  onAssignmentByApp: assignmentActions.onAssignmentByApp,
  onUserSelectItems: contactActions.onUserSelectItems
}

const filter = (state) => ({
  query: state.workflow.list.query,
  layout: state.layout,
  authUser: state.auth.user
})

const filterActions = {
  ...workflowActions,
  onUserSelectItems: contactActions.onUserSelectItems
}

const ApplicationList = connect(applicationList, applicationListActions)(ApplicationListComp)
const ApplicationTextFilter = connect(filter, filterActions)(ApplicationTextFilterComp)

export { ApplicationList, ApplicationTextFilter }
