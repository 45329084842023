import React from 'react'
import { connect } from 'react-redux'
import { Column, ExportIcon, ListFooterStats, Loading, GridHeader, CursorTooltip, ListActionButton } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import IconButton from 'material-ui/IconButton'
import BlankCheckboxIcon from 'material-ui/svg-icons/toggle/check-box-outline-blank'
import classNames from 'classnames'
import PublishIcon from '../../../../../../Common/Icons/publish'
import { TableNextGen } from '../../../../../../Common/NextGen/TableNextGen'
import { VERSION_STAGES, versionUpgradeIsFine } from '../../../../../../../lib/application/version'
import { hasPermission, PERMISSIONS } from '../../../../../../../lib/application'
import { EmptyListView } from '../../Sessions/SessionList/EmptyListView'
import VersionTextFilter from '../VersionFilter/VersionTextFilter'
import { COLUMNS } from './columns'
import UploadIcon from 'material-ui/svg-icons/file/file-upload'
import DeleteIcon from '../../../../../../Common/Icons/DeleteIcon'

import appVersionActions from '../../../../../../../actions/application/versions'
import nextGenApplicationActions from '../../../../../../../actions/nextGenApplication'
import contactActions from '../../../../../../../actions/contact'
import applicationExportActions from '../../../../../../../actions/applicationExport'
import { showUpgradeDialog } from '../../../../../../../actions/dialog'
import { processVersions } from '../../../../../../../svc/application/versions'

import './VersionList.css'

const VersionList = props => {
  const {
    list,
    scopes,
    application,
    permissions,
    onShowUpgradeDialog
  } = props
  const versions = list.items
  const { applicationId } = application

  const applicationPermissions = permissions[applicationId] || {}
  const hasVersionPublishPermissions = applicationPermissions[PERMISSIONS.VERSION.PUBLISH]
  const canUpdate = hasPermission(applicationPermissions, PERMISSIONS.VERSION.UPDATE)

  const handleItemAction = (e, action) => {
    e.stopPropagation()
    action()
  }

  const getItemActions = item => {
    return [
      {
        icon: PublishIcon,
        tooltip: { id: 'ACTION_PUBLISH_APPLICATION', default: 'Publish application' },
        className: 'publish',
        action: () => props.onStartPublishingVersion(item.version, versions),
        isDisabled: () => [VERSION_STAGES.PUBLISHED, VERSION_STAGES.INACTIVE, VERSION_STAGES.PENDING, VERSION_STAGES.UPGRADE_PENDING]
          .includes(item.stage) || !hasVersionPublishPermissions | !versionUpgradeIsFine(item)
      },
      {
        icon: ExportIcon,
        tooltip: { id: 'ACTION_EXPORT_VERSION', default: 'Export version' },
        className: 'export',
        action: () => props.onExportApplication(item.applicationId, item.version),
        isDisabled: () => item.stage === VERSION_STAGES.UPGRADE_PENDING || props.exportInProgress || props.upgradeInProgress || !versionUpgradeIsFine(item)
      },
      {
        icon: DeleteIcon,
        tooltip: { id: 'DELETE_VERSION', default: 'Delete version' },
        className: 'delete',
        action: () => props.onDeleteVersionUpgrade(item.upgrade),
        isDisabled: () => !canUpdate || (versionUpgradeIsFine(item) && item.stage !== VERSION_STAGES.UPGRADE_PENDING)
      }
    ].filter(x => typeof x.isDisabled === 'function' ? !x.isDisabled() : true)
  }

  const renderItemActions = item => {
    return getItemActions(item)
      .map((a, i, arr) => <CursorTooltip key={i} tooltip={<FormattedMessage id={a.tooltip.id} defaultMessage={a.tooltip.default} />}>
        <IconButton
          key={i}
          className={`item-action-button ${a.className || ''}`}
          onTouchTap={e => handleItemAction(e, a.action)}><a.icon className="icon" /></IconButton>
      </CursorTooltip>)
  }

  const renderFooterActions = item => {
    if (!item || list.selectedIds.length > 1) return null
    const ItemActions = getItemActions(item)
      .map((a, i) => <CursorTooltip key={i} tooltip={<FormattedMessage id={a.tooltip.id} defaultMessage={a.tooltip.default} />}>
        <IconButton
          className={`item-action-button ${a.className || ''}`}
          tooltipPosition="top-center"
          onTouchTap={e => handleItemAction(e, a.action)}><a.icon className="icon" /></IconButton>
      </CursorTooltip>)

    return <div className="footer-actions">{ItemActions}</div>
  }

  const renderTableFooter = () => {
    const selectedItem = list.items.find(x => x.version === list.selectedIds[0])
    return list.selectedIds.length > 0
      ? <ListFooterStats list={list} onUnselect={props.onVersionSelectItems} actions={renderFooterActions(selectedItem)} />
      : null
  }

  const onNavigateToUser = (item) => {
    props.onUserSelectItems([item.id], [item])
  }

  const renderVersionsTable = () => {
    if (!applicationId) {
      return null
    }

    return <div className="app-versions-table">
      <TableNextGen
        tableHeading={props.heading}
        showCheckboxes
        tableHeaderActions={props.tableHeaderActions}
        checkboxIcon={() => <BlankCheckboxIcon />}
        showHover
        keyProp="version"
        isLoading={list.isLoading}
        query={list.query}
        items={list.items}
        total={list.total}
        done={list.done}
        selectedRows={list.selectedIds}
        name="appVersionList"
        tableFooter={renderTableFooter()}
        emptyLabel={<EmptyListView />}
        onQuery={props.onVersionQuery}
        rowClassName={item => classNames(`version-row ${item.stage}`, { 'errored-upgrade': !versionUpgradeIsFine(item) })}
        onRowsSelected={props.onVersionSelectItems}>
        {COLUMNS.map(x => <Column
          key={x.name}
          name={x.name}
          sortable={x.sortable !== false}
          title={<FormattedMessage id={x.title} defaultMessage={x.title} />}
          render={x.render ? item => x.render(item, onNavigateToUser, scopes) : null} />)}
        <Column
          key="versionActions"
          className="actions"
          isAction
          render={renderItemActions} />
      </TableNextGen>
    </div>
  }

  const getHeaderActions = () => [<VersionTextFilter key="filter" />]
  const getTitle = () => {
    return <div className="version-list-title">{application.name}</div>
  }

  const isLoading = applicationId && list.isLoading && list.items.length === 0
  return (
    <div className="version-list">
      <GridHeader title={getTitle()} actions={getHeaderActions()} />
      <Loading
        isLoading={isLoading}
        message="LOADING_APP_VERSIONS_LIST"
        error={list.error
          ? <FormattedMessage
          id="EMPTY_APP_VERSIONS_LIST"
          defaultMessage="No versions available" />
          : null}>
        {renderVersionsTable()}
      </Loading>
      {canUpdate && (
        <ListActionButton
          icon={<UploadIcon />}
          tooltip={<FormattedMessage id="UPGRADE_APPLICATION" default="Upgrade application" />}
          onAction={onShowUpgradeDialog} />
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  const application = state.application.selectedApplication
  const { upgrades } = state.application.applicationUpgrade
  const applicationId = application?.applicationId
  const upgrade = upgrades.find(x => x.upgrade.applicationId === applicationId)
  const isCurrentAppUpgrading = applicationId === upgrade?.upgrade.applicationId
  const queryVersion = state.application.version.list.query.filter.version
  return {
    list: {
      ...state.application.version.list,
      items: processVersions(state.application.version.list.items, queryVersion)
    },
    permissions: state.application.permissions,
    scopes: state.auth.scopes,
    user: state.auth.user,
    application,
    exportInProgress: state.application.applicationExport.exporting,
    upgradeInProgress: !!(upgrade?.inProgress && isCurrentAppUpgrading)
  }
}

const versionListActions = {
  ...appVersionActions,
  ...nextGenApplicationActions,
  onUserSelectItems: contactActions.onUserSelectItems,
  onShowUpgradeDialog: showUpgradeDialog,
  ...applicationExportActions
}

const VersionListContainer = connect(mapStateToProps, versionListActions)(VersionList)

export default VersionListContainer
