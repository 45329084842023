import React from 'react'
import { Dialog, FlatButton } from 'material-ui'
import { FormattedMessage } from 'react-intl'
import PublishIcon from '../../../../Common/Icons/publish'

import './PublishVersion.css'
import { OutlinedTextField } from '../../../../Common/Form/OutlinedTextField'

class PublishVersion extends React.Component {
  state = {
    comments: ''
  }

  field = null

  handleFieldChange = (e, v) => {
    this.setState({ [e.target.name]: v })
  }

  handlePublishClick = e => {
    const { dialog, dialogType } = this.props
    const { params } = dialog[dialogType] || {}
    this.props.onPublishVersion(params.version, this.state.comments)
  }

  handleDialogClose = e => {
    const { onHideDialog, dialogType } = this.props
    onHideDialog(dialogType)
  }

  componentDidMount() {
    const { dialog, dialogType } = this.props
    const { open, params: { versions, version } } = dialog[dialogType] || {}
    const appVersion = versions.find(x => x.version === version)
    if (open) {
      this.setState({ comments: appVersion?.comment || '' })
    }
    requestAnimationFrame(() => {
      this.field && this.field.input.select()
    })
  }

  onSubmit = e => {
    e.preventDefault()
    this.handlePublishClick()
  }

  render() {
    const { dialog, dialogType } = this.props
    const { open } = dialog[dialogType] || {}
    const actions = [
      <FlatButton
        key="cancel-button"
        style={{ fontFamily: 'Roboto Medium' }}
        className="cancel-button"
        primary
        label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
        onTouchTap={this.handleDialogClose} />,
      <FlatButton
        key="publish-button"
        style={{ fontFamily: 'Roboto Medium' }}
        label={<FormattedMessage id="PUBLISH" defaultMessage="Publish" />}
        onTouchTap={this.handlePublishClick}
        primary />
    ]

    const title = (
      <div style={{ color: 'rgba(65, 64, 66, 1)' }}>
        <PublishIcon color="rgba(65, 64, 66, 1)" style={{ marginRight: 8 }} />
        <FormattedMessage id="ACTION_PUBLISH_APPLICATION" defaultMessage="Publish application" />
      </div>
    )

    return (
      <Dialog
        title={title}
        // modal={false}
        titleStyle={{ fontSize: 20, display: 'flex', alignItems: 'center', padding: '12px 24px' }}
        open={!!open}
        actions={actions}
        className="publish-version-dialog"
        overlayClassName="publish-version-overlay"
        overlayStyle={{
          backgroundColor: 'rgba(65, 64, 66, 0.33)'
        }}
        autoDetectWindowHeight={false}
        bodyStyle={{ paddingBottom: 0 }}
        contentStyle={{ maxWidth: 558, color: '#414042' }}
        onRequestClose={() => this.props.onHideDialog(dialogType)}>
        <form onSubmit={this.onSubmit}>
          <div className="publish-version-note">
            <FormattedMessage
              id="PUBLISH_VERSION_NOTE"
              defaultMessage="Publishing this version will make it available to everyone who has User permissions." />
          </div>
          <div
            style={{
              marginTop: 16
            }}>
            <OutlinedTextField
              textFieldRef={x => { this.field = x }}
              id="comment"
              type="text"
              name="comments"
              hintText={<FormattedMessage id="PUBLISH_VERSION_COMMENTS_HINT" defaultMessage="Describe what has changed..." />}
              floatingLabelText={<FormattedMessage id="PUBLISH_VERSION_COMMENTS_LABEL" defaultMessage="Comments (optional)" />}
              value={this.state.comments}
              onChange={this.handleFieldChange} />
            <input type="submit" hidden />
          </div>
        </form>
      </Dialog>
    )
  }
}

export { PublishVersion }
