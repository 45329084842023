import React from 'react'
import Done from 'material-ui/svg-icons/action/done'
import { FormattedMessage } from 'react-intl'
import { FlatButton } from 'material-ui'
import './index.css'

export const MediaPermissionsTab = ({ dialogType, ...props }) => {
  return (
    <div className="permissions-dialog-content">
      <div className="content-main second-tab">
        <div className="top-description">
          <FormattedMessage
            id="ALLOW_AUDIO_DESCRIPTION"
            default="To make and receive video calls, you need to grant permission"/>
        </div>

        <ul className="media-permissions-list">
          <li>
            <Done />
            <FormattedMessage id="ACCESS_MICROPHONE" default="Access your microphone"/>
          </li>
          <li>
            <Done />
            <FormattedMessage id="ACCESS_CAMERA" default="Access your camera"/>
          </li>
          <li>
            <Done />
            <FormattedMessage id="DESKTOP_NOTIFICATIONS" default="Turn on desktop notifications"/>
          </li>
        </ul>
      </div>

      <div className="dialog-actions-buttons">
        <div>
          <FlatButton
            label="BACK"
            primary={false}
            labelStyle={{ color: '#7d7d7d' }}
            onTouchTap={() => props.onSetPermissionDialogTab(2)}/>
        </div>
        <div>
          <FlatButton
            label="NOT NOW"
            primary={false}
            labelStyle={{ color: '#686868' }}
            onTouchTap={() => props.onHideDialog(dialogType)}/>
          <FlatButton
            label="GRANT PERMISSION"
            primary={true}
            onTouchTap={() => {
              props.onSavePermissions()
              props.onHideDialog(dialogType)
            }}/>
        </div>
      </div>
    </div>
  )
}
