const SKYLIGHT_THEME = {
  palette: {
    primary1Color: '#00aaed',
    primary2Color: '#31aeed',
    accent1Color: '#ff0081',
    accent2Color: '#f7dc7f'
  },
  toolbar: {
    backgroundColor: '#736f77'
  },
  tabs: {
    backgroundColor: '#736f77'
  },
  tableRow: {
    hoverColor: '#ededed'
  },
  inkBar: {
    backgroundColor: '#fff'
  },
  svgIcon: {
    color: '#fff'
  },
  toggle: {
    thumbOffColor: '#fff'
  },
  icon: {
    color: '#bbbec0'
  },
  flatButton: {
    secondaryTextColor: '#736f77'
  }
}

export { SKYLIGHT_THEME }
