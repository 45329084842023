import * as ActionTypes from '../actions/types'
import contacts from '../actions/contact'
import assignments from '../actions/assignment'
import callUser from '../actions/callUser'
import admin from '../actions/admin'
import dialog from '../actions/dialog'
import auth from '../actions/auth'
import nextGenAppActions from '../actions/nextGenApplication'
import { CALL_DEFAULT_QUERY } from '../lib/contact'
import { DEFAULT_EVENTS_QUERY, DEFAULT_CALLS_QUERY } from '../lib/activity'
import { DEFAULT_QUERY as ASSIGNMENTS_DEFAULT_QUERY, ATTACHMENT_DEFAULT_QUERY } from '../lib/assignment'
import { DEFAULT_QUERY as MEDIA_DEFAULT_QUERY } from '../lib/media'
import { createListMiddleware } from 'skylight-common'
import { get, isEqual } from 'lodash'
import { DEFAULT_QUERY } from '../lib/application'

const noop = () => dispatch => {}

const LOAD_TEAM = {
  action: contacts.onInitTeam,
  query: s => s.contact,
  prefix: ActionTypes.PREFIX_USER
}

const LOAD_GROUPS = {
  action: contacts.onGroupLoadItems,
  query: s => s.contact.groups.query
}

const LOAD_ACTIVITY_LOG = {
  action: noop,
  query: s => s.activity.event.list.query,
  prefix: ActionTypes.PREFIX_ACTIVITY,
  defaultQuery: DEFAULT_EVENTS_QUERY
}

const LOAD_ASSIGNMENTS = {
  action: assignments.onInitAssignments,
  query: s => ({ query: s.assignment.list.query, auth: s.auth }),
  prefix: ActionTypes.PREFIX_ASSIGNMENT,
  defaultQuery: ASSIGNMENTS_DEFAULT_QUERY
}

const LOAD_CALL_CONTACTS = {
  action: callUser.onCallUserLoadItems,
  query: s => ({ ...CALL_DEFAULT_QUERY, ...s.callUser.list.query }),
  prefix: ActionTypes.PREFIX_CALL_USER,
  defaultQuery: CALL_DEFAULT_QUERY
}

const LOAD_ADMIN_TOKENS = {
  action: admin.onApiTokenLoadItems,
  query: s => s.admin.apiToken.list.query,
  prefix: ActionTypes.PREFIX_API_TOKEN
}

const LOAD_ASSIGNMENT_ATTACHMENTS = {
  action: assignments.onAssignmentAttachmentLoadRoute,
  query: (s, p) => ({ ...s.assignment.attachment.list.query, id: p.id }),
  prefix: ActionTypes.PREFIX_ASSIGNMENT_ATTACHMENT,
  defaultQuery: ATTACHMENT_DEFAULT_QUERY
}

const LOAD_WORKFLOWS = {
  action: noop /* workflow.onWorkflowLoadItems */,
  query: s => s.workflow.list.query,
  prefix: ActionTypes.PREFIX_WORKFLOW
}

const LOAD_APPLICATIONS = {
  action: nextGenAppActions.onApplicationLoadItems,
  query: s => s.application.list.query,
  prefix: ActionTypes.PREFIX_APPLICATION
}

const LOAD_SESSIONS = {
  action: noop,
  query: s => s.application.session.list.query,
  prefix: ActionTypes.PREFIX_SESSION
}

const LOAD_APPLICATION_PARTICIPANTS = {
  action: noop,
  query: s => s.application.participant.list.query,
  prefix: ActionTypes.PREFIX_APPLICATION_PARTICIPANT
}

const LOAD_APPLICATION_ROLES = {
  action: noop,
  query: s => s.application.role.list.query,
  prefix: ActionTypes.PREFIX_APPLICATION_ROLE
}

const LOAD_VERSIONS = {
  action: noop,
  query: s => s.application.version.list.query,
  prefix: ActionTypes.PREFIX_VERSION
}

const listActions = {
  '/contacts/groups': { action: contacts.onGroupLoadItems, query: s => s.contact.groups.query },
  '/contacts/:type?': { action: contacts.onLoadContacts, query: s => s.contact.users.query },
  '/contacts/users/:id': { action: contacts.onLoadContacts, query: s => s.contact.users.query },
  '/team/groups': { ...LOAD_TEAM, prefix: ActionTypes.PREFIX_GROUP },
  '/team/:type?': { ...LOAD_TEAM, prefix: ActionTypes.PREFIX_USER },
  '/team/users/:id': LOAD_TEAM,
  // '/devices': { action: devices.onLoadDevices, query: s => s.device.devices.query },
  '/assignments/:type?': LOAD_ASSIGNMENTS,
  '/calls': { ...LOAD_CALL_CONTACTS },
  // '/calls/:type?': { action: contacts.onGroupLoadItems, query: s => s.contact.groups.query },
  '/calls/users/:id': { ...LOAD_CALL_CONTACTS },
  '/activities': { ...LOAD_ACTIVITY_LOG },
  '/activities/events': { ...LOAD_ACTIVITY_LOG },
  '/activities/calls': {
    action: noop,
    query: s => s.activity.call.list.query,
    prefix: ActionTypes.PREFIX_CALL,
    defaultQuery: DEFAULT_CALLS_QUERY
  },

  // Infinite scroll should handle initial load, remove for now.
  '/media': {
    action: () => noop /* media.onLoadMediaItems */,
    query: s => s.media.list.query,
    defaultQuery: MEDIA_DEFAULT_QUERY,
    prefix: ActionTypes.PREFIX_MEDIA
  },
  // '/admin': LOAD_ADMIN_TOKENS,
  '/admin/tokens': LOAD_ADMIN_TOKENS,
  '/admin/auth': { action: contacts.onGroupLoadItems, query: s => s.contact.groups.query },
  // '/admin/auth': { action: admin.onRealmLoad, query: () => {} },
  '/applications': LOAD_WORKFLOWS,
  '/profile': { action: auth.onRefreshUser, query: () => ({}) },
  '/assignments/attachments/:id': LOAD_ASSIGNMENT_ATTACHMENTS,
  '/ng/auth/ldap': LOAD_GROUPS,
  '/ng/applications': LOAD_APPLICATIONS,
  '/ng/applications/:applicationId/sessions': LOAD_SESSIONS,
  '/ng/applications/:applicationId/permissions': LOAD_APPLICATION_PARTICIPANTS,
  '/ng/applications/:applicationId/permissions/roles': LOAD_APPLICATION_ROLES,
  '/ng/applications/:applicationId/versions': LOAD_VERSIONS,
  '/ng/groups': { ...LOAD_TEAM, prefix: ActionTypes.PREFIX_GROUP },
  '/ng/users': { ...LOAD_TEAM, prefix: ActionTypes.PREFIX_USER }
}

const setupListActions = listActions => {
  // Temporary workaround. Some day it must go to common with active filters.
  Object.keys(listActions).forEach(key => {
    const val = listActions[key]
    if (val.prefix) {
      val.actionPrefix = val.prefix
      val.prefix = val.prefix.toUpperCase()
    }
  })
}

setupListActions(listActions)

const list = createListMiddleware({
  listActions,
  onActiveFilters: (listAction) => (dispatch, getState) => {
    const filter = get(getState(), 'application.list.query.filter')
    const snackbarShouldBeShown = !isEqual(filter, DEFAULT_QUERY.filter)
    if (snackbarShouldBeShown) {
      dispatch(dialog.onActiveFilters(listAction))
    }
  },
  onHideActiveFilters: dialog.onHideActiveFilters
})

export default list
