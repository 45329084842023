import React, { Component } from 'react'
import { RoleMapping } from '../Mappings/RoleMapping'

import './RoleBody.css'

const SYNC_TYPE = 'role'

class RoleBody extends Component {
  getAvailableOptions(additionalItems = []) {
    const { roles } = this.props

    return [...additionalItems, ...roles]
  }

  onChange = (idx) => (value) => {
    const { roleFilters } = this.props
    const lastRow = roleFilters.length - 1 === idx
    if (lastRow) {
      const filters = [
        ...roleFilters.slice(0, idx),
        { syncType: SYNC_TYPE, ...value }
      ]

      if (value.name && value.filter) {
        filters.push({ syncType: SYNC_TYPE, name: 'user' })
      }
      this.props.onRoleFiltersChange(filters)
      return
    }

    this.props.onRoleFiltersChange([
      ...roleFilters.slice(0, idx),
      { syncType: SYNC_TYPE, ...value },
      ...roleFilters.slice(idx + 1)
    ])
  }

  onDelete = (idx) => () => {
    this.props.onDelete(idx, 'roleFilters')
  }

  render() {
    const { roleFilters } = this.props

    return (
      <div className="query-filter-dialog-mapping-body role-body">
        {roleFilters.map(({ name, filter }, idx) => {
          const lastRow = idx === roleFilters.length - 1
          return <RoleMapping
            key={idx}
            role={name}
            filter={filter}
            options={this.props.roles}
            onChange={this.onChange(idx)}
            onDelete={!lastRow && this.onDelete(idx)} />
        })}
        <RoleMapping
          key="newRow"
          options={this.props.roles}
          disabled />
      </div>
    )
  }
}

export { RoleBody }
