import React from 'react'
import skylight from 'skylight-js-sdk'
import { FormattedMessage } from 'react-intl'
import DeviceClientRunIcon from '../../../Common/Icons/deviceClientRun'
import QrCodeScanIcon from '../../../Common/Icons/qrcodeScan'
import WifiStatusIcon from '../../../Common/Icons/wifiStatus'
import { TileList } from './TileList'

import './HomePage.css'

const HomePage = (props) => {
  const pairDevice = skylight.utils.isFrontline()
    ? []
    : [{
        title: { id: 'PAIR_DEVICE', defaultMessage: 'Pair a device with your account' },
        icon: QrCodeScanIcon,
        handleClick: () => props.onShowAddDeviceDialog(props.auth, { timeout: 60 })
      }]
  const tileActions = [
    {
      title: { id: 'RUN_WEB_CLIENT', defaultMessage: 'Run web client' },
      icon: DeviceClientRunIcon,
      handleClick: () => { window.open('/ng/client/') }
    },
    ...pairDevice,
    {
      title: { id: 'SETUP_WIFI_DEVICE', defaultMessage: 'Setup WiFi on a device' },
      icon: WifiStatusIcon,
      handleClick: () => props.onShowSetupWifiDialog()
    }
  ]

  return (
    <div className="home-page-next-gen">
      <div className="title-messages">
        <span className="title">
          <FormattedMessage
            id="MY_SKYLIGHT"
            defaultMessage="Home" />
        </span>
        <span className="tagline">
          <FormattedMessage
            id="SELECT_ACTION"
            defaultMessage="Select an action" />
        </span>
      </div>
      <TileList actions={tileActions} />
    </div>
  )
}

export { HomePage }
