import React from 'react'
import { ByDateRange, ByUser } from 'skylight-common'
import { FilterToolbarNextGen } from '../../../../../../Common/NextGen/FilterToolbarNextGen'

const getFilters = (filterProps = {}) => [
  { text: 'DATE_CREATED', value: 'createdAt', props: { title: 'DATE_CREATED' }, comp: ByDateRange },
  { text: 'DATE_UPDATED', value: 'modifiedAt', props: { title: 'DATE_UPDATED', filterProps }, comp: ByDateRange },
  { text: 'CREATED_BY', value: 'createdBy', props: { title: 'CREATED_BY', filterProps }, comp: ByUser }
]

const VersionFilter = props => {
  const filterProps = {
    urlPath: '/team/users',
    onSelectItems: props.onUserSelectItems,
    role: props.authUser.role
  }

  const filters = getFilters(filterProps)

  return <FilterToolbarNextGen
    className="session-filter"
    filters={filters}
    query={props.query}
    layout={props.layout}
    onFilterUpdated={props.onVersionFilterUpdated}
    onFiltersUpdated={props.onVersionFiltersUpdated}
    onFilterRemoved={props.onVersionFilterRemoved} />
}

export { VersionFilter }
