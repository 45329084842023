import React from 'react'
import { AssignmentDescription } from '../AssignmentDescription'
import { AttachmentIcon } from '../../../../containers/assignment'
import { ItemProps } from '../../../Common'
import './AssignmentItemDetails.css'

const DETAILS_PROPS = [
  { field: 'name', title: 'APPLICATION_NAME', type: 'string' },
  {
    field: 'description',
    title: 'DESCRIPTION',
    type: 'render',
    render: item => <AssignmentDescription item={item} />
  },
  { field: 'archived', title: 'ARCHIVED', type: 'boolean' },
  { field: 'id', title: 'ASSIGNMENT_ID', type: 'string' },
  { field: 'created', title: 'ASSIGNED_ON', type: 'date' },
  { field: 'assignedTo', title: 'ASSIGNED_TO', type: 'user' },
  { field: 'createdBy', title: 'CREATED_BY', type: 'user' },
  { field: 'updatedBy', title: 'LAST_UPDATED_BY', type: 'user' },
  { field: 'updated', title: 'LAST_UPDATED_ON', type: 'date' },
  { field: 'workflowId', title: 'APPLICATION_ID', type: 'string' }
]

const ERROR_PROPS = [
  { field: 'statusCode', title: 'STATUS_CODE', type: 'string' },
  { field: 'source', title: 'SOURCE', type: 'string' },
  { field: 'message', title: 'MESSAGE', type: 'string' }
]

const COMPLETE_PROPS = [
  { field: 'completedAt', title: 'COMPLETED_AT', type: 'date' },
  { field: 'completionDetails', title: 'COMPLETION_DETAILS', type: 'string' }
]

const AssignmentItemDetails = (props) => {
  const { item } = props

  return (
    <div className="assignment-item-details">
      {item.isTask && item.status === 'error' && item.error && <div className="error-details">
        <div className="error-details">
          <ItemProps item={item.error} props={ERROR_PROPS} />
        </div>
      </div>}
      {item.status !== 'error' && <AttachmentIcon item={item} />}
      <ItemProps item={item} props={item.isComplete ? [...COMPLETE_PROPS, ...DETAILS_PROPS] : DETAILS_PROPS} />
    </div>
  )
}

export { AssignmentItemDetails }
