import * as ActionTypes from '../actions/types'
import workflowActions from '../actions/workflow'
import { showError } from '../actions/error'

const onLoadWorkflows = (next, query) => {
  delete query.nextCursor

  next(workflowActions.onWorkflowLoadItems(query))
  next({ type: ActionTypes.APP_CLEAR_ERROR })
}

const application = store => next => action => {
  const res = next(action)

  switch (action.type) {
    case ActionTypes.WORKFLOW_APPLICATION_EVENT:
      if (window.location.pathname === '/applications') {
        const { query } = store.getState().workflow.list
        if (action.event === 'updated') {
          next(showError('APPLICATION_UPDATED_EVENT', {
            className: 'snackbar-common-notification',
            actionName: 'RELOAD_LIST',
            duration: 7000,
            action: () => onLoadWorkflows(next, query)
          }))
        } else {
          // Delete nextCursor to reload app list.
          delete query.nextCursor
          next(workflowActions.onWorkflowLoadItems(query))
        }
      }

      if (window.location.pathname.toLowerCase().includes('/admin/tokens')) {
        // Force items to load next time user opens token details.
        next(workflowActions.onWorkflowResetItems())
      }

      break
    default: break
  }

  return res
}

export default application
