import React from 'react'
import { UserImage } from '../../../Common/UserImage'

import './UserAvatar.css'

const UserAvatar = props => {
  const { user } = props

  const renderUserInitials = () => {
    const { firstName, lastName, username } = user
    if (!firstName && !lastName) {
      return username[0].toUpperCase()
    }

    return `${firstName.slice(0, 1)}${lastName.slice(0, 1)}`.trim().toUpperCase()
  }

  const renderAvatarPlaceholder = () => (
    <div className="user-avatar-placeholder">
      {renderUserInitials()}
    </div>
  )

  const renderAvatar = () => {
    if (!user.avatar) {
      return renderAvatarPlaceholder()
    }

    return <UserImage
      src={user.avatar}
      isEditable={false} />
  }

  return (
    <div className="user-avatar">
      {renderAvatar()}
    </div>
  )
}

export { UserAvatar }
