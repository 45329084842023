import React from 'react'
import Dialog from 'material-ui/Dialog'
import { FormattedMessage } from 'react-intl'
import InfoIcon from 'material-ui/svg-icons/action/info'
import './PrivacyAnonymousInfo.css'

// TODO: Move out as a reusable component along with AccessTokenInfo
const PrivacyAnonymousInfo = (props) => {
  return (
    <Dialog
      className="privacy-anonymous-info"
      contentClassName="privacy-anonymous-info-content"
      bodyClassName="privacy-anonymous-info-body"
      title={<div className="privacy-anonymous-info-title">
        <InfoIcon />
        <FormattedMessage id="ANONYMOUS_ACTIVITY_LOGGING" defaultMessage="Anonymous activity logging" />
      </div>}
      open={props.open}
      onRequestClose={props.onClose}>
      <FormattedMessage
        id="ANONYMOUSE_LOGGING_DESC"
        defaultMessage="All user activity except session, login, and logout events have user identity anonymized so they are no longer identifiable." />
    </Dialog>
  )
}

export { PrivacyAnonymousInfo }
