import * as ActionTypes from '../../actions/types'

const initialState = null

const session = (state = initialState, payload, all) => {
  switch (payload.type) {
    case ActionTypes.SESSION_CREATED:
      return payload
    default:
      return state
  }
}

export default session
