import React from 'react'
import { FormattedMessage } from 'react-intl'
import { UserTitle } from 'skylight-common'

const CALL_PROPS = {
  id: { order: 0, label: { id: 'ACTIVITY_CALL_ID', default: 'Call ID' } },
  callerId: {
    order: 2,
    label: { id: 'ACTIVITY_CALL_CALLER', default: 'Caller' },
    render: item => {
      return <UserTitle user={item.callerId}/>
    }
  },
  callerDevice: { order: 3, label: { id: 'ACTIVITY_CALL_CALLER_DEVICE', default: 'Caller device' } },
  receiverId: {
    order: 4,
    label: { id: 'ACTIVITY_CALL_RECEIVER', default: 'Receiver' },
    render: item => {
      return <UserTitle user={item.receiverId}/>
    }
  },
  receiverDevice: { order: 5, label: { id: 'ACTIVITY_CALL_RECEIVER_DEVICE', default: 'Receiver device' } }
}

const getProps = (item, allowedFields) => {
  const props = []

  for (const prop in item) {
    if (!item.hasOwnProperty(prop)) continue

    const propObj = { prop: prop }
    const callProp = CALL_PROPS[prop]

    if (!callProp) {
      continue
    }

    props.push({ ...propObj, ...callProp })
  }

  return props.sort((a, b) => { return a.order - b.order })
}

const CallDataDetails = (props) => {
  const item = props.item

  const renderProp = (u) => {
    return <div key={u.prop} className="detail-item">
      <div className="detail-item-title">
        <FormattedMessage id={u.label.id} defaultMessage={u.label.default} />
      </div>
      <div className="detail-item-value">
        {u.render ? u.render(item) : item[u.prop]}
      </div>
    </div>
  }

  return (
    <div className="call-details">
      {getProps(item).map(renderProp)}
    </div>
  )
}

export { CallDataDetails }
