import React from 'react'
import IconButton from 'material-ui/IconButton'
import ShowCredentialsIcon from 'material-ui/svg-icons/image/remove-red-eye'
import { FormattedMessage } from 'react-intl'
import './ShowCredentialsLink.css'

const ShowCredentialsLink = (props) => {
  const { token, onShowTokenCredentials } = props
  const onShow = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onShowTokenCredentials(token)
  }

  return (
    <IconButton
      className="show-credentials-link"
      tooltipPosition={props.tooltipPosition}
      tooltip={<FormattedMessage id="VIEW_CREDENTIAL" default="View credential" />}
      onTouchTap={onShow}><ShowCredentialsIcon /></IconButton>
  )
}

export { ShowCredentialsLink }
