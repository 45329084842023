import React from 'react'
import skylight from 'skylight-js-sdk'
import { Table, Column, FormattedDateTime, CalendarClock } from 'skylight-common'

import { FormattedMessage } from 'react-intl'

import './CallEventList.css'

class CallEventList extends React.Component {
  getCheckboxIcon = (item) => {
    return <CalendarClock className="skylight-icon"/>
  }

  onQuery(query) {
    const callId = this.props.match.params.callId
    skylight.utils.assert(!!callId, 'missing callId', this.props.match)
    this.props.onCallEventQuery({ ...query, callId })
  }

  render() {
    const list = this.props.list

    return <div className="activity-list">
      <Table
        showCheckboxes={true}
        checkboxIcon={this.getCheckboxIcon}
        showHover={true}
        query={list.query}
        items={list.items}
        total={list.total}
        isLoading={list.isLoading}
        done={list.done || !!list.error}
        selectedRows={list.selectedIds}
        name="callLogsList"
        emptyLabel={<FormattedMessage
          id="EMPTY_CALLS_LIST"
          defaultMessage="There are no call logs." />
        }
        onQuery={q => this.onQuery(q)}
        onRowsSelected={ids => this.props.onCallEventSelectItems(ids, list.items)} >
        <Column
          name="timestamp"
          title={<FormattedMessage id="ACTIVITY_CALL_EVENT_TIMESTAMP" defaultMessage="Timestamp"/>}
          sortable={true}
          render={item => <FormattedDateTime value={item.timestamp}/>} />
        <Column
          name="type"
          title={<FormattedMessage id="ACTIVITY_CALL_EVENT_TYPE" defaultMessage="Type"/>}
          sortable={false} />
        <Column
          name="source"
          title={<FormattedMessage id="ACTIVITY_CALL_EVENT_SOURCE" defaultMessage="Source"/>}
          sortable={false} />
      </Table>
    </div>
  }
}

export { CallEventList }
