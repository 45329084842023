import React from 'react'
import { Redirect } from 'react-router-dom'
import { ActivityList, Calls } from '../../../../containers/activity'
import { TabBar } from 'skylight-common'

const DEFAULT_PATHNAME = '/activities/events'

const TABS = [
  {
    label: { id: 'TAB_EVENTS', defaultMessage: 'Events' },
    value: 'events',
    renderChildren: (type) => type === 'events' && <ActivityList />
  },
  {
    label: { id: 'TAB_CALLS', defaultMessage: 'Calls' },
    value: 'calls',
    renderChildren: (type) => type === 'calls' && <Calls />
  }
]

const ActivityTabs = props => {
  const onChange = (v) => {
    props.redirect(`/activities/${v}`)
  }

  const type = props.type
  if (!type) {
    return <Redirect to={{ pathname: DEFAULT_PATHNAME }} />
  }

  return <TabBar tabs={TABS} className="activities-tabs" onChange={onChange} type={type} />
}

export { ActivityTabs }
