import React from 'react'
import { Dialog, FlatButton } from 'material-ui'
import { DialogTitle, toggleFiltersSnackbar } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import { Profile } from '../../../Profile'

import './AccountSettingsDialog.css'

class AccountSettingsDialog extends React.Component {
  componentDidMount() {
    this.props.onRefreshUser()
  }

  onCancel = () => {
    this.props.onCancelProfileUpdate()

    const { onHideDialog, dialogType } = this.props
    onHideDialog(dialogType)
  }

  onSave = () => {
    const { settings } = this.props.profile
    if (!settings.hasChanged) {
      return
    }

    this.props.onProfileUpdate(settings.form, settings.form.id)
    this.handlePreferencesUpdate()
  }

  handlePreferencesUpdate = () => {
    const { settings } = this.props.profile
    if (settings.preferencesForm && settings.preferencesForm.hasOwnProperty('showFiltersSnackbar')) {
      toggleFiltersSnackbar(!!settings.preferencesForm.showFiltersSnackbar)
    }
  }

  render() {
    const { dialog, dialogType } = this.props
    const { open } = dialog[dialogType] || {}
    const { settings } = this.props.profile

    const title = <DialogTitle id="ACCOUNT_SETTINGS" defaultMessage="Account settings" />

    const actions = [
      <FlatButton
        key="cancel-button"
        className="cancel-button"
        label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
        primary
        onTouchTap={this.onCancel} />,
      <FlatButton
        key="save-button"
        label={<FormattedMessage id="SAVE" defaultMessage="Save" />}
        onTouchTap={this.onSave}
        disabled={!settings.hasChanged}
        primary />
    ]

    return (
      <div className="account-settings-dialog">
        <Dialog
          title={title}
          modal={false}
          open={!!open}
          actions={actions}
          className="dialog-modal account-settings"
          contentClassName="account-settings-content"
          actionsContainerClassName="actions-container"
          autoDetectWindowHeight={false}
          contentStyle={{ maxWidth: '650px' }}
          onRequestClose={this.onCancel}>
          <div className="account-settings-container">
            <Profile {...this.props} onSave={this.onSave} onCancel={this.onCancel} />
          </div>
        </Dialog>
      </div>
    )
  }
}

export { AccountSettingsDialog }
