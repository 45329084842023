import React from 'react'
import { FormattedMessage } from 'react-intl'
import IconButton from 'material-ui/IconButton'
import { DeleteIcon, MediaGallery, ListFooterStats } from 'skylight-common'
import ModifyIcon from 'material-ui/svg-icons/content/create'
import DownloadIcon from 'material-ui/svg-icons/file/file-download'
import { MediaAttachments, DecisionAttachments, TextInputAttachments } from '../../../../containers/assignment'
import { listMediaItems } from './attachment-utils'
import { withDownloadSnackbar } from '../../Media/withDownloadSnackbar'
import './AssignmentAttachments.css'

class BaseAssignmentAttachments extends React.Component {
  onDelete = (items) => {
    this.props.onConfirm(
      () => this.props.onAssignmentAttachmentDeleteItems(items.map(x => x.id)),
      { message: 'DELETE_ATTACHMENT_MSG' }
    )
  }

  onSelectItems = (ids) => {
    if (ids.length === 0) return this.props.onAssignmentAttachmentSelectItems(ids)
    const { items } = this.props.attachment.list
    const selectedItems = ids.map(id => items.find(x => x.id === id))
    const lastSelected = selectedItems[selectedItems.length - 1]
    if (lastSelected.cardType !== 'media') {
      return this.props.onAssignmentAttachmentSelectItems([lastSelected.id])
    }

    const selectedMediaItems = selectedItems.filter(x => x.cardType === 'media')
    this.props.onAssignmentAttachmentSelectItems(selectedMediaItems.map(x => x.id))
  }

  getSelectedItems = () => {
    const { selectedIds, items } = this.props.attachment.list
    const selectedItems = selectedIds.map(id => items.find(x => x.id === id))
    return selectedItems
  }

  onDeleteItems = () => {
    this.onDelete(this.getSelectedItems())
  }

  onDownloadItems = () => {
    const items = this.getSelectedItems()
    this.props.onDownload(items, this.props.onDownloadAttachment)
  }

  getFooterActions = (selectedItems, isMediaOnlySelected) => {
    if (!isMediaOnlySelected) return null
    const actions = [
      <IconButton
        key="delete"
        tooltipPosition="top-left"
        tooltip={<FormattedMessage id="ACTION_DELETE" defaultMessage="Delete" />}
        onTouchTap={this.onDeleteItems}>
        <DeleteIcon />
      </IconButton>,
      <IconButton
        key="download"
        tooltipPosition="top-left"
        tooltip={<FormattedMessage id="ACTION_DOWNLOAD" defaultMessage="Download" />}
        className="skylight-icon"
        onTouchTap={this.onDownloadItems}>
        <DownloadIcon />
      </IconButton>
    ]

    if (selectedItems.length === 1) {
      const item = this.props.attachment.item
      actions.unshift(<IconButton
        key="edit"
        tooltipPosition="top-left"
        tooltip={<FormattedMessage id="ACTION_MODIFY" defaultMessage="Modify" />}
        className="skylight-icon"
        disabled={item.mode === 'edit'}
        onTouchTap={() => this.props.onAssignmentAttachmentChangeMode('edit', item.item)}>
        <ModifyIcon />
      </IconButton>)
    }

    return actions
  }

  render() {
    const { props } = this
    const { selectedIds, items, previewId, query } = props.attachment.list
    const list = props.attachment.list
    const media = items.filter(x => x.cardType === 'media' && x.mediaType === null)
    let mediaGalleryItems = []
    if (previewId) {
      const previewItem = items.find(x => x.id === previewId)
      if (previewItem) {
        if (previewItem.mediaType === null) {
          mediaGalleryItems = media
        } else {
          mediaGalleryItems = items.filter(x =>
            x.cardType === 'media' &&
            x.card.id === previewItem.card.id &&
            x.card.sequenceId === previewItem.card.sequenceId)
        }

        mediaGalleryItems = listMediaItems(mediaGalleryItems, query)
      }
    }
    const selectedItems = selectedIds.map(id => items.find(x => x.id === id))
    const isMediaOnlySelected = selectedIds.length > 0 &&
      !selectedItems.some(x => x.cardType !== 'media') // selectedIds.filter(id => media.some(x => x.id === id)).length > 0
    const actions = this.getFooterActions(selectedItems, isMediaOnlySelected)

    return (
      <div className="assignment-attachments">
        {previewId && <MediaGallery
          items={mediaGalleryItems}
          list={list}
          currentId={previewId}
          onPreviewMediaItem={props.onAssignmentAttachmentPreviewItem}
          onShowError={() => {}}
          onEdit={(item) => this.props.onAssignmentAttachmentChangeMode('edit', item.item)}
          onRemove={this.onDelete}
          onDownloadMediaItem={props.onDownloadAttachment}
          onDeselectMediaItems={() => {}}
          onMediaSelectItems={this.onSelectItems} />}
        <div className="assignment-attachments-view">
          <div className="assignment-attachments-title">
            <FormattedMessage id="CAPTURED_RESULTS" defaultMessage="Captured results" />
          </div>
          <MediaAttachments />
          <DecisionAttachments />
          <TextInputAttachments />
          {isMediaOnlySelected &&
          <ListFooterStats
            list={list}
            actions={actions}
            onUnselect={this.onSelectItems} />}
        </div>
      </div>
    )
  }
}

const AssignmentAttachments = withDownloadSnackbar(BaseAssignmentAttachments)
export { AssignmentAttachments }
