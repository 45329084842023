import React from 'react'
import { CurrentUserMenu } from '../../CurrentUserMenu'
import { TopBarPopoverButton } from './TopBarPopoverButton'
import { UserAvatar } from '../../UserAvatar'

import './CurrentUserButton.css'

const CurrentUserButton = props => {
  return (
    <TopBarPopoverButton
      className="current-user-button"
      openOnHover
      popoverBody={<CurrentUserMenu {...props} className="top-bar-popover-menu" />}>
      <UserAvatar user={props.auth.user} />
    </TopBarPopoverButton>
  )
}

export { CurrentUserButton }
