import React from 'react'
import { ByList } from 'skylight-common'
import './ByMedia.css'

const OPTIONS = [
  { text: 'TYPE_IMAGE', value: 'image' },
  { text: 'TYPE_VIDEO', value: 'video' },
  { text: 'TYPE_AUDIO', value: 'audio' }
]

const getOptions = () => Promise.resolve(OPTIONS)

const ByMedia = props => {
  return (
    <ByList
      className="by-media"
      title="MEDIA_TYPE"
      value={props.value}
      enableSorting={false}
      renderFilter={() => null}
      onChange={props.onChange}
      onRemove={props.onRemove}
      onGetItems={getOptions} />
  )
}

export { ByMedia }
