import React from 'react'

export class Sound extends React.Component {
  audio = null

  componentDidMount() {
    if (this.props.isPrevented) {
      return
    }

    this.audio = new Audio(this.props.source)
    this.audio.loop = this.props.loop
    this.audio.play()
  }

  componentWillUnmount() {
    if (this.props.isPrevented) {
      return
    }

    this.audio.pause()
    this.audio = null
  }

  render() {
    return null
  }
}

Sound.defaultProps = {
  loop: true
}
