import skylight from 'skylight-js-sdk'
import { getUserTitle } from 'skylight-common'
import { getRoles } from './roles'
import { getAllSessions } from './sessions'
import { PARTICIPANT_TYPES } from '../../lib/application/participant'

const sortingFieldBuilders = {
  name: item => {
    if (item.type === 'user') {
      return `${getUserTitle(item)}`.toLowerCase()
    }

    return item.name[0].toLowerCase()
  }
}

const withSortingValues = item => {
  const sortingValues = Object.keys(sortingFieldBuilders).reduce((acc, field) => {
    acc[`${field}SortingValue`] = sortingFieldBuilders[field](item)
    return acc
  }, {})

  return {
    ...item,
    ...sortingValues
  }
}

export const getAllParticipants = async applicationId => {
  const roles = await getRoles(applicationId)
  const participants = await getParticipantsFromRoles(roles.data)
  return participants.data
}

export const getAllSessionsParticipants = async applicationId => {
  const allSessions = await getAllSessions(applicationId)
  const parts = await getParticipantsFromSessions(allSessions)
  return parts
}

export const getParticipantsFromSessions = async sessions => {
  const participantsById = sessions.reduce((acc, session) => {
    session.participants.forEach(participant => {
      if (!acc[participant.id]) {
        acc[participant.id] = {
          ...participant
        }
      }
    })

    return acc
  }, {})

  return Promise.all(Object.values(participantsById).map(async participant => {
    const participantInfo = await getParticipantInfo(participant)
    return {
      ...participant,
      ...(participantInfo || {})
    }
  }))
}

export const getParticipantsFromRoles = async(roles) => {
  const participantsById = roles.reduce((acc, role) => {
    role.participants.forEach(participant => {
      if (acc[participant.id]) {
        acc[participant.id].roles.push(role.roleId)
        return acc
      }

      acc[participant.id] = {
        ...participant,
        roles: [role.roleId]
      }

      return acc
    })

    return acc
  }, {})

  const participants = await Promise.all(Object.values(participantsById).map(async participant => {
    let participantInfo = null
    try {
      participantInfo = await getParticipantInfo(participant)
    } catch (e) {
      console.error('error getting info about participant')
    }

    return withSortingValues({
      ...participant,
      ...participantInfo
    })
  }))

  return {
    data: participants,
    page: { nextCursor: null }
  }
}

const getParticipantInfo = async participant => {
  const groupsList = await skylight.group.list()
  const groupsById = groupsList.reduce((acc, group) => {
    acc[group.id] = group
    return acc
  }, {})

  switch (participant.type) {
    case PARTICIPANT_TYPES.USER: {
      const userInfo = await skylight.user.getUserFromCacheById(participant.id)
      return userInfo || skylight.user.getById(participant.id)
    }
    case PARTICIPANT_TYPES.GROUP:
      return groupsById[participant.id] || { name: 'Anonymous' }
    default:
      return {}
  }
}
