import React from 'react'
import { Column, UserTitle, FormattedDateTime } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import Checkbox from 'material-ui/Checkbox'

const getRender = (prop) => {
  switch (prop.type) {
    case 'date':
      return (item) => <FormattedDateTime value={new Date(item[prop.field])} />
    case 'user':
      return (item) => <UserTitle user={item[prop.field]} />
    case 'render':
      return prop.render
    case 'string':
      return (item) => <div>{item[prop.field]}</div>
    case 'boolean':
      return (item) => <Checkbox
        disabled checked={!!item[prop.field]}
        checkedIcon={prop.checkedIcon} uncheckedIcon={prop.uncheckedIcon} />
    default:
      return (item) => null
  }
}

const ValueForProp = ({ prop, item }) => {
  return getRender(prop)(item)
}

const ColumnFromProp = (prop, props) => {
  const render = getRender(prop, props)

  return <Column
    key={prop.field}
    name={prop.field}
    isVisible={prop.hasOwnProperty('isVisible') ? prop.isVisible : true}
    title={<FormattedMessage id={prop.title} defaultMessage={prop.title} />}
    render={prop.render && prop.editable ? render(props) : render} />
}

export { ColumnFromProp, ValueForProp }
