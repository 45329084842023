import skylight from 'skylight-js-sdk'

const getTime = date => new Date(date).getTime()

export const getWorkflows = async query => {
  const { name, title, createdBy, createdAt } = query.filter
  const byTitle = w => !title || (w.name && w.name.toLowerCase().includes(title.trim().toLowerCase()))
  const byName = w => !name || !name.length || name.some(x => w.workflowId === x)
  const byAssignedBy = w => !createdBy || !createdBy.length || createdBy.includes(w.createdBy)

  const createdFromTime = getTime((createdAt || {}).from || 0)
  const createdToTime = getTime((createdAt || {}).to || 8640000000000000)
  const byCreatedAt = w => !createdAt || (
    getTime(w.createdAt) >= createdFromTime && getTime(w.createdAt) <= createdToTime)

  const workflowQuery = {
    limit: query.limit,
    sort: `${query.sortAsc ? '' : '-'}${query.sortBy}`
  }

  if (query.nextCursor) {
    workflowQuery.nextCursor = query.nextCursor
  }

  const workflows = await skylight.workflow.v2.list(workflowQuery)

  return {
    data: workflows.data
      .filter(x => x.workflowId)
      .map(x => ({ ...x, id: x.workflowId }))
      .filter(w => byTitle(w) && byName(w) && byAssignedBy(w) && byCreatedAt(w)),
    page: workflows.page
  }
}
