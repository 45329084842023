import React from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'

import './OutlinedField.css'

const OutlinedField = props => {
  const renderUnderlineText = () => {
    const { error } = props

    if (!error) {
      return null
    }

    if (React.isValidElement(error)) {
      return error
    }

    if (error === 'FORM_FIELD_REQUIRED_ERROR') {
      return <FormattedMessage id={error} defaultMessage="Invalid value" values={{ field: '' }} />
    }

    if (typeof error === 'string') {
      return <FormattedMessage id={error} defaultMessage="Invalid value" />
    }

    return <FormattedMessage id={error.id} defaultMessage={error.defaultMessage} />
  }

  return <div
    onMouseOver={props.onMouseOver}
    onMouseLeave={props.onMouseLeave}
    className={classNames('outlined-field', props.className, {
      focused: props.focused,
      disabled: props.disabled,
      readonly: props.readonly,
      error: props.error
    })}>
    <div className="outlined-field-container">
      <div className="outlined-field-body">
        <fieldset aria-hidden="true">
          {props.children}
          <legend>
            {props.floatingLabelText}
            {props.required && <span className="required">*</span>}
          </legend>
        </fieldset>
        <div className="underline-text">
          {renderUnderlineText()}
        </div>
      </div>
    </div>
  </div>
}

export { OutlinedField }
