import React from 'react'
import { TextFilter } from 'skylight-common'
import { TeamGroupFilter } from '../../../../containers/users'

const VALUE = 'title'

const GroupTextFilter = props => {
  const onChange = (v) => {
    const filter = { ...props.query.filter, [VALUE]: v || '' }
    props.onGroupFilterUpdated(filter)
  }

  return <TextFilter
    className="group-text-filter"
    title="PLACEHOLDER_GROUPNAME"
    value={props.query.filter[VALUE]}
    iconFilter={<TeamGroupFilter />}
    onChange={onChange} />
}

export { GroupTextFilter }
