import React, { Component } from 'react'
import classNames from 'classnames'
import { MenuPicker } from 'skylight-common'
import ArrowExpandDown from '../../Common/Icons/arrowExpandDown'
import { SelectedItems } from './SelectedItems'

import './ItemsPicker.css'

class ItemsPicker extends Component {
  renderSelection = () => {
    const { placeholder, selectedItems, renderItem, itemIcon } = this.props
    if (!selectedItems.length) {
      return placeholder || null
    }

    return <SelectedItems renderItem={renderItem} itemIcon={itemIcon} items={selectedItems} />
  }

  render() {
    const {
      isLoading, items, selectedItems, translations,
      footer, disabled, className, onChange, searchDisabled, anchorOrigin,
      iconButton
    } = this.props

    return (
      <div className={classNames('items-picker', { disabled })} onTouchTap={e => e.stopPropagation()}>
        <MenuPicker
          anchorOrigin={anchorOrigin}
          className={classNames(className)}
          iconButton={iconButton || <ArrowExpandDown className="items-picker-arrow" />}
          openImmediately={false}
          isLoading={isLoading}
          items={items}
          selected={selectedItems}
          selectionRenderer={this.renderSelection}
          valueProp="name"
          translations={translations}
          onSelect={onChange}
          footer={footer}
          searchDisabled={searchDisabled}
          forceFixedDropdownPosition={false} />
      </div>
    )
  }
}

export { ItemsPicker }
