const QUOTE_REGEX = /"/g
const DOUBLE_QUOTE = '""'

const enquote = v => {
  if (!v) return ''

  if (typeof v === 'string' && (v.indexOf(',') > -1 || v.indexOf('"') > -1)) {
    return '"' + v.replace(QUOTE_REGEX, DOUBLE_QUOTE) + '"'
  }

  return v
}

export const json2csv = (objArray, colsToSkip) => {
  // inspect all objects to get all possible keys
  const getKeys = (objArray) => {
    const keys = []

    for (let i = 0; i < objArray.length; i++) {
      for (const key in objArray[i]) {
        if ((colsToSkip && !colsToSkip.includes(key)) && !keys.includes(key)) {
          keys.push(key)
        }
      }
    }

    return keys.sort()
  }

  objArray = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
  const keys = getKeys(objArray)
  let str = ''

  for (let i = 0; i < objArray.length; i++) {
    let line = ''
    const obj = objArray[i]

    keys.forEach((key, ki) => {
      line += enquote(obj[key])
      if (ki !== keys.length - 1) line += ','
    })

    str += line + '\r\n'
  }

  str = keys.join() + '\r\n' + str

  return str
}
