import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const DeleteIcon = (props) => {
  return <SvgIcon className="snapshot-icon" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M15 2V4H19V6H5V4H9V2H15ZM16 21H8C6.9 21 6 20.1 6 19V7H18V19C18 20.1 17.1 21 16 21ZM9 11H11V17H9V11ZM15 11H13V17H15V11Z"/>
  </SvgIcon>
}

export default DeleteIcon
