import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ClientAppLogin } from './ClientAppLogin'
import { TileList } from './TileList'
import './HomePage.css'

const HomePage = (props) => {
  const toLogin = () => {
    props.onShowError('CLIENT_APP_RELOGIN', { className: 'client-signin-error' })
    props.onClientAppLogin()
  }

  return (
    <div className="home-page-legacy">
      <div className="title-messages">
        <span className="welcome">
          <FormattedMessage
            id="SKYLIGHT_WELCOME"
            defaultMessage="Welcome to Skylight Web" />
        </span>
        <span className="tagline">
          <FormattedMessage
            id="CONNECT_WORKFORCE"
            defaultMessage="Connect your workforce" />
        </span>
      </div>
      <ClientAppLogin token={props.auth.ssoToken} onLaunchApp={props.onStartAndroid} toLogin={toLogin} />
      <TileList {...props} />
    </div>
  )
}

export { HomePage }
