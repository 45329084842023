import { VERSION_STAGES } from './application/version'

export const SETUP_NEW_APP_TEMPLATE_NAMES = {
  BLANK_APP: 'BLANK_APP',
  IMPORT_FILE: 'IMPORT_FILE'
}

export const APPLICATION_STATUSES = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  IMPORT: 'import'
}

export const isNextGenUrl = () => {
  return window.location.href.indexOf('/ng') > 0
}

export const getApplicationCurrentVersion = application => {
  if (application.draftVersion > application.publishedVersion) {
    return { version: application.draftVersion, stage: VERSION_STAGES.DRAFT }
  }

  return { version: application.publishedVersion, stage: VERSION_STAGES.PUBLISHED }
}
