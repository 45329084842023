import { connect } from 'react-redux'
import mediaActions from '../actions/media'
import errorActions from '../actions/error'
import contactActions from '../actions/contact'
import dialogActions from '../actions/dialog'
import { MediaList as MediaListComp } from '../App/Home/Media/MediaList/MediaList'
import {
  MediaTextFilter as MediaTextFilterComp,
  MediaDetails as MediaDetailsComp,
  MediaActionMenu as MediaActionMenuComp,
  MediaActionButtons as MediaActionButtonsComp
} from 'skylight-common'

const mediaDetails = (state, props) => ({
  item: state.media.item
})

const mediaList = (state, props) => ({
  list: state.media.list,
  item: state.media.item,
  user: state.auth.user,
  scopes: state.auth.scopes
})

const mediaDetailsActions = {
  ...mediaActions,
  ...dialogActions,
  ...errorActions
}

const mediaListActions = {
  ...mediaDetailsActions,
  ...errorActions,
  onUserSelectItems: contactActions.onUserSelectItems
}

const mediaFilter = (state, props) => ({
  query: state.media.list.query,
  layout: state.layout,
  authUser: state.auth.user
})

const mediaFilterActions = {
  ...mediaActions,
  onUserSelectItems: contactActions.onUserSelectItems
}

const MediaDetails = connect(mediaDetails, mediaDetailsActions)(MediaDetailsComp)
const MediaActionMenu = connect(mediaDetails, mediaDetailsActions)(MediaActionMenuComp)
const MediaActionButtons = connect(mediaDetails, mediaDetailsActions)(MediaActionButtonsComp)
const MediaList = connect(mediaList, mediaListActions)(MediaListComp)
const MediaTextFilter = connect(mediaFilter, mediaFilterActions)(MediaTextFilterComp)

export { MediaDetails, MediaActionMenu, MediaActionButtons }
export { MediaList }
export { MediaTextFilter }
