import React from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import RaisedButton from 'material-ui/RaisedButton'
import SettingIcon from 'material-ui/svg-icons/action/settings'

import './QueryFilterPicker.css'

const QueryFilterPicker = ({ showQueryFilterDialog, error }) => {
  return (
    <div className="query-filter-picker">
      <RaisedButton
        primary
        className={classNames('query-filter-picker-button', { 'filters-error': error })}
        onTouchTap={showQueryFilterDialog}>
        <SettingIcon className="query-filter-picker-icon" />
        <FormattedMessage
          className="query-filter-picker-text"
          id="LDAP_CONFIGURE"
          defaultMessage="Configure" />
      </RaisedButton>
      {error &&
        <FormattedMessage id={error} defaultMessage="Invalid filters">
          {(content) => <div className="query-filter-picker__error">{content}</div>}
        </FormattedMessage>
      }
    </div>
  )
}

export { QueryFilterPicker }
