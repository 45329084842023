import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const lockAlert = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M12,1A5,5 0 0,0 7,6V8H6A2,2 0 0,0 4,10V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V10A2,2 0 0,0 18,8H17V6A5,5 0 0,0 12,1M12,2.9C13.71,2.9 15.1,4.29 15.1,6V8H8.9V6C8.9,4.29 10.29,2.9 12,2.9M11,11H13V15H11V11M11,17H13V19H11V17Z" />
    </SvgIcon>
  )
}

export default lockAlert
