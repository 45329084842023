export const SUPPORTED_LOCALES = [
  { title: 'English', value: 'en' },
  { title: 'Spanish', value: 'es' },
  { title: 'German', value: 'de' },
  { title: 'French', value: 'fr' },
  { title: 'Portuguese', value: 'pt' },
  { title: 'Italian', value: 'it' },
  { title: 'Chinese', value: 'zh' },
  { title: 'Japanese', value: 'ja' },
  { title: 'Spanish - US', value: 'es-419' }
]
