import * as ActionTypes from '../../actions/types'
import { createItemReducer } from 'skylight-common'

const baseItem = createItemReducer({
  actionPrefix: ActionTypes.PREFIX_ASSIGNMENT
})

const item = (state, payload) => {
  state = baseItem(state, payload)
  switch (payload.type) {
    case ActionTypes.ASSIGNMENT_WORKFLOW_ASSIGN:
      return { ...state, mode: 'new', user: payload.user || state.user }
    case ActionTypes.ASSIGNMENT_WORKFLOW_ASSIGNING:
      return { ...state, isAssigning: true }
    case ActionTypes.ASSIGNMENT_WORKFLOW_ASSIGN_SUCCESS:
    case ActionTypes.ASSIGNMENT_WORKFLOW_CANCEL_ASSIGN:
    case ActionTypes.ASSIGNMENT_WORKFLOW_ASSIGN_ERROR:
      return { ...state, mode: 'view', user: null, isAssigning: false }
    default:
      return state
  }
}

export default item
