import React from 'react'
import { get } from 'lodash'
import skylight from 'skylight-js-sdk'
import { FormattedMessage } from 'react-intl'
import { OutlinedTextField } from '../../Common/Form/OutlinedTextField'
import LoginFormButton from '../LoginFormButton/LoginFormButton'
import './RealmSignin.css'

class RealmSignin extends React.Component {
  componentDidMount() {
    const urlSearch = new URLSearchParams(location.search)
    const from = get(this.props.location, 'state.from', null)
    if (from !== null && !from.pathname.startsWith('/logout')) {
      const stateSearch = new URLSearchParams(from.search)
      if (skylight.utils.isFrontline() && stateSearch.has('realm')) {
        stateSearch.set('sso', true)
        stateSearch.append('redirectUrl', from.pathname)
        window.location.search = stateSearch.toString()
        return
      }
    }
    if (urlSearch.has('realm')) {
      this.props.onRealmSignin(urlSearch.get('realm'))
    }
  }

  onSignIn = (e) => {
    e && e.preventDefault()
    this.props.onRealmSignin(this.props.login.realm.realm)
  }

  onChange = (e, v) => {
    this.props.onRealmChange(v)
  }

  onAutofill = (e) => {
    const val = e.target.value
    this.props.handleAutofill(val, this.props.login.realm.realm, v => this.onChange(null, v))
  }

  render() {
    return (
      <form className="realm-signin" onSubmit={this.onSignIn}>
        <OutlinedTextField
          required
          autoFocus
          floatingLabelText={<FormattedMessage id="REALM" defaultMessage="Realm" />}
          name="realm"
          id="realm"
          key="realm"
          className="realm-signin__text-field"
          error={this.props.login.realm.realmError
            ? <FormattedMessage id={this.props.login.realm.realmError} defaultMessage="Error" />
            : null}
          value={this.props.login.realm.realm}
          onBlur={this.onAutofill}
          onChange={this.onChange} />
        <LoginFormButton
          className="realm-signin__btn"
          label={<FormattedMessage id="CONTINUE" defaultMessage="Continue" />}
          type="submit" />
      </form>
    )
  }
}

export { RealmSignin }
