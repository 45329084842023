import React from 'react'
import { Switch, Route } from 'react-router'
import { AssignmentList, AssignmentAttachments } from '../../../containers/assignment'
import { AssignmentTabs } from './AssignmentTabs/AssignmentTabs'
import SecurityTrimmer from '../../../containers/SecurityTrimmer'
import { ASSIGNMENT } from '../../../svc/scopes'
import { allColumns } from './AssignmentList/listColumns'

export const Assignments = (props) => {
  const userColumns = allColumns.filter(
    (c) => !['description', 'assignedTo'].includes(c.name)
  )
  const userHeading = { id: 'ASSIGNMENT_LIST', default: 'My assignments' }

  return (
    <Switch>
      <Route
        path={`${props.match.url}/attachments/:id`}
        component={AssignmentAttachments} />
      <Route
        path={`${props.match.url}/:type?`}
        render={p => (
          <div className="assignment-wrapper">
            <SecurityTrimmer inverted={true} scopes={[ASSIGNMENT.READ]}>
              <AssignmentList
                {...props}
                columns={userColumns}
                type="my"
                heading={userHeading} />
            </SecurityTrimmer>
            <SecurityTrimmer scopes={[ASSIGNMENT.READ]}>
              <AssignmentTabs
                {...props}
                columns={allColumns}
                type={p.match.params.type || 'team'}
                heading={userHeading} />
            </SecurityTrimmer>
          </div>
        )} />
    </Switch>
  )
}
