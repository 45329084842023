import { connect } from 'react-redux'

import contactActions from '../actions/contact'
import dialogActions from '../actions/dialog'
import appActions from '../actions/app'

import {
  UserDetails as UserDetailsComp,
  UserActionButtons as UserActionButtonsComp,
  DetailsMenu as DetailsMenuComp
} from '../App/Home/Contacts/UserDetails'
import { UserList as TeamListComp } from '../App/Home/Team/TeamTabs/UserList'
import {
  UserFilter as TeamFilterComp,
  UserTextFilter as TeamTextFilterComp
} from '../App/Home/Team/TeamTabs/UserFilter'

import { UserDetailsMenu as TeamDetailsMenuComp } from '../App/Home/Team/TeamTabs/UserDetailsMenu/UserDetailsMenu'

import { GroupList as TeamGroupListComp } from '../App/Home/Contacts/GroupList/GroupList'
import {
  GroupFilter as TeamGroupFilterComp,
  GroupTextFilter as TeamGroupTextFilterComp
} from '../App/Home/Contacts/GroupFilter'
import {
  GroupDetailTabs as GroupDetailTabsComp,
  GroupActionButtons as GroupActionButtonsComp
} from '../App/Home/Contacts/GroupDetails'

import {
  UserDetails as UserDetailsNextGenComp,
  UserActionButtons as UserActionButtonsNextGenComp,
  DetailsMenu as DetailsMenuNextGenComp
} from '../App/Home/HomeNextGen/Users/UserDetails'

const userDetails = (state, props) => ({
  user: state.contact.user,
  scopes: state.auth.scopes,
  groups: state.contact.groups
})

const userList = (state, props) => ({
  users: state.contact.users,
  groups: state.contact.groups,
  scopes: state.auth.scopes
})

const userDetailsActions = {
  ...contactActions,
  ...dialogActions,
  onCloseDetails: appActions.onCloseDetails
}

const groups = (state, props) => ({
  groups: state.contact.groups,
  auth: state.auth
})

const groupsActions = {
  ...contactActions,
  onConfirm: dialogActions.onConfirm
}

const group = (state, props) => ({
  group: state.contact.group,
  groupMembers: state.contact.groupMembers,
  scopes: state.auth.scopes
})

const teamFilter = (state) => ({
  query: state.contact.users.query,
  layout: state.layout,
  authUser: state.auth.user
})

const teamGroupFilter = (state) => ({
  query: state.contact.groups.query,
  layout: state.layout,
  authUser: state.auth.user
})

const listActions = userDetailsActions

const UserDetails = connect(userDetails, userDetailsActions)(UserDetailsComp)
const UserActionButtons = connect(userDetails, userDetailsActions)(UserActionButtonsComp)
const UserDetailsMenu = connect(userDetails, userDetailsActions)(DetailsMenuComp)

const UserDetailsNextGen = connect(userDetails, userDetailsActions)(UserDetailsNextGenComp)
const UserActionButtonsNextGen = connect(userDetails, userDetailsActions)(UserActionButtonsNextGenComp)
const UserDetailsMenuNextGen = connect(userDetails, userDetailsActions)(DetailsMenuNextGenComp)

const TeamDetailsMenu = connect(userDetails, userDetailsActions)(TeamDetailsMenuComp)
const TeamList = connect(userList, listActions)(TeamListComp)
const TeamFilter = connect(teamFilter, contactActions)(TeamFilterComp)
const TeamTextFilter = connect(teamFilter, contactActions)(TeamTextFilterComp)

const TeamGroupList = connect(groups, groupsActions)(TeamGroupListComp)
const TeamGroupFilter = connect(teamGroupFilter, groupsActions)(TeamGroupFilterComp)
const TeamGroupTextFilter = connect(teamGroupFilter, groupsActions)(TeamGroupTextFilterComp)

const GroupDetailTabs = connect(group, groupsActions)(GroupDetailTabsComp)
const GroupActionButtons = connect(group, groupsActions)(GroupActionButtonsComp)

export {
  UserDetails, UserActionButtons, UserDetailsMenu,
  UserDetailsNextGen, UserActionButtonsNextGen, UserDetailsMenuNextGen,
  TeamList, TeamDetailsMenu, TeamFilter,
  TeamTextFilter,
  TeamGroupList, TeamGroupFilter,
  TeamGroupTextFilter,
  GroupDetailTabs, GroupActionButtons
}
