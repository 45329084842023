import { connect } from 'react-redux'

import dialogActions from '../actions/dialog'

import { Dialogs as DialogsComp } from '../App/Home/Layout/Dialogs'

const dialogs = (state, props) => ({
  dialog: state.dialog
})

const actions = dialogActions

const Dialogs = connect(dialogs, actions)(DialogsComp)

export { Dialogs }
