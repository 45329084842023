import React from 'react'
import { NameDialog } from 'skylight-common'

export const DuplicateAppDialog = props => {
  const { dialogType } = props

  const title = {
    id: 'NAME_OF_COPY',
    defaultMessage: 'Name of Copy'
  }
  const description = {
    id: 'ENTER_APP_COPY_NAME',
    defaultMessage: 'Enter a name for the application copy'
  }

  return (
    <NameDialog
      {...props}
      id={dialogType}
      title={title}
      description={description}
      onSave={props.onDuplicateApplication}/>
  )
}
