import React from 'react'
import { GroupActionMenu } from './GroupActionMenu'
import { isPhone } from 'skylight-common'
import { FooterActionButtons } from '../../../Common'
import './GroupActionButtons.css'

const GroupActionButtons = (props) => {
  const group = props.group
  const { mode, hasChanged } = group

  if (mode === 'view') {
    return null
  }

  const onCancel = (e) => {
    e.stopPropagation()
    props.onGroupCancel()
  }

  const onSave = () => {
    props.onGroupSave(group.item)
  }

  return (
    <div className={`group-action-buttons ${hasChanged ? 'updated' : ''}`}>
    {isPhone() && <GroupActionMenu {...props} />}
      {hasChanged && <FooterActionButtons
        isCancelDisabled={group.isLoading}
        isSubmitDisabled={group.isLoading || !group.hasChanged}
        onCancel={onCancel}
        onSave={onSave} />}
    </div>
  )
}

export { GroupActionButtons }
