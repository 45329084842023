import React from 'react'
import { ApplicationList } from '../../../containers/applications'

const Applications = (props) => {
  return (
    <div className="applications">
      <ApplicationList />
    </div>
  )
}

export { Applications }
