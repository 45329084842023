export const NOT_IMPLEMENTED = 'NOT_IMPLEMENTED'

export const APP_INIT = 'INIT'
export const APP_NATIVE_USER_EVENT = 'APP_NATIVE_USER_EVENT'
export const APP_VERSIONS_LOAD = 'APP_VERSIONS_LOAD'
export const APP_VERSIONS_LOADED = 'APP_VERSIONS_LOADED'
export const APP_VERSIONS_ERROR = 'APP_VERSIONS_ERROR'

export const APP_LOCALE_LOAD = 'APP_LOCALE_LOAD'
export const APP_LOCALE_LOADED = 'APP_LOCALE_LOADED'
export const APP_LOCALE_LOAD_ERROR = 'APP_LOCALE_LOAD_ERROR'

export const SIGNIN_USERNAME_CHANGED = 'SIGNIN_USERNAME_CHANGED'
export const SIGNIN_PASSWORD_CHANGED = 'SIGNIN_PASSWORD_CHANGED'
export const SIGNIN_REALM_CHANGED = 'SIGNIN_REALM_CHANGED'
export const SIGNIN_STAY_SIGNED_CHANGED = 'SIGNIN_STAY_SIGNED_CHANGED'
export const SIGNIN_SIGNIN = 'SIGNIN_SIGNIN'
export const SIGNIN_ERROR = 'SIGNIN_ERROR'
export const SIGNIN_ERRORS_CLEAR = 'SIGNIN_ERRORS_CLEAR'
export const SIGNIN_REALM_ERROR = 'SIGNIN_REALM_ERROR'
export const SIGNIN_REALM_SIGNIN = 'SIGNIN_REALM_SIGNIN'
export const SIGNIN_REALM_CONFIG = 'SIGNIN_REALM_CONFIG'
export const SIGNIN_IDP_TOKEN_LOADING = 'SIGNIN_IDP_TOKEN_LOADING'
export const SIGNIN_IDP_TOKEN_LOADED = 'SIGNIN_IDP_TOKEN_LOADED'
export const SIGNIN_IDP_TOKEN_ERROR = 'SIGNIN_IDP_TOKEN_ERROR'
export const AUTH_SIGNED_IN = 'AUTH_SIGNED_IN'
export const AUTH_SIGNIN_MISSING = 'AUTH_SIGNIN_MISSING'
export const AUTH_REFRESHING_TOKEN = 'AUTH_REFRESHING_TOKEN'
export const AUTH_REFRESH_USER_ERROR = 'AUTH_REFRESH_USER_ERROR'
export const AUTH_USER_REFRESHED = 'AUTH_USER_REFRESHED'
export const AUTH_REFRESH_TOKEN_SUCCESS = 'AUTH_REFRESH_TOKEN_SUCCESS'
export const AUTH_REFRESH_TOKEN_ERROR = 'AUTH_REFRESH_TOKEN_ERROR'
export const AUTH_EXPIRED = 'AUTH_EXPIRED'

export const PASSWORD_RESET_VALIDATION_ERROR_UPDATE = 'PASSWORD_RESET_VALIDATION_ERROR_UPDATE'
export const PASSWORD_RESET_TOKEN_INVALID_UPDATE = 'PASSWORD_RESET_TOKEN_INVALID_UPDATE'

export const USER_SIGNOUT = 'USER_SIGNOUT'
export const USER_SIGNOUT_START = 'USER_SIGNOUT_START'

export const ROUTER_LOCATION_CHANGE = '@@router/LOCATION_CHANGE'

export const PREFIX_LIST_GROUPS = 'LIST_GROUPS'
export const PREFIX_LIST_USERS = 'LIST_USERS'
export const PREFIX_LIST_MEDIA = 'LIST_MEDIA'
export const PREFIX_LIST_ACTIVITY = 'LIST_ACTIVITY'
export const PREFIX_LIST_ACTIVITY_CALL = 'LIST_ACTIVITY_CALL'
export const PREFIX_LIST_ACTIVITY_CALL_EVENT = 'LIST_ACTIVITY_CALL_EVENT'
export const PREFIX_LIST_ACTIVITY_CALL_SSRC_VIDEO_SEND = 'LIST_ACTIVITY_CALL_SSRC_VIDEO_SEND'
export const PREFIX_LIST_ACTIVITY_CALL_BWE_FOR_VIDEO = 'LIST_ACTIVITY_CALL_BWE_FOR_VIDEO'
export const PREFIX_LIST_ASSIGNMENTS = 'LIST_ASSIGNMENTS'
export const PREFIX_LIST_API_TOKENS = 'LIST_API_TOKENS'
export const PREFIX_LIST_DATA_APPS = 'LIST_DATA_APPS'
export const PREFIX_LIST_WORKFLOW = 'LIST_WORKFLOW'

export const HIDE_DETAILS = 'HIDE_DETAILS'
export const LIST_REMOVING = 'LIST_REMOVING'
export const LIST_REMOVED = 'LIST_REMOVED'
export const LIST_REMOVE_ERROR = 'LIST_REMOVE_ERROR'
export const LIST_CHANGE_VIEW = 'LIST_CHANGE_VIEW'
export const LIST_UPDATE = 'LIST_UPDATE'
export const LIST_UPDATE_QUERY = 'LIST_UPDATE_QUERY'
export const LIST_LOAD_QUERY = 'LOAD_QUERY'

export const DIALOG_SHOW = 'DIALOG_SHOW'
export const DIALOG_HIDE = 'DIALOG_HIDE'

export const CONTACT_LOAD_LIST = 'CONTACT_LOAD_LIST'
export const CONTACT_LIST_LOADED = 'CONTACT_LIST_LOADED'
export const CONTACT_LIST_LOAD_ERROR = 'CONTACT_LIST_LOAD_ERROR'
export const CONTACT_LIST_SELECTED = 'CONTACT_LIST_SELECTED'

export const USER_EDIT = 'USER_EDIT'
export const USER_EDIT_CANCEL = 'USER_EDIT_CANCEL'
export const USER_NEW = 'USER_NEW'
export const USER_PROP_CHANGE = 'USER_PROP_CHANGE'

export const USER_SAVING = 'USER_SAVING'
export const USER_SAVED = 'USER_SAVED'
export const USER_SAVE_ERROR = 'USER_SAVE_ERROR'
export const USER_SAVE_FIELD_ERROR = 'USER_SAVE_FIELD_ERROR'
export const USER_TEMP_PASSWORD_RESET_SUCCESS = 'USER_TEMP_PASSWORD_RESET_SUCCESS'
export const USER_TEMP_PASSWORD_RESET_ERROR = 'USER_TEMP_PASSWORD_RESET_ERROR'
export const USER_TEMP_PASSWORD_EXPIRED = 'USER_TEMP_PASSWORD_EXPIRED'

export const CONTACT_LOAD_GROUPS = 'CONTACT_LOAD_GROUPS'
export const CONTACT_GROUPS_LOADED = 'CONTACT_GROUPS_LOADED'
export const CONTACT_LOAD_GROUPS_ERROR = 'CONTACT_LOAD_GROUPS_ERROR'
export const CONTACT_GROUPS_SELECTED = 'CONTACT_GROUPS_SELECTED'

export const GROUPS_LOAD_LIST = 'GROUPS_LOAD_LIST'
export const GROUPS_LIST_LOADED = 'GROUPS_LIST_LOADED'
export const GROUPS_LIST_LOAD_ERROR = 'GROUPS_LIST_LOAD_ERROR'
export const GROUPS_LIST_SELECTED = 'GROUPS_LIST_SELECTED'
export const LIST_GROUPSLIST_HIDE_DETAILS = 'LIST_GROUPSLIST_HIDE_DETAILS'

export const DEVICE_LOAD_LIST = 'DEVICE_LOAD_LIST'
export const DEVICE_LIST_LOADED = 'DEVICE_LIST_LOADED'
export const DEVICE_LIST_LOAD_ERROR = 'DEVICE_LIST_LOAD_ERROR'
export const DEVICE_LIST_SELECTED = 'DEVICE_LIST_SELECTED'

export const DEVICE_TOKEN_LOAD = 'DEVICE_TOKEN_LOAD'
export const DEVICE_TOKEN_LOADED = 'DEVICE_TOKEN_LOADED'
export const DEVICE_TOKEN_LOAD_ERROR = 'DEVICE_TOKEN_LOAD_ERROR'
export const DEVICE_TOKEN_PREVIEW = 'DEVICE_TOKEN_PREVIEW'

export const DEVICES_REMOVE_START = 'DEVICES_REMOVE_START'
export const DEVICES_REMOVE_SUCCESS = 'DEVICES_REMOVE_SUCCESS'
export const DEVICES_REMOVE_ERROR = 'DEVICES_REMOVE_ERROR'

export const SESSION_EVENT_DEVICE_ADD = 'SESSION_EVENT_DEVICE_ADD'

export const ASSIGNMENT_LOAD_LIST = 'ASSIGNMENT_LOAD_LIST'
export const ASSIGNMENT_LIST_LOADED = 'ASSIGNMENT_LIST_LOADED'
export const ASSIGNMENT_LIST_LOAD_ERROR = 'ASSIGNMENT_LIST_LOAD_ERROR'
export const ASSIGNMENT_LIST_SELECTED = 'ASSIGNMENT_LIST_SELECTED'
export const ASSIGNMENT_WORKFLOW_ASSIGN = 'ASSIGNMENT_WORKFLOW_ASSIGN'
export const ASSIGNMENT_WORKFLOW_ASSIGNING = 'ASSIGNMENT_WORKFLOW_ASSIGNING'
export const ASSIGNMENT_WORKFLOW_ASSIGN_ERROR = 'ASSIGNMENT_WORKFLOW_ASSIGN_ERROR'
export const ASSIGNMENT_WORKFLOW_ASSIGN_SUCCESS = 'ASSIGNMENT_WORKFLOW_ASSIGN_SUCCESS'
export const ASSIGNMENT_WORKFLOW_CANCEL_ASSIGN = 'ASSIGNMENT_WORKFLOW_CANCEL_ASSIGN'
export const ASSIGNMENT_SET_PENDING_TOTAL = 'ASSIGNMENT_SET_PENDING_TOTAL'

export const ASSIGNMENT_WORKFLOW_UNASSIGNING = 'ASSIGNMENT_WORKFLOW_UNASSIGNING'
export const ASSIGNMENT_WORKFLOW_UNASSIGN_ERROR = 'ASSIGNMENT_WORKFLOW_UNASSIGN_ERROR'
export const ASSIGNMENT_WORKFLOW_UNASSIGN_SUCCESS = 'ASSIGNMENT_WORKFLOW_UNASSIGN_SUCCESS'

export const ASSIGNMENT_ATTACHMENT_LOAD = 'ASSIGNMENT_ATTACHMENT_LOAD'
export const ASSIGNMENT_ATTACHMENT_LOADED = 'ASSIGNMENT_ATTACHMENT_LOADED'
export const ASSIGNMENT_ATTACHMENT_LOAD_ERROR = 'ASSIGNMENT_ATTACHMENT_LOAD_ERROR'

export const ASSIGNMENT_WITH_ATTACHMENT_LOAD = 'ASSIGNMENT_WITH_ATTACHMENT_LOAD'
export const ASSIGNMENT_WITH_ATTACHMENT_LOADED = 'ASSIGNMENT_WITH_ATTACHMENT_LOADED'
export const ASSIGNMENT_WITH_ATTACHMENT_LOAD_ERROR = 'ASSIGNMENT_WITH_ATTACHMENT_LOAD_ERROR'

export const ASSIGNMENT_ATTACHMENT_SELECT = 'ASSIGNMENT_ATTACHMENT_SELECT'
export const ASSIGNMENT_ATTACHMENT_PREVIEW = 'ASSIGNMENT_ATTACHMENT_PREVIEW'

export const ASSIGNMENT_ATTACHMENT_DELETE = 'ASSIGNMENT_ATTACHMENT_DELETE'
export const ASSIGNMENT_ATTACHMENT_DELETED = 'ASSIGNMENT_ATTACHMENT_DELETED'
export const ASSIGNMENT_ATTACHMENT_DELETE_ERROR = 'ASSIGNMENT_ATTACHMENT_DELETE_ERROR'

export const ASSIGNMENT_ATTACHMENT_SORT_UPDATE = 'ASSIGNMENT_ATTACHMENT_SORT_UPDATE'
export const ASSIGNMENT_ATTACHMENT_FILTER_UPDATED = 'ASSIGNMENT_ATTACHMENT_FILTER_UPDATED'
export const ASSIGNMENT_ATTACHMENT_FILTERS_UPDATED = 'ASSIGNMENT_ATTACHMENT_FILTERS_UPDATED'

export const WORKFLOW_LOAD_LIST = 'WORKFLOW_LOAD_LIST'
export const WORKFLOW_LIST_LOADED = 'WORKFLOW_LIST_LOADED'
export const WORKFLOW_LIST_LOAD_ERROR = 'WORKFLOW_LIST_LOAD_ERROR'
export const WORKFLOW_LIST_SELECTED = 'WORKFLOW_LIST_SELECTED'
export const WORKFLOW_QUERY_UPDATE = 'WORKFLOW_QUERY_UPDATE'

export const WORKFLOW_APPLICATION_STATUS_CLEAR = 'WORKFLOW_APPLICATION_STATUS_CLEAR'
export const WORKFLOW_APPLICATION_ASSIGN = 'WORKFLOW_APPLICATION_ASSIGN'
export const WORKFLOW_APPLICATION_ASSIGNED = 'WORKFLOW_APPLICATION_ASSIGNED'
export const WORKFLOW_APPLICATION_ASSIGN_ERROR = 'WORKFLOW_APPLICATION_ASSIGN_ERROR'
export const WORKFLOW_APPLICATION_NEW = 'WORKFLOW_APPLICATION_NEW'
export const WORKFLOW_APPLICATION_OPEN = 'WORKFLOW_APPLICATION_OPEN'
export const WORKFLOW_APPLICATION_EVENT = 'WORKFLOW_APPLICATION_EVENT'

export const PROFILE_FIELD_CHANGED = 'PROFILE_FIELD_CHANGED'
export const PROFILE_UPDATE = 'PROFILE_UPDATE'
export const PROFILE_LOAD_ERROR = 'PROFILE_LOAD_ERROR'
export const PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR'
export const PROFILE_UPDATED = 'PROFILE_UPDATED'
export const PROFILE_UPDATE_CANCEL = 'PROFILE_UPDATE_CANCEL'
export const CLEAR_RESET_PASSWORD_FORM = 'CLEAR_RESET_PASSWORD_FORM'
export const PROFILE_GENERIC_ERRORS_UPDATE = 'PROFILE_GENERIC_ERRORS_UPDATE'
export const PROFILE_FORM_ERRORS_DELETE = 'PROFILE_FORM_ERRORS_DELETE'

export const PROFILE_PASSWORD_RESET = 'PROFILE_PASSWORD_RESET'
export const PROFILE_PASSWORD_HAD_RESET = 'PROFILE_PASSWORD_HAD_RESET'
export const PROFILE_PASSWORD_RESET_ERROR = 'PROFILE_PASSWORD_RESET_ERROR'

export const SESSION_CREATED = 'SESSION_CREATED'
export const SESSION_CLOSE_START = 'SESSION_CLOSE_START'
export const SESSION_CLOSE_SUCCESS = 'SESSION_CLOSE_SUCCESS'
export const SESSION_CLOSE_ERROR = 'SESSION_CLOSE_ERROR'
export const SESSIONS_CLOSE_START = 'SESSIONS_CLOSE_START'
export const SESSIONS_CLOSE_SUCCESS = 'SESSIONS_CLOSE_SUCCESS'
export const SESSIONS_CLOSE_ERROR = 'SESSIONS_CLOSE_ERROR'

export const SOCKET_STATE_CHANGED = 'SOCKET_STATE_CHANGED'
export const SOCKET_PREFIX = 'SOCKET'

export const NETWORK_STATE_CHANGED = 'NETWORK_STATE_CHANGED'

export const MQTT_STATE_CHANGED = 'MQTT_STATE_CHANGED'
export const MQTT_RECONNECT_IN = 'MQTT_RECONNECT_IN'

export const PRESENSE_USERS_LOADED = 'PRESENSE_USERS_LOADED'

export const REDIRECTION_START = 'REDIRECTION_START'
export const REDIRECTION_ERROR = 'REDIRECTION_ERROR'

export const MEDIA_IMAGE_UPLOAD = 'MEDIA_IMAGE_UPLOAD'
export const MEDIA_IMAGE_UPLOADED = 'MEDIA_IMAGE_UPLOADED'
export const MEDIA_IMAGE_UPLOAD_ERROR = 'MEDIA_IMAGE_UPLOAD_ERROR'

export const GROUP_EDIT = 'GROUP_EDIT'
export const GROUP_EDIT_CANCEL = 'GROUP_EDIT_CANCEL'
export const GROUP_NEW = 'GROUP_NEW'
export const GROUP_DELETE = 'GROUP_DELETE'
export const GROUP_PROP_CHANGE = 'GROUP_PROP_CHANGE'
export const GROUP_SAVING = 'GROUP_SAVING'
export const GROUP_SAVED = 'GROUP_SAVED'
export const GROUP_SAVE_ERROR = 'GROUP_SAVE_ERROR'
export const GROUP_SAVE_FIELD_ERROR = 'GROUP_SAVE_FIELD_ERROR'
export const GROUP_SET_NEW_MEMBERS = 'GROUP_SET_NEW_MEMBERS'
export const GROUP_SET_REMOVE_MEMBERS = 'GROUP_SET_REMOVE_MEMBERS'
export const GROUP_DETAILS_LOADED = 'GROUP_DETAILS_LOADED'

export const PREFIX_LIST_GROUP_MEMBER = 'LIST_GROUP_MEMBER'

export const GROUP_MEMBER_LIST_LOAD = 'GROUP_MEMBER_LIST_LOAD'
export const GROUP_MEMBER_LIST_LOADED = 'GROUP_MEMBER_LIST_LOADED'
export const GROUP_MEMBER_LIST_LOAD_ERROR = 'GROUP_MEMBER_LIST_LOAD_ERROR'
export const GROUP_MEMBER_LIST_SELECTED = 'GROUP_MEMBER_LIST_SELECTED'

export const GROUPS_MEMBERS_LOAD = 'GROUPS_MEMBERS_LOAD'
export const GROUPS_MEMBERS_LOADED = 'GROUPS_MEMBERS_LOADED'
export const GROUPS_MEMBERS_LOAD_ERROR = 'GROUPS_MEMBERS_LOAD_ERROR'

export const ADD_MEMBERS_TO_GROUPS_START = 'ADD_MEMBERS_TO_GROUPS_START'
export const ADD_MEMBERS_TO_GROUPS_SUCCESS = 'ADD_MEMBERS_TO_GROUPS_SUCCESS'
export const ADD_MEMBERS_TO_GROUPS_ERROR = 'ADD_MEMBERS_TO_GROUPS_ERROR'

export const GROUP_MEMBER_ADDING = 'GROUP_MEMBER_ADDING'
export const GROUP_MEMBER_ADDING_START = 'GROUP_MEMBER_ADDING_START'
export const GROUP_MEMBER_ADDING_SUCCESS = 'GROUP_MEMBER_ADDING_SUCCESS'
export const GROUP_MEMBER_ADDING_ERROR = 'GROUP_MEMBER_ADDING_ERROR'

export const GROUP_MEMBER_REMOVING_START = 'GROUP_MEMBER_REMOVING_START'
export const GROUP_MEMBER_REMOVING_SUCCESS = 'GROUP_MEMBER_REMOVING_SUCCESS'
export const GROUP_MEMBER_REMOVING_ERROR = 'GROUP_MEMBER_REMOVING_ERROR'

export const LIST_ADDING = 'LIST_ADDING'
export const LIST_ADDED = 'LIST_ADDED'
export const LIST_ADD_ERROR = 'LIST_ADD_ERROR'

export const APP_ERROR = 'APP_ERROR'
export const APP_CLEAR_ERROR = 'APP_CLEAR_ERROR'
export const SHOW_ERROR = 'SHOW_ERROR'

export const CONFIRM_SHOW = 'CONFIRM_SHOW'
export const CONFIRM_OK = 'CONFIRM_OK'
export const CONFIRM_CANCEL = 'CONFIRM_CANCEL'

export const CALL_LOAD_LIST = 'CALL_LOAD_LIST'
export const CALL_LIST_LOADED = 'CALL_LIST_LOADED'
export const CALL_LIST_LOAD_ERROR = 'CALL_LIST_LOAD_ERROR'
export const CALL_LIST_SELECTED = 'CALL_LIST_SELECTED'
export const CALL_EXTERNAL_USER_ERROR = 'CALL_EXTERNAL_USER_ERROR'
export const CALL_BUSY_USER_ERROR = 'CALL_BUSY_USER_ERROR'

export const ACTIVITY_LOAD_LIST = 'ACTIVITY_LOAD_LIST'
export const ACTIVITY_LIST_LOADED = 'ACTIVITY_LIST_LOADED'
export const ACTIVITY_LIST_LOAD_ERROR = 'ACTIVITY_LIST_LOAD_ERROR'
export const ACTIVITY_LIST_SELECTED = 'ACTIVITY_LIST_SELECTED'

export const ACTIVITY_LIST_DELETE = 'ACTIVITY_LIST_DELETE'
export const ACTIVITY_LIST_DELETE_SUCCESS = 'ACTIVITY_LIST_DELETE_SUCCESS'

export const MEDIA_LOAD_LIST = 'MEDIA_LOAD_LIST'
export const MEDIA_LIST_LOADED = 'MEDIA_LIST_LOADED'
export const MEDIA_LIST_LOAD_ERROR = 'MEDIA_LIST_LOAD_ERROR'
export const MEDIA_LIST_SELECTED = 'MEDIA_LIST_SELECTED'
export const MEDIA_EDIT = 'MEDIA_EDIT'
export const MEDIA_EDIT_CANCEL = 'MEDIA_EDIT_CANCEL'
export const MEDIA_PROP_CHANGE = 'MEDIA_PROP_CHANGE'
export const MEDIA_SAVING = 'MEDIA_SAVING'
export const MEDIA_SAVE_SUCCESS = 'MEDIA_SAVE_SUCCESS'
export const MEDIA_SAVE_ERROR = 'MEDIA_SAVE_ERROR'
export const MEDIA_FILTER_TYPE = 'MEDIA_FILTER_TYPE'
export const MEDIA_FILTER_UPDATED = 'MEDIA_FILTER_UPDATED'
export const MEDIA_FILTERS_UPDATED = 'MEDIA_FILTERS_UPDATED'

export const MEDIA_ITEM_PREVIEW = 'MEDIA_ITEM_PREVIEW'
export const MEDIA_VIEW_CHANGE = 'MEDIA_VIEW_CHANGE'

export const MEDIA_FILE_DOWNLOADING = 'MEDIA_FILE_DOWNLOADING'
export const MEDIA_FILE_DOWNLOADED = 'MEDIA_FILE_DOWNLOADED'
export const MEDIA_FILE_DOWNLOAD_ERROR = 'MEDIA_FILE_DOWNLOAD_ERROR'

export const CHECK_BROWSER_PERMISSIONS = 'CHECK_BROWSER_PERMISSIONS'
export const SAVE_PERMISSIONS = 'SAVE_PERMISSIONS'
export const SET_PERMISSIONS_STORE = 'SET_PERMISSIONS_STORE'
export const SET_PERMISSIONS_DIALOG_TAB = 'SET_PERMISSIONS_DIALOG_TAB'

export const NEW_MEDIA_IMAGE_SELECTED = 'NEW_MEDIA_IMAGE_SELECTED'
export const NEW_MEDIA_IMAGE_CLEAR = 'NEW_MEDIA_IMAGE_CLEAR'
export const NEW_MEDIA_IMAGE_UPLOADING = 'NEW_MEDIA_IMAGE_UPLOADING'
export const NEW_MEDIA_IMAGE_UPLOADED = 'NEW_MEDIA_IMAGE_UPLOADED'
export const NEW_MEDIA_IMAGE_UPLOAD_ERROR = 'NEW_MEDIA_IMAGE_UPLOAD_ERROR'
export const NEW_MEDIA_CHANGE_BY = 'NEW_MEDIA_CHANGE_BY'
export const NEW_MEDIA_SET_FILES = 'NEW_MEDIA_SET_FILES'
export const NEW_MEDIA_SET_URL = 'NEW_MEDIA_SET_URL'
export const NEW_MEDIA_URL_ERROR = 'NEW_MEDIA_URL_ERROR'

export const SSO_SET_TOKEN = 'SSO_SET_TOKEN'
export const SSO_SET_ERROR = 'SSO_SET_ERROR'

export const TOKEN_LIST_PREFIX = 'API_TOKEN'
export const API_LOAD_TOKENS = 'API_LOAD_TOKENS'
export const API_TOKENS_LOADED = 'API_TOKENS_LOADED'
export const API_LOAD_TOKENS_ERROR = 'API_LOAD_TOKENS_ERROR'
export const API_TOKENS_SELECTED = 'API_TOKENS_SELECTED'
export const API_TOKEN_CHANGE_VALUE = 'API_TOKEN_CHANGE_VALUE'
export const API_TOKEN_CREATE = 'API_TOKEN_CREATE'
export const API_TOKEN_CREATED = 'API_TOKEN_CREATED'
export const API_TOKEN_CREATE_ERROR = 'API_TOKEN_CREATE_ERROR'
export const TOKEN_SAVING = 'TOKEN_SAVING'
export const TOKEN_SAVED = 'TOKEN_SAVED'
export const TOKEN_SAVE_ERROR = 'TOKEN_SAVE_ERROR'
export const TOKEN_LIST_HIDE_DETAILS = 'TOKEN_LIST_HIDE_DETAILS'
export const TOKEN_MAP_ERROR = 'TOKEN_MAP_ERROR'
export const ADMIN_LANGUAGE_ERROR = 'ADMIN_LANGUAGE_ERROR'

export const CLIENT_APP_LAUNCH = 'CLIENT_APP_LAUNCH'
export const CLIENT_APP_LAUNCH_ERROR = 'CLIENT_APP_LAUNCH_ERROR'

export const CLIENT_APP_LOGIN = 'CLIENT_APP_LOGIN'

export const DATA_APP_LOAD_LIST = 'DATA_APP_LOAD_LIST'
export const DATA_APP_LIST_LOADED = 'DATA_APP_LIST_LOADED'
export const DATA_APP_LIST_LOAD_ERROR = 'DATA_APP_LIST_LOAD_ERROR'
export const DATA_APP_LIST_SELECTED = 'DATA_APP_LIST_SELECTED'

export const JOB_TASK_STATUS_CHANGED = 'JOB_TASK_STATUS_CHANGED'
export const JOB_RESET_STATUS = 'JOB_RESET_STATUS'

export const DELETE_NOT_EMPTY_DATA_SOURCE_ERROR = 'DELETE_NOT_EMPTY_DATA_SOURCE_ERROR'

export const ASSIGNMENT_ATTACHMENT_LOAD_PARENT = 'ASSIGNMENT_ATTACHMENT_LOAD_PARENT'
export const ASSIGNMENT_ATTACHMENT_LOAD_PARENT_ERROR = 'ASSIGNMENT_ATTACHMENT_LOAD_PARENT_ERROR'
export const ASSIGNMENT_ATTACHMENT_PARENT_LOADED = 'ASSIGNMENT_ATTACHMENT_PARENT_LOADED'

export const NET_SCAN_LOAD = 'NET_SCAN_LOAD'
export const NET_SCAN_LOADED = 'NET_SCAN_LOADED'
export const NET_SCAN_ITEM_LOADED = 'NET_SCAN_ITEM_LOADED'

export const SIGNOUT_USERS_ERROR = 'SIGNOUT_USERS_ERROR'
export const SIGNOUT_USERS_SUCCESS_ERROR = 'SIGNOUT_USERS_SUCCESS_ERROR'

export const LDAPAPP_CUSTOM_MAPPINGS_UPDATE_SUCCESS = 'LDAPAPP_CUSTOM_MAPPINGS_UPDATE_SUCCESS'
export const LDAPAPP_CUSTOM_MAPPINGS_UPDATE_ERROR = 'LDAPAPP_CUSTOM_MAPPINGS_UPDATE_ERROR'
export const LDAPAPP_CUSTOM_FILTERS_UPDATE_SUCCESS = 'LDAPAPP_CUSTOM_FILTERS_UPDATE_SUCCESS'
export const LDAPAPP_CUSTOM_FILTERS_UPDATE_ERROR = 'LDAPAPP_CUSTOM_FILTERS_UPDATE_ERROR'

export const LDAPAPP_FORCE_SYNC_LOAD = 'LDAPAPP_FORCE_SYNC_LOAD'
export const LDAPAPP_FORCE_SYNC_LOADED = 'LDAPAPP_FORCE_SYNC_LOADED'
export const LDAPAPP_FORCE_SYNC_LOAD_ERROR = 'LDAPAPP_FORCE_SYNC_LOAD_ERROR'
export const LDAPAPP_FORCE_SYNC_ABORT = 'LDAPAPP_FORCE_SYNC_ABORT'

export const CALL_USER_LIST_TOGGLE_SHOW_UNAVAILABLE = 'CALL_USER_LIST_TOGGLE_SHOW_UNAVAILABLE'
export const CALL_USER_LIST_TOGGLE_SHOW_ALL = 'CALL_USER_LIST_TOGGLE_SHOW_ALL'

export const PREFIX_API_TOKEN = 'ApiToken'
export const PREFIX_ACTIVITY = 'Activity'
export const PREFIX_CALL = 'Call'
export const PREFIX_CALL_EVENT = 'CallEvent'
export const PREFIX_CALL_REPORT_SSRC_VIDEO_SEND = 'CallReportSsrcVideoSend'
export const PREFIX_CALL_REPORT_BWE_FOR_VIDEO = 'CallReportBweForVideo'
export const PREFIX_CALL_SSRC_VIDEO_SEND = 'CallSsrcVideoSend'
export const PREFIX_CALL_BWE_FOR_VIDEO = 'CallBweForVideo'
export const PREFIX_APPLICATION = 'Application'
export const PREFIX_SESSION = 'Session'
export const PREFIX_SESSION_EVENT = 'SessionEvent'
export const PREFIX_APPLICATION_PARTICIPANT = 'ApplicationParticipant'
export const PREFIX_APPLICATION_ROLE = 'ApplicationRole'
export const PREFIX_VERSION = 'Version'
export const PREFIX_WORKFLOW = 'Workflow'
export const PREFIX_ASSIGNMENT = 'Assignment'
export const PREFIX_ASSIGNMENT_ATTACHMENT = 'AssignmentAttachment'
export const PREFIX_CALL_USER = 'CallUser'
export const PREFIX_USER = 'User'
export const PREFIX_GROUP = 'Group'
export const PREFIX_GROUP_MEMBER = 'GroupMember'
export const PREFIX_MEDIA = 'Media'
export const PREFIX_AUTH_APP = 'AuthApp'
export const PREFIX_TURN_CONFIG = 'TurnConfig'
export const PREFIX_REALM = 'Realm'
export const PREFIX_LDAP_APP = 'LdapApp'

export const ROLE_UPDATE_ERROR = 'ROLE_UPDATE_ERROR'
export const APPLICATION_ROLE_REMOVE_ERROR = 'APPLICATION_ROLE_REMOVE_ERROR'
export const APPLICATION_SINGLE_OWNER_REMOVE_ERROR = 'APPLICATION_SINGLE_OWNER_REMOVE_ERROR'
export const APPLICATION_ALL_OWNERS_REMOVE_ERROR = 'APPLICATION_ALL_OWNERS_REMOVE_ERROR'
export const APPLICATION_PARTICIPANT_REMOVE_ERROR = 'APPLICATION_PARTICIPANT_REMOVE_ERROR'

export const VERIFY_CREDENTIALS_REQUEST = 'VERIFY_CREDENTIALS_REQUEST'
export const VERIFY_CREDENTIALS_RESPONSE = 'VERIFY_CREDENTIALS_RESPONSE'
export const VERIFY_CREDENTIALS_FIELD_ERROR = 'VERIFY_CREDENTIALS_FIELD_ERROR'
export const CLEAR_VERIFY_CREDENTIALS_FIELD_ERROR = 'CLEAR_VERIFY_CREDENTIALS_FIELD_ERROR'

export const APPLICATION_PERMISSIONS_ADD = 'APPLICATION_PERMISSIONS_ADD'
export const SELECTED_APP_UPDATE = 'SELECTED_APP_UPDATE'
export const SELECTED_APP_EXPORT_UPDATE = 'SELECTED_APP_EXPORT_UPDATE'
export const CREATE_APP_STEP_UPDATE = 'CREATE_APP_STEP_UPDATE'
export const CREATE_APP_TEMPLATE_UPDATE = 'CREATE_APP_TEMPLATE_UPDATE'
export const CREATE_APP_IS_LOADING_UPDATE = 'CREATE_APP_IS_LOADING_UPDATE'
export const APPLICATION_EXPORT_STARTED = 'APPLICATION_EXPORT_STARTED'
export const APPLICATION_EXPORT_FINISHED = 'APPLICATION_EXPORT_FINISHED'
export const APPLICATION_ACTIVE_IMPORTS_ADD = 'APPLICATION_ACTIVE_IMPORTS_ADD'
export const APPLICATION_ACTIVE_IMPORT_REMOVE = 'APPLICATION_ACTIVE_IMPORT_REMOVE'
export const APPLICATION_IMPORT_UPLOAD_UPDATE = 'APPLICATION_IMPORT_UPLOAD_UPDATE'
export const APPLICATION_IMPORT_UPLOAD_RESET = 'APPLICATION_IMPORT_UPLOAD_RESET'
export const APPLICATION_UPGRADE_UPLOAD_UPDATE = 'APPLICATION_UPGRADE_UPLOAD_UPDATE'
export const APPLICATION_UPGRADE_UPLOAD_RESET = 'APPLICATION_UPGRADE_UPLOAD_RESET'
export const APPLICATION_UPGRADE_SET_UPGRADE_IN_PROGRESS = 'APPLICATION_UPGRADE_SET_UPGRADE_IN_PROGRESS'

export const SNACKBAR_SHOW = 'SNACKBAR_SHOW'
export const SNACKBAR_HIDE = 'SNACKBAR_HIDE'
