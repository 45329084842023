import React from 'react'
import { FormattedMessage } from 'react-intl'
import './JobStatus.css'

class JobStatus extends React.Component {
  timeoutHandler = null
  componentDidMount() {
    setTimeout(this.props.onJobResetStatus, this.props.delay)
  }

  // eslint-disable-next-line
  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !nextProps.remain) {
      this.timeoutHandler = setTimeout(this.props.onJobResetStatus, this.props.delay)
    }
  }

  componentWillUnmount() {
    this.timeoutHandler && clearTimeout(this.timeoutHandler)
  }

  render() {
    const props = this.props
    const isVisible = props.open
    const className = `job-status ${isVisible ? 'visible' : 'invisible'} ${props.type || ''} ${props.status || ''}`
    const translation = `JOB_${props.type}_${props.status}`.toUpperCase()

    return <div className={className}>
      {props.open && <FormattedMessage id={translation} defaultMessage="" />}
    </div>
  }
}

JobStatus.defaultProps = {
  delay: 3000
}

export { JobStatus }
