import React from 'react'

const BASE = '/ng/applications/:appId'

const Session = props => {
  const { sessionId } = props.match.params
  return <span>{sessionId}</span>
}

export const APP_DASHBOARD_ROUTES = [
  { path: `${BASE}/general`, props: { tooltipDisabled: true }, breadcrumb: 'GENERAL' },
  { path: `${BASE}/permissions`, props: { tooltipDisabled: true }, breadcrumb: 'PERMISSIONS' },
  { path: `${BASE}/permissions/roles`, props: { tooltipDisabled: true }, breadcrumb: 'ROLES' },
  { path: `${BASE}/versions`, props: { tooltipDisabled: true }, breadcrumb: 'VERSIONS' },
  { path: `${BASE}/sessions`, props: { tooltipDisabled: true }, breadcrumb: 'SESSIONS' },
  { path: `${BASE}/sessions/:sessionId/events`, props: { tooltipDisabled: true }, breadcrumb: () => <Session /> }
]
