import React from 'react'
import classNames from 'classnames'
import { TokenList } from '../../../../containers/admin'
import './AdminApiCredentials.css'

const AdminApiCredentials = props => {
  return (
    <div className={classNames('admin-api-credentials', props.className)}>
      <TokenList />
    </div>
  )
}

export { AdminApiCredentials }
