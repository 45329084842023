import React from 'react'
import PropTypes from 'prop-types'
import ArrowBack from 'material-ui/svg-icons/navigation/arrow-back'

import { NavLink } from 'react-router-dom'
import { CursorTooltip } from 'skylight-common'
import { FormattedMessage } from 'react-intl'

import './DomainLink.css'

const DomainLink = props => {
  const { realm, history } = props

  const onIconClick = () => {
    history.push('/login')
  }

  return (
    <div className="login-domain-link-wrapper">
      <ArrowBack className="login-domain-link-icon" onClick={onIconClick} />
      <CursorTooltip
        className="login-domain-link-tooltip"
        tooltip={<FormattedMessage id="CHANGE_DOMAIN" defaultMessage="Change domain" />}
        tooltipPosition={{ horizontalPosition: 'right', verticalPosition: 'bottom' }}>
        <NavLink to="/login" className="login-domain-link">
          <FormattedMessage
            id="DOMAIN_NAME"
            defaultMessage={`Domain: ${realm.realm}`}
            values={{ value: realm.realm }}/>
        </NavLink>
      </CursorTooltip>
    </div>
  )
}

DomainLink.propTypes = {
  realm: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export { DomainLink }
