import React from 'react'
import { Column, ListActionButton, ListFooterStats, Loading, GridHeader, CursorTooltip } from 'skylight-common'
import DeleteIcon from '../../../../../../Common/Icons/removeTrash'
import { FormattedMessage } from 'react-intl'
import { EmptyListView } from '../../Sessions/SessionList/EmptyListView'
import SecurityIcon from 'material-ui/svg-icons/hardware/security'
import { COLUMNS } from './columns'
import { connect } from 'react-redux'
import AddRoleIcon from '../../../../../../Common/Icons/addRole'
import { TableNextGen } from '../../../../../../Common/NextGen/TableNextGen'
import EditIcon from 'material-ui/svg-icons/content/create'
import ContentCopyIcon from 'material-ui/svg-icons/content/content-copy'
import IconButton from 'material-ui/IconButton'
import { DEFAULT_ROLES } from '../../../../../../../lib/application/roles'
import { PERMISSIONS, hasPermission } from '../../../../../../../lib/application'
import { isApplicationAdmin } from '../../../../../../../svc/scopes'

import dialogActions from '../../../../../../../actions/dialog'
import appRolesActions from '../../../../../../../actions/application/roles'
import contactActions from '../../../../../../../actions/contact'

import './RolesList.css'

const removeConfirmationDialogTitle = (
  <span className="remove-application-role-modal-title">
    <DeleteIcon />
    <FormattedMessage
      id="APPLICATION_ROLE_REMOVE_DIALOG_TITLE"
      defaultMessage="Are you sure you want to delete this role?" />
  </span>
)

const RolesList = props => {
  const { list, application, permissions } = props
  const { applicationId } = application
  const applicationPermissions = permissions[applicationId] || {}

  const hasRolePermission = (rolePermission) => {
    return isApplicationAdmin() || hasPermission(applicationPermissions, rolePermission)
  }
  const canUpdateRole = hasRolePermission(PERMISSIONS.ROLE.UPDATE)
  const canDeleteRole = hasRolePermission(PERMISSIONS.ROLE.DELETE)
  const canCreateRole = hasRolePermission(PERMISSIONS.ROLE.CREATE)

  const handleDelete = () => {
    props.onConfirm(props.onRemoveSelectedApplicationRole,
      {
        customTitle: removeConfirmationDialogTitle,
        message: 'APPLICATION_ROLE_REMOVE_DIALOG_DESCRIPTION',
        action: 'ACTION_REMOVE_APPLICATION_ROLE',
        className: 'remove-application-role-modal'
      }
    )
  }

  const getItemActions = item => {
    const moreThanOneSelected = list.selectedIds && list.selectedIds.length > 1

    return [
      {
        icon: <DeleteIcon className="icon delete-icon" />,
        tooltip: { id: 'DELETE_ROLE', default: 'Delete role' },
        className: 'delete-role',
        action: handleDelete,
        isDisabled: () => !canDeleteRole || item.name === DEFAULT_ROLES.OWNER || moreThanOneSelected
      },
      {
        icon: <EditIcon className="icon" />,
        tooltip: { id: 'EDIT_ROLE', default: 'Edit role' },
        className: 'edit-role',
        action: props.onApplicationRoleEdit,
        isDisabled: () => !canUpdateRole || moreThanOneSelected
      },
      {
        icon: <ContentCopyIcon className="icon" />,
        tooltip: { id: 'DUPLICATE_ROLE', default: 'Duplicate role' },
        className: 'duplicate-role',
        action: props.onApplicationRoleDuplicate,
        isDisabled: () => !canCreateRole || moreThanOneSelected
      }
    ].filter(x => typeof x.isDisabled === 'function' ? !x.isDisabled() : true)
  }

  const handleItemAction = (e, action, item) => {
    e.stopPropagation()
    if (item) {
      props.onApplicationRoleSelectItems([item.roleId])
    }
    action()
  }

  const renderItemActions = item => {
    return getItemActions(item).map((a, i) => (
      <CursorTooltip key={i} tooltip={<FormattedMessage id={a.tooltip.id} defaultMessage={a.tooltip.default} />}>
        <IconButton
          className={`item-action-button ${a.className || ''}`}
          onTouchTap={e => handleItemAction(e, a.action, item)}>{a.icon}</IconButton>
      </CursorTooltip>
    ))
  }

  const renderFooterActions = item => {
    if (!item) return null

    return <div className="footer-actions">{renderItemActions(item)}</div>
  }

  const renderTableFooter = () => {
    const selectedItem = list.items.find(x => x.id === list.selectedIds[0])
    return list.selectedIds.length > 0
      ? <ListFooterStats list={list} onUnselect={props.onApplicationRoleSelectItems} actions={renderFooterActions(selectedItem)} />
      : null
  }

  const renderRolesTable = () => {
    if (!applicationId) {
      return null
    }

    return <div className="app-roles-table">
      <TableNextGen
        tableHeading={props.heading}
        showCheckboxes
        tableHeaderActions={props.tableHeaderActions}
        checkboxIcon={() => <SecurityIcon className="security-icon" />}
        showHover
        isLoading={list.isLoading}
        query={list.query}
        items={list.items}
        total={list.total}
        done={list.done}
        selectedRows={list.selectedIds}
        name="appRoleList"
        tableFooter={renderTableFooter()}
        emptyLabel={<EmptyListView />}
        onQuery={props.onApplicationRoleQuery}
        rowClassName="app-role"
        onRowsSelected={props.onApplicationRoleSelectItems}>
        {COLUMNS.map(x => <Column
          key={x.name}
          name={x.name}
          sortable={x.sortable !== false}
          title={<FormattedMessage id={x.title} defaultMessage={x.title} />}
          render={x.render ? item => x.render(item, props) : null} />)}
        <Column
          key="rolesActions"
          className="actions roles-actions"
          isAction
          render={renderItemActions} />
      </TableNextGen>
    </div>
  }

  const renderListActionButton = () => {
    if (!canCreateRole) {
      return null
    }

    const anyItemSelected = !!list.selectedIds.length
    return <ListActionButton
      className={anyItemSelected ? 'item-selected' : ''}
      icon={<AddRoleIcon />}
      tooltip={<FormattedMessage id="ACTION_ADD_APPLICATION_ROLE" default="Add role" />}
      onAction={props.onApplicationRoleNew} />
  }

  const getTitle = () => {
    return <div className="role-list-title">{application.name}</div>
  }

  const isLoading = applicationId && list.isLoading && list.items.length === 0
  return (
    <div className="roles-list">
      <GridHeader title={getTitle()} />
      <Loading
        isLoading={isLoading}
        message="LOADING_APP_VERSIONS_LIST"
        error={list.error
          ? <FormattedMessage
          id="EMPTY_APP_VERSIONS_LIST"
          defaultMessage="No versions available" />
          : null}>
        {renderRolesTable()}
      </Loading>
      {renderListActionButton()}
    </div>
  )
}

const mapStateToProps = (state) => ({
  list: state.application.role.list,
  permissions: state.application.permissions,
  scopes: state.auth.scopes,
  user: state.auth.user,
  application: state.application.selectedApplication
})

const rolesListActions = {
  ...appRolesActions,
  ...dialogActions,
  onUserSelectItems: contactActions.onUserSelectItems
}

const RoleListContainer = connect(mapStateToProps, rolesListActions)(RolesList)

export default RoleListContainer
