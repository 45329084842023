import React from 'react'
import Toggle from 'material-ui/Toggle'
import { FormattedMessage } from 'react-intl'
import { CursorTooltip } from 'skylight-common'
import classNames from 'classnames'

const LDAPToggle = props => {
  const { ldapApp, authApp } = props

  const { ldapEnabled = false } = ldapApp.item || {}
  const { enabled: samlEnabled = false } = authApp.item || {}

  return (
    <div className="ldap-toggle">
      <div className="title">
        <FormattedMessage id="LDAP_TITLE" defaultMessage="LDAP Federation" />
      </div>
      <div className="description">
        <FormattedMessage id="LDAP_DESCRIPTION" defaultMessage="" />
      </div>
      <CursorTooltip tooltip={!samlEnabled ? <FormattedMessage id="ENABLE_SAML" defaultMessage="Enable SAML" /> : null}>
        <Toggle
          disabled={!samlEnabled}
          className={classNames('toggle-control', { disabled: !samlEnabled })}
          toggled={ldapEnabled && samlEnabled}
          onToggle={(e, v) => props.onToggleLdap(v)} />
      </CursorTooltip>
    </div>
  )
}

export { LDAPToggle }
