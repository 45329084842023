import React from 'react'
import { FormattedMessage } from 'react-intl'

import './EmptyListView.css'

const EmptyListView = props => {
  return (
    <div className="empty-list-view">
      <FormattedMessage
        id="EMPTY_APP_SESSION_LIST"
        defaultMessage="You have no sessions to view." />
    </div>
  )
}

export { EmptyListView }
