import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ApplicationPermissionSelector } from '../ApplicationPermissionSelector'
import classNames from 'classnames'
import SubdirectoryArrowRight from 'material-ui/svg-icons/navigation/subdirectory-arrow-right'
import { SUPER_PERMISSIONS_MAP } from '../../../../../../../lib/application/roles'

import './ApplicationPermission.css'

const ApplicationPermission = props => {
  const { permission, isFirstPermissionInCategory, isCategoryWithMultiplePermissions } = props
  const { permissions, description } = permission
  const { id, defaultMessage } = description
  const isMultiplePermissions = permissions.length > 1

  const renderPermissions = () => {
    return (permissions || []).map((p, i) => {
      const parentPermission = Object.keys(SUPER_PERMISSIONS_MAP).find(x => SUPER_PERMISSIONS_MAP[x].includes(p.permission))

      return <ApplicationPermissionSelector
        parentPermission={parentPermission}
        role={props.role}
        permission={p.permission}
        key={p.permission}
        onPermissionChange={props.onPermissionChange} />
    })
  }

  const renderLabels = () => {
    const labels = [
      {
        className: 'participants-only',
        render: () => <FormattedMessage key="PARTICIPANT" id="AS_A_PARTICIPANT_PERMISSION_SELECTION" defaultMessage="As a Participant Only" />
      },
      {
        className: 'all',
        render: () => <FormattedMessage key="ALL" id="ALL_PERMISSION_SELECTION" defaultMessage="All" />
      }
    ]

    if (isCategoryWithMultiplePermissions && isFirstPermissionInCategory) {
      return labels.map(x => <div key={x.className} className={classNames('top-label', x.className)}>{x.render()}</div>)
    }

    return null
  }

  const permissionPrefix = permission.permissions[0].permission
    .split(':')
    .map(x => `${x[0].toUpperCase()}${x.slice(1)}`)

  if (isMultiplePermissions) {
    permissionPrefix.pop()
  }

  return (
    <div className="application-permission">
      <div className="description-text">
        {permission.subPermission ? <SubdirectoryArrowRight className="sub-permission-icon" /> : null}
        <FormattedMessage id={id} defaultMessage={defaultMessage}>
          {message => <span>{permission.title || permissionPrefix.join(': ')} - {message}</span>}
        </FormattedMessage>
      </div>
      <div className={classNames('application-permissions-container', { 'multiple-permissions': isMultiplePermissions })}>
        {renderLabels()}
        {renderPermissions()}
      </div>
    </div>
  )
}

export { ApplicationPermission }
