import React from 'react'
import { FormattedMessage } from 'react-intl'
import { TokenItemDetails } from './TokenItemDetails'
import { WorkflowPicker } from '../../Workflow'
import './TokenDetails.css'

const TokenDetails = (props) => {
  const { item } = props.item

  const onInit = (wfList, id) => {
    id = id || item.id // Sometimes ID is just not ready yet.
    const matched = wfList.items.filter(x => x.integrationId === id).map(x => x.workflowId)
    props.onWorkflowSelectItems(matched)
  }

  return <div className="token-details">
      <TokenItemDetails item={item} onShowTokenCredentials={props.onShowTokenCredentials} />
      <div className="title">
        <FormattedMessage id="TOKEN_SUBSCRIBE_TITLE" default="Subscribed applications" />
        <FormattedMessage id="TOKEN_SUBSCRIBE" default="Choose the application" />
      </div>
      <WorkflowPicker {...props} onInit={onInit} id={item.id} columnNames={['name']} />
    </div>
}

export { TokenDetails }
