import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const cardCompleted = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M15,7 L15,2 L20,7 L15,7 Z M10.9377523,19 L7,14.8354164 L8.50201827,13.2468754 L10.9377523,15.8229181 L15.4979817,11 L17,12.5885409 L10.9377523,19 Z M16.414,1 L5,1 C3.897,1 3,1.898 3,3 L3,21 C3,22.103 3.897,23 5,23 L19,23 C20.103,23 21,22.103 21,21 L21,5.586 L16.414,1 Z" id="path-1"></path>
    </SvgIcon>
  )
}

export default cardCompleted
