import React from 'react'
import { Snackbar } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import WarningIcon from 'material-ui/svg-icons/alert/warning'
import { FlatButton } from 'material-ui'
import './SnackbarUpgradeError.css'

const SnackbarUpgradeError = props => {
  const { snackbar, snackbarType } = props
  const { open } = snackbar[snackbarType] || {}

  const handleClose = () => {
    props.onHideSnackbar(snackbarType)
  }

  return (
    <Snackbar className="snackbar-upgrade-error" open={open} onRequestClose={handleClose}>
      <div className="warning-icon">
        <WarningIcon />
      </div>
      <div className="snackbar-upgrade-error-box">
        <FormattedMessage id="ERROR_DURING_IMPORT" defaultMessage="Error during import." />
      </div>
      <div className="snackbar-upgrade-error-button">
        <FlatButton
          onClick={handleClose}
          primary
          label={<FormattedMessage id="OK" defaultMessage="OK" />} />
      </div>
    </Snackbar>
  )
}

export { SnackbarUpgradeError }
