import loadImage from 'blueimp-load-image'
import skylight from 'skylight-js-sdk'
import callSdk from 'skylight-call-sdk'
import Konva from 'konva'
import { shouldRotate } from 'skylight-common'

let localVideoRef = null
skylight.events.on('setVideoRef', payload => {
  if (payload.type === callSdk.constants.STREAM_TYPES.LOCAL) {
    localVideoRef = payload.ref
    window.localVideoRef = localVideoRef
  }
})

// Convert the Konva DataURL to a Blob for sending on the data channel.
const dataURItoArray = (dataURI) => {
  if (typeof dataURI !== 'string') {
    throw new Error('Invalid argument: dataURI must be a string')
  }

  dataURI = dataURI.split(',')
  const byteString = window.atob(dataURI[1])
  const byteStringLength = byteString.length
  const arrayBuffer = new ArrayBuffer(byteStringLength)
  const intArray = new Uint8Array(arrayBuffer)

  for (let i = 0; i < byteStringLength; i++) {
    intArray[i] = byteString.charCodeAt(i)
  }

  return intArray
}

export const getHtmlImage = src => {
  return new Promise((resolve, reject) => {
    const img = new window.Image()
    img.onload = () => {
      resolve(img)
    }
    img.onerror = (e) => {
      reject(e)
    }
    img.src = src
  })
}

export const getImageFromFileSrc = (fileSrc, name, type = 'image/png') => {
  return getHtmlImage(fileSrc).then(img => {
    return {
      src: fileSrc,
      name: name,
      type: type,
      width: img.width,
      height: img.height
    }
  })
}

const convertFileToImageSrc = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onerror = reject
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(base64data)
    }
  })
}

export const getImageFromFile = file => {
  return convertFileToImageSrc(file).then(getImageFromFileSrc)
}

export const getFileFromChunks = (chunks, options) => {
  return new Promise((resolve, reject) => {
    const blob = new window.Blob(chunks, options)
    loadImage.parseMetaData(blob, metadata => {
      const orientation = shouldRotate() ? metadata && metadata.exif && metadata.exif.get('Orientation') : undefined
      loadImage(blob, (canvas) => {
        const dataUrl = canvas.toDataURL()
        resolve(getImageFromFileSrc(dataUrl, options.name, options.type))
      }, { canvas: true, orientation })
    })
  })
}

export const getFileAsArray = file => {
  return Promise.resolve(dataURItoArray(file.src))
}

export const cropImage = (imgUrl, pixelCrop) => {
  const canvas = document.createElement('canvas')
  return getHtmlImage(imgUrl).then(image => {
    pixelCrop = {
      x: pixelCrop.x * image.width / 100,
      y: pixelCrop.y * image.height / 100,
      width: pixelCrop.width * image.width / 100,
      height: pixelCrop.height * image.height / 100
    }

    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height
    const ctx = canvas.getContext('2d')

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    )

    return canvas.toDataURL('image/jpeg')
  })
}

export const takeSnapshot = () => {
  const layer = new Konva.Layer({
    visible: true
  })

  const image = new Konva.Image({
    width: localVideoRef.videoWidth,
    height: localVideoRef.videoHeight,
    x: 0,
    y: 0,
    visible: true
  })
  layer.add(image)
  image.setImage(localVideoRef)
  image.draw()

  // Convert the dataURL to an ArrayBuffer, add the image to our camera roll,
  // and send to remote client.
  const imgData = image.toDataURL()
  return getImageFromFileSrc(imgData)
}

export const uploadFile = async(file, metadata) => {
  const array = await getFileAsArray(file)

  // Should be available in React Native in 0.54.0?
  // https://github.com/facebook/react-native/releases/tag/v0.54.0
  const blob = new window.Blob([array], { type: file.type })
  blob.name = metadata.title

  await skylight.media.uploadBlob(blob, metadata)
}
