import skylight from 'skylight-js-sdk'

const INTEGRATIONS_URL = '/v1/ims/integrations'
const CALL_URL = '/v1/calling/turn/configuration'

export const getTokens = () => {
  return skylight.api.get(INTEGRATIONS_URL)
}

export const createToken = token => {
  const newToken = {
    name: token.name,
    description: token.description
  }
  return skylight.api.post(INTEGRATIONS_URL, newToken)
}

export const removeToken = id => {
  return skylight.api.delete(`${INTEGRATIONS_URL}/${id}`)
}

export const getTurnConfig = async() => {
  const response = await skylight.api.get(`${CALL_URL}`)
  // Convert to new array style if necessary
  if (!response.iceServers) {
    const stunServer = { url: response.stunUrl }
    const turnServer = {
      url: response.turnUrl,
      username: response.username,
      credential: response.credential
    }
    response.iceServers = [stunServer, turnServer]

    delete response.stunUrl
    delete response.turnUrl
    delete response.username
    delete response.credential
  }

  return response
}

export const updateTurnConfig = config => {
  const svcConfig = { ...config }
  delete svcConfig.realm
  delete svcConfig.ttl

  return skylight.api.put(CALL_URL, svcConfig)
}

const getBaseSamlUrl = realmName => {
  return skylight.utils.resolveUrl(
    skylight.config.apiUrl,
    `/v1/authentication/idp/saml/${realmName}`
  )
}

export const getDefaultSamlApp = realm => {
  const baseUrl = getBaseSamlUrl(realm)
  return {
    url: `${baseUrl}/login`,
    type: 'saml',
    name: 'saml',
    enabled: false,
    options: {
      issuer: `${baseUrl}`,
      callbackUrl: `${baseUrl}/callback`,
      cert: '',
      uuidAttribute: 'nameID'
    }
  }
}

export const getDefaultLdap = () => {
  return {
    realm: 'upskill',
    ldapEnabled: false,
    serverIP: '',
    bindDN: '',
    bindCredential: '',
    baseDN: '',
    uuidAttribute: 'TestAttribute',
    customFilters: [],
    vendor: '',
    syncInterval: 12 * 60, // 12 hours
    id: null
  }
}
