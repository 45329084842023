import React from 'react'
import classNames from 'classnames'
import { Table } from 'skylight-common'

import './TableNextGen.css'

const TableNextGen = props => {
  return <Table
    {...props}
    className={classNames('table-next-gen', {
      'with-footer': !!props.tableFooter
    })} />
}

export { TableNextGen }
