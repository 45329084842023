import React from 'react'
import Dialog from 'material-ui/Dialog'
import { FormattedMessage } from 'react-intl'
import InfoIcon from 'material-ui/svg-icons/action/info'
import './TokenInfo.css'

const TokenInfo = (props) => {
  return (
    <Dialog
      className="token-info"
      contentClassName="token-info-content"
      bodyClassName="token-info-body"
      title={<div className="token-info-title">
        <InfoIcon />
        {props.infoTitleId && <FormattedMessage id={props.infoTitleId} defaultMessage="API access token" />}
      </div>}
      open={props.open}
      onRequestClose={props.onClose}>
      {props.infoDescId && <FormattedMessage
        id={props.infoDescId}
        defaultMessage="A short-lived JSON Web Token used to access and interact with protected data in the platform." />}
    </Dialog>
  )
}

export { TokenInfo }
