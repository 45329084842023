import * as ActionTypes from './types'
import { push } from 'react-router-redux'
import { createFilterActions, createItemActions, createListActions, validate, ValidationFieldError } from 'skylight-common'

import { SHOW_TOKEN_CREDENTIALS, ADD_TOKEN } from '../App/Home/Layout/Dialogs/dialogTypes'
import {
  getTokens,
  createToken,
  removeToken,
  getDefaultSamlApp,
  getTurnConfig as getTurnConfigSvc,
  updateTurnConfig as updateTurnConfigSvc
} from '../svc/admin'

import skylight from 'skylight-js-sdk'
import { SAML_FIELDS, TOKEN_SETTINGS_FIELDS, isIceUrl } from '../lib/admin'
import { DEFAULT_QUERY } from '../lib/token'
import { showError } from './error'
import ldapActions from './ldap'

const TURN_CONFIG_FIELDS = [
  { name: 'username', required: false },
  { name: 'credential', required: false },
  { name: 'url', required: true, rules: isIceUrl }
]

const COMMON_TOKEN_OPTIONS = {
  prefix: ActionTypes.PREFIX_API_TOKEN.toUpperCase(),
  handler: ActionTypes.PREFIX_API_TOKEN,
  onGetList: s => s.admin.apiToken.list
}

const TOKEN_LIST_ACTIONS = createListActions({
  ...COMMON_TOKEN_OPTIONS,
  onLoad: getTokens,
  onDelete: ids => {
    return Promise.all(ids.map(removeToken))
  }
})

const onSaveToken = async(token, ctx) => {
  const result = await createToken(token)

  if (result) {
    ctx.dispatch({ type: ActionTypes.DIALOG_HIDE, dialogType: ADD_TOKEN })
    ctx.dispatch({ type: ActionTypes.DIALOG_SHOW, dialogType: SHOW_TOKEN_CREDENTIALS, params: result })
  }
}

const TOKEN_ITEM_ACTIONS = createItemActions({
  ...COMMON_TOKEN_OPTIONS,
  onSave: onSaveToken,
  onLoad: async(item, res, ctx) => item,
  onLoadItems: TOKEN_LIST_ACTIONS.onApiTokenLoadItems
})

const loadTokens = query => {
  return (dispatch, getState) => {
    dispatch({ type: ActionTypes.API_LOAD_TOKENS, query })
    getTokens(query)
      .then(x => {
        dispatch({ type: ActionTypes.API_TOKENS_LOADED, items: x, total: x.length })
      })
      .catch(e => {
        dispatch({ type: ActionTypes.API_LOAD_TOKENS_ERROR, error: e.message })
      })
  }
}

const onInvalidAccessTokenTimeout = () => ({
  type: 'ACCESS_TOKEN_INVALID_TIMEOUT_ERROR',
  error: 'ACCESS_TOKEN_INVALID_TIMEOUT_ERROR'
})

const FILTER_ACTIONS = createFilterActions({
  baseUrl: '/admin/tokens',
  redirect: false,
  selector: state => state.admin.apiToken.list.query,
  listPrefix: ActionTypes.PREFIX_API_TOKEN.toUpperCase(),
  actionPrefix: ActionTypes.PREFIX_API_TOKEN,
  DEFAULT_QUERY,
  loadItems: TOKEN_LIST_ACTIONS[`on${ActionTypes.PREFIX_API_TOKEN}LoadItems`],
  push
})

const ITEM_ACTIONS = createItemActions({
  prefix: ActionTypes.PREFIX_REALM.toUpperCase(),
  handler: ActionTypes.PREFIX_REALM,
  onSave: async(item, store) => {
    try {
      const result = await skylight.domain.save(item.privacyItem || item)
      // TODO: Think for a better solution.
      // Snackbar should be dispatched only for Privacy settings on successful save.
      const { location } = store.getState().router
      if (location.pathname && location.pathname.includes('admin/privacy')) {
        store.dispatch(showError('ADMIN_PRIVACY_TIME'))
      }

      return result
    } catch (e) {
      console.log('error', e)
      const message = await e.response.text()
      if (e.status === 400 && message.includes('invalid accessTokenTimeout')) {
        throw new ValidationFieldError({ accessTokenTimeout: 'ACCESS_TOKEN_INVALID_TIMEOUT_ERROR' })
      }

      throw e
    }
  },
  onLoad: async() => {
    const realmName = skylight.auth.getRealmFromToken(skylight.auth.accessToken)
    return await skylight.domain.getByName(realmName)
  },
  onValidate: (realm, ctx) => {
    const errors = validate(realm.tokenSettings, TOKEN_SETTINGS_FIELDS) || {}
    if (Object.keys(errors).length > 0) {
      ctx.dispatch(onInvalidAccessTokenTimeout())
    }

    return errors
  }
})

const TURN_CONFIG_ACTIONS = createItemActions({
  prefix: ActionTypes.PREFIX_TURN_CONFIG.toUpperCase(),
  handler: ActionTypes.PREFIX_TURN_CONFIG,
  onSave: updateTurnConfigSvc,
  onLoad: getTurnConfigSvc,
  onValidate: config => {
    // validate each iceServer
    const iceServers = config.iceServers || []

    for (let i = 0; i < iceServers.length; i++) {
      const iceServer = iceServers[i]
      const errors = validate(iceServer, TURN_CONFIG_FIELDS)
      if (errors) return { ...errors, iceServerIndex: i }
    }

    return null
  }
})

const AUTH_APP_ACTIONS = createItemActions({
  prefix: ActionTypes.PREFIX_AUTH_APP.toUpperCase(),
  handler: ActionTypes.PREFIX_AUTH_APP,
  onLoad: async(prev, res, ctx) => {
    const authApps = await skylight.idp.authApp.list()
    let samlApp = authApps.find(x => x.type === 'saml')
    if (samlApp) {
      samlApp = await skylight.idp.authApp.getById(samlApp.id)
    } else {
      const realm = ctx.getState().auth.user.realm
      samlApp = getDefaultSamlApp(realm)
    }

    return samlApp
  },
  onValidate: item => {
    if (!item.enabled) {
      return true
    }

    return validate(item.options, SAML_FIELDS)
  },
  onSave: async(item, ctx) => {
    const original = ctx.getState().admin.auth.authApp.original
    // No changes were really made.
    if (original && !item.id && item.enabled === original.enabled) return
    return await skylight.idp.authApp.save(item)
  }
})

// const createToken = (token) => {
//   return (dispatch, getState) => {
//     const query = getState().admin.apiToken.list.query
//     dispatch({type: ActionTypes.API_TOKEN_CREATE, query})
//     createTokenSvc(token)
//       .then(x => {
//         dispatch({type: ActionTypes.API_TOKEN_CREATED})
//         dispatch({type: ActionTypes.DIALOG_HIDE, dialogType: ADD_TOKEN})
//         loadTokens(query)(dispatch)
//       })
//       .catch(e => {
//         dispatch({type: ActionTypes.API_TOKEN_CREATE_ERROR, error: e.message})
//       })
//   }
// }

// const removeTokens = (selectedIds) => {
//   return (dispatch, getState) => {
//     dispatch({type: ActionTypes.PREFIX_LIST_API_TOKENS + ActionTypes.LIST_REMOVING})
//     const { query } = getState().admin.apiToken.list

//     Promise.all(selectedIds.map(removeToken))
//       .then(values => {
//         dispatch({type: ActionTypes.TOKEN_LIST_PREFIX + ActionTypes.LIST_REMOVED})
//       })
//       .catch(e => {
//         console.log('e', e)
//         dispatch({type: ActionTypes.TOKEN_LIST_PREFIX + ActionTypes.LIST_REMOVE_ERROR})
//       })
//       .then(() => {
//         loadTokens(query)(dispatch)
//       })
//   }
// }

// const selectTokens = (selectedIds) => (dispatch, getState) => dispatch({
//   type: ActionTypes.API_TOKENS_SELECTED,
//   selectedIds,
//   items: getState().admin.apiToken.list.items
// })

const mapTokenToWorkflows = (token, workflowList) => {
  return (dispatch, getState) => {
    dispatch({ type: ActionTypes.HIDE_DETAILS })

    const workflowsToUpdate = []
    workflowList.items.forEach(wf => {
      const { workflowId, integrationId, isLocked } = wf
      const wfProps = isLocked ? { workflowId } : wf

      if (integrationId === token.id && !workflowList.selectedIds.includes(workflowId)) {
        workflowsToUpdate.push({ ...wfProps, integrationId: '' })
        return
      }

      if (workflowList.selectedIds.includes(workflowId) && integrationId !== token.id) {
        workflowsToUpdate.push({ ...wfProps, integrationId: token.id })
      }
    })

    Promise.all(workflowsToUpdate.map(w => {
      const { name, description, rootSequence, fade, template, integrationId, workflowId } = w
      // Do not pass read-only fields in request payload.
      const payload = {
        name,
        description,
        rootSequence,
        fade,
        template,
        integrationId,
        workflowId
      }
      return skylight.workflow.v2.patch(payload)
    }))
      .catch(e => {
        console.log('failed to map workflows', e)
        dispatch({ type: ActionTypes.TOKEN_LIST_PREFIX + ActionTypes.TOKEN_MAP_ERROR })
      })
      .then(() => {
        const { query } = getState().admin.apiToken.list
        dispatch(loadTokens(query))
      })
  }
}

export const adminLanguageError = e => async(dispatch, getState) => {
  dispatch({ type: ActionTypes.ADMIN_LANGUAGE_ERROR })
  const message = await e.response.text()
  if (e.status === 400 && message.includes('invalid accessTokenTimeout')) {
    dispatch(onInvalidAccessTokenTimeout())
  }
}

export default {
  // onCreateToken: createToken,
  // onRemoveTokens: removeTokens,
  ...TOKEN_LIST_ACTIONS,
  ...TOKEN_ITEM_ACTIONS,
  onMapTokenToWorkflows: mapTokenToWorkflows,
  // onLoadRealm: loadRealm,
  // onUpdateRealm: updateRealm,
  onAdminLanguageError: adminLanguageError,
  onInvalidAccessTokenTimeout,
  ...FILTER_ACTIONS,
  ...ITEM_ACTIONS,
  ...TURN_CONFIG_ACTIONS,
  ...AUTH_APP_ACTIONS,
  ...ldapActions
}
