import * as ActionTypes from '../../actions/types'

const initialState = {
  activeImports: [],
  upload: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.APPLICATION_ACTIVE_IMPORTS_ADD:
      return { ...state, activeImports: [...state.activeImports, ...action.imports] }
    case ActionTypes.APPLICATION_ACTIVE_IMPORT_REMOVE:
      return { ...state, activeImports: state.activeImports.filter(x => x.importId !== action.importId) }
    case ActionTypes.APPLICATION_IMPORT_UPLOAD_UPDATE:
      return { ...state, upload: { ...state.upload, ...action.upload } }
    case ActionTypes.APPLICATION_IMPORT_UPLOAD_RESET:
      return { ...state, upload: {} }
    default:
      return state
  }
}
