import React from 'react'
import { ByList } from 'skylight-common'
import { getAllSessionEvents, getEventDeviceInfoList } from '../../../../../../../svc/application/events'

const getItems = props => async() => {
  const { application, session } = props
  const allSessionEvents = await getAllSessionEvents(application.applicationId, session.sessionId)
  const eventDeviceInfoList = await getEventDeviceInfoList(allSessionEvents)

  const result = []
  const map = new Map()
  for (const device of eventDeviceInfoList.filter(device => device)) {
    const { formattedName } = device.device.device
    if (!map.has(formattedName)) {
      map.set(formattedName, true)
      result.push({ text: formattedName, value: formattedName })
    }
  }

  return result
}

const ByDeviceType = props => {
  return <ByList
    className="by-device-type"
    title="DEVICE"
    placeholder={{ id: 'ALL', defaultMessage: 'All' }}
    value={props.value}
    onChange={props.onChange}
    onRemove={props.onRemove}
    onGetItems={getItems(props)} />
}

export { ByDeviceType }
