import * as ActionTypes from '../../actions/types'
import { CALL_DEFAULT_QUERY } from '../../lib/contact'
import { getUserCallStatus } from '../../lib/calls'
import { createListReducer, localSort } from 'skylight-common'

const baseList = createListReducer({
  prefix: ActionTypes.PREFIX_CALL_USER.toUpperCase()
}, CALL_DEFAULT_QUERY)

const list = (state, payload, all) => {
  state = baseList(state, payload, all)

  switch (payload.type) {
    case ActionTypes.PRESENSE_USERS_LOADED: {
      if (!state.query.isCallUser) return state
      let items = state.items.map(x =>
        ({ ...x, status: getUserCallStatus(payload.users.filter(u => u.userId === x.id)) }))
      // First sort by full name.
      items = localSort(items, { sortBy: 'firstName,lastName', sortAsc: true })
      items = localSort(items, state.query)
      return { ...state, items }
    }
    default:
      return state
  }
}

export default list
