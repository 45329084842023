import * as ActionTypes from '../../actions/types'

const initialState = {
  deviceToken: null,
  isLoading: false,
  error: null,
  previewToken: null
}

const addDevice = (state = initialState, payload, all) => {
  switch (payload.type) {
    case ActionTypes.DEVICE_TOKEN_LOAD:
      return { ...initialState, isLoading: true }
    case ActionTypes.DEVICE_TOKEN_LOADED:
      return { ...initialState, deviceToken: payload.token }
    case ActionTypes.DEVICE_TOKEN_LOAD_ERROR:
      return { ...initialState, error: payload.error }
    case ActionTypes.DEVICE_TOKEN_PREVIEW:
      return { ...state, previewToken: payload.preview }
    default:
      return state
  }
}

export default addDevice
