export const presentNotification = async(title, options, handler) => {
  const permission = await Notification.requestPermission()
  if (permission !== 'granted') {
    return
  }

  const n = await new Notification(title, options)
  if (handler) {
    n.addEventListener('click', e => handler(e, n))
  }

  return n
}
