import React from 'react'
import LinearProgress from 'material-ui/LinearProgress'
import { AssignWorkflow } from '../../../../containers/assignment'
import { AssignmentItemDetails } from './AssignmentItemDetails'
import './AssignmentDetails.css'

export const AssignmentDetails = (props) => {
  const { mode } = props.assignmentItem

  return (
    <div className="assignment-details">
      {props.isAttachmentLoading && <LinearProgress className="attachment-loading" />}
      {mode === 'new'
        ? <AssignWorkflow {...props} onCancel={props.onCancelAssignment} />
        : <AssignmentItemDetails item={props.assignmentItem.item} />}
    </div>
  )
}
