import * as ActionTypes from '../../actions/types'
import { createListReducer } from 'skylight-common'

const groupMembersList = createListReducer({
  prefix: ActionTypes.PREFIX_GROUP_MEMBER.toUpperCase()
}, { sortBy: 'username', sortAsc: true })

const groupMembers = (state, payload) => {
  const list = groupMembersList(state, payload)

  switch (payload.type) {
    case ActionTypes.GROUP_NEW:
      return { ...list, query: {}, items: [], total: 0 }
    case ActionTypes.GROUPS_LIST_SELECTED:
      return { ...list, items: [], total: 0 }
    case ActionTypes.ADD_MEMBERS_TO_GROUPS_START:
      return { ...state, isLoading: true }
    case ActionTypes.ADD_MEMBERS_TO_GROUPS_SUCCESS:
      return { ...state, isLoading: false }
    case ActionTypes.ADD_MEMBERS_TO_GROUPS_ERROR:
      return { ...state, isLoading: false, message: payload.error }
    default:
      return list
  }
}

export default groupMembers
