import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const noitem = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M21,6.56578947 L21,19 C21,20.1 20.1,21 19,21 L6.93589744,21 L4.5,23.5 L2.5,21.5 L21.5,2 L23.5,4 L21,6.56578947 Z M8.88461538,19 L19,19 L19,8.61842105 L8.88461538,19 Z M3,16.9644661 L3,5 C3,3.9 3.9,3 5,3 L16.9644661,3 L14.9644661,5 L5,5 L5,14.9644661 L3,16.9644661 Z" />
    </SvgIcon>
  )
}

export default noitem
