import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import IconButton from 'material-ui/IconButton'
import BlankCheckboxIcon from 'material-ui/svg-icons/toggle/check-box-outline-blank'
import { GridHeader, Column, Loading, ListFooterStats, ListActionButton, CursorTooltip } from 'skylight-common'
import PlusIcon from '../../../../../../Common/Icons/plusIcon'
import CalendarTodayIcon from '../../../../../../Common/Icons/calendarToday'
import { TableNextGen } from '../../../../../../Common/NextGen/TableNextGen'
import StopIcon from '../../../../../../Common/Icons/stopOutline'
import { PERMISSIONS } from '../../../../../../../lib/application'
import { SESSION_STATUSES } from '../../../../../../../lib/application/session'
import { EmptyListView } from './EmptyListView'
import SessionTextFilter from '../SessionFilter/SessionTextFilter'
import { COLUMNS } from './columns'

import appSessionActions from '../../../../../../../actions/application/sessions'
import contactActions from '../../../../../../../actions/contact'
import dialogActions from '../../../../../../../actions/dialog'

import './SessionList.css'

const sessionCloseConfirmationDialogTitle = (
  <span className="close-application-sessions-modal-title">
    <StopIcon />
    <FormattedMessage
      id="APPLICATION_SESSIONS_CLOSE_DIALOG_TITLE"
      defaultMessage="Are you sure you want to close the session(s)?" />
  </span>
)

const SessionList = (props) => {
  const { list, application, permissions } = props
  const { applicationId } = application
  const applicationPermissions = permissions[applicationId] || {}
  const canCreateSession = applicationPermissions[PERMISSIONS.SESSION.CREATE]

  const onCloseSessions = (sessionIds) => {
    props.onConfirm(
      () => {
        props.onCloseSessions(applicationId, sessionIds)
      },
      {
        customTitle: sessionCloseConfirmationDialogTitle,
        message: 'APPLICATION_SESSIONS_CLOSE_DIALOG_DESCRIPTION',
        action: 'ACTION_CLOSE_APPLICATION_SESSIONS',
        className: 'close-application-sessions-modal'
      }
    )
  }

  const renderItemActions = (item, _i, params = {}) => {
    const itemActions = [
      {
        icon: <StopIcon className="icon" />,
        tooltip: { id: 'ACTION_CLOSE_SESSION', default: 'Close session' },
        className: 'close-sessions',
        action: () => onCloseSessions([item.id]),
        isDisabled: () => item.status === SESSION_STATUSES.CLOSED
      },
      {
        icon: <CalendarTodayIcon className="icon" />,
        tooltip: { id: 'ACTION_VIEW_EVENTS', default: 'View Events' },
        className: 'view-events',
        action: () => props.onViewSessionEvents(applicationId, item.id)
      }
    ]

    return renderActionButtons(itemActions, params)
  }

  const renderActionButtons = (itemActions, params) => itemActions
    .filter(a => typeof a.isDisabled === 'function' ? !a.isDisabled() : true)
    .map((a, i) => (
      <CursorTooltip key={i} tooltip={<FormattedMessage id={a.tooltip.id} defaultMessage={a.tooltip.default} />}>
        <IconButton
          key={i}
          className={`item-action-button ${a.className || ''}`}
          tooltipPosition={params.tooltipPosition || 'bottom-center'}
          onTouchTap={e => {
            e.stopPropagation()
            a.action()
          }}>
          {a.icon}
        </IconButton>
      </CursorTooltip>)
    )

  const renderFooterItemActions = footerActionParams => {
    const selectedItem = list.items.find(item => item.id === list.selectedIds[0])

    return <div className="footer-actions">
      {renderItemActions(selectedItem, 0, footerActionParams)}
    </div>
  }

  const renderFooterItemsActions = footerActionParams => {
    const { selectedIds, items } = list
    const openedSessionIds = items
      .filter(session => selectedIds.includes(session.id) && session.status === SESSION_STATUSES.OPEN)
      .map(session => session.id)
    const itemActions = [
      {
        icon: <StopIcon className="icon" />,
        tooltip: { id: 'ACTION_CLOSE_SESSIONS', default: 'Close sessions' },
        className: 'close-sessions',
        action: () => onCloseSessions(openedSessionIds),
        isDisabled: () => !openedSessionIds.length
      }
    ]

    return renderActionButtons(itemActions, footerActionParams)
  }

  const renderFooterActions = () => {
    const footerActionParams = {
      tooltipPosition: 'top-left'
    }

    if (list.selectedIds.length === 1) {
      return renderFooterItemActions(footerActionParams)
    }

    return renderFooterItemsActions(footerActionParams)
  }

  const renderTableFooter = () => {
    return list.selectedIds.length > 0
      ? <ListFooterStats list={list} onUnselect={props.onSessionSelectItems} actions={renderFooterActions()} />
      : null
  }

  const renderSessionsTable = () => {
    if (!applicationId) {
      return null
    }

    return (
      <div className="app-session-table">
        <TableNextGen
          tableHeading={props.heading}
          showCheckboxes
          tableHeaderActions={props.tableHeaderActions}
          checkboxIcon={() => <BlankCheckboxIcon />}
          showHover
          isLoading={list.isLoading}
          query={list.query}
          items={list.items}
          total={list.total}
          done={list.done}
          selectedRows={list.selectedIds}
          name="appSessionList"
          tableFooter={renderTableFooter()}
          emptyLabel={<EmptyListView />}
          onQuery={props.onSessionQuery}
          onRowsSelected={props.onSessionSelectItems}>
          {COLUMNS.map(x => <Column
            key={x.name}
            name={x.name}
            sortable={x.sortable !== false}
            title={<FormattedMessage id={x.title} defaultMessage={x.title} />}
            render={x.render ? item => x.render(item) : null} />)}
          <Column
            key="sessionActions"
            className="actions"
            isAction
            render={renderItemActions} />
        </TableNextGen>
      </div>
    )
  }

  const renderListActionButton = () => {
    if (!canCreateSession) {
      return null
    }

    const hasSelectedItem = !!list.selectedIds.length
    const className = hasSelectedItem ? 'item-selected' : ''

    return <ListActionButton
      className={className}
      icon={<PlusIcon />}
      tooltip={<FormattedMessage id="ACTION_ADD_APP_SESSION" default="Create session" />}
      onAction={props.onSessionSave} />
  }

  const getTitle = () => {
    return <div className="session-list-title">{application.name}</div>
  }

  const getHeaderActions = () => [<SessionTextFilter key="filter" />]

  const isLoading = applicationId && list.isLoading && list.items.length === 0
  return (
    <div className="session-list">
      <GridHeader title={getTitle()} actions={getHeaderActions()} />
      <Loading
        isLoading={isLoading}
        message="LOADING_APP_SESSION_LIST"
        error={list.error
          ? <FormattedMessage
          id="EMPTY_APP_SESSION_LIST"
          defaultMessage="No sessions are created yet" />
          : null}>
        {renderSessionsTable()}
      </Loading>
      {renderListActionButton()}
    </div>
  )
}

export { SessionList }

const mapStateToProps = (state) => ({
  list: state.application.session.list,
  permissions: state.application.permissions,
  user: state.auth.user,
  application: state.application.selectedApplication
})

const sessionListActions = {
  ...appSessionActions,
  onUserSelectItems: contactActions.onUserSelectItems,
  onConfirm: dialogActions.onConfirm
}

const SessionListContainer = connect(mapStateToProps, sessionListActions)(SessionList)

export default SessionListContainer
