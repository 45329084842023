import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const sessionCreated = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 L12,20 Z M12,2 C14.6521649,2 17.195704,3.0535684 19.0710678,4.92893219 C20.9464316,6.80429597 22,9.3478351 22,12 C22,17.5228475 17.5228475,22 12,22 C6.47,22 2,17.5 2,12 C2,6.4771525 6.4771525,2 12,2 L12,2 Z M12.5,7 L12.5,12.1639344 L17,14.7901639 L16.25,16 L11,12.9016393 L11,7 L12.5,7 Z" id="path-1"></path>
    </SvgIcon>
  )
}

export default sessionCreated
