import { connect } from 'react-redux'
import snackbarActions from '../actions/snackbar'

import { Snackbar as SnackbarComp } from '../App/Home/Layout/Snackbars/Snackbars'

const snackbar = state => ({
  snackbar: state.snackbar
})

const actions = snackbarActions

const Snackbar = connect(snackbar, actions)(SnackbarComp)

export { Snackbar }
