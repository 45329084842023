import React from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import IconButton from 'material-ui/IconButton'
import Checkbox from 'material-ui/Checkbox'
import WarningIcon from 'material-ui/svg-icons/alert/warning'

const CertVerification = ({
  value,
  onChange,
  fieldProps,
  fieldClassName
}) => {
  const {
    name,
    title,
    disabled
  } = fieldProps

  return (
    <div className={fieldClassName}>
      <div className="field field-cert">
        <Checkbox
          className={classNames('field-check-box', { disabled })}
          checked={!!value}
          label={<FormattedMessage id={title} defaultMessage={title} />}
          disabled={disabled}
          onCheck={(e, value) => onChange(name, value)} />
        {(!disabled && value) &&
          <IconButton
            tooltipPosition="bottom-center"
            tooltip={<FormattedMessage id="LDAP_TOOLTIP_CERT_VERIFICATION" defaultMessage="LDAP_TOOLTIP_CERT_VERIFICATION" />}>
            <WarningIcon />
          </IconButton>}
      </div>
    </div>
  )
}

export { CertVerification }
