import React from 'react'
import SvgIcon from 'material-ui/SvgIcon'

const UserRoleExisting = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M12,1 L3,5 L3,11 C3,16.55 6.84,21.74 12,23 C17.16,21.74 21,16.55 21,11 L21,5 L12,1 L12,1 Z M12,5 C13.6568542,5 15,6.34314575 15,8 C15,9.65685425 13.6568542,11 12,11 C10.3431458,11 9,9.65685425 9,8 C9,6.34314575 10.3431458,5 12,5 L12,5 Z M17.13,17 C15.92,18.85 14.11,20.24 12,20.92 C9.89,20.24 8.08,18.85 6.87,17 C6.53,16.5 6.24,16 6,15.47 C6,13.82 8.71,12.47 12,12.47 C15.29,12.47 18,13.79 18,15.47 C17.76,16 17.47,16.5 17.13,17 Z" />
    </SvgIcon>
  )
}

export default UserRoleExisting
