import React from 'react'
import { Dialog, FlatButton } from 'material-ui'
import { DialogTitle, Form } from 'skylight-common'
import { FormattedMessage } from 'react-intl'
import { AccountInfo } from '../../../Profile/Settings/AccountInfo'
import { AccountPhone } from '../../../Profile/Settings/AccountPhone'
import { AccountLocation } from '../../../Profile/Settings/AccountLocation'
import { AccountLocalization } from '../../../Profile/Settings/AccountLocalization'

import './NewUserDialog.css'

class NewUserDialog extends React.Component {
  onCancel = () => {
    const { onHideDialog, dialogType } = this.props
    onHideDialog(dialogType)

    this.props.onUserChangeMode('edit', null)
  }

  onSave = () => {
    const { item, hasChanged } = this.props.contact.user
    if (!hasChanged) {
      return
    }

    this.props.onUserSave(item)
  }

  onChange = (e, v, propName = '') => {
    const { name = propName } = e.target
    this.props.onUserChange(name, v)
  }

  handleSubmit = () => {
    const { item, hasChanged } = this.props.contact.user
    hasChanged && this.props.onUserSave(item)
  }

  onUploadAvatar = (item) => {
    if (item.id) {
      this.props.onUserChange('avatar', item.id)
    } else {
      this.props.onAvatarUploadError()
    }
  }

  render() {
    const { dialog, dialogType } = this.props
    const { open } = dialog[dialogType] || {}
    const { item, errors, hasChanged } = this.props.contact.user

    if (!item) {
      console.warn('new user dialog is showing without new user item')
      return null
    }

    const title = <DialogTitle id="NEW_USER" defaultMessage="New user" />

    const actions = [
      <FlatButton
        key="cancel-button"
        className="cancel-button"
        label={<FormattedMessage id="CANCEL" defaultMessage="Cancel" />}
        primary
        onTouchTap={this.onCancel} />,
      <FlatButton
        key="save-button"
        label={<FormattedMessage id="SAVE" defaultMessage="Save" />}
        onTouchTap={this.onSave}
        disabled={!hasChanged}
        primary />
    ]

    return (
      <div className="new-user-dialog">
        <Dialog
          title={title}
          modal={false}
          open={!!open}
          actions={actions}
          className="dialog-modal new-user"
          contentClassName="new-user-content"
          actionsContainerClassName="actions-container"
          autoDetectWindowHeight={false}
          contentStyle={{ maxWidth: '650px' }}
          onRequestClose={this.onCancel}>
          <div className="new-user-container">
            <Form onSubmit={this.onSave}>
              <AccountInfo
                form={item}
                errors={errors}
                profileImage={item.avatar}
                onChange={this.onChange}
                onUploadImage={this.onUploadAvatar} />
              <AccountPhone form={item} errors={errors} onChange={this.onChange} />
              <AccountLocation form={item} errors={errors} onChange={this.onChange} />
              <AccountLocalization
                form={item}
                errors={errors}
                onChange={this.onChange}
                onSwitchLocale={() => {}}
                onSwitchLocaleForUser={() => {}} />
            </Form>
          </div>
        </Dialog>
      </div>
    )
  }
}

export { NewUserDialog }
