import React from 'react'
import { ImagePicker, isMobile, SUPPORTED_IMAGE_TYPES } from 'skylight-common'

const TRANSLATIONS = {
  SET_MEDIA: 'UPLOAD_MEDIA',
  TITLE: 'TITLE_UPLOAD_IMAGE'
}

const DIALOG_TYPE = 'UPLOAD_USER_IMAGE_DIALOG'
const DIALOG_PROPS = { [DIALOG_TYPE]: { open: true } }

const UploadUserImage = (props) => {
  const onUploadUrl = () => {} // unused
  const onUpload = (result) => {
    props.onUpload(result[0])
  }

  return (
    <ImagePicker
      {...props}
      dialog={DIALOG_PROPS}
      dialogType={DIALOG_TYPE}
      allowedTypes={SUPPORTED_IMAGE_TYPES}
      translations={TRANSLATIONS}
      dialogProps={{ autoScrollBodyContent: true }}
      droppable={!isMobile()}
      onlyAllowFileUploads
      onUpload={onUpload}
      onUploadUrl={onUploadUrl} />
  )
}

export { UploadUserImage }
