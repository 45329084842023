import get from 'lodash/get'

export class ListQueryBuilder {
  constructor(defaultQuery = {}) {
    this.query = defaultQuery
  }

  byName = name => {
    if (name) {
      this.query.name = name
    }

    return this
  }

  byCreatedBy = createdBy => {
    if (createdBy && Array.isArray(createdBy) && createdBy.length) {
      this.query.createdBy = createdBy.join(',')
    }

    return this
  }

  byModifiedBy = modifiedBy => {
    if (modifiedBy && Array.isArray(modifiedBy) && modifiedBy.length) {
      this.query.modifiedBy = modifiedBy.join(',')
    }

    return this
  }

  byCreatedAt = createdAt => {
    const createdFrom = get(createdAt, 'from')
    const createdTo = get(createdAt, 'to')

    if (createdFrom) this.query.createdFrom = createdFrom
    if (createdTo) this.query.createdTo = createdTo

    return this
  }

  byModifiedAt = modifiedAt => {
    const modifiedFrom = get(modifiedAt, 'from')
    const modifiedTo = get(modifiedAt, 'to')

    if (modifiedFrom) this.query.modifiedFrom = modifiedFrom
    if (modifiedTo) this.query.modifiedTo = modifiedTo

    return this
  }

  buildQuery = () => this.query
}
